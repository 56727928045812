/* eslint-disable prefer-const */
/* eslint-disable prefer-destructuring */
import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Props {}

export const Test44: React.FC<Props> = () => {
  const a = 1;
  const [tt, settt] = useState<any[]>([]);

  const initmap = () => {
    const mapContainer = document.getElementById('map'); // 지도를 표시할 div
    const mapOption = {
      center: new window.kakao.maps.LatLng(33.450701, 126.570667), // 지도의 중심좌표
      level: 2, // 지도의 확대 레벨
      zoomControl: true,
      scaleControl: true,
    };
    const map = new window.kakao.maps.Map(mapContainer, mapOption); // 지도를 생성합니다
    let drawingFlag = false; // 원이 그려지고 있는 상태를 가지고 있을 변수입니다
    let centerPosition: any; // 원의 중심좌표 입니다
    let drawingCircle: any; // 그려지고 있는 원을 표시할 원 객체입니다
    let drawingLine: any; // 그려지고 있는 원의 반지름을 표시할 선 객체입니다
    let drawingOverlay: any; // 그려지고 있는 원의 반경을 표시할 커스텀오버레이 입니다
    let drawingDot: any; // 그려지고 있는 원의 중심점을 표시할 커스텀오버레이 입니다

    let circles: any = []; // 클릭으로 그려진 원과 반경 정보를 표시하는 선과 커스텀오버레이를 가지고 있을 배열입니다

    // 지도에 클릭 이벤트를 등록합니다
    window.kakao.maps.event.addListener(map, 'click', (mouseEvent: any) => {
      // 클릭 이벤트가 발생했을 때 원을 그리고 있는 상태가 아니면 중심좌표를 클릭한 지점으로 설정합니다
      if (!drawingFlag) {
        // 상태를 그리고있는 상태로 변경합니다
        drawingFlag = true;

        // 원이 그려질 중심좌표를 클릭한 위치로 설정합니다
        centerPosition = mouseEvent.latLng;

        // 그려지고 있는 원의 반경을 표시할 선 객체를 생성합니다
        if (!drawingLine) {
          drawingLine = new window.kakao.maps.Polyline({
            strokeWeight: 3, // 선의 두께입니다
            strokeColor: '#00a0e9', // 선의 색깔입니다
            strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
          });
        }

        // 그려지고 있는 원을 표시할 원 객체를 생성합니다
        if (!drawingCircle) {
          drawingCircle = new window.kakao.maps.Circle({
            strokeWeight: 1, // 선의 두께입니다
            strokeColor: '#00a0e9', // 선의 색깔입니다
            strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
            fillColor: '#00a0e9', // 채우기 색깔입니다
            fillOpacity: 0.2, // 채우기 불투명도입니다
          });
        }

        // 그려지고 있는 원의 반경 정보를 표시할 커스텀오버레이를 생성합니다
        if (!drawingOverlay) {
          drawingOverlay = new window.kakao.maps.CustomOverlay({
            xAnchor: 0,
            yAnchor: 0,
            zIndex: 1,
          });
        }
      }
    });

    // 지도에 마우스무브 이벤트를 등록합니다
    // 원을 그리고있는 상태에서 마우스무브 이벤트가 발생하면 그려질 원의 위치와 반경정보를 동적으로 보여주도록 합니다
    window.kakao.maps.event.addListener(map, 'mousemove', (mouseEvent: any) => {
      // 마우스무브 이벤트가 발생했을 때 원을 그리고있는 상태이면
      if (drawingFlag) {
        // 마우스 커서의 현재 위치를 얻어옵니다
        const mousePosition = mouseEvent.latLng;

        // 그려지고 있는 선을 표시할 좌표 배열입니다. 클릭한 중심좌표와 마우스커서의 위치로 설정합니다
        const linePath = [centerPosition, mousePosition];

        // 그려지고 있는 선을 표시할 선 객체에 좌표 배열을 설정합니다
        drawingLine.setPath(linePath);

        // 원의 반지름을 선 객체를 이용해서 얻어옵니다
        const length = drawingLine.getLength();

        if (length > 0) {
          // 그려지고 있는 원의 중심좌표와 반지름입니다
          const circleOptions = {
            center: centerPosition,
            radius: length,
          };

          // 그려지고 있는 원의 옵션을 설정합니다
          drawingCircle.setOptions(circleOptions);

          // 반경 정보를 표시할 커스텀오버레이의 내용입니다
          const radius = Math.round(drawingCircle.getRadius());
          const content = `<div class="info">반경 <span class="number">${radius}</span>m</div>`;

          // 반경 정보를 표시할 커스텀 오버레이의 좌표를 마우스커서 위치로 설정합니다
          drawingOverlay.setPosition(mousePosition);

          // 반경 정보를 표시할 커스텀 오버레이의 표시할 내용을 설정합니다
          drawingOverlay.setContent(content);

          // 그려지고 있는 원을 지도에 표시합니다
          drawingCircle.setMap(map);

          // 그려지고 있는 선을 지도에 표시합니다
          drawingLine.setMap(map);

          // 그려지고 있는 원의 반경정보 커스텀 오버레이를 지도에 표시합니다
          drawingOverlay.setMap(map);
        } else {
          drawingCircle.setMap(null);
          drawingLine.setMap(null);
          drawingOverlay.setMap(null);
        }
      }
    });

    // 지도에 마우스 오른쪽 클릭이벤트를 등록합니다
    // 원을 그리고있는 상태에서 마우스 오른쪽 클릭 이벤트가 발생하면
    // 마우스 오른쪽 클릭한 위치를 기준으로 원과 원의 반경정보를 표시하는 선과 커스텀 오버레이를 표시하고 그리기를 종료합니다
    window.kakao.maps.event.addListener(
      map,
      'rightclick',
      (mouseEvent: any) => {
        if (drawingFlag) {
          // 마우스로 오른쪽 클릭한 위치입니다
          const rClickPosition = mouseEvent.latLng;

          // 원의 반경을 표시할 선 객체를 생성합니다
          const polyline = new window.kakao.maps.Polyline({
            path: [centerPosition, rClickPosition], // 선을 구성하는 좌표 배열입니다. 원의 중심좌표와 클릭한 위치로 설정합니다
            strokeWeight: 3, // 선의 두께 입니다
            strokeColor: '#00a0e9', // 선의 색깔입니다
            strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
          });

          // 원 객체를 생성합니다
          const circle = new window.kakao.maps.Circle({
            center: centerPosition, // 원의 중심좌표입니다
            radius: polyline.getLength(), // 원의 반지름입니다 m 단위 이며 선 객체를 이용해서 얻어옵니다
            strokeWeight: 1, // 선의 두께입니다
            strokeColor: '#00a0e9', // 선의 색깔입니다
            strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
            fillColor: '#00a0e9', // 채우기 색깔입니다
            fillOpacity: 0.2, // 채우기 불투명도입니다
          });

          const getTimeHTML = (distance: any) => {
            // 도보의 시속은 평균 4km/h 이고 도보의 분속은 67m/min입니다
            const walkkTime = distance / 67 || 0;
            let walkHour = '';
            let walkMin = '';

            // 계산한 도보 시간이 60분 보다 크면 시간으로 표시합니다
            if (walkkTime > 60) {
              walkHour = `<span class="number">${Math.floor(
                walkkTime / 60
              )}</span>시간 `;
            }
            walkMin = `<span class="number">${walkkTime % 60}</span>분`;

            // 자전거의 평균 시속은 16km/h 이고 이것을 기준으로 자전거의 분속은 267m/min입니다
            const bycicleTime = distance / 227 || 0;
            let bycicleHour = '';
            let bycicleMin = '';

            // 계산한 자전거 시간이 60분 보다 크면 시간으로 표출합니다
            if (bycicleTime > 60) {
              bycicleHour = `<span class="number">${Math.floor(
                bycicleTime / 60
              )}</span>시간 `;
            }
            bycicleMin = `<span class="number">${bycicleTime % 60}</span>분`;

            // 거리와 도보 시간, 자전거 시간을 가지고 HTML Content를 만들어 리턴합니다
            let content = '<ul class="info">';
            content += '    <li>';
            content += `        <span class="label">총거리</span><span class="number">${distance}</span>m`;
            content += '    </li>';
            content += '    <li>';
            content += `        <span class="label">도보</span>${walkHour}${walkMin}`;
            content += '    </li>';
            content += '    <li>';
            content += `        <span class="label">자전거</span>${bycicleHour}${bycicleMin}`;
            content += '    </li>';
            content += '</ul>';

            return content;
          };

          const radius = Math.round(circle.getRadius()); // 원의 반경 정보를 얻어옵니다
          const content = getTimeHTML(radius); // 커스텀 오버레이에 표시할 반경 정보입니다

          // 반경정보를 표시할 커스텀 오버레이를 생성합니다
          const radiusOverlay = new window.kakao.maps.CustomOverlay({
            content: content, // 표시할 내용입니다
            position: rClickPosition, // 표시할 위치입니다. 클릭한 위치로 설정합니다
            xAnchor: 0,
            yAnchor: 0,
            zIndex: 1,
          });

          // 원을 지도에 표시합니다
          circle.setMap(map);

          // 선을 지도에 표시합니다
          polyline.setMap(map);

          // 반경 정보 커스텀 오버레이를 지도에 표시합니다
          radiusOverlay.setMap(map);

          // 배열에 담을 객체입니다. 원, 선, 커스텀오버레이 객체를 가지고 있습니다
          const radiusObj = {
            polyline: polyline,
            circle: circle,
            overlay: radiusOverlay,
          };
          settt([...tt, radiusObj]);

          // 배열에 추가합니다
          // 이 배열을 이용해서 "모두 지우기" 버튼을 클릭했을 때 지도에 그려진 원, 선, 커스텀오버레이들을 지웁니다
          circles.push(radiusObj);

          // 그리기 상태를 그리고 있지 않는 상태로 바꿉니다
          drawingFlag = false;

          // 중심 좌표를 초기화 합니다
          centerPosition = null;

          // 그려지고 있는 원, 선, 커스텀오버레이를 지도에서 제거합니다
          drawingCircle.setMap(null);
          drawingLine.setMap(null);
          drawingOverlay.setMap(null);
        }
      }
    );

    // 지도에 표시되어 있는 모든 원과 반경정보를 표시하는 선, 커스텀 오버레이를 지도에서 제거합니다
    function removeCircles() {
      for (let i = 0; i < circles.length; i += 1) {
        circles[i].circle.setMap(null);
        circles[i].polyline.setMap(null);
        circles[i].overlay.setMap(null);
      }
      circles = [];
    }
  };

  // ---------------------------------------------------------------------------- //

  const [centerPosition, setCenterPosition] = useState<any>();
  // const [drawingFlag, setDrawingFlag] = useState(false);
  const [checkLine, setCheckLine] = useState<any>();
  const [polyLine, setPolyLine] = useState<any>();
  const [coords, setCoords] = useState<any>();
  const map2 = document.getElementById('map2');
  let navermap: any;
  let drawingLine: any; // 그려지고 있는 원의 반지름을 표시할 선 객체입니다
  let drawingCircle: any; // 그려지고 있는 원의 반지름을 표시할 선 객체입니다
  let length2: any;
  let drawingOverlay: any; // 그려지고 있는 원의 반경을 표시할 커스텀오버레이 입니다
  let drawingDot: any; // 그려지고 있는 원의 중심점을 표시할 커스텀오버레이 입니다
  let centerposition: any;
  let drawingFlag = false;
  let circles: any = [];
  const initMap2 = () => {
    const center = new window.naver.maps.LatLng(37.511337, 127.012084);
    const mapOptions = {
      center: center,
      useStyleMap: true,
      zoom: 13,
      scaleControl: false,
      // zoomControl: true,
      // zoomControlOptions: {
      //   position: window.naver.maps.Position.TOP_RIGHT,
      // },
      mapDataControl: false,
      logoControlOptions: {
        position: window.naver.maps.Position.LEFT_BOTTOM,
      },
      disableKineticPan: false,
      // scaleControlOptions: {
      //   position: window.naver.maps.Position.RIGHT_CENTER,
      // },
    };
    navermap = new window.naver.maps.Map('map2', mapOptions);
    let drawingManager;

    window.naver.maps.Event.once(navermap, 'init', () => {
      drawingManager = new window.naver.maps.drawing.DrawingManager({
        map: navermap,
        drawingControl: [window.naver.maps.drawing.DrawingMode.ELLIPSE],
        drawingControlOptions: {
          position: window.naver.maps.Position.TOP_CENTER,
          style: window.naver.maps.drawing.DrawingStyle.VERTICAL,
        },
        ellipseOptions: {
          bounds: centerPosition,
          fillColor: '#ff25dc',
          fillOpacity: 0.5,
          strokeWeight: 3,
          strokeColor: '#ff25dc',
        },
      });
      drawingManager.addListener(
        window.naver.maps.drawing.DrawingEvent.SELECT,
        (overlay: any) => {
          console.log(overlay.id, overlay.name);
        }
      );
    });

    window.naver.maps.Event.addListener(navermap, 'click', (evt: any) => {
      const newCoord = evt.coord;
      setCoords([newCoord]);
      console.log('newcoord>>>>>>>>>>>>>>>>>', newCoord);
      // if (!drawingLine) {
      //   drawingLine = new window.naver.maps.Polyline({
      //     // strokeColor: '#f00',
      //     strokeWeight: 5,
      //     strokeOpacity: 0.6,
      //     path: [newCoord],
      //     map: navermap,
      //   });
      // }
      if (!drawingFlag) {
        // 상태를 그리고있는 상태로 변경합니다
        drawingFlag = true;

        // 원이 그려질 중심좌표를 클릭한 위치로 설정합니다
        centerposition = evt.coord;

        // 그려지고 있는 원의 반경을 표시할 선 객체를 생성합니다
        if (!drawingLine) {
          drawingLine = new window.naver.maps.Polyline({
            // strokeColor: '#f00',
            strokeWeight: 5,
            strokeOpacity: 0.6,
            path: [newCoord],
            map: navermap,
          });
          drawingCircle = new naver.maps.Circle({
            map: navermap,
            center: newCoord,
            radius: length2,
            fillColor: 'crimson',
            fillOpacity: 0.1,
          });
          // drawingLine.setMap(map.current);
          // drawingCircle.setMap(map.current);
        }
      }
    });
    window.naver.maps.Event.addListener(
      navermap,
      'mousemove',
      (mouseEvent: any) => {
        // 마우스무브 이벤트가 발생했을 때 원을 그리고있는 상태이면
        if (drawingFlag) {
          // 마우스 커서의 현재 위치를 얻어옵니다
          const mousePosition = mouseEvent.coord;

          // 그려지고 있는 선을 표시할 좌표 배열입니다. 클릭한 중심좌표와 마우스커서의 위치로 설정합니다
          const linePath = [centerposition, mousePosition];

          // 그려지고 있는 선을 표시할 선 객체에 좌표 배열을 설정합니다
          drawingLine.setPath(linePath);

          // 원의 반지름을 선 객체를 이용해서 얻어옵니다
          length2 = drawingLine.getDistance();
          const info = `<div><span>${Math.round(length2)}m</span></div>`;
          if (length2 > 0) {
            const circleOptions = {
              center: centerPosition,
              radius: length2,
            };
            drawingCircle.setOptions(circleOptions);
            drawingLine.setMap(navermap);
            drawingCircle.setMap(navermap);
            const infowindow = new naver.maps.InfoWindow({
              content: info,
              borderWidth: 0,
              disableAnchor: true,
              backgroundColor: 'transparent',
              pixelOffset: new naver.maps.Point(0, -28),
            });
            infowindow.open(navermap, mousePosition);
          } else {
            drawingLine.setMap(null);
            drawingCircle.setMap(null);
            drawingFlag = false;
          }
        }
      }
    );
    window.naver.maps.Event.addListener(
      navermap,
      'rightclick',
      (mouseEvent: any) => {
        if (drawingFlag) {
          // 마우스로 오른쪽 클릭한 위치입니다
          const rClickPosition = mouseEvent.coord;

          // 원의 반경을 표시할 선 객체를 생성합니다
          const polyline = new window.naver.maps.Polyline({
            path: [centerposition, rClickPosition], // 선을 구성하는 좌표 배열입니다. 원의 중심좌표와 클릭한 위치로 설정합니다
            strokeWeight: 3, // 선의 두께 입니다
            strokeColor: '#00a0e9', // 선의 색깔입니다
            strokeOpacity: 1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
          });
          console.log('dis<<<<<<<<<<<', polyline.getDistance());
          // 원 객체를 생성합니다
          const circle = new window.naver.maps.Circle({
            center: centerposition, // 원의 중심좌표입니다
            radius: drawingCircle, // 원의 반지름입니다 m 단위 이며 선 객체를 이용해서 얻어옵니다
            strokeWeight: 1, // 선의 두께입니다
            strokeColor: '#00a0e9', // 선의 색깔입니다
            strokeOpacity: 0.1, // 선의 불투명도입니다 0에서 1 사이값이며 0에 가까울수록 투명합니다
            strokeStyle: 'solid', // 선의 스타일입니다
            fillColor: '#00a0e9', // 채우기 색깔입니다
            fillOpacity: 0.2, // 채우기 불투명도입니다
          });

          const getTimeHTML = () => {
            let content = `<span>${polyline.getDistance()}</span>`;

            return content;
          };

          // 반경정보를 표시할 커스텀 오버레이를 생성합니다
          const radiusOverlay = new window.kakao.maps.CustomOverlay({
            content: getTimeHTML(), // 표시할 내용입니다
            position: rClickPosition, // 표시할 위치입니다. 클릭한 위치로 설정합니다
            xAnchor: 0,
            yAnchor: 0,
            zIndex: 1,
          });

          // 선을 지도에 표시합니다
          polyline.setMap(navermap);

          // 반경 정보 커스텀 오버레이를 지도에 표시합니다

          // 배열에 담을 객체입니다. 원, 선, 커스텀오버레이 객체를 가지고 있습니다
          const radiusObj = {
            polyline: polyline,
            circle: circle,
            overlay: radiusOverlay,
          };

          // 배열에 추가합니다
          // 이 배열을 이용해서 "모두 지우기" 버튼을 클릭했을 때 지도에 그려진 원, 선, 커스텀오버레이들을 지웁니다
          circles.push(radiusObj);

          // 그리기 상태를 그리고 있지 않는 상태로 바꿉니다
          drawingFlag = false;

          // 중심 좌표를 초기화 합니다
          centerposition = null;

          // 그려지고 있는 원, 선, 커스텀오버레이를 지도에서 제거합니다
          drawingLine.setMap(null);
          // drawingOverlay.setMap(null);
        }
      }
    );
  };

  // 핀 요소
  // var infoWindowElement = $([
  //     '<div class="pin_nation">',
  //     '   <a href="http://www.window.naver.com/" target="_blank" class="pin_a">',
  //     '       <img src="./img/hi-seoul.jpg" width="38" height="26" alt="" class="pin_flag_m">',
  //     '       <span class="pin_txt"><em>캐나다</em> <span class="spr spr_arrow"></span></span>',
  //     '       <span class="spr spr_arr"></span>',
  //     '   </a>',
  //     '   <div class="pin"><span class="pin_blur"></span></div>',
  //     '</div>'].join(''));

  // var infowindow = new window.naver.maps.InfoWindow({

  //     content: infoWindowElement[0],

  //     borderWidth: 0,
  //     disableAnchor: true,
  //     backgroundColor: 'transparent',

  //     pixelOffset: new window.naver.maps.Point(0, -28),
  // });

  // window.naver.maps.Event.addListener(map, "click", function(e) {

  //     var latlng = e.latlng;

  //     infoWindowElement.find("em").text(latlng.toString());
  //     infowindow.open(map, latlng);
  // });

  useEffect(() => {
    initmap();
    initMap2();
  }, []);

  return (
    <div style={{ margin: '5rem 20rem' }}>
      <Button onClick={() => console.log('tt>>>>>>>>>>>>>>>>>>>>>>', tt)}>
        tt
      </Button>
      <div id='map' style={{ width: '70vw', height: '50vh' }} />
      <div style={{ margin: '1rem' }}>ss</div>
      <div id='map2' style={{ width: '70vw', height: '50vh' }} />
    </div>
  );
};
