import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwDateRangePicker } from './dateSetting/SwDateRangePicker';
import { useMany } from '../../hooks/api';

interface Props {
  title: string;
  open: boolean;
  onClose: () => void;
  onRejectMessage: (message: string) => void;
}

const columns = ['아이디', '이름'];

export const SwCreationRequestReject: React.FC<Props> = ({
  title,
  open,
  onClose,
  onRejectMessage,
}) => {
  const [message, setMessage] = useState<any>();

  const onApply = () => {
    console.log('SwCreationRequestReject onApply !!!');
    onRejectMessage(message);
    onClose();
  };

  useEffect(() => {
    console.log('SwCreationRequestReject initial !!!');
    setMessage('');
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          width: '55vh',
          height: '45vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <Box>
            <span>{`${title} 반려 사유`}</span>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Box sx={{ mt: '0rem' }}>
          <Typography className='title'>
            {`${title} 반려 사유를 작성해 주세요.`}
          </Typography>
          <TextField
            multiline
            rows={8}
            fullWidth
            placeholder='반려 사유'
            value={message}
            onChange={evt => setMessage(evt.target.value)}
            sx={{ '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' } }}
          />
        </Box>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='primary'
          sx={{ fontSize: '.87rem' }}
          onClick={onApply}
        >
          완료
        </Button>
      </DialogActions>
    </Dialog>
  );
};
