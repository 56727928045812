import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Paper,
  Switch,
  ClickAwayListener,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  ChangeEvent,
  DragEvent,
  SyntheticEvent,
  useEffect,
  useState,
  Dispatch,
  useRef,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import {
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
  KEY_PROGRAM_MISSION_QNA_DETAIL_INFO,
  KEY_PROGRAM_MISSION_QNA_QUESTION_LIST,
} from '../../../common/key';
import {
  TOOLTIP_REQUIRED_ALL_ANSWER,
  authAvailableTime,
  countArr,
  getTimeArray,
  pointPerAchieve,
} from './SwProgramCommon';
import { SwFileSetting } from '../SwFileSetting';
import { useSession } from '../../../hooks/session';
import { SwSurveyCreate } from './SwSurveyCreate';
import { SwQuizCreate } from './SwQuizCreate';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import {
  TOOLTIP_FONT_SIZE,
  checkNaN,
  generateUuidId,
} from '../../../common/helper';
import {
  getProgramMissionDetailInfo,
  getProgramMissionQnaQuestionList,
  postProgramMissionQna,
  putProgramCurriculumMissionDelete,
  putProgramCurriculumMissionQuestionDelete,
  putProgramMissionQnaQuestionIsRequired,
  putProgramMissionQnaType,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import { SwSelectTemplate } from './SwSelectTemplate';
import { newUuidId } from '../SwUuidCreate';
import { requiredAllAnswerSts } from '../../../common/atom';
import { SwWeekAndTimePicker } from '../dateSetting/SwWeekAndTimePicker';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderRadius: '15px',
  },
});

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

interface Props {
  data: any;
  setData: Dispatch<React.SetStateAction<any>>;
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  disabled?: boolean;
}

export const SwMissionQna: React.FC<Props> = ({
  data,
  setData,
  open,
  onClose,
  onSave,
  disabled = false,
}) => {
  const queryClient = useQueryClient();
  const { loginVal } = useSession();
  const [requiredAllAnswerInfo, setRequiredAllAnswerInfo] =
    useRecoilState(requiredAllAnswerSts);
  const titleRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [achieveCount, setAchieveCount] = useState<number>(1);
  const [retryAnswer, setRetryAnswer] = useState<string>('retry-permit');
  const [attendDay, setAttendDay] = useState<string>('day-all');
  const [minAnswer, setMinAnswer] = useState<number>(1);
  const [requiredAllAnswer, setRequiredAllAnswer] = useState<number>(-1);
  const [requiredAllAnswerSwitch, setRequiredAllAnswerSwitch] =
    useState<number>(-1);
  const [content, setContent] = useState<string>('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [link, setLink] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openQnaConfirm, setOpenQnaConfirm] = useState<number>(0);
  const [missionId, setMissionId] = useState<string>('');
  const [weekId, setWeekId] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const [questionList, setQuestionList] = useState<any[]>([]);
  const [showAttenDay, setShowAttendDay] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<number>(0);
  const [deleteAll, setDeleteAll] = useState<boolean>(false);
  const [forcedSave, setForcedSave] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [hasWeekAndTime, setHasWeekAndTime] = useState<string>('no');
  const [attendWeeks, setAttendWeeks] = useState<any[]>([]);
  const [attendTimes, setAttendTimes] = useState<any[]>([]);

  const reset = () => {
    setTabValue(0);
    setTitle('');
    setPoint(10);
    setLink('');
    setQuestionList([]);
    setMinAnswer(1);
    setRequiredAllAnswer(-1);
    setRequiredAllAnswerSwitch(-1);
    setRetryAnswer('retry-deny');
    setHasWeekAndTime('no');
    setAttendWeeks([]);
    setAttendTimes([]);
  };

  const makeDummyQuestion = (
    newType: number,
    newMissionId: string,
    newWeekId: string
  ) => {
    const newOption = [{ body: '', isCorrect: 0, isCustomAnswer: 0 }];
    const newQuestion = {
      image: null,
      missionId: newMissionId,
      optionList: JSON.stringify(newOption),
      seq: 0,
      title: '',
      type: 1,
      weekId: newWeekId,
    };
    setQuestionList([newQuestion]);
  };

  const {
    data: detailInfo,
    isLoading,
    refetch: programMissionRefetch,
  } = useQuery(
    KEY_PROGRAM_MISSION_QNA_DETAIL_INFO,
    () => {
      console.log('missionId: ', missionId);
      if (missionId.length > 0) {
        const newData: any = {
          type: 'qna',
          missionId: missionId,
        };
        return getProgramMissionDetailInfo(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.mission !== null && res.mission.qna !== null) {
          res.mission.qna.title !== undefined &&
            setTitle(res.mission.qna.title);
          res.mission.qna.successScore !== undefined &&
            setPoint(res.mission.qna.successScore);
          setRetryAnswer(
            res.mission.qna.isRepeated === null
              ? 'retry-permit'
              : res.mission.qna.isRepeated === true ||
                res.mission.qna.isRepeated === 1
              ? 'retry-permit'
              : 'retry-deny'
          );
          setAttendDay('day-all');
          if (res.mission.qna.type !== undefined) {
            res.mission.qna.type === 1 ? setTabValue(0) : setTabValue(1);
          }
          setQuestionList(
            res.mission.questionList !== null ? res.mission.questionList : []
          );
          // if (
          //   res.mission.questionList !== null &&
          //   res.mission.questionList.length === 1
          // ) {
          //   setIsEdit(2);
          // }
          if (
            res.mission.questionList !== null &&
            res.mission.questionList.length === 0
          ) {
            if (data !== undefined && data !== null)
              makeDummyQuestion(tabValue === 0 ? 1 : 2, missionId, data.weekId);
            // setIsEdit(2);
          }

          setMinAnswer(res.mission.qna.minAnswer);
          const newTimes = [
            res.mission.qna.attendStartTime
              ? `${res.mission.qna.attendStartTime
                  .toString()
                  .padStart(2, '0')}:00~${res.mission.qna.attendEndTime
                  .toString()
                  .padStart(2, '0')}:00`
              : '00:00~24:00',
          ];
          const newWeeks: [] =
            res.mission.qna.attendWeeks &&
            res.mission.qna.attendWeeks.length > 0
              ? JSON.parse(res.mission.qna.attendWeeks)
              : [];
          setAttendTimes(newTimes !== null ? newTimes : []);
          setAttendWeeks(newWeeks);
          setHasWeekAndTime(newWeeks.length > 0 ? 'yes' : 'no');
        } else if (missionId.length > 0) {
          reset();
          setOpenDialog(true);
          setDialogMessage('데이터가 없습니다.');
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newMissionId: string) => {
    queryClient.prefetchQuery(KEY_PROGRAM_MISSION_QNA_DETAIL_INFO, () => {
      const newData: any = {
        type: 'qna',
        missionId: newMissionId,
      };
      return getProgramMissionDetailInfo(newData);
    });
  };

  const { refetch: programMissionQuestionRefetch } = useQuery(
    KEY_PROGRAM_MISSION_QNA_QUESTION_LIST,
    () => {
      console.log('missionId: ', missionId);
      if (missionId.length > 0) {
        const newData: any = {
          missionId: missionId,
        };
        return getProgramMissionQnaQuestionList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.questionList !== null) {
          setQuestionList(res.questionList !== null ? res.questionList : []);

          if (res.questionList !== null && res.questionList.length === 0) {
            if (data !== undefined && data !== null)
              makeDummyQuestion(tabValue === 0 ? 1 : 2, missionId, data.weekId);
            // setIsEdit(2);
          }
        } else if (missionId.length > 0) {
          reset();
          setOpenDialog(true);
          setDialogMessage('데이터가 없습니다.');
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchQuestionList = (newMissionId: string) => {
    queryClient.prefetchQuery(KEY_PROGRAM_MISSION_QNA_QUESTION_LIST, () => {
      const newData: any = {
        missionId: newMissionId,
      };
      return getProgramMissionQnaQuestionList(newData);
    });
  };

  const {
    mutate: deleteProgramCurriculumMissionMutate,
    isError: isMutateDeleteError,
  } = useMutation(putProgramCurriculumMissionDelete, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('삭제되었습니다.');
        setTimeout(() => {
          onSave();
        }, 500);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: deleteProgramCurriculumMissionQuestionMutate,
    isError: isMutateDeleteQuestionError,
  } = useMutation(putProgramCurriculumMissionQuestionDelete, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('삭제되었습니다.');
        if (!deleteAll) programMissionRefetch();
        // onSave();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: putMissionQnaQuestionIsRequiredMutate,
    isError: isRequriedMutateError,
  } = useMutation(putProgramMissionQnaQuestionIsRequired, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('변경되었습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('변경이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const { mutate: putMissionQnaTypeMutate, isError: isTypeMutateError } =
    useMutation(putProgramMissionQnaType, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('변경되었습니다.');
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage('변경이 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    });

  const { mutate: postMissionQnaMutate, isError: isMutateError } = useMutation(
    postProgramMissionQna,
    {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('등록되었습니다.');
          setTimeout(() => {
            setOpenSnackbar(false);
            onSave();
          }, 500);
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage('등록이 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    }
  );

  const registerCurriculumMission = () => {
    let isValid = 0;

    const newMissionId =
      missionId !== undefined && missionId.length > 0
        ? missionId
        : data.missionId;
    const newRepeated = retryAnswer === 'retry-permit' ? 1 : 0;
    const newType = tabValue === 0 ? 1 : 2;

    if (
      isValid === 0 &&
      (title === undefined || title === null || title.length === 0)
    ) {
      isValid = 1;
    }

    if (isValid === 0 && (point === undefined || point === null)) {
      isValid = 2;
    }

    if (isValid === 0 && tabValue === 1 && minAnswer === 0) {
      isValid = 3;
    }

    if (isValid === 0 && newMissionId === '00000000000000000000000000000000') {
      isValid = 4;
    }

    if (isValid === 0) {
      const newMissionData = {
        weekId: weekId,
        missionId: newMissionId,
        title: title,
        successScore: point,
        successCount: 1,
        isRepeated: newRepeated,
        minAnswer: minAnswer,
        type: newType,
        attendWeeks: attendWeeks,
        attendTimes:
          attendTimes !== null && attendTimes.length > 0 ? attendTimes[0] : '',
      };
      console.log('postMissionQnaMutate :', newMissionData);
      postMissionQnaMutate(newMissionData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '제목을 입력해 주세요';
          break;
        case 2:
          message = '달성시 획득 점수를 선택해주세요';
          break;
        case 3:
          message = '달성 인정 정답 개수를 입력해 주세요';
          break;
        case 4:
          message = '미션 아이디 오류, 화면 fresh 후 다시 시도해주세요';
          break;
        case 7:
          message = '커버 이미지를 등록해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenSnackbar(true);
      setDialogMessage(message);
    }
  };

  const checkValid = () => {
    setForcedSave(true);
    registerCurriculumMission();
  };

  const updateMissionQnaType = (newType: number) => {
    const newData: any = {
      missionId: data.missionId,
      type: newType,
    };
    putMissionQnaTypeMutate(newData);
  };

  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (isEdit === 1) return;

    console.log('onChangeTab tabValue :', tabValue, ', newValue:', newValue);
    if (newValue !== tabValue) {
      if (questionList.length > 0) {
        setOpenQnaConfirm(newValue + 1);
      } else {
        updateMissionQnaType(newValue + 1);

        setTabValue(newValue);
        if (data !== undefined && data !== null) {
          makeDummyQuestion(
            newValue === 0 ? 1 : 2,
            data.missionId,
            data.weekId
          );
        }
      }
    }
  };

  const reloadMission = (newMissionId: string) => {
    console.log('reloadMission :', newMissionId);
    prefetchQuestionList(newMissionId);
  };

  const [isPressed, setIsPressed] = useState(false);

  const handleMouseDown = () => {
    setIsPressed(true);
  };

  const handleMouseUp = () => {
    setIsPressed(false);
  };

  const handleChangeRetryAnswer = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRetryAnswer((event.target as HTMLInputElement).value);
  };

  const handleChangeAttendDay = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAttendDay((event.target as HTMLInputElement).value);
  };

  const saveData = () => {
    const newData = {
      missionId: generateUuidId(),
      missionTitle: title,
      successScore: point,
      successCount: 1,
      link: link,
      image: '',
    };
    setData(newData);
    onSave();
  };

  const deleteMissionQna = () => {
    setDeleteAll(false);
    const newMissionData = {
      weekId: weekId,
      missionId: missionId,
      type: 'qna',
    };
    console.log('deleteMissionQnaMutate :', newMissionData);
    deleteProgramCurriculumMissionMutate(newMissionData);
  };

  const changeQnaType = (newValue: number) => {
    setDeleteAll(true);
    questionList.map((item: any) => {
      const newMissionData = {
        weekId: weekId,
        missionId: item.missionId,
        type: 'qna',
      };
      console.log('deleteMissionQnaQuestionMutate :', newMissionData);
      deleteProgramCurriculumMissionQuestionMutate(newMissionData);
    });

    setQuestionList([]);
    makeDummyQuestion(newValue, data.missionId, data.weekId);
  };

  const preventClose = () => {
    // 클릭 이벤트 무시
  };

  const changeRequiredAnswer = (value: number) => {
    setRequiredAllAnswer(value);

    const newIds = questionList.map((item: any) => item.questionId);

    setRequiredAllAnswerInfo(prev => ({
      ...prev,
      ids: value === 1 ? [...newIds] : [''],
      requiredAll: value,
    }));

    const newData = {
      questionIds: newIds,
      isRequired: value === 1 ? 1 : 0,
    };
    putMissionQnaQuestionIsRequiredMutate(newData);
  };

  const doClose = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    reason: string
  ) => {
    console.log('doClose :', reason);
    reason !== 'backdropClick' ? onClose() : '';
  };

  const setupUI = () => {
    setQuestionList([]);
    setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);
    setMissionId(data.missionId);
    setWeekId(data.weekId);
    setMinAnswer(data.minAnswer);
    setForcedSave(false);

    if (
      data.missionId === undefined ||
      data.missionId === null ||
      data.missionId.length === 0
    ) {
      const newMissionId = newUuidId();
      setData((prevState: any) => ({
        ...prevState,
        cmd: 'update',
        missionId: newMissionId,
      }));
      reset();
      makeDummyQuestion(1, newMissionId, data.weekId);
      setIsEdit(1);
    }
  };

  useEffect(() => {
    const isRequiredList = questionList.filter(q =>
      requiredAllAnswerInfo.ids.includes(q.questionId)
    );
    requiredAllAnswerInfo.requiredAll === 1
      ? setRequiredAllAnswerSwitch(1)
      : setRequiredAllAnswerSwitch(0);
    if (
      isRequiredList !== null &&
      isRequiredList.length === questionList.length
    ) {
      setRequiredAllAnswerSwitch(1);
    }
  }, [requiredAllAnswerInfo]);

  useEffect(() => {
    console.log('missionId :', missionId);
    if (missionId !== undefined && missionId !== null && missionId.length > 0) {
      programMissionRefetch();
      data.isEdit === 2 ? setIsEdit(2) : setIsEdit(1);
    }
  }, [missionId]);

  useEffect(() => {
    if (open) {
      console.log('qna data:', data);
      setTimeout((_: any) => {
        setupUI();
      }, 500);
    } else {
      setQuestionList([]);
      setMissionId('');
      setWeekId('');
      setTitle('');
      setIsEdit(0);
      setMinAnswer(1);
      setTabValue(0);
      setForcedSave(false);
      setRequiredAllAnswer(-1);
      setRetryAnswer('retry-deny');
      setHasWeekAndTime('no');
      setAttendWeeks([]);
      setAttendTimes([]);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={doClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '75vh',
          margin: '40rem',
        },
      }}
    >
      <ClickAwayListener onClickAway={preventClose}>
        <div>
          <DialogTitle>
            <Box className='flex_between'>
              <span>
                {isEdit === 1 ? '질의응답 자세히보기' : '질의응답 추가하기'}
              </span>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider sx={{ m: 0, p: 0 }} />
          <DialogContent
            sx={{ p: '1rem 2rem', overflowY: 'scroll', height: '64vh' }}
          >
            {isEdit === 1 ? (
              <>
                <Box>
                  <Typography className='title'>{title}</Typography>
                  <Divider />
                </Box>
                <Box>
                  <Typography className='title'>달성시 획득 점수</Typography>
                  <TextField
                    sx={{ width: '16rem', mr: '1rem' }}
                    value={point}
                  />
                  <Divider />
                </Box>
                <Box>
                  <Typography className='title'>
                    미션에 참여할 수 있는 요일/시간대 제한
                  </Typography>
                  {hasWeekAndTime !== 'no' ? (
                    <SwWeekAndTimePicker
                      times={attendTimes}
                      setTimes={setAttendTimes}
                      weeks={attendWeeks}
                      setWeeks={setAttendWeeks}
                      showButton={false}
                      showSubTitle={true}
                      maxLimit={1}
                      disabled={true}
                    />
                  ) : (
                    <Typography className=''>설정 안함</Typography>
                  )}
                  <Divider />
                </Box>
                <Box>
                  <Typography className='title'>재답변 허용 여부</Typography>
                  <RadioGroup
                    row
                    aria-labelledby='qna-retry-row-radio-buttons-group-label'
                    name='retry-row-radio-buttons-group'
                    value={retryAnswer}
                    onChange={evt => {
                      evt.currentTarget.value === 'retry-permit'
                        ? setRetryAnswer('retry-permit')
                        : setRetryAnswer('retry-deny');
                    }}
                  >
                    <FormControlLabel
                      value='retry-permit'
                      control={<Radio />}
                      label='허용'
                    />
                    <FormControlLabel
                      value='retry-deny'
                      control={<Radio />}
                      label='미허용'
                    />
                  </RadioGroup>
                  <Divider />
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <Typography className='title'>제목</Typography>
                  <TextField
                    sx={{ width: '50%' }}
                    placeholder='제목을 입력해주세요.'
                    inputRef={titleRef}
                    onChange={evt => {
                      setTitle(evt.target.value.substring(0, 30));
                    }}
                    value={title}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>{title?.length} / 30</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Divider />
                </Box>
                <Box>
                  <Typography className='title'>달성시 획득 점수</Typography>
                  <TextField
                    sx={{ width: '16rem', mr: '1rem' }}
                    select
                    value={point}
                    onChange={evt => setPoint(Number(evt.target.value))}
                  >
                    {pointPerAchieve.map(option => (
                      <MenuItem key={`health_${option}`} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Divider />
                </Box>
                <Box>
                  <Typography className='title'>
                    미션에 참여할 수 있는 요일/시간대 제한
                  </Typography>
                  <RadioGroup
                    row
                    aria-labelledby='movie-row-radio-buttons-group-label'
                    name='row-radio-buttons-group'
                    value={hasWeekAndTime}
                    onChange={evt => {
                      setHasWeekAndTime(evt.currentTarget.value);
                      setAttendTimes([]);
                      setAttendWeeks([]);
                    }}
                  >
                    <FormControlLabel
                      value='no'
                      control={<Radio />}
                      label='설정 안함'
                    />
                    <FormControlLabel
                      value='yes'
                      control={<Radio />}
                      label='설정'
                    />
                  </RadioGroup>
                  {hasWeekAndTime !== 'no' && (
                    <SwWeekAndTimePicker
                      times={attendTimes}
                      setTimes={setAttendTimes}
                      weeks={attendWeeks}
                      setWeeks={setAttendWeeks}
                      showButton={false}
                      showSubTitle={true}
                      maxLimit={1}
                    />
                  )}
                  <Divider />
                </Box>
                <Box>
                  <Typography className='title'>재답변 허용 여부</Typography>
                  <RadioGroup
                    row
                    aria-labelledby='qna-retry-row-radio-buttons-group-label'
                    name='retry-row-radio-buttons-group'
                    value={retryAnswer}
                    onChange={handleChangeRetryAnswer}
                  >
                    <FormControlLabel
                      value='retry-permit'
                      control={<Radio />}
                      label='허용'
                    />
                    <FormControlLabel
                      value='retry-deny'
                      control={<Radio />}
                      label='미허용'
                    />
                  </RadioGroup>
                  <Divider />
                </Box>
                {showAttenDay && (
                  <Box>
                    <Typography className='title'>참여 가능 요일</Typography>
                    <RadioGroup
                      row
                      aria-labelledby='qna-day-row-radio-buttons-group-label'
                      name='day-row-radio-buttons-group'
                      value={attendDay}
                      onChange={handleChangeAttendDay}
                    >
                      <FormControlLabel
                        value='day-all'
                        control={<Radio />}
                        label='전체'
                      />
                      <FormControlLabel
                        value='day-select'
                        control={<Radio />}
                        label='요일 선택'
                      />
                    </RadioGroup>
                    <Divider />
                  </Box>
                )}
              </>
            )}
            <Box>
              <Box className='flex_between'>
                <Box sx={{ p: '0rem 0', mt: '1rem', width: '100%' }}>
                  <Box>
                    <CreateTabs value={tabValue} onChange={onChangeTab}>
                      <Tab
                        disabled={disabledButton}
                        key='0'
                        sx={{ width: '30rem', margin: '1rem .5rem' }}
                        label={
                          <Box
                            className='flex_between'
                            sx={{
                              gap: '8px',
                              width: '100%',
                            }}
                          >
                            <span>설문조사</span>
                            <DoneIcon />
                          </Box>
                        }
                      />
                      <Tab
                        disabled={disabledButton}
                        key='1'
                        sx={{ width: '30rem', margin: '1rem .5rem' }}
                        label={
                          <Box
                            className='flex_between'
                            sx={{
                              gap: '8px',
                              width: '100%',
                            }}
                          >
                            <span>퀴즈</span>
                            <DoneIcon />
                          </Box>
                        }
                      />
                    </CreateTabs>
                  </Box>
                  <TabPanel value={tabValue} index={0}>
                    <Box className='flex_end' sx={{ mb: '1rem' }}>
                      <Tooltip
                        title={
                          <Typography fontSize={TOOLTIP_FONT_SIZE}>
                            {TOOLTIP_REQUIRED_ALL_ANSWER}
                          </Typography>
                        }
                        followCursor
                      >
                        <HelpOutlineIcon sx={{ color: '#999999' }} />
                      </Tooltip>
                      <FormControlLabel
                        value='start'
                        control={
                          <Switch
                            disabled={disabledButton}
                            color='primary'
                            checked={requiredAllAnswerSwitch === 1}
                            value={requiredAllAnswerSwitch === 1}
                            onClick={evt => evt.stopPropagation()}
                            onChange={() => {
                              setRequiredAllAnswerSwitch(
                                requiredAllAnswerSwitch === 1 ? 0 : 1
                              );
                              changeRequiredAnswer(
                                requiredAllAnswerSwitch === 1 ? 0 : 1
                              );
                            }}
                          />
                        }
                        label='전체 필수 응답'
                        labelPlacement='start'
                      />
                    </Box>
                    <SwSurveyCreate
                      requiredAllAnswer={requiredAllAnswer}
                      missionInfo={data}
                      questionList={questionList}
                      setQuestionList={setQuestionList}
                      isEdit={isEdit}
                      onReloadMission={reloadMission}
                      forcedSave={forcedSave}
                      disabled={disabled}
                      onDisabledButton={type => setDisabledButton(type)}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <Box>
                      <Typography className='title'>
                        달성 인정 정답 개수
                      </Typography>
                      <Typography sx={{ color: '#999999' }}>
                        설정하신 정답 개수 이상 달성시 건강점수를 부여합니다.
                      </Typography>
                      <Box className='flex_start'>
                        <TextField
                          sx={{ width: '5rem' }}
                          value={minAnswer}
                          onChange={(
                            evt: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newValue = checkNaN(
                              evt.target.value,
                              minAnswer
                            );
                            setMinAnswer(
                              newValue > 0 && newValue <= questionList.length
                                ? newValue
                                : newValue > questionList.length
                                ? questionList.length
                                : 0
                            );
                          }}
                        />
                        <Typography sx={{ ml: '.5rem' }}>
                          / {questionList !== null && questionList.length}
                        </Typography>
                      </Box>
                      <Divider />
                    </Box>
                    <SwQuizCreate
                      missionInfo={data}
                      questionList={questionList}
                      setQuestionList={setQuestionList}
                      isEdit={isEdit}
                      onReloadMission={reloadMission}
                      forcedSave={forcedSave}
                      disabled={disabled}
                      onDisabledButton={type => setDisabledButton(type)}
                    />
                  </TabPanel>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <Divider sx={{ m: 0, p: 0 }} />
          <DialogActions>
            {isEdit === 1 ? (
              <Box className='flex_end'>
                <Button
                  sx={{ mr: '1rem' }}
                  variant='outlined'
                  color='info'
                  size='small'
                  onClick={deleteMissionQna}
                >
                  삭제
                </Button>
                <Button
                  disabled={disabled}
                  variant='contained'
                  color='info'
                  size='small'
                  onClick={() => {
                    setIsEdit(2);
                  }}
                >
                  수정
                </Button>
              </Box>
            ) : isEdit === 2 ? (
              <Box className='flex_end'>
                <Button
                  disabled={disabledButton}
                  sx={{ mr: '1rem' }}
                  variant='outlined'
                  color='info'
                  size='small'
                  onClick={onClose}
                >
                  취소
                </Button>
                <Button
                  disabled={disabledButton}
                  variant='contained'
                  color='info'
                  size='small'
                  onClick={checkValid}
                >
                  수정완료
                </Button>
              </Box>
            ) : (
              <Box className='flex_end'>
                <Button
                  sx={{ mr: '1rem' }}
                  variant='outlined'
                  color='info'
                  size='small'
                  onClick={onClose}
                >
                  취소
                </Button>
                <Button
                  variant='contained'
                  color='info'
                  size='small'
                  onClick={checkValid}
                >
                  작성 완료
                </Button>
              </Box>
            )}
          </DialogActions>
          <SwSnackbar
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
            contents={dialogMessage}
          />
          <SwAlert
            open={false}
            onConfirm={() => setOpenDialog(false)}
            title={dialogTitle}
            contents={dialogMessage}
          />
          <SwConfirmDialog
            contents='질의응답 타입을 변경하실 경우, 작성한 질문이 모두 삭제됩니다. 그래도 변경하시겠습니까?'
            open={!!openQnaConfirm}
            onClose={() => setOpenQnaConfirm(0)}
            onConfirm={() => {
              changeQnaType(openQnaConfirm);
              setTabValue(openQnaConfirm === 1 ? 0 : 1);
              updateMissionQnaType(openQnaConfirm === 1 ? 1 : 2);
              setOpenQnaConfirm(0);
            }}
            confirm={1}
          />
        </div>
      </ClickAwayListener>
    </Dialog>
  );
};
