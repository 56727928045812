import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { DataportalConnect } from '../../commonComponent/DataportalConnect';
import {
  convertDistance,
  convertTime,
  generateUuidId,
} from '../../../common/helper';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  IStamptour,
  IStamptourDetail,
  IStamptourInfo,
  DefaultStamptourInfo,
  DefaultStamptourDetail,
} from '../../../models/stamptour';
import {
  getStamptourRequestList,
  getStamptourRequestDetail,
  registerStamptour,
  deleteStamptour,
} from '../../../common/api/ApiStamptour';
import { RESULT_OK } from '../../../common/resultCode';

const CreatePaper = styled(Paper)({
  margin: '2rem 0',
  padding: '1.5rem',
  borderRadius: '12px',
});

const ContentTitle = styled(Typography)({
  padding: '.5rem',
  marginBottom: '.5rem',
  fontWeight: 500,
});

interface Props {
  commandType: number;
  setCommandType: Dispatch<React.SetStateAction<number>>;
  mapInfo: any;
  stamptourDetail: IStamptourDetail;
  setStamptourDetail: Dispatch<React.SetStateAction<IStamptourDetail>>;
  onReload: () => void;
}

export const StampTourForm: React.FC<Props> = ({
  commandType,
  setCommandType,
  mapInfo,
  stamptourDetail,
  setStamptourDetail,
  onReload,
}) => {
  const [stamptourId, setStamptourId] = useState<string>('');
  const [localStamptourDetail, setLocalStamptourDetail] = useState<any>();
  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<any>({
    id: '',
    name: '',
  });
  const [title, setTitle] = useState<string>('');
  const [courseCenterPointX, setCourseCenterPointX] = useState<number>(0);
  const [courseCenterPointY, setCourseCenterPointY] = useState<number>(0);
  const [courseCenterRadius, setCourseCenterRadius] = useState<number>(0);
  const [creator, setCreator] = useState<string>('');
  const [editor, setEditor] = useState<string>('');
  const [stampTourImgpath, setStampTourImgpath] = useState<string>('');
  const [createTime, setCreateTime] = useState<number>(0);
  const [content, setContent] = useState<string>('');
  const [show, setShow] = useState<number>(1);
  const [stamptourTags, setStamptourTags] = useState<any>(['', '', '']);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { mutate: registerStamptourMutation, isError: isStamptourMutateError } =
    useMutation(registerStamptour, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('스탬프 코스가 저장되었습니다.');
        onReload();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '스탬프 코스를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: deleteStamptourMutation,
    isError: isDeleteStamptourMutateError,
  } = useMutation(deleteStamptour, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('스탬프 코스가 삭제되었습니다.');
        onReload();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `스탬프 코스를 삭제하는 동안 오류가 발생했습니다. (${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '스탬프 코스를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });
  const deleteStamptourInfo = () => {
    const newData: IStamptour = {
      stampTourId: stamptourId,
      stampTourName: '',
      courseCenterPointLng: 0,
      courseCenterPointLat: 0,
      courseCenterRadius: 0,
      creator: '',
      editor: '',
      stampTourImgpath: '',
      stamps: [],
      stampTourContent: '',
      stampTourShow: 1,
      stampTourTags: [],
      createTime: 0,
    };
    deleteStamptourMutation(newData);
  };

  const registerStamptourInfo = () => {
    console.log('register mapInfo :', mapInfo);
    const newStampList = mapInfo.stamps?.map((item: any, idx: number) => {
      // const newDescription = item.description.map((desc: any) => {
      //   const newData: any = {
      //     idx: desc.idx,
      //     description: desc.description,
      //     image: desc.image,
      //   };
      //   return newData;
      // });
      const newStamp = {
        stampId:
          item.id === undefined || item.id === ''
            ? generateUuidId()
            : stamptourDetail.stamptour.stampTourId !== stamptourId
            ? generateUuidId()
            : item.id,
        stampPointLat: item.point[0],
        stampPointLng: item.point[1],
        stampRadius: item.radius,
        stampName: item.name,
        stampSeq: idx + 1,
        stampDescription: '',
        description: item.description,
        className: item.className,
        link: item.link,
        phoneNo: item.phoneNo,
        address: item.address,
        stampAddress: item.stampAddress,
        stampDel: 0,
      };
      return newStamp;
    });
    const newStampName = newStampList.filter(
      (item: any) => item.stampName === '' || item.stampName === undefined
    );
    const newCourseCenterPointLng = mapInfo.circle?.lng
      ? mapInfo.circle?.lng
      : courseCenterPointX;
    const newCourseCenterPointLat = mapInfo.circle?.lat
      ? mapInfo.circle?.lat
      : courseCenterPointY;
    const newCourseCenterRadius = mapInfo.circle?.radius
      ? mapInfo.circle?.radius
      : courseCenterRadius;
    const newCreator =
      connectDataportal !== undefined &&
      connectDataportal !== null &&
      connectDataportal.portalId !== undefined &&
      connectDataportal.portalId !== null
        ? connectDataportal.portalId
        : 'ADMIN';
    const newTags = stamptourTags.filter((_val: any) => _val !== '');
    const newData: IStamptour = {
      stampTourId: stamptourId,
      stampTourName: title,
      courseCenterPointLng: newCourseCenterPointLng,
      courseCenterPointLat: newCourseCenterPointLat,
      courseCenterRadius: newCourseCenterRadius,
      creator: newCreator,
      editor: editor,
      stampTourImgpath:
        stampTourImgpath === null || stampTourImgpath === ''
          ? '/v2/walkcourse/map_img.jpg'
          : stampTourImgpath,
      stamps: newStampList,
      stampTourContent: content,
      stampTourShow: show,
      stampTourTags: newTags,
      createTime:
        createTime !== 0 ? createTime : new Date(Date.now()).getTime() / 1000,
    };
    console.log('register stamptour :', newData);

    var isValid = 0;
    if (isValid === 0 && title.length === 0) {
      isValid = 1;
    }
    if (isValid === 0 && (newStampList === null || newStampList.length === 0)) {
      isValid = 2;
    }
    if (
      isValid === 0 &&
      (newCourseCenterPointLng === undefined ||
        newCourseCenterPointLng === 0 ||
        newCourseCenterPointLat === undefined ||
        newCourseCenterPointLat === 0 ||
        newCourseCenterRadius === undefined ||
        newCourseCenterRadius === null)
    ) {
      isValid = 3;
    }
    if (isValid === 0 && newStampName.length > 0) {
      isValid = 4;
    }
    // if (isValid === 0 && newCreator.length === 0) {
    //   isValid = 5;
    // }
    if (isValid === 0) {
      console.log('register stamptour :', newData);
      registerStamptourMutation(newData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '스탬프 코스 이름을 입력해주세요.';
          break;
        case 2:
          message = '스탬프 코스에 스탬프를 추가해주세요.';
          break;
        case 3:
          message = '위치 인식 반경을 지정해주세요.';
          break;
        case 4:
          message = '스탬프 이름을 지정해주세요.';
          break;
        case 5:
          message = '데이터 포탈을 연결해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenDialog(true);
      setDialogMessage(message);
    }
  };

  const deleteTag = (index: number) => {
    const newTag = stamptourTags.map((_val: any, i: number) =>
      i !== index ? _val : ''
    );
    setStamptourTags([...newTag]);
  };

  const onChangeTags = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newTag = evt.target.value;
    const tags = [...stamptourTags];
    tags[index] = newTag;
    setStamptourTags(tags);
  };

  useEffect(() => {
    if (commandType === 1) {
      console.log('StamptourForm register :', commandType);
      registerStamptourInfo();
    } else if (commandType === 2) {
      console.log('StamptourForm update :', commandType);
      registerStamptourInfo();
    } else if (commandType === 3) {
      console.log('StamptourForm delete :', commandType);
      deleteStamptourInfo();
    }
    setCommandType(0);
  }, [commandType]);

  useEffect(() => {
    console.log('StamptourForm stamptourDetail :', stamptourDetail);
    if (stamptourDetail && stamptourDetail.stamptour.stampTourId !== '') {
      console.log('StamptourForm stamptourDetail :', stamptourDetail);
      setLocalStamptourDetail(stamptourDetail);
      setTitle(stamptourDetail.stamptour.stampTourName);
      setStamptourId(stamptourDetail.stamptour.stampTourId);
      setCourseCenterPointX(stamptourDetail.stamptour.courseCenterPointX);
      setCourseCenterPointY(stamptourDetail.stamptour.courseCenterPointY);
      setCourseCenterRadius(stamptourDetail.stamptour.courseCenterRadius);
      const newCreator = stamptourDetail.stamptour.creator
        ? stamptourDetail.stamptour.creator
        : '';
      setCreator(newCreator);
      setFindDataportal('');
      setConnectDataportal({ portalId: newCreator, name: '' });
      setEditor(
        stamptourDetail.stamptour.editor ? stamptourDetail.stamptour.editor : ''
      );
      setStampTourImgpath(stamptourDetail.stamptour.stampTourImgpath);
      setContent(stamptourDetail.stamptour.stampTourContent);
      setCreateTime(stamptourDetail.stamptour.createTime);
      setShow(stamptourDetail.stamptour.stampTourShow);
      if (stamptourDetail.stamptour.stampTourTags?.length > 0) {
        const newTags = stamptourDetail.stamptour.stampTourTags
          .replaceAll('[', '')
          .replaceAll(']', '')
          .split(',');
        setStamptourTags(
          newTags.length === 1
            ? [newTags[0], '', '']
            : newTags.length === 2
            ? [newTags[0], newTags[1], '']
            : newTags
        );
      }
    } else {
      setCreateTime(0);
    }
  }, [stamptourDetail]);

  return (
    <>
      <CreatePaper>
        <Box>
          <ContentTitle>스탬프 코스 이름</ContentTitle>
          <TextField
            placeholder='스탬프 코스 이름을 50자 이내로 작성해 주세요.'
            value={title}
            onChange={evt => setTitle(evt.target.value.substring(0, 50))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>{title.length} / 50</Typography>
                </InputAdornment>
              ),
            }}
            sx={{ width: '70vw' }}
          />
          {/* <MenuItem value={1}>걸음수 </MenuItem> */}
          <Divider />
        </Box>
        <Box>
          <SwUuidCreate
            title='스탬프 코스'
            id={stamptourId}
            setId={setStamptourId}
          />
          <Divider />
        </Box>
        <Box>
          <ContentTitle>추천 코스 노출</ContentTitle>
          <TextField
            select
            sx={{ width: '10rem' }}
            value={show}
            onChange={evt => {
              setShow(Number(evt.target.value));
            }}
          >
            <MenuItem value={0}>노출 안함 </MenuItem>
            <MenuItem value={1}>노출</MenuItem>
          </TextField>
          <Divider />
        </Box>
        {/* <Box>
          <ContentTitle>내용</ContentTitle>
          <TextField
            fullWidth
            multiline
            rows={5}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 'auto',
              },
            }}
            onChange={evt => setContent(evt.target.value)}
            value={content}
          />
          <Divider />
        </Box> */}
        <Box>
          <ContentTitle>스탬프 코스 태그</ContentTitle>
          {stamptourTags?.map((tag: string, idx: number) => (
            <TextField
              key={idx.toString()}
              sx={{ mr: '1rem' }}
              placeholder='태그 입력'
              value={tag}
              onChange={evt => onChangeTags(evt, idx)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton size='small' onClick={() => deleteTag(idx)}>
                      <ClearIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ))}
          <Divider />
        </Box>
        <DataportalConnect
          findDataportal={findDataportal}
          setFindDataportal={setFindDataportal}
          connectDataportal={connectDataportal}
          setConnectDataportal={setConnectDataportal}
        />
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
