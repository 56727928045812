import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';

interface Props {}

export const Test11: React.FC<Props> = () => {
  const a = 1;
  // const [target, settarget] = useState<any>([]);

  // useEffect(() => {
  //   const q = [];
  //   for (let i = 0; i < 200; i += 1) {
  //     q.push(i);
  //   }
  //   settarget(q);
  // }, []);

  // const getItemSize = (index: number) => target && target[index];

  // const rowRenderer = ({ index, key }: any) => {
  //   const item = target && target[index];

  //   return (
  //     <Box key={key}>
  //       {item.communityId}
  //       {item.communityName}
  //     </Box>
  //   );
  // };

  const rowHeights = new Array(1000)
    .fill(true)
    .map(() => 25 + Math.round(Math.random() * 50));

  const getItemSize2 = (index: number) => rowHeights[index];

  // eslint-disable-next-line react/no-unstable-nested-components
  const Row = ({ index, style }: any) => <div style={style}>Row {index}</div>;

  return (
    <div className='root'>
      <Paper>
        {/* <AutoSizer disableHeight>
          {({ width }) => (
            <List width={100} height={50} itemCount={7} itemSize={getItemSize}>
              {target}
            </List>
          )}
        </AutoSizer> */}
        {/* <List height={150} itemCount={1000} itemSize={getItemSize2} width={300}> */}
        {/* {target && (
          <FixedSizeList
            height={150}
            itemCount={1000}
            itemSize={50}
            width={500}
          >
            {rowRenderer}
          </FixedSizeList>
        )} */}
        {/* )}
        </AutoSizer> */}
      </Paper>
      <div>dd</div>
      <div>dteted</div>
    </div>
  );
};
