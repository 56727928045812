import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwPagination } from '../commonComponent/tableElement/SwPagination';
import { getAccountLoginHistory } from '../../common/api/ApiAccount';
import { KEY_ACCOUNT_LOGIN_HISTORY } from '../../common/key';
import { SwDateRangePicker } from '../commonComponent/dateSetting/SwDateRangePicker';

interface Props {}

const columns = ['분류', '아이디', '일시', '로그인 IP', '기기'];

export const LoginHistory: React.FC<Props> = () => {
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [empList, setEmpList] = useState<any>([]);

  const {
    data,
    isLoading,
    refetch: refetchHistory,
  } = useQuery(
    KEY_ACCOUNT_LOGIN_HISTORY,
    () =>
      getAccountLoginHistory(
        page,
        rowsPerPage,
        rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        rangeDate[1] === null ? 0 : rangeDate[1].getTime()
      ),
    {
      onSuccess: res => {
        setEmpList(res.history);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_ACCOUNT_LOGIN_HISTORY, () =>
      getAccountLoginHistory(
        newPage,
        rowsPerPage,
        rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        rangeDate[1] === null ? 0 : rangeDate[1].getTime()
      )
    );
  };

  useEffect(() => {
    refetchHistory();
  }, [rangeDate]);

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  if (isLoading) return <div>isLoading...</div>;

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box className='flex_start'>
        <SwDateRangePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '2rem' }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {empList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  <TableCell key={row.na} sx={{ p: '.3rem .6rem' }}>
                    {row.type}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.id}</TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.timestamp}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.ip}</TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.device}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
    </Box>
  );
};
