import { Box, Button, Paper, Typography } from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import {
  getRegionCategory,
  generateUuidId,
  timestamp2Localestring,
  encodeSearchWord,
} from '../../../common/helper';
import {
  getGeoHashGroupList,
  registerRegionFilter,
  deleteRegionFilter,
} from '../../../common/api/ApiGeohash';
import { KEY_GEOHASH_GROUP_LIST } from '../../../common/key';
import { RegionFilterMap } from './RegionFilterMap';

const requestColumnlists = ['카테고리', '위치필터 이름', '작성자', '생성일시'];

const searchOption = [
  {
    value: 'name',
    title: '이름',
  },
  {
    value: 'category',
    title: '카테고리',
  },
  {
    value: 'creator',
    title: '작성자',
  },
];

interface Props {}

export const RegionalFilterInquire: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const [geohashGroupList, setGeohashGroupList] = useState<any>([]);
  const [geohashId, setGeohashId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [creator, setCreator] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [geohashIndex, setGeohashIndex] = useState<any>(0);
  const [requestList, setRequestList] = useState<any>([]);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [geohashList, setGeohashList] = useState<any>([]);
  const [geohashData, setGeohashData] = useState<any>([]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: refetchAmd,
  } = useQuery(
    KEY_GEOHASH_GROUP_LIST,
    () =>
      getGeoHashGroupList(searchKey, encodeSearchWord(searchKey, searchWord)),
    {
      onSuccess: res => {
        if (res != null) {
          console.log('res.geohash :', res.geohash);
          const newGeohashList = res.geohash.map((geohash: any) => {
            const newDate = timestamp2Localestring(geohash.createDate);

            const tmpCategory =
              geohash.name && geohash.name.length > 0
                ? geohash.name.match(/\[.*\]/)
                : '';
            const newCategory =
              tmpCategory && tmpCategory.length > 0
                ? tmpCategory.toString().replaceAll('[', '').replaceAll(']', '')
                : '';
            const newData = [
              geohash.category !== null ? geohash.category : newCategory,
              geohash.name,
              geohash.creator,
              newDate,
            ];
            return newData;
          });
          setRequestList(newGeohashList);
          setGeohashGroupList(res.geohash);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const geohashPrefetch = (newSearchKey: string, newSearchWord: string) => {
    queryClient.prefetchQuery(KEY_GEOHASH_GROUP_LIST, () =>
      getGeoHashGroupList(
        newSearchKey,
        encodeSearchWord(searchKey, newSearchWord)
      )
    );
  };

  const { mutate: registerRegionFilterMutation, isError: isRegionMutateError } =
    useMutation(registerRegionFilter, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('위치필터가 저장되었습니다.');
        geohashPrefetch(searchKey, searchWord);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '위치필터를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: deleteRegionFilterMutation,
    isError: isDeleteRegionMutateError,
  } = useMutation(deleteRegionFilter, {
    onSuccess: () => {
      setOpenSnakerDialog(true);
      setDialogMessage('위치필터가 삭제되었습니다.');
      geohashPrefetch(searchKey, searchWord);
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '위치필터를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const registerRegion = () => {
    const newData = {
      geoHashId: geohashId,
      name: title,
      geoHashGroup: JSON.stringify(geohashList),
      creator: creator,
      category: category,
    };
    console.log('register region filter :', newData);

    var isValid = 0;
    if (isValid === 0 && title.length === 0) {
      isValid = 1;
    }
    if (isValid === 0 && geohashList.length === 0) {
      isValid = 2;
    }
    if (isValid === 0) {
      registerRegionFilterMutation(newData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '위치필터 이름을 입력해 주세요';
          break;
        case 2:
          message = '위치을 추가해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenDialog(true);
      setDialogMessage(message);
    }
  };

  const deleteRegion = () => {
    const newData = {
      geoHashId: geohashId,
      name: '',
      geoHashGroup: '',
      creator: creator,
      category: '',
    };
    console.log('deleteRegion filter :', newData);

    var isValid = 0;
    if (isValid === 0 && geohashId.length === 0) {
      isValid = 1;
    }
    if (isValid === 0) {
      deleteRegionFilterMutation(newData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '위치 필터를 선택해 주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenDialog(true);
      setDialogMessage(message);
    }
  };

  const searching = () => {
    if (searchRef.current.value && selectRef.current.value) {
      setSearchKey(selectRef.current.value);
      setSearchWord(searchRef.current.value);
    } else {
      setSearchKey('');
      setSearchWord('');
    }
  };

  useEffect(() => {
    console.log('searchKey :', searchKey, ', searchWord:', searchWord);
    geohashPrefetch(searchKey, searchWord);
  }, [searchKey, searchWord]);

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const chosen = requestList[i];
    console.log('chose :', chosen);
    const newChosen = [chosen[0], chosen[1], chosen[2], chosen[4]];

    setChosenRequest([newChosen]);

    const newGeohashData = JSON.parse(geohashGroupList[i].geoHashGroup);
    setGeohashData([newGeohashData]);
    setTitle(geohashGroupList[i].name);
    setGeohashId(geohashGroupList[i].geoHashId);
    setCreator(geohashGroupList[i].creator);
    setCategory(geohashGroupList[i].category);
  };

  return (
    <Box sx={{ m: '2rem', p: '1rem' }}>
      <Paper
        sx={{
          display: 'inline-block',
          borderRadius: '12px',
          boxShadow: 'none',
        }}
      >
        <SelectSearchBox
          searchOption={searchOption}
          optionValue={selectRef}
          searchWord={searchRef}
          defaultOption='name'
          isSearching={searching}
          placeholderString='위치 필터 이름으로 검색해 주세요.'
        />
      </Paper>
      <Paper sx={{ m: '1.4rem 0' }}>
        <Box sx={{ mb: '1rem' }}>
          <RequestTable
            columns={requestColumnlists}
            rows={requestList}
            onclick={chooseRequest}
          />
        </Box>
      </Paper>

      <Paper>
        <RegionFilterMap
          geohashData={geohashData}
          setGeohashData={setGeohashData}
          setTargetGeohashList={setGeohashList}
          elementId='map-region'
        />
      </Paper>

      <Box className='flex_end' sx={{ m: '2rem 0' }}>
        <Box>
          <Button
            variant='outlined'
            color='info'
            sx={{ mr: '1rem' }}
            onClick={() => deleteRegion()}
          >
            삭제
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => registerRegion()}
          >
            저장
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};
