import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { QuestionAnswerForm } from './QuestionAnswerForm';
import { SwDateRangePicker } from '../../../commonComponent/dateSetting/SwDateRangePicker';
import { SelectSearchBox } from '../../../commonComponent/SelectSearchBox';
import { SwPagination } from '../../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../../commonComponent/views/SwConfirmDialog';
import SwLoading from '../../../commonComponent/spinner/SwLoading';
import { ButtonBox, ContentTitle, MarginButton } from './QuestionAnswerStyles';
import {
  changeQuestionAnswerApplyStatus,
  cloneQuestionAnswerMaster,
  cloneQuestionAnswerMission,
  confirmQuestionAnswerRegister,
  getQuestionAnswerList,
  getQuestionAnswerRequestList,
} from '../../../../common/api/ApiQuestionAnswer';
import {
  KEY_PROGRAM_LIST,
  KEY_QUESTION_ANSWER_LIST,
  KEY_QUESTION_ANSWER_REQUEST_LIST,
} from '../../../../common/key';
import {
  WhiteTextField,
  SwSwitch,
  CreateTabs,
  CreateTab,
} from '../../../styles/Styles';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../../common/helper';
import {
  RESULT_EMPTY_QUESTION,
  RESULT_EMPTY_REWARD,
  RESULT_OK,
} from '../../../../common/resultCode';
import { QuestionAnswerReward } from './QuestionAnswerReward';
import { getQuestionAnswerRegisterStatus } from '../../../../common/helperQuestionAnswer';
import { QuestionAnswerOpenRegion } from './QuestionAnswerOpenRegion';
import { QuestionAnswerQuestion } from './QuestionAnswerQuestion';
import { SwCreationRequestReject } from '../../../commonComponent/SwCreationRequestReject';
import { SwQuestionAnswerFilter } from '../../../commonComponent/program/SwQuestionAnswerFilter';
import { brandPointAdvertiserState } from '../../../../common/atom';

interface Props {}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const requestColumnlists = [
  '질의응답 제목',
  '질의응답 아이디',
  '타입',
  '신청일자',
  '고객명',
  '담당자',
  '상태',
];

const rewardColumns = [
  '순서',
  '리워드 이름',
  '수량',
  '지급 방법',
  '사용 방법',
  '상태',
];

export const QuestionAnswerCreate: React.FC<Props> = () => {
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const [curriculumId, setCurriculumId] = useState<string>('');
  const [qnaId, setQnaId] = useState<string>('');
  const [questionAnswerList, setQuestionAnswerList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);
  const [tabValue, setTabValue] = useState(0);
  const [rewardDrawUserList, setRewardDrawUserList] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSwitch, setOpenSwitch] = useState<boolean>(true);
  const [closeSwitch, setCloseSwitch] = useState<boolean>(true);
  const [questionAnswerFilterInfo, setQuestionAnswerFilterInfo] =
    useState<any>();
  const [filter, setFilter] = useState<any>(null);
  const [questionAnswerInfo, setQuestionAnswerInfo] = useState<any>();
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [open, setOpen] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [selectedId, setSelectedId] = useState<any>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<number>(0);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  let changeAlertMessage = '';

  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 3 && questionAnswerInfo.hasReward === 0) {
      setAlertOpen(true);
      setAlertMessage(
        '질문 탭에서 리워드 발급을 선택하셔야 리워드 등록이 가능합니다.'
      );
      return;
    }

    if (newValue !== tabValue) setTabValue(newValue);
  };
  const {
    data,
    isLoading,
    refetch: questionAnswerRefetch,
  } = useQuery(
    KEY_QUESTION_ANSWER_REQUEST_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      const newDate = new Date().getTime() / 1000;
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newRequest = {
        status: status,
        startDate: filter !== null ? filter.rangeDate[0] : 0,
        endDate: filter !== null ? filter.rangeDate[1] : 0,
        hasReward: filter !== null ? filter.hasReward : 0,
        type: filter !== null ? filter.type : 0,
        page: 0,
        rowsPerPage: 0,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        operator: '',
      };
      if (checkRequest) return getQuestionAnswerRequestList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('requestList:', res);
        const newQuestionAnswer =
          res &&
          res.creationRequest !== undefined &&
          res.creationRequest !== null &&
          res.creationRequest.qna.map((item: any, idx: number) => {
            const currentDate = new Date();
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const status = getQuestionAnswerRegisterStatus(item.registerStatus);
            const newAttendStime = timestamp2Localestring(
              item.attendStartDate,
              1000
            ).split(' ')[0];
            const newAttendEtime = timestamp2Localestring(
              item.attendEndDate,
              1000
            ).split(' ')[0];
            const newData = [
              item.title,
              item.qnaId,
              item.type === 1 ? '설문조사' : '퀴즈',
              newDate,
              item.customerName,
              item.operatorName,
              status,
            ];
            if (qnaId === item.qnaId) {
              setSelectedRow(idx);
              setQuestionAnswerInfo(item);
            }
            return newData;
          });
        setRequestList(newQuestionAnswer);
        setQuestionAnswerList(res ? res.creationRequest.qna : []);
        setRewardGivetype(res ? res.creationRequest.rewardGivetype : null);
        setRewardUsetype(res ? res.creationRequest.rewardUsetype : null);
        setBrandPointAdvertiserList(
          res ? res.creationRequest.advertiser : null
        );
        setTotalRecords(res.pagination.totalRecordCount);

        const newFilterInfo = {
          giveType: res ? res.creationRequest.rewardGivetype : null,
          useType: res ? res.creationRequest.rewardUsetype : null,
        };
        setQuestionAnswerFilterInfo(newFilterInfo);

        if (selectedId !== '') {
          const newSelect = res.creationRequest.qna.filter(
            (item: any) => item.qnaId === selectedId
          );
          console.log(
            'reloadQuestionAnswer: ',
            selectedId,
            ', info:',
            newSelect
          );
          setQuestionAnswerInfo(newSelect.length > 0 ? newSelect[0] : null);
        }
        setSelectedId('');
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_QUESTION_ANSWER_REQUEST_LIST, () => {
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newData = {
        status: status,
        startDate: filter !== null ? filter.rangeDate[0] : 0,
        endDate: filter !== null ? filter.rangeDate[1] : 0,
        hasReward: filter !== null ? filter.hasReward : 0,
        type: filter !== null ? filter.type : 0,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        operator: '',
      };
      return getQuestionAnswerRequestList(newData);
    });
  };

  const {
    mutate: confirmQuestionAnswerRegisterMutation,
    isError: isMutateError,
  } = useMutation(confirmQuestionAnswerRegister, {
    onSuccess: res => {
      console.log('confirmQuestionAnswerRegister res:', res);
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('저장되었습니다.');
        questionAnswerRefetch();
      } else if (res.resultCode === RESULT_EMPTY_REWARD) {
        setAlertOpen(true);
        setAlertMessage('리워드를 등록해 주세요.');
      } else if (res.resultCode === RESULT_EMPTY_QUESTION) {
        setAlertOpen(true);
        setAlertMessage('질문을 작성해 주세요');
      } else {
        setAlertOpen(true);
        setAlertMessage('질의응답을 생성하는동안 오류가 발생했습니다');
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '질의응답을 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: changeQuestionAnswerApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeQuestionAnswerApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage(changeAlertMessage);
      questionAnswerRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        `요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.`
      );
    },
  });

  const {
    mutate: cloneQuestionAnswerMasterMutation,
    isError: isMasterMutateError,
  } = useMutation(cloneQuestionAnswerMaster, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('복제 완료, 작성중 내역에서 확인하세요.');
        questionAnswerRefetch();
      } else {
        setOpenSnackbar(true);
        setAlertMessage(
          `질의응답을 복제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        `질의응답을 복제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const {
    mutate: cloneQuestionAnswerMissionMutation,
    isError: isMissionMutateError,
  } = useMutation(cloneQuestionAnswerMission, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('복제 완료, 작성중 내역에서 확인하세요.');
        questionAnswerRefetch();
      } else {
        setOpenSnackbar(true);
        setAlertMessage(
          `질의응답을 복제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        `질의응답을 복제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const searchQuestionAnswer = () => {
    setFilter(null);
    setPage(1);
    prefetch(1);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setSelectedRow(-1);
    setPage(newPage);
    prefetch(newPage);
  };

  const confirmQuestionAnswer = (testType: number) => {
    const newData: any = {
      qnaId: qnaId,
      testType: testType,
    };
    // if (
    //   (questionAnswerInfo.rewardCount === 0 || questionAnswerInfo.rewardCount === null) &&
    //   rewardCount === 0 &&
    //   questionAnswerInfo.questionAnswerType !== 5
    // ) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('리워드 정보를 입력해주세요');
    //   return;
    // }
    confirmQuestionAnswerRegisterMutation(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchQuestionAnswer();
    }
  };

  const onDownloadQuestionAnswerList = () => {
    const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
    const newRequest = {
      status: status,
      startDate: filter !== null ? filter.rangeDate[0] : 0,
      endDate: filter !== null ? filter.rangeDate[1] : 0,
      page: page,
      rowsPerPage: rowsPerPage,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
    };
    // downloadQuestionAnswerList(newRequest);
  };

  const onRejectMessage = (message: string) => {
    if (message.length > 0) {
      const newData: any = {
        qnaId: qnaId,
        status: 3, // 1-작성중 2-접수요청 3-반려 4-접수완료
        message: message,
      };
      changeAlertMessage = '질의응답이 수정되었습니다.';
      changeQuestionAnswerApplyStatusMutation(newData);
    }
  };

  const deleteQuestionAnswer = () => {
    const newData: any = {
      qnaId: qnaId,
      status: 9, // 1-작성중 2-접수요청 3-반려 4-접수완료 9-삭제 (어드민 only)
      message: '',
    };
    changeAlertMessage = '질의응답이 삭제되었습니다.';
    changeQuestionAnswerApplyStatusMutation(newData);
  };

  const checkFilter = (testType: number) => {
    const newFilters = JSON.parse(questionAnswerInfo.questionAnswerFilters);
    console.log('newFilters :', newFilters);
    const newFilterList =
      newFilters &&
      newFilters.map((item: any) => {
        const newData = {
          filterType: item.FILTER_TYPE,
          challengeId: item.CHALLENGE_ID,
          targetId: item.TARGET_ID,
          communityName: item.NAME,
        };
        return newData;
      });

    if (newFilterList === undefined || newFilterList.length === 0) {
      setOpenFilter(true);
    } else {
      confirmQuestionAnswer(testType);
    }
  };

  useEffect(() => {
    console.log('openSwitch :', openSwitch, ', closeSwitch :', closeSwitch);
    prefetch(1);
  }, [openSwitch, closeSwitch]);

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    setSelectedRow(i);
    const newChosen = [Chosen[2], Chosen[1], Chosen[3]];
    setChosenRequest([newChosen]);
    setQnaId(questionAnswerList[i].qnaId);
    setQuestionAnswerInfo(questionAnswerList[i]);
    setCurriculumId(questionAnswerList[i].curriculumId);
  };

  const saveRewardCount = (count: number) => {
    setRewardCount(count);
    if (count !== questionAnswerInfo.rewardCount) {
      questionAnswerRefetch();
    }
  };

  const reloadQuestionAnswer = (id: string) => {
    if (id !== '') {
      setQnaId(id);
      setSelectedId(id);
      questionAnswerRefetch();
    }
  };

  const searching = () => {
    setPage(1);
    if (searchRef.current.value && selectRef.current.value) {
      if (
        searchRef.current.value === searchWord &&
        selectRef.current.value === searchKey
      ) {
        questionAnswerRefetch();
      } else {
        setSearchKey(selectRef.current.value);
        setSearchWord(searchRef.current.value);
      }
    } else if (searchWord === '' && searchKey === '') {
      questionAnswerRefetch();
    } else {
      setSearchKey(selectRef.current.value);
      setSearchWord('');
    }
  };

  const cloneQuestionAnswer = () => {
    const newData: any = {
      qnaId: qnaId,
    };

    cloneQuestionAnswerMasterMutation(newData);
  };

  const cloneQuestionAnswerMissionItem = () => {
    const newData: any = {
      qnaId: qnaId,
    };

    cloneQuestionAnswerMissionMutation(newData);
  };

  useEffect(() => {
    console.log('searchKey :', searchKey, ', searchWord:', searchWord);
    questionAnswerRefetch();
  }, [searchKey, searchWord]);

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      questionAnswerRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    if (filter !== null && filter !== undefined) {
      console.log('filter :', filter);
      setPage(1);
      prefetch(1);
    }
  }, [filter]);

  useEffect(() => {
    questionAnswerRefetch();
  }, [rowsPerPage]);

  useEffect(() => {
    questionAnswerRefetch();
  }, [rangeDate]);

  return (
    <>
      <Box className='flex_start' sx={{ m: '1.5rem 0' }}>
        <Box>
          <TextField
            placeholder='질의응답 아이디, 질의응답 제목, 고객명 검색'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ pr: 0 }} onClick={searchQuestionAnswer}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ mr: '2rem' }} className='flex_between'>
          <Box className='flex'>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
          {/* <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={() => onDownloadQuestionAnswerList()}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button> */}
        </Box>
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '1.5rem' }}>
        <Box className='flex_between'>
          <Box>
            <Typography>
              질의응답 <span className='bluecontent'>{totalRecords}</span> 개
            </Typography>
          </Box>
          <Box className='flex_between'>
            <Typography sx={{ mr: '.4rem' }}>Open</Typography>
            <SwSwitch
              defaultChecked={true}
              value={openSwitch}
              onChange={() => setOpenSwitch(!openSwitch)}
            />
            <Typography sx={{ ml: '1rem', mr: '.4rem' }}>Close</Typography>
            <SwSwitch
              defaultChecked={true}
              value={closeSwitch}
              onChange={() => setCloseSwitch(!closeSwitch)}
            />
            {/* <TextField
              select
              value={rowsPerPage}
              defaultValue={10}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
              }}
              sx={{
                ml: '1rem',
                mb: 0,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  height: '2rem',
                },
              }}
            >
              <MenuItem value={5}>5개씩 보기</MenuItem>
              <MenuItem value={10}>10개씩 보기</MenuItem>
              <MenuItem value={15}>15개씩 보기</MenuItem>
              <MenuItem value={20}>20개씩 보기</MenuItem>
            </TextField> */}
          </Box>
        </Box>
        <TableContainer
          sx={{ p: '0rem', mt: '1rem', height: '30vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {requestColumnlists.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  {row.map((rowItem: any, rowIdx: number) => (
                    <TableCell
                      key={rowIdx.toString()}
                      className={
                        selectedRow === idx &&
                        questionAnswerList[idx].status === 0
                          ? clsx(
                              'request_tablecell selected_row not_important_row'
                            )
                          : selectedRow === idx && row[6] === '접수요청'
                          ? clsx('request_tablecell selected_row specified_row')
                          : selectedRow === idx
                          ? clsx('request_tablecell selected_row')
                          : questionAnswerList[idx].status === 0
                          ? clsx('request_tablecell not_important_row')
                          : row[6] === '접수요청'
                          ? clsx('request_tablecell specified_row')
                          : clsx('request_tablecell ')
                      }
                      sx={{ maxWidth: '25rem' }}
                    >
                      {rowIdx !== 1
                        ? row[rowIdx]
                        : questionAnswerList[idx].status === 0
                        ? `${row[rowIdx]} (close)`
                        : row[rowIdx]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        /> */}
      </Paper>
      <Paper sx={{ p: '0rem 0' }}>
        <Box sx={{ background: '#F5F7FA' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <CreateTabs value={tabValue} onChange={onChangeTab}>
              <CreateTab label='질의응답 정보' />
              <CreateTab label='공개범위(필터)' />
              <CreateTab label='질문' />
              <CreateTab label='리워드 정보' />
            </CreateTabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <QuestionAnswerForm
              questionAnswerInfo={questionAnswerInfo}
              setQuestionAnswerInfo={setQuestionAnswerInfo}
              reloadQuestionAnswer={reloadQuestionAnswer}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <QuestionAnswerOpenRegion
              questionAnswerInfo={questionAnswerInfo}
              setQuestionAnswerInfo={setQuestionAnswerInfo}
              reloadQuestionAnswer={reloadQuestionAnswer}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <QuestionAnswerQuestion
              questionAnswerInfo={questionAnswerInfo}
              setQuestionAnswerInfo={setQuestionAnswerInfo}
              reloadQuestionAnswer={reloadQuestionAnswer}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <QuestionAnswerReward
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              questionAnswerInfo={questionAnswerInfo}
              setQuestionAnswerInfo={setQuestionAnswerInfo}
              saveRewardCount={saveRewardCount}
              reloadQuestionAnswer={reloadQuestionAnswer}
            />
          </TabPanel>
        </Box>
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box>
            {/* <MarginButton
              variant='contained'
              color='info'
              onClick={() => cloneQuestionAnswerMissionItem()}
            >
              질의응답 미션 복제
            </MarginButton> */}
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => {
                window.location.reload();
              }}
            >
              신규작성
            </MarginButton>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => cloneQuestionAnswer()}
            >
              질의응답 복제
            </MarginButton>
          </Box>
          <Box>
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => setRejectOpen(true)}
            >
              수정 요청
            </MarginButton>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => confirmQuestionAnswer(1)}
            >
              승인
            </MarginButton>
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwQuestionAnswerFilter
        openType='create'
        open={openFilter}
        onClose={() => setOpenFilter(!openFilter)}
        filter={filter}
        setFilter={setFilter}
      />
      <SwCreationRequestReject
        title='질의응답'
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onRejectMessage={onRejectMessage}
      />
      <SwConfirmDialog
        contents='작성중인 질의응답을 삭제하시겠습니까?'
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false);
          deleteQuestionAnswer();
        }}
        confirm={1}
      />
      {loading && <SwLoading />}
    </>
  );
};
