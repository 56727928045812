import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSession } from '../../../hooks/session';
import { DefaultLoginUser } from '../../../models/emp';
import { Login } from '../../login/Index';

const withAuthentication = (WrappedPage: FC) => {
  const RequiresAuthentication = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { loginVal, setLoginVal, session, signAction } = useSession();
    useEffect(() => {
      if (loginVal.expire < new Date()) {
        setLoginVal(DefaultLoginUser);
        navigate('/login');
      }
    }, [location.pathname]);

    if (!loginVal.value?.didLogin) return <Login />;

    return <WrappedPage />;
  };

  return RequiresAuthentication;
};

export default withAuthentication;
