export interface IWalkwayCommand {
  localId: string;
  localName?: string;
  walkwayId: string;
  walkwayName?: string;
  reset: boolean;
  isSend: number;
}

export interface IContent {
  contentId: string;
  imagePath: string;
  bgImages: string[];
  routeImagePath: string;
  tags: string[];
}

export interface IWalkwayInfo {
  walkWayId: string;
  localId: string;
  title: string;
  la: number;
  lo: number;
  location: string;
  route: string;
  distance: number;
  expectedMins: number;
  estimatedSteps: number;
  recommended: number;
  hidden: number;
  geohash: string;
  walkCourseId: string;
}

export interface IWalkwayPointInfo {
  walkWayId: string;
  pointId: string;
  title: string;
  alias: string;
  la: number;
  lo: number;
  location: string;
  hidden: number;
}

export interface IPointContent {
  contentId: string;
  pointId: string;
  imagePath: string;
  storyImages: string[];
  description: string;
}

export interface IWalkwayPointCommand {
  walkwayId: string;
  pointId: string;
  title: string;
  description: string;
  mainImage: string;
  subImages: string[];
  location: string;
  la: number;
  lo: number;
  contentId: string;
  alias: string;
  open: number;
  urlPrefix: string;
}

export interface IWalkway {
  walkWay: IWalkwayInfo;
  content: IContent;
}

export interface IWalkwayPoint {
  point: IWalkwayPointInfo;
  content: IPointContent;
}

export interface IWalkwayLocal {
  localId: string;
}

export interface IWalkwayLocalModel {
  localId: string;
}

export const DefaultWalkwayLocal: IWalkwayLocal = {
  localId: '',
};

export const DefaultalkwayPointCommand: IWalkwayPointCommand = {
  walkwayId: '',
  pointId: '',
  title: '',
  description: '',
  mainImage: '',
  subImages: [],
  location: '',
  la: 0,
  lo: 0,
  contentId: '',
  alias: '',
  open: 0,
  urlPrefix: '',
};
