import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IGuideTool } from '../../models/challenge';
import { xapi } from '../../hooks/session';
import { IEmp } from '../../models/emp';
import { throwError } from '../helper';

/*
 * Account
 */

export const getAccountList = (
  page: number,
  rowsPerPage: number,
  searchWord: string
) =>
  xapi
    .get(
      `/admin/v2/apis/account?page=${page}&per_page=${rowsPerPage}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postAccountRegister = (data: IEmp) =>
  xapi
    .post('/admin/v2/apis/account', {
      id: data.id,
      password: data.password,
      name: data.name,
      phoneno: data.phoneNumber,
      role: data.role,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getAccountRoleList = () =>
  xapi.get('/admin/v2/apis/account/role').then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data.role;
  });

export const patchAccountRole = (data: any) =>
  xapi.put(`/admin/v2/apis/account/role`, {
    ids: data.ids,
    role: data.role,
  });

export const patchAccountObserver = (data: any) =>
  xapi.put(`/admin/v2/apis/account/observer`, {
    ids: data.ids,
    observerType: data.observerType,
  });

export const deleteAccount = (ids: string[]) =>
  xapi
    .delete('/admin/v2/apis/account', {
      data: { ids: ids },
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const patchAccountPassword = (data: IEmp) =>
  xapi.put(`/admin/v2/apis/account/${data.id}/password`, {
    id: data.id,
    password: data.password,
  });

export const getAccountLoginHistory = (
  page: number,
  rowsPerPage: number,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/account/login/history?page=${page}&per_page=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
