import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { PartnerInformation } from './PartnerInformation';

interface Props {}

export const PartnerManagement: React.FC<Props> = () => {
  const { isMobile } = useResize();
  return (
    <div
      className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}
      style={{ padding: '3rem 2rem' }}
    >
      <Helmet>
        <title>거래처 관리</title>
      </Helmet>
      <PartnerInformation />
    </div>
  );
};
