import {
  Box,
  Button,
  Collapse,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import {
  getUserDailyStepList,
  downloadUserDailyStepList,
  patchUserChangeStep,
  getUserWearableList,
  downloadUserWearableList,
} from '../../../common/api/ApiUser';
import {
  KEY_USER_DAILY_STEP_LIST,
  KEY_USER_WEARABLE_LIST,
} from '../../../common/key';
import { SwUserChangeStep } from '../../commonComponent/SwUserChangeStep';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { timestamp2Localestring } from '../../../common/helper';

export function getWearableDeviceName(mode: number, model: string) {
  /*
   * if isIphone
     model = newModel.split(')');
     hk = model[1].split(',');
     hkDeviceClass = String(hk[0]).trim();
     sensorActive = String(hk[1]).trim() === '0' ? '비활성화' : '활성화'
     hkActive = String(hk[2]).trim() === '0' ? '비활성화' : '활성화'
   * '센서활성화(동작 및 피트니스)' -> sensorActive ?
   * '헬스킷활성화(건강앱)' -> hkActive ?
   * '애플워치(이전 버전)' -> hkDeviceClass === '0' || hkDeviceClass === '4' ? '연동' : '-'
   * '건강앱(애플워치)' -> hkDeviceClass === '5' ? '연동' : '-'
   * '건강앱(Zepp Life)' -> hkDeviceClass === '6' ? '연동' : '-'
   * '건강앱(Very Fit)' -> hkDeviceClass === '7' ? '연동' : '-'
   */

  let deviceName = '';
  const type = model.includes('iPhone') ? 'ios' : 'android';

  if (type === 'android') {
    switch (mode) {
      case 1:
        deviceName = '삼성헬스(예전버전)';
        break;
      case 2:
        deviceName = '인랩워치';
        break;
      case 3:
        deviceName = '삼성헬스(최신버전)';
        break;
      case 4:
        deviceName = '구글피트니스';
        break;
      case 10:
        deviceName = '휴대폰걸음(가속도센서)';
        break;
      case 11:
        deviceName = '휴대폰걸음(스텝센서)';
        break;
      default:
        deviceName = '미지정';
    }
  } else {
    const newModel = model.split('(');
    const hk = newModel[1].split(')');
    const hkStr = String(hk[0]).trim();
    const hkInfo = JSON.parse(hkStr);
    const connected =
      hkInfo.connected === undefined || hkInfo.connected === null
        ? '-'
        : hkInfo.connected === true
        ? 'true'
        : 'false';
    console.log('hkInfo :', hkInfo);
    deviceName =
      Object.keys(hkInfo).length === 0
        ? '미지정'
        : Object.keys(hkInfo)
            .sort()
            .filter(item => item !== 'connected')
            .map(item => {
              console.log('item:', item);
              const name =
                item === 'iPhone'
                  ? '핸드폰'
                  : item === 'Apple Watch'
                  ? `애플워치(${connected})`
                  : item;
              return name;
            })
            .join(',');
  }
  console.log('deviceName :', deviceName);
  return deviceName;
}

interface Props {}

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.9rem 2rem',
});
const PaginationBox = styled(Box)({
  padding: '.6rem',
  display: 'flex',
  justifyContent: 'center',
});

const columns = ['접속 일시', '측정모드', '타입', '앱버전'];

export const UserWearable: React.FC<Props> = () => {
  const location = useLocation();
  const [userData, setUserData] = useState<any>(location.state);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [challengeId, setChallengeId] = useState<string>('');
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [wearableList, setWearableList] = useState<any>([]);
  const [openChangeStep, setOpenChangeStep] = useState<any>(false);
  const [userStepIndex, setUserStepIndex] = useState<any>(-1);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: wearableRefetch,
  } = useQuery(
    KEY_USER_WEARABLE_LIST,
    () => {
      if (userData.id !== '') {
        return getUserWearableList(
          userData.id,
          page,
          rowsPerPage,
          rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
          rangeDate[1] === null ? 0 : rangeDate[1].getTime()
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          const newWearable =
            res.userWearable &&
            res.userWearable.map((item: any, idx: number) => ({
              device: getWearableDeviceName(item.mode, item.model),
              os: item.model.includes('iPhone') ? 'IOS' : 'AOS',
              insertDate: timestamp2Localestring(item.insertDate, 1000),
              version: item.version,
            }));
          setWearableList(newWearable);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_USER_WEARABLE_LIST, () =>
      getUserWearableList(
        userData.id,
        newPage,
        rowsPerPage,
        rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        rangeDate[1] === null ? 0 : rangeDate[1].getTime()
      )
    );
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      prefetch(page);
    }
  };

  useEffect(() => {
    console.log('userData :', userData);
    if (userData.id !== '') wearableRefetch();
  }, [userData]);

  useEffect(() => {
    console.log('user wearable :', location.state);
    if (location.state) {
      setUserData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (rangeDate[0] !== null && rangeDate[1] !== null) wearableRefetch();
  }, [rangeDate[0], rangeDate[1]]);

  const onDownloadFile = () => {
    downloadUserWearableList(
      userData.id,
      rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      rangeDate[1] === null ? 0 : rangeDate[1].getTime()
    );
  };

  return (
    <>
      <Box
        className='flex_center'
        sx={{ justifyContent: 'flex-end', marginTop: '-1.4rem' }}
      >
        <Button
          color='info'
          variant='outlined'
          sx={{ ml: '1rem', mr: '1rem', p: '.2rem 1rem' }}
          onClick={onDownloadFile}
        >
          데이터 다운로드
          <FileDownloadRoundedIcon color='success' />
        </Button>
      </Box>
      <Paper sx={{ p: '0 1.5rem 1.5rem', m: '1.4rem 0' }}>
        <Box
          className='flex_end'
          sx={{
            p: '1rem .5rem',
          }}
        >
          <Box className='flex_center'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
        </Box>
        <Table sx={{ maxWidth: 'max-content' }}>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <RowsCell
                  key={idx.toString()}
                  sx={{
                    fontSize: '.88rem',
                    fontWeight: 400,
                    p: '.5rem',
                    textAlign: 'center',
                  }}
                  size='small'
                >
                  {column}
                </RowsCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {wearableList?.map((row: any, idx: number) => (
              <TableRow key={idx.toString()}>
                <RowsCell>{row.insertDate}</RowsCell>
                <RowsCell>{row.device}</RowsCell>
                <RowsCell>{row.os}</RowsCell>
                <RowsCell>{row.version}</RowsCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PaginationBox>
          <Pagination
            color='primary'
            variant='outlined'
            siblingCount={3}
            page={page}
            onChange={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </PaginationBox>
      </Paper>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
