export interface IGuideTool {
  title: string;
  contents: string;
  image?: any;
  imageFile?: any;
  uploadUrl?: any;
}

export interface IChallengeReqeust {
  searchType?: number;
  drawType?: string;
  challengeId?: string;
  rewardId?: string;
  searchKey?: string;
  searchWord?: string;
  userIds?: string[];
  portalId?: string;
  status?: number;
  startDate?: number;
  endDate?: number;
  page?: number;
  rowsPerPage?: number;
  point?: number;
  message?: string;
}

export interface IChallengePost {
  challengeId?: string;
  advertisementId?: string;
  searchKey?: string;
  searchWord?: string;

  idx?: number;
  id?: string;
  cid?: string;
  name?: string;
  profile?: string;
  content?: string;
  img?: string;
  createTime?: number;
  useActionButton?: string;
  actionButtonText?: string;
  actionButtonUrl?: string;
  repeatType?: number;
  startDate?: number;
  endDate?: number;
  endDates?: number[];
  period?: number;
  useBrandPoint?: number;
  brandPointId?: string;
  introductionImage?: string;
  pointName?: string;
  creator?: string[];
  filters?: any[];
  expirationPeriod?: number;
}

export interface IChallengeCreate {
  ticket: string;
  challengeId: string;
  challengeType: number;
  challengeGoal: number;
  challengeGpsGoal?: number;
  challengeStepsMax: number;
  challengeTitle: string;
  challengeImgpathMain: string;
  challengeInfoDuration: string;
  challengeInfoReward: string;
  challengeInfoGoal: string;
  challengeTestStime: number;
  challengeViewStime: number;
  challengeViewEtime: number;
  challengeAttendStime: number;
  challengeAttendEtime: number;
  challengeStepsPerPoint: number;
  challengeStepsMin: number;
  challengeWalkCourseId: string;
  challengeWalkZoneId: string;
  challengeStampTourId: string;
  challengeDescription: string;
  challengeCreator?: string;
  challengeStatus: number;
  challengeViewType: number;
  useChallengePost: number;
  challengeRewardType: number;
  testType: number;
  challengeApplyStatus: number;
  customPopupText: string;
  customPopupDeleted: number;
  challengeCreatorList?: string[];
  coverInfo: string;
  coverReward: string;
  coverAd: string;
  classType: number;
  recommendType: number;
  attendLimit: number;
  challengeAddress: string;
  challengeLocationLat: number;
  challengeLocationLng: number;
  isWithStep?: number;
  isLocationCert?: number;
  photoCertDescription?: string;
  photoCertificateWeeks?: number[];
  photoCertificateTimes?: string[];
  photoCertSuccessImage?: string;
  photoCertFailImage?: string;
  brandPointId?: string;
  hidden: number;
  bannerImage?: string;
  link?: string;
  challengeFieldId?: string;
  maxPoint?: number;
  useRank?: number;
  isPrivate?: number;
  useGallery?: number;
}

export interface IFilter {
  filterType: number;
  targetId?: string;
  targetAmd?: string;
  targetAddress?: string;
}

export interface IAuthRegion {
  targetType: number;
  targetId?: string;
  targetAmd?: string;
  targetAddress?: string;
}

export interface IAuthAddress {
  targetType: number;
  targetId?: string;
  targetAmd?: string;
  targetAddress?: string;
}

export interface IChallengeFilter {
  challengeId: string;
  filters: IFilter[];
  geohashs: string[];
  region: IFilter[];
  location: IFilter[];
  authRegions: IAuthRegion[];
  authAddress: IAuthAddress[];
  ages: number[];
  gender: number;
}

export interface IChallengeRewardBatch {
  challengeId: string;
  rewards: IChallengeReward[];
}

export interface IChallengeReward {
  rewardId: string;
  challengeId: string;
  rewardTitle: string;
  rewardSponsored: string;
  rewardMaxCount: number;
  rewardUseStime: number;
  rewardUseEtime: number;
  rewardUseDay: number;
  rewardStatus: number;
  rewardUseType: number;
  rewardImgpathMain: string;
  rewardImgpathSub: string;
  rewardDescription: string;
  rewardPointPerGet: number;
  rewardStampPerGet: number;
  rewardStepPerGet: number;
  rewardAddress: string;
  rewardContact: string;
  rewardCouponLink: string;
  rewardIsDuplicate: number;
  rewardGiveType: number;
  rewardSummary: string;
  rewardLocationLat: number;
  rewardLocationLng: number;
  rewardCreator: string;
  rewardEditor: string;
  rewardUsetypeSub: number;
  rewardApplyform: string;
  link: string;
  phoneNo: string;
  brandPointId?: string;
  storeId?: string;
  applyformVersion: number;
  rewardValue: number;
  certTitle: string;
  certContent: string;
  rewardAutoDrawDate?: number;
}

export interface IChallengeConfirm {
  challengeId: string;
  testType: number;
}

export interface IChallengeApplyStatus {
  challengeId: string;
  status: number;
  message: string;
}

export interface IChallengeClassType {
  type: number;
  title: string;
}

export const DefaultChallengeCreate: IChallengeCreate = {
  ticket: '',
  challengeId: '',
  challengeType: 0,
  challengeGoal: 0,
  challengeStepsMax: 0,
  challengeTitle: '',
  challengeImgpathMain: '',
  challengeInfoDuration: '',
  challengeInfoReward: '',
  challengeInfoGoal: '',
  challengeTestStime: 0,
  challengeViewStime: 0,
  challengeViewEtime: 0,
  challengeAttendStime: 0,
  challengeAttendEtime: 0,
  challengeStepsPerPoint: 0,
  challengeStepsMin: 0,
  challengeWalkCourseId: '',
  challengeWalkZoneId: '',
  challengeStampTourId: '',
  challengeDescription: '',
  challengeCreator: '',
  challengeStatus: 0,
  challengeViewType: 0,
  useChallengePost: 0,
  challengeRewardType: 0,
  testType: 0,
  challengeApplyStatus: 0,
  customPopupText: '',
  customPopupDeleted: 1,
  coverInfo: '',
  coverReward: '',
  coverAd: '',
  classType: 0,
  recommendType: 0,
  attendLimit: 0,
  challengeAddress: '',
  challengeLocationLat: 0,
  challengeLocationLng: 0,
  hidden: 0,
};
