import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSession, xapi } from '../../../hooks/session';
import { IUploadFile } from '../../../models/common';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { SwSwitch } from '../../styles/Styles';
import { generateUuidId } from '../../../common/helper';

const MAX_NAME_LENGTH = 100;

interface Props {
  open: boolean;
  character: any;
  characterIndex: number;
  onClose: () => void;
  onConfirm: (idx: number, character: any) => void;
  onAdd: (idx: number, character: any) => void;
}

export const ChallengeFieldCharacterInfo: React.FC<Props> = ({
  open,
  character,
  characterIndex,
  onClose,
  onConfirm,
  onAdd,
}) => {
  const IMAGE_COUNT = 5;
  const defaultValue = {
    id: generateUuidId(),
    name: '',
    mileage: 0,
    bigMileage: 0,
    bigAppearNumber: 0,
    difficulty: 1,
    basicRadius: 20,
    bigRadius: 20,
    popupTitle: '',
    popupContent: '',
    popupLink: '',
  };
  const { loginVal } = useSession();
  const [value, setValue] = useState<any>(defaultValue);
  const tt = useRef<any>();
  const [address, setAddress] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [characterMainImage, setCharacterMainImage] = useState<string>('');
  const [characterMainImageFile, setCharacterMainImageFile] =
    useState<any>(null);
  const [characterPopupImage, setCharacterPopupImage] = useState<string>('');
  const [characterPopupImageFile, setCharacterPopupImageFile] =
    useState<any>(null);
  const [descriptionBuffer, setDescriptionBuffer] = useState<string[]>([]);
  const [usePopupContent, setUsePopupContent] = useState<boolean>(false);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });

  const onChangeValue = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = evt.target.name;
    const targetValue =
      target === 'name'
        ? evt.target.value.substring(0, 10)
        : target === 'difficulty' ||
          target === 'basicRadius' ||
          target === 'bigRadius'
        ? Number(evt.target.value)
        : target === 'popupTitle'
        ? evt.target.value.substring(0, 16)
        : target === 'popupContent'
        ? evt.target.value.substring(0, 42)
        : evt.target.value.substring(0, 100);
    const newValue = { ...value, [target]: targetValue };
    setValue(newValue);
  };

  async function saveCharacterInfo() {
    var newMainUploadUrl = '';
    if (characterMainImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        characterMainImageFile
      );
      newMainUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newPopupUploadUrl = '';
    if (usePopupContent && characterPopupImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        characterPopupImageFile
      );
      newPopupUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    const newCharacterId =
      value.characterId !== undefined &&
      value.characterId !== null &&
      value.characterId.length > 0
        ? value.characterId
        : generateUuidId();

    const newValue = {
      ...value,
      characterId: newCharacterId,
      image:
        characterMainImageFile !== null ? newMainUploadUrl : characterMainImage,
      popupImage:
        characterPopupImageFile !== null
          ? newPopupUploadUrl
          : characterPopupImage,
    };
    setValue(newValue);

    character
      ? onConfirm(characterIndex, newValue)
      : onAdd(characterIndex, newValue);
  }

  useEffect(() => {
    setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);

    if (character && open) {
      const newValue = {
        characterId: character?.characterId,
        name: character.name ? character.name : '',
        mileage: character.mileage ? character.mileage : 0,
        bigMileage: character.bigMileage ? character.bigMileage : 0,
        bigAppearNumber: character.bigAppearNumber
          ? character.bigAppearNumber
          : 0,
        popupTitle: character.popupTitle ? character.popupTitle : '',
        popupContent: character.popupContent ? character.popupContent : '',
        popupLink: character.popupLink ? character.popupLink : '',
        difficulty: character.difficulty,
        basicRadius: character.basicRadius,
        bigRadius: character.bigRadius,
      };
      setValue(newValue);

      setUsePopupContent(
        character.popupTitle ? character.popupTitle.length > 0 : false
      );
      setCharacterMainImage(
        character?.image?.length > 0 ? character?.image : ''
      );
      setCharacterMainImageFile(null);
      setCharacterPopupImage(
        character?.popupImage?.length > 0 ? character?.popupImage : ''
      );
      setCharacterPopupImageFile(null);
    } else {
      setValue(defaultValue);
      setUsePopupContent(false);
      setCharacterMainImage('');
      setCharacterMainImageFile(null);
      setCharacterPopupImage('');
      setCharacterPopupImageFile(null);
    }
  }, [open]);

  return (
    <Dialog
      id='test'
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          marginTop: '18vh',
          marginLeft: '35vw',
          padding: '1rem .5rem 0',
          height: '66vh',
          width: '22vw',
          minWidth: '333px',
        },
      }}
      ref={tt}
    >
      <DialogTitle sx={{ padding: '1rem .5rem 0' }}>
        {characterIndex + 1}번 지정 출현 캐릭터
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ padding: '1rem .5rem 0' }}>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>캐릭터 이미지</Typography>
          <SwFileSetting
            setImage={setCharacterMainImage}
            image={
              characterMainImageFile !== null
                ? characterMainImage
                : characterMainImage !== '' && characterMainImage !== undefined
                ? `${imageUrlPrefix}${characterMainImage}`
                : ''
            }
            setFile={setCharacterMainImageFile}
            file={characterMainImageFile}
            disabled={false}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>캐릭터 이름</Typography>
          <TextField
            placeholder='이름을 입력해주세요.'
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.name}
            name='name'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography sx={{ fontWeight: 500 }}>캐릭터 획득 마일리지</Typography>
          <TextField
            placeholder='마일리지 값을 입력해주세요.'
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.mileage}
            name='mileage'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography className='title'>난이도</Typography>
          <TextField
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.difficulty}
            name='difficulty'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography className='title'>랜덤(기본) 포획 반경(m)</Typography>
          <TextField
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.basicRadius}
            name='basicRadius'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box sx={{ p: '.5rem' }}>
          <Typography className='title'>대왕 포획 반경(m)</Typography>
          <TextField
            sx={{ p: '.4rem 0 0' }}
            fullWidth
            value={value.bigRadius}
            name='bigRadius'
            onChange={evt => onChangeValue(evt)}
          />
        </Box>
        <Box className='flex_start'>
          <Typography sx={{ m: '0 .5rem' }}>콘텐츠 창 사용</Typography>
          <SwSwitch
            id='test'
            checked={usePopupContent}
            onChange={() => {
              setUsePopupContent(!usePopupContent);
            }}
          />
        </Box>
        {usePopupContent && (
          <>
            <Box sx={{ p: '.5rem' }}>
              <Typography sx={{ fontWeight: 500 }}>팝업 이미지</Typography>
              <SwFileSetting
                setImage={setCharacterPopupImage}
                image={
                  characterPopupImageFile !== null
                    ? characterPopupImage
                    : characterPopupImage !== '' &&
                      characterPopupImage !== undefined
                    ? `${imageUrlPrefix}${characterPopupImage}`
                    : ''
                }
                setFile={setCharacterPopupImageFile}
                file={characterPopupImageFile}
                disabled={false}
              />
            </Box>
            <Box sx={{ p: '.5rem' }}>
              <Typography sx={{ fontWeight: 500 }}>팝업 타이틀</Typography>
              <TextField
                placeholder='타이틀을 입력해주세요.(최대 16자)'
                sx={{ p: '.4rem 0 0' }}
                fullWidth
                value={value.popupTitle}
                name='popupTitle'
                onChange={evt => onChangeValue(evt)}
              />
            </Box>
            <Box sx={{ p: '.5rem' }}>
              <Typography sx={{ fontWeight: 500 }}>팝업 내용</Typography>
              <TextField
                placeholder='내용을 입력해주세요.(최대 42자)'
                sx={{ p: '.4rem 0 0' }}
                fullWidth
                value={value.popupContent}
                name='popupContent'
                onChange={evt => onChangeValue(evt)}
              />
            </Box>
            <Box sx={{ p: '.5rem' }}>
              <Typography sx={{ fontWeight: 500 }}>
                ‘알아보기’버튼 링크
              </Typography>
              <TextField
                placeholder='url을 입력해주세요.'
                sx={{ p: '.4rem 0 0' }}
                fullWidth
                value={value.popupLink}
                name='popupLink'
                onChange={evt => onChangeValue(evt)}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>취소</Button>
        <Button onClick={() => saveCharacterInfo()}>저장</Button>
      </DialogActions>
    </Dialog>
  );
};
