import { Box, MenuItem, Paper, Typography } from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { SelectTextField, TableButton } from '../../styles/Styles';
import { PartnerTable } from '../../commonComponent/table/PartnerTable';
import { CreateContract } from './CreateContract';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import {
  getContractList,
  deleteContract,
  getContractInfoList,
  downloadContractInfoData,
} from '../../../common/api/ApiContract';
import {
  getMemberLimit,
  getPurposeCode,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  KEY_CONTRACT_INFO_LIST,
  KEY_WORD_PREFIX_CONTRACT,
} from '../../../common/key';

interface Props {
  contractInfo: any;
}

const column = [
  '계약목적',
  '계약 시작 일시',
  '계약 종료 일시',
  '계약 인원',
  '계약 메모',
];

export const ContractInformation: React.FC<Props> = ({ contractInfo }) => {
  const queryClient = useQueryClient();
  const [contractUser, setContractUser] = useState<string>('');
  const [contractList, setContractList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [contractData, setContractData] = useState<any>(null);
  const [selectPortalCode, setSelectPortalCode] = useState<any>('');
  const [addContract, setAddContract] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(true);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [sortValue, setSortValue] = useState(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [checkRequest, setCheckRequest] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: contractInfoRefetch,
  } = useQuery(
    KEY_CONTRACT_INFO_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest && contractInfo !== null && contractInfo !== undefined) {
        const newData = {
          id: contractInfo.id,
          type: sortValue,
          page: page,
          rowsPerPage: rowsPerPage,
          startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
          endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
        };
        const rslt = getContractInfoList(newData);
        console.log('rslt :', rslt);
        return rslt;
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res && res.contract) {
          console.log('res.contract:', res.contract);
          const newContract = res.contract.map((item: any) => {
            const newData = [
              getPurposeCode(item.purposeCode),
              timestamp2Localestring(item.startDate, 1000).split(' ')[0],
              timestamp2Localestring(item.endDate, 1000).split(' ')[0],
              getMemberLimit()[item.memberLimit],
              item.memo,
            ];
            return newData;
          });
          setTotalRecords(res.pagination.totalRecordCount);
          setRequestList(newContract);
          setContractList(res.contract);
          setContractData(null);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const contractPrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_CONTRACT_INFO_LIST, () => {
      const newData = {
        id: contractInfo.id,
        type: sortValue,
        page: newPage,
        rowsPerPage: rowsPerPage,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
      };
      getContractInfoList(newData);
    });
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    contractPrefetch(newPage);
  };

  const { mutate: deleteContractMutation, isError: isDeleteRegionMutateError } =
    useMutation(deleteContract, {
      onSuccess: () => {
        setOpenSnakerDialog(true);
        setDialogMessage('고객이 삭제되었습니다.');
        contractInfoRefetch();
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '고객을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const updateContract = (updateFlag: boolean) => {
    if (contractData === null) {
      setOpenDialog(true);
      setDialogMessage(
        '선택한 계약정보가 없습니다. 계약정보를 선택 후 시도해주시기 바랍니다.'
      );
      return;
    }
    if (updateFlag) {
      setEdit(true);
      setAddContract(true);
    } else {
      deleteContractMutation(contractData.contractId);
    }
  };

  const downloadContractInfo = () => {
    const newData = {
      id: contractInfo.id,
      type: sortValue,
      page: page,
      rowsPerPage: rowsPerPage,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
    };
    downloadContractInfoData(newData);
  };

  const chooseRequest = (i: number) => {
    setContractData(contractList[i]);
    setSelectPortalCode(contractList[i].portalCode);
    setEdit(false);
  };

  useEffect(() => {
    contractInfoRefetch();
  }, [rangeDate]);

  useEffect(() => {
    if (checkRequest) {
      contractInfoRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    if (contractInfo !== null && contractInfo !== undefined) {
      setContractData(null);
      setCheckRequest(true);
      setSelectPortalCode('');

      console.log('contractinformation constractInfo :', contractInfo);
      // const newConstime = timestamp2string(contractInfo.constime, 1000);
      // const newActivate = timestamp2string(contractInfo.activatetime, 1000);
      // setContractUser(contractInfo?.contractUser);
      // const newData = [
      //   contractInfo.contractName,
      //   contractInfo.payment,
      //   contractInfo.paymentType,
      //   contractInfo.contractTerm,
      //   newConstime,
      //   newActivate,
      //   contractInfo.communityLimit,
      //   contractInfo.contractUserInfo,
      //   contractInfo.phoneNum,
      //   contractInfo.mainEmail,
      //   contractInfo.contractStatus,
      //   contractInfo.comment,
      // ];
      // setContractList([newData]);
      contractInfoRefetch();
    } else {
      setContractList([]);
      setRequestList([]);
    }
  }, [contractInfo]);

  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500, p: '2.5rem 0 .5rem' }}>
        계약 정보
      </Typography>
      <Paper sx={{ p: '1.5rem', m: '1rem 0' }}>
        <Box className='flex_end'>
          <TableButton
            color='info'
            variant='outlined'
            onClick={downloadContractInfo}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon
              color='success'
              sx={{ fontSize: '1.3rem' }}
            />
          </TableButton>
          <TableButton
            color='info'
            variant='outlined'
            onClick={() => updateContract(true)}
          >
            상세보기
          </TableButton>
          <TableButton
            color='info'
            variant='outlined'
            onClick={() => setOpenDelete(true)}
          >
            삭제
          </TableButton>
          <TableButton
            color='info'
            variant='contained'
            onClick={() => {
              setEdit(false);
              setAddContract(true);
            }}
          >
            <AddOutlinedIcon sx={{ fontSize: '1.5rem' }} />
            계약 추가
          </TableButton>
        </Box>
        <Box className='flex_between' sx={{ mb: '1rem' }}>
          {contractUser && <Box>{contractUser} 조회 1 건</Box>}
          <Box className='flex_between'>
            <Box className='flex_between'>
              <Box sx={{ mr: '.5rem' }}>
                <SwDateRangePicker
                  rangeDate={rangeDate}
                  setRangeDate={setRangeDate}
                />
              </Box>
              <SelectTextField
                select
                value={sortValue}
                onChange={evt => {
                  setSortValue(Number(evt.target.value));
                }}
                sx={{ mr: '.5rem' }}
              >
                <MenuItem value={0}>전체</MenuItem>
                <MenuItem value={1}>진행중</MenuItem>
                <MenuItem value={2}>종료</MenuItem>
              </SelectTextField>
              <SelectTextField
                select
                value={rowsPerPage}
                onChange={evt => {
                  setRowsPerPage(Number(evt.target.value));
                  setPage(1);
                }}
              >
                {rowsPerPageOptions.map(option => (
                  <MenuItem key={option.toString()} value={option}>
                    {option}개씩 보기
                  </MenuItem>
                ))}
              </SelectTextField>
            </Box>
          </Box>
        </Box>
        <PartnerTable
          columns={column}
          rows={requestList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
          onclick={chooseRequest}
        />
      </Paper>
      <CreateContract
        title='거래 내역'
        portalCode={contractInfo?.portalCode}
        contractInfo={contractData}
        open={addContract}
        onClose={() => {
          contractInfoRefetch();
          setAddContract(false);
        }}
        onConfirm={() => {
          contractInfoRefetch();
          setAddContract(false);
        }}
        edit={edit}
        isEdit={() => {
          contractInfoRefetch();
          setEdit(!edit);
        }}
      />
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='삭제하시겠습니까?'
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          updateContract(false);
          setOpenDelete(false);
        }}
        confirm={1}
      />
    </>
  );
};
