export interface IWalkcourse {
  courseId: string;
  courseName?: string;
  courseCal?: number;
  courseCenterPointLat?: number;
  courseCenterPointLng?: number;
  courseCenterRadius?: number;
  courseDeleted?: number;
  courseDistance?: number;
  courseImgpath?: string;
  courseStep?: number;
  courseTime?: number;
  creator?: string;
  editor?: string;
  points?: IWalkpointInfo[];
  spots?: IWalkSpotInfo[];
  courseContent?: string;
  courseShow?: number;
  courseDifficulty?: number;
}

export interface IWalkcourseInfo {
  courseId: string;
  courseName: string;
  courseCal: number;
  courseCenterPointLat: number;
  courseCenterPointLng: number;
  courseCenterRadius: number;
  courseDeleted: number;
  courseDistance: number;
  courseImgpath: string;
  courseStep: number;
  courseTime: number;
  createDate: number;
  creator: string;
  editor: string;
  courseContent: string;
  courseShow: number;
  courseDifficulty: number;
}

export interface IWalkpointInfo {
  pointId: string;
  pointPtLat: number;
  pointPtLng: number;
  pointRadius: number;
  pointSeq: number;
}

export interface IWalkSpotInfo {
  spotDeleted: string;
  spotId: string;
  spotName: string;
  spotPointLat: number;
  spotPointLng: number;
  spotRadius: number;
  spotSeq: number;
  className: string;
  link: string;
  phoneNo: string;
  address: string;
  description: string;
}

export interface IWalkcouseDetail {
  course: IWalkcourseInfo;
  point: IWalkpointInfo[];
}

export const DefaultWalkcourseInfo: IWalkcourseInfo = {
  courseId: '',
  courseName: '',
  courseCal: 0,
  courseCenterPointLat: 0,
  courseCenterPointLng: 0,
  courseCenterRadius: 0,
  courseDeleted: 0,
  courseDistance: 0,
  courseImgpath: '',
  courseStep: 0,
  courseTime: 0,
  createDate: 0,
  creator: '',
  editor: '',
  courseContent: '',
  courseShow: 1,
  courseDifficulty: 3,
};

export const DefaultWalkpointInfo: IWalkpointInfo = {
  pointId: '',
  pointPtLat: 0,
  pointPtLng: 0,
  pointRadius: 0,
  pointSeq: 0,
};

export const DefaultWalkcouseDetail: IWalkcouseDetail = {
  course: DefaultWalkcourseInfo,
  point: [DefaultWalkpointInfo],
};
