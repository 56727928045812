import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useSession, xapi } from '../../../hooks/session';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwBasicDialog } from '../../commonComponent/views/SwBasicDialog';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { MultilineTextField } from '../../styles/Styles';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { postWalkwayPoint } from '../../../common/api/ApiWalkway';
import { IUploadFile } from '../../../models/common';
import {
  IWalkwayPoint,
  IWalkwayPointInfo,
  IPointContent,
  IWalkwayPointCommand,
  IWalkwayCommand,
} from '../../../models/walkway';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';

interface Props {
  walkwayCommand: IWalkwayCommand;
  value: any;
  points: any[];
  setPoints: Dispatch<SetStateAction<any[]>>;
  setReloadPoints: Dispatch<SetStateAction<boolean>>;
  getWalkwayId: () => string;
}

export const WalkwayPointForm: React.FC<Props> = ({
  walkwayCommand,
  value,
  points,
  setPoints,
  setReloadPoints,
  getWalkwayId,
}) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  const descriptionRef = useRef<any>();
  const [walkwayPoint, setWalkwayPoint] = useState<any>();
  const [walkwayId, setWalkwayId] = useState<string>('');
  const [walkwayPointId, setWalkwayPointId] = useState<string>('');
  const [walkwayContentId, setWalkwayContentId] = useState<string>('');
  const [walkwayPointTitle, setWalkwayPointTitle] = useState<string>('');
  const [walkwayPointDescription, setWalkwayPointDescription] =
    useState<string>('');
  const [urlPrefix, setUrlPrefix] = useState<string>('');
  const [walkwayPointImage, setWalkwayPointImage] = useState<string>('');
  const [walkwayPointImageFile, setWalkwayPointImageFile] = useState<any>(null);
  const [walkwayPointDescriptionImage1, setWalkwayPointDescriptionImage1] =
    useState<string>('');
  const [walkwayPointDescriptionImage2, setWalkwayPointDescriptionImage2] =
    useState<string>('');
  const [walkwayPointDescriptionImage3, setWalkwayPointDescriptionImage3] =
    useState<string>('');
  const [walkwayPointDescriptionImage4, setWalkwayPointDescriptionImage4] =
    useState<string>('');
  const [walkwayPointDescriptionImage5, setWalkwayPointDescriptionImage5] =
    useState<string>('');
  const [
    walkwayPointDescriptionImageFile1,
    setWalkwayPointDescriptionImageFile1,
  ] = useState<any>(null);
  const [
    walkwayPointDescriptionImageFile2,
    setWalkwayPointDescriptionImageFile2,
  ] = useState<any>(null);
  const [
    walkwayPointDescriptionImageFile3,
    setWalkwayPointDescriptionImageFile3,
  ] = useState<any>(null);
  const [
    walkwayPointDescriptionImageFile4,
    setWalkwayPointDescriptionImageFile4,
  ] = useState<any>(null);
  const [
    walkwayPointDescriptionImageFile5,
    setWalkwayPointDescriptionImageFile5,
  ] = useState<any>(null);
  const [uploadFiles, setUploadFiles] = useState<any>([]);
  const [walkwayPointLocation, setWalkwayPointLocation] = useState<string>('');
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [alias, setAlias] = useState<string>('');
  const [hidden, setHidden] = useState<number>(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const newId = () => uuidv4().replace(/-/gi, '').toUpperCase();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [uploadUrl, setUploadUrl] = useState<any>({
    uploadUrl: '',
    descUploadUrl1: '',
    descUploadUrl2: '',
    descUploadUrl3: '',
    descUploadUrl4: '',
    descUploadUrl5: '',
  });

  // var uploadUrl: string = '';
  // var descUploadUrl1: string = '';
  // var descUploadUrl2: string = '';
  // var descUploadUrl3: string = '';
  // var descUploadUrl4: string = '';
  // var descUploadUrl5: string = '';

  const resetWalkwayPoint = () => {
    setWalkwayPointImage('');
    setWalkwayPointTitle('');
    setWalkwayPointId(newId());
    setWalkwayContentId(newId());
    setWalkwayPointDescription('');
    setResultLatLng('');
    setWalkwayPointLocation(' ');
    setWalkwayPointDescriptionImage1('');
    setWalkwayPointDescriptionImage2('');
    setWalkwayPointDescriptionImage3('');
    setWalkwayPointDescriptionImage4('');
    setWalkwayPointDescriptionImage5('');
    setWalkwayPointDescriptionImageFile1(null);
    setWalkwayPointDescriptionImageFile2(null);
    setWalkwayPointDescriptionImageFile3(null);
    setWalkwayPointDescriptionImageFile4(null);
    setWalkwayPointDescriptionImageFile5(null);

    // uploadUrl = '';
    // descUploadUrl1 = '';
    // descUploadUrl2 = '';
    // descUploadUrl3 = '';
    // descUploadUrl4 = '';
    // descUploadUrl5 = '';
    setUploadUrl({
      uploadUrl: '',
      descUploadUrl1: '',
      descUploadUrl2: '',
      descUploadUrl3: '',
      descUploadUrl4: '',
      descUploadUrl5: '',
    });
  };

  const { mutate: postWalkwayPointMutate, isError: isMutateError } =
    useMutation(postWalkwayPoint, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('워크웨이 포인트를 등록했습니다.');
        setReloadPoints(true);
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '워크웨이 포인트 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const saveWalkwayPoint = (idx: number, newWalkwayPoint: any) => {
    console.log('saveWalkwayPoint:', newWalkwayPoint);
    const newPoint: IWalkwayPointCommand = {
      walkwayId: newWalkwayPoint.point.walkWayId,
      pointId: newWalkwayPoint.point.pointId,
      title: newWalkwayPoint.point.title,
      description: newWalkwayPoint.content.description,
      mainImage: newWalkwayPoint.content.imagePath,
      subImages: newWalkwayPoint.content.storyImages,
      location: newWalkwayPoint.point.location,
      la: newWalkwayPoint.point.la,
      lo: newWalkwayPoint.point.lo,
      contentId: newWalkwayPoint.content.contentId,
      alias: newWalkwayPoint.point.alias,
      open: newWalkwayPoint.point.hidden,
      urlPrefix: urlPrefix,
    };

    const newPoints = points.map((point: any, i: number) => {
      if (i === value) return newPoint;
      return point;
    });

    setPoints(newPoints);
  };

  const registerWalkwayPoint = (newUploadUrl: any) => {
    const newWalkwayId = getWalkwayId();
    const newContentId = walkwayContentId === '' ? newId() : walkwayContentId;

    const newLtLng = resultLatLng.split(' ');
    const newLa = newLtLng[0] ? Number(newLtLng[0]) : 0;
    const newLo = newLtLng[1] ? Number(newLtLng[1]) : 0;
    const newImage =
      walkwayPointImageFile !== null
        ? newUploadUrl.uploadUrl
        : walkwayPointImage.length > 0
        ? walkwayPointImage
        : '';
    const newSubImages = [
      walkwayPointDescriptionImageFile1 !== null
        ? newUploadUrl.descUploadUrl1
        : walkwayPointDescriptionImage1 !== null &&
          walkwayPointDescriptionImage1.length > 0
        ? walkwayPointDescriptionImage1
        : '',
      walkwayPointDescriptionImageFile2 !== null
        ? newUploadUrl.descUploadUrl2
        : walkwayPointDescriptionImage2 !== null &&
          walkwayPointDescriptionImage2.length > 0
        ? walkwayPointDescriptionImage2
        : '',
      walkwayPointDescriptionImageFile3 !== null
        ? newUploadUrl.descUploadUrl3
        : walkwayPointDescriptionImage3 !== null &&
          walkwayPointDescriptionImage3.length > 0
        ? walkwayPointDescriptionImage3
        : '',
      walkwayPointDescriptionImageFile4 !== null
        ? newUploadUrl.descUploadUrl4
        : walkwayPointDescriptionImage4 !== null &&
          walkwayPointDescriptionImage4.length > 0
        ? walkwayPointDescriptionImage4
        : '',
      walkwayPointDescriptionImageFile5 !== null
        ? newUploadUrl.descUploadUrl5
        : walkwayPointDescriptionImage5 !== null &&
          walkwayPointDescriptionImage5.length > 0
        ? walkwayPointDescriptionImage5
        : '',
    ].filter(image => image !== '');
    console.log('newImage :', newImage);
    console.log('newSubImages :', newSubImages);

    const newPointContent: IPointContent = {
      contentId: newContentId,
      pointId: walkwayPointId,
      imagePath: newImage,
      storyImages: newSubImages,
      description: walkwayPointDescription,
    };

    const newWalkwayPointInfo: IWalkwayPointInfo = {
      walkWayId: newWalkwayId,
      pointId: walkwayPointId,
      title: walkwayPointTitle,
      alias: alias,
      la: newLa,
      lo: newLo,
      location: walkwayPointLocation,
      hidden: hidden,
    };
    const newWalkwayPoint: IWalkwayPoint = {
      point: newWalkwayPointInfo,
      content: newPointContent,
    };
    console.log('newWalkway point:', newWalkwayPoint);
    saveWalkwayPoint(value, newWalkwayPoint);
    postWalkwayPointMutate(newWalkwayPoint);
  };

  const checkValid = () => {
    var val = 0;
    const newWalkwayId = getWalkwayId();
    if (newWalkwayId.length === 0) val = 1;
    else if (walkwayPointTitle.length === 0) val = 2;
    else if (walkwayPointLocation.length === 0) val = 3;
    else if (walkwayPointDescription.length === 0) val = 4;
    else if (walkwayPointImage.length === 0 && walkwayPointImageFile == null)
      val = 5;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '워크웨이를 먼저 선택 후 포인트 작업을 해주시기 바랍니다.';
          break;
        case 2:
          message = '워크웨이 포인트 이름을 입력해 주세요.';
          break;
        case 3:
          message = '워크웨이 포인트 위치 정보를 선택해주세요.';
          break;
        case 4:
          message = '워크웨이 포인트 설명을 입력해주세요.';
          break;
        case 5:
          message = '워크웨이 포인트 대표 이미지를 선택해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenSnackbar(true);
    }

    return val;
  };

  async function uploadMultipleFiles() {
    const newUploadUrl: any = {
      uploadUrl: '',
      descUploadUrl1: '',
      descUploadUrl2: '',
      descUploadUrl3: '',
      descUploadUrl4: '',
      descUploadUrl5: '',
    };

    if (walkwayPointImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'walkway',
        walkwayPointImageFile
      );
      console.log('newUploadFile1 :', newUploadFile);
      newUploadUrl.uploadUrl = newUploadFile.data.uploadUrl.file.path;
    }
    if (walkwayPointDescriptionImageFile1 !== null) {
      const newUploadFile1: any = await uploadFile(
        'walkway',
        walkwayPointDescriptionImageFile1
      );
      newUploadUrl.descUploadUrl1 = newUploadFile1.data.uploadUrl.file.path;
    }
    if (walkwayPointDescriptionImageFile2 !== null) {
      const newUploadFile2: any = await uploadFile(
        'walkway',
        walkwayPointDescriptionImageFile2
      );
      newUploadUrl.descUploadUrl2 = newUploadFile2.data.uploadUrl.file.path;
    }
    if (walkwayPointDescriptionImageFile3 !== null) {
      const newUploadFile3: any = await uploadFile(
        'walkway',
        walkwayPointDescriptionImageFile3
      );
      newUploadUrl.descUploadUrl3 = newUploadFile3.data.uploadUrl.file.path;
    }
    if (walkwayPointDescriptionImageFile4 !== null) {
      const newUploadFile4: any = await uploadFile(
        'walkway',
        walkwayPointDescriptionImageFile4
      );
      newUploadUrl.descUploadUrl4 = newUploadFile4.data.uploadUrl.file.path;
    }
    if (walkwayPointDescriptionImageFile5 !== null) {
      const newUploadFile5: any = await uploadFile(
        'walkway',
        walkwayPointDescriptionImageFile5
      );
      newUploadUrl.descUploadUrl5 = newUploadFile5.data.uploadUrl.file.path;
    }
    setUploadUrl(newUploadUrl);
    registerWalkwayPoint(newUploadUrl);
  }

  const checkWalkwayPoint = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }

    uploadMultipleFiles();
  };

  useEffect(() => {
    console.log('point form walkwayCommand :', walkwayCommand);
    if (walkwayCommand.walkwayId.length > 0)
      setWalkwayId(walkwayCommand.walkwayId);

    if (walkwayCommand.reset) {
      resetWalkwayPoint();
    }
  }, [walkwayCommand]);

  useEffect(() => {
    console.log('val>>>>>>>>>>', value, ', point:', points);
    if (points.length > 0) {
      if (value >= points.length) {
        resetWalkwayPoint();
        return;
      }
      setWalkwayId(points[0].walkwayId);
      setUrlPrefix(points[0].urlPrefix);

      setWalkwayPoint(points[value]);
      setWalkwayPointImageFile(null);
      setWalkwayPointImage(
        points[value].mainImage !== null && points[value].mainImage !== ''
          ? points[value].mainImage
          : ''
      );
      setWalkwayPointTitle(points[value].title);
      setWalkwayPointId(points[value].pointId);
      setWalkwayPointDescription(
        points[value].description !== null ? points[value].description : ''
      );
      // setResultLatLng(`${points[value].la} ${points[value].lo}`);
      setWalkwayPointLocation(points[value].location);
      setHidden(points[value].open);
      setAlias(`p_${value}`);
      setWalkwayContentId(points[value].contentId);

      const newSubImages = points[value].subImages;
      if (newSubImages.length > 0) {
        newSubImages.length > 0
          ? setWalkwayPointDescriptionImage1(newSubImages[0])
          : setWalkwayPointDescriptionImage1('');
        newSubImages.length > 1
          ? setWalkwayPointDescriptionImage2(newSubImages[1])
          : setWalkwayPointDescriptionImage2('');
        newSubImages.length > 2
          ? setWalkwayPointDescriptionImage3(newSubImages[2])
          : setWalkwayPointDescriptionImage3('');
        newSubImages.length > 3
          ? setWalkwayPointDescriptionImage4(newSubImages[3])
          : setWalkwayPointDescriptionImage4('');
        newSubImages.length > 4
          ? setWalkwayPointDescriptionImage5(newSubImages[4])
          : setWalkwayPointDescriptionImage5('');
      } else {
        setWalkwayPointDescriptionImage1('');
        setWalkwayPointDescriptionImage2('');
        setWalkwayPointDescriptionImage3('');
        setWalkwayPointDescriptionImage4('');
        setWalkwayPointDescriptionImage5('');
      }
      setWalkwayPointDescriptionImageFile1(null);
      setWalkwayPointDescriptionImageFile2(null);
      setWalkwayPointDescriptionImageFile3(null);
      setWalkwayPointDescriptionImageFile4(null);
      setWalkwayPointDescriptionImageFile5(null);
    } else if (points.length === 0) {
      resetWalkwayPoint();
    }
  }, [value, points]);

  return (
    <>
      <Paper sx={{ p: '2rem', borderTopLeftRadius: 0 }}>
        <Box>
          <Typography className='title'>워크웨이 포인트 대표 이미지</Typography>
          <SwFileSetting
            setImage={setWalkwayPointImage}
            image={
              walkwayPointImageFile !== null
                ? walkwayPointImage
                : walkwayPointImage !== undefined &&
                  walkwayPointImage !== null &&
                  walkwayPointImage.length > 0
                ? `${imageUrlPrefix}${walkwayPointImage}`
                : ''
            }
            setFile={setWalkwayPointImageFile}
            file={walkwayPointImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>워크웨이 포인트 이름</Typography>
          <TextField
            sx={{ width: '44rem' }}
            onChange={evt => setWalkwayPointTitle(evt.target.value)}
            value={walkwayPointTitle}
          />
          <Divider />
        </Box>
        <Box>
          <SwUuidCreate
            id={walkwayPointId}
            setId={setWalkwayPointId}
            title='워크웨이 포인트'
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>워크웨이 포인트 위치 정보</Typography>
          <SwFindLatLngbyKakaomap
            elementId='pointMap'
            setLatLng={setResultLatLng}
            value={resultLatLng}
            location={walkwayPointLocation}
            setLocation={setWalkwayPointLocation}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>워크웨이 포인트 설명</Typography>
          <MultilineTextField
            multiline
            rows={8}
            fullWidth
            placeholder='워크웨이 포인트 설명을 1,000자 이내로 작성해 주세요.'
            value={walkwayPointDescription}
            onChange={evt => setWalkwayPointDescription(evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>
                    {walkwayPointDescription?.length || 0} / 1000
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>
            워크웨이 포인트 설명 이미지(선택)
          </Typography>
          <Stack spacing={2} direction='row' alignItems='center'>
            <SwFileSetting
              setImage={setWalkwayPointDescriptionImage1}
              image={
                walkwayPointDescriptionImageFile1 !== null
                  ? walkwayPointDescriptionImage1
                  : walkwayPointDescriptionImage1 !== null &&
                    walkwayPointDescriptionImage1.length > 0
                  ? `${imageUrlPrefix}${walkwayPointDescriptionImage1}`
                  : ''
              }
              setFile={setWalkwayPointDescriptionImageFile1}
              file={walkwayPointDescriptionImageFile1}
            />
            <SwFileSetting
              setImage={setWalkwayPointDescriptionImage2}
              image={
                walkwayPointDescriptionImageFile2 !== null
                  ? walkwayPointDescriptionImage2
                  : walkwayPointDescriptionImage2 !== null &&
                    walkwayPointDescriptionImage2.length > 0
                  ? `${imageUrlPrefix}${walkwayPointDescriptionImage2}`
                  : ''
              }
              setFile={setWalkwayPointDescriptionImageFile2}
              file={walkwayPointDescriptionImageFile2}
            />
            <SwFileSetting
              setImage={setWalkwayPointDescriptionImage3}
              image={
                walkwayPointDescriptionImageFile3 !== null
                  ? walkwayPointDescriptionImage3
                  : walkwayPointDescriptionImage3 !== null &&
                    walkwayPointDescriptionImage3.length > 0
                  ? `${imageUrlPrefix}${walkwayPointDescriptionImage3}`
                  : ''
              }
              setFile={setWalkwayPointDescriptionImageFile3}
              file={walkwayPointDescriptionImageFile3}
            />
            <SwFileSetting
              setImage={setWalkwayPointDescriptionImage4}
              image={
                walkwayPointDescriptionImageFile4 !== null
                  ? walkwayPointDescriptionImage4
                  : walkwayPointDescriptionImage4 !== null &&
                    walkwayPointDescriptionImage4.length > 0
                  ? `${imageUrlPrefix}${walkwayPointDescriptionImage4}`
                  : ''
              }
              setFile={setWalkwayPointDescriptionImageFile4}
              file={walkwayPointDescriptionImageFile4}
            />
            <SwFileSetting
              setImage={setWalkwayPointDescriptionImage5}
              image={
                walkwayPointDescriptionImageFile5 !== null
                  ? walkwayPointDescriptionImage5
                  : walkwayPointDescriptionImage5 !== null &&
                    walkwayPointDescriptionImage5.length > 0
                  ? `${imageUrlPrefix}${walkwayPointDescriptionImage5}`
                  : ''
              }
              setFile={setWalkwayPointDescriptionImageFile5}
              file={walkwayPointDescriptionImageFile5}
            />
          </Stack>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>공개 / 비공개</Typography>
          <TextField
            select
            defaultValue={0}
            sx={{ width: '13rem' }}
            value={hidden}
            onChange={evt => setHidden(Number(evt.target.value))}
          >
            <MenuItem value={0}>공개</MenuItem>
            <MenuItem value={1}>비공개</MenuItem>
          </TextField>
          <Divider />
        </Box>
        <Box sx={{ p: '1rem 0 1rem' }} className='flex_between'>
          <Button
            variant='contained'
            color='secondary'
            sx={{ mr: '1rem' }}
            onClick={() => {
              resetWalkwayPoint();
            }}
          >
            신규작성
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              setDialogTitle('워크웨이 포인트 저장');
              setDialogMessage('워크웨이 포인트를 저장하시겠습니까?');
              setOpenMore(1);
            }}
          >
            저장
          </Button>
        </Box>
      </Paper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={dialogMessage}
      />
      {openMore === 1 && (
        <SwBasicDialog
          message={dialogMessage}
          open={!!openMore}
          onConfirm={() => {
            checkWalkwayPoint();
            setOpenMore(0);
          }}
          onClose={() => {
            setOpenMore(0);
          }}
        />
      )}
    </>
  );
};
