import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Paper,
  styled,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { CommunityGroupMemberChange } from './CommunityGroupMemberChange';
import { chosenCommunityState } from '../../../common/atom';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { DraggablePaper, RowsCell } from '../../styles/Styles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { xapi } from '../../../hooks/session';
import {
  deleteCommunityGroup,
  getCommunityGroupDetailList,
  postCommunityGroup,
  putCommunityGroup,
  putCommunityGroupOrder,
} from '../../../common/api/ApiCommunity';
import { KEY_COMMUNITY_GROUP_DETAIL_LIST } from '../../../common/key';
import { ICommunityGroup } from '../../../models/community';

interface Props {}

const TopButton = styled(Button)({
  marginRight: '.7rem',
});

const DetailPaper = styled(Paper)({
  width: '100%',
  padding: '2rem 1.5rem .5rem',
  background: '#F9FAFC',
  border: '1px solid #DFE3E9',
});

const GroupDetailDivider = styled(Divider)({
  padding: 0,
  margin: '.5rem 0 1rem',
});

export const CommunityGroup: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const fileInputRef = useRef<any>();
  const [communityData, setCommunityData] = useState<any>(location.state);
  const [chosenCommunity, setChosenCommunity] =
    useRecoilState(chosenCommunityState);
  const groupIdRef = useRef<any>();
  const groupNameRef = useRef<any>();
  const [groupMemberChange, setGroupMemberChange] = useState<boolean>(false);
  const [groups, setGroups] = useState<any>([]);
  const [groupIds, setGroupIds] = useState<any>([]);
  const [communityMembers, setCommunityMembers] = useState<any>();
  const [groupChecks, setGroupChecks] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const groupItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const {
    data,
    isLoading,
    refetch: communityGroupRefetch,
  } = useQuery(
    KEY_COMMUNITY_GROUP_DETAIL_LIST,
    () => {
      const communityId = communityData && communityData.communityId;
      if (communityId.length > 0)
        return getCommunityGroupDetailList(communityId);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        setGroups(res.group);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: postCommunityGroupMutate, isError: isMutateError } =
    useMutation(postCommunityGroup, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('커뮤니티 그룹을 등록했습니다.');
        communityGroupRefetch();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '커뮤니티 그룹 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const { mutate: putCommunityGroupNameMutate, isError: isMutateNameError } =
    useMutation(putCommunityGroup, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('커뮤니티 그룹이름을 변경했습니다.');
        communityGroupRefetch();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '커뮤니티 그룹이름 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const { mutate: deleteCommunityGroupMutate, isError: isMutateDeleteError } =
    useMutation(deleteCommunityGroup, {
      onSuccess: () => {
        setOpenMore(0);
        setOpenDialog(true);
        setDialogMessage('커뮤니티 그룹을 삭제했습니다.');
        communityGroupRefetch();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '커뮤니티 그룹 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const { mutate: putCommunityGroupOrderMutate, isError: isMutateOrderError } =
    useMutation(putCommunityGroupOrder, {
      onSuccess: () => {},
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '커뮤니티 그룹 순서 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const changeCommunityGroupOrder = () => {
    const newGroups = groups.map((group: any, idx: number) => {
      const newGroup =
        idx === 0
          ? { groupId: group.groupId, groupOrder: 999 }
          : { groupId: group.groupId, groupOrder: idx };
      return newGroup;
    });
    const newData = {
      communityId: communityData.communityId,
      groups: newGroups,
    };
    putCommunityGroupOrderMutate(newData);
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: groups,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setGroups(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    groupItems.forEach(item => item.classList.remove('drag_over'));
    changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setGroups(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    groupItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    groupItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newPosition = Number(evt.currentTarget.dataset.position);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newPosition : null,
    });
    groupNameRef.current.value = groups[newPosition].groupName;
    groupIdRef.current.value = groups[newPosition].groupId;
  };

  const addGroup = () => {
    // const newGroup = groups;
    const newId = newUuidId();
    const newName = '새 그룹';
    // newGroup.push({
    //   groupName: newName,
    //   count: '0',
    //   groupId: newId,
    // });
    // setGroups(newGroup);
    // setListEvent({
    //   currentDrag: null,
    //   over: null,
    //   ...listEvent,
    // });

    const communityId = communityData && communityData.communityId;
    const groupData: ICommunityGroup = {
      communityId: communityId,
      groupName: newName,
      groupId: newId,
      isDefault: 0,
    };
    postCommunityGroupMutate(groupData);
  };

  const updateGroup = (group: any) => {
    const communityId = communityData && communityData.communityId;
    const groupData: ICommunityGroup = {
      communityId: communityId,
      groupName: group.groupName,
      groupId: group.groupId,
      isDefault: group.isDefault,
    };
    putCommunityGroupNameMutate(groupData);
  };

  const deleteGroup = () => {
    const communityId = communityData && communityData.communityId;
    const newId = groups[listEvent.clicked].groupId;
    const newGroup = groups.filter(
      (_d: any, idx: number) => idx !== listEvent.clicked
    );
    setGroups(newGroup);
    setListEvent({ ...listEvent, clicked: null });

    const groupData: ICommunityGroup = {
      communityId: communityId,
      deleteGroupIds: [newId],
    };
    deleteCommunityGroupMutate(groupData);
  };

  const deleteSelectedGroup = () => {
    const communityId = communityData && communityData.communityId;

    const groupData: ICommunityGroup = {
      communityId: communityId,
      deleteGroupIds: checkedButtons,
    };
    console.log('delete selected group :', groupData);

    deleteCommunityGroupMutate(groupData);
  };

  const checkDeleteGroup = (type: any) => {
    if (type === 0) {
      setOpenMore(1);
      setDialogMessage(
        '선택한 그룹를 삭제하시겠습니까? 그룹 멤버는 모두 기본 그룹으로 이동합니다.'
      );
    } else {
      if (checkedButtons.length === 0) {
        setOpenDialog(true);
        setDialogMessage('삭제할 커뮤니티 그룹을 선택해주세요.');
        return;
      }

      setOpenMore(2);
      setDialogMessage(
        `선택한 그룹 ${checkedButtons.length} 개를 삭제하시겠습니까? 그룹 멤버는 모두 기본 그룹으로 이동합니다.`
      );
    }
  };

  const uploadExcelFile = (evt: ChangeEvent<HTMLInputElement>) => {
    const communityId = communityData && communityData.communityId;
    const excelFile = evt.target.files && evt.target.files[0];

    var formData = new FormData();
    if (excelFile !== null) formData.append('file', excelFile);

    xapi
      .post(`/admin/v2/apis/upload/community/${communityId}/group`, formData)
      .then(res => {
        fileInputRef.current.value = '';
        if (res.status !== 200) throw new Error('http 에러');
        setOpenDialog(true);
        setDialogMessage('엑셀 업로드로 커뮤니티 그룹을 등록했습니다.');
        communityGroupRefetch();
        return res.data;
      });
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      groups
        ?.filter((group: any) => group.groupName !== '기본그룹')
        .map((group: any, idx: number) => {
          setCheckedButtons(current => [...current, group.groupId]);
        });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    const basicGroup = groups?.filter(
      (group: any) => group.groupId === id && group.groupName === '기본그룹'
    );

    if (basicGroup?.length === 0) {
      if (checked) {
        setCheckedButtons([...checkedButtons, id]);
      } else {
        setCheckedButtons(checkedButtons.filter(button => button !== id));
      }
    }
  };

  useEffect(() => {
    if (location.state) {
      fileInputRef.current.value = '';
      setCommunityData(location.state);
    }
  }, [location.state]);

  return (
    <>
      <Box sx={{ m: '2rem 0' }} className='flex_end'>
        <TopButton
          variant='outlined'
          color='info'
          onClick={() => checkDeleteGroup(1)}
        >
          그룹 삭제
        </TopButton>
        <TopButton
          variant='outlined'
          color='info'
          onClick={() => setGroupMemberChange(true)}
        >
          그룹 멤버 변경
        </TopButton>
        <TopButton variant='contained' color='info' onClick={addGroup}>
          새 그룹 추가
        </TopButton>
        <Button
          variant='contained'
          color='info'
          onClick={() => {
            const excelFile = document.getElementById('fileUpload_excel');
            excelFile?.click();
          }}
        >
          엑셀 업로드
        </Button>
        <input
          type='file'
          id='fileUpload_excel'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={uploadExcelFile}
        />
      </Box>
      <Paper
        sx={{
          heigth: '100%',
          overflowY: 'scroll',
          m: '1rem 0',
          p: '1rem',
          display: 'flex',
        }}
      >
        <Box sx={{ width: '70%' }}>
          <Box className='flex_between'>
            <Box className='flex_center'>
              <Checkbox
                onChange={e => {
                  handleSelectAllClick(e);
                }}
                checked={checkedAll}
              />
              <Typography sx={{ ml: '1rem' }}>
                전체 그룹 선택
                <span style={{ color: '#2581FF' }}> [{groups.length}개]</span>
              </Typography>
            </Box>
            {/* <Button variant='outlined' color='info' sx={{ mr: '1.7rem' }}>
              저장
            </Button> */}
          </Box>
          {groups?.map((group: any, idx: number) => (
            <Box key={idx.toString()} sx={{ display: 'flex' }}>
              <Checkbox
                id={group.groupId}
                onChange={e => {
                  changeHandler(e.currentTarget.checked, group.groupId);
                }}
                checked={checkedButtons.includes(group.groupId)}
              />
              {group.groupName === '기본그룹' ? (
                <DraggablePaper
                  className='draggable'
                  id={idx.toString()}
                  variant='outlined'
                >
                  <div>
                    <span>미분류</span>
                    <span>({group.count})</span>
                  </div>
                  <DragHandleIcon />
                </DraggablePaper>
              ) : (
                <DraggablePaper
                  className='draggable'
                  id={idx.toString()}
                  variant='outlined'
                  onDragStart={dragStart}
                  onDragEnd={dragEnd}
                  onDragOver={dragOver}
                  onDrop={drop}
                  onDragLeave={dragLeave}
                  onDragEnter={dragEnter}
                  draggable
                  data-position={idx}
                  onClick={selectList}
                >
                  <div>
                    <span>{group.groupName}</span>
                    <span>({group.count})</span>
                  </div>
                  <DragHandleIcon />
                </DraggablePaper>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            position: 'fixed',
            top: '35%',
            right: '3%',
            width: '25%',
            m: 0,
          }}
        >
          <Zoom
            timeout={100}
            in={groupItems[listEvent.clicked]?.classList.contains(
              'click_group'
            )}
          >
            <DetailPaper>
              <Typography sx={{ fontWeight: 500 }}>그룹명</Typography>
              <GroupDetailDivider />
              <TextField
                fullWidth
                sx={{ mb: '1.5rem' }}
                inputRef={groupNameRef}
              />
              <Typography sx={{ fontWeight: 500 }}>그룹 아이디</Typography>
              <GroupDetailDivider />
              <TextField
                fullWidth
                sx={{ mb: '1.5rem' }}
                inputRef={groupIdRef}
                disabled
                // inputProps={{ readOnly: true }}
              />
              <Typography sx={{ fontWeight: 500 }}>그룹 인원</Typography>
              <GroupDetailDivider />
              <Typography sx={{ ml: '.2rem' }}>
                {groups[listEvent.clicked]?.count}명
              </Typography>
              <Box className='flex_center' sx={{ mt: '1.4rem' }}>
                <Button
                  fullWidth
                  color='error'
                  onClick={() => checkDeleteGroup(0)}
                  sx={{ p: '.4rem .8rem' }}
                >
                  <DeleteOutlineIcon />
                  그룹 삭제하기
                </Button>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ p: 0, m: '.5rem 1rem' }}
                />
                <Button
                  fullWidth
                  onClick={() => {
                    if (
                      groupNameRef.current.value !==
                      groups[listEvent.clicked].groupName
                    ) {
                      const newName = groupNameRef.current.value;
                      const newGroup = groups;
                      newGroup[listEvent.clicked] = {
                        ...newGroup[listEvent.clicked],
                        groupName: newName,
                      };
                      setGroups(newGroup);
                      setListEvent({ ...listEvent, clicked: null });
                      updateGroup(groups[listEvent.clicked]);
                    }
                  }}
                  sx={{ p: '.4rem .8rem' }}
                >
                  저장
                </Button>
              </Box>
            </DetailPaper>
          </Zoom>
        </Box>
      </Paper>
      <CommunityGroupMemberChange
        open={groupMemberChange}
        onClose={() => {
          setGroupMemberChange(!groupMemberChange);
          communityGroupRefetch();
        }}
        onChange={() => {
          setGroupMemberChange(!groupMemberChange);
          communityGroupRefetch();
        }}
        groups={groups}
        communityId={communityData && communityData.communityId}
        communityMembers={communityMembers}
        setCommunityMembers={setCommunityMembers}
      />
      <SwSnackbar
        open={false}
        onClose={() => setOpenDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      {(openMore === 1 || openMore === 2) && (
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography sx={{ mt: '0.7rem' }}>{dialogMessage}</Typography>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => {
                openMore === 1 ? deleteGroup() : deleteSelectedGroup();
              }}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
