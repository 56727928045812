import { Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import fin from '../../images/fin.svg';
import failfin from '../../images/failfin.svg';

interface Props {
  markerData: any;
}

export const SwNaverMap: React.FC<Props> = ({ markerData }) => {
  const [markers, setMarkers] = useState<any>([]);

  const MapRef = useRef<any>();
  const initMap = () => {
    MapRef.current = new naver.maps.Map('map', {
      center: new naver.maps.LatLng(markers[0]?.lat, markers[0]?.lng),
      zoom: 17,
      scaleControl: false,
      zoomControl: true,
      minZoom: 6,
    });
    MapRef.current.setOptions({
      draggable: true,
      pinchZoom: true,
      scrollWheel: true,
      keyboardShortcuts: false,
      disableDoubleTapZoom: true,
      disableDoubleClickZoom: true,
      disableTwoFingerTapZoom: true,
    });

    markers.map((marker: any) => {
      const newMarkers = new naver.maps.Marker({
        position: new naver.maps.LatLng(marker.lat, marker.lng),
        map: MapRef.current,
        icon: {
          content: marker.content,
        },
      });
    });
  };

  useEffect(() => {
    initMap();
  }, [MapRef]);

  useEffect(() => {
    if (markers && markers.length > 0) {
      initMap();
    }
  }, [markers]);

  useEffect(() => {
    if (markerData && markerData.length > 0) {
      setMarkers(markerData);
    }
  }, [markerData]);

  const mapStyle = {
    width: '100%',
    height: '900px',
  };

  return (
    <Paper>
      <div id='map' style={mapStyle} ref={MapRef} />
    </Paper>
  );
};
