import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';

import { ProgramManageGuideDrawUp } from './ProgramManageGuideDrawUp';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SelectTextField } from '../../styles/Styles';

import {
  getOperationGuideList,
  deleteOperationGuide,
  putOperationGuideViewCount,
} from '../../../common/api/ApiOperation';
import { KEY_OPERATION_GUIDE_LIST } from '../../../common/key';
import { IOperationGuide } from '../../../models/operation';
import {
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';

interface Props {}

const columns = ['제목', '작성자', '작성일시', '조회수'];

export const ProgramManageGuide: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const [showDrawup, setShowDrawup] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [guideList, setGuideList] = useState<any>([]);
  const [selectGuide, setSelectGuide] = useState<any>(null);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: operationGuideRefetch,
  } = useQuery(
    KEY_OPERATION_GUIDE_LIST,
    () => {
      const newData = {
        type: 'program',
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (checkRequest) return getOperationGuideList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newGuideList =
          res &&
          res.operationGuide &&
          res.operationGuide.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate);
            const newData = {
              idx: String(item.idx),
              title: item.title,
              content: item.content,
              creator: item.creator,
              createDate: newDate,
              viewCount: item.viewCount,
              image: item.image,
              imageUrlPrefix: item.imageUrlPrefix,
            };
            return newData;
          });
        setGuideList(newGuideList);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_OPERATION_GUIDE_LIST, () => {
      const newData = {
        type: 'program',
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      return getOperationGuideList(newData);
    });
  };

  const { mutate: putOperationGuideViewMutate, isError: isPutViewMutateError } =
    useMutation(putOperationGuideViewCount, {
      onSuccess: () => {
        setOpenSnackbar(false);
        setDialogMessage('운영가이드 조회수를 수정했습니다.');
      },
      onError: error => {
        setOpenSnackbar(false);
        setDialogMessage(
          '운영가이드 조회수 수정이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const { mutate: deleteOperationGuideMutation, isError: isDeleteMutateError } =
    useMutation(deleteOperationGuide, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('운영가이드를 삭제했습니다.');
        operationGuideRefetch();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '운영가이드를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const deleteGuide = () => {
    const idxList = checkedButtons.map((item: string) => Number(item));
    const newData: IOperationGuide = {
      idxList: idxList,
    };
    deleteOperationGuideMutation(newData);
  };

  const clickOperationGuide = (idx: number) => {
    console.log('idx: ', Number(idx), ', guide: ', guideList[idx]);
    setIsEdit(true);
    setSelectGuide(guideList[idx]);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      guideList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.idx]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  useEffect(() => {
    if (selectGuide !== null) {
      const newData: any = {
        idx: selectGuide.idx,
      };
      putOperationGuideViewMutate(newData);
      setShowDrawup(true);
    }
  }, [selectGuide]);

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box className='flex_end'>
        <Button
          variant='contained'
          onClick={() => {
            setSelectGuide(null);
            setIsEdit(false);
            setShowDrawup(true);
          }}
          sx={{ pl: '.5rem' }}
        >
          <AddIcon sx={{ fontSize: '1.5rem', mr: '.3rem' }} />
          작성하기
        </Button>
      </Box>
      <Paper sx={{ p: '1rem 1.5rem', m: '2rem 0' }}>
        <Box className='flex_between'>
          <TableTitle title='게시물' count={totalRecords} />
          <SelectTextField
            select
            value={rowsPerPage}
            onChange={evt => {
              setRowsPerPage(Number(evt.target.value));
              setPage(1);
            }}
          >
            {rowsPerPageOptions.map(option => (
              <MenuItem key={option.toString()} value={option}>
                {option}개씩 보기
              </MenuItem>
            ))}
          </SelectTextField>
        </Box>
        <Paper>
          <Table>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell
                    key={idx.toString()}
                    align={idx === 2 || idx === 3 ? 'right' : 'inherit'}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {guideList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  <TableCell padding='checkbox'>
                    <Checkbox
                      id={row.idx}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, row.idx);
                      }}
                      checked={checkedButtons.includes(row.idx)}
                    />
                  </TableCell>
                  <TableCell onClick={() => clickOperationGuide(idx)}>
                    {row.title}
                  </TableCell>
                  <TableCell onClick={() => clickOperationGuide(idx)}>
                    {row.creator}
                  </TableCell>
                  <TableCell
                    align='right'
                    onClick={() => clickOperationGuide(idx)}
                  >
                    {row.createDate}
                  </TableCell>
                  <TableCell
                    align='right'
                    onClick={() => clickOperationGuide(idx)}
                  >
                    {row.viewCount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </Paper>
        <Box className='flex_end' sx={{ m: '1rem 0' }}>
          <Button variant='outlined' color='info' onClick={() => deleteGuide()}>
            삭제
          </Button>
        </Box>
      </Paper>
      <ProgramManageGuideDrawUp
        open={showDrawup}
        onClose={() => {
          setShowDrawup(false);
          setSelectGuide(null);
          operationGuideRefetch();
        }}
        onConfirm={() => {
          setShowDrawup(false);
          setSelectGuide(null);
          operationGuideRefetch();
        }}
        edit={isEdit}
        isEdit={() => setIsEdit(!isEdit)}
        selected={selectGuide}
      />
      <SwSnackbar
        open={false}
        onClose={() => setOpenDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};
