import { isArrayLiteralExpression } from 'typescript';
import { v4 as uuidv4 } from 'uuid';
import { reactQueryClient } from '..';
import { useSession } from '../hooks/session';

export function getQuestionAnswerRegisterStatus(registerStatus: number) {
  return registerStatus === 1
    ? '작성중'
    : registerStatus === 2
    ? '접수요청'
    : registerStatus === 3
    ? '수정요청'
    : registerStatus === 4
    ? '접수완료'
    : '미지정';
}

export function getFileSize(newFile: File) {
  return Math.floor(newFile !== null ? newFile.size / (1024 * 1024) : 0);
}
