import {
  Box,
  Button,
  Divider,
  MenuItem,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { SelectTextField, TableButton } from '../../styles/Styles';
import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  KEY_CHALLENGE_LOCAL_STORE_LIST,
  KEY_LOCAL_STORE_OWNER_STORE_LIST,
} from '../../../common/key';
import { getLocalStoreOwnerStoreList } from '../../../common/api/ApiLocalstore';
import { getChallengeLocalStoreList } from '../../../common/api/ApiChallenge';

interface Props {
  challengeInfo: any;
}

const columns = ['가게 이름', '가게 아이디', '주소', '업종', '등록 일시'];

const CreatePaper = styled(Paper)({
  // margin: '2rem',
  padding: '1rem',
  cursor: 'pointer',
});

const PaginationBox = styled(Box)({
  padding: '.6rem',
  display: 'flex',
  justifyContent: 'center',
});

export const ChallengeLocalStore: React.FC<Props> = ({ challengeInfo }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [requestList, setRequestList] = useState<any>([]);
  const [localStoreList, setLocalStoreList] = useState<any>([]);
  const [localStoreInfo, setLocalStoreInfo] = useState<any>();
  const [challengeId, setChallengeId] = useState<any>('');
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: challengeLocalStoreRefetch,
  } = useQuery(
    KEY_CHALLENGE_LOCAL_STORE_LIST,
    () => {
      if (challengeId.length > 0) {
        const newData: any = {
          page: 0,
          rowsPerPage: 0,
          challengeId: challengeId,
        };
        return getChallengeLocalStoreList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res && res.store) {
          const newStore = res.store.map((item: any) => {
            const createDate = timestamp2Localestring(item.createdDate, 1000);
            const newData = [
              item.storeName,
              item.storeId,
              item.address,
              item.categoryName,
              createDate,
            ];
            return newData;
          });
          setRequestList(newStore);
          setLocalStoreList(res.store);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const challengeLocalStorePrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_CHALLENGE_LOCAL_STORE_LIST, () => {
      const newData: any = {
        page: 0,
        rowsPerPage: 0,
        challengeId: challengeId,
      };
      getChallengeLocalStoreList(newData);
    });
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const clickRow = (event: any, idx: number) => {
    setSelectedRow(idx);
    setLocalStoreInfo(localStoreList[idx]);
  };

  useEffect(() => {
    if (challengeInfo) challengeLocalStoreRefetch();
  }, [page]);

  useEffect(() => {
    if (challengeId && challengeId.length > 0) challengeLocalStoreRefetch();
  }, [challengeId]);

  useEffect(() => {
    if (challengeInfo) {
      setSelectedRow(-1);
      setChallengeId(challengeInfo.challengeId);
    }
  }, [challengeInfo]);

  return (
    <>
      <CreatePaper>
        {`가게 ${totalRecords}개`}
        <TableContainer sx={{ mt: '1rem', maxHeight: '27rem' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell
                    key={`store_head_${idx}`}
                    className={clsx(
                      { request_head: true },
                      'request_tablehead'
                    )}
                    sx={{ p: '.4rem .7rem !important' }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody sx={{ maxHeight: '10rem', overflowY: 'scroll' }}>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                  onClick={event => clickRow(event, idx)}
                >
                  {row.map((r: any, i: number) => (
                    <TableCell
                      key={`store_key_${i}`}
                      className={clsx('request_tablecell ')}
                      sx={{ p: '.6rem .7rem' }}
                    >
                      {r}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {(requestList === null || requestList.length === 0) && (
                <Typography sx={{ mt: '2rem', mb: '2rem' }}>
                  데이터가 없습니다.
                </Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </CreatePaper>
      <CreatePaper sx={{ mt: '2rem' }}>
        <Box>
          <Typography className='title'>가게 이름</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={localStoreInfo?.storeName}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>가게 아이디</Typography>
          <TextField sx={{ width: '48rem' }} value={localStoreInfo?.storeId} />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>사업자 등록번호</Typography>
          <TextField
            sx={{ width: '24rem' }}
            value={localStoreInfo?.businessNumber}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>대표자 이름</Typography>
          <TextField
            sx={{ width: '24rem' }}
            value={localStoreInfo?.storeOwnerName}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>주소</Typography>
          <TextField sx={{ width: '24rem' }} value={localStoreInfo?.address} />
          <Box className='flex_start'>
            <TextField
              sx={{ width: '10rem', mr: '1rem' }}
              value={localStoreInfo?.storeLocationLat}
            />
            <TextField
              sx={{ width: '10rem' }}
              value={localStoreInfo?.storeLocationLng}
            />
          </Box>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>업종</Typography>
          <TextField
            sx={{ width: '20rem' }}
            value={localStoreInfo?.categoryName}
          />
        </Box>
        <Divider />
        <Box>
          <Typography className='title'>사장님 계정 아이디</Typography>
          <TextField sx={{ width: '48rem' }} value={localStoreInfo?.ownerId} />
          <Divider />
        </Box>
        <Divider />
        <Box>
          <Typography className='title'>휴대폰 번호</Typography>
          <TextField
            sx={{ width: '24rem' }}
            value={localStoreInfo?.ownerPhoneno}
          />
          <Divider />
        </Box>
      </CreatePaper>
    </>
  );
};
