import React from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { Advertiser } from './Advertiser';

interface Props {}

export const AdvertiserManagement: React.FC<Props> = () => {
  const { isMobile } = useResize();
  return (
    <div
      className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}
      style={{ padding: '3rem 2rem' }}
    >
      <Helmet>
        <title>광고주</title>
      </Helmet>
      <Advertiser />
    </div>
  );
};
