import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { InquireTable } from '../../commonComponent/table/InquireTable';
import { UserChallengeDetail } from './UserChallengeDetail';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  getUserChallengeList,
  downloadUserChallengeList,
  getUserProgramList,
  downloadUserProgramList,
  getUserBrandPointHistory,
  drawManualBrandPoint,
} from '../../../common/api/ApiUser';
import {
  KEY_CHALLENGE_BRAND_POINT_ADVERTISER_LIST,
  KEY_USER_BRAND_POINT_HISTORY,
  KEY_USER_CHALLENGE_LIST,
  KEY_USER_PROGRAM_LIST,
} from '../../../common/key';
import { timestamp2Localestring } from '../../../common/helper';
import {
  deleteChallengeAttendUser,
  getChallengeBrandPointAdvertiserList,
} from '../../../common/api/ApiChallenge';
import { programSearchOption } from '../../../common/helperProgram';
import { UserProgramDetail } from './UserProgramDetail';
import { brandPointAdvertiserState } from '../../../common/atom';
import { SwUserPayBrandPoint } from '../../commonComponent/SwUserPayBrandPoint';
import { RESULT_OK } from '../../../common/resultCode';

const columns = [
  '일시',
  '포인트 이름',
  '경로',
  '남은 유효기간',
  '획득 내역',
  '차감 내역',
  '현재 포인트',
  '누적 포인트',
];

const NO_SELECT_ADVERTISER = 99;

interface Props {}

export const UserPoint: React.FC<Props> = () => {
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const location = useLocation();
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [brandPointId, setBrandPointId] = useState<string>('');
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [brandPointHistory, setBrandPointHistory] = useState<any>([]);
  const [brandPointIndex, setBrandPointIndex] =
    useState<number>(NO_SELECT_ADVERTISER);
  const [userData, setUserData] = useState<any>(location.state);
  const [challengeId, setChallengeId] = useState('');
  const [selectRow, setSelectRow] = useState<number>(-1);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const [openUserBrandPoint, setOpenUserBrandPoint] = useState<boolean>(false);
  const isOpen = () => setOpenDetail(!openDetail);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { refetch: challengeBrandPointAdvertiserRefetch } = useQuery(
    KEY_CHALLENGE_BRAND_POINT_ADVERTISER_LIST,
    () => {
      const newData: any = {
        searchKey: 'all',
        searchWord: '',
      };
      if (userData.id !== '')
        return getChallengeBrandPointAdvertiserList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (
          res !== null &&
          res.advertiser !== undefined &&
          res.advertiser !== null
        ) {
          setBrandPointAdvertiserList(res.advertiser);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    data,
    isLoading,
    refetch: brandPointHistoryRefetch,
  } = useQuery(
    KEY_USER_BRAND_POINT_HISTORY,
    () => {
      if (userData.id !== '') {
        const newData: any = {
          userId: userData.id,
          brandPointId: brandPointId,
          page: page,
          rowsPerPage: rowsPerPage,
          startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
          endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
        };
        return getUserBrandPointHistory(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== undefined && res !== null && res.history !== null) {
          setBrandPointHistory(res.history);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number, newBrandPointId: string) => {
    queryClient.prefetchQuery(KEY_USER_BRAND_POINT_HISTORY, () => {
      const newData: any = {
        userId: userData.id,
        brandPointId: newBrandPointId,
        page: newPage,
        rowsPerPage: rowsPerPage,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
      };
      return getUserBrandPointHistory(newData);
    });
  };

  const { mutate: drawManualBrandPointMutate, isError: isCouponMutateError } =
    useMutation(drawManualBrandPoint, {
      onSuccess: res => {
        if (res.resultCode === Number(RESULT_OK)) {
          if (res.rewardDraw !== undefined) {
            const newRslt = res.rewardDraw[0].status;
            setOpenSnackbar(true);
            setDialogMessage(
              newRslt === '성공' ? '포인트를 지급했습니다.' : newRslt
            );
          } else if (res.rewardWithDraw !== undefined) {
            const newRslt = res.rewardWithDraw[0].status;
            setOpenSnackbar(true);
            setDialogMessage(
              newRslt === '성공' ? '포인트를 회수했습니다.' : newRslt
            );
          }
          prefetch(1, brandPointId);
        } else {
          setOpenSnackbar(true);
          setDialogMessage(`포인트처리 실패 : ${res.resultCodeMsg}`);
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '포인트 처리동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage, brandPointId);
  };

  const putBrandPointUser = (payInfo: any) => {
    const newRequest = {
      drawType: 'draw-point',
      brandPointId: payInfo.brandPointId,
      point: payInfo.point,
      message: payInfo.message,
      userIds: [userData.id],
    };
    drawManualBrandPointMutate(newRequest);
  };

  const delBrandPointUser = (payInfo: any) => {
    const newRequest = {
      drawType: 'withdraw-point',
      brandPointId: payInfo.brandPointId,
      point: payInfo.point,
      message: payInfo.message,
      userIds: [userData.id],
    };
    drawManualBrandPointMutate(newRequest);
  };

  const payBrandPoint = (payInfo: any) => {
    const newPayInfo = {
      ...payInfo,
      brandPointId: brandPointAdvertiserList[brandPointIndex].brandPointId,
    };
    payInfo.payType === 1
      ? putBrandPointUser(newPayInfo)
      : payInfo.payType === 2
      ? delBrandPointUser(newPayInfo)
      : '';
  };

  useEffect(() => {
    if (brandPointIndex !== NO_SELECT_ADVERTISER) {
      const newBrandPointId =
        brandPointAdvertiserList[brandPointIndex].brandPointId;
      prefetch(1, newBrandPointId);
    } else {
      prefetch(1, '');
    }
  }, [brandPointIndex]);

  useEffect(() => {
    if (userData.id !== '') brandPointHistoryRefetch();
  }, [userData]);

  useEffect(() => {
    if (location.state) {
      setUserData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    setPage(1);
    brandPointHistoryRefetch();
  }, [rangeDate]);

  if (isLoading) return <div>isLoading...</div>;

  return (
    <>
      <Paper sx={{ p: '.5rem 1rem', m: '1rem 0' }}>
        <Box className='flex_between'>
          <Box>
            <Typography className='title'>포인트 지급 브랜드</Typography>
            <Box>
              <TextField
                select
                sx={{ width: '10rem', mr: '.5rem' }}
                value={brandPointIndex}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setBrandPointIndex(Number(event.target.value));
                }}
              >
                <MenuItem key='advertiser_99' value={NO_SELECT_ADVERTISER}>
                  선택
                </MenuItem>
                {brandPointAdvertiserList.map((item: any, idx: number) => (
                  <MenuItem key={`advertiser_${idx}`} value={idx}>
                    {item.pointName}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box>
            <Button
              disabled={brandPointIndex === NO_SELECT_ADVERTISER}
              variant='outlined'
              color='primary'
              onClick={() => setOpenUserBrandPoint(true)}
            >
              수동 지급/차감
            </Button>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            p: '1rem .5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography component='div'>
            포인트 내역{' '}
            <span style={{ color: '#5891FF' }}>({totalRecords})</span>
          </Typography>
          <Box className='flex_center'>
            <SwDateRangePicker
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
            />
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {brandPointHistory?.map((row: any, idx: number) => (
                <TableRow key={`brand_point_idx${idx}`}>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {timestamp2Localestring(row.createDate, 1000)}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.pointName}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.comment}</TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.remainDay !== -1 ? `${row.remainDay} 일` : '-'}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.earnedPoint}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.deductedPoint}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.currentPoint}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.getPoint}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <SwUserPayBrandPoint
        open={openUserBrandPoint}
        onClose={() => setOpenUserBrandPoint(false)}
        onChangePayInfo={payInfo => {
          payBrandPoint(payInfo);
          setOpenUserBrandPoint(false);
        }}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
