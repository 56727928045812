import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Common
 */

export const getContentManageCategoryList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/content-manager/category?type=${data.type}&parentCategoryId=${data.parentCategoryId}&status=${data.status}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}&startDate=${data.startDate}&endDate=${data.endDate}&sortWay=${data.sortWay}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerContentManageCategory = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/content-manager/category`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteContentManageCategory = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/program/content-manager/category/${data.categoryId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getContentManageKeywordList = (data: any) =>
  xapi
    .get(`/admin/v2/apis/program/content-manager/keyword?type=${data.type}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getContentManageMap = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/content-manager/map?type=${data.type}&sortWay=${data.sortWay}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

/*
 * Content
 */
export const getContentManageContentList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/content-manager/content?type=${data.type}&categoryId=${data.categoryId}&status=${data.status}&favorite=${data.favorite}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}&startDate=${data.startDate}&endDate=${data.endDate}&sortWay=${data.sortWay}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getContentManageContentDetailInfo = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/content-manager/content/${data.contentId}/detail`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerContentManageContent = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/content-manager/content/${data.type}`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteContentManageContent = (data: any) =>
  xapi
    .delete(`/admin/v2/apis/program/content-manager/content/${data.contentId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const generateContentManageMagazineHtml = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/content-manager/content/magazine/html`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const batchGenerateMagazineHtml = () =>
  xapi
    .post(`/admin/v2/apis/program/content-manager/content/magazine/batch-html`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

/*
 * target
 */

export const getContentManageTargetList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/content-manager/target?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
