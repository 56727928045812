import {
  Box,
  Button,
  Divider,
  InputAdornment,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { xapi } from '../../../hooks/session';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwImageView } from '../../commonComponent/SwImageView';
import { SwSimpleDatesPicker } from '../../commonComponent/dateSetting/SwSimpleDatesPicker';
import { MultilineTextField } from '../../styles/Styles';
import {
  timestamp2string,
  checkImageFileName,
  timestamp2Localestring,
} from '../../../common/helper';
import { IUploadFile } from '../../../models/common';
import { IDataPortalQnA } from '../../../models/dataportal';
import { postQnARegister } from '../../../common/api/ApiDataPortal';
import { uploadFile } from '../../../common/api/ApiUpload';

interface Props {
  request: any;
  setReload: Dispatch<React.SetStateAction<any>>;
}

const DownloadBox = styled(Box)({
  display: 'flex',
});

const ContentTitle = styled(Typography)({
  padding: '.5rem',
  marginBottom: '.5rem',
  fontWeight: 500,
});

export const PrivateInquiryContents: React.FC<Props> = ({
  request,
  setReload,
}) => {
  const fileInputRef1 = useRef<any>();
  const fileInputRef2 = useRef<any>();
  const fileInputRef3 = useRef<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [adminName, setAdminName] = useState<string>('');
  const [adminComment, setAdminComment] = useState<string>('');
  const [completeDate, setCompleteDate] = useState<any>();
  const [selectDate, setSelectDate] = useState<any>();
  const [uploadFile1, setUploadFile1] = useState<any>(null);
  const [uploadFile2, setUploadFile2] = useState<any>(null);
  const [uploadFile3, setUploadFile3] = useState<any>(null);
  const [uploadFileName1, setUploadFileName1] = useState<any>('');
  const [uploadFileName2, setUploadFileName2] = useState<any>('');
  const [uploadFileName3, setUploadFileName3] = useState<any>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openDownloadImage, setOpenDownloadImage] = useState<boolean>(false);

  var uploadUrl1: string = '';
  var uploadUrl2: string = '';
  var uploadUrl3: string = '';

  const { mutate: registerQnAMutation, isError: isMutateError } = useMutation(
    postQnARegister,
    {
      onSuccess: () => {
        setReload(true);
        setIsDisabled(true);
        setOpenSnackbar(true);
        setDialogMessage(
          isUpdate ? '답변을 수정했습니다.' : '답변이 추가되었습니다.'
        );
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          isUpdate
            ? '답변을 수정하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
            : '답변을 추가하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    }
  );

  const registerQnA = (newUploadUrl?: any) => {
    console.log('registerQnA');
    const newAdminAttachment =
      uploadFile1 !== null
        ? uploadUrl1
        : uploadFileName1 !== null && uploadFileName1 !== ''
        ? uploadFileName1
        : '';

    const newAdminAttachment2 =
      uploadFile2 !== null
        ? uploadUrl2
        : uploadFileName2 !== null && uploadFileName2 !== ''
        ? uploadFileName2
        : '';

    const newAdminAttachment3 =
      uploadFile3 !== null
        ? uploadUrl3
        : uploadFileName3 !== null && uploadFileName3 !== ''
        ? uploadFileName3
        : '';
    const newData: IDataPortalQnA = {
      id: request?.qnaId,
      // adminName: adminName,
      adminComment: adminComment,
      adminAttachment: newAdminAttachment,
      adminAttachment2: newAdminAttachment2,
      adminAttachment3: newAdminAttachment3,
      // completeDate: selectDate.getTime(),
      status: 1,
    };
    console.log('registerQnA newData :', newData);
    registerQnAMutation(newData);
  };

  const checkValid = () => {
    var val = 0;

    if (request?.qnaId === undefined || request?.qnaId.length === 0) val = 4;
    else if (adminComment === null || adminComment.length === 0) val = 2;
    // if (adminName === null || adminName.length === 0) val = 1;
    // else
    // else if (selectDate === null) val = 3;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '답변자 이름을 입력해 주세요.';
          break;
        case 2:
          message = '답변 내용을 입력해 주세요.';
          break;
        case 3:
          message = '문의 답변시간을 입력해주세요.';
          break;
        case 4:
          message = '답변할 문의사항을 선택해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenDialog(true);
    }

    return val;
  };

  async function uploadMultipleFiles() {
    if (uploadFile1 !== null) {
      const newUploadFile1: any = await uploadFile('qna', uploadFile1);
      console.log('newUploadFile1 :', newUploadFile1);
      uploadUrl1 = newUploadFile1.data.uploadUrl.file.path;
    }
    if (uploadFile2 !== null) {
      const newUploadFile2: any = await uploadFile('qna', uploadFile2);
      // console.log('newUploadFile2 :', newUploadFile2);
      uploadUrl2 = newUploadFile2.data.uploadUrl.file.path;
    }
    if (uploadFile3 !== null) {
      const newUploadFile3: any = await uploadFile('qna', uploadFile3);
      // console.log('newUploadFile3 :', newUploadFile3);
      uploadUrl3 = newUploadFile3.data.uploadUrl.file.path;
    }
    registerQnA();
  }

  const checkQnA = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }
    if (uploadFile1 === null && uploadFile2 === null && uploadFile3 === null) {
      registerQnA();
    } else {
      uploadMultipleFiles();
    }
  };

  // const checkQnA = () => {
  //   if (checkValid() !== 0) {
  //     console.log('invalid !!!');
  //     return;
  //   }
  //   if (uploadFile1 === null) {
  //     registerQnA();
  //   } else {
  //     const uploadfile: IUploadFile = {
  //       type: 'qna',
  //       file: uploadFile1,
  //     };
  //     console.log('checkQnA uploadfile :', uploadfile);
  //     var formData = new FormData();
  //     formData.append('file', uploadFile1);
  //     formData.append('fileSub1', uploadFile2);
  //     formData.append('fileSub2', uploadFile3);

  //     xapi
  //       .post(`/admin/v2/apis/upload/${uploadfile.type}`, formData)
  //       .then(res => {
  //         if (res.status !== 200) throw new Error('http 에러');
  //         console.log('UploadUrl :', res.data);
  //         setUploadUrl1(res.data.uploadUrl.file.path);
  //         if (uploadFile2 !== null)
  //           setUploadUrl2(res.data.uploadUrl.fileSub1.path);
  //         else setUploadUrl2('');
  //         if (uploadFile3 !== null)
  //           setUploadUrl3(res.data.uploadUrl.fileSub2.path);
  //         else setUploadUrl3('');

  //         registerQnA(res.data.uploadUrl);

  //         return res.data;
  //       });
  //   }
  // };

  // useEffect(() => {
  //   if (selectDate) {
  //     const newDate = selectDate
  //       .toLocaleString('ko-KR', { hour12: false })
  //       .slice(
  //         0,
  //         selectDate.toLocaleString('ko-KR', { hour12: false }).length - 3
  //       );
  //     console.log('selectDate: ', newDate);
  //   }
  // }, [selectDate]);

  useEffect(() => {
    console.log('QnA :', request);
    const newComment = request?.adminComment ? request?.adminComment : '';
    setAdminName(request?.adminName ? request?.adminName : '');
    setAdminComment(newComment);
    setIsDisabled(newComment.length > 0);
    setCompleteDate(
      request?.completeDate ? timestamp2string(request?.completeDate) : ''
    );
    // setSelectDate(
    //   request?.completeDate ? new Date(request?.completeDate) : null
    // );
    setUploadFile1(null);
    setUploadFile2(null);
    setUploadFile3(null);
    const newAdminAttachment =
      request?.adminAttachment === null || request?.adminAttachment === ''
        ? ''
        : request?.adminAttachment;
    setUploadFileName1(newAdminAttachment);
    const newAdminAttachment2 =
      request?.adminAttachment2 === null || request?.adminAttachment2 === ''
        ? ''
        : request?.adminAttachment2;
    setUploadFileName2(newAdminAttachment2);
    const newAdminAttachment3 =
      request?.adminAttachment3 === null || request?.adminAttachment3 === ''
        ? ''
        : request?.adminAttachment3;
    setUploadFileName3(newAdminAttachment3);
    setIsUpdate(request?.des === 0);
  }, [request]);

  return (
    <>
      <Paper sx={{ m: '2rem 0', padding: '2rem' }}>
        <Typography variant='h5' sx={{ fontWeight: 700, p: '1rem 0' }}>
          {request?.title}
        </Typography>
        <Box className='flex_start'>
          <Typography color='#999999' sx={{ mr: '1rem' }}>
            문의 일자 :{' '}
            {request?.requestDate
              ? timestamp2Localestring(request?.requestDate)
              : ''}
          </Typography>
          <Typography color='#999999'>
            질문 답변{' '}
            {request?.completeDate
              ? timestamp2Localestring(request?.completeDate)
              : ''}
          </Typography>
        </Box>
        <Divider />
        <MultilineTextField
          multiline
          rows={10}
          fullWidth
          defaultValue={request?.content}
        />
        <Divider />
        <ContentTitle>첨부된 파일</ContentTitle>
        <Box className='flex_center'>
          <TextField
            disabled
            sx={{ width: '28rem', m: '0 .5rem 0 0' }}
            value={
              request?.attachment
                ? String(request?.attachment).split('/').reverse()[0]
                : ''
            }
          />
          {request?.attachment && (
            <Button
              variant='contained'
              onClick={() => {
                if (
                  checkImageFileName(
                    `${request?.imageUrlPrefix}${request?.attachment}`
                  ) === true
                ) {
                  setOpenDownloadImage(true);
                } else {
                  const qqq = document.getElementById('ttt');

                  qqq?.click();
                }
              }}
            >
              다운로드
            </Button>
          )}
          <a
            href={`${request?.imageUrlPrefix}${request?.attachment}`}
            id='ttt'
            style={{ display: 'none' }}
          >
            tt
          </a>
        </Box>
        <Divider />
        <ContentTitle>답변</ContentTitle>
        <MultilineTextField
          disabled={isDisabled}
          fullWidth
          multiline
          rows={6}
          placeholder='답변을 입력해주세요.'
          value={adminComment}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setAdminComment(evt.target.value)}
        />
        <ContentTitle>파일 첨부하기</ContentTitle>
        <Box className='flex_center' sx={{ mb: '1rem' }}>
          <TextField
            disabled={isDisabled}
            sx={{ width: '28rem', m: '0 .5rem 0 0' }}
            value={uploadFile1 !== null ? uploadFile1?.name : uploadFileName1}
          />
          <Button
            disabled={isDisabled}
            variant='contained'
            onClick={() => {
              const newFile1 = document.getElementById('adminfile1');
              newFile1?.click();
            }}
          >
            파일 선택
          </Button>
          <Button
            sx={{ ml: '1rem' }}
            disabled={isDisabled}
            variant='outlined'
            onClick={() => {
              setUploadFile1(null);
              setUploadFileName1('');
            }}
          >
            지우기
          </Button>
          <input
            className='nonedisplay'
            id='adminfile1'
            type='file'
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              evt.target.files && setUploadFile1(evt.target.files[0]);
            }}
          />
        </Box>
        <Box className='flex_center' sx={{ mb: '1rem' }}>
          <TextField
            disabled={isDisabled}
            sx={{ width: '28rem', m: '0 .5rem 0 0' }}
            value={uploadFile2 !== null ? uploadFile2?.name : uploadFileName2}
          />
          <Button
            disabled={isDisabled}
            variant='contained'
            onClick={() => {
              const newFile1 = document.getElementById('adminfile2');
              newFile1?.click();
            }}
          >
            파일 선택
          </Button>
          <Button
            sx={{ ml: '1rem' }}
            disabled={isDisabled}
            variant='outlined'
            onClick={() => {
              setUploadFile2(null);
              setUploadFileName2('');
            }}
          >
            지우기
          </Button>
          <input
            className='nonedisplay'
            id='adminfile2'
            type='file'
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              evt.target.files && setUploadFile2(evt.target.files[0]);
            }}
          />
        </Box>
        <Box className='flex_center' sx={{ mb: '1rem' }}>
          <TextField
            disabled={isDisabled}
            sx={{ width: '28rem', m: '0 .5rem 0 0' }}
            value={uploadFile3 !== null ? uploadFile3?.name : uploadFileName3}
          />
          <Button
            disabled={isDisabled}
            variant='contained'
            onClick={() => {
              const newFile1 = document.getElementById('adminfile3');
              newFile1?.click();
            }}
          >
            파일 선택
          </Button>
          <Button
            sx={{ ml: '1rem' }}
            disabled={isDisabled}
            variant='outlined'
            onClick={() => {
              setUploadFile3(null);
              setUploadFileName3('');
            }}
          >
            지우기
          </Button>
          <input
            className='nonedisplay'
            id='adminfile3'
            type='file'
            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
              evt.target.files && setUploadFile3(evt.target.files[0]);
            }}
          />
        </Box>
        <Divider />
        {/* <ContentTitle>문의 답변 시간 / 답변자</ContentTitle> */}
        {/* <TextField
          disabled={isDisabled}
          sx={{ width: '18rem', mr: '.5rem' }}
          value={completeDate}
        /> */}
        {/* <Box className='flex_start'>
          <SwSimpleDatesPicker
            title='문의 답변시간'
            disabled={isDisabled}
            date={selectDate}
            setDate={setSelectDate}
          />
          <TextField
            disabled={isDisabled}
            sx={{ width: '18rem', ml: '1rem' }}
            value={adminName}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setAdminName(evt.target.value)}
          />
        </Box> */}
      </Paper>
      <Box sx={{ p: '1.5rem 0', display: 'flex', justifyContent: 'flex-end ' }}>
        <Button
          variant='outlined'
          color='info'
          sx={{ mr: '1rem' }}
          onClick={() => setIsDisabled(false)}
        >
          수정
        </Button>
        <Button variant='contained' color='info' onClick={checkQnA}>
          답변 완료
        </Button>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwImageView
        image={`${request?.imageUrlPrefix}${request?.attachment}`}
        open={openDownloadImage}
        onClose={() => setOpenDownloadImage(false)}
      />
    </>
  );
};
