import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IGuideTool } from '../../models/challenge';
import { xapi } from '../../hooks/session';
import { IUser } from '../../models/user';
import { throwError } from '../helper';

/*
 * User
 */

export const getUserList = (
  page: number,
  rowsPerPage: number,
  searchKey: string,
  searchWord: string
) =>
  xapi
    .get(
      `/admin/v2/apis/user/users?page=${page}&per_page=${rowsPerPage}&searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getUserListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/attend-user/byPhonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardDrawUserListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/${data.rewardId}/draw-user/byPhonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getGoalAchieverList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/${data.rewardId}/goal-achiever/${data.type}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getUserDetailInfo = (id: string) =>
  xapi.get(`/admin/v2/apis/user/${id}/detail`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const patchUserEmail = (data: IUser) =>
  xapi
    .put(`/admin/v2/apis/user/${data.id}/email`, {
      email: data.email,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const patchUserChangeStep = (data: any) =>
  xapi.put(`/admin/v2/apis/user/${data.id}/step`, data);

export const patchUserChangeProgress = (data: any) =>
  xapi.put(`/admin/v2/apis/user/${data.id}/progress`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const patchUserChangeComplete = (data: any) =>
  xapi.put(`/admin/v2/apis/user/${data.id}/complete`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const patchUserDelete = (data: any) =>
  xapi.put(`/admin/v2/apis/user/${data.id}/delete`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const patchUserBlocking = (data: any) =>
  xapi.put(`/admin/v2/apis/user/${data.id}/blocking`, data);

export const patchUserInfo = (data: any) =>
  xapi.put(`/admin/v2/apis/user/${data.id}/info`, data);

export const patchUserProfileEmdCode = (data: any) =>
  xapi.put(`/admin/v2/apis/user/${data.id}/emdCode`, data);

export const downloadUserList = (searchKey: string, searchWord: string) =>
  xapi
    .get(
      `/admin/v2/apis/user/download?searchKey=${searchKey}&searchWord=${searchWord}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getUserCommunityList = (
  id: string,
  page: number,
  rowsPerPage: number,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/community?page=${page}&per_page=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadUserCommunityList = (
  id: string,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/community/download?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_community.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getUserChallengeList = (
  id: string,
  page: number,
  rowsPerPage: number,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/challenge?page=${page}&per_page=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getUserProgramList = (
  id: string,
  page: number,
  rowsPerPage: number,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/program?page=${page}&per_page=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getUserProgramReport = (data: any) =>
  xapi
    .get(`/admin/v2/apis/user/${data.userId}/program/${data.programId}/report`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getUserBrandPointHistory = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/user/${data.userId}/brand-point?brandPointId=${data.brandPointId}&page=${data.page}&per_page=${data.rowsPerPage}&startDate=${data.startDate}&endDate=${data.endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// Brand Point draw/widthdraw
export const drawManualBrandPoint = (data: any) =>
  xapi
    .post(`/admin/v2/apis/user/manual-point-draw/${data.drawType}`, data)
    .then(res => {
      if (res.status !== 200 && res.status !== 409) throwError(res.status);
      return res.data;
    });

export const downloadUserChallengeList = (
  id: string,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/challenge/download?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_challenge.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadUserProgramList = (
  id: string,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/program/download?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_program.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getUserStepList = (
  id: string,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/step?startDate=${startDate}&endDate=${endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadUserStepList = (
  id: string,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/step/download?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_step.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadUserTimelineList = (startDate: number, endDate: number) =>
  xapi
    .get(
      `/admin/v2/apis/user/timeline/download?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_timeline.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getUserDailyStepList = (
  id: string,
  page: number,
  rowsPerPage: number,
  startDate: number,
  endDate: number,
  challengeId?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/dailystep?page=${page}&per_page=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}&challengeId=${challengeId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadUserDailyStepList = (
  id: string,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/dailystep/download?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_dailystep.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getUserWearableList = (
  id: string,
  page: number,
  rowsPerPage: number,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/wearable?page=${page}&per_page=${rowsPerPage}&startDate=${startDate}&endDate=${endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadUserWearableList = (
  id: string,
  startDate: number,
  endDate: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/${id}/wearable/download?startDate=${startDate}&endDate=${endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_wearable.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getUserAuthCodeList = (
  phoneno: string,
  page: number,
  rowsPerPage: number
) =>
  xapi
    .get(
      `/admin/v2/apis/user/phoneno/${phoneno}/authcode?page=${page}&per_page=${rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getUserBlackList = (
  page: number,
  rowsPerPage: number,
  searchKey: string,
  searchWord: string
) =>
  xapi
    .get(
      `/admin/v2/apis/user/black-user?page=${page}&per_page=${rowsPerPage}&searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const controlBlockingUsers = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/user/${
        data.type === 'unblock' ? 'unblocking-user' : 'blocking-user'
      }`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const batchBlockingUsers = (data: any) =>
  xapi.post('/admin/v2/apis/user/batch-blocking-user', data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const updateBlockMemo = (data: any) =>
  xapi.post('/admin/v2/apis/user/block-memo', data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const downloadUserBlackList = (searchKey: string, searchWord: string) =>
  xapi
    .get(
      `/admin/v2/apis/user/black-user/download?searchKey=${searchKey}&searchWord=${searchWord}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'user_black_list.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });
