import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface Props {
  data: any;
}

export const SwBarChart: React.FC<Props> = ({ data }) => {
  const a = 1;
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart width={2000} height={800} data={data}>
        <CartesianGrid strokeDasharray='.1' />
        <XAxis dataKey='time' style={{ fontSize: '.75em' }} />
        <YAxis yAxisId='left' orientation='left' stroke='#8884d8' />
        <YAxis yAxisId='right' orientation='right' stroke='#82ca9d' />
        <Tooltip />
        <Bar yAxisId='left' dataKey='steps' fill='#66BB6A' />
      </BarChart>
    </ResponsiveContainer>
  );
};
