import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';

import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';

import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { newUuidId } from '../../commonComponent/SwUuidCreate';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { MultilineTextField } from '../../styles/Styles';
import {
  checkImageFileName,
  getExpirationPeriod,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';

import { IGuideTool, IChallengePost } from '../../../models/challenge';
import {
  getChallengeAdvertiserList,
  registerChallengeAdvertiser,
  deleteChallengeAdvertiser,
  deleteChallengeAdvertiserBrandPoint,
} from '../../../common/api/ApiChallenge';
import { KEY_CHALLENGE_ADVERTISER_LIST } from '../../../common/key';
import { SwSearchCommunityFilter } from '../../commonComponent/SwSearchCommunityFilter';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwPointUsePlace } from '../../commonComponent/SwPointUsePlace';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';

const columns = ['번호', '광고주 이름', '광고주 생성일시', '삭제'];

const CommunityBox = styled(Box)({
  margin: '.4rem .4rem .4rem 0',
  color: '#777777',
  display: 'flex',
  alignItems: 'center',
});

interface Props {}

export const Advertiser: React.FC<Props> = () => {
  const [requestList, setRequestList] = useState<any>([]);
  const [advertisementList, setAdvertisementList] = useState<any>([]);
  const [advertiserList, setAdvertiserList] = useState<any>([]);
  const [advertisement, setAdvertisement] = useState<any>();
  const [advertiser, setAdvertiser] = useState<any>(0);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [challengeId, setChallengeId] = useState<string>('');
  const [useChallengePost, setUseChallengePost] = useState<number>(0);
  const [advertiserId, setAdvertiserId] = useState<string>('');
  const [advertiserName, setAdvertiserName] = useState<string>('');
  const [advertiseImage, setAdvertiseImage] = useState<string>('');
  const [advertiseImageFile, setAdvertiseImageFile] = useState<any>(null);
  const [advertiseImageUrl, setAdvertiseImageUrl] = useState<any>();
  const [actionText, setActionText] = useState<string>('');
  const [actionUrl, setActionUrl] = useState<string>('');
  const [actionClickCount, setActionClickCount] = useState<number>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [repeatType, setRepeatType] = useState<string>('one');
  const [startDates, setStartDates] = useState<any[]>([]);
  const [endDates, setEndDates] = useState<any[]>([]);
  const [degreeDay, setDegreeDay] = useState<any>('');
  const [useBrandPoint, setUseBrandPoint] = useState<boolean>(false);
  const [brandPointId, setBrandPointId] = useState<string>('');
  const [introductionImage, setIntroductionImage] = useState<string>('');
  const [introductionImageFile, setIntroductionImageFile] = useState<any>(null);
  const [pointName, setPointName] = useState<string>('');
  const [communityFilterList, setCommunityFilterList] = useState<any>([]);
  const [expirationPeriod, setExpirationPeriod] = useState<number>(24);
  const [searchCommunity, setSearchCommunity] = useState<any[]>([]);
  const [searchKey, setSearchKey] = useState<string>('community');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSearchCommunity, setOpenSearchCommunity] =
    useState<boolean>(false);
  const [findDataPortal, setFindDataPortal] = useState<string>('');
  const [connectDataPortal, setConnectDataPortal] = useState<any>([]);
  const [dataPortal, setDataPortal] = useState<any>([]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);

  const resetBrandPointInfo = () => {
    setUseBrandPoint(false);
    setBrandPointId('');
    setIntroductionImage('');
    setIntroductionImageFile(null);
    setPointName('');
    setCommunityFilterList([]);
    setConnectDataPortal([]);
    setDataPortal([]);
    setFindDataPortal('');
  };

  const resetAdvertiser = () => {
    setAdvertiserId('');
    setAdvertiser('');
    setAdvertiserName('');
    setAdvertiseImage('');
    setAdvertiseImageFile(null);

    resetBrandPointInfo();
  };

  const {
    data,
    isLoading,
    refetch: challengeAdvertisementRefetch,
  } = useQuery(
    KEY_CHALLENGE_ADVERTISER_LIST,
    () => {
      const newData: IChallengePost = {
        searchKey: '',
        searchWord: '',
      };
      return getChallengeAdvertiserList(newData);
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res !== null) {
          const newAdList = res.advertiser.map((item: any, idx: number) => {
            const newDate = timestamp2Localestring(item.createTime);
            console.log('newDate: ', newDate);
            const newAd = {
              sequence: idx + 1,
              id: item.id,
              title: item.name,
              createTime: newDate,
            };
            return newAd;
          });
          console.log('newAdList :', newAdList);
          setRequestList(newAdList);
          setAdvertiserList(res.advertiser);
          resetAdvertiser();
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: registerAdvertiserMutation, isError: isRegisterMutateError } =
    useMutation(registerChallengeAdvertiser, {
      onSuccess: () => {
        setOpenSnakerDialog(true);
        setDialogMessage('광고주가 등록되었습니다.');
        challengeAdvertisementRefetch();
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '광고주를 등록하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const registerAdvertiser = (newUploadUrl: any, newIntroduceUrl: any) => {
    const newAdvertiser = advertiserList[advertiser - 1];
    const newUuid = newUuidId();
    const newCustomerUuid = `C_${newUuid}`;
    const newUrl =
      advertiseImageFile !== null && newUploadUrl !== ''
        ? newUploadUrl
        : advertiseImage.length > 0
        ? advertiseImage
        : '';
    const newIntroductionImage =
      introductionImageFile !== null && newIntroduceUrl !== ''
        ? newIntroduceUrl
        : introductionImage.length > 0
        ? introductionImage
        : '';
    const newCreator =
      dataPortal.length > 0
        ? dataPortal.map((item: any) => item.portalId)
        : connectDataPortal !== undefined && connectDataPortal.length > 0
        ? connectDataPortal.map((item: any) => item.portalId)
        : ['ADMIN'];
    const newFilters =
      communityFilterList !== null && communityFilterList.length > 0
        ? communityFilterList.map((item: any) => {
            const newData = {
              filterType: item.filterType === 'community' ? 1 : 2,
              targetId: item.targetId,
            };
            return newData;
          })
        : [];

    const newData: IChallengePost = {
      id: advertiserId.length > 0 ? advertiserId : newUuid,
      cid: newCustomerUuid,
      name: advertiserName,
      profile: newUrl,
      useBrandPoint: useBrandPoint ? 1 : 0,
      brandPointId: brandPointId,
      introductionImage: newIntroductionImage,
      pointName: pointName !== null && pointName !== '' ? pointName : '',
      filters: newFilters,
      creator: newCreator,
      expirationPeriod: expirationPeriod,
    };

    var val = 0;
    if (advertiserName === null || advertiserName.length === 0) val = 1;
    if (val === 0 && newUrl.length === 0) val = 2;
    if (val === 0 && checkImageFileName(newUrl) === false) val = 3;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '광고주 이름을 입력해 주세요.';
          break;
        case 2:
          message = '광고주 이미지를 선택해 주세요.';
          break;
        case 3:
          message = '이미지 파일를 선택해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenDialog(true);
      return;
    }

    console.log('registerAdvertiser : ', newData);
    registerAdvertiserMutation(newData);
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (advertiseImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'advertisement',
        advertiseImageFile
      );
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newIntroduceUploadUrl = '';
    if (introductionImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'advertisement',
        introductionImageFile
      );
      newIntroduceUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    registerAdvertiser(newUploadUrl, newIntroduceUploadUrl);
  }

  const checkAdvertiser = () => {
    uploadMultipleFiles();
  };

  const { mutate: deleteAdvertiserMutation, isError: isDeleteMutateError } =
    useMutation(deleteChallengeAdvertiser, {
      onSuccess: () => {
        setOpenSnakerDialog(true);
        setDialogMessage('광고주가 삭제되었습니다.');
        challengeAdvertisementRefetch();
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '광고주를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: deleteAdvertiserBrandPointMutation,
    isError: isDeleteBrandPointMutateError,
  } = useMutation(deleteChallengeAdvertiserBrandPoint, {
    onSuccess: () => {
      setOpenSnakerDialog(true);
      setDialogMessage('광고주 브랜드 포인트가 삭제되었습니다.');
      challengeAdvertisementRefetch();
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '광고주 브랜드 포인트를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteAdvertiser = (idx: number) => {
    const newData: IChallengePost = {
      idx: idx,
    };
    console.log('deleteAdvertiserMutation :', newData);
    deleteAdvertiserMutation(newData);
  };

  const clickRow = (idx: number) => {
    console.log('click row :', idx, ', advertiser:', advertiserList[idx]);
    setAdvertiserId(advertiserList[idx].id);
    setAdvertiser(advertiserList[idx]);
    setAdvertiserName(advertiserList[idx].name);
    setAdvertiseImageFile(null);
    setAdvertiseImage(
      advertiserList[idx].profile.length > 0 ? advertiserList[idx].profile : ''
    );
    setImageUrlPrefix(advertiserList[idx].imageUrlPrefix);
    setUseBrandPoint(advertiserList[idx].useBrandPoint === 1);
    setBrandPointId(
      advertiserList[idx].useBrandPoint === 1
        ? advertiserList[idx].brandPointId
        : ''
    );
    setIntroductionImage(
      advertiserList[idx].introductionImage !== null
        ? advertiserList[idx].introductionImage
        : ''
    );
    setIntroductionImageFile(null);
    setPointName(advertiserList[idx].pointName);

    const newFilters =
      advertiserList[idx].filters !== undefined &&
      advertiserList[idx].filters !== null
        ? JSON.parse(advertiserList[idx].filters.replace('\n', ''))
        : [];
    const newFilterList =
      newFilters &&
      newFilters.map((item: any) => {
        const newData = {
          filterType: item.FILTER_TYPE === 1 ? 'community' : 'group',
          challengeId: item.ADVERTISER_ID,
          targetId: item.TARGET_ID,
          communityName: item.NAME,
        };
        return newData;
      });
    setCommunityFilterList(newFilterList);

    const newCreatorList =
      advertiserList[idx].creator !== undefined &&
      advertiserList[idx].creator !== null
        ? JSON.parse(advertiserList[idx].creator)
        : [];
    const newPortal = newCreatorList.map((item: any) => {
      const newData = {
        portalId: item,
        name: '',
      };
      return newData;
    });
    setConnectDataPortal(newPortal !== null ? newPortal : []);
    setExpirationPeriod(advertiserList[idx].expirationPeriod);
  };

  const deleteCommunityFilter = (index: number, from: string) => {
    const newSomething = communityFilterList.filter(
      (_val: any, i: number) => i !== index
    );
    setCommunityFilterList([...newSomething]);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      // addCommunity();
      setOpenSearchCommunity(true);
    }
  };

  const channgeUseBrandPoint = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setBrandPointId(newUuidId());
      setUseBrandPoint(true);
    } else {
      setDialogMessage(
        '브랜드 포인트 사용을 취소하시겠습니까?\n기존 사용처 내역이 모두 삭제됩니다.'
      );
      setOpenConfirm(true);
    }
  };

  const onConfirm = () => {
    // if (brandPointId !== null && brandPointId.length > 0) {
    //   const newData: any = {
    //     advertiserId: advertiserId,
    //     brandPointId: brandPointId,
    //   };
    //   deleteAdvertiserBrandPointMutation(newData);
    // }
    resetBrandPointInfo();
  };

  useEffect(() => {
    const newFilterList = communityFilterList.map((item: any) => item.targetId);
    const newSearchResult = searchCommunity.filter(
      (item: any) => !newFilterList.includes(item.targetId)
    );
    const newData = [...communityFilterList, ...newSearchResult];
    setCommunityFilterList(newData);
    return () => {};
  }, [searchCommunity]);

  useEffect(() => {
    console.log('startDates :', startDates);
  }, [startDates]);

  useEffect(() => {
    console.log('advertiserList: ', advertiserList);
  }, [advertiserList]);

  useEffect(() => {
    challengeAdvertisementRefetch();
  }, [challengeId]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Paper>
        <Typography sx={{ m: '1rem 0 ' }}>광고주 목록</Typography>
        <TableContainer
          sx={{ p: '0 1rem', height: '20vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  className={
                    row.id === advertiserId ? 'selected_row' : 'whitecolor'
                  }
                  onClick={() => clickRow(idx)}
                >
                  <TableCell>{row.sequence}</TableCell>
                  <TableCell>{row.title}</TableCell>
                  <TableCell>{row.createTime}</TableCell>
                  <TableCell sx={{ p: '.1rem .6rem' }}>
                    <Button
                      color='info'
                      variant='contained'
                      onClick={() => deleteAdvertiser(row.id)}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Divider />
      <Box sx={{ p: '0rem 0' }}>
        <Box>
          <Typography className='title'>광고주</Typography>
          <TextField
            placeholder='광고주 입력'
            fullWidth
            value={advertiserName}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setAdvertiserName(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>광고주 이미지</Typography>
          <SwFileSetting
            setImage={setAdvertiseImage}
            image={
              advertiseImageFile !== null
                ? advertiseImage
                : advertiseImage !== ''
                ? `${imageUrlPrefix}${advertiseImage}`
                : ''
            }
            setFile={setAdvertiseImageFile}
            file={advertiseImageFile}
          />
          <Divider />
        </Box>
        <Box className='flex_start'>
          <Checkbox
            checked={useBrandPoint}
            onChange={e => channgeUseBrandPoint(e)}
            sx={{ p: 0, mr: '.5rem' }}
          />
          <Typography className='title' sx={{ mr: '3rem' }}>
            브랜드 포인트 사용하기
          </Typography>
        </Box>
        <Divider />
      </Box>
      {useBrandPoint === true && (
        <>
          <Box>
            <Typography className='title'>
              광고주 소개 이미지(일러스트)
            </Typography>
            <SwFileSetting
              setImage={setIntroductionImage}
              image={
                introductionImageFile !== null
                  ? introductionImage
                  : introductionImage !== ''
                  ? `${imageUrlPrefix}${introductionImage}`
                  : ''
              }
              setFile={setIntroductionImageFile}
              file={introductionImageFile}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>포인트 이름</Typography>
            <TextField
              placeholder='포인트 이름을 입력해 주세요.'
              sx={{ width: '40%' }}
              value={pointName}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setPointName(evt.target.value)}
            />
            <Divider />
          </Box>
          <Box>
            <Typography className='title'>포인트 유효 기간</Typography>
            <TextField
              select
              sx={{ width: '20rem' }}
              value={expirationPeriod}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setExpirationPeriod(Number(evt.target.value))}
            >
              {getExpirationPeriod().map((item: any, idx: number) => (
                <MenuItem key={`reward_givetype_${idx}`} value={item.value}>
                  {item.title}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <Box sx={{ p: '1.5rem 0' }}>
              <Box className='flex_center'>
                <Typography sx={{ mr: '.4rem' }}>공개 커뮤니티</Typography>
              </Box>
              <Box sx={{ display: 'flex', m: '.8rem 0' }}>
                <TextField
                  sx={{ width: '22rem', mb: 0, mr: '.3rem' }}
                  placeholder='커뮤니티 아이디로 검색해 주세요.'
                  value={searchWord}
                  onKeyDown={handleKeyDown}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setSearchWord(evt.target.value)}
                />
                <Button
                  variant='contained'
                  onClick={() => setOpenSearchCommunity(true)}
                >
                  조회
                </Button>
              </Box>
              <Paper
                sx={{
                  backgroundColor: '#FCFCFC',
                  p: '2rem 2rem 1rem',
                }}
              >
                <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
                  <Typography fontWeight={500}>공개 커뮤니티</Typography>
                  {communityFilterList &&
                    communityFilterList.map((item: any, idx: number) => (
                      <CommunityBox key={idx.toString()}>
                        <Typography>
                          {item.filterType === 'community'
                            ? `커뮤니티 : ${item.communityName}, ID:${item.targetId}`
                            : `그룹    : ${item.communityName}, ID:${item.targetId}`}
                        </Typography>
                        <ClearIcon
                          className='clear_icon_button'
                          onClick={() => deleteCommunityFilter(idx, 'filter')}
                        />
                      </CommunityBox>
                    ))}
                </Box>
                <Box sx={{ textAlign: 'right' }}>
                  <Button
                    color='error'
                    onClick={() => {
                      setCommunityFilterList([]);
                    }}
                  >
                    <DeleteOutlineIcon />
                    전체 삭제
                  </Button>
                </Box>
              </Paper>
            </Box>
            <Divider />
            <Box>
              <DataportalMultiConnect
                findDataportal={findDataPortal}
                setFindDataportal={setFindDataPortal}
                connectDataportal={connectDataPortal}
                setConnectDataportal={setConnectDataPortal}
                setTargetInfo={setDataPortal}
                isDetail={true}
              />
              <Divider />
            </Box>
            <Box>
              <SwPointUsePlace
                brandPointId={brandPointId}
                reset={!useBrandPoint}
              />
              <Divider />
            </Box>
          </Box>
        </>
      )}
      <Box className='flex_between' sx={{ mt: '0rem' }}>
        <Button variant='outlined' onClick={resetAdvertiser}>
          신규 작성
        </Button>
        <Button variant='contained' color='info' onClick={checkAdvertiser}>
          저장
        </Button>
      </Box>
      <SwSearchCommunityFilter
        title='커뮤니티/그룹 이름, 아이디 검색'
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchCommunity}
        onClose={() => {
          setOpenSearchCommunity(false);
        }}
        setTarget={setSearchCommunity}
      />
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents={dialogMessage}
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          onConfirm();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </Paper>
  );
};
