import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import Geohash from 'latlon-geohash';
import { getGeohashesBetweenTwoGeohashes } from 'geohashes-between';
import { getGeohashesAlong } from 'geohashes-along';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface Props {
  geohashData: any;
  setGeohashData: Dispatch<React.SetStateAction<any>>;
  setTargetGeohashList: Dispatch<React.SetStateAction<any>>;
  elementId?: string;
}

export const RegionFilterMap: React.FC<Props> = ({
  geohashData,
  setGeohashData,
  setTargetGeohashList,
  elementId = 'map',
}) => {
  const map = useRef<any>();
  const centerRef = useRef<any>();
  const drawRef = useRef<any>();
  const drawTypeRef = useRef<any>();
  const maps = document.getElementById(elementId);
  const [mapCenter, setMapCenter] = useState<any>([37.5, 127.012]);
  const [mapScale, setMapScale] = useState<number>(11);
  const [precision, setPrecision] = useState<number>(5);
  const [drawType, setDrawType] = useState<number>(1);
  const [markerStart, setMarkerStart] = useState(false);
  const [drawHashStart, setDrawHashStart] = useState(false);
  const [drawStart, setDrawStart] = useState(false);
  const [geohashList, setGeohashList] = useState<any>([]);
  const [geohashRectangleList, setGeohashRectangleList] = useState<any>([]);
  const [rectangleList, setRectangleList] = useState<any>([]);
  const [points, setPoints] = useState<any>([]);
  const [course, setCourse] = useState<any>(null);
  const [marker, setMarker] = useState<any>([]);

  let drawingStart = false;
  let centerPosition: any;
  let drawingLine: any;
  let mousePosition: any;
  let tempPosition: any;
  let currentMarker: any;
  let tempPolyline: any;
  const pointContent = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >마우스 오른쪽클릭으로 완성하세요.</div>`;
  const mouseMoveCustomOverlay = new naver.maps.InfoWindow({
    content: pointContent,
    borderWidth: 0,
    disableAnchor: true,
    backgroundColor: 'transparent',
    pixelOffset: new naver.maps.Point(0, -28),
  });

  const drawGeohash = () => {
    drawRef.current = null;
    const newPoints: any = points.map((p: any) => {
      const newLat = p.lat;
      const newLon = p.lng;
      return [newLon, newLat];
    });
    newPoints.push(newPoints[0]);
    setPoints([...points, points[0]]);
    const lineString: any = {
      type: 'LineString',
      coordinates: newPoints,
    };
    const geohashesAlong = getGeohashesAlong(lineString, precision);
    const reducer = (q: any[], val: any, idx: number) => {
      const temp = [val];
      if (idx !== geohashesAlong.length - 1) {
        let i = idx + 1;
        do {
          const between = getGeohashesBetweenTwoGeohashes(
            val,
            geohashesAlong[i]
          );
          temp.push(...between);
          i += 1;
        } while (i < geohashesAlong.length - 1);
      }
      const newTemp = temp.sort().filter((t, i) => t !== temp.sort()[i + 1]);
      const result = newTemp.filter(
        t => q.findIndex((w: any) => w === t) === -1
      );
      q.push(...result);
      return q;
    };
    const tempRectangle: any[] = [];
    const totalGeohashList: any[] = [];
    geohashesAlong.reduce(reducer, totalGeohashList);
    totalGeohashList.map(data => {
      const newGeohash = Geohash.bounds(data);
      const sw = new naver.maps.LatLng(newGeohash.sw.lat, newGeohash.sw.lon);
      const ne = new naver.maps.LatLng(newGeohash.ne.lat, newGeohash.ne.lon);
      const rectangleBounds = new naver.maps.LatLngBounds(sw, ne);
      const rectangle = new naver.maps.Rectangle({
        bounds: rectangleBounds,
        strokeWeight: 1,
        strokeColor: '#2681FF',
        strokeOpacity: 0.5,
        strokeStyle: 'shortdash',
        fillColor: '#2681FF',
        fillOpacity: 0.1,
      });
      rectangle.setMap(map.current);
      tempRectangle.push(rectangle);
    });
    setGeohashRectangleList([...geohashRectangleList, ...tempRectangle]);
    // console.log('totalGeohashList :', totalGeohashList);
    setTargetGeohashList(totalGeohashList);
  };

  const initMap = () => {
    if (maps) {
      map.current = new naver.maps.Map(elementId, {
        center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        zoom: mapScale,
      });
    }
  };

  const clickEvent = (evt: any) => {
    console.log('drawTypeRef?.current?.value :', drawTypeRef?.current?.value);
    if (drawTypeRef?.current?.value === 2) {
      console.log('drawingStart :', drawingStart);
      if (!drawingStart) {
        drawingStart = true;
        setMarkerStart(true);
      } else {
        centerPosition = evt.coord;
        drawingLine?.setMap(null);
        centerRef.current = evt.coord;
        drawingLine = new naver.maps.Polyline({
          strokeWeight: 3,
          strokeOpacity: 0.3,
          strokeColor: '#00a0e9',
          path: [centerPosition],
          map: map.current,
        });
        drawingLine?.setMap(map.current);
        setMarkerStart(false);
      }
    } else {
      centerRef.current = evt.coord;
      setDrawHashStart(true);
    }
  };
  const moveEvent = (evt: any) => {
    mousePosition = evt.coord;
    if (drawingStart) {
      setMarkerStart(true);
      const linePath = [centerPosition, mousePosition];
      drawingLine?.setPath(linePath);
      drawingLine?.setMap(map.current);
      mouseMoveCustomOverlay.setPosition(mousePosition);
      mouseMoveCustomOverlay.setMap(map.current);
    }
  };

  const rightClickEvent = (evt: any) => {
    if (drawingStart) {
      tempPosition = evt.coord;
      centerRef.current = evt.coord;
      centerPosition = evt.coord;
      drawingLine?.setMap(null);
      setMarkerStart(false);
      drawingStart = false;
      mouseMoveCustomOverlay.setMap(null);
      drawRef.current = 1;
    }
  };

  const drawingMap = () => {
    if (maps) {
      naver.maps.Event.addListener(map.current, 'click', clickEvent);
      naver.maps.Event.addListener(map.current, 'mousemove', moveEvent);
      naver.maps.Event.addListener(map.current, 'rightclick', rightClickEvent);
    }
  };

  const resetGeohashData = () => {
    console.log('resetGeohashData');
    rectangleList.map((item: any) => item.rectangle.setMap(null));
    setGeohashList([]);
    setRectangleList([]);
    setPoints([]);
    marker?.map((m: any) => m?.setMap(null));
    course?.setMap(null);
    setMarker([]);

    geohashRectangleList?.map((m: any) => m?.setMap(null));
    setGeohashRectangleList([]);
  };

  useMemo(() => {
    if (
      map.current !== null &&
      map.current !== undefined &&
      map.current.updateBy !== undefined
    ) {
      map.current.updateBy(
        new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        15
      );
    }
  }, [mapCenter]);

  useEffect(() => {
    console.log('maps>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    initMap();
    drawingMap();
  }, [maps]);

  useEffect(() => {
    if (centerRef.current) {
      const newPoint = { lat: centerRef.current.y, lng: centerRef.current.x };
      const tmpPoints = points.filter(
        (p: any, idx: number) => idx === 0 || (idx !== 0 && p !== points[0])
      );
      var newPoints = [...tmpPoints, newPoint];
      if (points.length > 0) {
        newPoints = [...newPoints, points[0]];
      }
      currentMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(newPoint?.lat, newPoint?.lng),
        map: map.current,
        icon: {
          content: `<div class='walkcourse_marker'>${newPoints.length}</div>`,
          anchor: new naver.maps.Point(13, 33),
        },
        clickable: false,
        draggable: false,
      });
      setMarker([...marker, currentMarker]);
      currentMarker?.setMap(null);
      setPoints(newPoints);
      centerRef.current = null;
      if (points.length < 1) {
        tempPolyline = new naver.maps.Polyline({
          path: newPoints.map(
            (point: any) => new naver.maps.LatLng(point.lat, point.lng)
          ),
          strokeWeight: 5,
          strokeColor: '#2681FF',
          strokeOpacity: 1,
          strokeStyle: 'solid',
          strokeLineCap: 'round',
          strokeLineJoin: 'round',
        });
        setCourse(tempPolyline);
        tempPolyline.setMap(null);
      } else {
        const prePoint = newPoints.length - 2;
        const newCourse = course;
        newCourse.setOptions({
          path: newPoints.map(
            (point: any) => new naver.maps.LatLng(point.lat, point.lng)
          ),
        });
        tempPolyline = new naver.maps.Polyline({
          map: map.current,
          path: [
            new naver.maps.LatLng(
              newPoints[prePoint]?.lat,
              newPoints[prePoint]?.lng
            ),
            new naver.maps.LatLng(newPoint.lat, newPoint.lng),
          ],
          strokeOpacity: 0,
        });
        setCourse(newCourse);
        tempPolyline.setMap(null);
      }
      if (drawRef.current === 1) {
        setDrawStart(true);
      }
    }
  }, [markerStart]);

  useEffect(() => {
    if (drawHashStart) {
      console.log('erer>>>>>>', centerRef.current);
      const geohash = Geohash.encode(
        centerRef.current.y,
        centerRef.current.x,
        precision
      );
      rectangleList.map((item: any) => {
        item.rectangle?.setMap(null);
      });
      const newRectangleList = rectangleList?.filter(
        (item: any) => item.geohash !== geohash
      );
      newRectangleList.map((item: any) => item.rectangle?.setMap(map.current));

      const newList = geohashList.filter((_val: string) => _val !== geohash);
      if (newList.length === geohashList.length) {
        const newGeohash = Geohash.bounds(geohash);
        const sw = new naver.maps.LatLng(newGeohash.sw.lat, newGeohash.sw.lon);
        const ne = new naver.maps.LatLng(newGeohash.ne.lat, newGeohash.ne.lon);
        const rectangleBounds = new naver.maps.LatLngBounds(sw, ne);
        const rectangle = new naver.maps.Rectangle({
          bounds: rectangleBounds,
          strokeWeight: 1,
          strokeColor: '#2681FF',
          strokeOpacity: 0.5,
          strokeStyle: 'shortdash',
          fillColor: '#2681FF',
          fillOpacity: 0.1,
        });
        rectangle.setMap(map.current);

        setGeohashList([...geohashList, geohash]);
        const newRectangle = {
          geohash: geohash,
          rectangle: rectangle,
        };
        setRectangleList([...newRectangleList, newRectangle]);
      } else {
        setGeohashList(newList);
        setRectangleList(newRectangleList);
      }
      setDrawHashStart(false);
      centerRef.current = null;
    }
  }, [drawHashStart]);

  useEffect(() => {
    // console.log('geohashList :', geohashList);
    setTargetGeohashList(geohashList);
  }, [geohashList]);

  useEffect(() => {
    if (drawStart) {
      drawGeohash();
    }
  }, [drawStart]);

  useEffect(() => {
    if (!drawStart) {
      marker?.map((m: any, idx: number) =>
        m.setIcon({
          content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
          anchor: new naver.maps.Point(13, 33),
        })
      );
      marker?.map((m: any) => m?.setMap(map.current));
    } else {
      const newCourse = course;
      newCourse.setOptions({
        path: points.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
      });
      setCourse(newCourse);
      setDrawStart(false);
    }
  }, [points]);

  useEffect(() => {
    resetGeohashData();
  }, [drawType]);

  useEffect(() => {
    course?.setMap(map.current);
  }, [course]);

  useEffect(() => {
    console.log('geohashData : ', geohashData);

    rectangleList.map((item: any) => item.rectangle.setMap(null));
    if (geohashData.length > 0) {
      var newCenter: string = '';
      const newRectangle: any = [];
      const newGeohash: any = [];
      geohashData.map((data: any, idx: number) => {
        data.map((_val: string, i: number) => {
          if (i === 0 && newCenter === '') newCenter = _val;
          const tmpGeohash = Geohash.bounds(_val);
          const sw = new naver.maps.LatLng(
            tmpGeohash.sw.lat,
            tmpGeohash.sw.lon
          );
          const ne = new naver.maps.LatLng(
            tmpGeohash.ne.lat,
            tmpGeohash.ne.lon
          );
          const rectangleBounds = new naver.maps.LatLngBounds(sw, ne);
          const rectangle = new naver.maps.Rectangle({
            bounds: rectangleBounds,
            strokeWeight: 1,
            strokeColor: '#2681FF',
            strokeOpacity: 0.5,
            strokeStyle: 'shortdash',
            fillColor: '#2681FF',
            fillOpacity: 0.1,
          });
          rectangle.setMap(map.current);

          setDrawHashStart(false);
          centerRef.current = null;

          const tmpRectangle = {
            geohash: _val,
            rectangle: rectangle,
          };
          newRectangle.push(tmpRectangle);
          newGeohash.push(_val);
        });
      });

      if (newRectangle.length > 0 && newGeohash.length > 0) {
        setGeohashList(newGeohash);
        setRectangleList(newRectangle);
        const newCenterPoint = Geohash.decode(newCenter);
        setMapCenter([newCenterPoint.lat, newCenterPoint.lon]);
      }
    } else {
      setGeohashList([]);
      setRectangleList([]);
    }
  }, [geohashData]);

  return (
    <Box sx={{ p: '1.5rem 0', margin: '2rem 1rem' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography className='title'>
          원하는 영역의 테두리를 그린 후 마우스 오른쪽 클릭으로 지오해시를
          넣어주세요.
        </Typography>
        <Box>
          <TextField
            select
            // disabled={drawType === 2}
            sx={{ width: '15rem' }}
            value={precision}
            onChange={evt => setPrecision(Number(evt.target.value))}
          >
            <MenuItem value={5}>사각형 (4.8km x 4.8km)</MenuItem>
            <MenuItem value={6}>사각형 (1.2km x 0.6km)</MenuItem>
            <MenuItem value={7}>사각형 (153m x 153m)</MenuItem>
            <MenuItem value={8}>사각형 (38.2m x 19.1m)</MenuItem>
            <MenuItem value={9}>사각형 (4.7m x 4.7m)</MenuItem>
          </TextField>
          <TextField
            select
            sx={{ width: '10rem', ml: '1rem' }}
            inputRef={drawTypeRef}
            value={drawType}
            onChange={evt => setDrawType(Number(evt.target.value))}
          >
            <MenuItem value={1}>점 그리기</MenuItem>
            <MenuItem value={2}>다각형 그리기</MenuItem>
          </TextField>
          <Button
            sx={{ ml: '1rem' }}
            color='info'
            variant='contained'
            onClick={() => resetGeohashData()}
          >
            초기화
          </Button>
        </Box>
      </Box>
      <div
        id={elementId}
        ref={map}
        style={{
          width: '100%',
          height: '70vh',
          borderRadius: '10px',
          minWidth: '900px',
        }}
        onContextMenu={e => {
          e.preventDefault();
        }}
      />
    </Box>
  );
};
