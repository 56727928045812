import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SelectTextField } from '../../styles/Styles';
import { SwAlert } from '../../commonComponent/views/SwAlert';

import {
  getServiceCouponList,
  putServiceCouponCancel,
} from '../../../common/api/ApiContract';
import { KEY_SERVICE_COUPON_LIST } from '../../../common/key';
import { IServiceCoupon } from '../../../models/service_coupon';
import { PlanHistory } from './PlanHistory';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { challengeCouponType } from './GivePlan';

interface Props {}

const COUPON_MAX_COUNT = 10;

const searchItems = [
  {
    value: 'company',
    title: '기관명',
  },
  {
    value: 'portal',
    title: '포탈 아이디',
  },
  {
    value: 'manager',
    title: '담당자',
  },
];

const ColumsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  whiteSpace: 'nowrap',
});

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  maxWidth: '10rem',
});

const columns = [
  '지급 대상 기관명',
  '서비스 종류',
  '서비스 이름',
  '지급 개수',
  '사용 개수',
  '지급 일시',
  '포탈 아이디',
  '지급 담당자',
  '지급 취소',
];

export const PlanInquire: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const [result, setResult] = useState<any>([]);
  const [couponList, setCouponList] = useState<any>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<any>(null);
  const [cancelCouponCount, setCancelCouponCount] = useState<number>(0);
  const [planCount, setPlanCount] = useState<number>(1);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('service');
  const [searchWord, setSearchWord] = useState<string>('');
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<number>(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: serviceCouponRefetch,
  } = useQuery(
    KEY_SERVICE_COUPON_LIST,
    () => {
      const newData: IServiceCoupon = {
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchRef.current?.value),
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (checkRequest) return getServiceCouponList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newList =
          res &&
          res.serviceCoupon &&
          res.serviceCoupon.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate);
            let newCouponName = item.couponName;
            if (item.type === 'challenge') {
              const newData = challengeCouponType.filter(
                (chg: any, idx: number) => chg.value === item.couponType
              );
              newCouponName = newData.length > 0 ? newData[0].title : '미지정';
            }
            const newData = {
              id: item.id,
              couponName: newCouponName,
              couponType: item.couponType,
              couponCount: item.couponCount,
              couponUseCount: item.couponUseCount,
              creator: item.creator,
              createDate: newDate,
              portalId: item.portalId,
              portalName: item.portalName,
              type: item.type,
            };
            return newData;
          });
        setCouponList(newList);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_SERVICE_COUPON_LIST, () => {
      const newData: IServiceCoupon = {
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchRef.current?.value),
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      return getServiceCouponList(newData);
    });
  };

  const { mutate: putServiceCouponMutate, isError: isMutateError } =
    useMutation(putServiceCouponCancel, {
      onSuccess: () => {
        setOpenAlert(2);
        setDialogMessage('서비스 이용권을 취소했습니다.');
        serviceCouponRefetch();
      },
      onError: error => {
        setOpenAlert(2);
        setDialogMessage(
          '서비스 이용권 취소가 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
    setSelectedCoupon(null);
  };

  const clickCoupon = (idx: number) => {
    console.log('idx: ', Number(idx), ', guide: ', couponList[idx]);
    // setEdit(false);
    // setSelectFaq(faqList[idx]);
    setSelectedCoupon(couponList[idx]);
  };

  const checkCancel = (row: any) => {
    const newCount = row.couponCount - row.couponUseCount;
    if (newCount > 0) {
      setOpenDialog(true);
      setCancelCouponCount(newCount);
    } else {
      setOpenAlert(1);
      setDialogMessage('취소할 이용권 개수가 없습니다.');
    }
  };

  const cancelServiceCoupon = () => {
    const newData: IServiceCoupon = {
      id: selectedCoupon.id,
      couponCount: planCount,
    };
    putServiceCouponMutate(newData);
    setOpenDialog(false);
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  const searchServiceCoupon = () => {
    prefetch(page);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchServiceCoupon();
    }
  };

  return (
    <>
      <Box sx={{ p: '2rem 0' }}>
        <TextField
          inputRef={searchRef}
          placeholder='기관명, 포탈 아이디, 지급 담당자로 검색해 주세요.'
          sx={{ width: '28rem', p: '2rem 0' }}
          onChange={onChangeSearch}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={searchServiceCoupon}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Paper sx={{ mb: '2rem 0', p: '2rem' }}>
          <TableContainer>
            <Box className='flex_between' sx={{ mb: '1rem' }}>
              <TableTitle title='지급 내역' count={totalRecords} />
            </Box>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => (
                    <ColumsCell key={idx.toString()}>{column}</ColumsCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {couponList.map((row: any, idx: number) => (
                  <TableRow
                    key={`service_${idx}`}
                    onClick={() => clickCoupon(idx)}
                  >
                    <RowsCell>{row.portalName}</RowsCell>
                    <RowsCell>{row.type}</RowsCell>
                    <RowsCell>{row.couponName}</RowsCell>
                    <RowsCell>{row.couponCount}</RowsCell>
                    <RowsCell>{row.couponUseCount}</RowsCell>
                    <RowsCell>{row.createDate}</RowsCell>
                    <RowsCell>{row.portalId}</RowsCell>
                    <RowsCell>{row.creator}</RowsCell>
                    <RowsCell>
                      <Button
                        id={row.pushId}
                        size='small'
                        variant='contained'
                        color='info'
                        onClick={(evt: any) => {
                          checkCancel(row);
                        }}
                      >
                        취소
                      </Button>
                    </RowsCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </Paper>
        <Paper>
          <PlanHistory selectedCoupon={selectedCoupon} />
        </Paper>
      </Box>
      <Dialog
        open={openDialog}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            height: '30vh',
            width: '25vw',
          },
        }}
      >
        <DialogContent>
          <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
            ‘{selectedCoupon?.portalName}({selectedCoupon?.portalId})’에게 지급
            취소할 이용권 개수를 선택 후 ‘확인’을 눌러주세요.
          </Typography>
          <Box sx={{ mb: '1rem' }}>
            <Typography className='title'>이용권 개수</Typography>
            <TextField
              select
              fullWidth
              value={planCount}
              onChange={evt => setPlanCount(Number(evt.target.value))}
            >
              {[...Array(cancelCouponCount)].map((x: any, i: number) => (
                <MenuItem key={i + 1} value={i + 1}>
                  {i + 1}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          <Button color='info' fullWidth onClick={() => setOpenDialog(false)}>
            취소
          </Button>
          <Button color='info'>|</Button>
          <Button
            color='primary'
            fullWidth
            onClick={() => cancelServiceCoupon()}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <SwAlert
        contents={dialogMessage}
        open={!!openAlert}
        onConfirm={() => {
          setOpenAlert(0);
        }}
        onClose={() => setOpenAlert(0)}
        confirm={1}
      />
    </>
  );
};
