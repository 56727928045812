import { Box, Divider, IconButton, TextField, Typography } from '@mui/material';
import React, { Dispatch, useEffect } from 'react';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import { v4 as uuidv4 } from 'uuid';

interface Props {
  title: string;
  id: string;
  setId: Dispatch<string>;
  isInquire?: boolean;
  disabled?: boolean;
}

export const SwUuidCreate: React.FC<Props> = ({
  title,
  id,
  setId,
  isInquire,
  disabled = false,
}) => {
  const newId = () => uuidv4().replace(/-/gi, '').toUpperCase();
  const copyId = async (text: string) => {
    try {
      if (navigator.clipboard !== undefined) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999);
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('복사 실패', err);
        }
        textArea.setSelectionRange(0, 0);
        document.body.removeChild(textArea);
      }
    } catch (error) {
      console.log('er>', error);
    }
  };

  useEffect(() => {
    if (id === undefined || id === null || id.length === 0) {
      setId(newId());
    } else setId(id);
  }, [id]);

  return (
    <Box>
      <div style={{ padding: '.5rem .15rem', marginBottom: '.5rem' }}>
        <Typography component='span' sx={{ fontWeight: 500 }}>
          {title} 아이디
        </Typography>
        <IconButton
          disabled={disabled}
          onClick={() => setId(newId())}
          sx={{ p: 0 }}
        >
          <CachedIcon sx={{ fontSize: '1.2rem', m: '-.2rem 0 0 .4rem' }} />
        </IconButton>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          id='uuid'
          sx={{ width: '20rem' }}
          value={id}
          InputProps={{ readOnly: true }}
        />
        {!isInquire && (
          <IconButton onClick={() => copyId(id)} sx={{ p: 0, m: '.5rem' }}>
            <ContentCopyOutlinedIcon sx={{ width: '1rem' }} />
          </IconButton>
        )}
      </div>
      {/* <Divider /> */}
    </Box>
  );
};

export const newUuidId = () => uuidv4().replace(/-/gi, '').toUpperCase();
