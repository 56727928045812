import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { MultilineTextField } from '../../styles/Styles';
import {
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  deleteNotice,
  postNotice,
  putNotice,
} from '../../../common/api/ApiNotice';
import { INotice } from '../../../models/notice';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
  selected: any;
}

export const NoticeDrawUp: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  edit,
  selected,
  isEdit,
}) => {
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const divisionRef = useRef<any>();
  const [noticeId, setNoticeId] = useState<string>('');
  const [division, setDivision] = useState<any>('all');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const { mutate: postNoticeMutate, isError: isMutateError } = useMutation(
    postNotice,
    {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('공지사항를 등록했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '공지사항 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    }
  );

  const { mutate: putNoticeMutate, isError: isPutMutateError } = useMutation(
    putNotice,
    {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('공지사항를 수정했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '공지사항 수정이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    }
  );

  const { mutate: deleteNoticeMutate, isError: isDeleteMutateError } =
    useMutation(deleteNotice, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('공지사항를 삭제했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '공지사항 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const checkNotice = () => {
    const newData: INotice = {
      noticeId: noticeId,
      title: titleRef.current?.value,
      content: contentRef.current?.value,
      ios:
        divisionRef.current?.value === 'ios' ||
        divisionRef.current?.value === 'all'
          ? 1
          : 0,
      android:
        divisionRef.current?.value === 'android' ||
        divisionRef.current?.value === 'all'
          ? 1
          : 0,
    };
    console.log('edit:', edit);
    console.log('notice:', newData);
    noticeId !== '' ? putNoticeMutate(newData) : postNoticeMutate(newData);
  };

  const deleteNotices = () => {
    const newData: INotice = {
      noticeIds: [noticeId],
    };
    console.log('notice:', newData);
    deleteNoticeMutate(newData);
  };

  useEffect(() => {
    setNoticeId(selected?.noticeId === undefined ? '' : selected?.noticeId);
    if (open) {
      setDivision(
        selected?.ios === 1 && selected?.android === 0
          ? 'ios'
          : selected?.ios === 0 && selected?.android === 1
          ? 'android'
          : selected?.ios === 1 && selected?.android === 1
          ? 'all'
          : 'all'
      );
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle sx={{ pl: '24px !important' }}>
          <Box className='flex_between'>
            <Typography variant='h6'>
              {!edit
                ? '워크온 공지사항'
                : selected
                ? '워크온 공지사항 수정'
                : '워크온 공지사항 작성'}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          {edit ? (
            <>
              <Typography className='title'>제목</Typography>
              <TextField
                fullWidth
                inputRef={titleRef}
                defaultValue={selected?.title}
              />
              <Divider />
              <Typography className='title'>내용</Typography>
              <MultilineTextField
                multiline
                rows={10}
                fullWidth
                inputRef={contentRef}
                defaultValue={selected?.content}
              />
              <Divider />
              <Typography className='title'>공개</Typography>
              <TextField
                select
                sx={{ width: '14rem' }}
                inputRef={divisionRef}
                value={division}
                defaultValue='all'
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => setDivision(event.target.value)}
              >
                <MenuItem value='all'>전체</MenuItem>
                <MenuItem value='ios'>아이폰</MenuItem>
                <MenuItem value='android'>안드로이드</MenuItem>
              </TextField>
            </>
          ) : (
            <>
              <Typography className='title' variant='h4'>
                {selected?.title}
              </Typography>
              <Typography color='#999999' fontSize='.83rem'>
                작성일자:{' '}
                {selected && timestamp2Localestring(selected?.timestamp, 1000)}
              </Typography>
              <Typography color='#999999' fontSize='.83rem'>
                공개:{' '}
                {division === 'all'
                  ? '전체'
                  : division === 'ios'
                  ? '아이폰'
                  : '안드로이드'}
              </Typography>
              <Divider />
              <MultilineTextField
                multiline
                rows={10}
                fullWidth
                value={selected?.content}
              />
            </>
          )}
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          {edit ? (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button onClick={checkNotice} color='info' variant='contained'>
                저장
              </Button>
            </>
          ) : (
            <>
              <Button onClick={deleteNotices} color='info' variant='outlined'>
                삭제
              </Button>
              <Button onClick={isEdit} color='info' variant='contained'>
                수정
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={false}
        onClose={() => setOpenDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
