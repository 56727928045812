import clsx from 'clsx';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useRef, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { LocalstoreOnwerInformation } from './LocalstoreOnwerInformation';
import { LocalstoreOwnerStore } from './LocalstoreOwnerStore';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { RequestPagingTable } from '../../commonComponent/table/SwRequestPagingTable';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { KEY_LOCAL_STORE_OWNER_LIST } from '../../../common/key';
import { getLocalStoreOwnerList } from '../../../common/api/ApiLocalstore';

interface Props {}

const columns = ['아이디', '휴대폰 번호', '이름', '이메일', '가입일자'];

export const LocalstoreOwnerManagement: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const searchRef = useRef<string | null>();
  const [request, setRequest] = useState<boolean>(true);
  const [ownerList, setOwnerList] = useState<any[]>([]);

  const [selectedOwner, setSelectedOwner] = useState<any>();
  const [searchKey, setSearchKey] = useState<any>('all');
  const [searchWord, setSearchWord] = useState<any>('');
  const [requestList, setRequestList] = useState<any>([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [waitCount, setWaitCount] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: localStoreOwnerRefetch,
  } = useQuery(
    KEY_LOCAL_STORE_OWNER_LIST,
    () => {
      const newData: any = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getLocalStoreOwnerList(newData);
    },
    {
      onSuccess: res => {
        if (res !== null) {
          const newOnwer =
            res &&
            res.owner &&
            res.owner.map((item: any, idx: number) => {
              const newData = [
                item.ownerId,
                item.ownerPhoneno,
                item.ownerName,
                item.ownerEmail,
                timestamp2Localestring(item.createDate, 1000),
              ];
              return newData;
            });
          setRequestList(newOnwer);
          setOwnerList(res.owner);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_LOCAL_STORE_OWNER_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getLocalStoreOwnerList(newData);
    });
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  const searchAccount = () => {
    prefetch(1);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchAccount();
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  useEffect(() => {
    if (reload) localStoreOwnerRefetch();
  }, [reload]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>사장님 계정 관리</title>
      </Helmet>
      <TextField
        inputRef={searchRef}
        placeholder='계정 아이디, 휴대폰 번호, 담당자 이름으로 검색해 주세요.'
        sx={{ width: '28rem', p: '2rem 0' }}
        onChange={onChangeSearch}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={searchAccount}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RequestPagingTable
        columns={columns}
        rows={requestList}
        page={page}
        rowsPerPage={rowsPerPage}
        totalCount={totalRecords}
        title={`계정 ${totalRecords}개`}
        onclick={(i: number) => setSelectedOwner(ownerList[i])}
        handleChangePage={handleChangePage}
      />

      <LocalstoreOnwerInformation
        ownerInfo={selectedOwner}
        setReload={setReload}
      />

      <LocalstoreOwnerStore ownerInfo={selectedOwner} />
    </div>
  );
};
