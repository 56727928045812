import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

interface Props {
  title: string;
  count: number;
  contents: any[];
  navi: string;
  offset?: number;
}

export const SwCard: React.FC<Props> = ({
  title,
  count,
  contents,
  navi,
  offset = 0,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <Card key={offset}>
        <CardHeader
          sx={{
            p: '.8rem 1.5rem',
            '& .MuiCardHeader-content': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          action={
            <Button sx={{ fontSize: '.81rem' }} onClick={() => navigate(navi)}>
              바로가기
              <ArrowForwardIosIcon sx={{ fontSize: '.85rem', ml: '.5rem' }} />
            </Button>
          }
          title={
            <Typography fontWeight={500} sx={{ mr: '.3rem' }}>
              {title}
            </Typography>
          }
          subheader={
            <Box className='flex_center' sx={{ fontWeight: 500 }}>
              <Typography sx={{ color: '#F63454' }} fontWeight={500}>
                {count}
              </Typography>
              <Typography fontWeight={500}>&nbsp;건</Typography>
            </Box>
          }
        />
        <Divider sx={{ p: 0, m: 0 }} />
        <CardContent sx={{ height: '35vh', overflowY: 'scroll' }}>
          {contents?.map((content: any, idx: number) => (
            <Fragment key={(idx + offset).toString()}>
              <Box sx={{ p: '.3rem .5rem' }}>
                <Typography sx={{ mb: '.3rem' }}>{content.title}</Typography>
                <Typography>{content.customer}</Typography>
                {content.date && <Typography>{content.date}</Typography>}
              </Box>
              <Divider sx={{ p: 0 }} />
            </Fragment>
          ))}
        </CardContent>
      </Card>
    </>
  );
};
