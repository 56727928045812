import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { ChangeEvent, useEffect, useRef, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import clsx from 'clsx';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { RequestPagingTable } from '../../commonComponent/table/SwRequestPagingTable';
import { NoticeDrawup } from './NoticeDrawup';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwRequestTable } from '../../commonComponent/table/SwRequestTable';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { KEY_LOCAL_STORE_NOTICE_LIST } from '../../../common/key';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  deleteLocalStoreNotice,
  getLocalStoreNoticeList,
} from '../../../common/api/ApiLocalstore';

interface Props {}

const columns = ['제목', '작성자', '작성일시'];

const typeItem = [
  '챌린지 수정 요청',
  '계약 및 청구 관련 문의',
  '개선요청/건의사항',
  '기타문의',
];

export const LocalStoreNotice: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const [openDrawup, setOpenDrawup] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [noticeList, setNoticeList] = useState<any>([]);
  const [selectNotice, setSelectNotice] = useState<any>(null);

  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [loading, setLoading] = useState<any>(null);

  const {
    data,
    isLoading,
    refetch: localStoreNoticeRefetch,
  } = useQuery(
    KEY_LOCAL_STORE_NOTICE_LIST,
    () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (checkRequest) {
        setLoading(true);
        return getLocalStoreNoticeList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        setLoading(false);
        const newNoticeList =
          res &&
          res.notice &&
          res.notice.map((item: any) => {
            const newDate = new Date(item.createDate).toLocaleString();
            const newData = {
              idx: String(item.idx),
              title: item.title,
              content: item.content,
              creator: item.creator !== null ? item.creator : '-',
              createDate: timestamp2Localestring(item.createDate, 1000),
              image: item.image,
              imageUrlPrefix: item.imageUrlPrefix,
            };
            return newData;
          });
        setNoticeList(newNoticeList);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_LOCAL_STORE_NOTICE_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      return getLocalStoreNoticeList(newData);
    });
  };

  const {
    mutate: deleteLocalStoreNoticeMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteLocalStoreNotice, {
    onSuccess: () => {
      setOpenAlert(false);
      setOpenDialog(true);
      setDialogMessage('자주 묻는 질문을 삭제했습니다.');
      localStoreNoticeRefetch();
    },
    onError: error => {
      setOpenDialog(true);
      setDialogMessage(
        '자주 묻는 질문을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteNotice = () => {
    const idxList = checkedButtons.map((item: string) => Number(item));
    const newData: any = {
      idxList: idxList,
    };
    deleteLocalStoreNoticeMutation(newData);
  };

  const clickNotice = (idx: number) => {
    console.log('idx: ', Number(idx), ', guide: ', noticeList[idx]);
    setEdit(true);
    setSelectNotice(noticeList[idx]);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      noticeList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.idx]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  useEffect(() => {
    if (selectNotice !== null) setOpenDrawup(true);
  }, [selectNotice]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>우리동네 챌린지 공지사항</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '2rem 0' }}>
        <Button
          variant='contained'
          onClick={() => {
            setEdit(false);
            setOpenDrawup(true);
          }}
        >
          <AddIcon />
          작성하기
        </Button>
      </Box>
      <Paper sx={{ m: '1rem 0', p: '1.5rem' }}>
        <TableTitle title='공지사항' count={totalRecords} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  onChange={e => {
                    handleSelectAllClick(e);
                  }}
                  checked={checkedAll}
                />
              </TableCell>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {noticeList?.map((row: any, idx: number) => (
              <TableRow key={idx.toString()}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    id={row.idx}
                    onChange={e => {
                      changeHandler(e.currentTarget.checked, row.idx);
                    }}
                    checked={checkedButtons.includes(row.idx)}
                  />
                </TableCell>
                <TableCell
                  sx={{ cursor: 'pointer' }}
                  onClick={() => clickNotice(idx)}
                >
                  {row.title}
                </TableCell>
                <TableCell onClick={() => clickNotice(idx)}>
                  {row.creator}
                </TableCell>
                <TableCell onClick={() => clickNotice(idx)}>
                  {row.createDate}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={noticeList && Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='outlined'
          color='info'
          onClick={() => setOpenAlert(true)}
        >
          삭제
        </Button>
      </Box>
      <NoticeDrawup
        open={openDrawup}
        onClose={() => {
          setOpenDrawup(false);
          setSelectNotice(null);
        }}
        onConfirm={() => {
          setOpenDrawup(false);
          setSelectNotice(null);
          localStoreNoticeRefetch();
        }}
        edit={edit}
        isEdit={() => setEdit(!edit)}
        notice={selectNotice}
      />
      <SwAlert
        contents='선택하신 공지사항을 삭제하시겠습니까?'
        open={openAlert}
        onClose={() => {
          setOpenAlert(false);
          setSelectNotice(null);
        }}
        onConfirm={() => deleteNotice()}
        confirm={1}
      />
      {loading && <SwLoading />}
    </div>
  );
};
