import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  title?: string;
  times: any;
  setTimes: Dispatch<SetStateAction<any[]>>;
  weeks: any;
  setWeeks: Dispatch<SetStateAction<any[]>>;
  showButton?: boolean;
  // setDeletedDate?: Dispatch<SetStateAction<any>>;
  maxLimit?: number;
  // allowPastDate?: boolean;
  disabled?: boolean;
  // dateRange?: any[];
  allowDeletePastDate?: boolean;
  showSubTitle?: boolean;
}

const DateTextField = styled(TextField)({
  '& .MuiInputLabel-root': { top: '-5px' },
  '& .MuiOutlinedInput-root': { pr: '1rem' },
  width: '20rem',
});

const DateChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const DateAddButton = styled(Button)({
  marginTop: '.3rem',
  marginLeft: '.7rem',
  padding: '.1rem 0',
  borderRadius: '20px',
});

const weekList = [
  { id: 1, title: '월' },
  { id: 2, title: '화' },
  { id: 3, title: '수' },
  { id: 4, title: '목' },
  { id: 5, title: '금' },
  { id: 6, title: '토' },
  { id: 7, title: '일' },
];

const timeListLeft = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

const timeListRight = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
];

export const SwWeekAndTimePicker: React.FC<Props> = ({
  title = '인증 가능 시간',
  times,
  setTimes,
  weeks,
  setWeeks,
  showButton = true,
  // setDeletedDate,
  maxLimit = 10,
  // allowPastDate = true,
  disabled = false,
  // dateRange = null,
  allowDeletePastDate = true,
  showSubTitle = false,
}) => {
  const [leftTime, setLeftTime] = useState<string>('99');
  const [rightTime, setRightTime] = useState<string>('99');
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('');
  const getType = (target: any) =>
    Object.prototype.toString.call(target).slice(8, -1);

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      weekList.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.title]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, newTitle: string, id: number) => {
    if (checked) {
      const newData = [...checkedButtons, newTitle];
      const newWeeks = [...weeks, id];
      setCheckedButtons(newData);
      setWeeks(newWeeks);

      const counts = weekList
        .map(week => (newData.includes(week.title) ? 1 : 0))
        .filter(data => data === 1);
      if (counts !== null && counts.length === weekList.length) {
        setCheckedAll(true);
      }
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== newTitle));
      setWeeks(weeks.filter((week: any) => week !== id));
      setCheckedAll(false);
    }
  };

  const addTime = () => {
    const newTime = `${leftTime}:00~${rightTime}:00`;
    const overlapTime = times
      .map((time: string) => {
        const newT = time.split('~');
        const leftT = newT[0].split(':')[0];
        const rightT = newT[1].split(':')[0];
        if (rightTime <= leftT) return 0;
        if (leftTime >= rightT) return 0;
        return 1;
      })
      .filter((result: number) => result === 1);
    const newTimes = showButton ? [...times] : [];
    if (newTimes.length === maxLimit) {
      setDateErrorMessage(
        `적용 시간은 최대 ${maxLimit}개까지 등록 가능합니다.`
      );
    } else if (newTimes.filter(d => d === newTime).length) {
      setDateErrorMessage('중복된 시간입니다.');
    } else if (overlapTime !== null && overlapTime.length > 0) {
      setDateErrorMessage('겹친 시간입니다.');
    } else {
      newTimes.push(newTime);
      setDateErrorMessage('');
    }

    setTimes(newTimes);
  };

  const addTimes = (newLeftTime: string, newRightTime: string) => {
    const newTime = `${newLeftTime}:00~${newRightTime}:00`;
    setTimes([newTime]);
  };

  function checkTime() {
    if (leftTime === '99' || rightTime === '99') return true;
    if (leftTime >= rightTime) return true;
    return false;
  }

  useEffect(() => {
    const newButtons: any[] = [];
    weeks.map((week: any) => newButtons.push(weekList[week - 1].title));
    setCheckedButtons(newButtons);
  }, [weeks]);

  useEffect(() => {
    if (!showButton && times.length > 0) {
      const newTimes = times[0].split('~');
      setLeftTime(newTimes[0].split(':')[0]);
      setRightTime(newTimes[1].split(':')[0]);
    }
  }, [times]);

  return (
    <>
      <Box
        sx={{
          display: showSubTitle ? '' : 'flex',
          alignItems: showSubTitle ? '' : 'flex-start',
        }}
      >
        <Box>
          {showSubTitle && (
            <>
              <Typography className='title'>요일</Typography>
            </>
          )}
          <FormGroup sx={{ flexDirection: 'row' }}>
            {/* <FormControlLabel
              control={
                <Checkbox
                  value='all'
                  checked={checkedAll}
                  onChange={evt => handleSelectAllClick(evt)}
                />
              }
              label='전체'
            /> */}
            {weekList.map((week: any, idx: number) => (
              <FormControlLabel
                key={`week_key_${idx}`}
                control={
                  <Checkbox
                    value={week.id}
                    checked={checkedButtons.includes(weekList[idx].title)}
                    onChange={evt =>
                      !disabled &&
                      changeHandler(
                        evt.currentTarget.checked,
                        weekList[idx].title,
                        weekList[idx].id
                      )
                    }
                  />
                }
                label={week.title}
              />
            ))}
          </FormGroup>
        </Box>
        {showSubTitle && (
          <>
            <Divider />
            <Typography className='title'>시간대</Typography>
          </>
        )}
        {disabled ? (
          <Typography className='title'>{times[0]}</Typography>
        ) : (
          <Box className='flex_start'>
            <TextField
              sx={{ width: '7rem', mr: '.5rem' }}
              select
              value={leftTime}
              onChange={evt => {
                setLeftTime(evt.target.value);
                if (!showButton) addTimes(evt.target.value, rightTime);
              }}
            >
              <MenuItem key='week_and_time_start' value='99'>
                시간입력
              </MenuItem>
              {timeListLeft.map((option, idx) => (
                <MenuItem key={`week_and_time_${option}`} value={option}>
                  {`${option}:00`}
                </MenuItem>
              ))}
            </TextField>
            <Typography>~</Typography>
            <TextField
              sx={{ width: '7rem', ml: '.5rem' }}
              select
              value={rightTime}
              onChange={evt => {
                setRightTime(evt.target.value);
                if (!showButton) addTimes(leftTime, evt.target.value);
              }}
            >
              <MenuItem key='week_and_time_start' value='99'>
                시간입력
              </MenuItem>
              {timeListRight.map((option, idx) => (
                <MenuItem key={`week_and_time_${option}`} value={option}>
                  {`${option}:00`}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}

        {showButton && (
          <DateAddButton
            disabled={checkTime()}
            onClick={addTime}
            variant='outlined'
            color='info'
          >
            입력
          </DateAddButton>
        )}
      </Box>
      {dateErrorMessage !== '' && (
        <Typography sx={{ color: 'red' }}>{dateErrorMessage}</Typography>
      )}
      <Box>
        {showButton &&
          times.length > 0 &&
          times.map((d: any, idx: number) =>
            allowDeletePastDate ? (
              <DateChip
                label={d}
                variant='outlined'
                deleteIcon={<CloseIcon />}
                onDelete={() => {
                  const newTimes = times.filter(
                    (_dt: any, i: number) => i !== idx
                  );
                  setTimes([...newTimes]);
                  setDateErrorMessage('');
                }}
                key={`delete_chip_${idx}`}
              />
            ) : (
              <DateChip label={d} variant='outlined' key={idx.toString()} />
            )
          )}
      </Box>
    </>
  );
};
