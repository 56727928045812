import React, { SyntheticEvent, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Tab, Tabs } from '@mui/material';
import { useNavigate, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { TabBox } from '../styles/Styles';
import { useResize } from '../../hooks/resize';

interface Props {}

export const OperatingMetrics: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(1);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('usercommunitychallenge');
        break;
      case 2:
        setValue(2);
        navigate('usercommunitychallenge');
        break;
      default:
        setValue(0);
        navigate('/operatingMetrics');
    }
  };
  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/operatingMetrics/usercommunitychallenge') setValue(1);
    else setValue(2);
  }, [window.location.pathname]);
  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>운영지표</title>
      </Helmet>
      <TabBox>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='사용자,커뮤니티,챌린지 지표' value={1} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
