import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IWalkwayLocal, IWalkway, IWalkwayPoint } from '../../models/walkway';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Walkway
 */

export const getWalkwayLocalList = (searchWord?: string) =>
  xapi
    .get(
      `/admin/v2/apis/walkway/local?searchKey=walkway&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getWalkwayLocalSimpleList = (searchWord?: string) =>
  xapi
    .get(
      `/admin/v2/apis/walkway/local-simple?searchKey=walkway&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getWalkwayDetailInfo = (walkWayId: string) =>
  xapi.get(`/admin/v2/apis/walkway/${walkWayId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getWalkwayPointList = (walkWayId: string) =>
  xapi.get(`/admin/v2/apis/walkway/${walkWayId}/point`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getWalkwayInfoByCommunityId = (communityId: string) =>
  xapi.get(`/admin/v2/apis/walkway/community/${communityId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postWalkway = (data: IWalkway) =>
  xapi.post(`/admin/v2/apis/walkway`, data);

export const postWalkwayPoint = (data: IWalkwayPoint) =>
  xapi.post(`/admin/v2/apis/walkway/${data.point.walkWayId}/point`, data);

export const postMakeLocalAmd = () =>
  xapi.post(`/admin/v2/apis/walkway/make-local-amd`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteWalkwayPoint = (data: any) =>
  xapi.delete(`/admin/v2/apis/walkway/point/${data.pointId}`);
