import React from 'react';
import { CssBaseline } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { RecoilRoot } from 'recoil';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SwTheme } from './SwTheme';
import { Resize } from './hooks/resize';
import { SessionProvider } from './hooks/session';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const reactQueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: Number(process.env.REACT_APP_QUERY_STALE_TIME),
      // retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
      retryDelay: 30000,
      onError: err => {
        console.error('error !!! :', (err as any).message);
      },
    },
  },
});

root.render(
  <React.StrictMode>
    <Resize>
      <SwTheme>
        <CssBaseline />
        <Router>
          <QueryClientProvider client={reactQueryClient}>
            <RecoilRoot>
              <SessionProvider>
                <HelmetProvider>
                  <App />
                </HelmetProvider>
              </SessionProvider>
            </RecoilRoot>
          </QueryClientProvider>
        </Router>
      </SwTheme>
    </Resize>
  </React.StrictMode>
);
// ReactDOM.render(
//   <React.StrictMode>
//     <Resize>
//       <SwTheme>
//         <CssBaseline />
//         <Router>
//           <QueryClientProvider client={reactQueryClient}>
//             <SessionProvider>
//               <App />
//             </SessionProvider>
//           </QueryClientProvider>
//         </Router>
//       </SwTheme>
//     </Resize>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
