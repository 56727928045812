import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { GridRowParams, MuiEvent } from '@mui/x-data-grid';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwGridTable } from '../../commonComponent/table/SwGridTable';
import columns from '../../../dummy/columntest.json';
import rows from '../../../dummy/rowtest.json';
import rowstest from '../../../dummy/rowstest.json';
import columntest2 from '../../../dummy/columntest2.json';
import aajson from '../../../dummy/aajson.json';
import { SwDateRangePicker2 } from '../../commonComponent/dateSetting/SwDateRangePicker2';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { CreatePartner } from './CreatePartner';
import {
  SelectTextField,
  TableButton,
  TableTopButton,
} from '../../styles/Styles';
import { PartnerTable } from '../../commonComponent/table/PartnerTable';
import { ContractInformation } from './ContractInformation';
import { PartnerCommunityInformation } from './PartnerCommunityInformation';
import { PartnerChallengeInformation } from './PartnerChallengeInformation';
import { IContract } from '../../../models/contract';
import {
  encodeSearchWord,
  getPartnerType,
  getPartnerTypeItems,
  timestamp2Localestring,
} from '../../../common/helper';
import {
  downloadContractData,
  getContractList,
  getContractReportMonthly,
} from '../../../common/api/ApiContract';
import {
  deleteDataPortalAccount,
  deleteDataPortalCompany,
} from '../../../common/api/ApiDataPortal';
import {
  KEY_CONTRACT_LIST,
  KEY_CONTRACT_REPORT_MONTHLY,
  KEY_WORD_PREFIX_COMPANY,
} from '../../../common/key';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { PartnerProgramInformation } from './PartnerProgramInformation';

interface Props {}

const searchOption = [
  {
    value: 'full',
    title: '전체',
  },
  {
    value: 'name',
    title: '고객명',
  },
  {
    value: 'manager',
    title: '고객 담당자',
  },
  {
    value: 'id',
    title: '고객 아이디',
  },
  {
    value: 'portal',
    title: '포탈 아이디',
  },
  {
    value: 'operator',
    title: '워크온 담당자',
  },
];

const requestColumns = [
  '고객 분류',
  '고객명',
  '고객 주소',
  '담당자',
  '담당자 연락처',
  '담당자 이메일',
  '데이터 포탈 ID',
  '포탈 상태',
  '포털계정시작일',
  '포털계정종료일',
  '워크온 담당자',
  '메모',
  '커뮤니티개수',
  '챌린지개수',
  '계약건수',
];

export const PartnerInformation: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const [requestList, setRequestList] = useState<any>([]);
  const [contractList, setContractList] = useState<any>([]);
  const [contractInfo, setContractInfo] = useState<any>();
  const [partnerType, setPartnerType] = useState(0);
  const [addPartner, setAddPartner] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [selectRow, setSelectRow] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [loading, setLoading] = useState<any>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: contractRefetch,
  } = useQuery(
    KEY_CONTRACT_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      setLoading(true);
      if (checkRequest) {
        const newType = partnerType;
        const newData = {
          type: newType,
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord:
            searchKey !== 'id'
              ? encodeSearchWord(searchKey, searchWord)
              : searchWord.replace(KEY_WORD_PREFIX_COMPANY, ''),
          startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
          endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
        };
        const rslt = getContractList(newData);
        console.log('rslt :', rslt);
        return rslt;
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        setLoading(false);
        if (res && res.contract) {
          const newContract = res.contract.map((item: any) => {
            const now = timestamp2Localestring(new Date(Date.now()).getTime());
            const constime = timestamp2Localestring(item.constime, 1000);
            const activatetime = timestamp2Localestring(
              item.activatetime,
              1000
            );
            const isValid = constime <= now && now <= activatetime;
            const newData = [
              getPartnerType(item.contype),
              item.name,
              item.address !== null && item.address !== '' ? 'O' : 'x',
              item.userName1,
              item.userPhoneno1,
              item.userEmail1,
              item.id,
              isValid ? 'O' : 'x',
              constime.split(' ')[0],
              activatetime.split(' ')[0],
              item.operator !== null ? item.operatorName : '',
              item.memo,
              item.communityCount,
              item.challengeCount,
              item.contractCount,
            ];
            return newData;
          });
          console.log('newContract:', newContract);
          setTotalRecords(res.pagination.totalRecordCount);
          setRequestList(newContract);
          setContractList(res.contract);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const contractPrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_CONTRACT_LIST, () => {
      const newType = partnerType === 0 ? '전체' : getPartnerType(partnerType);
      const newData = {
        type: newType,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
      };
      getContractList(newData);
    });
  };

  const {
    mutate: deletePortalCompanyMutation,
    isError: isDeleteCompanyMutateError,
  } = useMutation(deleteDataPortalCompany, {
    onSuccess: () => {
      setOpenSnakerDialog(true);
      setDialogMessage('고객정보를 삭제했습니다.');
      setContractInfo(null);
      contractRefetch();
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '고객정보을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const searching = () => {
    setPage(1);
    if (searchRef.current.value && selectRef.current.value) {
      if (
        searchRef.current.value === searchWord &&
        selectRef.current.value === searchKey
      ) {
        contractRefetch();
      } else {
        setSearchKey(selectRef.current.value);
        setSearchWord(searchRef.current.value);
      }
    } else if (searchWord === '' && searchKey === '') {
      contractRefetch();
    } else {
      setSearchKey('');
      setSearchWord('');
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    // contractPrefetch(newPage);
    // contractRefetch();
    setContractInfo(null);
  };

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    setContractInfo(contractList[i]);
    setEdit(false);
  };

  const updatePartner = (updateFlag: boolean) => {
    if (contractInfo === undefined || contractInfo === null) {
      setOpenDialog(true);
      setDialogMessage(
        '선택한 고객정보가 없습니다. 고객정보를 선택 후 시도해주시기 바랍니다.'
      );
      return;
    }
    if (updateFlag) {
      setEdit(true);
      setAddPartner(true);
    } else {
      console.log('delete contractInfo: ', contractInfo);
      contractInfo && deletePortalCompanyMutation(contractInfo.id);
    }
  };

  const downloadContract = () => {
    // const newType = partnerType === 0 ? '전체' : getPartnerType(partnerType);
    const newData = {
      type: partnerType,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
    };
    downloadContractData(newData);
  };

  useEffect(() => {
    contractRefetch();
  }, [page]);

  useEffect(() => {
    contractRefetch();
  }, [rowsPerPage]);

  useEffect(() => {
    console.log('searchKey :', searchKey, ', searchWord:', searchWord);
    contractRefetch();
  }, [searchKey, searchWord]);

  return (
    <>
      <Box className='flex_between'>
        <Typography variant='h3' sx={{ fontWeight: 500 }}>
          고객 정보
        </Typography>
        <Box className='flex_between'>
          <Typography variant='h4' sx={{ fontWeight: 500, mr: '2rem' }}>
            총 거래처: {totalRecords} 개
          </Typography>
          <Typography variant='h4' sx={{ fontWeight: 500 }}>
            서비스 이용중: {totalRecords} 개
          </Typography>
        </Box>
      </Box>
      <Paper sx={{ p: '1.5rem', m: '1rem 0' }}>
        <Box className='flex_end'>
          <TableButton
            color='info'
            variant='outlined'
            onClick={downloadContract}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon
              color='success'
              sx={{ fontSize: '1.3rem' }}
            />
          </TableButton>
          <TableButton
            color='info'
            variant='outlined'
            onClick={() => updatePartner(true)}
          >
            상세보기
          </TableButton>
          <TableButton
            color='info'
            variant='outlined'
            onClick={() => setOpenDelete(true)}
          >
            삭제
          </TableButton>
          <TableButton
            color='info'
            variant='contained'
            onClick={() => {
              setEdit(false);
              setAddPartner(true);
            }}
          >
            <AddOutlinedIcon sx={{ fontSize: '1.5rem' }} />
            고객 추가
          </TableButton>
        </Box>
        <Box className='flex_between' sx={{ mb: '1rem' }}>
          <SelectSearchBox
            searchOption={searchOption}
            optionValue={selectRef}
            searchWord={searchRef}
            defaultOption='full'
            isSearching={searching}
          />
          <Box className='flex_center'>
            <Box sx={{ mr: '.5rem' }}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Box>
            <SelectTextField
              select
              value={partnerType}
              onChange={evt => setPartnerType(Number(evt.target.value))}
              sx={{ mr: '.5rem' }}
            >
              <MenuItem value={0}>전체</MenuItem>
              {getPartnerTypeItems().map((item: any, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {item}
                </MenuItem>
              ))}
            </SelectTextField>
            <SelectTextField
              select
              value={rowsPerPage}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
                setPage(1);
              }}
            >
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option.toString()} value={option}>
                  {option}개씩 보기
                </MenuItem>
              ))}
            </SelectTextField>
          </Box>
        </Box>
        <PartnerTable
          columns={requestColumns}
          rows={requestList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecords={totalRecords}
          onclick={chooseRequest}
          handleChangePage={handleChangePage}
        />
      </Paper>
      <ContractInformation contractInfo={contractInfo} />
      <PartnerCommunityInformation contractInfo={contractInfo} />
      <PartnerChallengeInformation contractInfo={contractInfo} />
      <PartnerProgramInformation contractInfo={contractInfo} />
      <CreatePartner
        title='고객'
        contractInfo={contractInfo}
        open={addPartner}
        onClose={() => setAddPartner(false)}
        onConfirm={() => {
          setAddPartner(false);
          contractRefetch();
        }}
        edit={edit}
        isEdit={() => setEdit(!edit)}
      />
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='삭제하시겠습니까?'
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          updatePartner(false);
          setOpenDelete(false);
        }}
        confirm={1}
      />
      {loading && <SwLoading />}
    </>
  );
};
