import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useRef, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSearchDataPortal } from '../../commonComponent/SwSearchDataPortal';
import { SwSearchAmd } from '../../commonComponent/SwSearchAmd';
import {
  checkNameStrength,
  checkPasswordStrength,
  timestamp2Localestring,
  timestamp2string,
  validCheck,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSearchWalkwayLocal } from '../../commonComponent/SwSearchWalkwayLocal';
import {
  changeLocalStoreOwnerPassword,
  deleteLocalStoreOwner,
  postLocalStoreOwnerRegister,
} from '../../../common/api/ApiLocalstore';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { KEY_WORD_EMAIL, KEY_WORD_PHONE } from '../../../common/key';

interface Props {
  ownerInfo: any;
  setReload: Dispatch<React.SetStateAction<boolean>>;
}

const CreatePaper = styled(Paper)({
  margin: '2rem 0',
  padding: '1.5rem',
});

const ContentBox = styled(Box)({
  padding: '0 1rem',
});

const ContentTitle = styled(Typography)({
  padding: '.5rem',
  paddingLeft: 0,
  marginBottom: '.5rem',
  fontWeight: 500,
});

const authorityIds = ['challenge', 'community', 'program', 'qna'];

const authorityItem = ['all', 'challenge', 'community', 'program', 'qna'];

export const LocalstoreOnwerInformation: React.FC<Props> = ({
  ownerInfo,
  setReload,
}) => {
  const idRef = useRef<any>();
  const [ownerId, setOwnerId] = useState<string>('');
  const [ownerPhoneno, setOwnerPhoneno] = useState<string>('');
  const [ownerName, setOwnerName] = useState<string>('');
  const [ownerEmail, setOwnerEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [idDisabled, setIdDisabled] = useState<boolean>(false);
  const [view, setView] = useState<boolean>(false);

  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const reset = () => {
    setIdDisabled(false);
    setOwnerId('');
    setOwnerPhoneno('');
    setOwnerName('');
    setOwnerEmail('');
    setPassword('');
    setReload(false);
  };

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setDialogMessage(msg);
  };

  const { mutate: registerLocalStoreOwnerMutation, isError: isMutateError } =
    useMutation(postLocalStoreOwnerRegister, {
      onSuccess: res => {
        console.log('postLocalStoreOwnerRegister res : ', res);
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('저장되었습니다.');
          setReload(true);
        } else {
          showError(`저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`);
        }
      },
      onError: error => {
        showError(`저장하는 동안 오류가 발생했습니다.(${error})`);
      },
    });

  const {
    mutate: changeLocalStoreOwnerPasswordMutation,
    isError: isChangeMutateError,
  } = useMutation(changeLocalStoreOwnerPassword, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('비밀번호가 변경되었습니다.');
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '비밀번호을 변경하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteLocalStoreOwnerMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteLocalStoreOwner, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('계정이 삭제되었습니다.');
      setReload(true);
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '계정을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteOwner = () => {
    if (ownerId.length === 0) {
      setDialogMessage('삭제할 계정을 선택해 주세요');
      setOpenDialog(true);
      return;
    }
    const newData: any = {
      ownerId: ownerId,
    };
    deleteLocalStoreOwnerMutation(newData);
  };

  const changePassword = () => {
    if (password.length === 0) {
      setDialogMessage('비밀번호를 입력해 주세요.');
      setOpenDialog(true);
      return;
    }
    if (checkPasswordStrength(password) === false) {
      setDialogMessage(
        '최소 8자 이상 대소문자, 숫자 및 특수문자를 조합하여 입력해 주세요.'
      );
      setOpenDialog(true);
      return;
    }
    const ownerData: any = {
      ownerId: ownerId,
      password: password,
    };
    const newData: any = {
      ownerId: ownerId,
      owner: ownerData,
    };
    changeLocalStoreOwnerPasswordMutation(newData);
  };

  const registerOwner = () => {
    const ownerData: any = {
      ownerId: ownerId,
      ownerName: ownerName,
      ownerEmail: ownerEmail,
      ownerPhoneno: ownerPhoneno,
      password: password,
    };
    const newData: any = {
      owner: ownerData,
    };
    registerLocalStoreOwnerMutation(newData);
  };

  const checkValid = () => {
    let val = 0;

    if (val === 0 && ownerPhoneno.length === 0) {
      val = 1;
    }
    if (val === 0 && validCheck(KEY_WORD_PHONE, ownerPhoneno) === false) {
      val = 2;
    }
    if (val === 0 && ownerName.length === 0) {
      val = 5;
    }
    if (val === 0 && checkNameStrength(ownerName) === false) {
      val = 6;
    }
    // if (val === 0 && ownerEmail.length === 0) {
    //   val = 7;
    // }
    // if (val === 0 && validCheck(KEY_WORD_EMAIL, ownerEmail) === false) {
    //   val = 8;
    // }
    if (val === 0 && (ownerId === null || ownerId.length === 0)) {
      val = 9;
    }
    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '휴대폰 번호를 입력해 주세요.';
          break;
        case 2:
          message = '휴대폰 번호를 정확히 입력해 주세요.';
          break;
        case 3:
          message = '비밀번호를 입력해 주세요.';
          break;
        case 4:
          message =
            '최소 8자 이상 대소문자, 숫자 및 특수문자를 조합하여 입력해 주세요.';
          break;
        case 5:
          message = '이름을 입력해 주세요.';
          break;
        case 6:
          message = '이름을 정확히 입력해 주세요.';
          break;
        case 7:
          message = '이메일 주소를 입력해 주세요.';
          break;
        case 8:
          message = '이메일 주소를 정확히 입력해 주세요.';
          break;
        case 9:
          message = '계정 아이디를 입력해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenSnackbar(true);
      setDialogMessage(message);
    }
    return val;
  };

  const checkOwner = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }
    registerOwner();
  };

  useEffect(() => {
    setReload(false);
    if (ownerInfo) {
      setIdDisabled(true);
      setOwnerEmail(ownerInfo.ownerEmail);
      setOwnerId(ownerInfo.ownerId);
      setOwnerName(ownerInfo.ownerName);
      setOwnerPhoneno(ownerInfo.ownerPhoneno);
      setPassword('');
    } else reset();
  }, [ownerInfo]);

  return (
    <CreatePaper>
      <ContentBox>
        <SwUuidCreate title='계정' id={ownerId} setId={setOwnerId} />
        <Divider />
      </ContentBox>
      <ContentBox>
        <ContentTitle>휴대폰 번호</ContentTitle>
        <TextField
          sx={{ width: '18rem' }}
          placeholder='휴대폰 번호를 입력해 주세요.'
          value={ownerPhoneno}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setOwnerPhoneno(evt.target.value);
          }}
        />
        <Divider />
      </ContentBox>
      <ContentBox>
        <ContentTitle>비밀번호</ContentTitle>
        <Box>
          <TextField
            sx={{ width: '18rem' }}
            placeholder='비밀번호를 입력해 주세요.'
            type={view ? 'text' : 'password'}
            value={password}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setPassword(evt.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => setView(!view)}>
                    {view ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem' }}
            onClick={changePassword}
          >
            비밀번호 변경
          </Button>
        </Box>
        <Typography>최소 8자 이상 대소문자, 숫자 및 특수문자</Typography>

        <Divider />
      </ContentBox>
      <ContentBox>
        <ContentTitle>이름</ContentTitle>
        <TextField
          sx={{ width: '18rem' }}
          placeholder='이름을 입력해 주세요.'
          value={ownerName}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setOwnerName(evt.target.value);
          }}
        />
        <Divider />
      </ContentBox>
      <ContentBox>
        <ContentTitle>이메일</ContentTitle>
        <TextField
          sx={{ width: '18rem' }}
          placeholder='이메일을 입력해 주세요.'
          value={ownerEmail}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setOwnerEmail(evt.target.value);
          }}
        />
        <Divider />
      </ContentBox>
      <Box
        sx={{
          p: '1rem 0 2rem',
          display: 'flex',
        }}
        className='flex_between'
      >
        <Box>
          <Button
            color='info'
            variant='outlined'
            sx={{ mr: '1rem' }}
            onClick={reset}
          >
            신규 작성
          </Button>
        </Box>
        <Box>
          <Button
            color='info'
            variant='outlined'
            sx={{ mr: '1rem' }}
            onClick={deleteOwner}
          >
            삭제
          </Button>
          <Button color='info' variant='contained' onClick={checkOwner}>
            저장
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </CreatePaper>
  );
};
