import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import DaumPostcode from 'react-daum-postcode';

interface Props {
  open: boolean;
  onClose: () => void;
  request?: any;
  setTarget: Dispatch<React.SetStateAction<any>>;
}

export const SwSearchPostCode: React.FC<Props> = ({
  open,
  onClose,
  request,
  setTarget,
}) => {
  const [writeInfo, setWriteInfo] = useState<any>();

  const handleComplete = (data: any) => {
    console.log('swSearchPost :', data);
    const newAddress = {
      bcode: data?.bcode,
      jibunAddress:
        data?.jibunAddress === '' ? data?.autoJibunAddress : data?.jibunAddress,
      roadAddress: data?.roadAddress,
      sigunguCode: data?.sigunguCode,
      request: request,
    };
    setTarget(newAddress);
    onClose();
  };

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: '52vh',
          minWidth: '44vw',
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant='h6'>주소 검색</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <DaumPostcode
          onComplete={handleComplete}
          style={{ height: '446px' }}
          focusInput={true}
        />
      </DialogContent>
    </Dialog>
  );
};
