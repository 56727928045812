import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props {
  title: string;
  count: string | number;
  postFix?: string;
}

export const TableTitle: React.FC<Props> = ({ title, count, postFix = '' }) => {
  const a = 1;
  return (
    <Box className='flex_center' sx={{ p: '0 0 .8rem .12rem' }}>
      <Typography>{title}</Typography>
      <Typography color='primary' sx={{ ml: '.3rem' }}>
        [{count}]
      </Typography>
      <Typography sx={{ ml: '.3rem' }}>{postFix}</Typography>
    </Box>
  );
};
