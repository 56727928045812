import { Box, Button, styled, Tab, Tabs } from '@mui/material';
import clsx from 'clsx';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { useResize } from '../../../hooks/resize';
import { TabBox } from '../../styles/Styles';

interface Props {}

export const ChallengeManagement: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('create');
        break;
      case 2:
        setValue(2);
        navigate('list');
        break;
      case 3:
        setValue(3);
        navigate('popular');
        break;
      case 4:
        setValue(4);
        navigate('banner');
        break;
      case 5:
        setValue(5);
        navigate('labeling');
        break;
      default:
        setValue(0);
        navigate('/challengemanagement');
    }
  };
  useEffect(() => {
    const path = location.pathname;
    if (path === '/challengemanagement/create') setValue(1);
    else if (path === '/challengemanagement/list') setValue(2);
    else if (path === '/challengemanagement/popular') setValue(3);
    else if (path === '/challengemanagement/banner') setValue(4);
    else setValue(5);
  }, [location.pathname]);
  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>챌린지 관리</title>
      </Helmet>
      <TabBox className='flex_between'>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='챌린지 생성' value={1} />
          <Tab label='챌린지 목록' value={2} />
          <Tab label='인기 검색어' value={3} />
          <Tab label='광고 배너' value={4} />
          <Tab label='이미지 인식' value={5} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
