import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../common/key';

interface Props {
  open: boolean;
  item: any;
  onClose: () => void;
  onChangeItem: (date: string, progress: number) => void;
}

export const SwUserChangeProgress: React.FC<Props> = ({
  open,
  item,
  onClose,
  onChangeItem,
}) => {
  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    if (open) {
      setPercent(item?.progress);
    } else {
      setPercent(0);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          height: '35vh',
          width: '40vh',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>달성률 수정</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Typography sx={{ fontWeight: 'bold', mt: '0.7rem' }}>
          달성률
        </Typography>
        <Typography sx={{ mt: '0.7rem' }}>{item?.date}</Typography>
        <Typography sx={{ mt: '0.7rem' }}>{item?.progress}</Typography>
        <Typography sx={{ fontWeight: 'bold', mt: '1.5rem' }}>
          변경 할 달성률
        </Typography>
        <TextField
          sx={{
            width: '11rem',
            mt: '0.71rem',
            mb: 0,
            input: {
              textAlign: 'center',
            },
          }}
          type='number'
          value={percent}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setPercent(Number(event.target.value));
          }}
        />
      </DialogContent>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogActions>
        <Button color='info' fullWidth size='small' onClick={onClose}>
          취소
        </Button>
        <Button
          color='primary'
          fullWidth
          size='small'
          onClick={() => {
            onChangeItem(item?.date, percent);
            onClose();
          }}
        >
          완료
        </Button>
      </DialogActions>
    </Dialog>
  );
};
