import {
  Box,
  Button,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { InquireTable } from '../../commonComponent/table/InquireTable';
import { SwGridTable } from '../../commonComponent/table/SwGridTable';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import {
  getUserCommunityList,
  downloadUserCommunityList,
} from '../../../common/api/ApiUser';
import { KEY_USER_COMMUNITY_LIST } from '../../../common/key';
import {
  getDeletedReason,
  timestamp2Localestring,
} from '../../../common/helper';

interface Props {}

const columns = [
  'No',
  '커뮤니티 아이디',
  '종류',
  '소유자',
  '커뮤니티 이름',
  '그룹',
  '가입일시',
  '승인일시',
  '탈퇴일시',
  '탈퇴사유',
];

export const UserAttendCommunity: React.FC<Props> = () => {
  const location = useLocation();
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [deletedCount, setDeletedCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [communityList, setCommunityList] = useState<any>([]);
  const [userData, setUserData] = useState<any>(location.state);
  // const [rangeDate, setRangeDate] = useState<DateRange<Date>>([null, null]);

  const {
    data,
    isLoading,
    refetch: communityRefetch,
  } = useQuery(
    KEY_USER_COMMUNITY_LIST,
    () => {
      if (userData.id !== '') {
        return getUserCommunityList(
          userData.id,
          page,
          rowsPerPage,
          rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
          rangeDate[1] === null ? 0 : rangeDate[1].getTime()
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('communityRefetch res: ', res);
        if (res !== null) {
          setCommunityList(res.community.community);
          setTotalRecords(res.pagination.totalRecordCount);
          setDeletedCount(res.community.deletedCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_USER_COMMUNITY_LIST, () =>
      getUserCommunityList(
        userData.id,
        newPage,
        rowsPerPage,
        rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        rangeDate[1] === null ? 0 : rangeDate[1].getTime()
      )
    );
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  useEffect(() => {
    if (userData.id !== '') communityRefetch();
  }, [userData]);

  useEffect(() => {
    if (location.state) {
      setUserData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    communityRefetch();
  }, [rangeDate]);

  const onDownloadFile = () => {
    downloadUserCommunityList(
      userData.id,
      rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      rangeDate[1] === null ? 0 : rangeDate[1].getTime()
    );
  };

  if (isLoading) return <div>isLoading...</div>;

  return (
    <>
      <Box
        className='flex_center'
        sx={{ justifyContent: 'flex-end', marginTop: '-1.4rem' }}
      >
        <Button
          color='info'
          variant='outlined'
          sx={{ mr: '1rem', p: '.2rem 1rem' }}
          onClick={onDownloadFile}
        >
          데이터 다운로드
          <FileDownloadRoundedIcon color='success' />
        </Button>
      </Box>
      <Paper sx={{ p: '.5rem 1rem', m: '1rem 0' }}>
        <Box
          sx={{
            p: '1rem .5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography component='div'>
            커뮤니티 가입 내역{' '}
            <span style={{ color: '#5891FF' }}>
              ({totalRecords - deletedCount})
            </span>{' '}
            / 탈퇴 포함{' '}
            <span style={{ color: '#5891FF' }}>({totalRecords})</span>
          </Typography>
          <Box className='flex_center'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {communityList?.map((row: any, idx: number) => (
                <TableRow key={row.communityId}>
                  <TableCell
                    key={row.na}
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {(rowsPerPage * (page - 1) + idx + 1).toString()}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.communityId}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.official === 0
                      ? '개인'
                      : row.type === 0
                      ? '공개'
                      : '승인가입'}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.owner}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.groupName !== 'no-group' ? row.groupName : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.official === 1 && row.type === 1
                      ? timestamp2Localestring(row.createDate)
                      : timestamp2Localestring(row.createDate)}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.official === 1 && row.type === 1
                      ? row.joinTime !== null
                        ? timestamp2Localestring(row.joinTime)
                        : '날짜 미확인'
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.deleted === 1
                      ? timestamp2Localestring(row.deletedDate, 1000)
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.deleted === 1
                      ? getDeletedReason(row.deletedReason)
                      : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
    </>
  );
};
