import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { MultilineTextField } from '../../styles/Styles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import {
  postOperationGuide,
  putOperationGuide,
  deleteOperationGuide,
} from '../../../common/api/ApiOperation';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { IOperationGuide } from '../../../models/operation';
import { IUploadFile } from '../../../models/common';
import { MobileRegexp, timestamp2Localestring } from '../../../common/helper';
import {
  batchBlockingUsers,
  controlBlockingUsers,
  updateBlockMemo,
} from '../../../common/api/ApiUser';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onEdit: () => void;
  edit: number; // 1 : 신규 등록, 2: 수정
  selected: any;
  maxSize?: number;
}

export const UserRejectSetting: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  edit,
  onEdit,
  selected,
  maxSize = 500,
}) => {
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const [phonenoList, setPhonenoList] = useState<string>('');
  const [invalidData, setInvalidData] = useState<any[]>([]);
  const [memo, setMemo] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const {
    mutate: controlBlockingUsersMutation,
    isError: isUnblockingMutateError,
  } = useMutation(controlBlockingUsers, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage(`${selected?.deleted === 1 ? '차단' : '해제'}했습니다.`);
      onConfirm();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        `${
          selected?.deleted === 1 ? '차단' : '해제'
        }하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.`
      );
    },
  });

  const controlBlocking = (idx: number, unBlocked: boolean) => {
    const idxList = [idx];
    const newData: any = {
      type: unBlocked ? 'block' : 'unblock',
      message: memo,
      idxList: idxList,
    };
    controlBlockingUsersMutation(newData);
  };

  const { mutate: batchBlockingUsersMutation, isError: isblockingMutateError } =
    useMutation(batchBlockingUsers, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('차단했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '차단하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const checkPhonenoList = () => {
    const newContent = phonenoList.split('\n');

    const reducer = (s: any[], val: string, idx: number) => {
      if (s.findIndex(q => q.value === val) === -1) {
        if (MobileRegexp.test(val)) {
          s.push(val.replaceAll('-', ''));
        } else {
          val !== '' && val.length > 0 ? invalidData.push(val) : '';
        }
      }
      return s;
    };
    const sortList = newContent.reduce(reducer, []);

    if (sortList.length > maxSize) {
      setOpenSnackbar(true);
      setDialogMessage(`데이터가 ${maxSize}개를 초과하여 차단이 불가합니다.`);
      return;
    }

    if (sortList.length > 0 && invalidData.length > 0) {
      setOpenSnackbar(true);
      setDialogMessage(
        `문제 데이터를 제외하고 차단을 완료했습니다. \n문제 데이터 : ${invalidData}`
      );
    }

    if (sortList.length === 0 && invalidData.length > 0) {
      setOpenSnackbar(true);
      setDialogMessage(
        `문제 데이터를 제외하고 차단할 데이터가 없습니다. \n문제 데이터 : ${invalidData}`
      );
      return;
    }

    if (sortList.length === 0) {
      setOpenSnackbar(true);
      setDialogMessage(`차단할 데이터가 없습니다.`);
      return;
    }

    const newData: any = {
      message: memo,
      phonenos: sortList,
    };
    batchBlockingUsersMutation(newData);
  };

  const { mutate: updateBlockMemoMutation, isError: isBlockMemoMutateError } =
    useMutation(updateBlockMemo, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage(`차단 사유를 변경했습니다.`);
        onConfirm();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          `차단 사유를 변경하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.`
        );
      },
    });

  const saveMessage = (idx: number) => {
    const idxList = [idx];
    const newData: any = {
      message: memo,
      idxList: idxList,
    };
    updateBlockMemoMutation(newData);
  };

  useEffect(() => {
    if (edit === 3) {
      setMemo('');
    } else {
      setMemo(selected?.memo);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <span>차단 정보</span>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent sx={{ p: '1.5rem 2.6em' }}>
          {edit === 1 ? (
            <>
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                닉네임
              </Typography>
              <TextField
                disabled
                fullWidth
                inputRef={titleRef}
                defaultValue={selected?.name}
              />
              <Divider />
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                전화번호
              </Typography>
              <TextField
                disabled
                fullWidth
                inputRef={titleRef}
                defaultValue={selected?.phoneno}
              />
              <Divider />
              <TextField
                inputRef={contentRef}
                multiline
                rows={8}
                fullWidth
                value={memo}
                onChange={evt => setMemo(evt.target.value)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                  },
                }}
              />
            </>
          ) : edit === 2 ? (
            <>
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                닉네임: {selected?.name}
              </Typography>
              <Typography sx={{ color: '#999999' }}>
                차단 일시:{' '}
                {selected && timestamp2Localestring(selected?.createDate, 1000)}
              </Typography>
              {selected && selected?.unblockDate && (
                <Typography sx={{ color: '#999999' }}>
                  해제 일시:{' '}
                  {selected &&
                    timestamp2Localestring(selected?.unblockDate, 1000)}
                </Typography>
              )}
              <Divider />
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                차단 사유
              </Typography>
              <MultilineTextField
                multiline
                rows={10}
                fullWidth
                value={selected?.memo}
              />
            </>
          ) : edit === 3 ? (
            <>
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                차단 번호
              </Typography>
              <MultilineTextField
                placeholder='예시
                  01012341234
                  01023452345'
                multiline
                rows={10}
                fullWidth
                value={phonenoList}
                onChange={evt => setPhonenoList(evt.target.value)}
              />
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                차단 사유
              </Typography>
              <MultilineTextField
                multiline
                rows={10}
                fullWidth
                value={memo}
                onChange={evt => setMemo(evt.target.value)}
              />
            </>
          ) : (
            ''
          )}
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          {edit === 2 ? (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button onClick={onEdit} color='info' variant='contained'>
                수정
              </Button>
            </>
          ) : edit === 1 ? (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                onClick={() => saveMessage(selected?.idx)}
                color='info'
                variant='contained'
              >
                차단 사유 저장
              </Button>
              <Button
                onClick={() => {
                  controlBlocking(selected?.idx, selected?.deleted === 1);
                }}
                color='primary'
                variant='contained'
              >
                {selected?.deleted === 1 ? '차단하기' : '해제하기'}
              </Button>
            </>
          ) : edit === 3 ? (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                onClick={checkPhonenoList}
                color='info'
                variant='contained'
              >
                차단하기
              </Button>
            </>
          ) : (
            ''
          )}
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
