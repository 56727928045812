import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Information
 */
export const getProgramRequestList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/creation-request?operator=${data.operator}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramRequestListByOperator = () =>
  xapi
    .get(`/admin/v2/apis/program/information/creation-request-operator`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const confirmProgramRegister = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/information/${data.programId}/confirm`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeProgramApplyStatus = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/apply-status/${data.status}?message=${data.message}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeProgramMasterStatus = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/status/${data.status}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const updateProgramHealthPoint = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/information/health-point`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const updateProgramGoalPoint = (data: any) =>
  xapi.post(`/admin/v2/apis/program/information/goal-point`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerProgramMaster = (data: any) =>
  xapi.post(`/admin/v2/apis/program/information/master`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const cloneProgramMaster = (data: any) =>
  xapi.post(`/admin/v2/apis/program/information/clone`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const restoreProgramCurriculum = (data: any) =>
  xapi.put(`/admin/v2/apis/program/information/restore`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const putProgramRecordItem = (data: any) =>
  xapi.put(`/admin/v2/apis/program/information/record-item`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getProgramListByPortal = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/portalId/${data.portalId}?isCompleted=${data.isCompleted}&startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
/*
 * Curriculum
 */
export const getProgramCurriculumList = (data: any) =>
  xapi
    .get(`/admin/v2/apis/program/curriculum/${data.curriculumId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramCurriculumWeekAdd = (data: any) =>
  xapi.post(`/admin/v2/apis/program/curriculum/week`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postProgramCurriculumWeekDelete = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/curriculum/${data.curriculumId}/week/${data.weekId}/${data.type}`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putProgramCurriculumWeekTitle = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/program/curriculum/${data.curriculumId}/week/${data.weekId}/title`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
/*
 * Mission
 */
export const getProgramMissionDetailInfo = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/curriculum/mission/${data.missionId}/type/${data.type}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramMissionQnaQuestionList = (data: any) =>
  xapi
    .get(`/admin/v2/apis/program/curriculum/mission/${data.missionId}/question`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putProgramCurriculumMissionQuestionDelete = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/program/curriculum/mission/${data.missionId}/${data.type}/delete-question`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putProgramCurriculumMissionDelete = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/program/curriculum/mission/${data.missionId}/${data.type}/delete`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putProgramCurriculumMissionCopy = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/program/curriculum/mission/${data.missionId}/${data.type}/copy`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMissionMovie = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/curriculum/mission/movie`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMissionMagazine = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/curriculum/mission/magazine`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMissionQna = (data: any) =>
  xapi.post(`/admin/v2/apis/program/curriculum/mission/qna`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const putProgramMissionQnaType = (data: any) =>
  xapi
    .put(`/admin/v2/apis/program/curriculum/mission/qna/type`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putMissionQnaQuestionInfo = (data: any) =>
  xapi
    .put(`/admin/v2/apis/program/curriculum/mission/qna/info`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMissionQnaQuestion = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/curriculum/mission/qna/question`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMissionQnaQuestionBatch = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/curriculum/mission/qna/question-batch`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putProgramMissionQnaQuestionIsRequired = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/program/curriculum/mission/qna/question/required`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putProgramMissionQnaQuestionOrder = (data: any) =>
  xapi
    .put(`/admin/v2/apis/program/curriculum/mission/qna/question/order`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteProgramMissionQnaQuestion = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/program/curriculum/mission/qna/${data.qnaId}/question/${data.questionId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const cloneProgramMissionQnaQuestion = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/curriculum/mission/qna/question/clone`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMissionLifeStyle = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/curriculum/mission/lifestyle`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const putProgramCurriculumMissionList = (data: any) =>
  xapi.put(`/admin/v2/apis/program/curriculum/mission/list`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const putProgramCurriculumMissionInfo = (data: any) =>
  xapi.put(`/admin/v2/apis/program/curriculum/mission/info`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postProgramMissionContentFavorites = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/curriculum/mission/favorites`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteProgramMissionContentFavorites = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/program/curriculum/mission/content/${data.contentId}/favorites`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
/*
 * Filter
 */
export const getProgramFilterInfo = (targetId: any) =>
  xapi
    .get(`/admin/v2/apis/program/information/filter/${targetId}/info`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramCommunityFilterList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/filter/community/${data.type}/list?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramCommunityFilterInfo = (targetId: any) =>
  xapi
    .get(`/admin/v2/apis/program/information/filter/community/${targetId}/info`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramCommunityFilterLoadList = (targetId: any) =>
  xapi
    .get(`/admin/v2/apis/program/information/filter/${targetId}/load`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerProgramFilter = (data: any) =>
  xapi.post(`/admin/v2/apis/program/information/filter`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });
/*
 * Reward
 */
export const deleteProgramReward = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/program/information/${data.programId}/reward/${data.rewardId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramRewardList = (programId: string) =>
  xapi
    .get(`/admin/v2/apis/program/information/${programId}/reward`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramRewardUserList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/${data.programId}/reward/${data.rewardId}/recipient?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramRewardDrawingResult = (
  type: string,
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/reward/${rewardId}/draw/result/${type}?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramRewardDrawingApply = (
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/reward/${rewardId}/draw/apply?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramAttendUserList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/${data.programId}/attend-user?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerProgramReward = (data: any) =>
  xapi.post(`/admin/v2/apis/program/information/reward-one`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const programDrawManualCoupon = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/reward/${data.rewardId}/manual-coupon-draw/${data.drawType}`,
      {
        userIds: data.userIds,
        point: data.point,
        message: data.message,
      }
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const programDrawManualTicket = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/reward/${data.rewardId}/manual-ticket-draw/${data.drawType}`,
      {
        userIds: data.userIds,
        point: data.point,
        message: data.message,
      }
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramGoalAchieverList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/${data.programId}/reward/${data.rewardId}/goal-achiever/${data.type}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramUserListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/attend-user/byPhonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramRewardDrawUserListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/reward/${data.rewardId}/draw-user/byPhonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramRewardDrawSubmit = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/reward/${data.rewardId}/draw/submit`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramRewardDrawUserList = (
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/reward/${rewardId}/draw/user?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramRewardDrawCondition = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/reward/${data.rewardId}/draw/condition`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramRewardDrawDrawing = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/reward/${data.rewardId}/draw/drawing`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramRewardChangeDrawResult = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/reward/${data.rewardId}/change/draw-result`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramRewardResetDrawResult = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/reward/${data.rewardId}/reset/draw-result`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramRewardDrawUserDownload = (
  rewardId: string,
  type: string
) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/reward/${rewardId}/draw/user-download/${type}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `program_draw_user_${type}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getProgramRewardSubmitUserDownload = (
  rewardId: string,
  type: string
) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/reward/${rewardId}/submit/user-download/${type}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `program_submit_user_${type}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });
/*
 * Member
 */

export const getUserListWithFilter = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/${data.programId}/users?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramMemberList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/${data.programId}/member?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramMemberListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/member-by-phonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMember = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/information/${data.programId}/member`, {
      userIds: data.userIds,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMemberByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/program/information/${data.programId}/member-by-phonenos`,
      {
        phonenos: data.phonenos,
      }
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteProgramMember = (data: any) =>
  xapi
    .put(`/admin/v2/apis/program/information/${data.programId}/member/delete`, {
      userIds: data.userIds,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadProgramMemberList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/${data.programId}/member/download?status=${data.status}&type=${data.type}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'program_member.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

/*
 * Template Code
 */
export const getProgramTemplateCodeList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/templatecode?status=${data.status}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerTemplateCodeMaster = (data: any) =>
  xapi.post(`/admin/v2/apis/program/templatecode/master`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const putProgramTemplateCodeRecordItem = (data: any) =>
  xapi
    .put(`/admin/v2/apis/program/templatecode/record-item`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const confirmProgramTemplateCodeRegister = (data: any) =>
  xapi.post(`/admin/v2/apis/program/templatecode/confirm`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteProgramTemplateCode = (data: any) =>
  xapi.post(`/admin/v2/apis/program/templatecode/delete`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const updateProgramTemplateCodeStatus = (data: any) =>
  xapi.put(`/admin/v2/apis/program/templatecode/status`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getProgramMagazineList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/program/templatecode/${data.templateCode}/magazine?searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postProgramMagazine = (data: any) =>
  xapi.post(`/admin/v2/apis/program/templatecode/magazine`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteProgramMagazine = (data: any) =>
  xapi
    .post(`/admin/v2/apis/program/templatecode/magazine/delete`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
