import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Slide,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { SwDateRangePicker } from '../commonComponent/dateSetting/SwDateRangePicker';
import { SwPagination } from '../commonComponent/tableElement/SwPagination';
import { SelectSearchBox } from '../commonComponent/SelectSearchBox';
import { getCommunityDailyStepList } from '../../common/api/ApiCommunity';
import {
  KEY_OPERATING_METRICS_NEW_USER,
  KEY_OPERATING_METRICS_TOTAL_USER,
  KEY_OPERATING_METRICS_ACTIVE_TOTAL_USER,
  KEY_OPERATING_METRICS_MAU,
  KEY_OPERATING_METRICS_DAU,
  KEY_OPERATING_METRICS_NEW_COMMUNITY,
  KEY_OPERATING_METRICS_NEW_CHALLENGE,
} from '../../common/key';
import { ICommunityReqeust } from '../../models/community';
import {
  timestamp2string,
  getYearList,
  getMonthList,
} from '../../common/helper';
import { UserMetrics } from './UserMetrix';
import { CommunityMetrics } from './CommunityMetrix';
import { ChallengeMetrics } from './ChallengeMetrix';
import { useSession } from '../../hooks/session';

interface Props {}

const StatBox = styled(Box)({
  margin: '1rem 0rem',
});

export const UserCommunityChallengeMetrics: React.FC<Props> = () => {
  const navigate = useNavigate();
  const { loginVal } = useSession();

  return (
    <>
      {loginVal.value?.user?.id === 'kmj' ||
      loginVal.value?.user?.id === 'shjin' ? (
        <>
          <StatBox>
            <UserMetrics metricsType={KEY_OPERATING_METRICS_NEW_USER} />
          </StatBox>
          <StatBox>
            <UserMetrics metricsType={KEY_OPERATING_METRICS_TOTAL_USER} />
          </StatBox>
          <StatBox>
            <UserMetrics
              metricsType={KEY_OPERATING_METRICS_ACTIVE_TOTAL_USER}
            />
          </StatBox>
          <StatBox>
            <UserMetrics metricsType={KEY_OPERATING_METRICS_MAU} />
          </StatBox>
          <StatBox>
            <UserMetrics metricsType={KEY_OPERATING_METRICS_DAU} />
          </StatBox>
          <StatBox>
            <CommunityMetrics
              metricsType={KEY_OPERATING_METRICS_NEW_COMMUNITY}
            />
          </StatBox>
          <StatBox>
            <ChallengeMetrics
              metricsType={KEY_OPERATING_METRICS_NEW_CHALLENGE}
            />
          </StatBox>
        </>
      ) : (
        <StatBox>
          <Typography sx={{ mt: '2rem', mb: '2rem' }}>
            데이터가 없습니다.
          </Typography>
        </StatBox>
      )}
    </>
  );
};
