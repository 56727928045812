import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { MultilineTextField } from '../../styles/Styles';
import {
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  ProgramTypeBox,
  ProgramTypeTitle,
  ColorTableCell,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './ProgramStyles';
import { RESULT_OK } from '../../../common/resultCode';
import { getRewardDrawingResult } from '../../../common/api/ApiReward';
import { ProgramRewardDrawUserGivetype0 } from './ProgramRewardDrawUserGivetype0';
import {
  KEY_PROGRAM_REWARD_DRAWING_USER_LIST,
  KEY_PROGRAM_REWARD_LIST,
} from '../../../common/key';
import {
  getProgramRewardDrawUserList,
  getProgramRewardList,
  postProgramRewardResetDrawResult,
} from '../../../common/api/ApiProgram';
import { ProgramRewardDrawUserGivetype2 } from './ProgramRewardDrawUserGivetype2';
import { useSession } from '../../../hooks/session';
import { ProgramRewardDrawingSetting } from './drawing/ProgramRewardDrawingSetting';
import { ProgramRewardGivingReward } from './drawing/ProgramRewardGivingReward';
import { ProgramRewardDrawing } from './drawing/ProgramRewardDrawing';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';

export const PROGRAM_REWARD_STATE_NONE = 'none';
export const PROGRAM_REWARD_STATE_SETTING = 'setting';
export const PROGRAM_REWARD_STATE_DRAWING = 'drawing';
export const PROGRAM_REWARD_STATE_SUBMIT = 'submit';

const columns = [
  '순서',
  '리워드 이름',
  '수량',
  '발급(응모) 인원',
  '지급 방법',
  '사용 방법',
  '상태',
];

const guidetool: any = {
  title: '소제목',
  contents: '안내내용',
  image: '',
};

const clickColumnlist = ['리워드 이름', '수량', '응모 인원', '발급 쿠폰수'];

const drawColumn = ['번호', '연락처', '참여자 닉네임'];

interface Props {
  rewardGivetype: any;
  rewardUsetype: any;
  programInfo: any;
  setProgramInfo: Dispatch<React.SetStateAction<any>>;
}

export const ProgramRewardDraw: React.FC<Props> = ({
  rewardGivetype,
  rewardUsetype,
  programInfo,
  setProgramInfo,
}) => {
  const queryClient = useQueryClient();
  const putInputRef = useRef<any>();
  const delInputRef = useRef<any>();
  const guideTitleRef = useRef<any>();
  const guideContentsRef = useRef<any>();
  const { loginVal } = useSession();
  const [rewardDrawUserList, setRewardDrawUserList] = useState<any>({
    programId: '',
    rewardId: '',
    rewardCount: 0,
    unexcept: [],
    except: [],
    all: [],
    winner: [],
  });
  const [rewardConditionList, setRewardConditionList] = useState<any>([]);
  const [changeState, setChangeState] = useState<string>(
    PROGRAM_REWARD_STATE_NONE
  );
  const [rewardDrawingList, setRewardDrawingList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [programId, setProgramId] = useState<string>('');
  const [programType, setProgramType] = useState<number>(1);
  const [rewardId, setRewardId] = useState<string>('');
  const [giveType, setGiveType] = useState<string>('');
  const [useType, setUseType] = useState<string>('0/0');
  const [isDuplicate, setIsDuplicate] = useState<string>('');
  const [rewardMaxCount, setRewardMaxCount] = useState<number>();
  const [title, setTitle] = useState<string>('');
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<any[]>([guidetool]);
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>('');
  const [rewardImage, setRewardImage] = useState<string>('');
  const [rewardImageFile, setRewardImageFile] = useState<string>('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [creator, setCreator] = useState<string>('');
  const [rewardCount, setRewardCount] = useState<number>(0);
  const [rewardLocation, setRewardLocation] = useState<string>('');
  const [rewardStatus, setRewardStatus] = useState<number>(1);
  const [rewardSequence, setRewardSequence] = useState<number>(1);
  const [rewardPointPerGet, setRewardPointPerGet] = useState<number>(0);
  const [rewardStampPerGet, setRewardStampPerGet] = useState<number>(0);
  const [winnerUserList, setWinnerUserList] = useState<any>([]);
  const [nonwinnerUserList, setNonwinnerUserList] = useState<any>([]);
  const [rewardUserList, setRewardUserList] = useState<any>([]);
  const [putUserList, setPutUserList] = useState<any>([]);
  const [delUserList, setDelUserList] = useState<any>([]);
  const [targetUserList, setTargetUserList] = useState<any>([]);
  const [redrawDisable, setRedrawDisable] = useState<any>(true);
  const [filterList, setFilterList] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [chosenRequest, setChosenRequest] = useState<any>([['-', '-', '-']]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<any>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(true);

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const {
    data,
    isLoading,
    refetch: programRewardRefetch,
  } = useQuery(
    KEY_PROGRAM_REWARD_LIST,
    () => {
      console.log('programId: ', programId);
      if (programId !== undefined && programId.length > 0)
        return getProgramRewardList(programId);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res !== null) {
          const newRewardList = res.reward.map((item: any, idx: number) => {
            const newGiveType =
              rewardGivetype[item.rewardGiveType]
                .programRewardGivetypeDescription !== undefined
                ? rewardGivetype[item.rewardGiveType]
                    .programRewardGivetypeDescription
                : '-';
            const newUseType = rewardUsetype
              .filter(
                (useItem: any) =>
                  useItem.programRewardUsetype === item.rewardUseType &&
                  useItem.programRewardUsesubtype === item.rewardUsetypeSub
              )
              .map((useItem: any) => useItem.programRewardUsetypeDescription);

            const newReward = {
              sequence: idx + 1,
              title: item.rewardTitle,
              count: item.rewardMaxCount,
              applyCount: item.drawUserCount,
              resultCount: item.attendUserCount,
              giveTypeStr: newGiveType,
              giveType: item.rewardGiveType,
              useType: newUseType,
              status: rewardStatus === 0 ? 'Close' : 'Open',
            };
            return newReward;
          });

          setRequestList(newRewardList);
          setRewardList(res.reward);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: rewardDrawUserRefetch } = useQuery(
    KEY_PROGRAM_REWARD_DRAWING_USER_LIST,
    () => {
      if (checkRequest) {
        if (rewardId.length > 0) return getProgramRewardDrawUserList(rewardId);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('reward drawing user list :', res);
        setLoading(false);
        if (res !== null) {
          const newUserList: any = {
            programId: programId,
            rewardId: rewardId,
            rewardCount: rewardMaxCount,
            creator: creator,
            unexcept: res.rewardUser.unexcept,
            except: res.rewardUser.except,
            all: res.rewardUser.all,
            winner: res.rewardUser.winner,
          };
          setRewardDrawUserList(newUserList);
          if (
            res.rewardUser.winner !== null &&
            res.rewardUser.winner.length > 0
          ) {
            setChangeState(PROGRAM_REWARD_STATE_SUBMIT);
          }
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const {
    mutate: postRewardResetDrawResultMutation,
    isError: isMutateChangeError,
  } = useMutation(postProgramRewardResetDrawResult, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnakerDialog(true);
        setDialogMessage('추첨 상태를 초기화했습니다..');
        window.location.reload();
      } else {
        setOpenDialog(true);
        setDialogMessage(`추첨 상태 초기화 실패 : ${res.resultCodeMsg}`);
      }
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '추첨 상태를 초기화하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const onRefresh = () => {
    rewardDrawUserRefetch();
  };

  const prefetchUser = (newRewardId: string) => {
    queryClient.prefetchQuery(KEY_PROGRAM_REWARD_DRAWING_USER_LIST, () =>
      getProgramRewardDrawUserList(newRewardId)
    );
  };

  const clickRow = (idx: number) => {
    console.log('click row :', idx, ', rewardList[idx]:', rewardList[idx]);
    setRedrawDisable(rewardList[idx].attendUserCount > 0);
    setSelectedRow(idx);
    setRewardSequence(idx + 1);
    setRewardId(rewardList[idx].rewardId);
    setGiveType(String(rewardList[idx].rewardGiveType));
    setUseType(
      `${rewardList[idx].rewardUseType}/${rewardList[idx].rewardUsetypeSub}`
    );
    setIsDuplicate(rewardList[idx].rewardIsDuplicate);
    const newRangeDate = [
      timestamp2Localestring(rewardList[idx].rewardUseStime, 1000),
      timestamp2Localestring(rewardList[idx].rewardUseEtime, 1000),
    ];
    setRangeDate(newRangeDate);
    setRewardMaxCount(rewardList[idx].rewardMaxCount);
    setTitle(rewardList[idx].rewardTitle);
    setRewardImage(`${imageUrlPrefix}${rewardList[idx].rewardImgpathMain}`);
    setRewardLocation(rewardList[idx].rewardAddress);
    setRewardPointPerGet(rewardList[idx].rewardPointPerGet);
    setRewardStampPerGet(rewardList[idx].rewardStampPerGet);

    const newDescription =
      rewardList[idx].rewardDescription !== undefined &&
      rewardList[idx].rewardDescription !== null
        ? JSON.parse(rewardList[idx].rewardDescription)
        : [];
    console.log('newDescription :', newDescription);
    const newGuideList =
      newDescription.length > 0
        ? newDescription.map((item: any) => {
            const newGuide = {
              title: item.t,
              contents: item.c,
              image: item.i,
              imageFile: null,
            };
            return newGuide;
          })
        : [];
    setGuideLists(newGuideList);

    const Chosen = rewardList[idx];
    const newChosen = [
      Chosen.rewardTitle,
      Chosen.rewardMaxCount,
      Chosen.drawUserCount,
      Chosen.attendUserCount,
    ];
    setChosenRequest([newChosen]);

    rewardList[idx].attendUserCount === 0
      ? setChangeState(PROGRAM_REWARD_STATE_NONE)
      : setChangeState(PROGRAM_REWARD_STATE_SUBMIT);

    setCheckRequest(true);
    prefetchUser(rewardList[idx].rewardId);
  };

  const resetDrawResult = () => {
    const newData = {
      rewardId: rewardId,
    };
    postRewardResetDrawResultMutation(newData);
  };

  useEffect(() => {
    console.log('RewardList: ', rewardList);
  }, [rewardList]);

  useEffect(() => {
    if (rewardGivetype) {
      console.log('ProgramOpenRegion rewardGivetype :', rewardGivetype);
    }

    if (rewardUsetype) {
      console.log('ProgramOpenRegion rewardUsetype :', rewardUsetype);
    }
  }, [rewardGivetype, rewardUsetype]);

  useEffect(() => {
    if (targetUserList.length > 0) {
      console.log('targetUserList :', targetUserList);
      if (request.searchType === 0) {
        const newTargetList = targetUserList.filter((target: any) => {
          const newUser = putUserList.filter(
            (user: any) => target.id === user.id
          );
          if (newUser && newUser.length > 0) return false;
          return true;
        });
        const newPutList = [...putUserList, ...newTargetList];
        setPutUserList(newPutList);
      } else {
        const newTargetList = targetUserList.filter((target: any) => {
          const newUser = delUserList.filter(
            (user: any) => target.id === user.id
          );
          if (newUser && newUser.length > 0) return false;
          return true;
        });
        const newDelList = [...delUserList, ...newTargetList];
        setDelUserList(newDelList);
        console.log('targetUserList setDelUserList:', delUserList);
      }
    }
  }, [targetUserList]);

  useEffect(() => {
    if (
      changeState === PROGRAM_REWARD_STATE_DRAWING ||
      changeState === PROGRAM_REWARD_STATE_SUBMIT
    ) {
      rewardDrawUserRefetch();
    }
  }, [changeState]);

  useEffect(() => {
    if (programId.length > 0) {
      programRewardRefetch();
    }
  }, [programId]);

  useEffect(() => {
    if (programInfo) {
      console.log('RewardPutDel programInfo :', programInfo);
      setProgramId(programInfo.programId);
      setProgramType(programInfo.programType);

      setRangeDate([null, null]);
      setRewardMaxCount(0);
      setTitle('');
      setRewardImage('');
      setGiveType('');
      setUseType('');
      setSelectedRow(-1);
    }
  }, [programInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Paper>
        <Typography sx={{ m: '1rem 0 ', fontWeight: 'bold' }}>
          리워드 목록
        </Typography>
        <TableContainer
          sx={{ p: '0 1rem', height: '20vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column: any, idx: number) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList !== undefined && requestList.length > 0 ? (
                requestList?.map((row: any, idx: number) => (
                  <TableRow
                    key={idx.toString()}
                    onClick={() => clickRow(idx)}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                  >
                    <TableCell>{row.sequence}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.count}</TableCell>
                    {row.giveType === 0 ? (
                      <TableCell>{row.resultCount}</TableCell>
                    ) : (
                      <TableCell>{`${row.resultCount}(${row.applyCount})`}</TableCell>
                    )}
                    <TableCell>{row.giveTypeStr}</TableCell>
                    <TableCell>{row.useType}</TableCell>
                    <TableCell>{row.status}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <RequestTable columns={clickColumnlist} rows={chosenRequest} />

      <ProgramRewardDrawingSetting
        rewardDrawUserList={rewardDrawUserList}
        setRewardDrawUserList={setRewardDrawUserList}
        rewardConditionList={rewardConditionList}
        setRewardConditionList={setRewardConditionList}
        changeState={changeState}
        setChangeState={setChangeState}
        onRefresh={onRefresh}
      />
      <ProgramRewardDrawing
        rewardDrawUserList={rewardDrawUserList}
        setRewardDrawUserList={setRewardDrawUserList}
        rewardConditionList={rewardConditionList}
        setRewardConditionList={setRewardConditionList}
        changeState={changeState}
        setChangeState={setChangeState}
        onRefresh={onRefresh}
      />
      <ProgramRewardGivingReward
        programId={programId}
        rewardId={rewardId}
        rewardInfo={rewardList[selectedRow]}
        rewardDrawUserList={rewardDrawUserList}
        setRewardDrawUserList={setRewardDrawUserList}
        changeState={changeState}
        setChangeState={setChangeState}
      />
      <Box className='flex_end'>
        <Box>
          <Button
            disabled={redrawDisable}
            variant='contained'
            color='info'
            onClick={() => resetDrawResult()}
          >
            재추첨 하기
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      {loading && <SwLoading />}
    </Paper>
  );
};
