import axios from 'axios';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import clsx from 'clsx';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import SearchIcon from '@mui/icons-material/Search';
import { TableTitle } from '../../../commonComponent/tableElement/TableTitle';
import {
  KEY_CHALLENGE_RESULT_DOWNLOAD,
  KEY_CHALLENGE_RESULT_LIST,
  KEY_QUESTION_ANSWER_RESULT_LIST,
} from '../../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../../common/helper';
import { SwPagination } from '../../../commonComponent/tableElement/SwPagination';
import { xapi } from '../../../../hooks/session';
import SwLoading from '../../../commonComponent/spinner/SwLoading';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwLoadAttendUser } from '../../../commonComponent/SwLoadAttendUser';
import {
  downloadQuestionAnswerAttendUser,
  getQuestionAnswerResultList,
} from '../../../../common/api/ApiQuestionAnswer';
import { SwQuestionAnswerLoadAttendUser } from '../../../commonComponent/program/SwQuestionAnswerLoadAttendUser';
import { SwQuestionAnswerViewAnswer } from '../../../commonComponent/program/SwQuestionAnswerViewAnswer';

const TitleTypography = styled(Typography)({
  padding: '.5rem .5rem .5rem .15rem',
  marginBottom: '.33rem',
  fontWeight: 500,
  fontSize: '1rem',
});

const GridTitle = styled(Grid)({
  border: '1px solid #E7EDF6',
  backgroundColor: '#F9FAFC',
  borderLeft: 'none',
  padding: '.7em',
});

const GridCell = styled(Grid)({
  border: '1px solid #E7EDF6',
  borderLeft: 'none',
  padding: '.7em',
});

const Item = styled(Box)({
  textAlign: 'center',
});

const columnsType = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '답변일시',
  '점수',
  '응모 횟수',
  '쿠폰 발급수',
];

interface Props {
  questionAnswerInfo: any;
  setQuestionAnswerInfo: Dispatch<React.SetStateAction<any>>;
  reloadQuestionAnswer: (id: string) => void;
}

export const QuestionAnswerResult: React.FC<Props> = ({
  questionAnswerInfo,
  setQuestionAnswerInfo,
  reloadQuestionAnswer,
}) => {
  const queryClient = useQueryClient();
  const resultInputRef = useRef<any>();
  const [resultFilter, setResultFilter] = useState<string>('');
  const [requestList, setRequestList] = useState<any>([]);
  const [resultInfo, setResultInfo] = useState<any>();
  const [type, setType] = useState<number>(1);
  const [qnaId, setQnaId] = useState<string>('');
  const [hasReward, setHasReward] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>();
  const [targetCount, setTargetCount] = useState<number>(0);
  const [quizReport, setQuizReport] = useState<any>();
  const [columns, setColumns] = useState<string[]>([]);
  const rowsPerPageOptions = [30, 50, 100];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [searchKey, setSearchKey] = useState<string>('questionAnswer');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dailyStatType, setDailyStatType] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [downloadType, setDownloadType] = useState<any>('');
  const [alertMessage, setAlertMessage] = useState<any>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAttendUser, setOpenAttendUser] = useState<boolean>(false);
  const [openUserAnswer, setOpenUserAnswer] = useState<boolean>(false);

  const processResult = (result: any) => {
    if (result) {
      const newRequestList = result.result.map((item: any) => {
        console.log('-> result:', item);
        const newDate = timestamp2Localestring(item.createDate, 1000);

        var newResult: any[] = [];
        newResult = [
          item.name,
          item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
          item.nickName !== null ? item.nickName : '-',
          item.userId,
          item.phoneno,
          newDate,
          item.correctCount,
          item.drawCount,
          item.couponCount,
        ];
        setColumns(columnsType);
        return newResult;
      });
      return newRequestList;
    }
    return [];
  };

  const {
    data,
    isLoading,
    refetch: questionAnswerResultdRefetch,
  } = useQuery(
    KEY_QUESTION_ANSWER_RESULT_LIST,
    () => {
      const newData = {
        qnaId: qnaId,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (qnaId.length > 0) return getQuestionAnswerResultList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res && res.result) {
          setRequestList(processResult(res.result));
          setResultInfo(res.result);
          setTargetCount(res.result.targetCount);
          setQuizReport(res.result.quizReport);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const resultPrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_QUESTION_ANSWER_RESULT_LIST, () => {
      const newData = {
        qnaId: qnaId,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      getQuestionAnswerResultList(newData);
    });
  };

  const downloadQuestionAnswerLocal = (param: any) =>
    xapi
      .get(
        `/admin/v2/apis/question-answer/information/download/${param.downloadType}?qnaId=${param.qnaId}&type=${param.type}&searchKey=${param.searchKey}&searchWord=${param.searchWord}&startDate=${param.startDate}&endDate=${param.endDate}`,
        { responseType: 'blob' }
      )
      .then(res => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `questionAnswer_${param.downloadType}.xlsx`;
        document.body.appendChild(a);
        a.click();
        setTimeout((_: any) => {
          window.URL.revokeObjectURL(url);
        }, 600000);
        a.remove();
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error('err: ', err);
      });

  const downloadQuestionAnswerData = (newType: string) => {
    const newData = {
      downloadType: newType,
      qnaId: qnaId,
      type: '',
      searchKey: 'questionAnswer',
      searchWord: resultFilter,
      startDate: 0,
      endDate: 0,
    };
    setLoading(true);
    downloadQuestionAnswerLocal(newData);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const searchQuestionAnswer = () => {
    if (page === 1) {
      questionAnswerResultdRefetch();
    } else {
      setPage(1);
    }
  };

  const downloadAttendUser = () => {
    const newData = {
      qnaId: qnaId,
    };
    downloadQuestionAnswerAttendUser(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchQuestionAnswer();
    }
  };

  const checkAttendUser = () => {
    setOpenAttendUser(true);
  };

  const viewAnswer = (idx: number) => {
    const newUserData = {
      ...resultInfo.result[idx],
      type: type, // 설문(1), 퀴즈(2)
      count: quizReport.count, // 퀴즈 갯수
    };
    setUserData(newUserData);
    setOpenUserAnswer(true);
  };

  useEffect(() => {
    if (qnaId.length > 0) {
      resultPrefetch(1);
    }
  }, [rowsPerPage]);

  useEffect(() => {
    // const newRequestList = processResult(resultInfo);
    // const newResult = newRequestList.filter((item: any) => {
    //   if (
    //     item[0].includes(resultFilter) ||
    //     item[1].includes(resultFilter) ||
    //     item[2].includes(resultFilter)
    //   )
    //     return true;
    //   return false;
    // });
    // setRequestList(newResult);
    setSearchWord(resultFilter);
  }, [resultFilter]);

  useEffect(() => {
    if (qnaId.length > 0) {
      questionAnswerResultdRefetch();
    }
  }, [page]);

  useEffect(() => {
    if (qnaId.length > 0) {
      questionAnswerResultdRefetch();
    }
  }, [qnaId]);

  useEffect(() => {
    console.log(
      'QuestionAnswerResult questionAnswerInfo :',
      questionAnswerInfo
    );
    if (questionAnswerInfo) {
      setPage(1);
      setResultFilter('');
      setQnaId(questionAnswerInfo.qnaId);
      setType(questionAnswerInfo.type);
      setHasReward(questionAnswerInfo.hasReward);
    }
  }, [questionAnswerInfo]);

  return (
    <>
      <Paper sx={{ p: '1.5rem' }}>
        <Typography fontSize='1rem' fontWeight={500}>
          질의응답 결과
        </Typography>
        <Box className='flex_start' sx={{ p: '1rem 0' }}>
          <TextField
            sx={{ width: '24rem' }}
            placeholder='참여자 닉네임, 아이디, 연락처로 검색해 주세요'
            inputRef={resultInputRef}
            value={resultFilter}
            onChange={evt => setResultFilter(evt.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ pr: 0 }} onClick={searchQuestionAnswer}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box className='flex_start'>
          <Box sx={{ p: '1rem 0', width: type === 1 ? '50%' : '50%' }}>
            <TitleTypography>답변 정보</TitleTypography>
            <Grid
              container
              sx={{
                borderTop: '1px solid #8899B6',
                placeItems: 'center',
                width: '100%',
              }}
            >
              <GridTitle item xs={12}>
                <Item>답변 인원수</Item>
              </GridTitle>
              <GridCell item xs={12}>
                <Item>{`${totalRecords}명`}</Item>
              </GridCell>
            </Grid>
          </Box>
          {type === 2 && (
            <Box sx={{ p: '1rem 0', width: '50%', ml: '2rem' }}>
              <TitleTypography>퀴즈 정보</TitleTypography>
              <Grid
                container
                sx={{
                  borderTop: '1px solid #8899B6',
                  width: '100%',
                }}
              >
                <GridTitle item xs={3}>
                  <Item>평균</Item>
                </GridTitle>
                <GridTitle item xs={3}>
                  <Item>중앙값</Item>
                </GridTitle>
                <GridTitle item xs={3}>
                  <Item>최고 점수</Item>
                </GridTitle>
                <GridTitle item xs={3}>
                  <Item>최저 점수</Item>
                </GridTitle>
                <GridCell item xs={3}>
                  <Item>
                    {quizReport !== undefined ? quizReport.mean : '-'}
                  </Item>
                </GridCell>
                <GridCell item xs={3}>
                  <Item>
                    {quizReport !== undefined ? quizReport.center : '-'}
                  </Item>
                </GridCell>
                <GridCell item xs={3}>
                  <Item>{quizReport !== undefined ? quizReport.max : '-'}</Item>
                </GridCell>
                <GridCell item xs={3}>
                  <Item>{quizReport !== undefined ? quizReport.min : '-'}</Item>
                </GridCell>
              </Grid>
            </Box>
          )}
        </Box>
        <Box className='flex_end' sx={{ p: '1rem 0' }}>
          <Box className='flex_end'>
            <Button
              sx={{ mr: '1rem' }}
              color='info'
              variant='outlined'
              onClick={() => {
                downloadQuestionAnswerData('attend-user-result');
              }}
            >
              참여자 목록 다운로드
              <FileDownloadRoundedIcon color='success' />
            </Button>
            <Button
              sx={{ mr: '1rem' }}
              color='info'
              variant='outlined'
              onClick={() => {
                downloadQuestionAnswerData('answer-result');
              }}
            >
              답변 목록 다운로드
              <FileDownloadRoundedIcon color='success' />
            </Button>
            {/* <Button
              color='info'
              variant='outlined'
              onClick={() => checkAttendUser()}
            >
              참여자 추가
            </Button> */}
            <TextField
              select
              value={rowsPerPage}
              defaultValue={30}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
              }}
              sx={{
                ml: '1rem',
                mb: 0,
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                  height: '2rem',
                },
              }}
            >
              <MenuItem value={30}>30개씩 보기</MenuItem>
              <MenuItem value={50}>50개씩 보기</MenuItem>
              <MenuItem value={100}>100개씩 보기</MenuItem>
            </TextField>
          </Box>
        </Box>
        <Paper sx={{ p: '1rem' }}>
          <TableTitle title='총 참여자' count={totalRecords} />
          <TableContainer sx={{ height: '40vh', overflowY: 'scroll' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns
                    .filter(
                      (column: any, idx: number) =>
                        hasReward || (!hasReward && idx < 7)
                    )
                    .map((column: any, idx: number) =>
                      type === 1 && idx !== 6 ? (
                        <TableCell
                          key={`column_title_${idx}`}
                          sx={{ p: '.4rem .7rem' }}
                        >
                          {column}
                        </TableCell>
                      ) : type === 2 ? (
                        <TableCell
                          key={`column_title_${idx}`}
                          sx={{ p: '.4rem .7rem' }}
                        >
                          {column}
                        </TableCell>
                      ) : (
                        <></>
                      )
                    )}
                  <TableCell key='column_title_99' sx={{ p: '.4rem .7rem' }}>
                    답변 보기
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {requestList.length > 0 &&
                  requestList.map((row: any, idx: number) => (
                    <TableRow key={`row_${idx}`}>
                      {columns
                        .filter(
                          (column: any, i: number) =>
                            hasReward || (!hasReward && i < 7)
                        )
                        .map((column: any, i: number) =>
                          type === 1 && i !== 6 ? (
                            <TableCell
                              key={`column_${i}`}
                              sx={{ p: '.4rem .7rem' }}
                              className={
                                row.deleted === 1
                                  ? clsx('not_important_row')
                                  : ''
                              }
                            >
                              {row[i]}
                            </TableCell>
                          ) : type === 2 ? (
                            <TableCell
                              key={`column_${i}`}
                              sx={{ p: '.4rem .7rem' }}
                              className={
                                row.deleted === 1
                                  ? clsx('not_important_row')
                                  : ''
                              }
                            >
                              {row[i]}
                            </TableCell>
                          ) : (
                            <></>
                          )
                        )}
                      <TableCell key='column_99' sx={{ p: '.7rem' }}>
                        <Button
                          id={row[1]}
                          sx={{ ml: '.5rem' }}
                          size='small'
                          variant='outlined'
                          color='primary'
                          onClick={(evt: any) => {
                            viewAnswer(idx);
                          }}
                        >
                          답변보기
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </Paper>
      </Paper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwQuestionAnswerLoadAttendUser
        qnaId={qnaId}
        open={openAttendUser}
        onclose={() => {
          setOpenAttendUser(false);
          questionAnswerResultdRefetch();
          reloadQuestionAnswer(qnaId);
        }}
      />
      <SwQuestionAnswerViewAnswer
        qnaId={qnaId}
        userData={userData}
        open={openUserAnswer}
        onclose={() => {
          setOpenUserAnswer(false);
        }}
      />
      {loading && <SwLoading />}
    </>
  );
};
