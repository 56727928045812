import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import ClearIcon from '@mui/icons-material/Clear';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useState,
  Dispatch,
  useRef,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_OPTION_COUNT,
  MAX_UPLOAD_FILE_SIZE,
  getQnaSurveyType,
  getQnaSurveyTypeNo,
  pointPerAchieve,
  qnaSurveyTypeList,
  unitSelectorList,
} from './SwProgramCommon';
import { SwDatesPicker } from '../dateSetting/SwDatesPicker';
import { SwFileSetting } from '../SwFileSetting';
import { useSession } from '../../../hooks/session';
import { ButtonBox } from '../../challenge/challengeManagement/ChallengeStyles';
import { SwTimePicker } from '../dateSetting/SwTimePicker';
import {
  dateFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  cloneProgramMissionQnaQuestion,
  deleteProgramMissionQnaQuestion,
  postProgramMissionQnaQuestion,
  putProgramMissionQnaQuestionIsRequired,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { newUuidId } from '../SwUuidCreate';
import { getDurationType, getFileSize } from '../../../common/helperProgram';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import { DraggablePaper } from '../../styles/Styles';

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

const QnaBox = styled(Box)({
  margin: '1rem 1rem 1rem 3rem',
});

const INIT_UNIT_SELECTOR = '선택';

interface Props {
  survey: any;
}

export const SwSurveyAnswerForm: React.FC<Props> = ({ survey }) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [preEditMode, setPreEditMode] = useState<string>('view');
  const [qnaType, setQnaType] = useState<string>('choice');
  const [missionId, setMissionId] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [seq, setSeq] = useState<number>(0);
  const [answer, setAnswer] = useState<string>('');
  const [inputType, setInputType] = useState<string>('direct');
  const [link, setLink] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [coverFile, setCoverFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [requiredAnswer, setRequiredAnswer] = useState<boolean>(true);
  const [multipleAnswer, setMultipleAnswer] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([
    { type: 'normal', title: '' },
    { type: 'normal', title: '' },
    { type: 'normal', title: '' },
  ]);
  const [image, setImage] = useState<string>('');
  const [uploadQuestionFile, setUploadQuestionFile] = useState<any>(null);
  const [uploadQuestionFileName, setUploadQuestionFileName] = useState<any>('');
  const [multipleAnswerCount, setMultipleAnswerCount] = useState<number>(0);
  const [multipleAnswerCountList, setMultipleAnswerCountList] = useState<any[]>(
    [
      { title: '지정 안함', value: 0 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
    ]
  );
  const [hideEtcButton, setHideEtcButton] = useState<boolean>(false);
  const [showUnitSelector, setShowUnitSelector] = useState<boolean>(false);
  const [selectUnit, setSelectUnit] = useState<any>(INIT_UNIT_SELECTOR);
  const [directInputUnit, setDirectInputUnit] = useState<any>('');
  const [selectScale, setSelectScale] = useState<number>(1);
  const [startScale, setStartScale] = useState<number>(1);
  const [endScale, setEndScale] = useState<number>(2);
  const [scaleStartInput, setScaleStartInput] = useState<string>('');
  const [scaleEndInput, setScaleEndInput] = useState<string>('');
  const [startDates, setStartDates] = useState<any[]>([]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [durationYear, setDurationYear] = useState<string>('선택');
  const [durationMonth, setDurationMonth] = useState<string>('선택');
  const [durationDay, setDurationDay] = useState<string>('선택');
  const [selectTime, setSelectTime] = useState<any>(null);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const makeMultipleAnswer = (newOption: any) => {
    let newCountList: any[];
    if (newOption < 2) {
      newCountList = [{ title: '지정 안함', value: 0 }];
      setMultipleAnswerCountList(newCountList);
    } else {
      newCountList = [{ title: '지정 안함', value: 0 }];
      for (let i = 2; i <= newOption.length; i += 1) {
        newCountList.push({ title: i.toString(), value: i });
      }
      setMultipleAnswerCountList(newCountList);
    }
  };

  const checkAnswer = (newSurvey: any) => {
    const newQnaType = getQnaSurveyType(newSurvey.qnaType);
    if (
      newQnaType === 'choice' ||
      newQnaType === 'short' ||
      newQnaType === 'long'
    ) {
      setAnswer(newSurvey.answerType2);
    } else if (newQnaType === 'scale') {
      const newScale = JSON.parse(newSurvey.answerType1);
      setSelectScale(newScale[0]);
    } else if (newQnaType === 'date' || newQnaType === 'datepoint') {
      const newDate = JSON.parse(newSurvey.answerType3);
      setAnswer(dateFormat(new Date(newDate.time * 1000), newQnaType));
    } else if (newQnaType === 'point') {
      // {\"hour\":2,\"minutes\":24,\"amOrPm\":1}"
      const newDate = JSON.parse(newSurvey.answerType3);
      const newHour =
        newDate.amOrPm === 1
          ? newDate.hour
          : newDate.hour === 0
          ? 12
          : newDate.hour;
      setAnswer(
        `${newDate.amOrPm === 1 ? '오전' : '오후'} ${newHour}시 ${
          newDate.minutes
        }분`
      );
    } else if (newQnaType === 'time') {
      // {\"hour\":2,\"minutes\":24}"
      const newDate = JSON.parse(newSurvey.answerType3);
      setAnswer(`${newDate.hour}시간 ${newDate.minutes}분`);
    } else if (newQnaType === 'duration') {
      const newDuration = JSON.parse(newSurvey.answerType3);
      // {\"month\":2,\"year\":1,\"type\":4}"
      // 기간타입(type:7)의 내부타입(1-년 2-월 3-일 4-년월 5-년일 6-월일 7-년월일)
      if (newDuration.type === 1) {
        setDurationYear(newDuration.year);
      } else if (newDuration.type === 2) {
        setDurationMonth(newDuration.month);
      } else if (newDuration.type === 3) {
        setDurationDay(newDuration.day);
      } else if (newDuration.type === 4) {
        setDurationYear(newDuration.year);
        setDurationMonth(newDuration.month);
      } else if (newDuration.type === 5) {
        setDurationYear(newDuration[0]);
        setDurationDay(newDuration.day);
      } else if (newDuration.type === 6) {
        setDurationMonth(newDuration.month);
        setDurationDay(newDuration.day);
      } else if (newDuration.type === 7) {
        setDurationYear(newDuration[0]);
        setDurationMonth(newDuration.month);
        setDurationDay(newDuration.day);
      }
    }
  };

  useEffect(() => {
    if (multipleAnswerCountList !== null && multipleAnswerCountList.length > 0)
      setMultipleAnswerCount(
        survey.responsesCount !== undefined
          ? survey.responsesCount
          : multipleAnswerCountList[0].value
      );
  }, [multipleAnswerCountList]);

  useEffect(() => {
    if (survey !== undefined && survey !== null) {
      console.log('survey:', survey);
      const newQnaType = getQnaSurveyType(survey.qnaType);
      setQnaType(newQnaType);
      setQuestion(survey.title);
      const optionData =
        survey.optionList !== undefined && survey.optionList !== null
          ? JSON.parse(survey.optionList)
          : [];
      const newOptions = optionData.map((item: any, idx: number) => {
        let correctAnswer = 0;
        if (
          newQnaType === 'choice' &&
          survey.answerType1.includes(item.optionId)
        ) {
          if (item.isCorrect === 1) {
            correctAnswer = 1; // correct
          } else correctAnswer = 2; // incorrect
        }
        const newData = {
          type: item.isCustomAnswer === 0 ? 'normal' : 'etc',
          title: item.body,
          isCorrect: item.isCorrect,
          isCustomAnswer: item.isCustomAnswer,
          correctAnswer: correctAnswer,
        };
        return newData;
      });
      setOptions(newOptions);
      makeMultipleAnswer(newOptions);
      setMultipleAnswer(survey.allowMultipleResponses === 1);
      setMultipleAnswerCount(
        newOptions !== undefined && newOptions !== null
          ? survey.responsesCount
          : 0
      );
      setUploadQuestionFileName(survey.image);
      setSeq(survey.seq);
      setShowUnitSelector(survey.isUnitExist === 1);
      const newSelectUnit = unitSelectorList.filter(
        unit => unit.title === survey.answerUnit
      );
      setSelectUnit(
        survey.isUnitExist === undefined ||
          survey.isUnitExist === null ||
          survey.isUnitExist === 0
          ? '선택'
          : newSelectUnit !== null && newSelectUnit.length > 0
          ? newSelectUnit[0].type
          : '직접입력'
      );
      setDirectInputUnit(
        newSelectUnit === null || newSelectUnit.length === 0
          ? survey.answerUnit
          : ''
      );
      setRequiredAnswer(survey.isRequired === 1);
      setMissionId(survey.missionId);

      const newMeasureInfo =
        survey.measureInfo !== undefined &&
        survey.measureInfo !== null &&
        survey.measureInfo.length > 0
          ? JSON.parse(survey.measureInfo)
          : null;

      setStartScale(
        newMeasureInfo === null
          ? 1
          : newMeasureInfo.measure_min !== undefined
          ? newMeasureInfo.measure_min
          : newMeasureInfo.measureMin !== undefined
          ? newMeasureInfo.measureMin
          : 1
      );
      setEndScale(
        newMeasureInfo === null
          ? 2
          : newMeasureInfo.measure_max !== undefined
          ? newMeasureInfo.measure_max
          : newMeasureInfo.measureMax !== undefined
          ? newMeasureInfo.measureMax
          : 2
      );
      setScaleStartInput(
        newMeasureInfo === null
          ? ''
          : newMeasureInfo.measure_min_text !== undefined
          ? newMeasureInfo.measure_min_text
          : newMeasureInfo.measureMinText !== undefined
          ? newMeasureInfo.measureMinText
          : ''
      );
      setScaleEndInput(
        newMeasureInfo === null
          ? ''
          : newMeasureInfo.measure_max_text !== undefined
          ? newMeasureInfo.measure_max_text
          : newMeasureInfo.measureMaxText !== undefined
          ? newMeasureInfo.measureMaxText
          : ''
      );

      if (
        survey.durationType === 1 ||
        survey.durationType === 4 ||
        survey.durationType === 5 ||
        survey.durationType === 7
      )
        setDurationYear('년');
      if (
        survey.durationType === 2 ||
        survey.durationType === 4 ||
        survey.durationType === 6 ||
        survey.durationType === 7
      )
        setDurationMonth('개월');
      if (
        survey.durationType === 3 ||
        survey.durationType === 5 ||
        survey.durationType === 6 ||
        survey.durationType === 7
      )
        setDurationDay('일');
    }

    // check answer
    checkAnswer(survey);
  }, [survey]);

  return (
    <Paper
      sx={{
        mb: '1rem',
        background: '#FFFFFF',
      }}
    >
      <Box className='flex_between'>
        <Box className='flex_start' sx={{ ml: '1rem', mt: '1rem' }}>
          <Typography className='title' sx={{ ml: '1rem' }}>
            {`질문 ${seq + 1} `}
            {requiredAnswer && <span style={{ color: '#F63454' }}>*</span>}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ m: '0rem' }} />
      <>
        <QnaBox className='flex_between'>
          <Typography>{question}</Typography>
        </QnaBox>
        {uploadQuestionFileName !== undefined &&
        uploadQuestionFileName !== null &&
        uploadQuestionFileName !== '' ? (
          <QnaBox className='flex_start'>
            <Box sx={{ m: '1rem 0' }}>
              <img
                src={`${imageUrlPrefix}${uploadQuestionFileName}`}
                alt={`${imageUrlPrefix}${uploadQuestionFileName}`}
                style={{
                  maxWidth: '10vw',
                  borderRadius: '14px',
                  width: '10rem',
                  height: 'auto',
                }}
              />
            </Box>
          </QnaBox>
        ) : (
          <></>
        )}
        <Divider />
      </>
      {qnaType === 'choice' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            {options.map((option: any, idx: number) => (
              <Box
                key={`choice_view_${idx}`}
                sx={{
                  mb: '1rem',
                }}
                className='flex_start'
              >
                <RadioButtonUncheckedIcon
                  sx={{
                    color:
                      option.correctAnswer === 1 || option.correctAnswer === 2
                        ? 'rgba(0, 162, 255,1)'
                        : '#D4D9E1',
                  }}
                />
                {option.type === 'normal' ? (
                  <Typography sx={{ ml: '1rem' }}>{option.title}</Typography>
                ) : option.isCustomAnswer === 1 ? (
                  <>
                    <Typography sx={{ ml: '1rem', width: '5rem' }}>
                      기타
                    </Typography>
                    <TextField
                      sx={{ width: '10rem', mr: '1rem' }}
                      value={answer}
                    />
                  </>
                ) : (
                  <TextField sx={{ ml: '1rem' }} value='기타' />
                )}
              </Box>
            ))}
          </QnaBox>
          <Divider />
        </>
      ) : qnaType === 'short' ? (
        <>
          <QnaBox
            className='flex_between'
            sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}
          >
            <Box sx={{ width: '50%' }} className='flex_start'>
              <TextField
                sx={{ width: '100%' }}
                placeholder='답변을 입력해주세요.'
                value={answer}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      {showUnitSelector && selectUnit === '직접입력' ? (
                        <Typography>{directInputUnit}</Typography>
                      ) : (
                        <Typography>{selectUnit}</Typography>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </QnaBox>
        </>
      ) : qnaType === 'long' ? (
        <>
          <QnaBox className='flex_between' sx={{ mt: '2rem' }}>
            <Box sx={{ width: '90%', mb: '2rem' }}>
              <TextField
                fullWidth
                multiline
                rows={5}
                placeholder='답변을 입력해주세요.'
                value={answer}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                    padding: '.5rem',
                  },
                }}
              />
            </Box>
          </QnaBox>
        </>
      ) : qnaType === 'scale' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <Box className='flex_start'>
              <Box>
                <Typography sx={{ ml: '1rem', width: '7rem' }}>
                  {scaleStartInput}
                </Typography>
              </Box>
              {Array.from(
                { length: endScale - startScale + 1 },
                (_, index) => startScale + index
              ).map((item: any, idx: number) => (
                <FormControlLabel
                  key={`scale_view_${idx}`}
                  value={item}
                  control={<Radio checked={selectScale === idx + 1} />}
                  label={item}
                  labelPlacement='top'
                />
              ))}
              <Box>
                <Typography sx={{ ml: '1rem', width: '7rem' }}>
                  {scaleEndInput}
                </Typography>
              </Box>
            </Box>
          </QnaBox>
        </>
      ) : qnaType === 'date' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <TextField
              sx={{ width: '18rem', mb: '2rem' }}
              disabled
              value={answer}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccessTimeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'datepoint' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value={answer}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'point' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value={answer}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'duration' ? (
        <>
          <QnaBox sx={{ padding: '0 0 1rem 0rem' }}>
            {durationYear !== '선택' && (
              <TextField
                sx={{ width: '10rem', mr: '1rem' }}
                value={`${durationYear} 년`}
              />
            )}
            {durationMonth !== '선택' && (
              <TextField
                sx={{ width: '10rem', mr: '1rem' }}
                value={`${durationMonth} 개월`}
              />
            )}
            {durationDay !== '선택' && (
              <TextField
                sx={{ width: '10rem', mr: '1rem' }}
                value={`${durationDay} 일`}
              />
            )}
          </QnaBox>
        </>
      ) : qnaType === 'time' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value={answer}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'time' ? (
        <>
          <QnaBox sx={{ mt: '2rem', height: '70px' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value={answer}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : (
        <></>
      )}
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </Paper>
  );
};
