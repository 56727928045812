import {
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { v4 as uuidv4 } from 'uuid';
import React, {
  ChangeEvent,
  Dispatch,
  DragEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { DraggablePaper } from '../../styles/Styles';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { useSession, xapi } from '../../../hooks/session';
import { IUploadFile } from '../../../models/common';
import { checkUrl, getAddUrl, timestamp2string } from '../../../common/helper';
import {
  postChallengeLabel,
  putChallengeLabel,
} from '../../../common/api/ApiChallenge';

interface Props {
  title: string;
  open: boolean;
  labels: any;
  labelId: number;
  setLabels: Dispatch<any>;
  onClose: () => void;
  onConfirm: () => void;
}

export const ChallengeLabelingSetting: React.FC<Props> = ({
  open,
  labels,
  labelId,
  setLabels,
  onClose,
  onConfirm,
  title,
}) => {
  const { loginVal } = useSession();
  const linkRef = useRef<any>();
  const linkSelectRef = useRef<any>();
  const [tmpLabels, setTmpLabels] = useState<any>([]);
  const [labelImage, setLabelImage] = useState<string>('');
  const [labelImageFile, setLabelImageFile] = useState<any>(null);
  const [labelRangeDate, setLabelRangeDate] = useState<any[]>([null, null]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [actionType, setActionType] = useState<string>('url');
  const [actionLink, setActionLink] = useState<string>('');
  const [holderMsg, setHolderMsg] = useState<string>('URL입력');
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');

  const { mutate: postChallengeLabelMutate, isError: isMutateLabelError } =
    useMutation(postChallengeLabel, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnacker(true);
          setDialogMessage('라벨링 이미지를 추가했습니다.');
          onConfirm();
        } else {
          setOpenSnacker(true);
          setDialogMessage(
            `라벨링 이미지를 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnacker(true);
        setDialogMessage(
          `라벨링 이미지 추가가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
        );
      },
    });

  const { mutate: putChallengeLabelMutate, isError: isMutateError } =
    useMutation(putChallengeLabel, {
      onSuccess: () => {
        onConfirm();
      },
      onError: error => {
        setOpenSnacker(true);
        setDialogMessage(
          '라벨링 이미지 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const addLabel = (newUploadUrl: string) => {
    const newData = {
      labelId: labelId,
      image: newUploadUrl,
    };
    console.log('addLabel :', newData);
    if (labelId === 0) {
      postChallengeLabelMutate(newData);
    } else {
      putChallengeLabelMutate(newData);
    }
  };

  const uploadLabelImage = () => {
    const uploadfile: IUploadFile = {
      type: 'label',
      file: labelImageFile,
    };
    var formData = new FormData();
    formData.append('file', labelImageFile);

    xapi
      .post(`/admin/v2/apis/upload/${uploadfile.type}`, formData)
      .then(res => {
        if (res.status !== 200) throw new Error('http 에러');
        console.log('UploadUrl :', res.data);

        addLabel(res.data.uploadUrl.file.path);

        return res.data;
      });
  };

  const onAction = () => {
    var val = 0;
    if (labelImageFile === null && labelImage.length === 0) val = 1;

    if (val === 0) {
      if (labelImageFile !== null) {
        uploadLabelImage();
      } else {
        addLabel(labelImage);
      }
    } else {
      const msg = val === 1 ? '이미지를 선택해주세요.' : '';
      setOpenDialog(true);
      setDialogMessage(msg);
    }
  };

  useEffect(() => {
    if (open) {
      setTmpLabels(labels);
      setLabelImageFile(null);
    }
    setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);
    if (labelId !== 0) {
      const newLabel = labels.filter((label: any) => label.labelId === labelId);
      setLabelImage(newLabel[0].image);
    } else {
      setLabelImage('');
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minWidth: '45vw',
            minHeight: '55vh',
            borderRadius: '12px',
          },
        }}
      >
        <DialogTitle className='flex_between'>
          <Box>{title}</Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          <Box>
            <Typography className='title'>이미지</Typography>
            <SwFileSetting
              image={
                labelImageFile !== null
                  ? labelImage
                  : labelImage !== ''
                  ? `${imageUrlPrefix}${labelImage}`
                  : ''
              }
              setImage={setLabelImage}
              file={labelImageFile}
              setFile={setLabelImageFile}
              width='22vh'
              height='22vh'
            />
            <Divider />
          </Box>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          <Box sx={{ textAlign: 'right' }}>
            <Button variant='outlined' color='info' onClick={onClose}>
              취소
            </Button>
            <Button
              variant='contained'
              color='info'
              sx={{ ml: '1rem' }}
              onClick={onAction}
            >
              라벨링 요청
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
