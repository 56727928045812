import { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper, Slide, styled, Typography } from '@mui/material';
import { SwTable2 } from '../../commonComponent/table/SwTable2';
import { SwNaverMap } from '../../commonComponent/map/SwNaverMap';
import {
  timestamp2string,
  getYearList,
  getMonthList,
  commaFormat,
  timestamp2Localestring,
  getCurrentDate,
  removeNatCodePhoneNumber,
} from '../../../common/helper';

interface Props {
  type: any;
  programInfo: any;
}

const TitleTypography = styled(Typography)({
  padding: '.5rem .5rem .5rem .15rem',
  marginBottom: '.33rem',
  fontWeight: 500,
  fontSize: '1rem',
});

const GridRow = styled(Grid)({
  border: '1px solid #E7EDF6',
  backgroundColor: '#F9FAFC',
  borderLeft: 'none',
  padding: '.7em',
});
const GridCell = styled(Grid)({
  border: '1px solid #E7EDF6',
  borderLeft: 'none',
  padding: '.7em',
});

export const UserProgramDetailReport: React.FC<Props> = ({
  type,
  programInfo,
}) => {
  const a = 1;
  const [filteredPoint, setFilteredPoint] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<any>({
    name: '',
    phoeno: '',
    currentScore: 0,
    goalPoint: 0,
    totalPoint: 0,
  });
  const [rewardInfo, setRewardInfo] = useState<any>([]);

  useEffect(() => {
    if (programInfo !== undefined && programInfo !== null) {
      setUserInfo(programInfo.userInfo);
      setRewardInfo(programInfo.reward);
      let pointSum = 0;
      const newData = programInfo.point.map((point: any, idx: number) => {
        if (idx === 0) {
          pointSum = point.score;
        } else {
          pointSum += point.score;
        }
        return {
          ...point,
          pointSum: pointSum,
        };
      });
      setFilteredPoint(newData);
    }
  }, [programInfo]);

  return (
    <>
      <Box>
        <TitleTypography>사용자 정보</TitleTypography>
        <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
          <GridRow item xs={3}>
            닉네임
          </GridRow>
          <GridCell item xs={9}>
            {userInfo.name}
          </GridCell>
          <GridRow item xs={3}>
            휴대폰 번호
          </GridRow>
          <GridCell item xs={9}>
            {removeNatCodePhoneNumber(userInfo?.phoneno)}
          </GridCell>
          {type === 1 ? (
            <>
              <GridRow item xs={3}>
                실시간 건강 점수
              </GridRow>
              <GridCell item xs={9}>
                {`${commaFormat(String(userInfo.currentScore))} /
                  ${commaFormat(String(userInfo.goalPoint))}`}
              </GridCell>
              <GridRow item xs={3}>
                총 건강 점수
              </GridRow>
              <GridCell item xs={9}>
                {commaFormat(String(userInfo.totalPoint))}
              </GridCell>
            </>
          ) : (
            <></>
          )}
        </Grid>
      </Box>
      <Box sx={{ mt: '2em' }}>
        <TitleTypography>건강 점수 내역</TitleTypography>
        <Grid
          container
          sx={{ borderTop: '1px solid #8899B6', textAlign: 'center' }}
        >
          {type === 1 ? (
            <>
              <GridRow item xs={3}>
                날짜
              </GridRow>
              <GridRow item xs={4}>
                미션 제목
              </GridRow>
              <GridRow item xs={2}>
                건강 점수
              </GridRow>
              <GridRow item xs={3}>
                건강 점수 내역
              </GridRow>
              {filteredPoint !== null && filteredPoint.length > 0 ? (
                filteredPoint?.map((point: any, idx: number) => (
                  <Fragment key={idx.toString()}>
                    <GridCell item xs={3}>
                      {point?.attendDate !== null
                        ? timestamp2Localestring(point?.attendDate, 1000)
                        : '-'}
                    </GridCell>
                    <GridCell item xs={4}>
                      {point?.missionTitle}
                    </GridCell>
                    <GridCell item xs={2}>
                      {commaFormat(String(point?.score))}
                    </GridCell>
                    <GridCell item xs={3}>
                      {commaFormat(String(point?.pointSum))}
                    </GridCell>
                  </Fragment>
                ))
              ) : (
                <>
                  <TitleTypography sx={{ m: '.5rem' }}>
                    건강 점수 내역이 없습니다.
                  </TitleTypography>
                </>
              )}
            </>
          ) : (
            <>
              <TitleTypography sx={{ m: '.5rem' }}>
                건강 점수 내역이 없습니다.
              </TitleTypography>
            </>
          )}
        </Grid>
      </Box>
      <Box sx={{ mt: '2em' }}>
        {type === 1 && (
          <TitleTypography>
            리워드 발급 정보(
            {rewardInfo ? rewardInfo?.length : 0}
            건)
          </TitleTypography>
        )}
        {rewardInfo !== null &&
          rewardInfo.map((item: any, idx: number) => (
            <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
              <GridRow item xs={4}>
                쿠폰명
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardTitle}
              </GridCell>
              <GridRow item xs={4}>
                쿠폰 지급 형태
              </GridRow>
              <GridCell item xs={8}>
                {
                  programInfo.rewardGivetype[item.rewardGiveType]
                    .challengeRewardGivetypeDescription
                }
              </GridCell>
              {item.rewardGiveType === 2 ? (
                <>
                  <GridRow item xs={4}>
                    쿠폰 응모 일시
                  </GridRow>
                  <GridCell item xs={8}>
                    {item.rewardDrawTime !== null
                      ? timestamp2Localestring(item.rewardDrawTime)
                      : '-'}
                  </GridCell>
                  <GridRow item xs={4}>
                    쿠폰 발급 일시
                  </GridRow>
                  <GridCell item xs={8}>
                    {item.rewardCreateTime !== null
                      ? timestamp2Localestring(item.rewardCreateTime)
                      : '-'}
                  </GridCell>
                </>
              ) : (
                <>
                  <GridRow item xs={4}>
                    쿠폰 발급/응모 일시
                  </GridRow>
                  <GridCell item xs={8}>
                    {item.rewardCreateTime !== null
                      ? timestamp2Localestring(item.rewardCreateTime)
                      : '-'}
                  </GridCell>
                </>
              )}
              <GridRow item xs={4}>
                쿠폰 사용 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardUsedTime !== null
                  ? timestamp2Localestring(item.rewardUsedTime)
                  : '-'}
              </GridCell>
              <GridRow item xs={4}>
                양식 입력 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.양식입력일시}
              </GridCell>
            </Grid>
          ))}
      </Box>
    </>
  );
};
