import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import readXlsxFile from 'read-excel-file';
import { CSVLink } from 'react-csv';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import { postRewardDrawCondition } from '../../../../common/api/ApiReward';
import {
  KEY_CHALLENGE_INFO,
  KEY_REWARD_DRAWING_RESULT,
} from '../../../../common/key';

import { RESULT_OK } from '../../../../common/resultCode';
import { PROGRAM_REWARD_STATE_DRAWING } from '../ProgramRewardDraw';
import { DrawUserTable } from './DrawUserTable';
import { postProgramRewardDrawCondition } from '../../../../common/api/ApiProgram';

const column = ['번호', '연락처', '삭제'];

interface Props {
  rewardDrawUserList: any;
  setRewardDrawUserList: Dispatch<React.SetStateAction<any>>;
  rewardConditionList: any;
  setRewardConditionList: Dispatch<React.SetStateAction<any>>;
  changeState: any;
  setChangeState: Dispatch<React.SetStateAction<any>>;
  onRefresh: () => void;
}

export const ProgramRewardDrawingSetting: React.FC<Props> = ({
  rewardDrawUserList,
  setRewardDrawUserList,
  rewardConditionList,
  setRewardConditionList,
  changeState,
  setChangeState,
  onRefresh,
}) => {
  const exceptInputRef = useRef<any>();
  const unexceptInputRef = useRef<any>();
  const otherUnexceptInputRef = useRef<any>();
  const otherExceptInputRef = useRef<any>();
  const unexceptRegistRef = useRef<any>();
  const exceptRegistRef = useRef<any>();
  const [creator, setCreator] = useState<string>('');
  const [unexceptUserList, setUnexceptUserList] = useState<any>([]);
  const [exceptUserList, setExceptUserList] = useState<any>([]);
  const [unexceptUserTempList, setUnexceptUserTempList] = useState<any>([]);
  const [exceptUserTempList, setExceptUserTempList] = useState<any>([]);
  const [otherUnexceptList, setOtherUnexceptList] = useState<any>([]);
  const [otherExceptList, setOtherExceptList] = useState<any>([]);
  const [otherUnexceptCount, setOtherUnexceptCount] = useState<number>(0);
  const [otherUnexceptCondition, setOtherUnexceptCondition] =
    useState<string>('');
  const [otherExceptCount, setOtherExceptCount] = useState<number>(0);
  const [otherExceptCondition, setOtherExceptCondition] = useState<string>('');
  const [checkRequest, setCheckRequest] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const MobileRegexp = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
  const [winnerCheck, setWinnerCheck] = useState<number>(-1);
  const [winnerInput, setWinnerInput] = useState<string>('');
  const [nonWinnerInput, setNonWinnerInput] = useState<string>('');
  const [uploadWinnerList, setUploadWinnerList] = useState<any>([]);
  const [uploadNonWinnerList, setUploadNonWinnerList] = useState<any>([]);
  const [checkWinnerTotalCount, setCheckWinnerTotalCount] = useState<number>(0);
  const [checkWinnerDuplicateCount, setCheckWinnerDuplicateCount] =
    useState<number>(0);
  const [checkNonWinnerTotalCount, setCheckNonWinnerTotalCount] =
    useState<number>(0);
  const [checkNonWinnerDuplicateCount, setCheckNonWinnerDuplicateCount] =
    useState<number>(0);

  const { mutate: postDrawConditionMutation, isError: isMutateError } =
    useMutation(postProgramRewardDrawCondition, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setChangeState(PROGRAM_REWARD_STATE_DRAWING);
          setOpenSnakerDialog(true);
          setDialogMessage('조건이 적용되었습니다.');
          onRefresh();
        } else {
          setOpenSnakerDialog(true);
          setDialogMessage(
            `조건을 적용하는동안 오류가 발생했습니다(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          `조건을 적용하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const addUser = (addType: number) => {
    const newPhoneno =
      addType === 0
        ? unexceptInputRef.current?.value
        : exceptInputRef.current?.value;

    if (newPhoneno.replaceAll('-', '').trim().length === 0) {
      setOpenSnakerDialog(true);
      setDialogMessage('참여자 연락처를 입력해주세요');
      return;
    }
    const unexceptDuplicated = unexceptUserTempList.filter(
      (item: any) => item.phoneno === newPhoneno
    );
    const exceptDuplicated = exceptUserTempList.filter(
      (item: any) => item.phoneno === newPhoneno
    );

    if (
      (unexceptDuplicated === null || unexceptDuplicated.length === 0) &&
      (exceptDuplicated === null || exceptDuplicated.length === 0)
    ) {
      if (addType === 0) {
        const newData = [...unexceptUserTempList, { phoneno: newPhoneno }];
        setUnexceptUserTempList(newData);
      } else {
        const newData = [...exceptUserTempList, { phoneno: newPhoneno }];
        setExceptUserTempList(newData);
      }
    } else {
      setOpenSnakerDialog(true);
      setDialogMessage('참여자가 중복되었습니다.');
    }
  };

  const uploadFile = (v: boolean) => {
    const inputMemberRegist: any = v
      ? document.getElementById('unexceptRegist')
      : document.getElementById('exceptRegist');
    console.log(inputMemberRegist && inputMemberRegist.files);
    if (inputMemberRegist && inputMemberRegist.files.length) {
      readXlsxFile(inputMemberRegist.files[0])
        .then(rows => {
          console.log(rows);
          const list = rows
            .map(row => row.toString())
            .map(r => (r[0] !== '0' ? `0${r}` : r));
          // list.shift(); // 첫번째 항목 삭제
          v
            ? setCheckWinnerTotalCount(list.length)
            : setCheckNonWinnerTotalCount(list.length);
          const reducer = (s: any[], val: string, idx: number) => {
            if (s.findIndex(q => q.value === val) === -1) {
              if (MobileRegexp.test(val)) {
                s.push(val);
              } else {
                // s.push({ value: val, check: 1 });
              }
            } else {
              v
                ? setCheckWinnerDuplicateCount(pre => pre + 1)
                : setCheckNonWinnerDuplicateCount(pre => pre + 1);
            }
            return s;
          };
          const sortList = list.reduce(reducer, []);
          if (sortList.filter(q => q.check === 1).length > 50) {
            setDialogMessage('파일에 너무 많은 오류 행이 있습니다.');
            setOpenSnakerDialog(true);
            v ? setCheckWinnerTotalCount(0) : setCheckNonWinnerTotalCount(0);
            return;
          }
          setWinnerCheck(Number(v));

          const newList = sortList
            .filter(
              (s: any) =>
                unexceptUserTempList.findIndex(
                  (item: any) => item.phoneno === s
                ) === -1 &&
                exceptUserTempList.findIndex(
                  (item: any) => item.phoneno === s
                ) === -1
            )
            .map((s: any) => {
              const newData = {
                phoneno: s,
              };
              return newData;
            });

          if (v) {
            setUnexceptUserTempList([...unexceptUserTempList, ...newList]);
            unexceptRegistRef.current.value = null;
          } else {
            setExceptUserTempList([...exceptUserTempList, ...newList]);
            exceptRegistRef.current.value = null;
          }

          setDialogMessage(`업로드된 번호 개수 : ${newList.length}`);
          setOpenSnakerDialog(true);
        })
        .catch(err => {
          console.log('err>>>>>>>>>', err);
        });
    }
  };

  const applyCondition = () => {
    const tmpUnexcept: any[] = rewardDrawUserList.all.filter((item: any) => {
      const found = unexceptUserTempList.filter(
        (_val: any) => _val.phoneno === item.phoneno
      );
      if (found === null || found.length === 0) return false;
      return true;
    });
    const tmpExcept: any[] = rewardDrawUserList.all.filter((item: any) => {
      const found = exceptUserTempList.filter(
        (_val: any) => _val.phoneno === item.phoneno
      );
      if (found === null || found.length === 0) return false;
      return true;
    });
    console.log('new tmpUnexcept :', tmpUnexcept);
    console.log('new tmpExcept :', tmpExcept);
    const newUnexcept: any[] = [
      // ...rewardDrawUserList.unexcept,
      ...tmpUnexcept,
    ];
    const newExcept: any[] = [
      // ...rewardDrawUserList.except,
      ...tmpExcept,
    ];
    const newUserList: any = {
      programId: rewardDrawUserList.programId,
      rewardId: rewardDrawUserList.rewardId,
      rewardCount: rewardDrawUserList.rewardCount,
      unexcept: newUnexcept,
      except: newExcept,
      all: rewardDrawUserList.all,
      winner: rewardDrawUserList.winner,
    };

    console.log('new rewardDrawUserList :', newUserList);
    setRewardConditionList(newUserList);

    const newUserIds = newUnexcept?.map((item: any) => item.userId);
    const newExcepUserIds = newExcept?.map((item: any) => item.userId);
    const newRewardId =
      newUnexcept.length > 0
        ? newUnexcept[0].rewardId
        : newExcept.length > 0
        ? newExcept[0].rewardId
        : rewardDrawUserList.rewardId;
    const newPrograms = otherUnexceptList.map((item: any) => item.programId);
    const newExceptPrograms = otherExceptList.map(
      (item: any) => item.programId
    );
    const newCondition = {
      programIds: newPrograms,
      condition: `${otherUnexceptCount}/${otherUnexceptCondition}`,
    };
    const newExceptCondition = {
      programIds: newExceptPrograms,
      condition: `${otherExceptCount}/${otherExceptCondition}`,
    };
    const newData: any = {
      rewardId: newRewardId,
      userIds: newUserIds,
      exceptUserIds: newExcepUserIds,
      condition: newCondition,
      exceptCondition: newExceptCondition,
    };

    if (
      newUnexcept.length === 0 &&
      newExcept.length === 0 &&
      newPrograms.length === 0 &&
      newExceptPrograms.length === 0
    ) {
      setOpenSnakerDialog(true);
      setDialogMessage('추가할 대상자가 없습니다.');
      setChangeState(PROGRAM_REWARD_STATE_DRAWING);
      return;
    }

    if (newRewardId !== '' && newRewardId.length > 0) {
      console.log('postDrawConditionMutation :', newData);
      postDrawConditionMutation(newData);
    } else {
      setOpenDialog(true);
      setDialogMessage('리워드 아이디를 찾을 수 없습니다.');
    }
  };

  const deleteOtherProgram = (type: number, index: number) => {
    if (type === 0) {
      const newSomething = otherUnexceptList.filter(
        (_val: any, i: number) => i !== index
      );
      setOtherUnexceptList(newSomething);
    } else if (type === 1) {
      const newSomething = otherExceptList.filter(
        (_val: any, i: number) => i !== index
      );
      setOtherExceptList(newSomething);
    }
  };

  const addOtherProgram = (type: number) => {
    const newProgramId =
      type === 0
        ? otherUnexceptInputRef.current?.value
        : otherExceptInputRef.current?.value;

    if (newProgramId.trim().length === 0) {
      setOpenSnakerDialog(true);
      setDialogMessage('챌린지 아이디를 입력해주세요');
      return;
    }
    const unexceptDuplicated = otherUnexceptList.filter(
      (item: any) => item.programId === newProgramId.trim()
    );
    const exceptDuplicated = otherExceptList.filter(
      (item: any) => item.programId === newProgramId.trim()
    );

    if (
      (unexceptDuplicated === null || unexceptDuplicated.length === 0) &&
      (exceptDuplicated === null || exceptDuplicated.length === 0)
    ) {
      type === 0 ? setCheckRequest(1) : type === 1 ? setCheckRequest(2) : '';
    } else {
      setOpenSnakerDialog(true);
      setDialogMessage('챌린지 아이디가 중복되었습니다.');
    }
  };

  const handleKeyDown = (e: any, value: number) => {
    if (e.key === 'Enter') {
      value === 0
        ? addUser(0)
        : value === 1
        ? addUser(1)
        : value === 2
        ? addOtherProgram(0)
        : value === 3
        ? addOtherProgram(1)
        : '';
    }
  };

  useEffect(() => {
    if (rewardDrawUserList) {
      console.log('rewardDrawUserList :', rewardDrawUserList);
      setCreator(rewardDrawUserList.creator ? rewardDrawUserList.creator : '');
    }
    // if (rewardDrawUserList) {
    //   console.log('rewardDrawUserList :', rewardDrawUserList);
    //   setCreator(rewardDrawUserList.creator ? rewardDrawUserList.creator : '');
    //   if (rewardDrawUserList?.all.length > 0) {
    //     setUnexceptUserTempList(
    //       rewardDrawUserList.unexcept?.map((item: any) => {
    //         const newItem = { name: item.name, phoneno: item.phoneno };
    //         return newItem;
    //       })
    //     );
    //     setExceptUserTempList(
    //       rewardDrawUserList.except?.map((item: any) => {
    //         const newItem = { name: item.name, phoneno: item.phoneno };
    //         return newItem;
    //       })
    //     );
    //   } else {
    //     setUnexceptUserTempList([]);
    //     setExceptUserTempList([]);
    //   }
    // } else {
    //   setUnexceptUserTempList([]);
    //   setExceptUserTempList([]);
    // }
    // setUnexceptUserTempList([]);
    // setExceptUserTempList([]);
  }, [rewardDrawUserList]);

  return (
    <>
      <Paper sx={{ m: '2rem 0', p: '2rem' }}>
        <Box sx={{ mb: '4rem' }}>
          <Typography variant='h6' sx={{ marginBottom: '1.5rem' }}>
            1. 당첨자 조건 추가하기
          </Typography>
          <Typography sx={{ fontWeight: 500 }}>100% 당첨자로</Typography>
          <Typography sx={{ color: '#9EA2A9', mb: '1.5rem' }}>
            추첨 시 당첨자를 미리 선택해 주세요.
          </Typography>
          <Box className='flex_between'>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <TextField
                placeholder='참여자 연락처를 입력해 주세요.'
                inputRef={unexceptInputRef}
                onKeyDown={evt => {
                  handleKeyDown(evt, 0);
                }}
                sx={{ width: '24rem', mr: '.5rem' }}
                type='number'
              />
              <Button variant='contained' onClick={() => addUser(0)}>
                추가
              </Button>
            </Box>
            <Box>
              <Button
                color='info'
                sx={{ mr: '.5rem' }}
                variant='outlined'
                onClick={() => {
                  const inputMemberRegist =
                    document.getElementById('unexceptRegist');
                  inputMemberRegist && inputMemberRegist.click();
                }}
              >
                엑셀 업로드
              </Button>
              <Button color='info' variant='outlined'>
                <CSVLink
                  filename='사전 당첨자 목록'
                  data={unexceptUserTempList.map((q: any, idx: number) => ({
                    ...q,
                    phoneNumber: `=""${q.phoneno}""`,
                    idx: idx + 1,
                  }))}
                  headers={[
                    {
                      label: '번호',
                      key: 'idx',
                    },
                    {
                      label: '연락처',
                      key: 'phoneNumber',
                    },
                  ]}
                >
                  데이터 다운로드
                </CSVLink>
                <FileDownloadRoundedIcon color='success' />
              </Button>
              <input
                ref={unexceptRegistRef}
                type='file'
                id='unexceptRegist'
                style={{ display: 'none' }}
                onChange={evt => uploadFile(true)}
              />
            </Box>
          </Box>
          <Paper sx={{ m: '1rem 0', p: '1rem' }}>
            <Typography sx={{ mb: '1.5rem' }}>
              100% 당첨자 연락처{' '}
              <span style={{ color: '#2581FF' }}>
                {unexceptUserTempList.length}
              </span>
              개
            </Typography>
            <DrawUserTable
              columns={column}
              rows={unexceptUserTempList}
              setRows={setUnexceptUserTempList}
            />
          </Paper>
        </Box>
        <Box sx={{ mb: '4rem' }}>
          <Typography sx={{ fontWeight: 500 }}>100% 미당첨자로</Typography>
          <Typography sx={{ color: '#9EA2A9', mb: '1.5rem' }}>
            추첨 대상자 목록에서 제외할 참여자를 선택해 주세요.
          </Typography>
          <Box className='flex_between'>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <TextField
                placeholder='사용자 연락처를 입력해 주세요.'
                inputRef={exceptInputRef}
                onKeyDown={evt => {
                  handleKeyDown(evt, 1);
                }}
                sx={{ width: '24rem', mr: '.5rem' }}
                type='number'
              />
              <Button variant='contained' onClick={() => addUser(1)}>
                추가
              </Button>
            </Box>
            <Box>
              <Button
                color='info'
                sx={{ mr: '.5rem' }}
                variant='outlined'
                onClick={() => {
                  const inputMemberRegist =
                    document.getElementById('exceptRegist');
                  inputMemberRegist && inputMemberRegist.click();
                }}
              >
                엑셀 업로드
              </Button>
              <Button color='info' variant='outlined'>
                <CSVLink
                  filename='사전 미당첨자 목록'
                  data={exceptUserTempList.map((q: any, idx: number) => ({
                    ...q,
                    phoneNumber: `=""${q.phoneno}""`,
                    idx: idx + 1,
                  }))}
                  headers={[
                    {
                      label: '번호',
                      key: 'idx',
                    },
                    {
                      label: '연락처',
                      key: 'phoneNumber',
                    },
                  ]}
                >
                  데이터 다운로드
                </CSVLink>
                <FileDownloadRoundedIcon color='success' />
              </Button>
              <input
                ref={exceptRegistRef}
                type='file'
                id='exceptRegist'
                style={{ display: 'none' }}
                onChange={evt => uploadFile(false)}
              />
            </Box>
          </Box>
          <Paper sx={{ m: '1rem 0', p: '1rem' }}>
            <Typography sx={{ mb: '1.5rem' }}>
              100% 미당첨자 연락처{' '}
              <span style={{ color: '#2581FF' }}>
                {exceptUserTempList.length}
              </span>
              개
            </Typography>
            <DrawUserTable
              columns={column}
              rows={exceptUserTempList}
              setRows={setExceptUserTempList}
            />
          </Paper>
        </Box>
        <Divider />
        <Box sx={{ mb: '4rem' }}>
          <Typography sx={{ fontWeight: 500 }}>
            타프로그램 쿠폰 발급자를 당첨자로
          </Typography>
          <Typography sx={{ color: '#9EA2A9', mb: '1.5rem' }}>
            타프로그램에서 쿠폰을 발급한 참여자를 당첨자로 넣습니다.
          </Typography>
          <Box className='flex_center'>
            <TextField
              placeholder='프로그램 아이디를 입력해 주세요.'
              sx={{ width: '24rem', mr: '.5rem' }}
              inputRef={otherUnexceptInputRef}
              onKeyDown={evt => {
                handleKeyDown(evt, 2);
              }}
            />
            <Button variant='contained' onClick={() => addOtherProgram(0)}>
              추가
            </Button>
          </Box>
          <Paper sx={{ m: '1rem 0', p: '1rem' }}>
            <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
              {otherUnexceptList &&
                otherUnexceptList.map((item: any, idx: number) => (
                  <Box className='flex_start'>
                    <Typography>
                      {`${item.qnaId} : ${item.qnaTitle}`}
                    </Typography>
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() => deleteOtherProgram(0, idx)}
                    />
                  </Box>
                ))}
            </Box>
          </Paper>
          <Box className='flex_center' sx={{ pt: '1rem' }}>
            <Typography>{`추가한 ${otherUnexceptList.length} 개 질의응답 중`}</Typography>
            <TextField
              sx={{ width: '5rem', m: '0 .5rem' }}
              value={otherUnexceptCount}
              type='number'
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setOtherUnexceptCount(Number(event.target.value))}
            />
            <Typography>개</Typography>
            <TextField
              select
              sx={{ width: '10rem', m: '0 .5rem' }}
              defaultValue='0'
              value={otherUnexceptCondition}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setOtherUnexceptCondition(event.target.value)}
            >
              <MenuItem value='more'>이상</MenuItem>
              <MenuItem value='equal'>설정안함</MenuItem>
            </TextField>
            <Typography>프로그램에서 쿠폰을 발급한 참여자</Typography>
          </Box>
        </Box>
        <Divider />
        <Box>
          <Typography sx={{ fontWeight: 500 }}>
            타프로그램 쿠폰 발급자를 미당첨자로
          </Typography>
          <Typography sx={{ color: '#9EA2A9', mb: '1.5rem' }}>
            타프로그램에서 쿠폰을 발급한 참여자를 미당첨자로 넣습니다.
          </Typography>
          <Box className='flex_center'>
            <TextField
              placeholder='프로그램 아이디를 입력해 주세요.'
              sx={{ width: '24rem', mr: '.5rem' }}
              inputRef={otherExceptInputRef}
              onKeyDown={evt => {
                handleKeyDown(evt, 3);
              }}
            />
            <Button variant='contained' onClick={() => addOtherProgram(1)}>
              추가
            </Button>
          </Box>
          <Paper sx={{ m: '1rem 0', p: '1rem' }}>
            <Box sx={{ height: '10rem', overflowY: 'scroll' }}>
              {otherExceptList &&
                otherExceptList.map((item: any, idx: number) => (
                  <Box className='flex_start'>
                    <Typography>
                      {`${item.challengeId} : ${item.challengeTitle}`}
                    </Typography>
                    <ClearIcon
                      className='clear_icon_button'
                      onClick={() => deleteOtherProgram(1, idx)}
                    />
                  </Box>
                ))}
            </Box>
          </Paper>
          <Box className='flex_center' sx={{ pt: '1rem' }}>
            <Typography>{`추가한 ${otherExceptList.length} 개 프로그램 중`}</Typography>
            <TextField
              sx={{ width: '5rem', m: '0 .5rem' }}
              value={otherExceptCount}
              type='number'
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setOtherExceptCount(Number(event.target.value))}
            />
            <Typography>개</Typography>
            <TextField
              select
              sx={{ width: '10rem', m: '0 .5rem' }}
              defaultValue='0'
              value={otherExceptCondition}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setOtherExceptCondition(event.target.value)}
            >
              <MenuItem value='more'>이상</MenuItem>
              <MenuItem value='equal'>설정안함</MenuItem>
            </TextField>
            <Typography>프로그램에서 쿠폰을 발급한 참여자</Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '3.5rem' }}>
          <Button variant='contained' onClick={applyCondition}>
            조건 적용하기
          </Button>
        </Box>
      </Paper>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
