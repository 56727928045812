import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  styled,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { SwDialog } from '../../commonComponent/views/SwDialog';
import { SubTabBox } from '../../styles/Styles';

interface Props {}

const TabContainer = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider',
  '& .MuiTab-root.Mui-selected': {
    color: '#00A2FF',
    backgroundColor: '#fff',
    border: '4px solid #EBECF2',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTabs-root': {
    minHeight: '1rem',
  },
  '& .MuiTab-root': {
    fontSize: '.8rem',
    minWidth: '3.2rem',
    minHeight: '1rem',
    backgroundColor: '#EBECF2',
    padding: '.4rem 1.5rem',
  },
});

const FirstTab = styled(Tab)({
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
});

const LastTab = styled(Tab)({
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
});

export const UserInfo: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string>('');
  const searchRef = useRef<any>(null);
  const [value, setValue] = useState(1);
  const [detailOpen, setDetailOpen] = useState(false);
  const details = () => setDetailOpen(!detailOpen);

  function moveToPath(newValue: number) {
    const newId =
      searchRef.current.value !== undefined && searchRef.current.value !== null
        ? searchRef.current.value.trim()
        : '';
    const newPhoneno =
      searchRef.current.name !== undefined && searchRef.current.name !== null
        ? searchRef.current.name.trim()
        : '';
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('community', {
          replace: true,
          state: {
            id: newId,
            phoneno: newPhoneno,
          },
        });
        break;
      case 2:
        setValue(2);
        navigate('challenge', {
          replace: true,
          state: {
            id: newId,
            phoneno: newPhoneno,
          },
        });
        break;
      case 3:
        setValue(3);
        navigate('walkpiece', {
          replace: true,
          state: {
            id: newId,
            phoneno: newPhoneno,
          },
        });
        break;
      case 4:
        setValue(4);
        navigate('dailywalk', {
          replace: true,
          state: {
            id: newId,
            phoneno: newPhoneno,
          },
        });
        break;
      case 5:
        setValue(5);
        navigate('wearable', {
          replace: true,
          state: {
            id: searchRef.current.value,
            phoneno: searchRef.current.name,
          },
        });
        break;
      case 6:
        setValue(6);
        navigate('program', {
          replace: true,
          state: {
            id: searchRef.current.value,
            phoneno: searchRef.current.name,
          },
        });
        break;
      case 7:
        setValue(7);
        navigate('point', {
          replace: true,
          state: {
            id: searchRef.current.value,
            phoneno: searchRef.current.name,
          },
        });
        break;
      default:
        setValue(0);
        navigate('/users/userinfo');
    }
  }

  const tabChange = (event: SyntheticEvent, newValue: number) => {
    moveToPath(newValue);
  };

  const searchUserInfo = () => {
    const path = window.location.pathname;
    if (path === '/users/userinfo/community') {
      moveToPath(1);
    } else if (path === '/users/userinfo/challenge') {
      moveToPath(2);
    } else if (path === '/users/userinfo/walkpiece') {
      moveToPath(3);
    } else if (path === '/users/userinfo/dailywalk') {
      moveToPath(4);
    } else if (path === '/users/userinfo/wearable') {
      moveToPath(5);
    } else if (path === '/users/userinfo/program') {
      moveToPath(6);
    } else if (path === '/users/userinfo/point') {
      moveToPath(7);
    } else {
      moveToPath(1);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchUserInfo();
    }
  };

  useEffect(() => {
    if (location.state) {
      const newState: any = location.state;
      searchRef.current.value = newState.id;
      searchRef.current.name = newState.phoneno;
      searchUserInfo();
    }
  }, []);

  return (
    <Box className='root_box'>
      <TextField
        placeholder='사용자 아이디 검색'
        inputRef={searchRef}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton sx={{ pr: 0 }} onClick={searchUserInfo}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{ mb: '1.5rem', width: '23rem', backgroundColor: 'white' }}
      />
      <SubTabBox>
        <Tabs value={value} onChange={tabChange}>
          <FirstTab label='커뮤니티' value={1} />
          <Tab label='챌린지' value={2} />
          <Tab label='프로그램' value={6} />
          <Tab label='걸음 조각' value={3} />
          <Tab label='일일 걸음 수' value={4} />
          <Tab label='측정모드' value={5} />
          <LastTab label='포인트' value={7} />
        </Tabs>
      </SubTabBox>
      {/* <button onClick={details}>dd</button> */}
      <SwDialog open={detailOpen} onclose={details} />
      <Outlet />
    </Box>
  );
};
