import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { NoneBorderChip } from '../../styles/Styles';
import { SwSearchTemplateCode } from './SwSearchTemplateCode';

interface Props {
  findTemplateCode: string;
  setFindTemplateCode: (value: SetStateAction<string>) => void;
  connectTemplateCode: any;
  setConnectTemplateCode: (value: SetStateAction<any>) => void;
  setTargetInfo?: Dispatch<React.SetStateAction<any>>;
  showTitle?: boolean;
  isDetail?: boolean;
}

export const SwTemplateCodeConnect: React.FC<Props> = ({
  findTemplateCode,
  setFindTemplateCode,
  connectTemplateCode,
  setConnectTemplateCode,
  setTargetInfo,
  showTitle = false,
  isDetail = false,
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [templateCodes, setTemplateCodes] = useState<any>([]);
  const [title, setTitle] = useState<string>('');

  const changeValue = (
    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setFindTemplateCode(evt.target.value);

  const connectingTemplateCode = () => {
    // setConnectTemplateCode(findTemplateCode);
    // setFindTemplateCode('');
    setSearchKey('all');
    setSearchWord(findTemplateCode);
    setOpenSearchResult(true);
  };
  const disconnectingTemplateCode = (index: number) => {
    const newPortalIds = templateCodes.filter(
      (item: any, idx: number) => idx !== index
    );
    setTemplateCodes(newPortalIds);
    setTargetInfo && setTargetInfo(newPortalIds);
  };

  const deleteAllTemplateCode = () => {
    setTemplateCodes([]);
    setTargetInfo && setTargetInfo([]);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      connectingTemplateCode();
    }
  };

  useEffect(() => {
    setTemplateCodes([...connectTemplateCode]);
    setTargetInfo && setTargetInfo([...connectTemplateCode]);
    setFindTemplateCode('');
  }, [connectTemplateCode]);

  useEffect(() => {
    console.log('TemplateCodeConnect searchResult:', searchResult);
    if (
      searchResult !== null &&
      searchResult !== undefined &&
      searchResult.length > 0
    ) {
      const newTemplate = searchResult
        .map((item: any) => {
          const newData = {
            templateCode: item.templateCode,
            templateName: item.templateName,
          };
          return newData;
        })
        .filter((item: any) => {
          const isInclude = templateCodes.find(
            (t: any) => t.templateCode === item.templateCode
          );
          return isInclude === undefined;
        });

      setTemplateCodes([...templateCodes, ...newTemplate]);

      isDetail
        ? setTargetInfo && setTargetInfo([...templateCodes, ...searchResult])
        : setTargetInfo && setTargetInfo([...templateCodes, ...newTemplate]);
    } else {
      setTemplateCodes([]);
    }
    return () => {};
  }, [searchResult]);

  return (
    <>
      <Box>
        {showTitle && (
          <Typography className='title'>템플릿 코드 조회</Typography>
        )}
        <TextField
          value={findTemplateCode}
          onChange={changeValue}
          onKeyDown={handleKeyDown}
          sx={{ width: '26rem', mr: '1em' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={connectingTemplateCode}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Paper className='chip_container'>
        {showTitle && <Typography>템플릿 코드</Typography>}
        {templateCodes.length > 0 &&
          templateCodes.map((item: any, idx: number) => (
            <Box key={`template_code_idx_${idx}`}>
              <NoneBorderChip
                label={`${item.templateName}(${item.templateCode})`}
                variant='outlined'
                onDelete={() => disconnectingTemplateCode(idx)}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            </Box>
          ))}
        <div className='flex_end'>
          <Button
            onClick={() => deleteAllTemplateCode()}
            color='info'
            sx={{ fontSize: '.8rem' }}
          >
            <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
            전체삭제
          </Button>
        </div>
      </Paper>
      <SwSearchTemplateCode
        title={title !== '' ? title : '프로그램 종류'}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
        isDetail={isDetail}
      />
    </>
  );
};
