import {
  Box,
  Button,
  Pagination,
  Paper,
  Slide,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { getUserList } from '../../../common/api/ApiUser';
import { KEY_USER_LIST } from '../../../common/key';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import {
  convertIphoneModel,
  removeNatCodePhoneNumber,
} from '../../../common/helper';

interface Props {
  columns: any[];
  rows: any[];
  totalCount: number;
  rowsPerPage: number;
  searchData: string[];
  onAddEmail: () => void;
  setUserData: Dispatch<React.SetStateAction<any>>;
  isOpenUserDetail: () => void;
}

export const UserInquireTable: React.FC<Props> = ({
  columns,
  rows,
  totalCount,
  rowsPerPage,
  searchData,
  onAddEmail,
  setUserData,
  isOpenUserDetail,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const containerRef = useRef<any>();
  const [open, setopen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const handleChangePage = (_event: any, newPage: number) => {
    if (searchData[0].length === 0 || searchData[1].length === 0) {
      setOpenSnakerDialog(true);
      setSnackbarMessage('검색할 단어를 입력해주세요.');
      return;
    }
    setPage(newPage);
    queryClient.prefetchQuery(KEY_USER_LIST, () =>
      getUserList(newPage, rowsPerPage, searchData[0], searchData[1])
    );
  };

  const registerLog = (id: any) => {
    console.log('id :', id);
    const devCheck = false;
    const param = {
      uuid: id,
    };
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json, text/plain',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
    };
    const uri = devCheck
      ? 'http://52.78.235.179/apis/v3/log/prepare'
      : 'https://walkon-v2.swallaby.net/apis/v3/log/prepare';
    axios
      .post(uri, JSON.stringify(param), { headers })
      .then((res: any) => {
        console.log(res);
        if (res.data.resultCode === -1) {
          setOpenSnakerDialog(true);
          setSnackbarMessage('이미 예약되었습니다.');
          return;
        }
        setOpenSnakerDialog(true);
        setSnackbarMessage('정상적으로 예약되었습니다.');
      })
      .catch(err => console.log('err>>>>', err));
  };

  const viewLog = (id: any) => {
    const devCheck = false;
    console.log('id :', id);
    const uri = devCheck
      ? `http://52.78.235.179/apis/v3/log/stack?uuid=${id}`
      : `https://walkon-v2.swallaby.net/apis/v3/log/stack?uuid=${id}`;
    axios.get(uri).then(res => {
      const newLog = res.data.data.logInfo;
      console.log('viewLog :', res.data.data);
      if (!newLog.length) {
        setOpenSnakerDialog(true);
        setSnackbarMessage('데이터가 없습니다.');
      }
    });
  };

  useEffect(() => {
    console.log('searwchData:', searchData);
  }, []);

  return (
    <Paper sx={{ p: '1.5rem', m: '1.4rem 0' }}>
      <TableTitle title='조회된 계정' count={totalCount} />
      <TableContainer>
        <Table sx={{ whiteSpace: 'nowrap' }}>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={idx.toString()}
                  sx={{ fontSize: '.88rem', fontWeight: 400, p: '.5rem' }}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .map(row => [
                row.name == null ? '-' : row.name,
                row.id == null ? '-' : row.id,
                row.phoneno == null
                  ? '-'
                  : removeNatCodePhoneNumber(row.phoneno),
                row.email == null ? '-' : row.email,
                row.gender == null
                  ? '-'
                  : row.gender === '1'
                  ? '남성'
                  : row.gender === '0'
                  ? '여성'
                  : '-',
                row.age === null ? '-' : row.age,
                row.address === null || row.address === '' ? '-' : row.address,
                row.registerDate === null ? '-' : row.registerDate,
                row.lastUpdateDate === null ? '-' : row.lastUpdateDate,
                row.deviceModel === null ? '-' : row.deviceModel,
                row.appVersion === null ? '-' : row.appVersion,
              ])
              .map((value: any, idx) => (
                <TableRow key={idx.toString()}>
                  {value.map((w: any, i: number) => (
                    <TableCell
                      key={i.toString()}
                      className={
                        rows[idx].auth === 0 ? 'not_important_row' : ''
                      }
                    >
                      {i === 3 && (w === '-' || w === '') ? (
                        <Button
                          id={value[1]}
                          name={value[0]}
                          size='small'
                          variant='outlined'
                          color='info'
                          onClick={(evt: any) => {
                            setUserData(rows[idx]);
                            onAddEmail();
                          }}
                        >
                          이메일 추가
                        </Button>
                      ) : i === 9 && w.includes('iPhone') ? (
                        <>
                          <Typography>{convertIphoneModel(w)}</Typography>
                          <Typography>{w}</Typography>
                        </>
                      ) : (
                        w
                      )}
                    </TableCell>
                  ))}
                  <TableCell>
                    <Button
                      id={value[1]}
                      name={value[0]}
                      size='small'
                      variant='outlined'
                      color='info'
                      onClick={(evt: any) => {
                        setUserData(rows[idx]);
                        isOpenUserDetail();
                      }}
                    >
                      상세보기
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      id={value[1]}
                      name={value[2]}
                      size='small'
                      variant='contained'
                      color='primary'
                      onClick={(evt: any) => {
                        navigate('/users/userinfo', {
                          replace: true,
                          state: {
                            id: evt.currentTarget.id,
                            phoneno: evt.currentTarget.name,
                          },
                        });
                      }}
                    >
                      바로가기
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      id={value[1]}
                      name={value[0]}
                      size='small'
                      variant='outlined'
                      color='primary'
                      onClick={(evt: any) => {
                        registerLog(evt.currentTarget.id);
                      }}
                    >
                      예약
                    </Button>
                    <Button
                      id={value[1]}
                      name={value[0]}
                      sx={{ ml: '.5rem' }}
                      size='small'
                      variant='outlined'
                      color='primary'
                      onClick={(evt: any) => {
                        viewLog(evt.currentTarget.id);
                      }}
                    >
                      로그보기
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            {open &&
              rows
                .map(row => Object.values(row))
                .map((q: any) => [
                  q[0],
                  q[9],
                  ...q.slice(2, 5),
                  q[10],
                  q[6],
                  q[11],
                  q[12],
                ])
                .map((value: any, idx) => (
                  <Slide
                    in={open}
                    key={idx.toString()}
                    container={containerRef.current}
                  >
                    <TableRow key={idx.toString()}>
                      {value.map((w: any, i: number) => (
                        <TableCell key={i.toString()}>
                          {i !== 3 || w !== '' ? (
                            w
                          ) : (
                            <Button
                              size='small'
                              variant='outlined'
                              color='info'
                            >
                              이메일 추가
                            </Button>
                          )}
                        </TableCell>
                      ))}
                      <TableCell>
                        <Button size='small' variant='outlined' color='info'>
                          상세보기
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button size='small' variant='contained' color='info'>
                          바로가기
                        </Button>
                      </TableCell>
                    </TableRow>
                  </Slide>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: '.6rem', display: 'flex', justifyContent: 'center' }}>
        <Pagination
          color='primary'
          variant='outlined'
          page={page}
          onChange={handleChangePage}
          count={Math.ceil(totalCount / rowsPerPage)}
        />
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={snackbarMessage}
      />
    </Paper>
  );
};
