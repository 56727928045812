import {
  Box,
  Button,
  ButtonProps,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  styled,
  TextField,
} from '@mui/material';
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridCsvExportOptions,
  gridPageCountSelector,
  gridPageSelector,
  GridRowClassNameParams,
  GridRowParams,
  GridSelectionModel,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridValueGetterParams,
  MuiEvent,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { MouseEvent, useEffect, useState } from 'react';

interface Props {
  columns: GridColDef<any, any, any>[];
  rows: any[];
}

const StyledDataGrid = styled(DataGrid)({
  border: 0,
  color: 'rgba(0,0,0,.85)',
  '& .MuiDataGrid-columnsContainer': {
    backgroundColor: '#fafafa',
  },
  '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
    borderRight: '1px solid #f0f0f0',
  },
  '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
    borderBottom: '1px solid #f0f0f0',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#7787AF',
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-cell': {
    color: 'rgba(0,0,0,.85)',
    overflow: 'unset',
    whiteSpace: 'normal',
    height: 'max-content',
  },
  '& .MuiPaginationItem-root': {
    borderRadius: '50%',
  },
  '& .MuiDataGrid-footerContainer': {
    justifyContent: 'center',
  },
  '& .MuiDataGrid-main': {
    backgroundColor: '#FFFFFF',
  },
  '& .MuiDataGrid-cellContent': {
    overflowWrap: 'anywhere',
    overflow: 'unset',
    textOverflow: 'clip',
  },
});

const Grids = styled(GridToolbarContainer)({
  '& .MuiButton-root': {
    color: 'black',
    // color: 'deepBlue',
  },
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '1em',
});

const CustomToolbar = () => {
  const apiRef = useGridApiContext();
  const handleExport = (options: GridCsvExportOptions) =>
    apiRef.current.exportDataAsCsv(options);
  const buttonBaseProps: ButtonProps = {
    color: 'error',
    size: 'small',
    endIcon: <FileDownloadRoundedIcon color='success' />,
  };
  const handleAllExport = () => apiRef.current.exportDataAsCsv();
  const columnFilter = () => {
    console.log(22);
  };
  const csvOptions: GridCsvExportOptions = { delimiter: ';' };
  return (
    <Box
      className='flex_center'
      id='ttt'
      sx={{ margin: '1rem 0', justifyContent: 'flex-start' }}
    >
      <GridToolbarFilterButton />
      <GridToolbarColumnsButton />
      {/* <Button onClick={columnFilter}>123</Button>
      <TextField
        label='거래처 분류 선택'
        select
        sx={{
          width: '10rem',
          '& .MuiOutlinedInput-root': {
            marginRight: '.5rem',
            borderRadius: '4px',
            height: '35px',
          },
          '& .MuiInputLabel-root': {
            top: -7,
          },
        }}
      >
        <MenuItem value={1}>1</MenuItem>
        <MenuItem value={2}>2</MenuItem>
        <MenuItem value={3}>3</MenuItem>
      </TextField> */}
      {/* <Box sx={{ border: '1px solid #D4D9E1', borderRadius: '4px' }}>
          <Button {...buttonBaseProps} onClick={() => handleExport(csvOptions)}>
            데이터 다운로드
          </Button>
        </Box> */}
      {/* <Box
        sx={{ border: '1px solid #D4D9E1', borderRadius: '4px', mr: '.5rem' }}
      >
        <Button {...buttonBaseProps} onClick={() => handleExport(csvOptions)}>
          데이터 전체 다운로드
        </Button>
      </Box>
      <Button variant='outlined' color='info' sx={{ mr: '.5rem' }}>
        수정
      </Button>
      <Button variant='outlined' color='info' sx={{ mr: '.5rem' }}>
        삭제
      </Button>
      <Button variant='contained' color='info' sx={{ mr: '.5rem' }}>
        <AddOutlinedIcon />
        고객 추가
      </Button> */}
    </Box>
  );
};

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      siblingCount={4}
      page={page + 1}
      count={pageCount}
      onChange={(event, value) => {
        apiRef.current.setPage(value - 1);
      }}
    />
  );
};

export const SwGridTableTest: React.FC<Props> = ({ columns, rows }) => {
  const columnFilter = (a: any) => {
    console.log(22);
  };
  return (
    <div style={{ height: '90vh', width: '100vw', minWidth: '1800px' }}>
      <StyledDataGrid
        onSelectionModelChange={(
          selectionModel: GridSelectionModel,
          details: GridCallbackDetails
        ) => {
          console.log(selectionModel, details);
          // const qq = do
        }}
        // getRowHeight={() => 300}
        rows={rows}
        columns={columns}
        rowHeight={200}
        pageSize={20}
        autoPageSize
        // checkboxSelection
        hideFooterSelectedRowCount
        components={{
          Pagination: CustomPagination,
          Toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};
