import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';

interface Props {
  src: string;
  onDelete: () => void;
}

export const SwImageBox: React.FC<Props> = ({ src, onDelete }) => {
  const [id, setId] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  return (
    <Box sx={{ position: 'relative' }}>
      <img
        src={src}
        alt=''
        style={{
          width: '12rem',
          height: '12rem',
          padding: '.5rem',
          margin: '0rem 0',
        }}
      />
      <Button
        sx={{ position: 'absolute', top: '5%', right: '1%' }}
        onClick={() => {
          onDelete();
        }}
      >
        <DisabledByDefaultIcon sx={{ color: '#000000' }} />
      </Button>
    </Box>
  );
};
