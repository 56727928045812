import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';
import CloseIcon from '@mui/icons-material/Close';
import { useFind, useMany, useSaver } from '../../hooks/api';
import { DefaultEmp, IEmp } from '../../models/emp';
import { SwSnackbar } from '../commonComponent/views/SwSnackbar';
import { SwAlert } from '../commonComponent/views/SwAlert';
import {
  getAccountRoleList,
  postAccountRegister,
} from '../../common/api/ApiAccount';
import {
  KEY_ACCOUNT_LIST,
  KEY_ACCOUNT_ROLE_LIST,
  KEY_ACCOUNT_REGISTER,
} from '../../common/key';
import { RESULT_OK } from '../../common/resultCode';
import { useSession } from '../../hooks/session';

interface Props {
  role: any;
  open: boolean;
  onclose: () => void;
  isEdit: boolean;
  account: any;
  title: string;
}

const JoinTextField = styled(TextField)({
  width: '29.4rem',
});

const JoinDivider = styled(Divider)({
  margin: '.7rem 0',
});

export const JoinDialog: React.FC<Props> = ({
  role,
  open,
  onclose,
  isEdit,
  account,
  title,
}) => {
  const { loginVal } = useSession();
  const queryClient = useQueryClient();
  const [check, setCheck] = useState<boolean>(false);
  const [checkContents, setCheckContents] = useState<string>('');
  const onCloseCheck = () => setCheck(!check);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const onChangeAlertOpen = () => setAlertOpen(!alertOpen);
  const [name, setName] = useState<any>('');
  const [phoneNumber, setPhoneNumber] = useState<any>('');
  const [id, setId] = useState<any>('');
  const [password, setPassword] = useState<any>('');
  const [selectRole, seSelectRole] = useState<any>(1);

  const inputSet = [
    {
      title: '이름',
      value: name,
      setValue: setName,
      placeholder: '이름을 입력해주세요',
    },
    {
      title: '연락처',
      value: phoneNumber,
      setValue: setPhoneNumber,
      placeholder: '연락처를 입력해 주세요.',
    },
    {
      title: '아이디',
      value: id,
      setValue: setId,
      placeholder: '영문 아이디를 입력해 주세요.',
    },
    {
      title: '비밀번호',
      value: password,
      setValue: setPassword,
      placeholder: '비밀번호를 입력해 주세요.',
    },
  ];

  // const { data: roles } = useFind<string[]>('adminAccount/roles');

  // const joinAccount = useSaver<IEmp>(
  //   '/adminAccount/join',
  //   newEmp => {
  //     console.log('newEmp>>>>>>>>>>', newEmp);
  //   },
  //   (err: AxiosError) => {
  //     console.log('err>>>', err);
  //   }
  // );

  const { mutate, isError: isMutateError } = useMutation(postAccountRegister, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        queryClient.invalidateQueries(KEY_ACCOUNT_LIST);
        setCheckContents('계정이 생성되었습니다.');
        setCheck(true);
        onclose();
      } else {
        setCheckContents(
          `계정을 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
      setCheck(true);
    },
    onError: error => {
      setCheckContents(
        '계정생성 과정에서 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
      setCheck(true);
      console.log(error);
    },
  });

  const Signup = () => {
    var val = 0;

    if (name.length === 0) {
      setCheckContents('이름을 입력해주세요.');
      setCheck(true);
      val = 1;
    } else if (phoneNumber === undefined || phoneNumber.length === 0) {
      setCheckContents('연락처를 입력해주세요.');
      setCheck(true);
      val = 2;
    } else if (id.length === 0) {
      setCheckContents('아이디를 입력해주세요.');
      setCheck(true);
      val = 3;
    } else if (password.length === 0) {
      setCheckContents('비밀번호를 입력해주세요.');
      setCheck(true);
      val = 4;
    }

    if (val === 0) {
      // const newRole = role
      //   ?.map((item: any, idx: number) => {
      //     if (item === selectRole) return idx;
      //     return -1;
      //   })
      //   .filter((item: any) => item !== -1);
      const newEmp = {
        ...DefaultEmp,
        name: name,
        phoneNumber: phoneNumber,
        id: id,
        password: password,
        role: selectRole,
      };
      console.log('newEmp>>>>', newEmp);
      mutate(newEmp);
    }
  };

  useEffect(() => {
    console.log('account :', account, ',isEdit:', isEdit);
    if (open && isEdit) {
      setName(account.name);
      setPhoneNumber(account.phoneNumber);
      setId(account.id);
      setPassword(account.password);
      seSelectRole(account.type);
    } else {
      setName('');
      setPhoneNumber('');
      setId('');
      setPassword('');
      seSelectRole(1);
    }
  }, [open]);
  return (
    <>
      <Dialog
        open={open}
        onClose={onclose}
        sx={{
          '& .MuiDialog-paper': {
            width: '31rem',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            {title}
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent sx={{ ml: '.2rem' }}>
          {inputSet.map((input, idx) => (
            <Box key={idx.toString()}>
              <Typography className='title'>{input.title}</Typography>
              <JoinTextField
                placeholder={input.placeholder}
                value={input.value}
                InputProps={{
                  endAdornment: input.title === '이름' && (
                    <InputAdornment position='end'>
                      <span>0 / 5</span>
                    </InputAdornment>
                  ),
                }}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => input.setValue(evt.target.value)}
              />
              <JoinDivider />
            </Box>
          ))}
          <Box>
            <Typography className='title'>권한</Typography>
            <JoinTextField
              placeholder='일반'
              select
              value={selectRole}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => seSelectRole(evt.target.value)}
            >
              {role
                ?.filter((item: string) =>
                  item !== '마스터' ? true : loginVal.value?.user?.type === 3
                )
                .map((item: string, idx: number) => (
                  <MenuItem value={idx + 1} key={idx.toString()}>
                    {item}
                  </MenuItem>
                ))}
            </JoinTextField>
          </Box>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          <Button
            variant='contained'
            color='secondary'
            sx={{ fontSize: '.9rem', mr: '.8rem' }}
            onClick={Signup}
          >
            {isEdit ? '저장' : '완료'}
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={check}
        onClose={onCloseCheck}
        contents={checkContents}
      />
      <SwAlert
        title={
          alertMessage !== '계정 생성은 워크온 담당자에게 문의해 주세요.'
            ? '로그인 오류'
            : '워크온 담당자'
        }
        open={alertOpen}
        onConfirm={onChangeAlertOpen}
        contents={alertMessage}
      />
    </>
  );
};
