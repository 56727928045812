import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import {
  downloadCommunityDailyStepList,
  getCommunityDailyStepList,
} from '../../../common/api/ApiCommunity';
import { KEY_COMMUNITY_DAILYSTEP_LIST } from '../../../common/key';
import { ICommunityReqeust } from '../../../models/community';
import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import SwLoading from '../../commonComponent/spinner/SwLoading';

interface Props {}

const searchItems = [
  {
    value: 'step',
    title: '걸음수',
  },
  {
    value: 'power',
    title: '파워워킹',
  },
  {
    value: 'calorie',
    title: '칼로리',
  },
];

const columns = [
  '번호',
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '연락처',
  '나이',
  '성별',
  '그룹',
  '가입일자',
];

export const DailyStatistics: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [communityData, setCommunityData] = useState<any>(location.state);
  const selectRef = useRef<any>();
  const searchRef = useRef<any>();
  const checkBoxAllRef = useRef<any>();
  const [openFilter, setOpenFilter] = useState(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentRow, setCurrentRow] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dataList, setDataList] = useState<any>([]);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [communityId, setCommunityId] = useState<string>('');
  const [checkSearch, setCheckSearch] = useState<boolean>(false);
  const [dateTitles, setDateTitles] = useState<any>([]);
  const [statType, setStatType] = useState<any>('step');
  const [loading, setLoading] = useState<any>(false);

  const {
    data,
    isLoading,
    refetch: communityStatisticsRefetch,
  } = useQuery(
    KEY_COMMUNITY_DAILYSTEP_LIST,
    () => {
      if (checkSearch) {
        const newData: ICommunityReqeust = {
          communityId: communityData.communityId, // '000E2E79CB6944C48E9C25C34348430E', //
          statType: statType,
          startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
          endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
        };
        setLoading(true);
        return getCommunityDailyStepList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('dailystep :', res);
        setCheckSearch(false);
        setLoading(false);
        if (res != null) {
          setDataList(res.data);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        setLoading(false);
        setCheckSearch(false);
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_DAILYSTEP_LIST, () => {
      const newData: ICommunityReqeust = {
        communityId: communityData.communityId,
        statType: statType,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getCommunityDailyStepList(newData);
    });
  };

  const makeDateTitle = () => {
    const startDate =
      rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000 + 32400;
    const endDate =
      rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000 + 32400;

    let i = 1;
    const oneDay = 86400;
    const repeatCount = Math.floor((endDate - startDate) / oneDay) + 1;
    const newRangeDate = [startDate];
    do {
      if (!repeatCount) {
        break;
      }
      newRangeDate.push(new Date(startDate + oneDay * i).getTime());
      i += 1;
    } while (i < repeatCount);
    const newTitles = newRangeDate.map(
      (item: any) => timestamp2string(item, 1000).split(' ')[0]
    );
    setDateTitles(newTitles);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    // prefetch(newPage);
  };

  const convertDate = (timestamp: any): string => {
    const newDate = timestamp2Localestring(timestamp, 1000);
    console.log('date :', newDate);
    return newDate.split(' ')[0];
  };

  const onDownloadFile = (type: string) => {
    const newData = {
      communityId: communityData.communityId,
      statType: statType,
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
      downloadType: type,
    };
    if (type === 'pdf') {
      downloadCommunityDailyStepList(newData);
    } else if (type === 'excel') {
      downloadCommunityDailyStepList(newData);
    }
  };

  const searchDailyStatistics = () => {
    makeDateTitle();
    setCheckSearch(true);
  };

  const goBack = () => {
    navigate('/communitymanage/listdetail/basicinfomation', {
      replace: true,
      state: location.state,
    });
  };

  useEffect(() => {
    if (checkSearch === true) communityStatisticsRefetch();
  }, [checkSearch]);

  useEffect(() => {
    communityStatisticsRefetch();
  }, [rangeDate]);

  useEffect(() => {
    if (location.state) {
      console.log('location.state:', location.state);
      setCommunityData(location.state);
    }
  }, [location.state]);

  return (
    <>
      <Button onClick={goBack}>
        <ArrowBackIcon sx={{ mr: '.5rem' }} />
        커뮤니티 상세
      </Button>
      <Box className='flex_between' sx={{ p: '0 0', mb: '.5rem' }}>
        <Box>
          <Typography className='title'>일자별 통계</Typography>
        </Box>
        <Box>
          <Button color='info' variant='outlined' sx={{ ml: '1rem' }}>
            조회 신청
          </Button>
          <Button variant='contained' color='info' sx={{ ml: '1rem' }}>
            신청 내역
          </Button>
        </Box>
      </Box>
      <Paper sx={{ p: '1.5rem' }}>
        {/* <Box className='flex_center' sx={{ p: '0 0 .8rem .12rem' }}>
          <Typography>커뮤니티</Typography>
          <Typography color='primary' sx={{ ml: '.3rem' }}>
            {` ${totalRecords}`}
          </Typography>
          <Typography>개</Typography>
        </Box> */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            mb: '1rem',
          }}
        >
          <Box className='flex_between' sx={{ mt: '0.8rem' }}>
            <Typography className='title'>조회 데이터</Typography>
            <TextField
              sx={{ width: '10rem', mr: '1rem' }}
              select
              value={statType}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setStatType(event.target.value);
              }}
            >
              {searchItems.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box className='flex_between'>
            <Box className='flex_between'>
              <Typography className='title'>기간</Typography>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
              <Button
                color='info'
                variant='outlined'
                sx={{ ml: '1rem' }}
                onClick={searchDailyStatistics}
              >
                조회
              </Button>
            </Box>
            <Box className='flex_between'>
              <Button
                color='info'
                variant='outlined'
                sx={{ ml: '1rem' }}
                onClick={() => onDownloadFile('pdf')}
              >
                PDF 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
              <Button
                color='info'
                variant='outlined'
                sx={{ ml: '1rem' }}
                onClick={() => onDownloadFile('excel')}
              >
                엑셀 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
            </Box>
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column: any, idx: number) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
                {dateTitles.map((column: any, idx: number) => (
                  <TableCell key={(idx + columns.length).toString()}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList
                ?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
                ?.map((row: any, idx: number) => (
                  <TableRow key={idx.toString()}>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {idx + 1 + (page - 1) * rowsPerPage}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row?.nickName === ''
                        ? '-'
                        : row?.useNickName === 1
                        ? 'O'
                        : '-'}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row?.nickName !== '' ? row?.nickName : '-'}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row?.userName}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row?.phoneno}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>{row?.age}</TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row?.gender}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row?.groupName === 'no-group'
                        ? '기본그룹'
                        : row?.groupName}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {convertDate(row?.timestamp)}
                    </TableCell>
                    {dateTitles.map((column: any, i: number) => (
                      <TableCell key={(idx + i + 1).toString()}>
                        {row[column]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      {loading && <SwLoading />}
    </>
  );
};
