import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { MultilineTextField } from '../../styles/Styles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import {
  postOperationGuide,
  putOperationGuide,
  deleteOperationGuide,
} from '../../../common/api/ApiOperation';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { IOperationGuide } from '../../../models/operation';
import { IUploadFile } from '../../../models/common';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
  selected: any;
}

export const ManageGuideDrawUp: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
  selected,
}) => {
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const [guideIdx, setGuideIdx] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageFile, setImageFile] = useState<any>(null);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const { mutate: postOperationGuideMutate, isError: isMutateError } =
    useMutation(postOperationGuide, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('운영가이드를 등록했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '운영가이드 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const { mutate: putOperationGuideMutate, isError: isPutMutateError } =
    useMutation(putOperationGuide, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('운영가이드를 수정했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '운영가이드 수정이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const registerOperationGuide = (newUploadUrl: string) => {
    const newImage =
      imageFile !== null
        ? newUploadUrl
        : image.length > 0
        ? selected?.image
        : '';

    const newData: IOperationGuide = {
      type: 'community',
      idx: Number(guideIdx),
      title: titleRef.current?.value,
      content: contentRef.current?.value,
      image: newImage,
    };

    console.log('registerOperationGuide : ', newData);
    selected?.idx !== undefined && selected?.idx !== null
      ? putOperationGuideMutate(newData)
      : postOperationGuideMutate(newData);
  };

  const { mutate: uploadFileMutate, isError: isUploadMutateError } =
    useMutation(postUploadFile, {
      onSuccess: res => {
        console.log('uploadFile:', res);
        setUploadUrl(res.uploadUrl.file.path);
        console.log('uploadFile uploadUrl:', res.uploadUrl.file.path);
        registerOperationGuide(res.uploadUrl.file.path);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const checkOperationGuide = () => {
    if (imageFile === null) {
      registerOperationGuide('');
    } else {
      const uploadfile: IUploadFile = {
        type: 'operation',
        file: imageFile,
      };
      uploadFileMutate(uploadfile);
    }
  };

  const { mutate: deleteOperationGuideMutation, isError: isDeleteMutateError } =
    useMutation(deleteOperationGuide, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('운영가이드를 수정했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '운영가이드를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const deleteGuide = (idx: number) => {
    const newData: IOperationGuide = {
      idxList: [idx],
    };
    deleteOperationGuideMutation(newData);
  };

  useEffect(() => {
    setGuideIdx(selected?.idx === undefined ? '' : selected?.idx);
    if (selected?.image.length > 0) {
      setImage(`${selected?.imageUrlPrefix}${selected?.image}`);
    } else {
      setImage('');
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <span>운영 가이드 작성</span>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent sx={{ p: '1.5rem 2.6em' }}>
          {edit === false ? (
            <>
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                제목
              </Typography>
              <TextField
                fullWidth
                inputRef={titleRef}
                defaultValue={selected?.title}
              />
              <Divider />
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                내용
              </Typography>
              <TextField
                inputRef={contentRef}
                multiline
                rows={8}
                fullWidth
                defaultValue={selected?.content}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                  },
                }}
              />
              <Divider />
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                이미지(선택)
              </Typography>
              <SwFileSetting
                image={image}
                setImage={setImage}
                setFile={setImageFile}
                file={imageFile}
              />
            </>
          ) : (
            <>
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                {selected?.title}
              </Typography>
              <Typography sx={{ color: '#999999' }}>
                작성일자: {selected && selected?.createDate} | 조회수:{' '}
                {selected && selected?.viewCount}
              </Typography>
              <Divider />
              <Paper
                sx={{
                  widht: '100%',
                  height: '30vh',
                  overflowY: 'scroll',
                  mt: '1rem',
                  mb: '1rem',
                }}
              >
                {selected?.image.length > 0 ? (
                  <img
                    src={
                      selected?.image.length > 0
                        ? `${selected?.imageUrlPrefix}${selected?.image}`
                        : ''
                    }
                    alt=''
                    style={{ width: '100%' }}
                  />
                ) : (
                  <></>
                )}
              </Paper>
              <MultilineTextField
                multiline
                rows={10}
                fullWidth
                value={selected?.content}
              />
            </>
          )}
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          {edit ? (
            <>
              <Button
                onClick={() => {
                  deleteGuide(selected?.idx);
                }}
                color='info'
                variant='outlined'
              >
                삭제
              </Button>
              <Button onClick={isEdit} color='info' variant='contained'>
                수정
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                onClick={checkOperationGuide}
                color='info'
                variant='contained'
              >
                저장
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
