import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Drawer,
  styled,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Dispatch, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import tabItems from '../tabList.json';
import { useSession } from '../../../hooks/session';
import { useResize } from '../../../hooks/resize';
import useClasses from '../../../hooks/useClasses';

const LeftTab = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '18em',
    boxSizing: 'border-box',
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const TabAccordion = styled(Accordion)({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 'inherit',
    borderLeft: '3px solid #2581FF',
    backgroundColor: 'rgba(37, 129, 255, 0.04)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '15px 0',
  },
  borderRadius: 1,
});

const TabTitle = styled(Typography)({
  fontSize: '.8rem',
  fontWeight: 500,
  color: '#999999',
  margin: '1rem',
  marginBottom: '.5rem',
});

const TabButton = styled(Button)({
  color: 'inherit',
  fontSize: '.85rem',
  textAlign: 'left',
  padding: '.5rem 1.5rem',
  justifyContent: 'flex-start',
});

const styles = {
  isDevTab: {
    '& .MuiDrawer-paper': {
      backgroundColor: '#2C313D !important',
      color: '#FFFFFF',
    },
  },
  defaultDashboard: {
    padding: '2rem 1rem',
    paddingBottom: '1rem',
    cursor: 'pointer',
  },
  dashboard: {
    backgroundColor: '#F6F7F8',
  },
};

const tabList: any[] = tabItems;

interface Props {
  menuMap: any;
  openNav: boolean;
  setOpenNav: Dispatch<React.SetStateAction<boolean>>;
  param: string;
  onSelected: (node: any) => void;
}

export const SwTemplateSideTab: React.FC<Props> = ({
  menuMap,
  openNav,
  setOpenNav,
  param,
  onSelected,
}) => {
  const { logout } = useSession();
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const classes = useClasses(styles);
  const [expandedDepth1, setExpandedDepth1] = useState<string | false>(false);
  const [expandedDepth2, setExpandedDepth2] = useState<string | false>(false);
  const [expandedDepth3, setExpandedDepth3] = useState<string | false>(false);
  const [expandedDepth4, setExpandedDepth4] = useState<string | false>(false);
  const tabChangeDepth1 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedDepth1(isExpanded ? panel : false);
    };
  const tabChangeDepth2 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedDepth2(isExpanded ? panel : false);
    };
  const tabChangeDepth3 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedDepth3(isExpanded ? panel : false);
    };
  const tabChangeDepth4 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedDepth4(isExpanded ? panel : false);
    };

  const doSelected = (node: any, menuPath: string[], list: any) => {
    const newNode = { ...node };
    newNode.menuPath = menuPath;
    newNode.node = list;
    newNode.nodeType = 'node';
    onSelected(newNode);
    console.log('newNode : ', newNode);
  };

  const doShowTab = (list: any) => {
    const newNode: any = {
      nodeType: 'tab',
      menuPath: '',
      node: list,
    };
    onSelected(newNode);
  };

  useEffect(() => {
    console.log('param: ', param);
    if (param.length > 0) {
      const currentTab = tabList.findIndex(
        q => q.contents.findIndex((w: any) => w.title === param) !== -1
      );
      setExpandedDepth1(currentTab.toString());
    }
  }, [param]);

  useEffect(() => {
    if (menuMap) {
      console.log('--> menuMap: ', menuMap);
    }
  }, [menuMap]);

  return (
    <Box sx={{ width: '25rem' }}>
      <Box
        className={clsx(
          { [classes.dashboard]: true },
          classes.defaultDashboard
        )}
      >
        <Box className='flex_start'>
          <Typography sx={{ mr: '1rem' }}>카테고리 선택</Typography>
        </Box>
      </Box>
      {menuMap.map((list: any, idx: number) => (
        <div key={`side_menu_${idx.toString()}`}>
          <TabAccordion
            className={clsx({ isDev: false })}
            expanded={expandedDepth1 === idx.toString()}
            onChange={tabChangeDepth1(idx.toString())}
            square
            elevation={0}
          >
            <AccordionSummary
              expandIcon={
                <NavigateNextIcon className={clsx({ whitecolor: false })} />
              }
              onClick={() => {
                list.contentCount > 0
                  ? doSelected(list, [list.title], [])
                  : console.log('list:', list);
              }}
            >
              <Typography variant='h6' component='div'>
                {list.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className='flex_column' sx={{ p: 0, ml: '1rem' }}>
              {list.contentCount > 0 &&
                list.nodeList.map((node: any, i: number) => (
                  <TabButton
                    key={`side_menu_tabbutton1_${i.toString()}`}
                    sx={{ ml: '1rem' }}
                    className={clsx({ selected_tab: param === node.title })}
                    onClick={() => {
                      doSelected(node, [list.title, node.title], []);
                    }}
                  >
                    {node.title}
                  </TabButton>
                ))}
              {list.contentCount === 0 &&
                list.nodeList.map((node: any, i: number) => (
                  <TabAccordion
                    key={`side_menu_accodion1_${i.toString()}`}
                    expanded={expandedDepth2 === i.toString()}
                    onClick={() => doShowTab(node)}
                    onChange={tabChangeDepth2(i.toString())}
                    square
                    elevation={0}
                  >
                    <AccordionSummary
                      expandIcon={
                        <NavigateNextIcon
                          className={clsx({ whitecolor: false })}
                        />
                      }
                      onClick={() => {
                        node.contentCount > 0
                          ? doSelected(node, [list.title, node.title], node)
                          : doShowTab(node);
                      }}
                    >
                      <Typography variant='h6' component='div'>
                        {node.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className='flex_column' sx={{ p: 0 }}>
                      {node.nodeList.map((subNode: any, j: number) => (
                        <TabButton
                          key={`side_menu_tabbutton2_${j.toString()}`}
                          className={clsx({
                            selected_tab: param === subNode.title,
                          })}
                          sx={{ ml: '1rem' }}
                          onClick={() => {
                            doSelected(
                              subNode,
                              [list.title, node.title, subNode.title],
                              []
                            );
                          }}
                        >
                          {subNode.title}
                        </TabButton>
                      ))}
                    </AccordionDetails>
                  </TabAccordion>
                ))}
            </AccordionDetails>
          </TabAccordion>
        </div>
      ))}
    </Box>
  );
};
