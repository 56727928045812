import {
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { ButtonBox, ContentTitle, MarginButton } from './LocalstoreStyles';
import { LocalstoreForm } from './LocalstoreForm';
import { LocalstoreReward } from './LocalstoreReward';

import {
  KEY_CHALLENGE_REQUEST_LIST,
  KEY_CHALLENGE_REQUEST_DETAIL,
  KEY_ACCOUNT_LIST,
  KEY_LOCAL_STORE_REQUEST_LIST,
} from '../../../common/key';
import { SwCreationRequestReject } from '../../commonComponent/SwCreationRequestReject';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { RESULT_OK } from '../../../common/resultCode';
import { getAccountList } from '../../../common/api/ApiAccount';
import { useSession } from '../../../hooks/session';
import {
  encodeSearchWord,
  getLocalstoreApplyStatus,
  timestamp2Localestring,
} from '../../../common/helper';
import {
  changeLocalstoreApplyStatus,
  confirmLocalstoreRegister,
  getLocalstoreRequestList,
} from '../../../common/api/ApiLocalstore';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
});

const requestColumnlists = [
  '가게 이름',
  '가게 아이디',
  '상태',
  '신청일자',
  '계정 아이디',
];

interface Props {}

export const LocalstoreCreate: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { loginVal, logout, session } = useSession();
  const [localstoreId, setLocalstoreId] = useState<string>('');
  const [localstoreList, setLocalstoreList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [localstoreRequestData, setLocalstoreRequestData] = useState<any>();
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);
  const [localstoreClassType, setLocalstoreClassType] = useState<any>([]);
  const [localstoreInfo, setLocalstoreInfo] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>('');
  const [selectedRow, setSelectedRow] = useState(-1);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [localstoreTitle, setLocalstoreTitle] = useState<string>('');
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [empList, setEmpList] = useState<any>();
  const [operator, setOperator] = useState<string>(''); // loginVal.value?.user?.id);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [tabValue, setTabValue] = useState(0);
  let changeAlertMessage = '';

  const {
    data,
    isLoading,
    refetch: localstoreRequestRefetch,
  } = useQuery(
    KEY_LOCAL_STORE_REQUEST_LIST,
    () => {
      const newRequest = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (checkRequest) return getLocalstoreRequestList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('requestList:', res);
        const newLocalstore =
          res &&
          res.creationRequest.store &&
          res.creationRequest.store.map((item: any, idx: number) => {
            const currentDate = new Date();
            const newDate =
              item.createdDate !== 0
                ? timestamp2Localestring(item.createdDate, 1000)
                : '-';
            const status =
              item.localstoreApplyStatus === 4
                ? '시험중'
                : getLocalstoreApplyStatus(item.localstoreApplyStatus);
            const newData = [
              item.storeName,
              item.storeId,
              item.storeStatus,
              newDate,
              item.ownerId,
            ];
            if (localstoreId === item.storeId) {
              setSelectedRow(idx);
            }
            return newData;
          });
        setRequestList(newLocalstore);
        setLocalstoreList(res ? res.creationRequest.store : []);
        setLocalstoreClassType(res ? res.creationRequest.classType : null);
        setTotalRecords(res?.pagination.totalRecordCount);
        if (selectedId !== '') {
          const newSelect = res.creationRequest.store.filter(
            (item: any) => item.storeId === selectedId
          );
          console.log('reloadLocalstore: ', selectedId, ', info:', newSelect);
          setLocalstoreInfo(newSelect.length > 0 ? newSelect[0] : null);
        }
        setSelectedId('');
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: confirmLocalstoreRegisterMutation, isError: isMutateError } =
    useMutation(confirmLocalstoreRegister, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK || res.localstoreType === 5) {
          setOpenSnackbar(true);
          setAlertMessage('가게가 승인되었습니다.');
          localstoreRequestRefetch();
        } else {
          setAlertOpen(true);
          setAlertMessage('리워드를 등록해 주세요.');
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '가게 생성을 승인하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: changeLocalstoreApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeLocalstoreApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage(changeAlertMessage);
      localstoreRequestRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        `요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.`
      );
    },
  });

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[3], Chosen[1], Chosen[2], Chosen[4], 'x'];
    setChosenRequest([newChosen]);
    setLocalstoreId(localstoreList[i].storeId);
    setLocalstoreInfo(localstoreList[i]);
    setSelectedRow(i);
    Chosen[1] === '작성중' ? setIsEditing(true) : setIsEditing(false);
  };

  const confirmChallege = (testType: number) => {
    const newData: any = {
      storeId: localstoreId,
      testType: testType,
    };

    confirmLocalstoreRegisterMutation(newData);
  };

  const saveRewardCount = (count: number) => {
    setRewardCount(count);
    if (count !== localstoreInfo.rewardCount) {
      localstoreRequestRefetch();
    }
  };

  const reloadLocalstore = (id: string) => {
    if (id !== '') {
      setLocalstoreId(id);
      setSelectedId(id);
      localstoreRequestRefetch();
    }
  };

  const searchLocalstoreRequest = (
    newOperator: string,
    key: string,
    name: string
  ) => {
    localstoreRequestRefetch();
  };

  const onRejectMessage = (message: string) => {
    if (message.length > 0) {
      const newData: any = {
        storeId: localstoreId,
        status: 3, // 1-작성중 2-접수요청 3-반려 4-접수완료
        message: message,
      };
      changeAlertMessage = '가게가 수정되었습니다.';
      changeLocalstoreApplyStatusMutation(newData);
    }
  };

  const deleteLocalstore = () => {
    const newData: any = {
      storeId: localstoreId,
      status: 9, // 1-작성중 2-접수요청 3-반려 4-접수완료 9-삭제 (어드민 only)
      message: '',
    };
    changeAlertMessage = '가게가 삭제되었습니다.';
    changeLocalstoreApplyStatusMutation(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchLocalstoreRequest(operator, 'all', searchWord);
    }
  };

  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== tabValue) setTabValue(newValue);
  };

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      localstoreRequestRefetch();
    }
    return () => {};
  }, [checkRequest]);

  return (
    <>
      <Box className='flex_between' sx={{ m: '1.5rem 0' }}>
        <Box>
          <TextField
            placeholder='가게 이름, 아이디, 주소, 업종, 계정으로 검색해 주세요.'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => searchLocalstoreRequest}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper sx={{ cursor: 'pointer', mb: '1rem' }}>
        <Box sx={{ m: '1rem 1rem' }}>
          <Box className='flex_between'>
            <Typography className='title'>
              요청 {requestList?.length}개
            </Typography>
          </Box>
          <TableContainer sx={{ maxHeight: '27rem' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {requestColumnlists.map((column, idx) => (
                    <TableCell
                      key={idx.toString()}
                      sx={{ p: '.4rem .7rem !important' }}
                      className={clsx(
                        { request_head: false },
                        'request_tablehead'
                      )}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {requestList?.map((row: any, idx: number) => (
                  <TableRow
                    key={idx.toString()}
                    onClick={() => chooseRequest(idx)}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                  >
                    {row.map((rowItem: any, rowIdx: number) => (
                      <TableCell
                        key={rowIdx.toString()}
                        sx={{ p: '.6rem .7rem' }}
                        className={
                          selectedRow === idx &&
                          localstoreList[idx].localstoreStatus === 0
                            ? clsx(
                                'request_tablecell selected_row not_important_row'
                              )
                            : selectedRow === idx && row[1] === '접수요청'
                            ? clsx(
                                'request_tablecell selected_row specified_row'
                              )
                            : selectedRow === idx
                            ? clsx('request_tablecell selected_row')
                            : localstoreList[idx].localstoreStatus === 0
                            ? clsx('request_tablecell not_important_row')
                            : row[1] === '접수요청'
                            ? clsx('request_tablecell specified_row')
                            : clsx('request_tablecell ')
                        }
                      >
                        {rowIdx !== 0
                          ? row[rowIdx]
                          : localstoreList[idx].localstoreStatus === 0
                          ? `${row[rowIdx]} (close)`
                          : row[rowIdx]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      <Paper>
        <Box sx={{ p: '0rem 0', mt: '2rem' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <CreateTabs value={tabValue} onChange={onChangeTab}>
              <Tab key={0} label='가게 정보' />
              <Tab key={1} label='리워드 정보' />
            </CreateTabs>
          </Box>
          <TabPanel value={tabValue} index={0} key={0}>
            <LocalstoreForm
              localstoreInfo={localstoreInfo}
              setLocalstoreInfo={setLocalstoreInfo}
              reloadLocalstore={reloadLocalstore}
              classTypeList={localstoreClassType}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} key={1}>
            <LocalstoreReward localstoreInfo={localstoreInfo} />
          </TabPanel>
        </Box>
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box className='flex_between'>
            <Box>
              <MarginButton
                variant='outlined'
                color='info'
                onClick={() => {
                  window.location.reload();
                }}
              >
                신규작성
              </MarginButton>
            </Box>
          </Box>
          <Box>
            <MarginButton
              variant='outlined'
              color='warning'
              onClick={() => setOpenDelete(true)}
            >
              삭제
            </MarginButton>
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => setRejectOpen(true)}
            >
              수정 요청
            </MarginButton>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => confirmChallege(1)}
            >
              승인
            </MarginButton>
            {/* <Button
              variant='contained'
              color='info'
              onClick={() => confirmChallege(0)}
            >
              저장
            </Button> */}
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwCreationRequestReject
        title='가게'
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onRejectMessage={onRejectMessage}
      />
      <SwConfirmDialog
        contents='개설하시겠습니까?'
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onConfirm={() => {
          confirmChallege(1);
          setOpenFilter(false);
        }}
        confirm={1}
      />
      <SwConfirmDialog
        contents={`'${localstoreInfo?.storeName}'를 삭제하시겠습니까? 가게를 삭제하면 해당 가게에 등록된 리워드도 모두 삭제됩니다.`}
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false);
          deleteLocalstore();
        }}
        confirm={1}
      />
    </>
  );
};
