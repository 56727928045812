import { Box, MenuItem, Paper, Typography } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { SelectTextField, TableButton } from '../../styles/Styles';
import { PartnerTable } from '../../commonComponent/table/PartnerTable';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { IChallengeReqeust } from '../../../models/challenge';
import { getChallengeListByPortal } from '../../../common/api/ApiChallenge';
import { KEY_CHALLENGE_LIST_BY_PORTAL } from '../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { downloadChallengeData } from '../../../common/api/ApiContract';

interface Props {
  contractInfo: any;
}

const column = [
  '챌린지 종류',
  '챌린지 이름',
  '참여 시작일자',
  '참여 종료일자',
  '노출 시작일자',
  '노출 종료일자',
  '리워드 개수',
  '참여자 수',
];

export const PartnerChallengeInformation: React.FC<Props> = ({
  contractInfo,
}) => {
  const queryClient = useQueryClient();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [requestList, setRequestList] = useState<any>([]);
  const [challegenList, setChallengeList] = useState<any>([]);
  const [sortValue, setSortValue] = useState(0);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [portalId, setPortalId] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: challengeRefetch,
  } = useQuery(
    KEY_CHALLENGE_LIST_BY_PORTAL,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest && portalId.length > 0) {
        const newData: IChallengeReqeust = {
          portalId: portalId,
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
          startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
          endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        };
        return getChallengeListByPortal(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res && res.challenge) {
          const newChallenge = res.challenge.map((item: any) => {
            const newData = [
              item.challengeTypeStr,
              item.challengeTitle,
              timestamp2Localestring(item.challengeAttendStime, 1000),
              timestamp2Localestring(item.challengeAttendEtime, 1000),
              timestamp2Localestring(item.challengeViewStime, 1000),
              timestamp2Localestring(item.challengeViewEtime, 1000),
              item.rewardCount,
              item.attendUserCount,
            ];
            return newData;
          });
          setRequestList(newChallenge);
          setChallengeList(res.challenge);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const challengePrefetch = (newPortalId: string) => {
    queryClient.prefetchQuery(KEY_CHALLENGE_LIST_BY_PORTAL, () => {
      const newData: IChallengeReqeust = {
        portalId: newPortalId,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
      };
      getChallengeListByPortal(newData);
    });
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const downloadChallenge = () => {
    const newData = {
      portalId: portalId,
      page: 0,
      rowsPerPage: 0,
      searchKey: searchKey,
      searchWord: encodeSearchWord(searchKey, searchWord),
      startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
      endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
    };
    downloadChallengeData(newData);
  };

  useEffect(() => {
    if (rangeDate[0] !== null && rangeDate[1] !== null) {
      if (portalId.length > 0) challengeRefetch();
    }
  }, [rangeDate]);

  useEffect(() => {
    if (portalId.length > 0) challengeRefetch();
  }, [page]);

  useEffect(() => {
    if (portalId.length > 0) challengeRefetch();
  }, [portalId]);

  useEffect(() => {
    console.log('PartnerChallengeInformation contractInfo :', contractInfo);
    if (contractInfo) {
      setPortalId(contractInfo.id);
      challengePrefetch(contractInfo.id);
    } else {
      setPortalId('');
      setRequestList([]);
    }
  }, [contractInfo]);

  return (
    <>
      <Typography variant='h3' sx={{ fontWeight: 500, p: '2.5rem 0 .5rem' }}>
        챌린지 이용 정보
      </Typography>
      <Paper sx={{ p: '1.5rem', m: '1rem 0' }}>
        <Box className='flex_between' sx={{ mb: '1rem' }}>
          <Box>조회 {totalRecords} 건</Box>
          <Box className='flex_center'>
            <Box sx={{ mr: '.5rem' }}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Box>
            <SelectTextField
              select
              value={sortValue}
              onChange={evt => {
                setSortValue(Number(evt.target.value));
              }}
              sx={{ mr: '.5rem' }}
            >
              <MenuItem value={0}>전체</MenuItem>
              <MenuItem value={1}>진행중</MenuItem>
              <MenuItem value={2}>종료</MenuItem>
            </SelectTextField>
            <SelectTextField
              select
              value={rowsPerPage}
              onChange={evt => {
                setRowsPerPage(Number(evt.target.value));
                setPage(1);
              }}
            >
              {rowsPerPageOptions.map(option => (
                <MenuItem key={option.toString()} value={option}>
                  {option}개씩 보기
                </MenuItem>
              ))}
            </SelectTextField>
            <TableButton
              color='info'
              variant='outlined'
              onClick={downloadChallenge}
            >
              데이터 다운로드
              <FileDownloadRoundedIcon
                color='success'
                sx={{ fontSize: '1.3rem' }}
              />
            </TableButton>
          </Box>
        </Box>
        <PartnerTable
          columns={column}
          rows={requestList}
          rowsPerPage={rowsPerPage}
          page={page}
          totalRecords={totalRecords}
          handleChangePage={handleChangePage}
        />
      </Paper>
    </>
  );
};
