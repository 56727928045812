import {
  Box,
  Button,
  Chip,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { WalkcourseMap } from './WalkcourseMap';
import { WalkcourseForm } from './WalkcourseForm';
import {
  IWalkcouseDetail,
  IWalkcourseInfo,
  DefaultWalkcourseInfo,
  DefaultWalkcouseDetail,
} from '../../../models/walkcourse';
import {
  getWalkcourseRequestList,
  getWalkcourseRequestDetail,
  changeWalkcourseApplyStatus,
} from '../../../common/api/ApiWalkcourse';
import {
  KEY_WALKCOURSE_REQUEST_LIST,
  KEY_WALKCOURSE_REQUEST_DETAIL,
} from '../../../common/key';
import { timestamp2Localestring } from '../../../common/helper';
import { SwCreationRequestReject } from '../../commonComponent/SwCreationRequestReject';

interface Props {}

const requestColumnlists = [
  '길 코스 이름',
  '신청일자',
  '신청자',
  '상태',
  '담당자',
];
const clickColumnlist = ['길 코스 이름', '신청일자', '신청자', '담당자'];

export const WalkcourseCreate: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const [commandType, setCommandType] = useState<number>(0); // 0: no-action, 1: save, 2:update, 3: delete
  const [gpsData, setGpsData] = useState<any>([]);
  const [mapReload, setMapReload] = useState(0);
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [selectRequest, setSelectRequest] = useState<any>([]);
  const [walkcourseList, setWalkcourseList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [mapInfo, setMapInfo] = useState<any>();
  const [walkcourseDetail, setWalkcourseDetail] = useState<IWalkcouseDetail>(
    DefaultWalkcouseDetail
  );
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [courseId, setCourseId] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [courseData, setCourseData] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: walkcourseRequestRefetch,
  } = useQuery(
    KEY_WALKCOURSE_REQUEST_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getWalkcourseRequestList();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newWalkcourse =
          res &&
          res.creationRequest &&
          res.creationRequest.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const newData = [
              item.courseName,
              newDate,
              item.creatorName,
              item.registerStatus === 2
                ? '접수요청'
                : item.registerStatus === 3
                ? '반려'
                : '-',
              item.editor,
            ];
            return newData;
          });
        setRequestList(newWalkcourse);
        setWalkcourseList(res ? res.creationRequest : []);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: walkcourseRequestDetailRefetch } = useQuery(
    KEY_WALKCOURSE_REQUEST_DETAIL,
    () => {
      console.log('courseId:', courseId);
      if (courseId.length > 0) return getWalkcourseRequestDetail(courseId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          const tmpRoute = res.creationRequestDetail.point.map((point: any) => {
            const temp = {
              lat: point.pointPtLat,
              lng: point.pointPtLng,
              radius: point.pointRadius,
            };
            return temp;
          });
          console.log('point :', tmpRoute);
          setGpsData(tmpRoute);

          const newCircleData = {
            lat: res.creationRequestDetail.course.courseCenterPointLat,
            lng: res.creationRequestDetail.course.courseCenterPointLng,
            radius: res.creationRequestDetail.course.courseCenterRadius,
          };

          const newSpot = res.creationRequestDetail.spot.map((spot: any) => {
            const temp = {
              id: spot.spotId,
              name: spot.spotName,
              image: spot.spotImage,
              lat: spot.spotPointLat,
              lng: spot.spotPointLng,
              radius: spot.spotRadius,
              description: spot.description,
              className: spot.className,
              link: spot.link,
              phoneNo: spot.phoneNo,
              address: spot.address,
            };
            return temp;
          });

          const newCourseData = {
            gpsData: tmpRoute,
            circleData: newCircleData,
            spotData: newSpot,
          };
          setCourseData(newCourseData);

          setWalkcourseDetail(res.creationRequestDetail);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newCourseId: string) => {
    queryClient.prefetchQuery(KEY_WALKCOURSE_REQUEST_DETAIL, () =>
      getWalkcourseRequestDetail(newCourseId)
    );
  };

  const {
    mutate: changeWalkcourseApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeWalkcourseApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage('수정 요청되었습니다');
      walkcourseRequestRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '길 코스 수정을 요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const onReload = () => {
    walkcourseRequestRefetch();
  };

  useEffect(() => {
    setMapReload(1);
    return () => {
      setMapReload(0);
    };
  }, []);

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[0], Chosen[1], Chosen[2], Chosen[4]];
    // setIsSend(false);
    setChosenRequest([newChosen]);
    setCourseId(walkcourseList[i].courseId);
    prefetchDetail(walkcourseList[i].courseId);
    setSelectedRow(i);
  };

  const onRejectMessage = (message: string) => {
    if (message.length > 0) {
      const newData = {
        courseId: courseId,
        status: 3, // 1-작성중 2-접수요청 3-반려 4-접수완료
        message: message,
      };
      changeWalkcourseApplyStatusMutation(newData);
    }
  };

  useEffect(() => {
    if (mapInfo) {
      console.log('WalkcourseCreate mapInfo :', mapInfo);
      const newCourse = walkcourseDetail.course;
      newCourse.courseDistance = mapInfo.courseDistance;
      newCourse.courseTime = mapInfo.courseTime;
      newCourse.courseCenterPointLat = mapInfo.circle?.lat;
      newCourse.courseCenterPointLng = mapInfo.circle?.lng;
      newCourse.courseCenterRadius = mapInfo.circle?.radius;
      const newWalkcourseDetail = {
        course: newCourse,
        point: mapInfo.points,
      };
      console.log(
        'WalkcourseCreate newWalkcourseDetail :',
        newWalkcourseDetail
      );
      setWalkcourseDetail(newWalkcourseDetail);
      setGpsData(mapInfo.points);
    }
  }, [mapInfo]);

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      walkcourseRequestRefetch();
    }
    return () => {};
  }, [checkRequest]);

  return (
    <>
      <Box>
        <Box sx={{ m: '1.5rem 0' }}>
          <Chip
            icon={<FiberNewOutlinedIcon />}
            label='확인'
            variant='outlined'
            color='primary'
            onClick={() => setCheckRequest(!checkRequest)}
          />
        </Box>
        <Collapse in={checkRequest}>
          <Paper sx={{ cursor: 'pointer', mb: '1rem' }}>
            <Box sx={{ m: '1rem 1rem' }}>
              <Typography className='title'>
                요청 {requestList?.length}개
              </Typography>
              <TableContainer sx={{ maxHeight: '27rem' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {requestColumnlists.map((column, idx) => (
                        <TableCell
                          key={idx.toString()}
                          sx={{ p: '.4rem .7rem !important' }}
                          className={clsx(
                            { request_head: false },
                            'request_tablehead'
                          )}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {requestList?.map((row: any, idx: number) => (
                      <TableRow
                        key={idx.toString()}
                        onClick={() => chooseRequest(idx)}
                        className={
                          selectedRow === idx ? 'selected_row' : 'whitecolor'
                        }
                      >
                        {row.map((rowItem: any, rowIdx: number) => (
                          <TableCell
                            key={rowIdx.toString()}
                            sx={{ p: '.6rem .7rem' }}
                            className={
                              selectedRow === idx && row[3] === '접수요청'
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row[3] === '접수요청'
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                          >
                            {row[rowIdx]}
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
          <RequestTable columns={clickColumnlist} rows={chosenRequest} />
        </Collapse>
      </Box>
      <WalkcourseMap
        courseData={courseData}
        mapInfo={mapInfo}
        setMapInfo={setMapInfo}
      />
      <WalkcourseForm
        commandType={commandType}
        setCommandType={setCommandType}
        mapInfo={mapInfo}
        walkcourseDetail={walkcourseDetail}
        setWalkcourseDetail={setWalkcourseDetail}
        onReload={onReload}
      />
      <Box className='flex_between' sx={{ m: '2rem 0' }}>
        <Button
          variant='outlined'
          color='error'
          onClick={() => window.location.reload()}
        >
          신규 작성
        </Button>
        <Box>
          <Button
            variant='outlined'
            color='info'
            sx={{ mr: '1rem' }}
            onClick={() => setRejectOpen(true)}
          >
            수정 요청
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              setCommandType(1);
            }}
          >
            저장
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwCreationRequestReject
        title='길 코스'
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onRejectMessage={onRejectMessage}
      />
    </>
  );
};
