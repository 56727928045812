import {
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { ButtonBox, ContentTitle, MarginButton } from './ChallengeStyles';
import { ChallengeForm } from './ChallengeForm';
import { ChallengeOpenRegion } from './ChallengeOpenRegion';
import { ChallengeReward } from './ChallengeReward';
import { ChallengePost } from './ChallengePost';
import {
  getChallengeApplyStatus,
  timestamp2Localestring,
} from '../../../common/helper';
import {
  IChallengeCreate,
  IChallengeConfirm,
  IChallengeApplyStatus,
  DefaultChallengeCreate,
  IChallengeClassType,
} from '../../../models/challenge';
import {
  getChallengeRequestList,
  getChallengeRequestDetail,
  confirmChallengeRegister,
  changeChallengeApplyStatus,
  registerChallengeMaster,
  cloneChallengeMaster,
} from '../../../common/api/ApiChallenge';
import {
  KEY_CHALLENGE_REQUEST_LIST,
  KEY_CHALLENGE_REQUEST_DETAIL,
  KEY_ACCOUNT_LIST,
} from '../../../common/key';
import { SwCreationRequestReject } from '../../commonComponent/SwCreationRequestReject';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { RESULT_OK } from '../../../common/resultCode';
import { getAccountList } from '../../../common/api/ApiAccount';
import { useSession } from '../../../hooks/session';
import {
  brandPointAdvertiserState,
  challengeClassTypeState,
  challengeState,
} from '../../../common/atom';
import { ChallengeAudioContent } from './ChallengeAudioContent';
import { ChallengeLocalStore } from './ChallengeLocalStore';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
});

const requestColumnlists = [
  '챌린지 이름',
  '상태',
  '신청일자',
  '신청자',
  '담당자',
];
const clickColumnlist = ['신청자', '상태', '신청일자', '담당자', '챌린지 메모'];
const clickColumnlist2 = [
  ['강동구 보건소(02-584-6675)', '2021/05/06 15:00', '담당자', '메모다'],
  ['-', '-', '-', '-'],
];

interface Props {}

export const ChallengeCreate: React.FC<Props> = () => {
  const [classTypeList, setClassTypeList] = useRecoilState(
    challengeClassTypeState
  );
  const [selectedChallengeInfo, setSelectedChallengeInfo] =
    useRecoilState(challengeState);
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const queryClient = useQueryClient();
  const { loginVal, logout, session } = useSession();
  const [challengeId, setChallengeId] = useState<string>('');
  const [challengeList, setChallengeList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [challengeRequestData, setChallengeRequestData] = useState<any>();
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);
  const [challengeMasterType, setChallengeMasterType] = useState<any>([]);
  const [challengeInfo, setChallengeInfo] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>('');
  const [selectedRow, setSelectedRow] = useState(-1);
  const [searchWord, setSearchWord] = useState<string>('');
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([
    clickColumnlist2[1],
  ]);
  const [tabValue, setTabValue] = useState(0);
  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== tabValue) setTabValue(newValue);
  };
  const [challengeTitle, setChallengeTitle] = useState<string>('');
  const [challengeInfoContents, setChallengeInfoContents] = useState<any>({
    challengeTitle: '',
    challengeId: '',
    challengeType: '',
    challengeTypeContents: {
      goal: '',
    },
    challengeMainImage: '',
    challengeHeaderInfo: '',
    exposureRangeDate: '',
    participationRangeDate: '',
    guideList: [],
    score: '',
    challengeheungtae: '',
    dataportalConnect: '',
    status: '',
  });
  const [rangeSetting, setRangeSetting] = useState({
    community: '',
    group: '',
    area: [],
    region: [],
    gender: '',
    age: '',
  });
  const [reward, setReward] = useState([
    {
      order: '',
      id: '',
      type: '',
      typedetail: {
        content1: '',
        content2: '',
        content3: '',
        content4: '',
      },
      duplicate: false,
      rangeDate: [],
      count: '',
      name: '',
      image: '',
      guideList: [],
      areaInfo: '',
      status: '',
    },
  ]);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [empList, setEmpList] = useState<any>();
  const [operator, setOperator] = useState<string>(''); // loginVal.value?.user?.id);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  let changeAlertMessage = '';
  let searchData: any = {
    operator: '', // loginVal.value?.user?.id,
    searchKey: 'all',
    searchWord: '',
  };

  // const { refetch: accountRefetch } = useQuery(
  //   KEY_ACCOUNT_LIST,
  //   () => getAccountList(0, 0, ''),
  //   {
  //     onSuccess: res => {
  //       setEmpList(res.account);
  //     },
  //     onError: e => {
  //       console.log(e);
  //     },
  //   }
  // );

  const {
    data,
    isLoading,
    refetch: challengeRequestRefetch,
  } = useQuery(
    KEY_CHALLENGE_REQUEST_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getChallengeRequestList(searchData);
      return null;
    },
    {
      onSuccess: res => {
        console.log('requestList:', res);
        const newChallenge =
          res &&
          res.creationRequest &&
          res.creationRequest.challenge.map((item: any, idx: number) => {
            const currentDate = new Date();
            const newDate = timestamp2Localestring(item.challengeCreatetime);
            const status =
              item.challengeApplyStatus === 4
                ? '시험중'
                : getChallengeApplyStatus(item.challengeApplyStatus);
            const newData = [
              item.challengeTitle,
              status,
              newDate,
              `${item.challengeCreatorName}(${item.challengeCreator})`,
              item.operatorName,
            ];
            if (challengeId === item.challengeId) {
              setSelectedRow(idx);
            }
            return newData;
          });
        setRequestList(newChallenge);
        setChallengeList(res ? res.creationRequest.challenge : []);
        setChallengeRequestData(res ? res.creationRequest : null);
        setChallengeMasterType(res ? res.creationRequest.type : null);
        setRewardGivetype(res ? res.creationRequest.rewardGivetype : null);
        setRewardUsetype(res ? res.creationRequest.rewardUsetype : null);
        setBrandPointAdvertiserList(
          res ? res.creationRequest.advertiser : null
        );
        const newData = res
          ? res.creationRequest.classType.map((item: any) => {
              const newItem: IChallengeClassType = {
                type: item.classType,
                title: item.classTitle,
              };
              return newItem;
            })
          : [];
        setClassTypeList(newData);
        if (selectedId !== '') {
          const newSelect = res.creationRequest.challenge.filter(
            (item: any) => item.challengeId === selectedId
          );
          setChallengeInfo(newSelect.length > 0 ? newSelect[0] : null);
          setSelectedChallengeInfo((prevState: any) => ({
            ...prevState,
            authAddress: newSelect.length > 0 ? newSelect[0].authAddress : '[]',
          }));
        } else {
          setSelectedChallengeInfo((prevState: any) => ({
            ...prevState,
            challengeType: 1,
            challengeId: '',
            useChallengePostAds: false,
            authAddress: '[]',
          }));
        }
        setSelectedId('');
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: confirmChallengeRegisterMutation, isError: isMutateError } =
    useMutation(confirmChallengeRegister, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK || res.challengeType === 5) {
          setOpenSnackbar(true);
          setAlertMessage('저장되었습니다.');
          challengeRequestRefetch();
        } else {
          setAlertOpen(true);
          setAlertMessage('리워드를 등록해 주세요.');
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '챌린지 생성을 승인하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: changeChallengeApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeChallengeApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage(changeAlertMessage);
      challengeRequestRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        `요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.`
      );
    },
  });

  const { mutate: cloneChallengeMasterMutation, isError: isMasterMutateError } =
    useMutation(cloneChallengeMaster, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('복제 완료, 작성중 내역에서 확인하세요.');
          challengeRequestRefetch();
        } else {
          setOpenSnackbar(true);
          setAlertMessage(
            `챌린지를 복제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          `챌린지를 복제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[3], Chosen[1], Chosen[2], Chosen[4], 'x'];
    setChosenRequest([newChosen]);
    setChallengeId(challengeList[i].challengeId);
    setChallengeInfo(challengeList[i]);
    setSelectedRow(i);
    Chosen[1] === '작성중' ? setIsEditing(true) : setIsEditing(false);

    setSelectedChallengeInfo((prevState: any) => ({
      ...prevState,
      challengeType: challengeList[i].challengeType,
      challengeId: challengeList[i].challengeId,
      useChallengePostAds: challengeList[i].useChallengePostAds,
      authAddress: challengeList[i].authAddress,
    }));
  };

  const confirmChallege = (testType: number) => {
    const newData: IChallengeConfirm = {
      challengeId: challengeId,
      testType: testType,
    };

    // if (
    //   (challengeInfo.rewardCount === 0 || challengeInfo.rewardCount === null) &&
    //   rewardCount === 0 &&
    //   challengeInfo.challengeType !== 5
    // ) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('리워드 정보를 입력해주세요');
    //   return;
    // }
    confirmChallengeRegisterMutation(newData);
  };

  const checkFilter = (testType: number) => {
    const newFilters = JSON.parse(challengeInfo.challengeFilters);
    console.log('newFilters :', newFilters);
    const newFilterList =
      newFilters &&
      newFilters.map((item: any) => {
        const newData = {
          filterType: item.FILTER_TYPE,
          challengeId: item.CHALLENGE_ID,
          targetId: item.TARGET_ID,
          communityName: item.NAME,
        };
        return newData;
      });

    if (newFilterList === undefined || newFilterList.length === 0) {
      setOpenFilter(true);
    } else {
      confirmChallege(testType);
    }
  };

  const saveRewardCount = (count: number) => {
    setRewardCount(count);
    if (count !== challengeInfo.rewardCount) {
      challengeRequestRefetch();
    }
  };

  const reloadChallenge = (id: string) => {
    if (id !== '') {
      setChallengeId(id);
      setSelectedId(id);
      challengeRequestRefetch();
    }
  };

  const searchChallengeRequest = (
    newOperator: string,
    key: string,
    name: string
  ) => {
    const newData = {
      operator: newOperator,
      searchKey: key,
      searchWord: name,
    };
    searchData = newData;
    challengeRequestRefetch();
  };

  // useEffect(() => {
  //   if (reloadChallenge !== '') {
  //     challengeRequestRefetch();
  //   }
  //   return () => {};
  // }, [reloadChallenge]);

  const onRejectMessage = (message: string) => {
    if (message.length > 0) {
      const newData: IChallengeApplyStatus = {
        challengeId: challengeId,
        status: 3, // 1-작성중 2-접수요청 3-반려 4-접수완료
        message: message,
      };
      changeAlertMessage = '챌린지가 수정되었습니다.';
      changeChallengeApplyStatusMutation(newData);
    }
  };

  const deleteChallenge = () => {
    const newData: IChallengeApplyStatus = {
      challengeId: challengeId,
      status: 9, // 1-작성중 2-접수요청 3-반려 4-접수완료 9-삭제 (어드민 only)
      message: '',
    };
    changeAlertMessage = '챌린지가 삭제되었습니다.';
    changeChallengeApplyStatusMutation(newData);
  };

  const cloneChallenge = () => {
    if (challengeId === '' || challengeInfo === null) {
      setOpenSnackbar(true);
      setAlertMessage(`복제할 챌린지를 선택해주세요.`);
      return;
    }
    const newCreatorList = JSON.parse(challengeInfo.creatorList);

    // photoCertificateTimes
    const parsedTimes: any[] =
      challengeInfo.photoCertificateTimes !== null &&
      challengeInfo.photoCertificateTimes.length > 0
        ? JSON.parse(challengeInfo.photoCertificateTimes)
        : [];
    const newTimes = parsedTimes.map(
      (time: any) => `${time.start}:00~${time.end}:00`
    );

    const parsedWeeks: any[] =
      challengeInfo.photoCertificateWeeks !== null &&
      challengeInfo.photoCertificateWeeks.length > 0
        ? JSON.parse(challengeInfo.photoCertificateWeeks)
        : [];

    const parsedBanner =
      challengeInfo.bannerImage !== null && challengeInfo.bannerImage.length > 0
        ? JSON.parse(challengeInfo.bannerImage)
        : [];

    const newChallengeBanner = parsedBanner.filter(
      (item: any, idx: number) => item.image !== ''
    );

    const newData: IChallengeCreate = {
      ticket: '',
      challengeId: challengeInfo.challengeId,
      challengeType: challengeInfo.challengeType,
      challengeGoal: challengeInfo.challengeGoal,
      challengeStepsMax: challengeInfo.challengeStepsMax,
      challengeStepsPerPoint: challengeInfo.challengeStepsPerPoint,
      challengeStepsMin: challengeInfo.challengeStepsMin,
      challengeWalkCourseId: challengeInfo.challengeWalkCourseId,
      challengeWalkZoneId: challengeInfo.challengeWalkZoneId,
      challengeStampTourId: challengeInfo.challengeStampTourId,
      challengeTitle: challengeInfo.challengeTitle,
      challengeImgpathMain: challengeInfo.challengeImgpathMain,
      challengeInfoDuration: challengeInfo.challengeInfoDuration,
      challengeInfoReward: challengeInfo.challengeInfoReward,
      challengeInfoGoal: challengeInfo.challengeInfoGoal,
      challengeTestStime: challengeInfo.challengeTestStime,
      challengeViewStime: challengeInfo.challengeViewStime,
      challengeViewEtime: challengeInfo.challengeViewEtime,
      challengeAttendStime: challengeInfo.challengeAttendStime,
      challengeAttendEtime: challengeInfo.challengeAttendEtime,
      challengeDescription: challengeInfo.challengeDescription,
      challengeCreator:
        newCreatorList !== undefined && newCreatorList.length > 0
          ? newCreatorList[0]
          : 'ADMIN',
      challengeCreatorList: newCreatorList,
      challengeStatus: challengeInfo.challengeStatus,
      challengeViewType: challengeInfo.challengeViewType,
      useChallengePost: challengeInfo.useChallengePost,
      challengeRewardType: 0,
      challengeApplyStatus: challengeInfo.challengeApplyStatus,
      testType: 0,
      customPopupText:
        challengeInfo.customPopupText === null
          ? ''
          : challengeInfo.customPopupText,
      customPopupDeleted:
        challengeInfo.customPopupDeleted === null
          ? 1
          : challengeInfo.customPopupDeleted === 0
          ? 0
          : 1,
      coverInfo: challengeInfo.coverInfo,
      coverReward: challengeInfo.coverReward,
      coverAd: challengeInfo.coverAd,
      classType: challengeInfo.classType,
      recommendType: challengeInfo.recommendType,
      attendLimit: challengeInfo.attendLimit,
      challengeAddress: challengeInfo.challengeAddress,
      challengeLocationLat: challengeInfo.challengeLocationLat,
      challengeLocationLng: challengeInfo.challengeLocationLng,
      isWithStep: challengeInfo.isWithStep,
      isLocationCert: challengeInfo.isLocationCert,
      photoCertDescription: challengeInfo.photoCertDescription,
      photoCertificateWeeks: parsedWeeks,
      photoCertificateTimes: newTimes !== null ? newTimes : [],
      photoCertSuccessImage: challengeInfo.photoCertSuccessImage,
      photoCertFailImage: challengeInfo.photoCertFailImage,
      brandPointId: challengeInfo.brandPointId,
      hidden: challengeInfo.hidden,
      bannerImage: JSON.stringify(newChallengeBanner),
      link: challengeInfo.link,
      challengeFieldId: challengeInfo.challengeFieldId,
      maxPoint: challengeInfo.maxPoint,
      useRank: challengeInfo.useRank,
      isPrivate: challengeInfo.isPrivate,
      useGallery: challengeInfo.useGallery,
    };

    console.log('registerChallengeMaster :', newData);
    cloneChallengeMasterMutation(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchChallengeRequest(operator, 'all', searchWord);
    }
  };

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      challengeRequestRefetch();
    }
    return () => {};
  }, [checkRequest]);

  return (
    <>
      <Paper sx={{ cursor: 'pointer', mb: '1rem' }}>
        <Box sx={{ m: '1rem 1rem' }}>
          <Box className='flex_between'>
            <Typography className='title'>
              요청 {requestList?.length}개
            </Typography>
            <Box className='flex_between'>
              <TextField
                placeholder='챌린지 이름, 아이디, 기관명, 포탈 아이디로 검색해 주세요'
                value={searchWord}
                onChange={(
                  evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                ) => setSearchWord(evt.target.value)}
                onKeyDown={handleKeyDown}
                sx={{ width: '30rem', mr: '1em' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        sx={{ pr: 0 }}
                        onClick={() =>
                          searchChallengeRequest(operator, 'all', searchWord)
                        }
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {/* <Box className='flex_center'>
                <ContentTitle>담당자</ContentTitle>
                <TextField
                  sx={{ width: '10rem' }}
                  select
                  value={operator}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setOperator(evt.target.value);
                    searchChallengeRequest(evt.target.value, 'all', searchWord);
                  }}
                >
                  <MenuItem key={0} value='0'>
                    선택
                  </MenuItem>
                  {empList?.length > 0 &&
                    empList.map((emp: any, idx: number) => (
                      <MenuItem key={emp.id} value={emp.id}>
                        {emp.name}
                      </MenuItem>
                    ))}
                </TextField>
              </Box> */}
            </Box>
          </Box>
          <TableContainer sx={{ maxHeight: '27rem' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {requestColumnlists.map((column, idx) => (
                    <TableCell
                      key={idx.toString()}
                      sx={{ p: '.4rem .7rem !important' }}
                      className={clsx(
                        { request_head: false },
                        'request_tablehead'
                      )}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {requestList?.map((row: any, idx: number) => (
                  <TableRow
                    key={idx.toString()}
                    onClick={() => chooseRequest(idx)}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                  >
                    {row.map((rowItem: any, rowIdx: number) => (
                      <TableCell
                        key={rowIdx.toString()}
                        sx={{ p: '.6rem .7rem' }}
                        className={
                          selectedRow === idx &&
                          challengeList[idx].challengeStatus === 0
                            ? clsx(
                                'request_tablecell selected_row not_important_row'
                              )
                            : selectedRow === idx && row[1] === '접수요청'
                            ? clsx(
                                'request_tablecell selected_row specified_row'
                              )
                            : selectedRow === idx
                            ? clsx('request_tablecell selected_row')
                            : challengeList[idx].challengeStatus === 0
                            ? clsx('request_tablecell not_important_row')
                            : row[1] === '접수요청'
                            ? clsx('request_tablecell specified_row')
                            : clsx('request_tablecell ')
                        }
                      >
                        {rowIdx !== 0
                          ? row[rowIdx]
                          : challengeList[idx].challengeStatus === 0
                          ? `${row[rowIdx]} (close)`
                          : row[rowIdx]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      <RequestTable columns={clickColumnlist} rows={chosenRequest} />
      <Paper>
        <Box sx={{ p: '0rem 0', mt: '1rem' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <CreateTabs value={tabValue} onChange={onChangeTab}>
              <Tab key={0} label='챌린지 정보' />
              <Tab
                key={1}
                label='오디오 콘텐츠'
                disabled={selectedChallengeInfo.challengeType !== 16}
              />
              <Tab
                key={2}
                label={
                  <Badge
                    variant='dot'
                    color='warning'
                    invisible={
                      selectedChallengeInfo.authAddress !== undefined &&
                      selectedChallengeInfo.authAddress !== null
                        ? JSON.parse(selectedChallengeInfo.authAddress)
                            .length === 0
                        : false
                    }
                  >
                    공개 범위(필터)
                  </Badge>
                }
              />
              <Tab
                key={3}
                label='가게 정보'
                disabled={selectedChallengeInfo.challengeType !== 11}
              />
              <Tab key={4} label='리워드 정보' />
              <Tab
                key={5}
                label={
                  <Badge
                    variant='dot'
                    color='warning'
                    invisible={selectedChallengeInfo.useChallengePostAds !== 1}
                  >
                    게시판 광고
                  </Badge>
                }
              />
            </CreateTabs>
          </Box>
          <TabPanel value={tabValue} index={0} key={0}>
            <ChallengeForm
              challengeMasterType={challengeMasterType}
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} key={1}>
            <ChallengeAudioContent
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2} key={2}>
            <ChallengeOpenRegion
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3} key={3}>
            <ChallengeLocalStore challengeInfo={challengeInfo} />
          </TabPanel>
          <TabPanel value={tabValue} index={4} key={4}>
            <ChallengeReward
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
              saveRewardCount={saveRewardCount}
              reloadChallenge={reloadChallenge}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={5} key={5}>
            <ChallengePost
              challengeInfo={challengeInfo}
              setChallengeInfo={setChallengeInfo}
            />
          </TabPanel>
        </Box>
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box className='flex_between'>
            <Box>
              <MarginButton
                variant='outlined'
                color='info'
                onClick={() => {
                  window.location.reload();
                }}
              >
                신규작성
              </MarginButton>
            </Box>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => cloneChallenge()}
            >
              챌린지 복제
            </MarginButton>
          </Box>
          <Box>
            {isEditing && (
              <MarginButton
                variant='contained'
                color='secondary'
                onClick={() => setOpenDelete(true)}
              >
                삭제
              </MarginButton>
            )}
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => setRejectOpen(true)}
            >
              수정 요청
            </MarginButton>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => checkFilter(1)}
            >
              승인
            </MarginButton>
            {/* <Button
              variant='contained'
              color='info'
              onClick={() => confirmChallege(0)}
            >
              저장
            </Button> */}
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwCreationRequestReject
        title='챌린지'
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onRejectMessage={onRejectMessage}
      />
      <SwConfirmDialog
        contents='공개 대상 커뮤니티 혹은 지역 설정이 되어있지 않습니다. 이대로 개설하시겠습니까?'
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onConfirm={() => {
          confirmChallege(1);
          setOpenFilter(false);
        }}
        confirm={1}
      />
      <SwConfirmDialog
        contents='작성중인 챌린지를 삭제하시겠습니까?'
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false);
          deleteChallenge();
        }}
        confirm={1}
      />
    </>
  );
};
