import { isArrayLiteralExpression } from 'typescript';
import { v4 as uuidv4 } from 'uuid';
import { reactQueryClient } from '..';
import { useSession } from '../hooks/session';

export const programSearchOption = [
  { value: 'all', title: '전체' },
  { value: 'HT', title: '고혈압 프로그램' },
  { value: 'DB', title: '당뇨 프로그램' },
  { value: 'WC', title: '체중 관리 프로그램' },
  { value: 'SS', title: '금연 프로그램' },
  { value: 'OH', title: '구강건강 프로그램' },
  { value: 'MH', title: '정신건강 프로그램' },
  { value: 'DC', title: '자유형 프로그램' },
  { value: 'GY', title: '헬스장 프로그램' },
  { value: 'PE', title: '초기 임산부 관리 프로그램' },
  { value: 'PM', title: '중기 임산부 관리 프로그램' },
  { value: 'PL', title: '후기 임산부 관리 프로그램' },
  { value: 'AB', title: '산후 관리 프로그램' },
  { value: 'DE', title: '치매예방 관리 프로그램' },
  { value: 'SF', title: '안전 교육 프로그램' },
  { value: 'PH', title: '보건 교육 프로그램' },
  { value: 'NT', title: '영양 교육 프로그램' },
];

export const programTarget = [
  '전체',
  '성인',
  '청소년',
  '어린이',
  '노인',
  '여성',
  '남성',
];
export const programDifficulty = ['기본', '심화'];
export const programTemplateCodeClass = [
  '선택',
  '고혈압',
  '당뇨',
  '체중관리',
  '금연',
  '구강 건강',
  '정신건강',
  '자유형',
  '헬스장',
  '초기 임산부',
  '중기 임산부',
  '후기 임산부',
  '산후',
  '치매예방',
  '안전 교육',
  '보건 교육',
  '영양 교육',
];
export const programContentConfiguration = [
  '영상',
  '매거진',
  '생활습관 개선',
  '주차별 퀴즈',
  '중간 점검',
];

export const programMissionType = [
  { title: 'movie', type: 1 },
  { title: 'magazine', type: 2 },
  { title: 'qna', type: 3 },
  { title: 'lifestyle', type: 4 },
];

export const programCustomeContent = [
  { title: '매거진 (레시피)', type: 1 },
  { title: '매거진 (맞춤 운동)', type: 2 },
  { title: '매거진 (식재료 사전)', type: 3 },
  { title: '매거진 (예방 및 관리)', type: 4 },
];

export const programAttendUserStatus = [
  '승인대기',
  '승인완료',
  '승인거절',
  '사전승인',
  '신청실패 (청원초과)',
  '신청실패(타 프로그램 참여)',
];

export function getProgramClassCode(codeType: string) {
  const idx = programTemplateCodeClass
    .map((item: string, i: number) => (item === codeType ? i : -1))
    .filter(item => item !== -1);
  console.log('targe:', codeType, ', idx:', idx);
  return idx === undefined || idx === null ? -1 : idx[0];
}

export function getProgramTargetName(target: number) {
  return target < programTarget.length ? programTarget[target - 1] : '-';
}

export function getProgramTargetCode(target: string) {
  const idx = programTarget
    .map((item: string, i: number) => (item === target ? i + 1 : -1))
    .filter(item => item !== -1);
  console.log('targe:', target, ', idx:', idx);
  return idx === undefined || idx === null ? -1 : idx[0];
}

export function getProgramDifficultyName(difficulty: number) {
  return difficulty <= programDifficulty.length
    ? programDifficulty[difficulty - 1]
    : '-';
}
export function getProgramDifficultyCode(difficulty: string) {
  const idx = programDifficulty
    .map((item: string, i: number) => (item === difficulty ? i + 1 : -1))
    .filter(item => item !== -1);
  console.log('difficulty:', difficulty, ', idx:', idx);
  return idx === undefined || idx === null ? -1 : idx[0];
}

export function getProgramTemplateCodeClass(target: number) {
  return target < programTemplateCodeClass.length
    ? programTemplateCodeClass[target]
    : '-';
}

export function makeTemplateCode(data: any) {
  if (data === undefined || data === null) return '';
  const newTarget = getProgramTargetName(data.target);
  const newDifficulty = getProgramDifficultyName(data.difficulty);
  return `${data.typeName} ${data.weekCount}주 ${newTarget}용 ${newDifficulty} 프로그램(${data.templateCode})`;
}

export function getProgramRegisterStatus(registerStatus: number) {
  return registerStatus === 1
    ? '작성중'
    : registerStatus === 2
    ? '접수요청'
    : registerStatus === 3
    ? '수정요청'
    : registerStatus === 4
    ? '접수완료'
    : '미지정';
}

export function getMissionTypeString(missionType: number) {
  const newType = programMissionType.filter(
    (item: any) => item.type === missionType
  );
  console.log('newType: ', newType);
  return newType !== undefined && newType.length > 0 ? newType[0].title : '';
}

export function getMagazineSubType(title: string) {
  const subType =
    title === '식재료 사전'
      ? 1
      : title === '레시피'
      ? 2
      : title === '맞춤 운동'
      ? 3
      : title === '예방 및 관리'
      ? 4
      : 0;
  return subType;
}

export function getDurationType(year: string, month: string, day: string) {
  let durationType = 0;

  if (year === '선택') {
    if (month === '선택') {
      durationType = day === '선택' ? 0 : 3;
    } else {
      durationType = day === '선택' ? 2 : 6;
    }
  } else if (month === '선택') {
    durationType = day === '선택' ? 1 : 5;
  } else {
    durationType = day === '선택' ? 4 : 7;
  }
  return durationType;
}

export function getBloodPressureGroup(pressureGroup: number) {
  return pressureGroup === -1
    ? '-'
    : pressureGroup === 1
    ? '정상'
    : pressureGroup === 2
    ? '주의'
    : pressureGroup === 3
    ? '고혈압 전단계'
    : pressureGroup === 4
    ? '고혈압 1기'
    : pressureGroup === 5
    ? '고혈압 2기'
    : '-';
}

export function getFileSize(newFile: File) {
  return Math.floor(newFile !== null ? newFile.size / (1024 * 1024) : 0);
}

export function checkOverProgramAttendDate(attendData: number) {
  return attendData <= Date.now() / 1000;
}
