import { Box, Button } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { CommunityForm } from './CommunityForm';

interface Props {}

export const CommunityInfomation: React.FC<Props> = () => {
  const location = useLocation();
  const [isSend, setIsSend] = useState<boolean>(false);
  const [communityData, setCommunityData] = useState<any>();
  // const [a, seta] = useState(false);
  // const b = () => seta(!a);

  useEffect(() => {
    setCommunityData(location.state);
  }, [location.state]);

  return (
    <>
      <CommunityForm
        isSend={false}
        setIsSend={setIsSend}
        communityData={communityData}
        setCommunityData={setCommunityData}
      />
      <Box sx={{ mb: '7rem' }} className='flex_end'>
        {/* <Button variant='contained' color='info'>
          ?
        </Button> */}
      </Box>
    </>
  );
};
