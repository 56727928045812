import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import ClearIcon from '@mui/icons-material/Clear';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useState,
  Dispatch,
  useRef,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_OPTION_COUNT,
  MAX_UPLOAD_FILE_SIZE,
  getQnaSurveyType,
  getQnaSurveyTypeNo,
  pointPerAchieve,
  qnaSurveyTypeList,
  unitSelectorList,
} from './SwProgramCommon';
import { SwDatesPicker } from '../dateSetting/SwDatesPicker';
import { SwFileSetting } from '../SwFileSetting';
import { useSession } from '../../../hooks/session';
import { ButtonBox } from '../../challenge/challengeManagement/ChallengeStyles';
import { SwTimePicker } from '../dateSetting/SwTimePicker';
import { timestamp2Localestring } from '../../../common/helper';
import {
  cloneProgramMissionQnaQuestion,
  deleteProgramMissionQnaQuestion,
  postProgramMissionQnaQuestion,
  putProgramMissionQnaQuestionIsRequired,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { newUuidId } from '../SwUuidCreate';
import { getDurationType, getFileSize } from '../../../common/helperProgram';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import { DraggablePaper } from '../../styles/Styles';
import { requiredAllAnswerSts } from '../../../common/atom';

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

const QnaBox = styled(Box)({
  margin: '1rem 1rem 1rem 3rem',
});

const INIT_UNIT_SELECTOR = '선택';

interface Props {
  draggableName?: string;
  editMode: string;
  onChangeEditMode: (value: string) => void;
  survey: any;
  setSurvey: Dispatch<React.SetStateAction<any>>;
  onReloadMission: (missionId: string) => void;
  onDelete: (id: string) => void;
  disabled: boolean;
  onChangeOrder: () => void;
}

export const SwSurveyForm: React.FC<Props> = ({
  draggableName = 'survey_draggable',
  editMode,
  onChangeEditMode,
  survey,
  setSurvey,
  onReloadMission,
  onDelete,
  disabled,
  onChangeOrder,
}) => {
  const { loginVal } = useSession();
  const [requiredAllAnswerInfo, setRequiredAllAnswerInfo] =
    useRecoilState(requiredAllAnswerSts);
  const titleRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [lclEditMode, setLclEditMode] = useState<string>(
    editMode === undefined ? 'view' : editMode
  );
  const [preEditMode, setPreEditMode] = useState<string>('view');
  const [qnaType, setQnaType] = useState<string>('choice');
  const [missionId, setMissionId] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [seq, setSeq] = useState<number>(0);
  const [answer, setAnswer] = useState<string>('');
  const [inputType, setInputType] = useState<string>('direct');
  const [link, setLink] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [coverFile, setCoverFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [requiredAnswer, setRequiredAnswer] = useState<boolean>(true);
  const [multipleAnswer, setMultipleAnswer] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([
    { type: 'normal', title: '' },
    { type: 'normal', title: '' },
    { type: 'normal', title: '' },
  ]);
  const [image, setImage] = useState<string>('');
  const [uploadQuestionFile, setUploadQuestionFile] = useState<any>(null);
  const [uploadQuestionFileName, setUploadQuestionFileName] = useState<any>('');
  const [multipleAnswerCount, setMultipleAnswerCount] = useState<number>(0);
  const [multipleAnswerCountList, setMultipleAnswerCountList] = useState<any[]>(
    [
      { title: '지정 안함', value: 0 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
    ]
  );
  const [hideEtcButton, setHideEtcButton] = useState<boolean>(false);
  const [showUnitSelector, setShowUnitSelector] = useState<boolean>(false);
  const [selectUnit, setSelectUnit] = useState<any>(INIT_UNIT_SELECTOR);
  const [directInputUnit, setDirectInputUnit] = useState<any>('');
  const [startScale, setStartScale] = useState<number>(1);
  const [endScale, setEndScale] = useState<number>(2);
  const [scaleStartInput, setScaleStartInput] = useState<string>('');
  const [scaleEndInput, setScaleEndInput] = useState<string>('');
  const [startDates, setStartDates] = useState<any[]>([]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [durationYear, setDurationYear] = useState<string>('선택');
  const [durationMonth, setDurationMonth] = useState<string>('선택');
  const [durationDay, setDurationDay] = useState<string>('선택');
  const [selectTime, setSelectTime] = useState<any>(null);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<number>(0);

  const choiceItems = document.querySelectorAll(`.${draggableName}`);
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const { mutate: postMissionQnaQuestionMutate, isError: isMutateError } =
    useMutation(postProgramMissionQnaQuestion, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('등록되었습니다.');
          onReloadMission(missionId);
        } else {
          setOpenSnackbar(true);
          setAlertMessage(
            `등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage('등록이 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    });

  const registerCurriculumMissionQnaQuestion = (uploadUrl: any) => {
    const newType = getQnaSurveyTypeNo(qnaType);
    const newImage =
      uploadUrl !== null
        ? uploadUrl
        : uploadQuestionFileName !== null && uploadQuestionFileName.length > 0
        ? uploadQuestionFileName
        : '';

    const newOptions = options.map((item: any, idx: number) => {
      const newData = {
        body: item.type === 'normal' ? item.title : '기타',
        isCustomAnswer: item.type === 'normal' ? 0 : 1,
        isCorrect: 0,
        seq: idx,
      };
      return newData;
    });

    const newMeasureInfo = {
      measureMin: startScale,
      measureMax: endScale,
      measureMinText: scaleStartInput,
      measureMaxText: scaleEndInput,
    };
    // (1-년 2-월 3-일 4-년월 5-년일 6-월일 7-년월일)
    const newDurationType = getDurationType(
      durationYear,
      durationMonth,
      durationDay
    );

    const tmpAnswerUnit = unitSelectorList.filter(
      (unit: any) => unit.type === selectUnit
    );
    const newAnswerUnit =
      tmpAnswerUnit !== null ? tmpAnswerUnit[0].title : directInputUnit;

    const newQuestionData = {
      weekId: survey.weekId,
      questionId: survey.questionId,
      qnaId: missionId,
      title: question,
      type: newType,
      seq: seq,
      image: newImage,
      isRequired: requiredAnswer ? 1 : 0,
      isUnitExist: selectUnit !== '선택' ? 1 : 0,
      answerUnit:
        selectUnit === '직접입력'
          ? directInputUnit
          : selectUnit !== '선택'
          ? newAnswerUnit
          : '',
      measureInfo: qnaType === 'scale' ? JSON.stringify(newMeasureInfo) : '',
      optionList:
        newOptions.length === 0
          ? ''
          : qnaType === 'choice'
          ? JSON.stringify(newOptions)
          : '',
      allowMultipleResponses: multipleAnswer ? 1 : 0,
      responsesCount: multipleAnswerCount,
      durationType: newDurationType,
    };
    console.log('postProgramMissionQnaQuestion :', newQuestionData);
    postMissionQnaQuestionMutate(newQuestionData);
  };

  async function uploadImage() {
    let uploadUrl = '';
    if (uploadQuestionFile !== null) {
      const newUploadFile: any = await uploadFile('qna', uploadQuestionFile);
      console.log('uploadImage :', newUploadFile);
      uploadUrl = newUploadFile.data.uploadUrl.file.path;
      setUploadQuestionFileName(uploadUrl);
    }

    registerCurriculumMissionQnaQuestion(uploadUrl);
  }

  const registerQuestion = () => {
    if (uploadQuestionFile === null) {
      registerCurriculumMissionQnaQuestion(null);
    } else {
      if (
        uploadQuestionFile !== null &&
        getFileSize(uploadQuestionFile) >= MAX_UPLOAD_FILE_SIZE
      ) {
        setOpenSnackbar(true);
        setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
        return;
      }
      uploadImage();
    }
  };

  function checkValid() {
    let isValid = 0;

    isValid = question.length === 0 ? 1 : 0;
    const newOptionList = options.filter(
      (option: any) => option.type === 'normal' && option.title.length === 0
    );
    console.log('newOptionList:', options);
    isValid =
      isValid === 0 &&
      qnaType === 'choice' &&
      newOptionList !== null &&
      newOptionList.length > 0
        ? 2
        : isValid;
    isValid =
      isValid === 0 &&
      qnaType === 'short' &&
      showUnitSelector &&
      selectUnit === '직접입력' &&
      (directInputUnit === undefined ||
        directInputUnit === null ||
        directInputUnit.length === 0)
        ? 3
        : isValid;
    isValid =
      isValid === 0 &&
      qnaType === 'short' &&
      showUnitSelector &&
      selectUnit === '선택'
        ? 4
        : isValid;
    isValid =
      isValid === 0 &&
      qnaType === 'scale' &&
      (scaleStartInput.length === 0 || scaleEndInput.length === 0)
        ? 5
        : isValid;
    isValid =
      isValid === 0 &&
      qnaType === 'duration' &&
      getDurationType(durationYear, durationMonth, durationDay) === 0
        ? 6
        : isValid;

    console.log('isValid :', isValid);
    if (isValid !== 0) {
      var message = '';
      switch (isValid) {
        case 1:
          message = '질문을 입력해 주세요.';
          break;
        case 2:
          message = '선택지를 입력해 주세요.';
          break;
        case 3:
          message = '단위를 입력해 주세요.';
          break;
        case 4:
          message = '단위를 선택해 주세요.';
          break;
        case 5:
          message = '척도 항목을 입력해 주세요.';
          break;
        case 6:
          message = '기간 답변란을 선택해 주세요';
          break;
        case 7:
          message = '답변 개수를 지정해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenSnackbar(true);
      setAlertMessage(message);

      return false;
    }
    return true;
  }

  // Drag & Drop Start ------------------------------------------------------
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: options,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setOptions(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    choiceItems.forEach((item: any) => item.classList.remove('drag_over'));
    // update form
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setOptions(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    choiceItems.forEach((item: any) => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    choiceItems.forEach((item: any) => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newPosition = Number(evt.currentTarget.dataset.position);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newPosition : null,
    });
  };
  // Drag & Drop End --------------------------------------------------------
  const {
    mutate: putMissionQnaQuestionIsRequiredMutate,
    isError: isRequriedMutateError,
  } = useMutation(putProgramMissionQnaQuestionIsRequired, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('변경되었습니다.');
      } else {
        setOpenSnackbar(true);
        setAlertMessage(
          `변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage('변경이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: deleteMissionQnaQuestionMutate,
    isError: isDeleteMutateError,
  } = useMutation(deleteProgramMissionQnaQuestion, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('삭제했습니다.');
        onReloadMission(missionId);
      } else {
        setOpenSnackbar(true);
        setAlertMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const { mutate: cloneMissionQnaQuestionMutate, isError: isCloneMutateError } =
    useMutation(cloneProgramMissionQnaQuestion, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('복사했습니다.');
          onReloadMission(missionId);
        } else {
          setOpenSnackbar(true);
          setAlertMessage(
            `복사하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage('복사가 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputType((event.target as HTMLInputElement).value);
  };

  const addOption = () => {
    if (options.length >= MAX_OPTION_COUNT) {
      setOpenSnackbar(true);
      setAlertMessage('선택지는 최대 10개까지 추가가 가능합니다.');
      return;
    }

    // const newOptionList = options.filter(
    //   (option: any) => option.title.length === 0
    // );
    // if (newOptionList !== null && newOptionList.length > 0) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('선택지 내용을 입력해 주세요.');
    //   return;
    // }

    const tmpOptions = options.filter((option: any) => option.type !== 'etc');
    let newOptions = [...tmpOptions, { type: 'normal', title: '' }];
    if (options.findIndex((option: any) => option.type === 'etc') !== -1) {
      newOptions = [...newOptions, { type: 'etc', title: '기타' }];
    }
    setOptions(newOptions);
  };

  const addEtcOption = () => {
    if (options.length >= MAX_OPTION_COUNT) {
      setOpenSnackbar(true);
      setAlertMessage('선택지는 최대 10개까지 추가가 가능합니다.');
      return;
    }

    // const newOptionList = options.filter(
    //   (option: any) => option.title.length === 0
    // );
    // if (newOptionList !== null && newOptionList.length > 0) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('선택지 내용을 입력해 주세요.');
    //   return;
    // }

    const newOptions = [...options, { type: 'etc', title: '기타' }];
    setOptions(newOptions);
  };

  const deleteOption = (selected: number) => {
    if (options.length === 1) {
      setOpenSnackbar(true);
      setAlertMessage('선택지는 최소 1개를 포함해야 합니다.');
      return;
    }
    const newOptions = options.filter(
      (option: any, idx: number) => idx !== selected
    );
    setOptions(newOptions);
  };

  const updateOption = (idx: number, newTitle: string) => {
    const newOptions = options.map((option: any, i: number) => {
      const newData =
        i === idx ? { type: option.type, title: newTitle } : option;
      return newData;
    });
    setOptions(newOptions);
  };

  const makeMultipleAnswer = (newOption: any) => {
    let newCountList: any[];
    if (newOption < 2) {
      newCountList = [{ title: '지정 안함', value: 0 }];
      setMultipleAnswerCountList(newCountList);
    } else {
      newCountList = [{ title: '지정 안함', value: 0 }];
      for (let i = 2; i <= newOption.length; i += 1) {
        newCountList.push({ title: i.toString(), value: i });
      }
      setMultipleAnswerCountList(newCountList);
    }
  };

  const cloneQuestion = () => {
    if (survey.questionId === undefined || survey.questionId === null) {
      onReloadMission(missionId);
      return;
    }

    const newData: any = {
      questionId: survey.questionId,
    };
    cloneMissionQnaQuestionMutate(newData);
  };

  const deleteQuestion = () => {
    if (survey.questionId === undefined || survey.questionId === null) {
      onReloadMission(missionId);
      return;
    }

    const newData: any = {
      qnaId: survey.missionId,
      questionId: survey.questionId,
    };
    // deleteMissionQnaQuestionMutate(newData);
    onDelete(survey.questionId);
  };

  const doConfirm = () => {
    setUploadQuestionFile(null);
    setUploadQuestionFileName('');
    setConfirmType(0);
  };

  const changeRequiredAnswer = (value: boolean) => {
    setRequiredAnswer(value);

    if (requiredAllAnswerInfo.requiredAll === 1 && value === false) {
      const newIds = requiredAllAnswerInfo.ids.filter(
        id => id !== survey.questionId
      );
      setRequiredAllAnswerInfo(prev => ({
        ...prev,
        ids: [...newIds],
        requiredAll: 0,
      }));
    } else if (requiredAllAnswerInfo.requiredAll === 0) {
      const newIds = requiredAllAnswerInfo.ids.filter(
        id => id !== survey.questionId
      );
      setRequiredAllAnswerInfo(prev => ({
        ...prev,
        ids: value ? [...newIds, survey.questionId] : [...newIds],
      }));
    }

    const newData = {
      questionIds: [survey.questionId],
      isRequired: value ? 1 : 0,
    };
    putMissionQnaQuestionIsRequiredMutate(newData);
  };

  useEffect(() => {
    console.log('startDates: ', startDates);
  }, [startDates]);

  useEffect(() => {
    console.log('selectTime: ', selectTime);
  }, [selectTime]);

  useEffect(() => {
    console.log(
      '> survey :',
      survey.questionId,
      ', lclEditMode :',
      lclEditMode,
      ',preEditMode:',
      preEditMode
    );
    let result = true;
    if (lclEditMode !== undefined) {
      if (lclEditMode === 'view' && preEditMode === 'edit') {
        result = checkValid();
        if (result) {
          registerQuestion();
        } else {
          onChangeEditMode('edit');
        }
      }
      if (result) {
        setPreEditMode(lclEditMode);
      } else {
        setLclEditMode('edit');
        setPreEditMode('view');
      }
    }
  }, [lclEditMode]);

  useEffect(() => {
    editMode === undefined ? setLclEditMode('view') : setLclEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    makeMultipleAnswer(options);

    const etcButton = options.filter((option: any) => option.type === 'etc');
    setHideEtcButton(!(etcButton === undefined || etcButton.length === 0));
  }, [options]);

  useEffect(() => {
    if (multipleAnswerCountList !== null && multipleAnswerCountList.length > 0)
      setMultipleAnswerCount(
        survey.responsesCount !== undefined
          ? survey.responsesCount
          : multipleAnswerCountList[0].value
      );
  }, [multipleAnswerCountList]);

  useEffect(() => {
    if (survey !== undefined && survey !== null) {
      console.log('survey:', survey);
      setQnaType(getQnaSurveyType(survey.type));
      setQuestion(survey.title);
      const optionData =
        survey.optionList !== undefined && survey.optionList !== null
          ? JSON.parse(survey.optionList)
          : [];
      const newOptions = optionData.map((item: any, idx: number) => ({
        type: item.isCustomAnswer === 0 ? 'normal' : 'etc',
        title: item.body,
        isCorrect: item.isCorrect,
        isCustomAnswer: item.isCustomAnswer,
      }));
      setOptions(newOptions);
      makeMultipleAnswer(newOptions);
      setMultipleAnswer(survey.allowMultipleResponses === 1);
      setMultipleAnswerCount(
        newOptions !== undefined && newOptions !== null
          ? survey.responsesCount
          : 0
      );
      setUploadQuestionFileName(survey.image);
      setSeq(survey.seq);
      setShowUnitSelector(survey.isUnitExist === 1);
      const newSelectUnit = unitSelectorList.filter(
        unit => unit.title === survey.answerUnit
      );
      setSelectUnit(
        survey.isUnitExist === undefined ||
          survey.isUnitExist === null ||
          survey.isUnitExist === 0
          ? '선택'
          : newSelectUnit !== null && newSelectUnit.length > 0
          ? newSelectUnit[0].type
          : '직접입력'
      );
      setDirectInputUnit(
        newSelectUnit === null || newSelectUnit.length === 0
          ? survey.answerUnit
          : ''
      );
      setRequiredAnswer(survey.isRequired === 1);
      setMissionId(survey.missionId);

      const newMeasureInfo =
        survey.measureInfo !== undefined &&
        survey.measureInfo !== null &&
        survey.measureInfo.length > 0
          ? JSON.parse(survey.measureInfo)
          : null;

      setStartScale(
        newMeasureInfo === null
          ? 1
          : newMeasureInfo.measure_min !== undefined
          ? newMeasureInfo.measure_min
          : newMeasureInfo.measureMin !== undefined
          ? newMeasureInfo.measureMin
          : 1
      );
      setEndScale(
        newMeasureInfo === null
          ? 2
          : newMeasureInfo.measure_max !== undefined
          ? newMeasureInfo.measure_max
          : newMeasureInfo.measureMax !== undefined
          ? newMeasureInfo.measureMax
          : 2
      );
      setScaleStartInput(
        newMeasureInfo === null
          ? ''
          : newMeasureInfo.measure_min_text !== undefined
          ? newMeasureInfo.measure_min_text
          : newMeasureInfo.measureMinText !== undefined
          ? newMeasureInfo.measureMinText
          : ''
      );
      setScaleEndInput(
        newMeasureInfo === null
          ? ''
          : newMeasureInfo.measure_max_text !== undefined
          ? newMeasureInfo.measure_max_text
          : newMeasureInfo.measureMaxText !== undefined
          ? newMeasureInfo.measureMaxText
          : ''
      );

      if (
        survey.durationType === 1 ||
        survey.durationType === 4 ||
        survey.durationType === 5 ||
        survey.durationType === 7
      )
        setDurationYear('년');
      if (
        survey.durationType === 2 ||
        survey.durationType === 4 ||
        survey.durationType === 6 ||
        survey.durationType === 7
      )
        setDurationMonth('개월');
      if (
        survey.durationType === 3 ||
        survey.durationType === 5 ||
        survey.durationType === 6 ||
        survey.durationType === 7
      )
        setDurationDay('일');
    }
  }, [survey]);

  return (
    <Paper
      sx={{
        mb: '1rem',
        background: lclEditMode === 'view' ? '#FFFFFF' : '#F7F8F9',
      }}
    >
      <Box className='flex_between'>
        <Box className='flex_start' sx={{ ml: '1rem', mt: '1rem' }}>
          <DragHandleIcon
            onClick={evt => {
              evt.stopPropagation();
              onChangeOrder();
            }}
          />
          <Typography className='title' sx={{ ml: '1rem' }}>
            {`질문 ${seq + 1} `}
            {requiredAnswer && <span style={{ color: '#F63454' }}>*</span>}
          </Typography>
        </Box>
        <Box sx={{ mt: '1rem' }}>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '.5rem 1rem .5rem' }}
              />
            }
          >
            <Box>
              <FormControlLabel
                disabled={disabled}
                value='start'
                control={
                  <Switch
                    color='primary'
                    checked={requiredAnswer}
                    value={requiredAnswer}
                    onClick={evt => {
                      evt.stopPropagation();
                    }}
                    onChange={() => {
                      changeRequiredAnswer(!requiredAnswer);
                    }}
                  />
                }
                label='필수응답'
                labelPlacement='start'
              />
            </Box>
            <Box className='flex_between'>
              <Button
                disabled={disabled}
                onClick={evt => {
                  evt.stopPropagation();
                  cloneQuestion();
                }}
              >
                <ContentCopy fontSize='small' color='info' />
              </Button>
              <Button
                disabled={disabled}
                onClick={evt => {
                  evt.stopPropagation();
                  deleteQuestion();
                }}
              >
                <ClearIcon fontSize='small' color='info' />
              </Button>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Divider sx={{ m: '0rem' }} />
      {lclEditMode === 'view' ? (
        <>
          <QnaBox className='flex_between'>
            <Typography>{question}</Typography>
          </QnaBox>
          {uploadQuestionFileName !== undefined &&
          uploadQuestionFileName !== null &&
          uploadQuestionFileName !== '' ? (
            <QnaBox className='flex_start'>
              <Box sx={{ m: '1rem 0' }}>
                <img
                  src={`${imageUrlPrefix}${uploadQuestionFileName}`}
                  alt={`${imageUrlPrefix}${uploadQuestionFileName}`}
                  style={{
                    maxWidth: '10vw',
                    borderRadius: '14px',
                    width: '10rem',
                    height: 'auto',
                  }}
                />
              </Box>
            </QnaBox>
          ) : (
            <></>
          )}
          <Divider />
        </>
      ) : lclEditMode === 'edit' ? (
        <>
          <QnaBox className='flex_between'>
            <TextField
              sx={{ width: '20%', mr: '1rem' }}
              select
              defaultValue={10}
              value={qnaType}
              onChange={evt => setQnaType(evt.target.value)}
            >
              {qnaSurveyTypeList.map(option => (
                <MenuItem key={`qna_${option.type}`} value={option.type}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: '80%' }}
              placeholder=''
              value={question}
              onChange={evt => {
                setQuestion(evt.target.value.substring(0, 100));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{question?.length} / 100</span>
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
          <QnaBox>
            <Button
              variant='contained'
              onClick={() => {
                const newFile1 = document.getElementById('surveyfile1');
                newFile1?.click();
              }}
            >
              <InsertPhotoOutlinedIcon color='secondary' />
              이미지 첨부
            </Button>
            <TextField
              sx={{ width: '28rem', m: '0 .5rem 0 1rem' }}
              value={
                uploadQuestionFile !== null
                  ? uploadQuestionFile?.name
                  : uploadQuestionFileName
              }
            />
            <Button
              sx={{ ml: '1rem' }}
              variant='outlined'
              onClick={() => {
                setConfirmType(1);
              }}
            >
              지우기
            </Button>
            <input
              className='nonedisplay'
              id='surveyfile1'
              type='file'
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                evt.target.files && setUploadQuestionFile(evt.target.files[0]);
              }}
            />
          </QnaBox>
          <Divider />
        </>
      ) : (
        <></>
      )}
      {qnaType === 'choice' && lclEditMode === 'view' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            {options.map((option: any, idx: number) => (
              <Box
                key={`choice_view_${idx}`}
                sx={{ mb: '1rem' }}
                className='flex_start'
              >
                <RadioButtonUncheckedIcon sx={{ color: '#D4D9E1' }} />
                {option.type === 'normal' ? (
                  <Typography sx={{ ml: '1rem' }}>{option.title}</Typography>
                ) : (
                  <TextField sx={{ ml: '1rem' }} value='기타' />
                )}
              </Box>
            ))}
          </QnaBox>
          <Divider />
        </>
      ) : qnaType === 'choice' && lclEditMode === 'edit' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            {options.map((option: any, idx: number) => (
              <DraggablePaper
                key={`choice_edit_${idx}`}
                className={draggableName}
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                onDragOver={dragOver}
                onDrop={drop}
                onDragLeave={dragLeave}
                onDragEnter={dragEnter}
                draggable
                data-position={idx}
                onClick={selectList}
                sx={{ width: '70%' }}
              >
                <Box className='flex_start' sx={{ width: '100%' }}>
                  <DragHandleIcon sx={{ color: '#9EA2A9' }} />
                  <RadioButtonUncheckedIcon sx={{ color: '#D4D9E1' }} />
                  <TextField
                    sx={{ width: '50%', m: '0 .5rem 0 1rem' }}
                    placeholder={
                      option.type === 'normal' ? '선택지 입력' : '기타'
                    }
                    disabled={option.type !== 'normal'}
                    value={option.type === 'normal' ? option.title : '기타'}
                    onChange={evt => {
                      updateOption(
                        idx,
                        option.type === 'normal'
                          ? evt.target.value.substring(0, 100)
                          : '기타'
                      );
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>{option.title?.length} / 100</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button color='info' onClick={() => deleteOption(idx)}>
                    삭제
                  </Button>
                </Box>
              </DraggablePaper>
            ))}
          </QnaBox>
          <QnaBox>
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              divider={
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ p: 0, m: '.5rem 1rem .5rem' }}
                />
              }
            >
              <Button color='info' onClick={() => addOption()}>
                <AddIcon fontSize='small' color='primary' />
                선택지 추가
              </Button>
              {hideEtcButton === false && (
                <Button color='info' onClick={() => addEtcOption()}>
                  <AddIcon fontSize='small' color='primary' />
                  ‘기타’ 항목 추가
                </Button>
              )}
            </Stack>
          </QnaBox>
          <Divider />
          <Box className='flex_end'>
            <Box sx={{ mr: '1rem' }}>
              <FormControlLabel
                value='start'
                control={
                  <Switch
                    color='primary'
                    checked={multipleAnswer}
                    value={multipleAnswer}
                    onChange={() => setMultipleAnswer(!multipleAnswer)}
                  />
                }
                label='복수 답변'
                labelPlacement='start'
              />
            </Box>
            {multipleAnswer && (
              <Box className='flex_between' sx={{ ml: '1rem' }}>
                <Typography>답변개수</Typography>
                <TextField
                  sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                  select
                  value={multipleAnswerCount}
                  onChange={evt =>
                    setMultipleAnswerCount(Number(evt.target.value))
                  }
                >
                  {multipleAnswerCountList.map((option: any, i: number) => (
                    <MenuItem
                      key={`qna_multiple_answer_${option.value}`}
                      value={option.value}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
          </Box>
        </>
      ) : qnaType === 'short' && lclEditMode === 'view' ? (
        <>
          <QnaBox
            className='flex_between'
            sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}
          >
            <Box sx={{ width: '50%' }} className='flex_start'>
              <TextField
                sx={{ width: '100%' }}
                placeholder='답변을 입력해주세요.'
                value={answer}
                onChange={evt => {
                  setAnswer(evt.target.value.substring(0, 30));
                }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      {showUnitSelector && selectUnit === '직접입력' ? (
                        <Typography>{directInputUnit}</Typography>
                      ) : (
                        <Typography>{selectUnit}</Typography>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </QnaBox>
        </>
      ) : qnaType === 'short' && lclEditMode === 'edit' ? (
        <>
          <QnaBox className='flex_start' sx={{ mt: '2rem' }}>
            <Box sx={{ width: '50%' }}>
              <TextField
                sx={{ width: '100%' }}
                placeholder='답변을 입력해주세요.'
                value={answer}
                onChange={evt => {
                  setAnswer(evt.target.value.substring(0, 30));
                }}
                InputProps={{
                  readOnly: true,
                  // endAdornment: (
                  //   <InputAdornment position='end'>
                  //     <span>{answer?.length} / 30</span>
                  //   </InputAdornment>
                  // ),
                }}
              />
            </Box>
            {showUnitSelector && selectUnit === '직접입력' ? (
              <TextField
                sx={{ ml: '1rem' }}
                placeholder='단위입력'
                value={directInputUnit}
                onChange={evt => {
                  setDirectInputUnit(evt.target.value);
                }}
              />
            ) : (
              <></>
            )}
            {showUnitSelector && (
              <Box>
                <TextField
                  sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                  select
                  defaultValue={selectUnit}
                  value={selectUnit}
                  onChange={evt => setSelectUnit(evt.target.value)}
                >
                  {unitSelectorList.map((option: any, i: number) => (
                    <MenuItem
                      key={`qna_unit_${option.type}`}
                      value={option.type}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
          </QnaBox>
          <QnaBox>
            <FormControlLabel
              sx={{ mb: '2rem' }}
              value='start'
              control={
                <Switch
                  color='primary'
                  checked={showUnitSelector}
                  value={showUnitSelector}
                  onChange={() => {
                    setSelectUnit(INIT_UNIT_SELECTOR);
                    setShowUnitSelector(!showUnitSelector);
                  }}
                />
              }
              label='단위'
              labelPlacement='start'
            />
          </QnaBox>
        </>
      ) : qnaType === 'long' && lclEditMode === 'view' ? (
        <>
          <QnaBox className='flex_between' sx={{ mt: '2rem' }}>
            <Box sx={{ width: '90%', mb: '2rem' }}>
              <TextField
                fullWidth
                multiline
                rows={5}
                placeholder='답변을 입력해주세요.'
                value={answer}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                    padding: '.5rem',
                  },
                }}
              />
            </Box>
          </QnaBox>
        </>
      ) : qnaType === 'long' && lclEditMode === 'edit' ? (
        <>
          <QnaBox className='flex_between' sx={{ mt: '2rem' }}>
            <Box sx={{ width: '90%', mb: '2rem' }}>
              <TextField
                fullWidth
                multiline
                rows={5}
                placeholder='답변을 입력해주세요.'
                value={answer}
                onChange={evt => {
                  setAnswer(evt.target.value.substring(0, 500));
                }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>{answer?.length} / 500</span>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    height: 'auto',
                    padding: '.5rem',
                  },
                }}
              />
            </Box>
          </QnaBox>
        </>
      ) : qnaType === 'scale' && lclEditMode === 'view' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <Box className='flex_start'>
              <Box>
                <Typography sx={{ ml: '1rem', width: '7rem' }}>
                  {scaleStartInput}
                </Typography>
              </Box>
              {Array.from(
                { length: endScale - startScale + 1 },
                (_, index) => startScale + index
              ).map((item: any, idx: number) => (
                <FormControlLabel
                  key={`scale_view_${idx}`}
                  value={item}
                  control={<Radio />}
                  label={item}
                  labelPlacement='top'
                />
              ))}
              <Box>
                <Typography sx={{ ml: '1rem', width: '7rem' }}>
                  {scaleEndInput}
                </Typography>
              </Box>
            </Box>
          </QnaBox>
        </>
      ) : qnaType === 'scale' && lclEditMode === 'edit' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            <TextField
              sx={{ width: '10rem', mr: '1rem' }}
              select
              defaultValue={1}
              value={startScale}
              onChange={evt => setStartScale(Number(evt.target.value))}
            >
              {[1].map((option: any, i: number) => (
                <MenuItem key={`qna_scale_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            ~
            <TextField
              sx={{ width: '10rem', mr: '1rem' }}
              select
              defaultValue={endScale}
              value={endScale}
              onChange={evt => setEndScale(Number(evt.target.value))}
            >
              {[2, 3, 4, 5, 6, 7].map((option: any, i: number) => (
                <MenuItem key={`qna_unit_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </QnaBox>
          <QnaBox>
            <Box>
              <Typography className='title'>{startScale}</Typography>
            </Box>
            <Box>
              <TextField
                sx={{ width: '70%' }}
                placeholder='항목 입력'
                value={scaleStartInput}
                onChange={evt => {
                  setScaleStartInput(evt.target.value.substring(0, 30));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>{scaleStartInput?.length} / 30</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Box>
              <Typography className='title'>{endScale}</Typography>
            </Box>
            <Box>
              <TextField
                sx={{ width: '70%', mb: '2rem' }}
                placeholder='항목 입력'
                value={scaleEndInput}
                onChange={evt => {
                  setScaleEndInput(evt.target.value.substring(0, 30));
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>{scaleEndInput?.length} / 30</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </QnaBox>
        </>
      ) : qnaType === 'date' && lclEditMode === 'view' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <TextField
              sx={{ width: '18rem', mb: '2rem' }}
              disabled
              value='0000년 00월 00일'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccessTimeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'date' && lclEditMode === 'edit' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            <TextField
              sx={{ width: '18rem', mb: '2rem' }}
              disabled
              value='0000년 00월 00일'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <AccessTimeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'datepoint' && lclEditMode === 'view' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value='0000년 00월 00일 오전/오후 00시 00분'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'datepoint' && lclEditMode === 'edit' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value='0000년 00월 00일 오전/오후 00시 00분'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'point' && lclEditMode === 'view' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value='오전/오후 00시 00분'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'point' && lclEditMode === 'edit' ? (
        <>
          <QnaBox sx={{ mt: '2rem', height: '70px' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value='오전/오후 00시 00분'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'duration' && lclEditMode === 'view' ? (
        <>
          <QnaBox sx={{ padding: '0 0 1rem 0rem' }}>
            {durationYear !== '선택' && (
              <TextField
                sx={{ width: '10rem', mr: '1rem' }}
                value={durationYear}
              />
            )}
            {durationMonth !== '선택' && (
              <TextField
                sx={{ width: '10rem', mr: '1rem' }}
                value={durationMonth}
              />
            )}
            {durationDay !== '선택' && (
              <TextField
                sx={{ width: '10rem', mr: '1rem' }}
                value={durationDay}
              />
            )}
          </QnaBox>
        </>
      ) : qnaType === 'duration' && lclEditMode === 'edit' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            <Typography>답변 입력시, 보여질 입력란을 선택해 주세요.</Typography>
          </QnaBox>
          <QnaBox>
            <TextField
              sx={{ width: '10rem', mr: '1rem' }}
              select
              value={durationYear}
              onChange={evt => setDurationYear(evt.target.value)}
            >
              {['선택', '년'].map((option: any, i: number) => (
                <MenuItem key={`qna_duration_year_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: '10rem', mr: '1rem' }}
              select
              value={durationMonth}
              onChange={evt => setDurationMonth(evt.target.value)}
            >
              {['선택', '개월'].map((option: any, i: number) => (
                <MenuItem key={`qna_duration_month_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: '10rem', mr: '1rem', mb: '2rem' }}
              select
              value={durationDay}
              onChange={evt => setDurationDay(evt.target.value)}
            >
              {['선택', '일'].map((option: any, i: number) => (
                <MenuItem key={`qna_duration_day_${option}`} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </QnaBox>
        </>
      ) : qnaType === 'time' && lclEditMode === 'view' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value='00시간 00분'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : qnaType === 'time' && lclEditMode === 'edit' ? (
        <>
          <QnaBox sx={{ mt: '2rem', height: '70px' }}>
            <TextField
              sx={{ width: '30rem', mb: '2rem' }}
              disabled
              value='00시간 00분'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <CalendarTodayOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
        </>
      ) : (
        <></>
      )}
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwConfirmDialog
        contents={confirmType === 1 ? '이미지를 삭제하시겠습니까?' : ''}
        open={confirmType !== 0}
        onClose={() => setConfirmType(0)}
        onConfirm={() => {
          doConfirm();
        }}
        confirm={1}
      />
    </Paper>
  );
};
