import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

export const getQuestionAnswerRequestList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/creation-request?operator=${data.operator}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}&hasReward=${data.hasReward}&type=${data.type}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRequestListByOperator = () =>
  xapi
    .get(`/admin/v2/apis/question-answer/information/creation-request-operator`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&hasReward=${data.hasReward}&type=${data.type}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const confirmQuestionAnswerRegister = (data: any) =>
  xapi
    .post(`/admin/v2/apis/question-answer/information/${data.qnaId}/confirm`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeQuestionAnswerApplyStatus = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/apply-status/${data.status}?message=${data.message}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeQuestionAnswerMasterStatus = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/status/${data.status}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeQuestionAnswerMasterHasReward = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/hasReward/${data.hasReward}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerQuestionAnswerMaster = (data: any) =>
  xapi
    .post(`/admin/v2/apis/question-answer/information/master`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const cloneQuestionAnswerMaster = (data: any) =>
  xapi
    .post(`/admin/v2/apis/question-answer/information/clone`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const cloneQuestionAnswerMission = (data: any) =>
  xapi
    .post(`/admin/v2/apis/question-answer/information/mission/clone`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadQuestionAnswerList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/download/list?status=${data.status}&startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `question_answer_list.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });
/*
 * Filter
 */
export const getQuestionAnswerFilterInfo = (targetId: any) =>
  xapi
    .get(`/admin/v2/apis/question-answer/information/filter/${targetId}/info`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerCommunityFilterList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/filter/community/${data.type}/list?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerCommunityFilterInfo = (targetId: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/filter/community/${targetId}/info`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerCommunityFilterLoadList = (targetId: any) =>
  xapi
    .get(`/admin/v2/apis/question-answer/information/filter/${targetId}/load`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerQuestionAnswerFilter = (data: any) =>
  xapi
    .post(`/admin/v2/apis/question-answer/information/filter`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
/*
 * Reward
 */
export const deleteQuestionAnswerReward = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/reward/${data.rewardId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardList = (qnaId: string) =>
  xapi
    .get(`/admin/v2/apis/question-answer/information/${qnaId}/reward`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardUserList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/reward/${data.rewardId}/recipient?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardDrawUserListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.programId}/reward/${data.rewardId}/draw-user/byPhonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardDrawingResult = (
  type: string,
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/reward/${rewardId}/draw/result/${type}?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardDrawingApply = (
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/reward/${rewardId}/draw/apply?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerAttendUserList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/attend-user?searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerQuestionAnswerReward = (data: any) =>
  xapi
    .post(`/admin/v2/apis/question-answer/information/reward-one`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const questionAnswerDrawManualCoupon = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/reward/${data.rewardId}/manual-coupon-draw/${data.drawType}`,
      {
        userIds: data.userIds,
        point: data.point,
        message: data.message,
      }
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const questionAnswerDrawManualTicket = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/reward/${data.rewardId}/manual-ticket-draw/${data.drawType}`,
      {
        userIds: data.userIds,
        point: data.point,
        message: data.message,
      }
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerGoalAchieverList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/reward/${data.rewardId}/goal-achiever/${data.type}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerUserListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/attend-user/byPhonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postQuestionAnswerRewardDrawSubmit = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/reward/${data.rewardId}/draw/submit`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardDrawUserList = (
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/reward/${rewardId}/draw/user?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postQuestionAnswerRewardDrawCondition = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/reward/${data.rewardId}/draw/condition`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postQuestionAnswerRewardDrawDrawing = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/reward/${data.rewardId}/draw/drawing`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postQuestionAnswerRewardChangeDrawResult = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/reward/${data.rewardId}/change/draw-result`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postQuestionAnswerRewardResetDrawResult = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/reward/${data.rewardId}/reset/draw-result`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardDrawUserDownload = (
  rewardId: string,
  type: string
) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/reward/${rewardId}/draw/user-download/${type}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `questionAnswer_draw_user_${type}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getQuestionAnswerRewardSubmitUserDownload = (
  rewardId: string,
  type: string
) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/reward/${rewardId}/submit/user-download/${type}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `questionAnswer_submit_user_${type}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

/*
 * Reward Drawing
 */

export const getQuestionAnswerRewardDrawList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/reward/draw?operator=${data.operator}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardDrawDetailInfo = (
  requestType: string,
  qnaId: string,
  rewardId: string
) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${qnaId}/reward/${rewardId}/draw?request-type=${requestType}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardDrawDetailList = (
  requestType: string,
  qnaId: string
) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${qnaId}/reward/draw/detail?request-type=${requestType}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRewardDrawingResultDownload = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/reward/${data.rewardId}/draw/result-download/${data.type}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `draw_result_${data.type}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

/*
 * Member
 */

export const getUserListWithFilter = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/users?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerMemberList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/member?startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerMemberListByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/member-by-phonenos`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postQuestionAnswerMember = (data: any) =>
  xapi
    .post(`/admin/v2/apis/question-answer/information/${data.qnaId}/member`, {
      userIds: data.userIds,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postQuestionAnswerMemberByPhonenos = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/member-by-phonenos`,
      {
        phonenos: data.phonenos,
      }
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteQuestionAnswerMember = (data: any) =>
  xapi.put(
    `/admin/v2/apis/question-answer/information/${data.qnaId}/member/delete`,
    {
      userIds: data.userIds,
    }
  );

export const downloadQuestionAnswerMemberList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/member/download?type=${data.type}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'question_answer_member.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

/*
 * Result
 */
export const getQuestionAnswerResultList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/result?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerUserAnswerList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/user/${data.userId}/answer`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getQuestionAnswerRecipientUserList = (data: any) =>
  xapi
    .get(`/admin/v2/apis/question-answer/information/${data.qnaId}/recipient`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeQuestionAnswerRecipientUser = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/recipient`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const addQuestionAnswerAttendUser = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/attend-user/add`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadQuestionAnswerAttendUser = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/attend-user/download`,
      {
        responseType: 'blob',
      }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `question_answer_attend_user_${data.downloadType}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadQuestionAnswerQuestionList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/question-answer/information/${data.qnaId}/user/${data.userId}/question/download`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `question_answer_question_list.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });
