import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { StampTourForm } from './StampTourForm';
import { StamptourMap } from './StamptourMap';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import {
  IStamptourDetail,
  IStamptourInfo,
  DefaultStamptourInfo,
  DefaultStamptourDetail,
} from '../../../models/stamptour';
import {
  getStamptourList,
  getStamptourRequestDetail,
} from '../../../common/api/ApiStamptour';
import {
  KEY_STAMPTOUR_LIST,
  KEY_STAMPTOUR_REQUEST_DETAIL,
} from '../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';

const requestColumnlists = [
  '개설 기관명',
  '스탬프 코스 이름',
  '추천코스 노출',
  '생성일시',
];

const selectVal = [
  {
    title: '코스 이름',
    value: 'name',
  },
  { title: '코스 아이디', value: 'id' },
  { title: '개설 기관명', value: 'company' },
];

interface Props {}

export const StampTourInquire: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const selectRef = useRef<any>(0);
  const searchRef = useRef<any>();
  const [commandType, setCommandType] = useState(0);
  const [mapReload, setMapReload] = useState(0);
  const [mapInfo, setMapInfo] = useState<any>();
  const [gpsData, setGpsData] = useState<any>([]);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [stamptourId, setStamptourId] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchWord, setSearchWord] = useState<string>('');
  const [stamptourList, setStamptourList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [stamptourData, setStamptourData] = useState<any>();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [stamptourDetail, setStamptourDetail] = useState<IStamptourDetail>(
    DefaultStamptourDetail
  );
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: stampTourRefetch,
  } = useQuery(
    KEY_STAMPTOUR_LIST,
    () => {
      if (searchKey !== '') {
        const newData = {
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
        };
        return getStamptourList(newData);
      }
      return false;
    },
    {
      onSuccess: res => {
        console.log('StamptourInquire  res:', res);
        const newStamptour =
          res &&
          res.stampTour &&
          res.stampTour.map((item: any, idx: number) => {
            const newDate = timestamp2Localestring(item.createTime, 1000);
            const newCreatorName = item.creatorName
              ? item.creatorName
              : item.creator;
            const show = item.stampTourShow === 1 ? '노출' : '-';

            const newData = [newCreatorName, item.stampTourName, show, newDate];
            return newData;
          });
        setRequestList(newStamptour);
        setStamptourList(res ? res.stampTour : []);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const stamptourPrefetchPage = (newPage: number) => {
    queryClient.prefetchQuery(KEY_STAMPTOUR_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getStamptourList(newData);
    });
  };

  const stamptourPrefetch = (newSearchKey: string, newSearchWord: string) => {
    queryClient.prefetchQuery(KEY_STAMPTOUR_LIST, () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: newSearchKey,
        searchWord: encodeSearchWord(newSearchKey, newSearchWord),
      };
      return getStamptourList(newData);
    });
  };

  const { refetch: stamptourRequestDetailRefetch } = useQuery(
    KEY_STAMPTOUR_REQUEST_DETAIL,
    () => {
      console.log('stamptourId:', stamptourId);
      if (stamptourId.length > 0) return getStamptourRequestDetail(stamptourId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          const tmpRoute = res.creationRequestDetail.stamp.map((stamp: any) => {
            const newDesc: any[] = [];
            if (stamp.description === null) {
              newDesc.push({
                description:
                  stamp.stampDescription !== null ? stamp.stampDescription : '',
                image:
                  stamp.stampImgpathMain !== null ? stamp.stampImgpathMain : '',
                idx: 1,
              });
            }
            const temp = {
              id: stamp.stampId,
              name: stamp.stampName,
              lat: stamp.stampPointLat,
              lng: stamp.stampPointLng,
              radius: stamp.stampRadius,
              description:
                stamp.description !== null
                  ? stamp.description
                  : JSON.stringify(newDesc),
              image: stamp.stampImgpathMain,
              className: stamp.className,
              link: stamp.link,
              phoneNo: stamp.phoneNo,
              address: stamp.address,
              stampAddress: stamp.stampAddress,
            };
            return temp;
          });
          console.log('stamp :', tmpRoute);
          setGpsData(tmpRoute);

          const newCircleData = {
            lat: res.creationRequestDetail.stamptour.courseCenterPointY,
            lng: res.creationRequestDetail.stamptour.courseCenterPointX,
            radius: res.creationRequestDetail.stamptour.courseCenterRadius,
          };

          const newCourseData = {
            gpsData: tmpRoute,
            circleData: newCircleData,
          };
          setStamptourData(newCourseData);

          setStamptourDetail(res.creationRequestDetail);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newStamptourId: string) => {
    queryClient.prefetchQuery(KEY_STAMPTOUR_REQUEST_DETAIL, () =>
      getStamptourRequestDetail(newStamptourId)
    );
  };

  useEffect(() => {
    setMapReload(1);
    return () => {
      setMapReload(0);
    };
  }, []);

  const isSearching = () => {
    if (searchRef.current.value && selectRef.current.value) {
      setSearchKey(selectRef.current.value);
      setSearchWord(searchRef.current.value);
    } else {
      setSearchKey('');
      setSearchWord('');
    }
  };

  const onReload = () => {
    stamptourPrefetch(searchKey, searchWord);
  };

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[0], Chosen[1], Chosen[2], Chosen[4]];
    // setIsSend(false);
    setChosenRequest([newChosen]);
    setStamptourId(stamptourList[i].stampTourId);
    prefetchDetail(stamptourList[i].stampTourId);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    stamptourPrefetchPage(newPage);
  };

  const getFocusIndex = () => {
    const newIndex = stamptourList
      .map((item: any, idx: number) =>
        item.stampTourId === stamptourId ? idx : -1
      )
      .filter((item: any) => item !== -1);
    const index = newIndex !== null && newIndex.length > 0 ? newIndex[0] : -1;
    return index;
  };

  useEffect(() => {
    console.log('searchKey :', searchKey, ', searchWord:', searchWord);
    stamptourPrefetch(searchKey, searchWord);
  }, [searchKey, searchWord]);

  useEffect(() => {
    if (mapInfo) {
      console.log('WalkcourseInquire mapInfo :', mapInfo);
      const newStamptour = stamptourDetail.stamptour;
      const newStamptourDetail = {
        stamptour: newStamptour,
        stamp: stamptourDetail.stamp,
      };
      console.log('StamptourInquire newStamptourDetail :', newStamptourDetail);
      setStamptourDetail(newStamptourDetail);
      setGpsData(mapInfo.stamp);
    }
  }, [mapInfo]);

  return (
    <>
      <Box className='root_box'>
        <Box className='flex_between'>
          <SelectSearchBox
            searchOption={selectVal}
            searchWord={searchRef}
            defaultOption='name'
            isSearching={isSearching}
            optionValue={selectRef}
          />
        </Box>
        <Paper sx={{ p: '1.5rem', m: '1.4rem 0' }}>
          <Box sx={{ mb: '1rem' }}>
            <RequestTable
              columns={requestColumnlists}
              rows={requestList}
              onclick={chooseRequest}
              getFocusIndex={getFocusIndex}
            />
          </Box>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
          <StamptourMap
            stamptourData={stamptourData}
            mapInfo={mapInfo}
            setMapInfo={setMapInfo}
          />
          <StampTourForm
            commandType={commandType}
            setCommandType={setCommandType}
            mapInfo={mapInfo}
            stamptourDetail={stamptourDetail}
            setStamptourDetail={setStamptourDetail}
            onReload={onReload}
          />
        </Paper>
        <Box className='flex_end' sx={{ m: '2rem 0' }}>
          <Box>
            <Button
              variant='outlined'
              color='info'
              sx={{ mr: '1rem' }}
              onClick={() => setOpenDelete(true)}
            >
              삭제
            </Button>
            <Button
              variant='contained'
              color='info'
              onClick={() => setCommandType(2)}
            >
              저장
            </Button>
          </Box>
        </Box>
      </Box>
      <SwConfirmDialog
        contents='스탬프 코스를 삭제하시겠습니까?'
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setCommandType(3);
          setOpenDelete(false);
        }}
        confirm={1}
      />
    </>
  );
};
