import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
} from './ContentManageStyles';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { registerContentManageContent } from '../../../common/api/ApiContentManage';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import { ContentManageContentMovieForm } from './ContentManageContentMovieForm';
import { ContentManageContentMagazineForm } from './ContentManageContentMagazineForm';
import { ContentManageContentQnaForm } from './ContentManageContentQnaForm';
import { ContentManageContentLifeStyleForm } from './ContentManageContentLifeStyleForm';
import { ContentManageContentAudioForm } from './ContentManageContentAudioForm';

interface Props {
  contentManageInfo: any;
  setContentManageInfo: Dispatch<React.SetStateAction<any>>;
  reloadContentManage: (id: any) => void;
}

export const ContentManageContentForm: React.FC<Props> = ({
  contentManageInfo,
  setContentManageInfo,
  reloadContentManage,
}) => {
  const location = useLocation();
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [status, setStatus] = useState<number>(0);
  const [selectedContentManage, setSelectedContentManage] =
    useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  return (
    <>
      {(contentManageInfo.type === 1 ||
        contentManageInfo.type === 2 ||
        contentManageInfo.type === 4 ||
        contentManageInfo.type === 6) && (
        <CreatePaper>
          <Box>
            {contentManageInfo.type === 1 && (
              <ContentManageContentMovieForm
                contentManageInfo={contentManageInfo}
                setContentManageInfo={setContentManageInfo}
                reloadContentManage={reloadContentManage}
              />
            )}
            {contentManageInfo.type === 2 && (
              <ContentManageContentMagazineForm
                contentManageInfo={contentManageInfo}
                setContentManageInfo={setContentManageInfo}
                reloadContentManage={reloadContentManage}
              />
            )}
            {contentManageInfo.type === 4 && (
              <ContentManageContentLifeStyleForm
                contentManageInfo={contentManageInfo}
                setContentManageInfo={setContentManageInfo}
                reloadContentManage={reloadContentManage}
              />
            )}
            {contentManageInfo.type === 6 && (
              <ContentManageContentAudioForm
                contentManageInfo={contentManageInfo}
                setContentManageInfo={setContentManageInfo}
                reloadContentManage={reloadContentManage}
              />
            )}
            <Divider />
          </Box>
        </CreatePaper>
      )}
      {(contentManageInfo.type === 3 || contentManageInfo.type === 5) && (
        <ContentManageContentQnaForm
          contentManageInfo={contentManageInfo}
          setContentManageInfo={setContentManageInfo}
          reloadContentManage={reloadContentManage}
        />
      )}
    </>
  );
};
