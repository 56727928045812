import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import SearchIcon from '@mui/icons-material/Search';

import {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SyntheticEvent,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import clsx from 'clsx';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import { useSession } from '../../../hooks/session';
import { useResize } from '../../../hooks/resize';
import { RequestPagingTable } from '../../commonComponent/table/SwRequestPagingTable';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwRequestTable } from '../../commonComponent/table/SwRequestTable';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  getDataPortalConnectPopupList,
  deleteDataPortalConnectPopup,
  getPortalGuideManageMap,
  getPortalGuideManageContentList,
} from '../../../common/api/ApiDataPortal';
import {
  KEY_CONTENT_MAMANGE_CONTENT_LIST,
  KEY_CONTENT_MAMANGE_MAP,
  KEY_DATAPORTAL_CONNECT_POPUP_LIST,
  KEY_DATAPORTAL_GUIDE_MAMANGE_CONTENT_LIST,
  KEY_DATAPORTAL_GUIDE_MAMANGE_MAP,
} from '../../../common/key';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  getContentManageContentList,
  getContentManageMap,
} from '../../../common/api/ApiContentManage';
import { MultilineTextField, TabBox } from '../../styles/Styles';
import { SwSideTab } from '../../commonComponent/SwSideTab';
import { GuideManageDrawup } from './GuideManageDrawup';

interface Props {}

const columns = ['카테고리', '제목', '작성기간'];

const typeItem = ['이미지', '작성자', '공개 시작일시', '공개 종료일시'];

export const PortalGuideList: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { loginVal } = useSession();
  const putInputRef = useRef<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [categoryId, setCategoryId] = useState<string>('');
  const [targetCategory, setTargetCategory] = useState<string>('all');
  const [contentManageList, setContentManageList] = useState<any>([]);
  const [contentManageInfo, setContentManageInfo] = useState<any>({
    idx: '',
    title: '',
    content: '',
    createDate: 0,
  });
  const [request, setRequest] = useState<boolean>(false);
  const [selectedGuide, setSelectedGuide] = useState<any>();
  const [selectedNode, setSelectedNode] = useState<any>();
  const [contentCount, setContentCount] = useState<number>(0);
  const [contentManageMap, setContentManageMap] = useState<any[]>([]);
  const [openNav, setOpenNav] = useState<boolean>(true);
  const [param, setParam] = useState<string>('');
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openContentEditor, setOpenContentEditor] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  const showStatusMessage = (type: number, count: number) => {
    setStatusMessage(
      type === 1
        ? `${count}개가 선택되었습니다.`
        : `총 ${count}건의 검색 결과가 있습니다.`
    );
  };

  const {
    data,
    isLoading,
    refetch: portalGuideManageMapRefetch,
  } = useQuery(
    KEY_DATAPORTAL_GUIDE_MAMANGE_MAP,
    () => {
      const newRequest = {
        sortWay: 1, // asc
      };
      if (request === true) return getPortalGuideManageMap(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.operationGuide !== undefined) {
          setContentCount(res.operationGuide.count);
          setContentManageMap(res.operationGuide.map);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: contentManageContentRefetch } = useQuery(
    KEY_DATAPORTAL_GUIDE_MAMANGE_CONTENT_LIST,
    () => {
      const newRequest = {
        categoryId: categoryId,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: searchWord,
        sortWay: 1, // asc
      };
      if (checkRequest && categoryId.length > 0)
        return getPortalGuideManageContentList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          setContentManageList(res ? res.contentManage : []);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchContent = (
    newCategoryId: string,
    newPage: number,
    newSearchWord: string
  ) => {
    const newRequest = {
      categoryId: newCategoryId,
      page: newPage,
      rowsPerPage: rowsPerPage,
      searchKey: searchKey,
      searchWord: newSearchWord,
      sortWay: 1, // asc
    };
    queryClient.prefetchQuery(KEY_DATAPORTAL_GUIDE_MAMANGE_CONTENT_LIST, () =>
      getPortalGuideManageContentList(newRequest)
    );
  };

  const doSelected = (node: any) => {
    console.log('selected :', node);
    if (node !== undefined && node !== null && node.nodeType === 'node') {
      setSelectedNode(node);
      setCategoryId(node.id);
      const newSearchWord = putInputRef.current?.value;
      setSearchWord(newSearchWord);

      if (targetCategory !== 'all') {
        if (node.menuPath.length === 3 && targetCategory !== node.title) {
          const a = 1;
        } else {
          prefetchContent(node.id, 1, newSearchWord);
        }
      } else {
        prefetchContent(node.id, 1, newSearchWord);
      }
    } else {
      node && setSelectedNode(node.node);
      setContentManageList([]);
      setTotalRecords(0);
    }
  };

  const clickRow = (idx: number) => {
    setEdit(true);
    setSelectedGuide(contentManageList[idx]);
    setOpenContentEditor(true);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetchContent(categoryId, newPage, searchWord);
  };

  const searchGuideContent = () => {
    setCategoryId('');
    setSearchWord(putInputRef.current?.value);
    prefetchContent('', 1, putInputRef.current?.value);
  };

  const handleKeyDown = (e: any, value: number) => {
    if (e.key === 'Enter') {
      setCategoryId('');
      searchGuideContent();
    }
  };

  useEffect(() => {
    if (request) portalGuideManageMapRefetch();
  }, [request]);

  useEffect(() => {
    setRequest(true);
  }, []);

  return (
    <div>
      <Helmet>
        <title>포털 설명서 관리</title>
      </Helmet>
      <Paper sx={{ m: '1rem', p: '1.5rem' }}>
        <Box>
          <TextField
            placeholder='제목으로 검색해 주세요.'
            inputRef={putInputRef}
            onKeyDown={evt => {
              handleKeyDown(evt, 0);
            }}
            sx={{ width: '30rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => searchGuideContent()}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '.5rem 2rem 0' }}
            />
          }
        >
          <Box sx={{ height: '40rem' }}>
            <SwSideTab
              menuMap={contentManageMap}
              openNav={openNav}
              setOpenNav={setOpenNav}
              param={param}
              onSelected={doSelected}
              count={contentCount}
            />
          </Box>
          <Box sx={{ width: '80%' }}>
            <Box sx={{ mb: '.5rem' }}>
              {categoryId !== '' && totalRecords > 0 ? (
                <Typography sx={{ fontWeight: 'bold', mr: '1rem' }}>
                  {`${selectedNode.title} `}
                  <span style={{ color: '#2581FF' }}>{totalRecords}</span>개
                </Typography>
              ) : categoryId === '' ? (
                <Typography sx={{ fontWeight: 'bold', mr: '1rem' }}>
                  {`'${searchWord}' 검색결과 `}
                  <span style={{ color: '#2581FF' }}>{totalRecords}</span>개
                </Typography>
              ) : (
                ''
              )}
            </Box>
            <TableContainer sx={{ height: '90%' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {columns.map((column, idx) => (
                      <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {contentManageList !== null &&
                  contentManageList.length > 0 ? (
                    contentManageList?.map((row: any, idx: number) => (
                      <TableRow
                        key={`template_code_${idx}`}
                        onClick={() => clickRow(idx)}
                      >
                        <TableCell sx={{ p: '.5rem .6rem' }}>
                          {row.categoryName}
                        </TableCell>
                        <TableCell sx={{ p: '.5rem .6rem' }}>
                          {row.title}
                        </TableCell>
                        <TableCell sx={{ p: '.5rem .6rem' }}>
                          {timestamp2Localestring(row.createDate, 1000)}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <TableRow>
                        <TableCell colSpan={1}>
                          등록된 콘텐츠가 없습니다.
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {contentManageList !== undefined &&
              contentManageList.length > 0 &&
              totalRecords && (
                <SwPagination
                  page={page}
                  handleChangePage={handleChangePage}
                  count={
                    contentManageList && Math.ceil(totalRecords / rowsPerPage)
                  }
                />
              )}
          </Box>
        </Stack>
        <Box className='flex_end'>
          <Button
            color='info'
            variant='contained'
            onClick={() => {
              setEdit(false);
              setOpenContentEditor(true);
            }}
          >
            작성하기
          </Button>
        </Box>
      </Paper>
      <GuideManageDrawup
        open={openContentEditor}
        onClose={() => {
          setOpenContentEditor(false);
          setSelectedGuide(contentManageInfo);
        }}
        onConfirm={() => {
          setOpenContentEditor(false);
          portalGuideManageMapRefetch();
          prefetchContent(categoryId, 1, searchWord);
        }}
        edit={edit}
        isEdit={() => setEdit(!edit)}
        guide={selectedGuide}
        menuMap={contentManageMap}
      />
    </div>
  );
};
