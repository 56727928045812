import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IOperationGuide } from '../../models/operation';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Operation
 */

export const getOperationGuideList = (data: IOperationGuide) =>
  xapi
    .get(
      `/admin/v2/apis/operation/operation-guide/${data.type}?page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const migrationOperationGuide = () =>
  xapi.get(`/admin/v2/apis/operation/migration`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postOperationGuide = (data: IOperationGuide) =>
  xapi.post(`/admin/v2/apis/operation/operation-guide/${data.type}`, {
    title: data.title,
    content: data.content,
    image: data.image,
  });

export const putOperationGuide = (data: IOperationGuide) =>
  xapi.put(`/admin/v2/apis/operation/operation-guide/${data.idx}`, {
    title: data.title,
    content: data.content,
    image: data.image,
  });

export const putOperationGuideViewCount = (data: IOperationGuide) =>
  xapi.put(`/admin/v2/apis/operation/operation-guide/${data.idx}/view`, data);

export const deleteOperationGuide = (data: IOperationGuide) =>
  xapi.post(`/admin/v2/apis/operation/operation-guide/delete`, data);
