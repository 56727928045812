import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IReward, IRewardDrawing } from '../../models/reward';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Reward
 */

export const getRewardDrawChallengeList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/reward/draw/challenge?operator=${data.operator}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardDrawChallengeListByOperator = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/reward/draw/challenge-operator?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardDrawChallengeDetailInfo = (
  requestType: string,
  challengeId: string,
  rewardId: string
) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${challengeId}/reward/${rewardId}/draw?request-type=${requestType}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardDrawChallengeDetailList = (
  requestType: string,
  challengeId: string
) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/${challengeId}/reward/draw?request-type=${requestType}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardDrawUserList = (
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/reward/${rewardId}/draw/user?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardDrawUserDownload = (rewardId: string, type: string) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/reward/${rewardId}/draw/user-download/${type}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `draw_user_${type}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getRewardSubmitUserDownload = (rewardId: string, type: string) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/reward/${rewardId}/submit/user-download/${type}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `submit_user_${type}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const getRewardDrawingApply = (
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/reward/${rewardId}/draw/apply?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardDrawingResult = (
  type: string,
  rewardId: string,
  searchKey?: string,
  searchWord?: string
) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/reward/${rewardId}/draw/result/${type}?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getRewardDrawingResultDownload = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/challenge/reward/${data.rewardId}/draw/result-download/${data.type}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `draw_result_${data.type}.xlsx`;
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const postRewardDrawCondition = (data: IReward) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/reward/${data.rewardId}/draw/condition`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postRewardChangeDrawResult = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/reward/${data.rewardId}/change/draw-result`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postRewardResetDrawResult = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/reward/${data.rewardId}/reset/draw-result`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postRewardDrawDrawing = (data: IRewardDrawing) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/${data.rewardId}/draw/drawing`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postRewardDrawSubmit = (data: IRewardDrawing) =>
  xapi
    .post(
      `/admin/v2/apis/challenge/${data.challengeId}/reward/${data.rewardId}/draw/submit`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
