import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../common/key';

interface Props {
  title?: string;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any>>;
}

export const SwSearchAmd: React.FC<Props> = ({
  title,
  open,
  onClose,
  setTarget,
}) => {
  const [amdList, setAmdList] = useState<any>([]);
  const [siList, setSiList] = useState<any>([]);
  const [doIndex, setDoIndex] = useState<any>(0);
  const [siIndex, setSiIndex] = useState<any>(0);

  const {
    data,
    isLoading,
    refetch: refetchDataPortal,
  } = useQuery(
    KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
    () => getDataPortalAccountAmdList(),
    {
      onSuccess: res => {
        console.log('PortalUserManagement res :', res);
        if (res != null) {
          setAmdList(res.amd);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const selectSiInfo = (selected: string) => {
    if (Number(selected) > 0) {
      const idx = Number(selected) - 1;
      const newSiList = amdList.depth1List[idx].depth2List.map(
        (item: any) => item.depthName2
      );
      setSiList(newSiList);
    }
    setSiIndex(0);
  };

  const onConfirm = () => {
    if (amdList && amdList.depthName1List) {
      const doName = doIndex > 0 ? amdList.depthName1List[doIndex - 1] : '';
      const siName = siIndex > 0 ? siList[siIndex - 1] : '';
      const newData = {
        name: `${doName} ${siName}`,
        amd: amdList.depth1List[doIndex - 1].depth2List[siIndex - 1].amdId,
      };
      console.log('newData:', newData);
      setTarget(newData);
    }
    onClose();
  };

  useEffect(() => {
    if (open) {
      setDoIndex(0);
      setSiIndex(0);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-container': {
          height: '85%',
        },
      }}
    >
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Typography sx={{ mt: '0.7rem' }}>지역별 데이터(선택)</Typography>
        <TextField
          select
          sx={{ width: '12rem', mr: '1rem' }}
          defaultValue={0}
          value={doIndex}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setDoIndex(event.target.value);
            selectSiInfo(event.target.value);
          }}
        >
          <MenuItem key={0} value={0}>
            선택
          </MenuItem>
          {amdList.depthName1List?.map((item: any, idx: number) => (
            <MenuItem key={idx + 1} value={idx + 1}>
              {item}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          sx={{ width: '12rem' }}
          defaultValue={0}
          value={siIndex}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setSiIndex(event.target.value);
          }}
        >
          <MenuItem key={0} value={0}>
            선택
          </MenuItem>
          {siList.map((item: any, idx: number) => (
            <MenuItem key={idx + 1} value={idx + 1}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button color='info' fullWidth size='small' onClick={onClose}>
          취소
        </Button>
        <Button color='primary' fullWidth size='small' onClick={onConfirm}>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};
