import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { GridColDef } from '@mui/x-data-grid';
import { SwTable } from '../../commonComponent/table/SwTable';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { getUserAuthCodeList } from '../../../common/api/ApiUser';
import { KEY_USER_AUTH_CODE_LIST } from '../../../common/key';
import { removeNatCodePhoneNumber } from '../../../common/helper';

interface Props {}

const columns = ['인증번호', '발송 일시'];

export const UserCert: React.FC<Props> = () => {
  const location = useLocation();
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const searchRef = useRef<any>(null);
  const [authCodeList, setAuthCodeList] = useState<any>([]);
  const [userData, setUserData] = useState<any>(location.state);

  const {
    data,
    isLoading,
    refetch: authcodeRefetch,
  } = useQuery(
    KEY_USER_AUTH_CODE_LIST,
    () => {
      if (userData.phoneno !== '') {
        return getUserAuthCodeList(
          userData.phoneno.includes('+')
            ? removeNatCodePhoneNumber(userData.phoneno)
            : userData.phoneno,
          page,
          rowsPerPage
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          setAuthCodeList(res.userAuthCode);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_USER_AUTH_CODE_LIST, () =>
      getUserAuthCodeList(
        userData.phoneno.includes('+')
          ? removeNatCodePhoneNumber(userData.phoneno)
          : userData.phoneno,
        newPage,
        rowsPerPage
      )
    );
  };

  const searchAuthCode = () => {
    setUserData({ phoneno: searchRef.current?.value });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchAuthCode();
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  useEffect(() => {
    authcodeRefetch();
  }, [userData]);

  useEffect(() => {
    if (location.state) {
      const newState: any = location.state;
      searchRef.current.value = newState.phoneno;
      setUserData(location.state);
      authcodeRefetch();
    }
  }, [location.state]);

  return (
    <>
      <Paper sx={{ p: '.5rem 1rem', m: '1rem 0' }}>
        <Box
          sx={{
            p: '1rem .5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            placeholder='연락처를 검색해 주세요.'
            inputRef={searchRef}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => searchAuthCode()}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ m: ' 1.5rem 0' }}
          />
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {authCodeList?.map((row: any, idx: number) => (
                <TableRow key={(rowsPerPage * (page - 1) + idx + 1).toString()}>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.code}</TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.sendtime}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
    </>
  );
};
