import { Box, styled, Tab, Tabs } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import { SyntheticEvent, useState } from 'react';

interface Props {}

const TabContainer = styled(Box)({
  marginTop: '1.8rem',
  borderBottom: 1,
  borderColor: 'divider',
  '& .MuiTab-root.Mui-selected': {
    color: '#00A2FF',
    backgroundColor: '#fff',
    border: '4px solid #EBECF2',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTabs-root': {
    minHeight: '1rem',
  },
  '& .MuiTab-root': {
    fontSize: '.8rem',
    minWidth: '3.2rem',
    minHeight: '1rem',
    backgroundColor: '#EBECF2',
    padding: '.4rem 1.5rem',
  },
});

const FirstTab = styled(Tab)({
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
});

const LastTab = styled(Tab)({
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
});

export const PushInquire: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('adminpush');
        break;
      case 2:
        setValue(2);
        navigate('dataportalpush');
        break;
      default:
        setValue(0);
        navigate('/push/inquire');
    }
  };
  return (
    <>
      <TabContainer>
        <Tabs value={value} onChange={tabChange}>
          <FirstTab label='어드민' value={1} />
          <LastTab label='데이터 포탈' value={2} />
        </Tabs>
      </TabContainer>
      <Outlet />
    </>
  );
};
