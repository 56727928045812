import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSession, xapi } from '../../../hooks/session';
import { IUploadFile } from '../../../models/common';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';

const MAX_NAME_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 1000;

interface Props {
  open: boolean;
  stamp: any;
  stampIndex: number;
  onClose: () => void;
  onConfirm: (idx: number, stamp: any) => void;
  onAdd: (idx: number, stamp: any) => void;
}

export const StamptourInfo: React.FC<Props> = ({
  open,
  stamp,
  stampIndex,
  onClose,
  onConfirm,
  onAdd,
}) => {
  const IMAGE_COUNT = 5;
  const defaultValue = {
    lat: '',
    lng: '',
    radius: '',
    name: '',
    image: '',
    description: '',
  };
  const { loginVal } = useSession();
  const [value, setValue] = useState<any>(defaultValue);
  const tt = useRef<any>();
  const geocoder = new window.kakao.maps.services.Geocoder();
  const [address, setAddress] = useState<string>('');
  const [stampAddress, setStampAddress] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [descriptionBuffer, setDescriptionBuffer] = useState<string[]>([]);
  const [stampImage, setStampImage] = useState<string>('');
  const [stampImageFile, setStampImageFile] = useState<any>(null);
  const [stampImageBuffer, setStampImageBuffer] = useState<string[]>([]);
  const [stampImageFileBuffer, setStampImageFileBuffer] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [openPostCode, setOpenPostCode] = useState<number>(0);
  const [openConfirm, setOpenConfirm] = useState<number>(0);
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });

  const saveCurrentPage = () => {
    const newDescriptionBuffer = [...descriptionBuffer];
    newDescriptionBuffer[page - 1] = description;
    setDescriptionBuffer(newDescriptionBuffer);

    const newImageBuffer = [...stampImageBuffer];
    newImageBuffer[page - 1] = stampImage;
    setStampImageBuffer(newImageBuffer);

    const newImageFileBuffer = [...stampImageFileBuffer];
    newImageFileBuffer[page - 1] = stampImageFile;
    setStampImageFileBuffer(newImageFileBuffer);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    saveCurrentPage();

    setDescription(descriptionBuffer[newPage - 1]);
    setStampImage(stampImageBuffer[newPage - 1]);
    setStampImageFile(stampImageFileBuffer[newPage - 1]);
    setPage(newPage);
  };

  const getBufferCount = () => {
    const newDescription = descriptionBuffer.filter(
      (desc: string) => desc !== null && desc !== ''
    );
    return newDescription === null ||
      newDescription === undefined ||
      newDescription.length === 0
      ? 1
      : newDescription.length;
  };

  const onChangeValue = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const target = evt.target.name;
    const targetValue =
      target !== 'name'
        ? evt.target.value
        : evt.target.value.substring(0, MAX_NAME_LENGTH);
    const newValue = { ...value, [target]: targetValue };
    setValue(newValue);
  };

  const addDescriptionImage = () => {
    const newDescriptionBuffer = [...descriptionBuffer];
    newDescriptionBuffer[page - 1] = description;
    newDescriptionBuffer[page] = '';
    setDescriptionBuffer(newDescriptionBuffer);

    const newImageBuffer = [...stampImageBuffer];
    newImageBuffer[page - 1] = stampImage;
    newImageBuffer[page] = '';
    setStampImageBuffer(newImageBuffer);

    const newImageFileBuffer = [...stampImageFileBuffer];
    newImageFileBuffer[page - 1] = stampImageFile;
    newImageFileBuffer[page] = null;
    setStampImageFileBuffer(newImageFileBuffer);

    setDescription('');
    setStampImage('');
    setStampImageFile(null);
    const newPage = newDescriptionBuffer.length;

    setPage(newPage);
    setTotalPage(newPage);
  };

  const deleteDescriptionImage = () => {
    if (totalPage === 1) {
      return;
    }

    const newDescriptionBuffer = descriptionBuffer.filter(
      (item: any, idx: number) => idx !== page - 1
    );
    setDescriptionBuffer(newDescriptionBuffer);

    const newImageBuffer = stampImageBuffer.filter(
      (item: any, idx: number) => idx !== page - 1
    );
    setStampImageBuffer(newImageBuffer);

    const newImageFileBuffer = stampImageFileBuffer.filter(
      (item: any, idx: number) => idx !== page - 1
    );
    setStampImageFileBuffer(newImageFileBuffer);

    const newPage = page > totalPage - 1 ? totalPage - 1 : page;
    if (page > totalPage - 1) {
      setPage(newPage);
    }
    setTotalPage(totalPage - 1);

    setDescription(newDescriptionBuffer[newPage - 1]);
    setStampImage(newImageBuffer[newPage - 1]);
    setStampImageFile(newImageFileBuffer[newPage - 1]);
  };

  async function saveStampInfo() {
    const newDescription = await Promise.all(
      descriptionBuffer
        .filter(item => item.length > 0)
        .map(async (item: any, idx: number) => {
          var imageUrl = null;
          const imageFile = stampImageFileBuffer[idx];
          var newUploadFile: any = null;
          if (imageFile !== null && imageFile !== undefined) {
            newUploadFile = await uploadFile('stamp', imageFile);
          }

          return {
            idx: idx + 1,
            description: item,
            image:
              stampImageBuffer[idx] === null ||
              stampImageBuffer[idx] === undefined
                ? ''
                : stampImageBuffer[idx],
            imageFile:
              imageFile !== null && imageFile !== undefined ? imageFile : null,
            uploadUrl:
              imageFile !== null && imageFile !== undefined
                ? newUploadFile.data.uploadUrl.file.path
                : null,
          };
        })
    );

    const newConvertDescription = newDescription.map((desc: any) => {
      const newData: any = {
        idx: desc.idx,
        description: desc.description,
        image: desc.uploadUrl !== null ? desc.uploadUrl : desc.image,
      };
      return newData;
    });

    const newValue = {
      ...value,
      address: address,
      description: JSON.stringify(newConvertDescription),
      stampAddress: stampAddress,
    };
    setValue(newValue);

    stamp && stamp?.point !== undefined
      ? onConfirm(stampIndex, newValue)
      : onAdd(stampIndex, newValue);
  }

  const changeDescription = (desc: string) => {
    setDescription(desc);
    const newDescriptionBuffer = [...descriptionBuffer];
    newDescriptionBuffer[page - 1] = desc;
    setDescriptionBuffer(newDescriptionBuffer);
  };

  useEffect(() => {
    if (
      targetData.roadAddress !== undefined &&
      targetData.roadAddress !== null
    ) {
      const newAddress =
        targetData.roadAddress.length > 0
          ? targetData.roadAddress
          : targetData.jibunAddress;
      if (targetData.request === 2) {
        setAddress(newAddress);
      }

      if (targetData.request === 1) {
        setStampAddress(newAddress);
        geocoder.addressSearch(newAddress, (result: any, status: any) => {
          if (result.length === 0) {
            return;
          }
          const wanted = new window.kakao.maps.LatLng(result[0].y, result[0].x);
          const newValue = {
            ...value,
            lng: Number(wanted.La).toFixed(7),
            lat: Number(wanted.Ma).toFixed(7),
          };
          setValue(newValue);
        });
      }
    }
  }, [targetData]);

  useEffect(() => {
    if (stampImage === '' && stampImageFile === null) {
      const newImageBuffer = [...stampImageBuffer];
      newImageBuffer[page - 1] = '';
      setStampImageBuffer(newImageBuffer);

      const newImageFileBuffer = [...stampImageFileBuffer];
      newImageFileBuffer[page - 1] = null;
      setStampImageFileBuffer(newImageFileBuffer);
    } else {
      const newImageFileBuffer = [...stampImageFileBuffer];
      newImageFileBuffer[page - 1] = stampImageFile;
      setStampImageFileBuffer(newImageFileBuffer);
    }
  }, [stampImage, stampImageFile]);

  useEffect(() => {
    if (stamp && stamp?.point !== undefined && open) {
      setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);
      const newValue = {
        id: stamp?.id,
        lat: stamp?.point[0],
        lng: stamp?.point[1],
        radius: stamp?.radius,
        name: stamp.name ? stamp.name : '',
        className: stamp?.className ? stamp.className : '',
        link: stamp?.link ? stamp.link : '',
        phoneNo: stamp?.phoneNo ? stamp.phoneNo : '',
      };
      setStampAddress(stamp?.stampAddress ? stamp?.stampAddress : '');
      setAddress(stamp?.address ? stamp.address : '');
      setDescription('');
      setValue(newValue);
      setPage(1);

      const newDescriptionBuffer: any[] = [];
      const newImageBuffer: any[] = [];
      const newFileBuffer: any[] = [];
      const newDescription =
        stamp?.description !== undefined && stamp?.description !== null
          ? JSON.parse(stamp.description)
          : [];
      newDescription.map((item: any) => {
        newDescriptionBuffer.push(
          item.description !== undefined && item.description !== null
            ? item.description
            : ''
        );
        const newImage =
          item.imageFile !== '' &&
          item.imageFile !== null &&
          item.imageFile !== undefined
            ? item.uploadUrl
            : item.image !== '' &&
              item.image !== null &&
              item.image !== undefined
            ? item.image
            : '';
        newImageBuffer.push(newImage);
        newFileBuffer.push(null);
      });

      setDescriptionBuffer(newDescriptionBuffer);
      setStampImageBuffer(newImageBuffer);
      setStampImageFileBuffer(newFileBuffer);
      setDescription(
        newDescriptionBuffer.length > 0 ? newDescriptionBuffer[0] : ''
      );
      setStampImage(newImageBuffer.length > 0 ? newImageBuffer[0] : '');
      setStampImageFile(null);
      setTotalPage(
        newDescriptionBuffer.length > 0 ? newDescriptionBuffer.length : 1
      );
    }
    return () => {
      setValue(defaultValue);
      setStampAddress('');
    };
  }, [open]);

  return (
    <>
      <Dialog
        id='test'
        open={open}
        sx={{
          '& .MuiDialog-paper': {
            marginTop: '18vh',
            marginLeft: '35vw',
            padding: '1rem .5rem 0',
            height: '66vh',
            width: '32vw',
            minWidth: '333px',
          },
        }}
        ref={tt}
      >
        <DialogTitle sx={{ padding: '1rem .5rem 0' }}>
          스탬프 정보 입력
        </DialogTitle>
        <DialogContent sx={{ padding: '1rem .5rem 0' }}>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>스탬프 반경</Typography>
            <Typography sx={{ fontSize: '.7rem' }}>
              GPS 오차 범위를 고려한 안전한 반경은 150~200m
            </Typography>
            <TextField
              sx={{ p: '.4rem 0 0' }}
              fullWidth
              name='radius'
              value={value.radius}
              onChange={evt => onChangeValue(evt)}
            />
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>위도</Typography>
            <TextField
              sx={{ p: '.4rem 0 0' }}
              fullWidth
              value={value.lat}
              name='lat'
              onChange={evt => onChangeValue(evt)}
              onClick={() => stampAddress !== '' && setOpenConfirm(1)}
            />
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>경도</Typography>
            <TextField
              sx={{ p: '.4rem 0 0' }}
              fullWidth
              value={value.lng}
              name='lng'
              onChange={evt => onChangeValue(evt)}
              onClick={() => stampAddress !== '' && setOpenConfirm(1)}
            />
          </Box>
          <Box sx={{ p: '.5rem', mb: '1rem' }}>
            {stampAddress !== '' && (
              <Box className='flex_between'>
                <Typography>{stampAddress}</Typography>
                <Stack
                  direction={{ xs: 'column', sm: 'column', md: 'row' }}
                  divider={
                    <Divider
                      orientation='vertical'
                      flexItem
                      sx={{ p: 0, m: '.5rem .5rem' }}
                    />
                  }
                >
                  <Button
                    color='warning'
                    onClick={() => {
                      setStampAddress('');
                    }}
                  >
                    삭제
                  </Button>
                  <Button
                    color='primary'
                    onClick={() => {
                      setOpenPostCode(1);
                    }}
                  >
                    수정
                  </Button>
                </Stack>
              </Box>
            )}
            {stampAddress === '' && (
              <Button
                sx={{ width: '100%' }}
                variant='outlined'
                color='primary'
                onClick={() => {
                  setOpenPostCode(1);
                }}
              >
                주소 입력
              </Button>
            )}
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>스탬프 이름</Typography>
            <TextField
              sx={{ p: '.4rem 0 0' }}
              fullWidth
              value={value.name}
              name='name'
              onChange={evt => onChangeValue(evt)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>
                      {value.name.length} / {MAX_NAME_LENGTH}
                    </span>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>스탬프 분류</Typography>
            <TextField
              placeholder='가게, 유적지 등'
              sx={{ p: '.4rem 0 0' }}
              fullWidth
              value={value.className}
              name='className'
              onChange={evt => onChangeValue(evt)}
            />
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>(선택)링크 정보</Typography>
            <TextField
              placeholder='url'
              sx={{ p: '.4rem 0 0' }}
              fullWidth
              value={value.link}
              name='link'
              onChange={evt => onChangeValue(evt)}
            />
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>(선택)전화번호</Typography>
            <TextField
              placeholder='02-0000-0000'
              sx={{ p: '.4rem 0 0' }}
              fullWidth
              value={value.phoneNo}
              name='phoneNo'
              onChange={evt => onChangeValue(evt)}
            />
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>(선택)주소</Typography>
            <TextField
              placeholder='예) 서울 강남구 봉은사로 437'
              sx={{ p: '.4rem 0 0' }}
              fullWidth
              value={address}
              name='address'
              onChange={evt => setAddress(evt.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ pr: 0 }}
                      onClick={() => setOpenPostCode(2)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Box className='flex_between'>
              <Typography sx={{ fontWeight: 500 }}>스탬프 설명</Typography>
              <Box>
                {totalPage > 1 && (
                  <Button
                    onClick={deleteDescriptionImage}
                    size='small'
                    sx={{ color: 'red' }}
                  >
                    삭제
                  </Button>
                )}
                <Button onClick={addDescriptionImage} size='small'>
                  추가
                </Button>
              </Box>
            </Box>
            <TextField
              placeholder='스탬프 설명을 입력해주세요.'
              fullWidth
              multiline
              rows={3}
              value={description}
              name='description'
              onChange={evt =>
                changeDescription(
                  evt.target.value.substring(0, MAX_DESCRIPTION_LENGTH)
                )
              }
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                  padding: '.5rem',
                },
              }}
            />
            <Box className='flex_end' sx={{ fontSize: '0.7rem' }}>
              <span>
                {description.length} / {MAX_DESCRIPTION_LENGTH}
              </span>
            </Box>
          </Box>
          <Box sx={{ p: '.5rem' }}>
            <Typography sx={{ fontWeight: 500 }}>(선택)이미지</Typography>
            <SwFileSetting
              setImage={setStampImage}
              image={
                stampImageFile !== null
                  ? stampImage
                  : stampImage !== '' && stampImage !== undefined
                  ? `${imageUrlPrefix}${stampImage}`
                  : ''
              }
              setFile={setStampImageFile}
              file={stampImageFile}
              disabled={false}
            />
          </Box>
          {openPostCode && (
            <SwSearchPostCode
              open={!!openPostCode}
              onClose={() => setOpenPostCode(0)}
              request={openPostCode}
              setTarget={setTargetData}
            />
          )}
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalPage / rowsPerPage)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>취소</Button>
          <Button onClick={() => saveStampInfo()}>저장</Button>
        </DialogActions>
      </Dialog>
      <SwConfirmDialog
        contents='입력한 주소가 초기화됩니다. 계속 진행하시겠니까?'
        open={!!openConfirm}
        onClose={() => setOpenConfirm(0)}
        onConfirm={() => {
          setOpenConfirm(0);
          setStampAddress('');
        }}
        confirm={1}
      />
    </>
  );
};
