import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import React, { KeyboardEvent, useEffect, useRef, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { useNavigate } from 'react-router';
import { useSession } from '../../hooks/session';
import { SwAlert } from '../commonComponent/views/SwAlert';
import { KEY_AMD_LIST } from '../../common/key';
import useLocalStorage from '../../hooks/storage';

interface Props {}

const LoginTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: '3.4rem',
  },
  width: '24.6rem',
  marginBottom: '1.4rem',
  display: 'block',
});

const SubmitButton = styled(Button)({
  maxWidth: '48vw',
  width: '24.6rem',
  height: '2.5rem',
  display: 'block',
  marginBottom: '1.23rem ',
});

export const Login: React.FC<Props> = () => {
  const { loginVal, login } = useSession();
  const navigate = useNavigate();
  const [view, setView] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const idRef = useRef<any>(null);
  const pwRef = useRef<any>(null);
  const onChangeAlertOpen = () => setAlertOpen(!alertOpen);

  const submit = () => {
    if (idRef.current.value && pwRef.current.value) {
      const params = { id: idRef.current.value, password: pwRef.current.value };
      login(params, err => {
        if (err.response) {
          const msg = err.response?.data;
          if (msg === 'invalid account id') {
            setAlertMessage('유효하지 않은 아이디입니다.');
            setAlertOpen(true);
          } else if (msg === 'invalid password') {
            setAlertMessage('비밀번호가 확인해주세요.');
            setAlertOpen(true);
          } else {
            setAlertMessage('아이디나 비밀번호를 확인 후 다시 시도해주세요.');
            setAlertOpen(true);
          }
        }
      });
    } else {
      setAlertMessage('아이디와 비밀번호 모두 입력해주세요.');
      setAlertOpen(true);
    }
  };
  const requestAccount = () => {
    setAlertMessage('계정 생성은 워크온 담당자에게 문의해 주세요.');
    setAlertOpen(!alertOpen);
  };
  const pressEnterLogin = (evt: KeyboardEvent<HTMLDivElement>) => {
    evt.key === 'Enter' && submit();
  };

  useEffect(() => {
    if (loginVal.value?.didLogin) navigate('/');
  }, []);

  return (
    <>
      <Helmet>
        <title>로그인</title>
      </Helmet>
      <Box
        sx={{
          // position: 'fixed',
          // top: '20em',
          // left: '43%',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '25vh',
        }}
      >
        <Typography
          component='div'
          variant='h2'
          sx={{ fontWeight: 'fontWeightRegular', mb: '.4rem', mr: '14.1rem' }}
        >
          안녕하세요.
        </Typography>
        <Box sx={{ mb: '3em' }}>
          <Typography variant='h2' component='span'>
            WalkOn Administrator
          </Typography>
          <Typography variant='subtitle2' component='span'>
            &nbsp;입니다.
          </Typography>
        </Box>
        <LoginTextField
          placeholder='아이디를 입력해주세요'
          autoComplete='off'
          autoFocus
          fullWidth
          inputRef={idRef}
        />
        <LoginTextField
          placeholder='비밀번호를 입력해주세요'
          type={view ? 'text' : 'password'}
          fullWidth
          autoComplete='off'
          inputRef={pwRef}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={() => setView(!view)}>
                  {view ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyDown={pressEnterLogin}
        />
        <SubmitButton variant='contained' onClick={submit}>
          로그인
        </SubmitButton>
        <div className='inline_center'>
          <Button sx={{ p: 0 }} onClick={requestAccount}>
            계정 생성하기
          </Button>
        </div>
        <div className='login_footer'>
          <Typography>스왈라비(주)</Typography>
        </div>
        <SwAlert
          title={
            alertMessage !== '계정 생성은 워크온 담당자에게 문의해 주세요.'
              ? '로그인 오류'
              : '워크온 담당자'
          }
          open={alertOpen}
          onConfirm={onChangeAlertOpen}
          contents={alertMessage}
        />
      </Box>
    </>
  );
};
