import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import clsx from 'clsx';
import { Box, styled, Tab, Tabs } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { TabBox } from '../../styles/Styles';

interface Props {}

export const Walkcourse: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(1);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('create');
        break;
      case 2:
        setValue(2);
        navigate('inquire');
        break;
      default:
        setValue(0);
        navigate('/walkcourse');
    }
  };
  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/walkcourse/create') setValue(1);
    else setValue(2);
  }, [window.location.pathname]);
  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>길 코스</title>
      </Helmet>
      <TabBox>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='길 코스 생성' value={1} />
          <Tab label='길 코스 목록' value={2} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
