import clsx from 'clsx';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useResize } from '../../../hooks/resize';
import { SelectTextField, TabBox } from '../../styles/Styles';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';

import { ManageGuideDrawUp } from './ManageGuideDrawUp';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';

import {
  chosenCommunityState,
  communitySearchState,
} from '../../../common/atom';
import { postCommunity } from '../../../common/api/ApiCommunity';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { ICommunity } from '../../../models/community';
import { xapi } from '../../../hooks/session';
import { RESULT_OK } from '../../../common/resultCode';

interface Props {
  open: boolean;
  onclose: () => void;
}

export const CommunityPersonal: React.FC<Props> = ({ open, onclose }) => {
  const dummyUserId = '00000000000000000000000000000000';
  const queryClient = useQueryClient();
  const locationSearch = useRef<any>();
  const titleRef = useRef<any>();
  const groupInputRef = useRef<any>();
  const descriptionRef = useRef<any>();
  const [page, setPage] = useState(1);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [communityId, setCommunityId] = useState<string>(newUuidId());
  const [communityCoverImage, setCommunityCoverImage] = useState<string>('');
  const [communityCoverFile, setCommunityCoverFile] = useState<any>(null);
  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<any>();
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [communityGroup, setCommunityGroup] = useState<any>([]);
  const [authRegion, setAuthRegion] = useState<any>([]);
  const [communityDivision, setCommunityDivision] = useState<any>([0, 0]);
  const [communityType, setCommunityType] = useState<any>(0);
  const [communityCreatorId, setCommunityCreatorId] = useState<string>('');
  const [communityExtraId, setCommunityExtraId] = useState<string>(newUuidId());
  const [communityGeohash, setCommunityGeohash] = useState<string>('');
  const [communityOwnerName, setCommunityOwnerName] = useState<string>('');
  const [communityDefaultGroupId, setCommunityDefaultGroupId] =
    useState<string>(newUuidId());
  const [communityTitle, setCommunityTitle] = useState<string>('');
  const [communityTitleHelperText, setCommunityTitleHelperText] =
    useState<string>('');
  const [communityDescription, setCommunityDescription] = useState<string>('');
  const [communityDescriptionHelperText, setCommunityDescriptionHelperText] =
    useState<string>('');
  const [communityTerms, setCommunityTerms] = useState<string>('');
  const [communityTag, setCommunityTag] = useState<any>(['', '', '']);
  const [suggestion, setSuggestion] = useState<any>(0);
  const [memberLimit, setMemberLimit] = useState<any>(0);
  const [groupName, setGroupName] = useState<string>('');
  const [limitUser, setLimitUser] = useState<any>(0);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const { mutate: postCommunityMutate, isError: isMutateError } = useMutation(
    postCommunity,
    {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenDialog(true);
          setDialogMessage('커뮤니티를 등록했습니다.');
          onclose();
        } else {
          setOpenDialog(true);
          setDialogMessage(
            `커뮤니티를 등록 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          `커뮤니티 저장이 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
        );
      },
    }
  );

  const registerCommunity = (newUploadUrl: any) => {
    const newAgreement = {
      type: 1,
      title: '',
      detail: ``,
      option: communityTerms,
    };
    const newLtLng = resultLatLng.split(' ');
    console.log('newLt', newLtLng);
    const newGeohash = '';
    const newImage = communityCoverFile !== null ? newUploadUrl.file.path : '';
    const nowDate = new Date(Date.now());
    const nowYear = nowDate.getFullYear();
    const newCommunityData: ICommunity = {
      official: 0,
      communityId: communityId,
      challengeHidden: 0,
      contractTypeMaster: communityDivision[0],
      contractTypeSub: communityDivision[1],
      memberLimit:
        limitUser === -1 ? memberLimit : limitUser === 0 ? 100 : limitUser,
      creatorId: communityCreatorId.length > 0 ? communityCreatorId : '',
      type: communityType,
      name: communityTitle,
      desc: communityDescription,
      image: newImage,
      tags: communityTag.filter((tag: string) => tag !== ''),
      groups: communityGroup,
      startDate: nowDate.getTime() / 1000,
      endDate: new Date(`${nowYear}-12-31T23:59:59`).getTime() / 1000,
      agreement: JSON.stringify(newAgreement),
      la: newLtLng[0] ? newLtLng[0] : 0.0,
      lo: newLtLng[1] ? newLtLng[1] : 0.0,
      address: '',
      extraId: communityExtraId,
      geohash: newGeohash,
      ownerName:
        communityOwnerName.length > 0
          ? communityOwnerName
          : connectDataportal?.id.length > 0
          ? connectDataportal?.name
          : '',
      defaultGroupId: communityDefaultGroupId,
      portalId: connectDataportal?.id.length > 0 ? connectDataportal?.id : '',
      authRegions: authRegion,
      registerStatus: 3,
      usePost: 1,
      useRank: 1,
      hiddenStep: 0,
    };
    console.log('new : ', newCommunityData);

    var isValid = 0;
    if (isValid === 0 && newCommunityData.name.length === 0) {
      isValid = 1;
    }
    if (isValid === 0 && newCommunityData.desc.length === 0) {
      isValid = 2;
    }
    if (isValid === 0 && newCommunityData.creatorId.length === 0) {
      isValid = 3;
    }

    if (isValid === 0) {
      console.log('postCommunityMutate :', newCommunityData);
      postCommunityMutate(newCommunityData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '커뮤니티 이름을 입력해 주세요';
          break;
        case 2:
          message = '커뮤니티 소개를 입력해 주세요.';
          break;
        case 3:
          message = '개설자 아이디를 입력해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenDialog(true);
      setDialogMessage(message);
    }
  };

  const { mutate: uploadFileMutate, isError: isUploadMutateError } =
    useMutation(postUploadFile, {
      onSuccess: res => {
        console.log('uploadFile:', res);
        setUploadUrl(res.uploadUrl.file.path);
        console.log('uploadFile uploadUrl:', uploadUrl);
        registerCommunity(res.uploadUrl);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const checkCommunity = () => {
    setOpenMore(0);
    if (communityCoverFile === null) {
      registerCommunity(null);
    } else {
      const uploadfile: IUploadFile = {
        type: 'community',
        file: communityCoverFile,
      };
      uploadFileMutate(uploadfile);
    }
  };

  useEffect(() => {
    if (open) {
      setCommunityCreatorId('');
      setCommunityTitle('');
      setCommunityCoverFile(null);
      setCommunityDescription('');
      setCommunityCoverImage('');
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <span>개인 커뮤니티 생성</span>
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
            개설자 아이디
          </Typography>
          <TextField
            fullWidth
            value={communityCreatorId}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setCommunityCreatorId(event.target.value)}
          />
          <Divider />
          <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
            개설자 커뮤니티 이름
          </Typography>
          <TextField
            fullWidth
            value={communityTitle}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setCommunityTitle(event.target.value)}
          />
          <Divider />
          <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
            개인 커뮤니티 소개
          </Typography>
          <TextField
            multiline
            rows={8}
            fullWidth
            onChange={evt =>
              setCommunityDescription(evt.target.value.substring(0, 1000))
            }
            value={communityDescription}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 'auto',
              },
            }}
          />
          <Divider />
          <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
            커버 이미지(선택)
          </Typography>
          <SwFileSetting
            setImage={setCommunityCoverImage}
            image={communityCoverImage}
            setFile={setCommunityCoverFile}
            file={communityCoverFile}
          />
          <input type='file' id='fileUpload' style={{ display: 'none' }} />
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          <Button onClick={onclose} color='info' variant='outlined'>
            닫기
          </Button>
          <Button color='info' variant='contained' onClick={checkCommunity}>
            저장
          </Button>
        </DialogActions>
      </Dialog>
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
