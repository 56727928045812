import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IAmd } from '../../models/amd';
import { IRegionFilter } from '../../models/regionfilter';
import { throwError } from '../helper';

/*
 * Gallery
 */

export const getGalleryImageList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/gallery/image?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerGalleryImage = (data: any) =>
  xapi.post(`/admin/v2/apis/gallery/image`, data);
