import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IUploadFile } from '../../models/common';
import { throwError } from '../helper';

/*
 * Common
 */

export const getTarget = (searchKey: string, searchWord: string) =>
  xapi
    .get(
      `/admin/v2/apis/search/target?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getProgramTarget = (searchKey: string, searchWord: string) =>
  xapi
    .get(
      `/admin/v2/apis/program/information/target?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postUploadFile = (upload: IUploadFile) => {
  const formData = new FormData();
  formData.append('file', upload.file);
  return xapi
    .post(`/admin/v2/apis/upload/${upload.type}`, formData)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
};
