import { Box, Button, styled, TextField } from '@mui/material';
import React, { Dispatch, useEffect, useRef, useState } from 'react';

interface Props {
  elementId?: string;
  setLatLng: Dispatch<any>;
  value: string;
  setAddress?: Dispatch<any>;
  location?: string;
  setLocation?: Dispatch<any>;
  disabled?: boolean;
  detail?: boolean;
  showButton?: boolean;
}

const FindBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
});

export const SwFindLatLngbyKakaomap: React.FC<Props> = ({
  elementId = 'map',
  setLatLng,
  setAddress,
  value,
  location = '',
  setLocation,
  disabled = false,
  detail = false,
  showButton = true,
}) => {
  const locationSearch = useRef<any>();
  const [localLocation, setLocalLocation] = useState<string>('');
  const geocoder = new window.kakao.maps.services.Geocoder();
  const searchingLatLng = (newLocation: string) => {
    const tmpLocaiton = newLocation.trim();
    if (tmpLocaiton === '') return;
    setLocation && setLocation(tmpLocaiton);
    geocoder.addressSearch(tmpLocaiton, (result: any, status: any) => {
      if (result.length === 0) {
        setLatLng('');
        return;
      }
      const wanted = new window.kakao.maps.LatLng(result[0].y, result[0].x);
      console.log('wanted>>>>>>>>.', wanted);
      setLatLng(`${wanted.Ma} ${wanted.La}`);
      geocoder.coord2RegionCode(result[0].x, result[0].y, (result2: any) => {
        console.log(result2);
        if (detail) {
          setAddress &&
            setAddress([
              result2[0].region_1depth_name,
              result2[0].region_2depth_name,
              result2[0].region_3depth_name,
            ]);
        } else {
          setAddress &&
            setAddress([
              result2[0].region_1depth_name,
              result2[0].region_2depth_name,
            ]);
        }
      });
      const mapContainer = document.getElementById(`${elementId}`);
      const mapOption = {
        center: wanted,
        level: 3,
      };
      const map = new window.kakao.maps.Map(mapContainer, mapOption);
    });
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchingLatLng(locationSearch.current?.value);
    }
  };

  useEffect(() => {
    if (location !== null && location.length > 0) {
      setLocalLocation(location);
      searchingLatLng(location);
    } else {
      setLocalLocation('');
    }
  }, [location]);

  return (
    <>
      <div
        id={elementId}
        style={{ width: '100vw', height: '50vh', display: 'none' }}
      />
      <FindBox>
        <TextField
          InputProps={{ readOnly: disabled }}
          sx={{ width: '35rem', mr: '1rem', mb: 0 }}
          inputRef={locationSearch}
          placeholder='주소를 검색해 주세요.'
          onChange={evt => setLocalLocation(evt.target.value)}
          onKeyDown={handleKeyDown}
          value={localLocation}
        />
        {showButton && (
          <Button
            disabled={disabled}
            onClick={() => searchingLatLng(locationSearch.current?.value)}
            variant='contained'
          >
            검색
          </Button>
        )}
      </FindBox>
      <TextField
        disabled
        value={value.split(' ')[0] || ''}
        sx={{ width: '13rem', maxWidth: '66%', mr: '1rem' }}
      />
      <TextField
        disabled
        value={value.split(' ')[1] || ''}
        sx={{ width: '13rem', maxWidth: '66%' }}
      />
    </>
  );
};
