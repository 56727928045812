import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { NoneBorderChip, SwSwitch } from '../styles/Styles';
import { KEY_DATAPORTAL_ACCOUNT_LIST } from '../../common/key';
import { getDataPortalAccountList } from '../../common/api/ApiDataPortal';
import { SwSearchDataPortal } from './SwSearchDataPortal';

interface Props {
  findDataportal: string;
  setFindDataportal: (value: SetStateAction<string>) => void;
  connectDataportal: any;
  setConnectDataportal: (value: SetStateAction<any>) => void;
  setTargetInfo?: Dispatch<React.SetStateAction<any>>;
  isDetail?: boolean;
  title?: string;
}

export const DataportalMultiConnect: React.FC<Props> = ({
  findDataportal,
  setFindDataportal,
  connectDataportal,
  setConnectDataportal,
  setTargetInfo,
  isDetail = false,
  title = '데이터 포탈 연결',
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [portalIds, setPortalIds] = useState<any>([]);
  const [subTitle, setSubTitle] = useState<string>('');

  const changeValue = (
    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setFindDataportal(evt.target.value);

  const connectingDataportal = () => {
    // setConnectDataportal(findDataportal);
    // setFindDataportal('');
    setSearchKey('all');
    setSearchWord(findDataportal);
    setOpenSearchResult(true);
  };
  const disconnectingDataportal = (index: number) => {
    const newPortalIds = portalIds.filter(
      (item: any, idx: number) => idx !== index
    );
    setPortalIds(newPortalIds);
    setTargetInfo && setTargetInfo(newPortalIds);
  };

  const deleteAllPortalId = () => {
    setPortalIds([]);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      connectingDataportal();
    }
  };

  useEffect(() => {
    setPortalIds([...connectDataportal]);
    setTargetInfo && setTargetInfo([]);
    setFindDataportal('');
  }, [connectDataportal]);

  useEffect(() => {
    console.log(
      'DataportalConnect searchResult:',
      searchResult,
      ', searchResult.portalId: ',
      searchResult?.portalId
    );
    if (
      searchResult !== null &&
      searchResult !== undefined &&
      searchResult.portalId !== undefined
    ) {
      setPortalIds([
        ...portalIds,
        { portalId: searchResult.portalId, name: searchResult.parent },
      ]);
      isDetail
        ? setTargetInfo &&
          setTargetInfo([
            ...portalIds,
            { portalId: searchResult.portalId, name: searchResult.parent },
          ])
        : setTargetInfo && setTargetInfo([]);
    } else if (searchResult !== undefined) {
      setPortalIds([]);
    }
    return () => {};
  }, [searchResult]);

  return (
    <>
      <Box>
        <Typography className='title'>{title}</Typography>
        <TextField
          value={findDataportal}
          onChange={changeValue}
          onKeyDown={handleKeyDown}
          sx={{ width: '26rem', mr: '1em' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={connectingDataportal}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Paper className='chip_container'>
        <Typography>데이터 포탈</Typography>
        {portalIds.length > 0 &&
          portalIds.map((item: any, idx: number) => (
            <NoneBorderChip
              label={`${item?.portalId}`}
              variant='outlined'
              onDelete={() => disconnectingDataportal(idx)}
              key={idx.toString()}
              sx={{
                m: '.5em',
                border: 'none',
                '& .css-6od3lo-MuiChip-label': {
                  paddingRight: '6px',
                },
              }}
            />
          ))}
        <div className='flex_end'>
          <Button
            onClick={() => deleteAllPortalId()}
            color='info'
            sx={{ fontSize: '.8rem' }}
          >
            <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
            전체삭제
          </Button>
        </div>
      </Paper>
      <SwSearchDataPortal
        title={subTitle !== '' ? subTitle : '포털아이디'}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
        isDetail={isDetail}
      />
    </>
  );
};
