export interface ICommunity {
  official: number;
  communityId: string;
  contractTypeMaster: number;
  contractTypeSub: number;
  memberLimit: number;
  creatorId: string;
  type: number;
  name: string;
  desc: string;
  image: string;
  tags: string[];
  groups: string;
  startDate: number;
  endDate: number;
  agreement: string;
  additionalAgreement?: string;
  lo: number;
  la: number;
  address: string;
  extraId: string;
  geohash: string;
  ownerName: string;
  defaultGroupId: string;
  portalId: string;
  authRegions?: any[];
  authAddress?: any[];
  registerStatus?: number;
  isSearch?: number;
  joinForm?: string;
  challengeHidden: number;
  joinCode?: string;
  hiddenStep: number;
  usePost: number;
  useRank: number;
}

export interface ICommunityReqeust {
  communityId?: string;
  statType?: string;
  portalId?: string;
  startDate?: number;
  endDate?: number;
  page: number;
  rowsPerPage: number;
  searchKey?: string;
  searchWord?: string;
}

export interface ICommunityReceiver {
  type: string;
  id: string;
  name: string;
}

export interface ICommunityLink {
  type: string;
  target: string;
}

export interface ICommunityRepeat {
  startDate: number;
  endDate: number;
}

export interface ICommunityCancel {
  CommunityId: string;
  type: string;
  site: string;
}

export interface ICommunityGroup {
  communityId?: string;
  groupId?: string;
  groupName?: string;
  isDefault?: number;
  deleteGroupIds?: string[];
}

export interface ICommunityModel {
  push: ICommunity;
}

export interface ICommunitys {
  emp: ICommunity[];
}

export const DefaultCommunity: ICommunity = {
  official: 0,
  communityId: '',
  contractTypeMaster: 0,
  contractTypeSub: 0,
  memberLimit: 0,
  creatorId: '',
  type: 0,
  name: '',
  desc: '',
  image: '',
  tags: [],
  groups: '',
  startDate: 0,
  endDate: 0,
  agreement: '',
  lo: 0,
  la: 0,
  address: '',
  extraId: '',
  geohash: '',
  ownerName: '',
  defaultGroupId: '',
  portalId: '',
  challengeHidden: 0,
  hiddenStep: 0,
  usePost: 1,
  useRank: 1,
};
