import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IWalkwayLocal } from '../../models/walkway';
import { xapi } from '../../hooks/session';
import { IWalkcourse } from '../../models/walkcourse';
import { throwError } from '../helper';
/*
 * Walkcourse
 */

export const getWalkcourseList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/walkcourse?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getWalkcourseDetailInfo = (walkcourseId: string) =>
  xapi.get(`/admin/v2/apis/walkcourse/${walkcourseId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getWalkcourseRequestList = () =>
  xapi.get(`/admin/v2/apis/walkcourse/creation-request`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getWalkcourseRequestListByOperator = () =>
  xapi.get(`/admin/v2/apis/walkcourse/creation-request-operator`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getWalkcourseRequestDetail = (walkcourseId: string) =>
  xapi
    .get(`/admin/v2/apis/walkcourse/creation-request/${walkcourseId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getWalkcourseWalkway = (walkcourseId: string) =>
  xapi.get(`/admin/v2/apis/walkcourse/${walkcourseId}/walkway`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerWalkcourse = (data: IWalkcourse) =>
  xapi.post(`/admin/v2/apis/walkcourse`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const updateWalkcoursePoint = (data: any) =>
  xapi
    .put(`/admin/v2/apis/walkcourse/${data.courseId}/points`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteWalkcourse = (data: IWalkcourse) =>
  xapi.delete(`/admin/v2/apis/walkcourse/${data.courseId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const changeWalkcourseApplyStatus = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/walkcourse/${data.courseId}/status/${data.status}?message=${data.message}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
