export interface IEmp {
  id: string;
  name: string;
  phoneNumber: string;
  email?: string;
  password?: string;
  role: string;
  ids?: string[];
}

export interface IEmpModel {
  emp: IEmp;
}

export interface IEmps {
  emp: IEmp[];
}
export interface ILoginUser {
  user: {
    id: string;
    name: string;
    role: string | null;
  };
  didLogin: boolean;
  authorization: string;
  attemptedPage?: string;
  resultCode?: string;
  resultCodeMsg?: string;
  imageUrlPrefix?: string;
  cloudFront?: string;
}

export const DefaultEmp: IEmp = {
  id: '',
  name: '',
  phoneNumber: '',
  role: '',
};

export const DefaultLoginUser = {
  user: {
    id: '',
    name: '',
    role: null,
  },
  didLogin: false,
  authorization: '',
  attemptedPage: '',
};
