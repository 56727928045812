import { Paper } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import fin from '../../images/fin.svg';
import failfin from '../../images/failfin.svg';

interface Props {}

export const Test4: React.FC<Props> = () => {
  const [coords, setCoords] = useState<any[]>([]);
  const [markers, setMarkers] = useState<any>([
    {
      lat: 37.4867995957995,
      lng: 127.012084,
      content:
        '<img src="http://localhost:3001/static/media/fin.41ee2fd5d61f6cd77a20926754660854.svg" alt=22 style="width: 3em">',
    },
    {
      lat: 37.4867995957995,
      lng: 126.982211871752,
      content:
        '<img src="	http://localhost:3001/static/media/failfin.66635514726981e7a0bbba7e23e5b030.svg" alt=22 style="width: 3em">',
    },
  ]);
  const mapRef = useRef<any>();
  const initMap = () => {
    mapRef.current = new window.naver.maps.Map('map', {
      center: new window.naver.maps.LatLng(37.511337, 127.012084),
      zoom: 13,
      scaleControl: false,
      // zoomControl: true,
      minZoom: 6,
    });
    markers.map((marker: any) => {
      const newMarkers = new window.naver.maps.Marker({
        position: new window.naver.maps.LatLng(marker.lat, marker.lng),
        map: mapRef.current,
        // icon: {
        //   content: marker.content,
        // },
      });
    });
  };

  useEffect(() => {
    const newBounds = {
      north: 37.39230584065902,
      east: 37.37230584065902,
      south: 37.17230584065902,
      west: 37.17230584065902,
    };
    const newCoords = coords.map(
      (coord: any) => new window.naver.maps.LatLng(coord.y, coord.x)
    );

    const newPolygon = new window.naver.maps.Polyline({
      map: mapRef.current,
      path: newCoords,
      strokeColor: '#f00',
      strokeWeight: 5,
      strokeOpacity: 0.6,
      // strokeStyle: 'shortdash',
      // fillColor: '#ff0000',
      // fillOpacity: 0.3,
      // strokeColor: '#ff0000',
      // strokeOpacity: 0.6,
      // strokeWeight: 3,
    });
    newCoords.map(coord => {
      const checkMarkers = new window.naver.maps.Marker({
        position: coord,
        map: mapRef.current,
      });
    });
  }, [coords]);

  useEffect(() => {
    initMap();
    console.log('currrr>>>>>>', mapRef.current.addListener);
  }, [mapRef]);
  const mapStyle = {
    width: '100%',
    height: '900px',
  };

  const ttest = () => {
    mapRef.current.addListener('click', (evt: any) => {
      const newCoord = { x: evt.coord.x, y: evt.coord.y };
      setCoords([...coords, newCoord]);
      console.log('newCoord', newCoord);
    });
  };
  mapRef.current?.addListener('click', (evt: any) => {
    const newCoord = { x: evt.coord.x, y: evt.coord.y };
    setCoords([...coords, newCoord]);
    console.log('newCoord', coords);
  });
  mapRef.current?.addListener('rightclick', (evt: any) => {
    // eslint-disable-next-line no-underscore-dangle
    console.log('currrr>>>>>>', mapRef.current);
  });

  const newBounds = {
    north: 37.39230584065902,
    east: 37.37230584065902,
    south: 37.17230584065902,
    west: 37.17230584065902,
  };
  // window.naver.maps.Event.once(mapRef.current, 'init', () => {
  //   const drawingManager = new window.naver.maps.drawing.DrawingManager({
  //     map: mapRef.current,
  //     rectangleOptions: {
  //       fillColor: '#ff0000',
  //       fillOpacity: 0.5,
  //       strokeWeight: 3,
  //       strokeColor: '#ff0000',
  //       bounds: newBounds,
  //     },
  //   });
  // });

  return (
    <Paper className='root' sx={{ mt: '10em' }}>
      <div id='map' style={mapStyle} ref={mapRef} aria-hidden />
    </Paper>
  );
};

// new window.naver.maps.LatLng(37.37230584065902, 127.10791110992432),
//         new window.naver.maps.LatLng(37.35975408751081, 127.10795402526855),
//         new window.naver.maps.LatLng(37.359924641705476, 127.11576461791992),
//         new window.naver.maps.LatLng(37.35931064479073, 127.12211608886719),
//         new window.naver.maps.LatLng(37.36043630196386, 127.12293148040771),
//         new window.naver.maps.LatLng(37.36354029942161, 127.12310314178465),
//         new window.naver.maps.LatLng(37.365211629488016, 127.12456226348876),
//         new window.naver.maps.LatLng(37.37544345085402, 127.11224555969238),
// _startDistance: function() {
//   var map = this.map;

//   this._distanceListeners = [
//       window.naver.maps.Event.addListener(map, 'click', function(e) {
//         var map = this.map,
//             coord = e.coord;

//         if (!this._polyline) {
//             // 임시로 보여줄 점선 폴리라인을 생성합니다.
//             this._guideline = new window.naver.maps.Polyline({
//                 strokeColor: '#f00',
//                 strokeWeight: 5,
//                 strokeStyle: [4, 4],
//                 strokeOpacity: 0.6,
//                 path: [coord],
//                 map: map
//             });

//             $(document).on('mousemove.measure',function(e) {
//               var map = this.map,
//                   proj = this.map.getProjection(),
//                   coord = proj.fromPageXYToCoord(new window.naver.maps.Point(e.pageX, e.pageY));
//                   path = this._guideline.getPath();

//               if (path.getLength() === 2) {
//                   path.pop();
//               }

//               path.push(coord);
//             });
//             this._distanceListeners.push(window.naver.maps.Event.addListener(map, 'rightclick', function() {
//               window.naver.maps.Event.removeListener(this._distanceListeners);
//               delete this._distanceListeners;

//               $(document).off('mousemove.measure');

//               if (this._guideline) {
//                   this._guideline.setMap(null);
//                   delete this._guideline;
//               }

//               if (this._polyline) {
//                   var path = this._polyline.getPath(),
//                       lastCoord = path.getAt(path.getLength() - 1),
//                       distance = this._polyline.getDistance();
//                       // 폴리라인의 거리를 미터 단위로 반환합니다.

//                   delete this._polyline;

//                   if (lastCoord) {
//                       this._addMileStone(lastCoord, this._fromMetersToText(distance), {
//                           'font-size': '14px',
//                           'font-weight': 'bold',
//                           'color': '#f00'
//                       });
//                   }
//               }

//               this.$btnDistance.removeClass('control-on').blur();

//               map.setCursor('auto');

//               delete this._lastDistance;
//               this._mode = null;
//             }));

//             // 실제 거리재기에 사용되는 폴리라인을 생성합니다.
//             this._polyline = new window.naver.maps.Polyline({
//                 strokeColor: '#f00',
//                 strokeWeight: 5,
//                 strokeOpacity: 0.8,
//                 path: [coord],
//                 map: map
//             });

//             // 폴리라인의 거리를 미터 단위로 반환합니다.
//             this._lastDistance = this._polyline.getDistance();
//         } else {
//             this._guideline.setPath([e.coord]);
//             this._polyline.getPath().push(coord);

//             // 폴리라인의 거리를 미터 단위로 반환합니다.
//             var distance = this._polyline.getDistance();

//             this._addMileStone(coord, this._fromMetersToText(distance - this._lastDistance));

//             this._lastDistance = distance;
//         }
//       }))
//   ];

//   map.setCursor("url('"+ HOME_PATH +"/img/rule.cur'), default");
// },
