import {
  Box,
  Button,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { WalkwayCreate } from './WalkwayCreate';
import { WalkwayPointCreate } from './WalkwayPointCreate';
import { WalkwayRegionSort } from './WalkwayRegionSort';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { IWalkwayCommand } from '../../../models/walkway';

interface Props {}

export const Walkway: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const [walkwayCommand, setWalkwayCommand] = useState<any>({
    localId: '',
    walkwayId: '',
    reset: false,
    isSend: 0,
  });
  const [walkwayId, setWalkwayId] = useState<string>('');
  const [localId, setLocalId] = useState<string>('');
  const [reset, setReset] = useState<boolean>(false);
  const [isSend, setIsSend] = useState<number>(0);
  const [locationLimit, setLocationLimit] = useState<any>([]);
  const [reload, setReload] = useState<string>('');

  const confirmSave = () => {
    const newWalkwayCommand = {
      localId: walkwayCommand.localId,
      walkwayId: walkwayCommand.walkwayId,
      reset: walkwayCommand.reset,
      isSend: 1,
    };
    setWalkwayCommand(newWalkwayCommand);
  };

  return (
    <>
      <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
        <Helmet>
          <title>워크웨이</title>
        </Helmet>
        <WalkwayRegionSort
          setWalkwayCommand={setWalkwayCommand}
          reload={reload}
        />
        <WalkwayCreate
          walkwayCommand={walkwayCommand}
          setWalkwayCommand={setWalkwayCommand}
          setReload={setReload}
        />
        <WalkwayPointCreate walkwayCommand={walkwayCommand} reload={reload} />
      </div>
    </>
  );
};
