import { createTheme, ThemeProvider } from '@mui/material';
import React, { ReactNode } from 'react';
import { koKR } from '@mui/x-data-grid';
import { koKR as corekoKR } from '@mui/material/locale';

const SwallabyThm = createTheme(
  {
    palette: {
      mode: 'light',
      primary: {
        main: '#2581FF',
        contrastText: '#FFF',
      },
      secondary: {
        main: '#FFF',
        contrastText: '#2E3A59',
      },
      error: {
        main: '#858C97',
        contrastText: '#FFF',
      },
      warning: {
        main: '#F63454',
        contrastText: '#FFF',
      },
      info: {
        main: '#6B73A2',
        contrastText: '#FFF',
      },
      success: {
        main: '#1FCD7A',
        contrastText: '#FFF',
      },
      background: {
        default: '#F5F7FA',
        paper: '#FFFFFF',
      },
      text: {
        primary: '#2E3A59',
        secondary: '#424242',
      },
    },
    typography: {
      fontFamily: [
        'Noto Sans KR',
        'Inter',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Helvetica Neue',
        'Arial',
        'sans-serif',
        'Apple Color Emoji',
        'Segoe UI Emoji',
        'Segoe UI Symbol',
      ].join(','),
      fontSize: 15,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 600,
      fontWeightBold: 700,
      h1: {
        fontSize: '1.8rem',
        fontWeight: 800,
        lineHeight: 1.25,
      },
      h2: {
        fontSize: '1.5rem',
        fontWeight: 700,
        lineHeight: 1.25,
      },
      h3: {
        fontSize: '1.25rem',
        fontWeight: 600,
        lineHeight: 1.25,
      },
      h4: {
        fontSize: '1.125rem',
        fontWeight: 600,
        lineHeight: 1.25,
      },
      h5: {
        fontSize: '1.0625rem',
        fontWeight: 500,
        lineHeight: 1.25,
      },
      h6: {
        fontSize: '1rem',
        fontWeight: 500,
        lineHeight: 1.25,
      },
      subtitle1: {
        fontSize: '1.1rem',
        fontWeight: 400,
        lineHeight: 1.25,
      },
      subtitle2: {
        fontSize: '1.5rem',
        fontWeight: 400,
        lineHeight: 1.25,
      },
      body1: {
        fontSize: 14,
        fontWeight: 400,
      },
      button: {
        textTransform: 'none',
        fontWeight: 400,
        // fontSize: '1rem',
        fontSize: 14,
        lineHeight: 1.75,
        letterSpacing: '0.02857em',
        disableRipple: true,
      },
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label.Mui-focused': {
              color: '#2581FF',
            },
            '& .MuiOutlinedInput-root': {
              '&:hover fieldset': {
                borderColor: '#785AEC',
              },
              height: '2.6rem',
              borderRadius: '10px',
              paddingRight: '.7rem',
            },
            '& .MuiOutlinedInput-input': {
              padding: '.5rem 1rem',
            },
            maxWidth: '100%',
            marginBottom: '.4rem',
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            padding: '.5rem',
            margin: '1rem .2rem',
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          root: {
            '& .MuiDialog-paper': {
              borderRadius: '12px',
              '& .MuiDialogTitle-root': {
                padding: '.5rem 1rem',
              },
              '& .MuiDialogActions-root': {
                padding: '.9rem',
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            padding: '5px 15px',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow:
              '0px 2px 1px -1px rgb(0 0 0 / 9%), 0px 1px 1px 0px rgb(0 0 0 / 8%), 0px 1px 3px 0px rgb(0 0 0 / 6%)',
            borderRadius: '12px',
          },
        },
      },
    },
  },
  koKR,
  corekoKR
);

interface Props {
  children: JSX.Element | ReactNode;
}

export const SwTheme: React.FC<Props> = ({ children }) => (
  <ThemeProvider theme={SwallabyThm}>{children}</ThemeProvider>
);
