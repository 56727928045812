import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { useSession } from '../../hooks/session';
import { SwPagination } from './tableElement/SwPagination';
import { encodeSearchWord } from '../../common/helper';
import { KEY_GALLERY_IMAGE_LIST } from '../../common/key';
import {
  getGalleryImageList,
  registerGalleryImage,
} from '../../common/api/ApiGallery';
import { uploadFile } from '../../common/api/ApiUpload';
import { SwSnackbar } from './views/SwSnackbar';
import { SwSwitch } from '../styles/Styles';

interface Props {
  title?: string;
}

export const SwImageGalleryView: React.FC<Props> = ({ title }) => {
  const { loginVal } = useSession();
  const queryClient = useQueryClient();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [galleryImageList, setGalleryImageList] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [reqRequest, setReqRequest] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [imageTagType, setImageTagType] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const rowsPerPageOptions = [5, 8, 10, 15, 20];
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const { refetch: galleryImageRefetch } = useQuery(
    KEY_GALLERY_IMAGE_LIST,
    () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: 'all',
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (reqRequest) return getGalleryImageList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res) {
          setGalleryImageList(res.image);
          setTotalRecords(res.pagination.totalRecordCount);
        }
        setLoading(false);
      },
      onError: e => {
        console.log(e);
        setLoading(false);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_GALLERY_IMAGE_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: 'all',
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getGalleryImageList(newData);
    });
  };

  const { mutate: postGalleryImageMutate, isError: isMutateError } =
    useMutation(registerGalleryImage, {
      onSuccess: () => {
        galleryImageRefetch();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '이미지 업로드가 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  async function uploadMultipleFiles(newImage: any) {
    var newUploadUrl = '';
    if (newImage.file !== null) {
      const newUploadFile: any = await uploadFile('gallery', newImage.file);
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }
    const newData: any = {
      fileName: newImage.fname,
      path: newUploadUrl,
    };
    postGalleryImageMutate(newData);
  }

  const handleUploadFile = (targetFile: any) => {
    const newImage = {
      name: targetFile.name,
      file: targetFile,
      fname: targetFile.name,
    };
    uploadMultipleFiles(newImage);
  };

  const searchGallery = () => {
    galleryImageRefetch();
  };

  const handleKeyDown = (e: any, value: number) => {
    if (e.key === 'Enter') {
      searchGallery();
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const handleClickImage = async (idx: number) => {
    try {
      const text = imageTagType
        ? `<img src="${loginVal.value.user.cloudFront}${galleryImageList[idx].path}" width="100px"/>`
        : `![title](${loginVal.value.user.cloudFront}${galleryImageList[idx].path})`;
      if (navigator.clipboard !== undefined) {
        await navigator.clipboard.writeText(text);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        textArea.setSelectionRange(0, 99999);
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('복사 실패', err);
        }
        textArea.setSelectionRange(0, 0);
        document.body.removeChild(textArea);
      }
    } catch (error) {
      console.log('er>', error);
    }
  };

  useEffect(() => {
    if (reqRequest) galleryImageRefetch();
  }, [reqRequest]);

  useEffect(() => {
    setReqRequest(true);
  }, []);

  return (
    <>
      <Box className='flex_between' sx={{ mb: '.5rem' }}>
        <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
          <SwSwitch
            checked={imageTagType}
            onChange={() => setImageTagType(!imageTagType)}
          />
          <Typography>이미지 태그 형식</Typography>
        </Stack>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => {
            const newFile1 = document.getElementById('gallery_file');
            newFile1?.click();
          }}
        >
          이미지 추가하기
        </Button>
      </Box>
      <input
        className='nonedisplay'
        id='gallery_file'
        type='file'
        onChange={(evt: ChangeEvent<HTMLInputElement>) => {
          evt.target.files && handleUploadFile(evt.target.files[0]);
        }}
      />
      <Box>
        <TextField
          placeholder='제목으로 검색해 주세요.'
          value={searchWord}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setSearchWord(evt.target.value)}
          onKeyDown={evt => {
            handleKeyDown(evt, 0);
          }}
          sx={{ mt: '.5rem', width: '100%' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={() => searchGallery()}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box
        sx={{
          widht: '100%',
          height: '56vh',
          mt: '1rem',
          mb: '1rem',
          overflowY: 'scroll',
        }}
      >
        {galleryImageList && galleryImageList.length > 0 ? (
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {galleryImageList.map((image: any, index: number) => (
              <Grid item xs={2} sm={6} md={6} key={`image_key_${index}`}>
                <Button onClick={() => handleClickImage(index)}>
                  <img
                    src={`${loginVal.value.user.cloudFront}${image.path}`}
                    alt={`alt-${index}`}
                    style={{ height: '6rem' }}
                  />
                </Button>
                <Typography>{image.name}</Typography>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box>
            <Box>데이터가 없습니다.</Box>
          </Box>
        )}
      </Box>
      {galleryImageList && galleryImageList.length > 0 && (
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={galleryImageList && Math.ceil(totalRecords / rowsPerPage)}
        />
      )}
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </>
  );
};
