import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  MenuItem,
  Pagination,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  checkNaN,
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
} from './ContentManageStyles';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  generateContentManageMagazineHtml,
  registerContentManageContent,
} from '../../../common/api/ApiContentManage';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import { SwTemplateCodeConnect } from '../../commonComponent/program/SwTemplateCodeConnect';
import { SwInputLink } from '../../commonComponent/program/SwInputLink';
import { SwSelectKeyword } from '../../commonComponent/program/SwSelectKeyword';
import { KEY_PROGRAM_TEMPLATE_CODE_LIST } from '../../../common/key';
import { getProgramTemplateCodeList } from '../../../common/api/ApiProgram';
import { getFileSize, getMagazineSubType } from '../../../common/helperProgram';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_UPLOAD_FILE_SIZE,
} from '../../commonComponent/program/SwProgramCommon';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';

const WALKING_CALORIE = 1.7;
const INIT_UNIT_SELECTOR = 'g';
const intakeUnitList = ['g', 'mg', 'ml', 'mcg'];
const keywordIntakeUnitList = ['g', 'mg', '㎍', 'ml'];
const difficultyList = [
  { name: '상', value: 1 },
  { name: '중', value: 2 },
  { name: '하', value: 3 },
];

interface Props {
  contentManageInfo: any;
  setContentManageInfo: Dispatch<React.SetStateAction<any>>;
  reloadContentManage: (id: any) => void;
}

export const ContentManageContentAudioForm: React.FC<Props> = ({
  contentManageInfo,
  setContentManageInfo,
  reloadContentManage,
}) => {
  const location = useLocation();
  const { loginVal } = useSession();
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [subType, setSubType] = useState<number>(1);
  const [contentId, setContentId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [intake, setIntake] = useState<number>(0);
  const [selectUnit, setSelectUnit] = useState<any>(INIT_UNIT_SELECTOR);
  const [difficulty, setDifficulty] = useState<number>(1);
  const [exerciseTime, setExerciseTime] = useState<number>(10);
  const [calorie, setCalorie] = useState<number>(0);
  const [keywordSelectUnit, setKeywordSelectUnit] = useState<any>('select');
  const [description, setDescription] = useState<string>('');
  const [thumbImage, setThumbImage] = useState<string>('');
  const [thumbImageFile, setThumbImageFile] = useState<any>(null);
  const [htmlFileName, setHtmlFileName] = useState<string>('');
  const [uploadMagazineImage, setUploadMagazineImage] = useState<any[]>([]);
  const [uploadMagazineFile, setUploadMagazineFile] = useState<any[]>([]);
  const [uploadMagazineLink, setUploadMagazineLink] = useState<any[]>([]);
  const [linkInfo, setLinkInfo] = useState<any>();
  const [audioContentPath, setAudioContentPath] = useState<any>('');
  const [audioContentName, setAudioContentName] = useState<any>('');
  const [audioContentPathFile, setAudioContentPathFile] = useState<any>();
  const [audioContentProducer, setAudioContentProducer] = useState<string>('');
  const [mainPlayTime, setMainPlayTime] = useState<number>(0);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [cloudFront, setCloudFront] = useState<string>(
    loginVal.value.user.cloudFront
  );
  const [customContentType, setCustomContentType] = useState<string>('none');
  const [seq, setSeq] = useState<number>(0);
  const [status, setStatus] = useState<number>(1);
  const [selectedContentManage, setSelectedContentManage] =
    useState<boolean>(false);
  const [findTemplateCode, setFindTemplateCode] = useState<string>('');
  const [connectTemplateCode, setConnectTemplateCode] = useState<any[]>([]);
  const [targetInfo, setTargetInfo] = useState<any>();
  const [keywordInfo, setKeywordInfo] = useState<any[]>([]);
  const [highKeywordInfo, setHighKeywordInfo] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openLink, setOpenLink] = useState<boolean>(false);
  const [openKeyword, setOpenKeyword] = useState<boolean>(false);
  const keywordItems = document.querySelectorAll('.keyword_draggable');
  const highKeywordItems = document.querySelectorAll('.high_keyword_draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  const [highListEvent, setHighListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  const [templateCodeList, setTemplateCodeList] = useState<any[]>([]);

  const {
    mutate: registerContentManageContentMutation,
    isError: isMutateError,
  } = useMutation(registerContentManageContent, {
    onSuccess: res => {
      console.log('registerContentManageContent res:', res);
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('저장되었습니다.');
        reloadContentManage({
          id: categoryId,
          parentCategoryId: parentCategoryId,
        });
      } else {
        setAlertOpen(true);
        setAlertMessage(
          `저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '콘텐츠를 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const addContentManageContent = (
    newUploadUrl: string,
    newAudioContentPathUrl: string,
    newAudioContentName: string,
    newMainPlayTime: number
  ) => {
    const newThumbImage =
      thumbImageFile !== null
        ? newUploadUrl
        : thumbImage !== null || thumbImage !== ''
        ? thumbImage
        : '';

    const newIdx =
      contentManageInfo.idx !== undefined && contentManageInfo.idx !== null
        ? contentManageInfo.idx
        : -1;
    const tmpKeywordList = [...keywordInfo, ...highKeywordInfo];
    const newKeywordList = tmpKeywordList.map((item: any) => {
      const newData: any = {
        t: item.title,
        h: item.isHigh,
      };
      if (item.showUnit) {
        newData.v = item.intake !== undefined ? item.intake : '';
        newData.u =
          item.unit !== undefined && item.unit !== 'select' ? item.unit : '';
      }
      return newData;
    });

    const newData: any = {
      idx: newIdx,
      type: 'audio',
      categoryId: categoryId,
      contentId: contentId,
      title: title,
      source: audioContentProducer,
      audioContentName: newAudioContentName,
      audioContentUrl: newAudioContentPathUrl,
      playTime: newMainPlayTime,
      keyword: newKeywordList.length > 0 ? JSON.stringify(newKeywordList) : '',
      thumbImage: newThumbImage,
      status: status,
      seq: seq,
    };
    console.log('addContentManageContent:', newData);
    registerContentManageContentMutation(newData);
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: keywordInfo,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setKeywordInfo(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    keywordItems.forEach(item => item.classList.remove('drag_over'));
    // changeCommunityGroupOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setKeywordInfo(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    keywordItems.forEach(item => item.classList.remove('drag_over'));
  };

  // High Keyword
  const dragStartHigh = (evt: DragEvent<HTMLDivElement>) => {
    setHighListEvent({
      ...highListEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: highKeywordInfo,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const dropHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = highListEvent.currentDrag;
    const filterList = highListEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== highListEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      highListEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setHighKeywordInfo(newList);

    setHighListEvent({
      currentDrag: null,
      over: null,
      ...highListEvent,
    });
  };

  const dragEndHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    highKeywordItems.forEach(item => item.classList.remove('drag_over_high'));
    // changeCommunityGroupOrder();
  };

  const dragEnterHigh = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setHighListEvent({ ...highListEvent, over: newOver });
  };

  const dragOverHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over_high');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = highListEvent.currentDrag;
    const filterList = highListEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      highListEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    highListEvent.over !== newOver && setHighKeywordInfo(newList);
  };

  const dragLeaveHigh = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    highKeywordItems.forEach(item => item.classList.remove('drag_over_high'));
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (thumbImageFile !== null) {
      const newUploadFile: any = await uploadFile('program', thumbImageFile);
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newAudioContentPathUrl = '';
    var newMainPlayTime = mainPlayTime;
    var newAudioContentPathName = '';
    if (audioContentPathFile !== null && audioContentPathFile.file !== null) {
      newAudioContentPathName = audioContentPathFile.name;
      const newUploadFile: any = await uploadFile(
        'program',
        audioContentPathFile.file
      );
      newAudioContentPathUrl = newUploadFile.data.uploadUrl.file.path;
      newMainPlayTime = newUploadFile.data.uploadUrl.file.duration;
    } else {
      newAudioContentPathUrl = audioContentPath || '';
      newAudioContentPathName = audioContentName || '';
      newMainPlayTime = mainPlayTime;
    }

    addContentManageContent(
      newUploadUrl,
      newAudioContentPathUrl,
      newAudioContentPathName,
      newMainPlayTime
    );
  }

  const checkValid = () => {
    var val = 0;

    if (val === 0 && title.length === 0) val = 1;
    if (val === 0 && categoryId.length === 0) val = 4;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '제목을 입력해주세요.';
          break;
        case 4:
          message = '카테고리를 선택해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }

    return val;
  };

  const saveData = () => {
    console.log('saveData -> :', contentManageInfo);
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return false;
    }

    if (
      thumbImageFile !== undefined &&
      thumbImageFile !== null &&
      getFileSize(thumbImageFile) >= MAX_UPLOAD_FILE_SIZE
    ) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return false;
    }

    if (
      audioContentPathFile !== undefined &&
      audioContentPathFile !== null &&
      getFileSize(audioContentPathFile) >= MAX_UPLOAD_FILE_SIZE
    ) {
      setOpenSnackbar(true);
      setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
      return false;
    }
    console.log('saveData -> :', contentManageInfo);
    uploadMultipleFiles();
    return true;
  };

  const resetContent = () => {
    setTitle('');
    setKeywordInfo([]);
    setHighKeywordInfo([]);
    setUploadMagazineImage([]);
    setDescription('');
    setStatus(1);
    setThumbImage('');
    setThumbImageFile(null);
    setAudioContentName('');
    setAudioContentPath('');
    setAudioContentPathFile(null);
    setAudioContentProducer('');
    setSeq(1);
  };

  useEffect(() => {
    console.log('keywordInfo:', keywordInfo);
  }, [keywordInfo]);

  useEffect(() => {
    if (
      contentManageInfo.contentId !== undefined &&
      contentManageInfo.contentId !== null
    ) {
      setTitle(contentManageInfo.title);
      setStatus(contentManageInfo.status === false ? 0 : 1);
      setSeq(contentManageInfo.seq);
      setThumbImage(contentManageInfo.thumbImage);
      setThumbImageFile(null);
      setAudioContentName(contentManageInfo.audioContentName);
      setAudioContentPath(contentManageInfo.audioContentPath);
      setAudioContentPathFile(null);
      setAudioContentProducer(contentManageInfo.source);
      setSubType(
        contentManageInfo.subType !== null ? contentManageInfo.subType : 4
      );
      setMainPlayTime(contentManageInfo.playTime);

      const tmpKeyword =
        contentManageInfo.keyword !== undefined &&
        contentManageInfo.keyword.length > 0
          ? JSON.parse(contentManageInfo.keyword)
          : [];
      const newKeyword = tmpKeyword
        .filter((item: any) => item.h === 0)
        .map((item: any) => {
          const newData: any = {
            intake: item.v !== undefined ? item.v : 0,
            unit: item.u !== undefined ? item.u : 'select',
            title: item.t,
            isHigh: item.h,
            showUnit: item.u !== undefined ? 1 : 0,
          };
          return newData;
        });
      const newHighKeyword = tmpKeyword
        .filter((item: any) => item.h === 1)
        .map((item: any) => {
          const newData: any = {
            intake: item.v !== undefined ? item.v : 0,
            unit: item.u !== undefined ? item.u : 'select',
            title: item.t,
            isHigh: item.h,
            showUnit: item.u !== undefined ? 1 : 0,
          };
          return newData;
        });
      setKeywordInfo(newKeyword);
      setHighKeywordInfo(newHighKeyword);

      const tmpImage =
        contentManageInfo.image !== undefined &&
        contentManageInfo.image.length > 0
          ? JSON.parse(contentManageInfo.image)
          : [];
    } else {
      resetContent();
    }
  }, [contentId]);

  useEffect(() => {
    console.log('contentManageInfo -> :', contentManageInfo);
    if (contentManageInfo !== undefined && contentManageInfo !== null) {
      if (contentManageInfo.save) {
        if (saveData() === false) return;
      }
      setContentId(
        contentManageInfo.contentId !== undefined &&
          contentManageInfo.contentId !== null
          ? contentManageInfo.contentId
          : newUuidId()
      );
      setCategoryId(
        contentManageInfo.categoryId !== undefined &&
          contentManageInfo.categoryId !== null
          ? contentManageInfo.categoryId
          : ''
      );
      setParentCategoryId(
        contentManageInfo.parentCategoryId !== undefined &&
          contentManageInfo.parentCategoryId !== null
          ? contentManageInfo.parentCategoryId
          : ''
      );
      setSubType(
        contentManageInfo.subType !== null ? contentManageInfo.subType : 4
      );
    }
  }, [contentManageInfo]);

  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>오디오 제목</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={title}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setTitle(evt.target.value.substring(0, 30))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{title?.length} / 30</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>콘텐츠 등록</Typography>
          <Box className='flex_start'>
            <TextField
              sx={{ width: '48rem' }}
              placeholder='`등록`버튼 선택'
              value={
                audioContentPathFile !== null
                  ? audioContentPathFile?.name
                  : audioContentName
              }
            />
            <Button
              sx={{ ml: '1rem', mr: '1rem' }}
              color='info'
              variant='contained'
              onClick={() => {
                const newFile = document.getElementById('audio_content_file');
                newFile?.click();
              }}
            >
              등록
            </Button>
            <input
              className='nonedisplay'
              id='audio_content_file'
              accept='audio/*'
              type='file'
              onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                if (evt.target.files) {
                  const newData: any = {
                    name: evt.target.files[0].name,
                    file: evt.target.files[0],
                  };
                  setAudioContentPathFile(newData);
                }
              }}
            />
          </Box>
          <Typography className=''>
            {' '}
            play time : {mainPlayTime > 0 ? `${mainPlayTime} sec` : '미등록'}
          </Typography>
        </Box>
        <Box sx={{ mt: '1rem' }}>
          <Typography className='title'>제작</Typography>
          <TextField
            sx={{ width: '48rem' }}
            placeholder='제작처 입력'
            value={audioContentProducer}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setAudioContentProducer(event.target.value)}
          />
        </Box>
        <Divider />
        <Box>
          <Typography className='title'>썸네일 이미지</Typography>
          <SwFileSetting
            image={
              thumbImageFile != null
                ? thumbImage
                : thumbImage !== ''
                ? `${imageUrlPrefix}${thumbImage}`
                : ''
            }
            setImage={setThumbImage}
            setFile={setThumbImageFile}
            file={thumbImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>키워드</Typography>
          </Box>
          <Box>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setOpenKeyword(true);
              }}
            >
              키워드 추가
            </Button>
          </Box>
        </Box>
        <Box>
          {keywordInfo.map((keyword: any, idx: number) => (
            <Box key={`drag_keyword_${idx}`} sx={{ display: 'flex' }}>
              <DraggablePaper
                className='keyword_draggable'
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                onDragOver={dragOver}
                onDrop={drop}
                onDragLeave={dragLeave}
                onDragEnter={dragEnter}
                draggable
                data-position={idx}
                sx={{ width: '60rem' }}
              >
                <Box className='flex_between'>
                  <Box className='flex_start'>
                    <DragHandleIcon />
                    <Box sx={{ ml: '1rem', width: '15rem' }}>
                      <Typography>{keyword.title}</Typography>
                    </Box>
                  </Box>
                  <Box className='flex_end' sx={{ width: '40rem' }}>
                    {keyword.showUnit === 99 && (
                      <Box>
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          placeholder='수치입력'
                          value={
                            keyword.intake !== undefined ? keyword.intake : ''
                          }
                          onChange={(
                            evt: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newKeywordInfo = [...keywordInfo];
                            newKeywordInfo[idx].intake = checkNaN(
                              evt.target.value,
                              keyword.intake
                            );
                            setKeywordInfo(newKeywordInfo);
                          }}
                        />
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          select
                          defaultValue='select'
                          value={
                            keyword.unit !== undefined ? keyword.unit : 'select'
                          }
                          onChange={evt => {
                            const newKeywordInfo = [...keywordInfo];
                            newKeywordInfo[idx].unit = evt.target.value;
                            setKeywordInfo(newKeywordInfo);
                          }}
                        >
                          <MenuItem key='magazine_unit_select' value='select'>
                            선택
                          </MenuItem>
                          {keywordIntakeUnitList.map(
                            (option: any, i: number) => (
                              <MenuItem
                                key={`magazine_keyword_unit_${option}`}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={`keyword_cb_${idx}`}
                              sx={{ color: '#808383' }}
                              onChange={e => {
                                if (e.currentTarget.checked) {
                                  keyword.isHigh = 1;
                                  const newHighKeywordInfo = [
                                    ...highKeywordInfo,
                                    keyword,
                                  ];
                                  setHighKeywordInfo(newHighKeywordInfo);

                                  const newKeywordInfo = keywordInfo.filter(
                                    (item: any, i: number) => i !== idx
                                  );
                                  setKeywordInfo(newKeywordInfo);
                                }
                              }}
                              checked={keyword.isHigh}
                            />
                          }
                          label='고함량 여부'
                        />
                      </Box>
                    )}
                    <Button
                      onClick={() => {
                        const newKeywordInfo = keywordInfo.filter(
                          (item: any, i: number) => i !== idx
                        );
                        setKeywordInfo(newKeywordInfo);
                      }}
                    >
                      <CancelIcon
                        sx={{ fontSize: '1.2rem', color: '#000000' }}
                      />
                    </Button>
                  </Box>
                </Box>
              </DraggablePaper>
            </Box>
          ))}
        </Box>
        {highKeywordInfo.length > 0 && (
          <Box sx={{ mt: '1rem' }}>
            <Typography>고함량 키워드</Typography>
            <Typography sx={{ color: '#999999' }}>
              앱 내에서 고함량으로 표시됩니다.
            </Typography>
          </Box>
        )}
        <Box>
          {highKeywordInfo.map((keyword: any, idx: number) => (
            <Box key={`drag_high_keyword_${idx}`} sx={{ display: 'flex' }}>
              <DraggablePaper
                className='high_keyword_draggable'
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStartHigh}
                onDragEnd={dragEndHigh}
                onDragOver={dragOverHigh}
                onDrop={dropHigh}
                onDragLeave={dragLeaveHigh}
                onDragEnter={dragEnterHigh}
                draggable
                data-position={idx}
                sx={{ width: '70%' }}
              >
                <Box className='flex_between'>
                  <Box className='flex_start'>
                    <DragHandleIcon />
                    <Box sx={{ ml: '1rem', width: '15rem' }}>
                      <Typography>{keyword.title}</Typography>
                    </Box>
                  </Box>
                  <Box className='flex_end'>
                    {keyword.showUnit === 1 && (
                      <Box>
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          placeholder='수치입력'
                          value={
                            keyword.intake !== undefined ? keyword.intake : ''
                          }
                          onChange={(
                            evt: ChangeEvent<
                              HTMLTextAreaElement | HTMLInputElement
                            >
                          ) => {
                            const newKeywordInfo = [...highKeywordInfo];
                            newKeywordInfo[idx].intake = checkNaN(
                              evt.target.value,
                              keyword.intake
                            );
                            setHighKeywordInfo(newKeywordInfo);
                          }}
                        />
                        <TextField
                          sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                          select
                          defaultValue='select'
                          value={
                            keyword.unit !== undefined ? keyword.unit : 'select'
                          }
                          onChange={evt => {
                            const newKeywordInfo = [...highKeywordInfo];
                            newKeywordInfo[idx].unit = evt.target.value;
                            setHighKeywordInfo(newKeywordInfo);
                          }}
                        >
                          <MenuItem key='magazine_unit_select' value='select'>
                            선택
                          </MenuItem>
                          {keywordIntakeUnitList.map(
                            (option: any, i: number) => (
                              <MenuItem
                                key={`magazine_keyword_unit_${option}`}
                                value={option}
                              >
                                {option}
                              </MenuItem>
                            )
                          )}
                        </TextField>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={`keyword_cb_${idx}`}
                              sx={{ color: '#808383' }}
                              onChange={e => {
                                if (!e.currentTarget.checked) {
                                  keyword.isHigh = 0;
                                  const newKeywordInfo = [
                                    ...keywordInfo,
                                    keyword,
                                  ];
                                  setKeywordInfo(newKeywordInfo);

                                  const newHighKeywordInfo =
                                    highKeywordInfo.filter(
                                      (item: any, i: number) => i !== idx
                                    );
                                  setHighKeywordInfo(newHighKeywordInfo);
                                }
                              }}
                              checked={keyword.isHigh}
                            />
                          }
                          label='고함량 여부'
                        />
                      </Box>
                    )}
                    <Button
                      onClick={() => {
                        const newHighKeywordInfo = highKeywordInfo.filter(
                          (item: any, i: number) => i !== idx
                        );
                        setHighKeywordInfo(newHighKeywordInfo);
                      }}
                    >
                      <CancelIcon
                        sx={{ fontSize: '1.2rem', color: '#000000' }}
                      />
                    </Button>
                  </Box>
                </Box>
              </DraggablePaper>
            </Box>
          ))}
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>순서</Typography>
          </Box>
          <TextField
            value={seq}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSeq(checkNaN(evt.target.value, seq))}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            sx={{ width: '12rem' }}
            select
            defaultValue={1}
            value={status}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setStatus(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwInputLink
        open={openLink}
        onClose={() => setOpenLink(false)}
        linkInfo={linkInfo}
        setLinkInfo={setLinkInfo}
      />
      <SwSelectKeyword
        open={openKeyword}
        onClose={() => setOpenKeyword(false)}
        type={subType}
        keywordInfo={keywordInfo}
        setKeywordInfo={setKeywordInfo}
      />
    </>
  );
};
