import clsx from 'clsx';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useResize } from '../../../hooks/resize';
import { TabBox } from '../../styles/Styles';

interface Props {}

export const ContentManage: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const location = useLocation();
  const [openCreatePersonalCommunity, setOpenCreatePersonalCommunity] =
    useState(false);
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('contentmanage_movie');
        break;
      case 2:
        setValue(2);
        navigate('contentmanage_magazine');
        break;
      case 3:
        setValue(3);
        navigate('contentmanage_qna');
        break;
      case 4:
        setValue(4);
        navigate('contentmanage_lifestyle');
        break;
      case 5:
        setValue(5);
        navigate('contentmanage_audio');
        break;
      default:
        setValue(0);
        navigate('/contentmanage');
    }
  };
  useEffect(() => {
    const path = location.pathname;
    if (path === '/contentmanage/contentmanage_movie') setValue(1);
    else if (path === '/contentmanage/contentmanage_magazine') setValue(2);
    else if (path === '/contentmanage/contentmanage_qna') setValue(3);
    else if (path === '/contentmanage/contentmanage_lifestyle') setValue(4);
    else if (path === '/contentmanage/contentmanage_audio') setValue(5);
    else setValue(1);
  }, [location.pathname]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>콘텐츠 관리</title>
      </Helmet>
      <TabBox className='flex_between'>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='영상' value={1} />
          <Tab label='매거진' value={2} />
          <Tab label='오디오' value={5} />
          <Tab label='질의응답' value={3} />
          <Tab label='생활습관' value={4} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
