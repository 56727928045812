export interface IStamptour {
  stampTourId: string;
  stampTourName: string;
  courseCenterPointLng: number;
  courseCenterPointLat: number;
  courseCenterRadius: number;
  creator: string;
  editor: string;
  stampTourImgpath: string;
  stamps: IStampInfo[];
  stampTourContent: string;
  stampTourShow: number;
  stampTourTags: string[];
  createTime: number;
}

export interface IStamptourInfo {
  stampTourId: string;
  stampTourName: string;
  courseCenterPointX: number;
  courseCenterPointY: number;
  courseCenterRadius: number;
  createTime: number;
  creator: string;
  editor: string;
  stampTourDel: number;
  stampTourImgpath: string;
  stampTourContent: string;
  stampTourShow: number;
  stampTourTags: string;
}

export interface IStampInfo {
  stampDel: string;
  stampDescription: string;
  stampId: string;
  stampImgPathSub: string;
  stampImgpathMain: string;
  stampName: string;
  stampPointLat: number;
  stampPointLng: number;
  stampRadius: number;
  stampSeq: number;
}

export interface IStamptourDetail {
  stamptour: IStamptourInfo;
  stamp: IStampInfo[];
}

export const DefaultStamptourInfo: IStamptourInfo = {
  stampTourId: '',
  stampTourName: '',
  courseCenterPointX: 0,
  courseCenterPointY: 0,
  courseCenterRadius: 0,
  createTime: 0,
  creator: '',
  editor: '',
  stampTourDel: 0,
  stampTourImgpath: '',
  stampTourContent: '',
  stampTourShow: 1,
  stampTourTags: '',
};

export const DefaultStampInfo: IStampInfo = {
  stampDel: '',
  stampDescription: '',
  stampId: '',
  stampImgPathSub: '',
  stampImgpathMain: '',
  stampName: '',
  stampPointLat: 0,
  stampPointLng: 0,
  stampRadius: 0,
  stampSeq: 0,
};

export const DefaultStamptourDetail: IStamptourDetail = {
  stamptour: DefaultStamptourInfo,
  stamp: [DefaultStampInfo],
};
