import clsx from 'clsx';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useResize } from '../../../hooks/resize';
import { TabBox } from '../../styles/Styles';

interface Props {}

export const ProgramManage: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const location = useLocation();
  const [openCreatePersonalCommunity, setOpenCreatePersonalCommunity] =
    useState(false);
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('create');
        break;
      case 2:
        setValue(2);
        navigate('list');
        break;
      case 3:
        setValue(3);
        navigate('templatecode');
        break;
      case 4:
        setValue(4);
        navigate('manageguide');
        break;
      default:
        setValue(0);
        navigate('/programmanage');
    }
  };
  useEffect(() => {
    const path = location.pathname;
    if (path === '/programmanage/create') setValue(1);
    else if (path === '/programmanage/list') setValue(2);
    else if (path === '/programmanage/templatecode') setValue(3);
    else if (path === '/programmanage/manageguide') setValue(4);
    else setValue(1);
  }, [location.pathname]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>프로그램 관리</title>
      </Helmet>
      <TabBox className='flex_between'>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='프로그램 생성' value={1} />
          <Tab label='프로그램 목록' value={2} />
          <Tab label='템플릿 코드' value={3} />
          <Tab label='운영 가이드' value={4} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
