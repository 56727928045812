import {
  Box,
  Button,
  Checkbox,
  Divider,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DndProvider } from 'react-dnd';
import {
  getBackendOptions,
  MultiBackend,
  NodeModel,
  Tree,
} from '@minoru/react-dnd-treeview';
import { Helmet } from 'react-helmet-async';
import { v4 as uuidv4 } from 'uuid';
import {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  Dispatch,
  SyntheticEvent,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { DraggablePaper } from '../../styles/Styles';
import { KEY_DATAPORTAL_GUIDE_MAMANGE_MAP } from '../../../common/key';
import {
  getPortalGuideManageMap,
  postDataPortalGuideCategory,
} from '../../../common/api/ApiDataPortal';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';

const initialData = [
  {
    id: 1,
    parent: 0,
    droppable: true,
    text: '',
  },
];

interface Props {}

export const PortalGuideCategoryManage: React.FC<Props> = () => {
  const [request, setRequest] = useState<boolean>(false);
  const [treeData, setTreeData] = useState(initialData);
  const [selectedNode, setSelectedNode] = useState<any>();
  const [nodeDepth, setNodeDepth] = useState<number>(0);
  const [contentCount, setContentCount] = useState<number>(0);
  const [contentManageMap, setContentManageMap] = useState<any[]>([]);
  const [arrayTreeData, setArrayTreeData] = useState<any[]>([]);
  const [title, setTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const addTreeData = (depth: number, parent: string, list: any) => {
    if (list === undefined || list === null || list.length === 0) return;
    list.map((node: any, idx: number) => {
      if (list.contentCount === 0 && list.nodeList.length === 0) {
        arrayTreeData.push({
          id: node.id,
          parent: parent,
          droppable: !(depth >= 3),
          text: node.title,
        });
      } else {
        arrayTreeData.push({
          id: node.id,
          parent: parent,
          droppable: !(depth >= 3),
          text: node.title,
        });
        addTreeData(depth + 1, node.id, node.nodeList);
      }
    });
  };

  const makeTreeData = (menuMap: any) => {
    if (menuMap === null || menuMap.length === 0) {
      return;
    }
    menuMap.map((list: any, idx: number) => {
      if (list.contentCount === 0 && list.nodeList.length === 0) {
        arrayTreeData.push({
          id: list.id,
          parent: 0,
          droppable: true,
          text: list.title,
        });
      } else {
        arrayTreeData.push({
          id: list.id,
          parent: 0,
          droppable: true,
          text: list.title,
        });
        addTreeData(2, list.id, list.nodeList);
      }
    });
  };

  const {
    data,
    isLoading,
    refetch: portalGuideManageMapRefetch,
  } = useQuery(
    KEY_DATAPORTAL_GUIDE_MAMANGE_MAP,
    () => {
      const newRequest = {
        sortWay: 1, // asc
      };
      if (request === true) return getPortalGuideManageMap(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.operationGuide !== undefined) {
          setContentCount(res.operationGuide.count);
          setContentManageMap(res.operationGuide.map);
          makeTreeData(res.operationGuide.map);
          setTreeData(arrayTreeData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const handleDrop = (newTreeData: any) => {
    setTreeData(newTreeData);
  };

  const handleSelect = (node: NodeModel, depth: number) => {
    setSelectedNode(node);
    setTitle(node.text);
    setNodeDepth(depth);
  };

  const updateTreeTitle = () => {
    const newTreeData = treeData.map((node: any, idx: number) => {
      let newNode = { ...node };
      if (node.id === selectedNode.id) {
        newNode = { ...newNode, text: title };
      }
      return newNode;
    });
    setTreeData(newTreeData);
  };

  const { mutate: postDataPortalGuideCategoryMutate, isError: isMutateError } =
    useMutation(postDataPortalGuideCategory, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('카테고리를 등록했습니다.');
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '카테고리 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const saveTreeData = () => {
    const result: any[] = [];
    const parentMap: any[] = [];

    treeData.forEach((node: any) => {
      if (!parentMap[node.parent]) {
        parentMap[node.parent] = 1;
      } else {
        parentMap[node.parent] += 1;
      }
      result.push({
        categoryId: node.id,
        parentCategoryId: node.parent,
        title: node.text,
        seq: parentMap[node.parent],
      });
    });
    // console.log('--> result:', result);
    const newData = {
      guideCategoryList: result,
    };
    postDataPortalGuideCategoryMutate(newData);
  };

  const addTreeNode = (type: number) => {
    const newTreeData: any[] = [];

    if (treeData.length === 0) {
      newTreeData.push({
        id: uuidv4().replace(/-/gi, '').toUpperCase(),
        parent: 0,
        text: `카테고리-${1}`,
        droppable: true,
      });
      setTreeData(newTreeData);
      return;
    }

    treeData.map((node: any, idx: number) => {
      newTreeData.push(node);
      if (node.id === selectedNode.id) {
        if (type === 1) {
          newTreeData.push({
            id: uuidv4().replace(/-/gi, '').toUpperCase(),
            parent: selectedNode.parent,
            text: `카테고리-${idx + 1}`,
            droppable: true,
          });
        } else {
          if (node.droppable === false || nodeDepth + 1 === 3) {
            setOpenSnackbar(true);
            setDialogMessage('하위 카테고리를 추가할 수 없습니다.');
            return;
          }
          newTreeData.push({
            id: uuidv4().replace(/-/gi, '').toUpperCase(),
            parent: selectedNode.id,
            text: `하위 카테고리-${idx + 1}`,
            droppable: nodeDepth + 1 < 2,
          });
        }
      }
    });
    setTreeData(newTreeData);
  };

  const deleteTreeNode = () => {
    const newTreeData = treeData.filter(node => {
      if (node.id === selectedNode.id) return false;
      let current: any = node;
      while (current.parent) {
        if (current.parent === selectedNode.id) return false;
        current = treeData.find(n => n.id === current.parent);
      }
      return true;
    });
    setTreeData(newTreeData);
  };

  useEffect(() => {
    if (request) portalGuideManageMapRefetch();
  }, [request]);

  useEffect(() => {
    setRequest(true);
  }, []);

  return (
    <div>
      <Helmet>
        <title>포털 카테고리 관리</title>
      </Helmet>
      <Paper sx={{ m: '1rem', p: '1.5rem' }}>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '.5rem 2rem 0' }}
            />
          }
        >
          <Paper sx={{ width: '40%', p: '1.5rem' }}>
            <Typography className='title'>카테고리</Typography>
            <Box sx={{ height: '35rem', overflowY: 'scroll' }}>
              <DndProvider backend={MultiBackend} options={getBackendOptions()}>
                <Tree
                  tree={treeData}
                  rootId={0}
                  sort={false}
                  onDrop={handleDrop}
                  dropTargetOffset={5}
                  insertDroppableFirst={false}
                  // eslint-disable-next-line consistent-return
                  canDrop={(tree, { dragSource, dropTargetId }) => {
                    if (dragSource?.parent === dropTargetId) {
                      return true;
                    }
                  }}
                  render={(node, { depth, isOpen, onToggle }) => (
                    <DraggablePaper sx={{ width: '90%' }} variant='outlined'>
                      <Box
                        className={
                          selectedNode && selectedNode.id === node.id
                            ? 'flex_start selected_row'
                            : 'flex_start'
                        }
                        onClick={() => handleSelect(node, depth)}
                        sx={{ width: '100%' }}
                      >
                        {node.droppable && (
                          <Box onClick={onToggle}>
                            {isOpen ? (
                              <KeyboardArrowDownIcon />
                            ) : (
                              <KeyboardArrowRightIcon />
                            )}
                          </Box>
                        )}
                        <Box>{node.text}</Box>
                      </Box>
                    </DraggablePaper>
                  )}
                />
              </DndProvider>
            </Box>
            <Divider />
            <Box className='flex_between'>
              <Box>
                <Button
                  variant='outlined'
                  sx={{ width: '7rem' }}
                  onClick={() => addTreeNode(1)}
                >
                  추가
                </Button>
                <Button
                  variant='outlined'
                  sx={{ width: '7rem', ml: '.5rem' }}
                  onClick={() => addTreeNode(2)}
                >
                  하위 추가
                </Button>
                <Button
                  variant='outlined'
                  color='warning'
                  sx={{ width: '7rem', ml: '.5rem' }}
                  onClick={() => deleteTreeNode()}
                >
                  삭제
                </Button>
              </Box>{' '}
              <Box>
                <Button
                  variant='contained'
                  sx={{ width: '7rem' }}
                  onClick={() => saveTreeData()}
                >
                  저장
                </Button>
              </Box>
            </Box>
          </Paper>
          <Paper sx={{ width: '60%', p: '1.5rem' }}>
            <Typography className='title'>카테고리 설정</Typography>
            <Box sx={{ height: '35rem' }}>
              <Box className='flex_start'>
                <TextField
                  sx={{ width: '25rem', mt: '.5rem' }}
                  value={title}
                  onChange={evt => {
                    setTitle(evt.target.value);
                  }}
                />
                <Button
                  variant='contained'
                  color='info'
                  sx={{ width: '7rem', ml: '.5rem' }}
                  onClick={() => updateTreeTitle()}
                >
                  적용
                </Button>
              </Box>
            </Box>
          </Paper>
        </Stack>
      </Paper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </div>
  );
};
