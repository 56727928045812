import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  addQuestionAnswerAttendUser,
  downloadQuestionAnswerQuestionList,
  getQuestionAnswerUserAnswerList,
} from '../../../common/api/ApiQuestionAnswer';
import { RESULT_OK } from '../../../common/resultCode';
import { SwDatesPicker } from '../dateSetting/SwDatesPicker';
import { MultilineTextField } from '../../styles/Styles';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { KEY_QUESTION_ANSWER_USER_ANSWER_LIST } from '../../../common/key';
import { SwQuizForm } from './SwQuizForm';
import { SwQuizAnswerForm } from './SwQuizAnswerForm';
import { SwSurveyAnswerForm } from './SwSurveyAnswerForm';

const TitleTypography = styled(Typography)({
  padding: '.5rem .5rem .5rem .15rem',
  marginBottom: '.33rem',
  fontWeight: 500,
  fontSize: '1rem',
});

const GridTitle = styled(Grid)({
  border: '1px solid #E7EDF6',
  backgroundColor: '#F9FAFC',
  borderLeft: 'none',
  padding: '.7em',
});

const GridCell = styled(Grid)({
  border: '1px solid #E7EDF6',
  borderLeft: 'none',
  padding: '.7em',
});

const Item = styled(Box)({
  textAlign: 'center',
});

interface Props {
  qnaId: string;
  userData: any;
  open: boolean;
  onclose: () => void;
}

export const SwQuestionAnswerViewAnswer: React.FC<Props> = ({
  qnaId,
  userData,
  open,
  onclose,
}) => {
  const [quiz, setQuiz] = useState<any>();
  const [answerList, setAnswerList] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const {
    data,
    isLoading,
    refetch: questionAnswerUserAnswerRefetch,
  } = useQuery(
    KEY_QUESTION_ANSWER_USER_ANSWER_LIST,
    () => {
      if (
        open &&
        qnaId.length > 0 &&
        userData !== undefined &&
        userData !== null &&
        userData.userId.length > 0
      ) {
        const request: any = {
          qnaId: qnaId,
          userId: userData.userId,
        };
        return getQuestionAnswerUserAnswerList(request);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.answer !== undefined && res.answer !== null) {
          console.log('getQuestionAnswerUserAnswerList :', res);
          setAnswerList(res.answer);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const downloadQuestionData = () => {
    const newData = {
      qnaId: qnaId,
      userId: userData.userId,
    };
    downloadQuestionAnswerQuestionList(newData);
  };

  useEffect(() => {
    if (open && userData !== undefined && userData !== null) {
      console.log('userData: ', userData);
      questionAnswerUserAnswerRefetch();
    }
  }, [open, userData]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onclose}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '75vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box>
              <Typography>답변 현황</Typography>
            </Box>
            <Box>
              <Button
                sx={{ mr: '1rem' }}
                color='info'
                variant='outlined'
                onClick={() => {
                  downloadQuestionData();
                }}
              >
                데이터 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
              <IconButton onClick={onclose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box sx={{ p: '1rem 0', width: '100%' }}>
            <TitleTypography>답변 정보</TitleTypography>
            {userData !== undefined && userData !== null && (
              <Grid
                container
                sx={{
                  borderTop: '1px solid #8899B6',
                  placeItems: 'center',
                  width: '100%',
                }}
              >
                <GridTitle item xs={6}>
                  <Item>닉네임</Item>
                </GridTitle>
                <GridCell item xs={6}>
                  <Item>{userData.name !== null ? userData.name : '-'}</Item>
                </GridCell>
                <GridTitle item xs={6}>
                  <Item>연락처</Item>
                </GridTitle>
                <GridCell item xs={6}>
                  <Item>
                    {userData.phoneno !== null ? userData.phoneno : '-'}
                  </Item>
                </GridCell>
                {userData.type === 2 && (
                  <>
                    <GridTitle item xs={6}>
                      <Item>점수</Item>
                    </GridTitle>
                    <GridCell item xs={6}>
                      <Item>{`${userData.correctCount}/${userData.count}`}</Item>
                    </GridCell>
                  </>
                )}
              </Grid>
            )}
          </Box>
          <Box>
            {answerList !== undefined &&
              answerList !== null &&
              answerList.length > 0 &&
              answerList.map((row: any, idx: number) => (
                <Box key={`answer_list_${idx}`}>
                  {row.type === 2 ? (
                    <SwQuizAnswerForm quiz={row} />
                  ) : (
                    <SwSurveyAnswerForm survey={row} />
                  )}
                </Box>
              ))}
          </Box>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={onclose}
          >
            완료
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
