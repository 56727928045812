import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, useEffect, useState } from 'react';

interface Props {
  head: string;
  setTitle: Dispatch<React.SetStateAction<string>>;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const SwSaveDialog: React.FC<Props> = ({
  head,
  setTitle,
  open,
  onConfirm,
  onClose,
}) => {
  const [message, setMessage] = useState<string>('');

  const onSave = () => {
    setTitle(message);
    onConfirm();
  };

  useEffect(() => {
    if (open) setMessage('');
  }, [open]);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          height: '20vh',
          width: '40vh',
        },
      }}
    >
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Box>
          <Typography sx={{ m: '1rem' }}>{head}</Typography>
        </Box>
        <Box>
          <TextField
            fullWidth
            onChange={evt => {
              setMessage(evt.target.value.substring(0, 20));
            }}
            value={message}
            placeholder='제목을 입력해주세요.'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{message?.length} / 20</span>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 'auto',
                padding: '.5rem',
              },
            }}
          />
        </Box>
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        <Button color='info' fullWidth size='small' onClick={onClose}>
          취소
        </Button>
        <Button color='primary' fullWidth size='small' onClick={onSave}>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};
