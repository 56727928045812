import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IPush, IPushCancel, IPushUpdate } from '../../models/push';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Push
 */

export const getPushList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/push/site/${data.site}?startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// export const getPushTarget = (searchKey: string, searchWord: string) =>
//   xapi
//     .get(
//       `/admin/v2/apis/push/target?searchKey=${searchKey}&searchWord=${searchWord}`
//     )
//     .then(res => {
//       if (res.status !== 200) throwError(res.status);
//       return res.data;
//     });

export const postPushSingle = (data: IPush) =>
  xapi
    .post(`/admin/v2/apis/push/single/site/${data.site}`, {
      receiver: {
        type: data.receiver.type,
        id: data.receiver.id,
        name: data.receiver.name,
      },
      title: data.title,
      content: data.content,
      link: data.link,
      linkName: data.linkName,
      site: data.site,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postPush = (data: IPush) =>
  xapi
    .post(`/admin/v2/apis/push/site/${data.site}`, {
      receiver: {
        type: data.receiver.type,
        id: data.receiver.id,
        users: data.receiver.users,
        name: data.receiver.name,
      },
      title: data.title,
      content: data.content,
      link: data.link,
      linkName: data.linkName,
      site: data.site,
      requestDate: data.requestDate,
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const cancelPush = (data: IPushCancel) =>
  xapi.delete(`/admin/v2/apis/push/${data.id}/site/${data.site}`);

export const patchPush = (data: IPushUpdate) =>
  xapi.put(`/admin/v2/apis/push/${data.pushId}/site/${data.site}`, {
    receiver: {
      type: data.receiver.type,
      id: data.receiver.id,
      name: data.receiver.name,
    },
    title: data.title,
    content: data.content,
    link: data.link,
    linkName: data.linkName,
    site: data.site,
    requestDate: data.requestDate,
  });
