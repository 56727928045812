import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IDashboardMemo, IDashboardShortcut } from '../../models/dashboard';
import { throwError } from '../helper';

/*
 * Dashboard
 */

export const getDashboardMemoList = () =>
  xapi.get(`/admin/v2/apis/dashboard/memo`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postDashboardMemoRegister = (data: IDashboardMemo) =>
  xapi.post('/admin/v2/apis/dashboard/memo', {
    memo: data.memo,
  });

export const deleteDashboardMemo = (data: IDashboardMemo) =>
  xapi.delete(`/admin/v2/apis/dashboard/memo/${data.memoId}`);

export const getDashboardShortcutList = () =>
  xapi.get(`/admin/v2/apis/dashboard/shortcut`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postDashboardShortcutRegister = (data: IDashboardShortcut) =>
  xapi.post('/admin/v2/apis/dashboard/shortcut', {
    title: data.title,
    link: data.link,
  });

export const deleteDashboardShortcut = (data: IDashboardShortcut) =>
  xapi.delete(`/admin/v2/apis/dashboard/shortcut/${data.shortcutId}`);
