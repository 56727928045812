import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  useEffect,
  useState,
  useRef,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_OPTION_COUNT,
  MAX_UPLOAD_FILE_SIZE,
  getQnaSurveyType,
  getQnaSurveyTypeNo,
  pointPerAchieve,
  quizTypeList,
  unitSelectorList,
} from './SwProgramCommon';
import { SwDatesPicker } from '../dateSetting/SwDatesPicker';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { SwFileSetting } from '../SwFileSetting';
import { useSession } from '../../../hooks/session';
import { ButtonBox } from '../../challenge/challengeManagement/ChallengeStyles';
import { SwTimePicker } from '../dateSetting/SwTimePicker';
import { postProgramMissionQnaQuestion } from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { newUuidId } from '../SwUuidCreate';
import { registerContentManageContent } from '../../../common/api/ApiContentManage';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import { getFileSize } from '../../../common/helperProgram';
import { DraggablePaper } from '../../styles/Styles';
import { checkUrl, getAddUrl } from '../../../common/helper';

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

const QnaBox = styled(Box)({
  margin: '1rem 1rem 1rem 3rem',
});

const INIT_UNIT_SELECTOR = '선택';
const MAX_EXPLANATION = 500;

interface Props {
  draggableName?: string;
  editMode: string;
  onChangeEditMode: (value: string) => void;
  contentManageInfo: any;
  setContentManageInfo: Dispatch<React.SetStateAction<any>>;
  reloadContentManage: (id: any) => void;
}

export const SwQuizContentForm: React.FC<Props> = ({
  draggableName = 'quiz_content_draggable',
  editMode,
  onChangeEditMode,
  contentManageInfo,
  setContentManageInfo,
  reloadContentManage,
}) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  const [qnaClass, setQnaClass] = useState<number>(5);
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [contentId, setContentId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [lclEditMode, setLclEditMode] = useState<string>(
    editMode === undefined ? 'view' : editMode
  );
  const [preEditMode, setPreEditMode] = useState<string>('view');
  const [qnaType, setQnaType] = useState<string>('choice');
  const [missionId, setMissionId] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [seq, setSeq] = useState<number>(0);
  const [status, setStatus] = useState<number>(1);
  const [answer, setAnswer] = useState<string>('');
  const [explanation, setExplanation] = useState<string>('');
  const [inputType, setInputType] = useState<string>('direct');
  const [link, setLink] = useState<string>('');
  const [hintUrl, setHintUrl] = useState<string>('');
  const [coverFile, setCoverFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [requiredAnswer, setRequiredAnswer] = useState<boolean>(true);
  const [multipleAnswer, setMultipleAnswer] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([
    { type: 'normal', title: '' },
    { type: 'normal', title: '' },
    { type: 'normal', title: '' },
  ]);
  const [image, setImage] = useState<string>('');
  const [uploadQuestionFile, setUploadQuestionFile] = useState<any>(null);
  const [uploadQuestionFileName, setUploadQuestionFileName] = useState<any>('');
  const [multipleAnswerCount, setMultipleAnswerCount] = useState<number>(0);
  const [multipleAnswerCountList, setMultipleAnswerCountList] = useState<any[]>(
    [
      { title: '지정 안함', value: 0 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
    ]
  );
  const [hideEtcButton, setHideEtcButton] = useState<boolean>(false);
  const [showUnitSelector, setShowUnitSelector] = useState<boolean>(false);
  const [selectUnit, setSelectUnit] = useState<any>(INIT_UNIT_SELECTOR);
  const [directInputUnit, setDirectInputUnit] = useState<any>('');
  const [startScale, setStartScale] = useState<number>(1);
  const [endScale, setEndScale] = useState<number>(2);
  const [startDates, setStartDates] = useState<any[]>([]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [durationYear, setDurationYear] = useState<string>('년');
  const [durationMonth, setDurationMonth] = useState<string>('개월');
  const [durationDay, setDurationDay] = useState<string>('일');
  const [selectTime, setSelectTime] = useState<any>(null);
  const [showHintUrl, setShowHintUrl] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<number>(0);

  const choiceItems = document.querySelectorAll(`.${draggableName}`);
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const {
    mutate: registerContentManageContentMutation,
    isError: isMutateError,
  } = useMutation(registerContentManageContent, {
    onSuccess: res => {
      console.log('registerContentManageContent res:', res);
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('저장되었습니다.');
        reloadContentManage({
          id: categoryId,
          parentCategoryId: parentCategoryId,
        });
      } else {
        setAlertOpen(true);
        setAlertMessage(
          `저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '콘텐츠를 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const addContentManageContent = (uploadUrl: any) => {
    const newType = getQnaSurveyTypeNo(qnaType);
    const newImage =
      uploadUrl !== null
        ? uploadUrl
        : uploadQuestionFileName !== undefined &&
          uploadQuestionFileName !== null &&
          uploadQuestionFileName.length > 0
        ? uploadQuestionFileName
        : '';

    const newOptions = options.map((item: any, idx: number) => {
      const newData = {
        body: item.type === 'normal' ? item.title : '기타',
        isCustomAnswer: item.type === 'normal' ? 0 : 1,
        isCorrect: item.isCorrect,
        seq: idx,
      };
      return newData;
    });

    const newQuizAnswer = qnaType === 'short' ? answer : '';

    const newHintUrl =
      hintUrl !== undefined && hintUrl !== null && hintUrl.length > 0
        ? checkUrl(hintUrl)
        : null;

    const newIdx =
      contentManageInfo.idx !== undefined && contentManageInfo.idx !== null
        ? contentManageInfo.idx
        : -1;

    const tmpAnswerUnit = unitSelectorList.filter(
      (unit: any) => unit.type === selectUnit
    );
    const newAnswerUnit =
      tmpAnswerUnit !== null ? tmpAnswerUnit[0].title : directInputUnit;

    const newQuestionData: any = {
      idx: newIdx,
      type: 'qna',
      contentType: contentManageInfo.type,
      categoryId: categoryId,
      contentId: contentId,
      questionId: contentManageInfo.questionId,
      title: question,
      qnaClass: qnaClass,
      qnaType: newType,
      seq: seq,
      quizAnswer: newQuizAnswer,
      quizExplanation: explanation,
      quizHintUrl: newHintUrl,
      image: newImage,
      isRequired: requiredAnswer ? 1 : 0,
      isUnitExist: selectUnit !== '선택' ? 1 : 0,
      answerUnit:
        selectUnit === '직접입력'
          ? directInputUnit
          : selectUnit !== '선택'
          ? newAnswerUnit
          : '',
      measureInfo: '',
      optionList:
        newOptions.length === 0
          ? ''
          : qnaType === 'choice'
          ? JSON.stringify(newOptions)
          : '',
      allowMultipleResponses: multipleAnswer ? 1 : 0,
      responsesCount: multipleAnswerCount,
      status: status,
    };
    console.log('registerContentManageQnaQuestion :', newQuestionData);
    registerContentManageContentMutation(newQuestionData);
  };

  async function uploadImage() {
    let uploadUrl = '';
    if (uploadQuestionFile !== null) {
      const newUploadFile: any = await uploadFile('qna', uploadQuestionFile);
      console.log('uploadImage :', newUploadFile);
      uploadUrl = newUploadFile.data.uploadUrl.file.path;
      setUploadQuestionFileName(uploadUrl);
    }

    addContentManageContent(uploadUrl);
  }

  const checkValid = () => {
    let isValid = 0;

    isValid = question.length === 0 ? 1 : 0;
    const newOptionList = options.filter(
      (option: any) => option.title.length === 0
    );
    isValid =
      isValid === 0 &&
      qnaType === 'choice' &&
      newOptionList !== null &&
      newOptionList.length > 0
        ? 2
        : isValid;
    isValid =
      isValid === 0 &&
      qnaType === 'short' &&
      showUnitSelector &&
      selectUnit === '직접입력' &&
      (directInputUnit === undefined ||
        directInputUnit === null ||
        directInputUnit.length === 0)
        ? 3
        : isValid;
    isValid =
      isValid === 0 &&
      qnaType === 'short' &&
      showUnitSelector &&
      selectUnit === '선택'
        ? 4
        : isValid;
    const newOptions = options.filter(
      (option: any, i: number) => option.isCorrect === 1
    );
    isValid =
      isValid === 0 &&
      qnaType === 'choice' &&
      (newOptions === undefined || newOptions === null)
        ? 5
        : isValid;
    isValid =
      isValid === 0 &&
      (qnaType === 'choice' || qnaType === 'short') &&
      (explanation === undefined ||
        explanation === null ||
        explanation.length === 0)
        ? 5
        : isValid;
    isValid =
      isValid === 0 &&
      qnaType === 'short' &&
      (answer === undefined || answer === null || answer.length === 0)
        ? 5
        : isValid;
    isValid =
      isValid === 0 &&
      qnaType === 'choice' &&
      multipleAnswer &&
      multipleAnswerCount === 0
        ? 6
        : isValid;

    if (isValid !== 0) {
      var message = '';
      switch (isValid) {
        case 1:
          message = '질문을 입력해 주세요.';
          break;
        case 2:
          message = '선택지를 입력해 주세요.';
          break;
        case 3:
          message = '단위를 입력해 주세요.';
          break;
        case 4:
          message = '단위를 선택해 주세요.';
          break;
        case 5:
          message = '정답 및 해설을 입력해 주세요.';
          break;
        case 6:
          message = '답변 개수를 지정해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      console.log('isvalid :', isValid);
      setOpenSnackbar(true);
      setAlertMessage(message);

      return;
    }

    if (uploadQuestionFile === null) {
      addContentManageContent(null);
    } else {
      if (
        uploadQuestionFile !== null &&
        getFileSize(uploadQuestionFile) >= MAX_UPLOAD_FILE_SIZE
      ) {
        setOpenSnackbar(true);
        setAlertMessage(IMAGE_SIZE_10MB_MESSAGE);
        return;
      }
      uploadImage();
    }
  };

  // Drag & Drop Start ------------------------------------------------------
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: options,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setOptions(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    choiceItems.forEach((item: any) => item.classList.remove('drag_over'));
    // update form
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setOptions(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    choiceItems.forEach((item: any) => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    choiceItems.forEach((item: any) => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newPosition = Number(evt.currentTarget.dataset.position);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newPosition : null,
    });
  };
  // Drag & Drop End --------------------------------------------------------

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputType((event.target as HTMLInputElement).value);
  };

  const addOption = () => {
    if (options.length >= MAX_OPTION_COUNT) {
      setOpenSnackbar(true);
      setAlertMessage('선택지는 최대 10개까지 추가가 가능합니다.');
      return;
    }

    // const newOptionList = options.filter(
    //   (option: any) => option.title.length === 0
    // );
    // if (newOptionList !== null && newOptionList.length > 0) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('선택지 내용을 입력해 주세요.');
    //   return;
    // }

    const newOptions = [...options, { type: 'normal', title: '' }];
    setOptions(newOptions);
  };

  const addEtcOption = () => {
    const newOptions = [...options, { type: 'etc', title: '' }];
    setOptions(newOptions);
  };

  const deleteOption = (selected: number) => {
    if (options.length === 1) {
      setOpenSnackbar(true);
      setAlertMessage('선택지는 최소 1개를 포함해야 합니다.');
      return;
    }

    const newOptions = options.filter(
      (option: any, idx: number) => idx !== selected
    );
    setOptions(newOptions);
  };

  const updateOption = (idx: number, newTitle: string) => {
    const newOptions = options.map((option: any, i: number) => {
      const newData =
        i === idx ? { type: option.type, title: newTitle } : option;
      return newData;
    });
    setOptions(newOptions);
  };

  const changeAnswer = (evt: any, idx: number) => {
    evt.stopPropagation();

    if (multipleAnswer) {
      const newOptions = options.map((option: any, i: number) => {
        const newData = { ...option };
        if (i === idx)
          newData.isCorrect === 1
            ? (newData.isCorrect = 0)
            : (newData.isCorrect = 1);
        return newData;
      });

      const newAnswerCount = newOptions.filter(
        (option: any, i: number) => option.isCorrect === 1
      );

      if (
        multipleAnswer &&
        multipleAnswerCount > 0 &&
        newAnswerCount !== null &&
        newAnswerCount.length > multipleAnswerCount
      ) {
        setOpenSnackbar(true);
        setAlertMessage('선택한 정답 개수가 답변 개수보다 많습니다.');
      } else setOptions(newOptions);
    } else {
      const newOptions = options.map((option: any, i: number) => {
        const newData = { ...option };
        if (i === idx) newData.isCorrect = 1;
        else newData.isCorrect = 0;
        return newData;
      });
      setOptions(newOptions);
    }
  };

  const checkAnswerAndExplanation = (evt: any, qnaTye: string) => {
    if (qnaType === 'choice') {
      const newAnswerCount = options.filter(
        (option: any, i: number) => option.isCorrect === 1
      );
      if (newAnswerCount === null || newAnswerCount.length === 0) {
        setOpenSnackbar(true);
        setDialogMessage('정답을 선택해주세요.');
      } else if (
        multipleAnswer &&
        multipleAnswerCount > 0 &&
        newAnswerCount !== null &&
        newAnswerCount.length !== multipleAnswerCount
      ) {
        setOpenSnackbar(true);
        setDialogMessage('복수답변 개수와 정답 개수가 다릅니다.');
      } else if (
        multipleAnswer === false &&
        (newAnswerCount.length === 0 || newAnswerCount.length > 1)
      ) {
        setOpenSnackbar(true);
        setDialogMessage('정답 및 해설을 다시 입력해 주세요');
      } else if (explanation.length === 0) {
        setOpenSnackbar(true);
        setDialogMessage('해설을 적어주세요.');
      } else {
        onChangeEditMode('edit');
      }
      evt.stopPropagation();
    } else if (qnaType === 'short') {
      if (answer.length === 0) {
        setOpenSnackbar(true);
        setDialogMessage('정답을 입력해주세요.');
      } else if (explanation.length === 0) {
        setOpenSnackbar(true);
        setDialogMessage('해설을 적어주세요.');
      } else {
        onChangeEditMode('edit');
      }
      evt.stopPropagation();
    }
  };

  const makeMultipleAnswer = (newOption: any) => {
    let newCountList: any[];
    if (newOption < 2) {
      newCountList = [];
      setMultipleAnswerCountList(newCountList);
    } else {
      newCountList = [];
      for (let i = 2; i <= newOption.length; i += 1) {
        newCountList.push({ title: i.toString(), value: i });
      }
      setMultipleAnswerCountList(newCountList);
    }
  };

  const doConfirm = () => {
    setUploadQuestionFile(null);
    setUploadQuestionFileName('');
    setConfirmType(0);
  };

  const reset = () => {
    setQnaType('choice');
    setQuestion('');
    setSeq(1);
    const newOptions = {
      type: 'normal',
      title: '',
      isCorrect: 0,
      isCustomAnswer: 0,
    };
    setOptions([newOptions]);
    setMultipleAnswer(false);
    setMultipleAnswerCount(0);
    setExplanation('');
    setAnswer('');
    setUploadQuestionFileName('');
    setHintUrl('');
    setShowHintUrl(false);
    setShowUnitSelector(false);
    setSelectUnit('선택');
    setDirectInputUnit('');
    setRequiredAnswer(false);
    setStatus(1);
  };

  // useEffect(() => {
  //   console.log('startDates: ', startDates);
  // }, [startDates]);

  useEffect(() => {
    console.log('> lclEditMode :', lclEditMode, ',preEditMode:', preEditMode);
    if (lclEditMode !== undefined) {
      if (lclEditMode === 'view' && preEditMode === 'edit') {
        checkValid();
      }

      setPreEditMode(lclEditMode);
    }
  }, [lclEditMode]);

  useEffect(() => {
    editMode === undefined ? setLclEditMode('view') : setLclEditMode(editMode);
  }, [editMode]);

  useEffect(() => {
    makeMultipleAnswer(options);

    const etcButton = options.filter((option: any) => option.type === 'etc');
    setHideEtcButton(!(etcButton === undefined || etcButton.length === 0));
  }, [options]);

  useEffect(() => {
    if (multipleAnswerCountList !== null && multipleAnswerCountList.length > 0)
      setMultipleAnswerCount(contentManageInfo.responsesCount);
  }, [multipleAnswerCountList]);

  useEffect(() => {
    if (
      contentManageInfo.contentId !== undefined &&
      contentManageInfo.contentId !== null
    ) {
      setQnaType(getQnaSurveyType(contentManageInfo.qnaType));
      setQuestion(contentManageInfo.title);
      setSeq(contentManageInfo.seq);
      const optionData =
        contentManageInfo.optionList !== undefined &&
        contentManageInfo.optionList !== null
          ? JSON.parse(contentManageInfo.optionList)
          : [];
      const newOptions = optionData.map((item: any, idx: number) => ({
        type: item.isCustomAnswer === 0 ? 'normal' : 'etc',
        title: item.body,
        isCorrect: item.isCorrect,
        isCustomAnswer: item.isCustomAnswer,
      }));
      setOptions(newOptions);
      setMultipleAnswer(contentManageInfo.allowMultipleResponses === 1);
      makeMultipleAnswer(newOptions);
      setMultipleAnswerCount(
        newOptions !== undefined && newOptions !== null
          ? contentManageInfo.responsesCount
          : 0
      );
      setExplanation(contentManageInfo.quizExplanation);
      setAnswer(
        contentManageInfo.quizAnswer !== null
          ? contentManageInfo.quizAnswer
          : ''
      );
      setUploadQuestionFileName(contentManageInfo.image);
      setHintUrl(
        contentManageInfo.quizHintUrl !== undefined &&
          contentManageInfo.quizHintUrl !== null
          ? contentManageInfo.quizHintUrl
          : ''
      );
      setShowHintUrl(
        contentManageInfo.quizHintUrl !== undefined &&
          contentManageInfo.quizHintUrl !== null &&
          contentManageInfo.quizHintUrl.length > 0
      );
      setShowUnitSelector(contentManageInfo.isUnitExist === 1);
      const newSelectUnit = unitSelectorList.filter(
        unit => unit.title === contentManageInfo.answerUnit
      );
      setSelectUnit(
        contentManageInfo.isUnitExist === undefined ||
          contentManageInfo.isUnitExist === null ||
          contentManageInfo.isUnitExist === 0
          ? '선택'
          : newSelectUnit !== null && newSelectUnit.length > 0
          ? newSelectUnit[0].type
          : '직접입력'
      );
      setDirectInputUnit(
        newSelectUnit === null || newSelectUnit.length === 0
          ? contentManageInfo.answerUnit
          : ''
      );
      setRequiredAnswer(contentManageInfo.isRequired === 1);
    } else {
      setTitle('');
    }
  }, [contentId]);

  useEffect(() => {
    console.log('contentManageInfo -> :', contentManageInfo);
    if (contentManageInfo !== undefined && contentManageInfo !== null) {
      setQnaClass(contentManageInfo.type);
      setSeq(contentManageInfo.seq);
      setStatus(contentManageInfo.status === true ? 1 : 0);
      if (contentManageInfo.save) {
        checkValid();
      }
      setContentId(
        contentManageInfo.contentId !== undefined &&
          contentManageInfo.contentId !== null
          ? contentManageInfo.contentId
          : newUuidId()
      );
      setCategoryId(
        contentManageInfo.categoryId !== undefined &&
          contentManageInfo.categoryId !== null
          ? contentManageInfo.categoryId
          : ''
      );
      setParentCategoryId(
        contentManageInfo.parentCategoryId !== undefined &&
          contentManageInfo.parentCategoryId !== null
          ? contentManageInfo.parentCategoryId
          : ''
      );

      if (contentManageInfo.contentId === null) {
        reset();
      }
    }
  }, [contentManageInfo]);

  // useEffect(() => {
  //   if (quiz !== undefined && quiz !== null) {
  //     console.log('quiz:', quiz);
  //     setQnaType(getQnaSurveyType(quiz.type));
  //     setQuestion(quiz.title);
  //     const optionData =
  //       quiz.optionList !== undefined && quiz.optionList !== null
  //         ? JSON.parse(quiz.optionList)
  //         : [];
  //     const newOptions = optionData.map((item: any, idx: number) => ({
  //       type: 'normal',
  //       title: item.body,
  //       isCorrect: item.isCorrect,
  //       isCustomAnswer: item.isCustomAnswer,
  //     }));
  //     setOptions(newOptions);
  //     setExplanation(quiz.quizExplanation);
  //     setAnswer(quiz.quizAnswer !== null ? quiz.quizAnswer : '');
  //     setUploadQuestionFileName(quiz.image);
  //     setHintUrl(quiz.quizHintUrl !== null ? quiz.quizHintUrl : '');
  //     setShowHintUrl(quiz.quizHintUrl !== null);
  //     setSeq(quiz.seq);
  //     setMissionId(quiz.missionId);
  //   }
  // }, [quiz]);

  return (
    <Paper
      sx={{
        mb: '1rem',
        background: editMode === 'view' ? '#FFFFFF' : '#F7F8F9',
      }}
    >
      <Box className='flex_between'>
        <Box className='flex_start' sx={{ ml: '1rem', mt: '1rem' }}>
          <DragHandleIcon />
          <Typography className='title' sx={{ ml: '1rem' }}>
            질문
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ m: '0rem' }} />
      {editMode === 'view' ? (
        <>
          <QnaBox className='flex_between'>
            <Typography sx={{ width: '58rem' }}>{question}</Typography>
          </QnaBox>

          <QnaBox>
            {uploadQuestionFileName !== undefined &&
            uploadQuestionFileName !== null &&
            uploadQuestionFileName !== '' ? (
              <Box sx={{ m: '1rem 0' }}>
                <img
                  src={`${imageUrlPrefix}${uploadQuestionFileName}`}
                  alt={`${imageUrlPrefix}${uploadQuestionFileName}`}
                  style={{
                    maxWidth: '10vw',
                    borderRadius: '14px',
                    width: '10rem',
                    height: 'auto',
                  }}
                />
              </Box>
            ) : (
              <></>
            )}
            <Box sx={{ mr: '1rem' }}>
              {showHintUrl && (
                <Button
                  onClick={evt => {
                    evt.stopPropagation();
                    window.open(`${hintUrl}`, '_blank');
                  }}
                >
                  힌트 보러가기 {'>'}
                </Button>
              )}
            </Box>
          </QnaBox>

          <Divider />
        </>
      ) : editMode === 'edit' ? (
        <>
          <QnaBox className='flex_between'>
            <TextField
              sx={{ width: '20%', mr: '1rem' }}
              select
              defaultValue={10}
              value={qnaType}
              onChange={evt => setQnaType(evt.target.value)}
            >
              {quizTypeList.map(option => (
                <MenuItem key={`qna_${option.type}`} value={option.type}>
                  {option.title}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: '80%' }}
              placeholder=''
              value={question}
              onChange={evt => {
                setQuestion(evt.target.value.substring(0, 100));
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{question?.length} / 100</span>
                  </InputAdornment>
                ),
              }}
            />
          </QnaBox>
          <QnaBox className='flex_start'>
            <Box>
              <Button
                variant='contained'
                onClick={() => {
                  const newFile1 = document.getElementById('quizfile1');
                  newFile1?.click();
                }}
              >
                <InsertPhotoOutlinedIcon color='secondary' />
                이미지 첨부
              </Button>
              <TextField
                sx={{ width: '28rem', m: '0 .5rem 0 1rem' }}
                value={
                  uploadQuestionFile !== null
                    ? uploadQuestionFile?.name
                    : uploadQuestionFileName
                }
              />
              <Button
                sx={{ ml: '1rem' }}
                variant='outlined'
                onClick={() => {
                  setConfirmType(1);
                }}
              >
                지우기
              </Button>
              <input
                className='nonedisplay'
                id='quizfile1'
                type='file'
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  evt.target.files &&
                    setUploadQuestionFile(evt.target.files[0]);
                }}
              />
            </Box>
            <Box sx={{ mr: '1rem' }}>
              <FormControlLabel
                value='start'
                control={
                  <Switch
                    color='primary'
                    checked={showHintUrl}
                    value={showHintUrl}
                    onChange={() => setShowHintUrl(!showHintUrl)}
                  />
                }
                label='힌트 URL'
                labelPlacement='start'
              />
            </Box>
          </QnaBox>
          <Divider />
          {showHintUrl && (
            <>
              <QnaBox>
                <Box>
                  <Box>
                    <Typography>힌트</Typography>
                  </Box>{' '}
                  <Box sx={{ width: '90%' }}>
                    <TextField
                      sx={{ width: '100%' }}
                      placeholder='힌트를 확인할 수 있는 url 을 입력해주세요.'
                      value={hintUrl}
                      onChange={evt => {
                        setHintUrl(getAddUrl(evt.target.value));
                      }}
                    />
                  </Box>
                </Box>
              </QnaBox>
              <Divider />
            </>
          )}
        </>
      ) : editMode === 'answer' ? (
        <>
          <QnaBox>
            <Typography sx={{ ml: '1rem', fontWeight: 'bold' }}>
              정답을 입력해 주세요.
            </Typography>
            <Typography
              className='title'
              sx={{ ml: '1rem', fontSize: '1.2rem' }}
            >
              {question}
            </Typography>
          </QnaBox>
        </>
      ) : (
        <></>
      )}
      {qnaType === 'choice' && editMode === 'view' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            {options.map((option: any, idx: number) => (
              <Box
                key={`choice_view_${idx}`}
                sx={{ mb: '1rem' }}
                className='flex_start'
              >
                {option.isCorrect === 1 ? (
                  <AdjustOutlinedIcon sx={{ color: '#00A2FF' }} />
                ) : (
                  <RadioButtonUncheckedIcon sx={{ color: '#D4D9E1' }} />
                )}
                <Typography sx={{ ml: '1rem' }}>{option.title}</Typography>
              </Box>
            ))}
          </QnaBox>
          <QnaBox>
            <Typography sx={{ fontWeight: 'bold' }}>[정답 및 해설]</Typography>
          </QnaBox>
          <QnaBox sx={{ mt: '2rem' }}>
            {options
              .filter((option: any) => option.isCorrect === 1)
              .map((option: any, idx: number) => (
                <Box className='flex_start' sx={{ color: '#00A2FF' }}>
                  <Typography>{option.title}</Typography>
                </Box>
              ))}
          </QnaBox>
          <QnaBox sx={{ padding: '0 0 1rem 0rem' }}>
            <Typography>{explanation}</Typography>
          </QnaBox>
        </>
      ) : qnaType === 'choice' && editMode === 'edit' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            {options.map((option: any, idx: number) => (
              <DraggablePaper
                key={`choice_edit_${idx}`}
                className={draggableName}
                id={idx.toString()}
                variant='outlined'
                onDragStart={dragStart}
                onDragEnd={dragEnd}
                onDragOver={dragOver}
                onDrop={drop}
                onDragLeave={dragLeave}
                onDragEnter={dragEnter}
                draggable
                data-position={idx}
                onClick={selectList}
                sx={{ width: '70%' }}
              >
                <Box className='flex_start' sx={{ width: '100%' }}>
                  <DragHandleIcon sx={{ color: '#9EA2A9' }} />
                  {option.isCorrect === 1 ? (
                    <AdjustOutlinedIcon sx={{ color: '#00A2FF' }} />
                  ) : (
                    <RadioButtonUncheckedIcon sx={{ color: '#D4D9E1' }} />
                  )}
                  <TextField
                    sx={{ width: '80%', m: '0 .5rem 0 1rem' }}
                    placeholder='선택지 입력'
                    disabled={option.type !== 'normal'}
                    value={option.title}
                    onChange={evt => {
                      updateOption(idx, evt.target.value.substring(0, 100));
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>{option.title?.length} / 100</span>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button color='info' onClick={() => deleteOption(idx)}>
                    삭제
                  </Button>
                </Box>
              </DraggablePaper>
            ))}
          </QnaBox>
          <QnaBox>
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              divider={
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ p: 0, m: '.5rem 1rem .5rem' }}
                />
              }
            >
              <Button color='info' onClick={() => addOption()}>
                <AddIcon fontSize='small' color='primary' />
                선택지 추가
              </Button>
            </Stack>
          </QnaBox>
          <Divider sx={{ m: '0rem' }} />
          <Box className='flex_between'>
            <QnaBox>
              <Button
                onClick={evt => {
                  const newOptionList = options.filter(
                    (option: any) => option.title.length === 0
                  );
                  if (newOptionList !== null && newOptionList.length > 0) {
                    setOpenSnackbar(true);
                    setAlertMessage('선택지 내용을 입력해 주세요.');
                    return;
                  }

                  onChangeEditMode('answer');
                  evt.stopPropagation();
                }}
              >
                <EditOutlinedIcon />
                정답 및 해설
              </Button>
            </QnaBox>
            <QnaBox className='flex_end' sx={{ width: '50%' }}>
              <Box>
                <FormControlLabel
                  value='start'
                  control={
                    <Switch
                      color='primary'
                      checked={multipleAnswer}
                      value={requiredAnswer}
                      onChange={() => setMultipleAnswer(!multipleAnswer)}
                    />
                  }
                  label='복수 답변'
                  labelPlacement='start'
                />
              </Box>
              {multipleAnswer && (
                <Box className='flex_between' sx={{ ml: '1rem' }}>
                  <Typography>답변개수</Typography>
                  <TextField
                    sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                    select
                    defaultValue={multipleAnswerCount}
                    value={multipleAnswerCount}
                    onChange={evt =>
                      setMultipleAnswerCount(Number(evt.target.value))
                    }
                  >
                    {multipleAnswerCountList.map((option: any, i: number) => (
                      <MenuItem
                        key={`qna_multiple_answer_${option}`}
                        value={option.value}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              )}
            </QnaBox>
          </Box>
        </>
      ) : qnaType === 'choice' && editMode === 'answer' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            {options.map((option: any, idx: number) => (
              <Box
                key={`choice_answer_${idx}`}
                sx={{ mb: '1rem' }}
                className='flex_start'
              >
                <Button
                  onClick={evt => {
                    changeAnswer(evt, idx);
                  }}
                >
                  {option.isCorrect === 1 ? (
                    <AdjustOutlinedIcon sx={{ color: '#00A2FF' }} />
                  ) : (
                    <RadioButtonUncheckedIcon sx={{ color: '#D4D9E1' }} />
                  )}
                </Button>
                <Typography sx={{ width: '50%', m: '0 .5rem 0 1rem' }}>
                  {option.title}
                </Typography>
              </Box>
            ))}
          </QnaBox>
          <Divider sx={{ m: '0rem' }} />
          <QnaBox>
            <Box>
              <Typography sx={{ ml: '1rem', mb: '.5rem', fontWeight: 'bold' }}>
                해설을 적어주세요.
              </Typography>
            </Box>
            <Box>
              <TextField
                sx={{ width: '100%' }}
                placeholder='해설 입력'
                value={explanation}
                onClick={evt => evt.stopPropagation()}
                onChange={evt => {
                  setExplanation(
                    evt.target.value.substring(0, MAX_EXPLANATION)
                  );
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>
                        {answer?.length} / {MAX_EXPLANATION}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </QnaBox>
          <Divider sx={{ m: '0rem' }} />
          <QnaBox className='flex_end'>
            <Button
              variant='contained'
              color='info'
              sx={{ mb: '1rem' }}
              onClick={evt => {
                onChangeEditMode('edit');
                evt.stopPropagation();
              }}
            >
              완료
            </Button>
          </QnaBox>
        </>
      ) : qnaType === 'short' && editMode === 'view' ? (
        <>
          <QnaBox
            className='flex_between'
            sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}
          >
            <Box sx={{ width: '50%' }} className='flex_start'>
              <TextField
                sx={{ width: '100%' }}
                placeholder='답변을 입력해주세요.'
                value={answer}
                onChange={evt => {
                  setAnswer(evt.target.value.substring(0, 30));
                }}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      {showUnitSelector && selectUnit === '직접입력' ? (
                        <Typography>{directInputUnit}</Typography>
                      ) : (
                        <Typography>
                          {selectUnit !== '선택' ? selectUnit : ''}
                        </Typography>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </QnaBox>
          <QnaBox>
            <Typography sx={{ fontWeight: 'bold' }}>[정답 및 해설]</Typography>
          </QnaBox>
          <QnaBox sx={{ padding: '0 0 1rem 0rem', color: '#00A2FF' }}>
            <Typography>{answer}</Typography>
          </QnaBox>
          <QnaBox sx={{ padding: '0 0 1rem 0rem' }}>
            <Typography>{explanation}</Typography>
          </QnaBox>
        </>
      ) : qnaType === 'short' && editMode === 'edit' ? (
        <>
          <QnaBox className='flex_start' sx={{ mt: '2rem' }}>
            <Box sx={{ width: '50%' }}>
              <TextField
                sx={{ width: '100%' }}
                placeholder='답변을 입력해주세요.'
                // value={answer}
                // onChange={evt => {
                //   setAnswer(evt.target.value.substring(0, 30));
                // }}
                InputProps={{
                  readOnly: true,
                  // endAdornment: (
                  //   <InputAdornment position='end'>
                  //     <span>{answer?.length} / 30</span>
                  //   </InputAdornment>
                  // ),
                }}
              />
            </Box>
            {showUnitSelector && selectUnit === '직접입력' ? (
              <TextField
                sx={{ ml: '1rem' }}
                placeholder='단위입력'
                value={directInputUnit}
                onChange={evt => {
                  setDirectInputUnit(evt.target.value);
                }}
              />
            ) : (
              <></>
            )}
            {showUnitSelector && (
              <Box>
                <TextField
                  sx={{ width: '10rem', mr: '1rem', ml: '1rem' }}
                  select
                  defaultValue={selectUnit}
                  value={selectUnit}
                  onChange={evt => setSelectUnit(evt.target.value)}
                >
                  {unitSelectorList.map((option: any, i: number) => (
                    <MenuItem
                      key={`qna_unit_${option.type}`}
                      value={option.type}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            )}
          </QnaBox>
          <QnaBox>
            <FormControlLabel
              sx={{ mb: '2rem' }}
              value='start'
              control={
                <Switch
                  color='primary'
                  checked={showUnitSelector}
                  value={showUnitSelector}
                  onChange={() => {
                    setSelectUnit(INIT_UNIT_SELECTOR);
                    setShowUnitSelector(!showUnitSelector);
                  }}
                />
              }
              label='단위'
              labelPlacement='start'
            />
          </QnaBox>
          <Divider sx={{ m: '0rem' }} />
          <Box className='flex_start'>
            <QnaBox>
              <Button
                onClick={evt => {
                  onChangeEditMode('answer');
                  evt.stopPropagation();
                }}
              >
                <EditOutlinedIcon />
                정답 및 해설
              </Button>
            </QnaBox>
          </Box>
        </>
      ) : qnaType === 'short' && lclEditMode === 'answer' ? (
        <>
          <QnaBox>
            <Box>
              <Typography sx={{ ml: '1rem', mb: '.5rem', fontWeight: 'bold' }}>
                * 사용자가 한 글자라도 틀리면 오답으로 처리되니 정확히 입력해
                주세요.
              </Typography>
            </Box>
            <Box>
              <TextField
                sx={{ width: '100%' }}
                placeholder='정답을 입력해주세요.'
                value={answer}
                onClick={evt => evt.stopPropagation()}
                onChange={evt => {
                  setAnswer(evt.target.value.replace(' ', '').substring(0, 30));
                }}
              />
            </Box>
          </QnaBox>
          <Divider sx={{ m: '0rem' }} />
          <QnaBox>
            <Box>
              <Typography sx={{ ml: '1rem', mb: '.5rem', fontWeight: 'bold' }}>
                해설을 적어주세요.
              </Typography>
            </Box>
            <Box>
              <TextField
                sx={{ width: '100%' }}
                placeholder='해설 입력'
                value={explanation}
                onClick={evt => evt.stopPropagation()}
                onChange={evt => {
                  setExplanation(
                    evt.target.value.substring(0, MAX_EXPLANATION)
                  );
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <span>
                        {explanation?.length} / {MAX_EXPLANATION}
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </QnaBox>
          <Divider sx={{ m: '0rem' }} />
          <QnaBox className='flex_end'>
            <Button
              variant='contained'
              color='info'
              sx={{ mb: '1rem' }}
              onClick={evt => {
                checkAnswerAndExplanation(evt, 'short');
              }}
            >
              완료
            </Button>
          </QnaBox>
        </>
      ) : (
        <></>
      )}
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwConfirmDialog
        contents={confirmType === 1 ? '이미지를 삭제하시겠습니까?' : ''}
        open={confirmType !== 0}
        onClose={() => setConfirmType(0)}
        onConfirm={() => {
          doConfirm();
        }}
        confirm={1}
      />
    </Paper>
  );
};
