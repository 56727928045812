import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import React, { useState } from 'react';

interface Props {
  open: boolean;
  onclose: () => void;
}

export const SwDialog: React.FC<Props> = ({ open, onclose }) => {
  const a = 1;
  return (
    <Dialog
      open={open}
      onClose={onclose}
      sx={{
        '& .MuiDialog-paper': {
          width: '30em',
          height: '60em',
        },
      }}
    >
      <DialogTitle>title</DialogTitle>
      <DialogContent>contents</DialogContent>
      <DialogActions>actions</DialogActions>
    </Dialog>
  );
};
