import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { ChangeEvent, useEffect, useState, Dispatch, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';
import { pointPerAchieve } from './SwProgramCommon';
import { SwFileSetting } from '../SwFileSetting';
import { useSession } from '../../../hooks/session';
import { SwSurveyForm } from './SwSurveyForm';
import { SwSelectTemplate } from './SwSelectTemplate';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import {
  deleteProgramMissionQnaQuestion,
  postProgramMissionQnaQuestion,
  postProgramMissionQnaQuestionBatch,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';
import { SwArrangeQuestion } from './SwArrangeQuestion';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import { requiredAllAnswerSts } from '../../../common/atom';

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

interface Props {
  requiredAllAnswer: number;
  missionInfo: any;
  questionList: any[];
  setQuestionList: Dispatch<React.SetStateAction<any[]>>;
  isEdit: number;
  onReloadMission: (missionId: string) => void;
  forcedSave: boolean;
  disabled: boolean;
  onDisabledButton: (type: boolean) => void;
}

export const SwSurveyCreate: React.FC<Props> = ({
  requiredAllAnswer,
  missionInfo,
  questionList,
  setQuestionList,
  isEdit,
  onReloadMission,
  forcedSave,
  disabled,
  onDisabledButton,
}) => {
  const { loginVal } = useSession();
  const [requiredAllAnswerInfo, setRequiredAllAnswerInfo] =
    useRecoilState(requiredAllAnswerSts);
  const titleRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [inputType, setInputType] = useState<string>('direct');
  const [link, setLink] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [coverFile, setCoverFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [survey, setSurvey] = useState<any>();
  const formItems = document.querySelectorAll(`.surveyCreate`);
  const [targetId, setTargetId] = useState<string>('');
  const [editMode, setEditMode] = useState<string[]>(['view']);
  const [selectTarget, setSelectTarget] = useState<any>();
  const [openTemplate, setOpenTemplate] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [disabledAdd, setDisabledAdd] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openArrangeQuestion, setOpenArrangeQuestion] =
    useState<boolean>(false);

  const doReloadMission = () => {
    const newMissionId =
      questionList.length > 0
        ? questionList[questionList.length - 1].missionId
        : missionInfo.missionId;
    onReloadMission(newMissionId);
  };

  const { mutate: postMissionQnaQuestionBatchMutate, isError: isMutateError } =
    useMutation(postProgramMissionQnaQuestionBatch, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenDialog(true);
          setDialogMessage('등록되었습니다.');
        } else {
          setOpenDialog(true);
          setDialogMessage(
            `등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage('등록이 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    });

  const registerCurriculumMissionQnaQuestionBatch = (qnaDataList: any[]) => {
    const newQuestionDataList = qnaDataList.map((qnaData: any) => {
      const newData = {
        weekId: qnaData.weekId,
        questionId: '',
        qnaId: qnaData.missionId,
        title: qnaData.title,
        type: qnaData.type,
        seq: qnaData.seq,
        image: qnaData.image,
        isRequired: qnaData.isRequired,
        isUnitExist: qnaData.isUnitExist,
        answerUnit: qnaData.answerUnit,
        measureInfo: qnaData.measureInfo,
        optionList: qnaData.optionList,
        allowMultipleResponses: qnaData.allowMultipleResponses,
        responsesCount: qnaData.multipleAnswerCount,
      };
      return newData;
    });

    const neqQuestinList = {
      questionList: newQuestionDataList,
    };

    console.log('postProgramMissionQnaQuestionBatch :', neqQuestinList);
    postMissionQnaQuestionBatchMutate(neqQuestinList);
  };

  const {
    mutate: deleteMissionQnaQuestionMutate,
    isError: isDeleteMutateError,
  } = useMutation(deleteProgramMissionQnaQuestion, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('삭제했습니다.');

        doReloadMission();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputType((event.target as HTMLInputElement).value);
  };

  const handleClick = (evt: any, idx: number) => {
    if (isEdit === 1) return;
    evt.stopPropagation();
    console.log('click -> ', idx);

    const newEdit = editMode
      .map((item: any, i: number) =>
        item === 'edit' || item === 'answer' ? i : -1
      )
      .filter(item => item !== -1);
    if (
      idx !== 0 &&
      disabledAdd &&
      newEdit !== null &&
      newEdit.length > 0 &&
      newEdit[0] !== idx
    )
      return;

    const newEditMode = editMode.map(item => 'view');

    if (idx === 0) {
      const newAnswer = editMode
        .map(item => (item === 'answer' ? 1 : -1))
        .filter(item => item === 1);
      if (newAnswer === null || newAnswer.length === 0) {
        setEditMode(newEditMode);
      } else {
        newEditMode[newAnswer[0]] = 'answer';
        setEditMode(newEditMode);
      }
    } else if (editMode[idx] !== 'answer') {
      if (idx !== 0) newEditMode[idx] = 'edit';
      setEditMode(newEditMode);
    } else {
      if (idx !== 0) newEditMode[idx] = 'answer';
      setEditMode(newEditMode);
    }

    const newView = newEditMode
      .map(item => (item !== 'view' ? 1 : -1))
      .filter(item => item === 1);
    if (newView === null || newView.length === 0) {
      setDisabledAdd(false);
      onDisabledButton(false);
    } else {
      setDisabledAdd(true);
      onDisabledButton(true);
    }
  };

  const handleMouseLeave = (evt: any, idx: number) => {
    const newEditMode = editMode.map(item => 'view');
    // setEditMode(newEditMode);
    // handleClick(evt, 0);
  };

  const doDisabledButton = (type: boolean) => {
    setDisabledAdd(type);
    onDisabledButton(type);
  };

  const onChangeEditMode = (idx: number, value: string) => {
    const newEditMode = [...editMode];
    newEditMode[idx] = value;
    setEditMode(newEditMode);
    value === 'view' ? doDisabledButton(false) : doDisabledButton(true);
  };

  const deleteQuestion = () => {
    if (questionList.length === 1) {
      setOpenSnackbar(true);
      setDialogMessage('질문은 최소한 1개가 있어야 합니다.');
      return;
    }
    const newMissionId =
      questionList.length > 0
        ? questionList[questionList.length - 1].missionId
        : missionInfo.missionId;
    const newData: any = {
      qnaId: newMissionId,
      questionId: targetId,
    };
    deleteMissionQnaQuestionMutate(newData);
  };

  const addQna = (evt: any) => {
    evt.stopPropagation();

    const newSeq =
      questionList.length > 0
        ? questionList[questionList.length - 1].seq + 1
        : 0;
    const newMissionId =
      questionList.length > 0
        ? questionList[questionList.length - 1].missionId
        : missionInfo.missionId;
    const newOptions = [{ body: '', isCorrect: 0, isCustomAnswer: 0 }];
    const newQnaList = [
      ...questionList,
      {
        type: 1,
        title: '',
        seq: newSeq,
        missionId: newMissionId,
        image: null,
        weekId: missionInfo.weekId,
        optionList: JSON.stringify(newOptions),
      },
    ];

    let newEditMode = ['view'];
    newQnaList.map(item => {
      newEditMode = [...newEditMode, 'view'];
    });
    // newEditMode[newQnaList.length] = 'edit';
    console.log('addQna newEditMode :', newEditMode);
    setEditMode(newEditMode);

    setQuestionList(newQnaList);
  };

  useEffect(() => {
    if (forcedSave) {
      const newTarget = editMode
        .map((item: any, idx: number) => (item === 'edit' ? idx : -1))
        .filter(item => item !== -1);
      if (newTarget !== null && newTarget.length > 0)
        onChangeEditMode(newTarget[0], 'view');
    }
  }, [forcedSave]);

  useEffect(() => {
    console.log('selectTarget: ', selectTarget);
    if (selectTarget !== undefined && selectTarget.length > 0) {
      let newSeq =
        questionList.length > 0
          ? questionList[questionList.length - 1].seq + 1
          : 0;
      const newMissionId =
        questionList.length > 0
          ? questionList[questionList.length - 1].missionId
          : missionInfo.missionId;

      const newSelectTarget = selectTarget.map((item: any) => {
        const newItem = item;
        newItem.type = item.qnaType;
        newItem.seq = newSeq;
        newItem.missionId = newMissionId;
        newItem.weekId = missionInfo.weekId;
        newItem.isRequired = 0;
        newSeq += 1;
        return newItem;
      });
      registerCurriculumMissionQnaQuestionBatch(newSelectTarget);
      const newQuestionList = [...questionList, ...newSelectTarget];
      setQuestionList(newQuestionList);
    }
  }, [selectTarget]);

  useEffect(() => {
    if (questionList.length > 0) {
      const newQuestionList = questionList.map((item: any) => {
        const newData = { ...item };
        newData.isRequired = requiredAllAnswerInfo.ids.includes(item.questionId)
          ? 1
          : 0;
        return newData;
      });
      setQuestionList(newQuestionList);
    }
  }, [requiredAllAnswerInfo]);

  useEffect(() => {
    if (questionList.length > 0) {
      const initEditMode = 'view';
      let newEditMode = ['view'];
      questionList.map(item => {
        newEditMode = [...newEditMode, initEditMode];
      });
      // newEditMode[newEditMode.length - 1] = 'view';
      // console.log('-> editMode: ', editMode);
      // console.log('-> newEditMode: ', newEditMode);
      const newEdit = editMode
        .map((item: any, idx: number) => (item !== 'view' ? idx : -1))
        .filter(item => item !== -1);
      if (newEdit !== null && newEdit.length > 0) {
        newEditMode[newEdit[0]] = 'edit';
      }
      setEditMode(newEditMode);
    }
  }, [questionList]);

  return (
    <>
      {/* <Box onClick={evt => handleClick(evt, 0)}> */}
      <Box>
        {questionList.length > 0 &&
          questionList.map((row: any, idx: number) => (
            <Box
              key={`survey_list_${idx}`}
              onClick={evt => handleClick(evt, idx + 1)}
              onMouseLeave={evt => handleMouseLeave(evt, idx + 1)}
            >
              <SwSurveyForm
                editMode={editMode[idx + 1]}
                onChangeEditMode={(value: string) =>
                  onChangeEditMode(idx + 1, value)
                }
                survey={row}
                setSurvey={setSurvey}
                onReloadMission={onReloadMission}
                onDelete={(id: string) => {
                  setTargetId(id);
                  setOpenConfirm(true);
                }}
                disabled={disabled}
                onChangeOrder={() => setOpenArrangeQuestion(true)}
              />
              {editMode[idx + 1] === 'edit' && (
                <Box className='flex_end'>
                  <Button
                    sx={{ mr: '1rem' }}
                    variant='contained'
                    color='primary'
                    size='small'
                    onClick={evt => handleClick(evt, 0)}
                  >
                    저장
                  </Button>
                </Box>
              )}
              <Divider />
            </Box>
          ))}
        {isEdit !== 1 && (
          <Box
            sx={{
              mt: '2rem',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Button
                disabled={disabledAdd}
                sx={{ mr: '1rem' }}
                variant='outlined'
                color='info'
                size='small'
                onClick={evt => {
                  addQna(evt);
                }}
              >
                <AddIcon color='primary' />새 질문 추가
              </Button>
              <Button
                disabled={disabledAdd}
                variant='outlined'
                color='info'
                size='small'
                onClick={() => {
                  setOpenTemplate(true);
                }}
              >
                템플릿으로 추가
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <SwSelectTemplate
        categoryType={3}
        open={openTemplate}
        onClose={() => {
          setOpenTemplate(false);
        }}
        setTarget={setSelectTarget}
      />
      <SwArrangeQuestion
        open={openArrangeQuestion}
        onClose={() => {
          setOpenArrangeQuestion(false);
          doReloadMission();
        }}
        questionList={questionList}
        setQuestionList={setQuestionList}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='삭제하시면 입력하신 내용이 모두 사라집니다. 그래도 삭제하시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          deleteQuestion();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </>
  );
};
