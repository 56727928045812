import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, { useState, useEffect } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { InquireTable } from '../../commonComponent/table/InquireTable';
import { UserChallengeDetail } from './UserChallengeDetail';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  getUserChallengeList,
  downloadUserChallengeList,
} from '../../../common/api/ApiUser';
import { KEY_USER_CHALLENGE_LIST } from '../../../common/key';
import {
  getDeletedReason,
  timestamp2Localestring,
} from '../../../common/helper';
import { deleteChallengeAttendUser } from '../../../common/api/ApiChallenge';

interface Props {}

const columns = [
  'No',
  '챌린지 ID',
  '챌린지 종류',
  '챌린지 이름',
  '운영 시작일',
  '운영 종료일',
  '참여일시',
  '탈퇴일시',
  '탈퇴사유',
  '상세보기',
  '삭제',
];

export const UserChallenge: React.FC<Props> = () => {
  const location = useLocation();
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [deletedCount, setDeletedCount] = useState(0);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [challengeList, setChallengeList] = useState<any>([]);
  const [userData, setUserData] = useState<any>(location.state);
  const [challengeId, setChallengeId] = useState('');
  const [selectRow, setSelectRow] = useState<number>(-1);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState<boolean>(false);
  const isOpen = () => setOpenDetail(!openDetail);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: challengeRefetch,
  } = useQuery(
    KEY_USER_CHALLENGE_LIST,
    () => {
      if (userData.id !== '') {
        return getUserChallengeList(
          userData.id,
          page,
          rowsPerPage,
          rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
          rangeDate[1] === null ? 0 : rangeDate[1].getTime()
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          console.log('user challenge list :', res.challenge);
          setChallengeList(res.challenge.challenge);
          setTotalRecords(res.pagination.totalRecordCount);
          setDeletedCount(res.challenge.deletedCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_USER_CHALLENGE_LIST, () =>
      getUserChallengeList(
        userData.id,
        newPage,
        rowsPerPage,
        rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        rangeDate[1] === null ? 0 : rangeDate[1].getTime()
      )
    );
  };

  const { mutate: cancelChallengeAttendUserMutate, isError: isMutateError } =
    useMutation(deleteChallengeAttendUser, {
      onSuccess: () => {
        challengeRefetch();
        setOpenSnackbar(true);
        setDialogMessage('챌린지 참여 내역을 삭제했습니다..');
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          `챌린지 참여 내역을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const handleChangePage = (_event: any, newPage: number) => {
    console.log('userData:', userData);
    setPage(newPage);
    prefetch(newPage);
  };

  const cancelChallengeAttendUser = () => {
    const newData = {
      challengeId: challengeList[selectRow].challengeId,
      userId: userData.id,
    };
    cancelChallengeAttendUserMutate(newData);
  };

  useEffect(() => {
    if (userData.id !== '') challengeRefetch();
  }, [userData]);

  useEffect(() => {
    if (location.state) {
      setUserData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    setPage(1);
    challengeRefetch();
  }, [rangeDate]);

  const onDownloadFile = () => {
    downloadUserChallengeList(
      userData.id,
      rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      rangeDate[1] === null ? 0 : rangeDate[1].getTime()
    );
  };

  if (isLoading) return <div>isLoading...</div>;

  return (
    <>
      <Box
        className='flex_center'
        sx={{ justifyContent: 'flex-end', marginTop: '-1.4rem' }}
      >
        <Button
          color='info'
          variant='outlined'
          sx={{ mr: '1rem', p: '.2rem 1rem' }}
          onClick={onDownloadFile}
        >
          데이터 다운로드
          <FileDownloadRoundedIcon color='success' />
        </Button>
      </Box>
      <Paper sx={{ p: '.5rem 1rem', m: '1rem 0' }}>
        <Box
          sx={{
            p: '1rem .5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography component='div'>
            참여 챌린지 내역{' '}
            <span style={{ color: '#5891FF' }}>
              ({totalRecords - deletedCount})
            </span>{' '}
            / 탈퇴 포함{' '}
            <span style={{ color: '#5891FF' }}>({totalRecords})</span>
          </Typography>
          <Box className='flex_center'>
            <SwDateRangePicker
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
            />
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {challengeList?.map((row: any, idx: number) => (
                <TableRow key={row.challengeId}>
                  <TableCell
                    key={row.na}
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {(rowsPerPage * (page - 1) + idx + 1).toString()}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.challengeId}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.type}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.title}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {timestamp2Localestring(row.startDate, 1000)}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {timestamp2Localestring(row.endDate, 1000)}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {timestamp2Localestring(row.attendDate, 1000)}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.deleted === 1
                      ? timestamp2Localestring(row.deletedDate, 1000)
                      : '-'}
                  </TableCell>
                  <TableCell
                    className={
                      row.deleted
                        ? 'user_table_cell_deleted'
                        : 'user_table_cell'
                    }
                  >
                    {row.deleted === 1
                      ? getDeletedReason(row.deletedReason)
                      : '-'}
                  </TableCell>
                  <TableCell>
                    <Button
                      id={row.challengeId}
                      size='small'
                      variant='outlined'
                      color='info'
                      onClick={(evt: any) => {
                        setChallengeId(evt.currentTarget.id);
                        setSelectRow(idx);
                        isOpen();
                      }}
                    >
                      상세보기
                    </Button>
                  </TableCell>
                  <TableCell>
                    <Button
                      id={row.challengeId}
                      size='small'
                      variant='contained'
                      color='info'
                      onClick={(evt: any) => {
                        setChallengeId(evt.currentTarget.id);
                        setSelectRow(idx);
                        setOpenDelete(true);
                      }}
                      disabled={row.deleted === 1}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <UserChallengeDetail
        open={openDetail}
        onClose={() => {
          isOpen();
          challengeRefetch();
        }}
        userData={userData}
        challengeData={challengeList[selectRow]}
      />
      <SwConfirmDialog
        contents={`'${challengeList[selectRow]?.title}' 챌린지 참여 내역을 삭제하시겠습니까?`}
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          cancelChallengeAttendUser();
          setOpenDelete(false);
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
