import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import {
  getDataPortalAccountList,
  getDataPortalAccountDetailList,
} from '../../common/api/ApiDataPortal';
import {
  KEY_DATAPORTAL_ACCOUNT_LIST,
  KEY_DATAPORTAL_ACCOUNT_DETAIL_LIST,
  KEY_CONTRACT_LIST,
  KEY_WORD_PREFIX_COMPANY,
} from '../../common/key';
import { getContractList } from '../../common/api/ApiContract';
import SwLoading from './spinner/SwLoading';
import { encodeSearchWord } from '../../common/helper';

interface Props {
  title: string;
  searchKey: string;
  searchWord: string;
  isDetail?: boolean;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

const columns = [
  '아이디',
  '고객명',
  '담당자',
  '연락처',
  '주소',
  '워크온담당자',
];

export const SwSearchContractCompany: React.FC<Props> = ({
  title,
  open,
  onClose,
  searchKey,
  searchWord,
  isDetail = false,
  setTarget,
}) => {
  const queryClient = useQueryClient();
  const [contractList, setContractList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(0);
  const [loading, setLoading] = useState<any>(false);
  const [resultMsg, setResultMsg] = useState<any>('');

  const clickItem = (row: any) => {
    const target =
      isDetail === false ? { portalId: row.portalId, name: row.parent } : row;
    setTarget(target);
    onClose();
  };

  const {
    data,
    isLoading,
    refetch: contractRefetch,
  } = useQuery(
    KEY_CONTRACT_LIST,
    () => {
      setLoading(true);
      if (open) {
        const newData = {
          type: 0,
          page: page,
          rowsPerPage: 0,
          searchKey: searchKey,
          searchWord:
            searchWord === null || searchWord === ''
              ? ''
              : searchWord.includes(KEY_WORD_PREFIX_COMPANY)
              ? searchWord.replace(KEY_WORD_PREFIX_COMPANY, '')
              : encodeSearchWord(searchKey, searchWord),
          startDate: 0,
          endDate: 0,
        };
        const rslt = getContractList(newData);
        console.log('rslt :', rslt);
        return rslt;
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        setLoading(false);
        if (res && res.contract && res.contract.length > 0) {
          console.log('res.contract:', res.contract);
          setContractList(res.contract);

          if (res.contract.length === 1) {
            clickItem(res.contract[0]);
          }
        } else {
          setResultMsg('검색결과 없습니다.');
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  useEffect(() => {
    if (open === true) {
      setContractList([]);
      setResultMsg('');
      contractRefetch();
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {searchWord && searchWord.length > 0 && `'${searchWord}'`} {title}{' '}
            검색결과
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {contractList !== undefined && contractList.length > 0 ? (
                contractList?.map((row: any, idx: number) => (
                  <TableRow key={idx.toString()} onClick={() => clickItem(row)}>
                    <TableCell key={row.na} sx={{ p: '.3rem .6rem' }}>
                      {`${KEY_WORD_PREFIX_COMPANY}${row.portalCode}`}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>{row.name}</TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.userName1}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.userPhoneno1}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.address}
                    </TableCell>
                    <TableCell sx={{ p: '.3rem .6rem' }}>
                      {row.operator}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>{resultMsg}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      {/* <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ fontSize: '.87rem' }}
          onClick={onClose}
        >
          닫기
        </Button>
      </DialogActions> */}
      {loading && <SwLoading />}
    </Dialog>
  );
};
