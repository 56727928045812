import clsx from 'clsx';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { useResize } from '../../../hooks/resize';
import { TabBox } from '../../styles/Styles';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { CommunityPersonal } from './CommunityPersonal';

interface Props {}

export const CommunityManage: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const location = useLocation();
  const [openCreatePersonalCommunity, setOpenCreatePersonalCommunity] =
    useState(false);
  const [communityData, setCommunityData] = useState<any>(location.state);
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    console.log('tabChange :', communityData);
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('create', { replace: true, state: communityData });
        break;
      case 2:
        setValue(2);
        navigate('list', { replace: true, state: communityData });
        break;
      case 3:
        setValue(3);
        navigate('manageguide', { replace: true, state: communityData });
        break;
      default:
        setValue(0);
        navigate('/communitymanage', { replace: true, state: communityData });
    }
  };
  useEffect(() => {
    if (location.state) {
      setCommunityData(location.state);
    }
    const path = location.pathname;
    if (path === '/communitymanage/create') setValue(1);
    else if (path === '/communitymanage/manageguide') setValue(3);
    else setValue(2);
  }, [location.pathname]);
  const fileUpload = document.getElementById('fileUpload');
  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>커뮤니티</title>
      </Helmet>
      <TabBox className='flex_between'>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='커뮤니티 생성' value={1} />
          <Tab label='커뮤니티 목록' value={2} />
          <Tab label='운영 가이드' value={3} />
        </Tabs>
        {location.pathname === '/communitymanage/create' && (
          <Button
            variant='contained'
            onClick={() =>
              setOpenCreatePersonalCommunity(!openCreatePersonalCommunity)
            }
          >
            <AddIcon />
            개인 커뮤니티
          </Button>
        )}
      </TabBox>
      <Outlet />
      <CommunityPersonal
        open={openCreatePersonalCommunity}
        onclose={() => setOpenCreatePersonalCommunity(false)}
      />
    </div>
  );
};
