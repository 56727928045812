import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { useSession, xapi } from '../../../hooks/session';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import {
  DraggablePaper,
  RowsCell,
  MultilineTextField,
} from '../../styles/Styles';
import {
  generateUuidId,
  NORMAL_CHARACTER,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  IGuideTool,
  IChallengeCreate,
  IChallengeReward,
  IChallengeRewardBatch,
  DefaultChallengeCreate,
} from '../../../models/challenge';
import {
  deleteChallengeReward,
  getChallengeRewardList,
  registerChallengeReward,
} from '../../../common/api/ApiChallenge';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  KEY_CHALLENGE_REWARD_LIST,
  KEY_FIELD_NORMAL_CHARACTER_LIST,
} from '../../../common/key';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwRewardApplyForm } from '../../commonComponent/SwRewardApplyForm';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { brandPointAdvertiserState } from '../../../common/atom';
import {
  deleteFieldCharacter,
  getFieldCharacterList,
  registerFieldCharacter,
} from '../../../common/api/ApiField';

const PointPaper = styled(Paper)({
  borderRadius: '12px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: '8rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.2rem',
  boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
  borderBottom: '1px solid white',
  fontSize: '1rem',
});

export const CharacterPaper = styled(Paper)({
  margin: '.5rem 0',
  padding: '0 1rem',
  background: '#fafafa',
});

const NO_SELECT_ADVERTISER = 99;

const columns = [
  '번호',
  '캐릭터 이름',
  '일반 출현 개수',
  '일반 획득 마일리지',
  '대왕 출현 개수',
  '대왕 획득 마일리지',
  '삭제',
];

interface Props {
  fieldInfo: any;
}

export const ChallengeFieldCharacter: React.FC<Props> = ({ fieldInfo }) => {
  const { loginVal } = useSession();

  const [fieldCharacterList, setFieldCharacterList] = useState<any>([]);
  const [fieldId, setFieldId] = useState<string>('');
  const [characterId, setCharacterId] = useState<string>('');
  const [characterName, setCharacterName] = useState<string>('');
  const [characterImage, setCharacterImage] = useState<string>('');
  const [characterImageFile, setCharacterImageFile] = useState<any>(null);
  const [maxCharacterNumber, setMaxCharacterNumber] = useState<number>(0);
  const [appearNumber, setAppearNumber] = useState<number>(0);
  const [bigAppearNumber, setBigAppearNumber] = useState<number>(0);
  const [mileage, setMileage] = useState<number>(0);
  const [bigMileage, setBigMileage] = useState<number>(0);
  const [normalCharacter, setNormalCharacter] = useState<boolean>(false);
  const [bigCharacter, setBigCharacter] = useState<boolean>(false);
  const [basicRadius, setBasicRadius] = useState<number>(20);
  const [bigRadius, setBigRadius] = useState<number>(20);
  const [difficulty, setDifficulty] = useState<number>(1);

  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [disabledEdit, setDisabledEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );

  const clickRow = (idx: number, newCharacterList: any[]) => {
    setSelectedRow(idx);
    setCharacterId(newCharacterList[idx].characterId);
    setCharacterImage(
      newCharacterList[idx].image?.length > 0 ? newCharacterList[idx].image : ''
    );
    setCharacterImageFile(null);
    setNormalCharacter(
      newCharacterList[idx].mode === 1 || newCharacterList[idx].mode === 3
    );
    setBigCharacter(
      newCharacterList[idx].mode === 2 || newCharacterList[idx].mode === 3
    );
    setCharacterName(newCharacterList[idx].name);
    setAppearNumber(newCharacterList[idx].appearNumber);
    setBigAppearNumber(newCharacterList[idx].bigAppearNumber);
    setMileage(newCharacterList[idx].mileage);
    setBigMileage(newCharacterList[idx].bigMileage);
    setDifficulty(newCharacterList[idx].difficulty);
    setBasicRadius(newCharacterList[idx].basicRadius);
    setBigRadius(newCharacterList[idx].bigRadius);
  };

  const resetFieldCharacter = () => {
    setCharacterId('');
    setCharacterImage('');
    setCharacterImageFile(null);
    setNormalCharacter(false);
    setBigCharacter(false);
    setCharacterName('');
    setAppearNumber(0);
    setBigAppearNumber(0);
    setMileage(0);
    setBigMileage(0);
    setSelectedRow(-1);
    setDifficulty(1);
    setBasicRadius(20);
    setBigRadius(20);
  };

  const {
    data,
    isLoading,
    refetch: fieldCharacterRefetch,
  } = useQuery(
    KEY_FIELD_NORMAL_CHARACTER_LIST,
    () => {
      if (fieldId !== null && fieldId.length > 0)
        return getFieldCharacterList(fieldId, NORMAL_CHARACTER);
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.fieldCharacter) {
          setFieldCharacterList(res.fieldCharacter);
          const newIdx = res.fieldCharacter
            .map((item: any, idx: number) =>
              item.characterId === characterId ? idx : -1
            )
            .filter((item: number) => item !== -1);
          if (newIdx !== null && newIdx.length > 0)
            clickRow(newIdx[0], res.fieldCharacter);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerChallengeFieldCharacterMutation,
    isError: isMutateError,
  } = useMutation(registerFieldCharacter, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('캐릭터가 등록되었습니다.');
      fieldCharacterRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '캐릭터 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteChallengeFieldCharacterMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteFieldCharacter, {
    onSuccess: () => {
      setOpenMore(0);
      setOpenSnackbar(true);
      setDialogMessage('캐릭터가 삭제되었습니다.');
      resetFieldCharacter();
      fieldCharacterRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '캐릭터를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const removeFieldCharacter = (idx: number) => {
    const newData = {
      characterId: fieldCharacterList[idx].characterId,
      fieldId: fieldId,
    };
    deleteChallengeFieldCharacterMutation(newData);
  };

  const addFieldCharacter = (newImageUrl: string) => {
    const newImage =
      characterImageFile !== null
        ? newImageUrl
        : characterImage.length > 0
        ? characterImage
        : '';
    const newMode = (normalCharacter ? 1 : 0) + (bigCharacter ? 2 : 0);

    const newData: any = {
      characterId:
        characterId !== null && characterId !== ''
          ? characterId
          : generateUuidId(),
      fieldId: fieldId,
      type: 1, // 일반
      name: characterName,
      image: newImage,
      mode: newMode,
      appearNumber: appearNumber,
      mileage: mileage,
      bigAppearNumber: bigAppearNumber,
      bigMileage: bigMileage,
      difficulty: difficulty,
      basicRadius: basicRadius,
      bigRadius: bigRadius,
    };

    const newCharacter: any = {
      fieldId: fieldId,
      character: newData,
    };
    registerChallengeFieldCharacterMutation(newCharacter);
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (characterImageFile !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        characterImageFile
      );
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }
    addFieldCharacter(newUploadUrl);
  }

  const checkFieldCharacter = () => {
    uploadMultipleFiles();
  };

  useEffect(() => {
    if (fieldId !== undefined && fieldId !== null && fieldId.length > 0) {
      fieldCharacterRefetch();
    }
  }, [fieldId]);

  useEffect(() => {
    if (fieldInfo !== undefined && fieldInfo !== null) {
      resetFieldCharacter();
      setFieldId(fieldInfo.field.fieldId);
    }
  }, [fieldInfo]);

  return (
    <>
      <CharacterPaper>
        <Typography sx={{ m: '0 0' }} className='title'>
          {`캐릭터 목록(${fieldCharacterList?.length || 0})`}
        </Typography>
        <TableContainer
          sx={{
            height: '20vh',
            overflowY: 'scroll',
            background: '#ffffff',
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell
                    key={`reward_head_${idx}`}
                    sx={{ p: '.4rem .6rem' }}
                  >
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldCharacterList?.map((row: any, idx: number) => (
                <TableRow
                  key={`reward_body_${idx}`}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                  onClick={event => clickRow(idx, fieldCharacterList)}
                >
                  <TableCell>{idx + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.appearNumber}</TableCell>
                  <TableCell>{row.mileage}</TableCell>
                  <TableCell>{row.bigAppearNumber}</TableCell>
                  <TableCell>{row.bigMileage}</TableCell>
                  <TableCell className='removeButton' sx={{ p: '.1rem .6rem' }}>
                    <Button
                      color='info'
                      variant='contained'
                      onClick={() => {
                        setOpenMore(idx + 1);
                      }}
                    >
                      삭제
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ p: '1.5rem 0' }}>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 3rem 0' }}
              />
            }
          >
            <Box>
              <Box>
                <Typography className='title'>캐릭터 이미지</Typography>
                <SwFileSetting
                  disabled={disabledEdit}
                  image={
                    characterImageFile !== null
                      ? characterImage
                      : characterImage !== ''
                      ? `${imageUrlPrefix}${characterImage}`
                      : ''
                  }
                  setImage={setCharacterImage}
                  setFile={setCharacterImageFile}
                  file={characterImageFile}
                />
              </Box>
            </Box>
            <Box>
              <Box className='flex_start'>
                <Box className='flex_start'>
                  <Checkbox
                    checked={normalCharacter}
                    onChange={() => setNormalCharacter(!normalCharacter)}
                    sx={{ p: 0, mr: '.5rem' }}
                  />
                  <Typography className='title' sx={{ mr: '3rem' }}>
                    일반
                  </Typography>
                </Box>
                <Box className='flex_start'>
                  <Checkbox
                    checked={bigCharacter}
                    onChange={() => setBigCharacter(!bigCharacter)}
                    sx={{ p: 0, mr: '.5rem' }}
                  />
                  <Typography className='title' sx={{ mr: '3rem' }}>
                    대왕
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ mt: '1rem' }}>
                <Typography className='title'>캐릭터 이름</Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '20rem' }}
                  value={characterName}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setCharacterName(evt.target.value)}
                />
              </Box>
              <Box sx={{ mt: '1rem' }}>
                <Typography className='title'>캐릭터 아이디</Typography>
                <TextField
                  disabled={true}
                  sx={{ width: '20rem' }}
                  value={characterId}
                />
              </Box>
            </Box>
            <Box>
              {/* <Box>
                <Typography className='title'>
                  총 획득 가능 캐릭터 수
                </Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '10rem' }}
                  value={maxCharacterNumber}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setMaxCharacterNumber(Number(evt.target.value))}
                />
              </Box> */}
              <Box>
                <Typography className='title'>
                  참여자 반경 300m 내 캐릭터 출현 개수
                </Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '10rem' }}
                  value={appearNumber}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setAppearNumber(Number(evt.target.value))}
                />
              </Box>
              <Box>
                <Typography className='title'>
                  챌린지 진행 중 만날 대왕 캐릭터 개수
                </Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '10rem' }}
                  value={bigAppearNumber}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setBigAppearNumber(Number(evt.target.value))}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography className='title'>일반 획득 마일리지</Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '10rem' }}
                  value={mileage}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setMileage(Number(evt.target.value))}
                />
              </Box>
              <Box>
                <Typography className='title'>대왕 획득 마일리지</Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '10rem' }}
                  value={bigMileage}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setBigMileage(Number(evt.target.value))}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <Typography className='title'>난이도</Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '10rem' }}
                  value={difficulty}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setDifficulty(Number(evt.target.value))}
                />
              </Box>
              <Box>
                <Typography className='title'>
                  랜덤(기본) 포획 반경(m)
                </Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '10rem' }}
                  value={basicRadius}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setBasicRadius(Number(evt.target.value))}
                />
              </Box>
              <Box>
                <Typography className='title'>대왕 포획 반경(m)</Typography>
                <TextField
                  disabled={disabledEdit}
                  sx={{ width: '10rem' }}
                  value={bigRadius}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setBigRadius(Number(evt.target.value))}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <Box className='flex_start' sx={{ mt: '0rem' }}>
          <Box>
            <Button
              variant='outlined'
              sx={{ m: '1rem 1rem' }}
              onClick={resetFieldCharacter}
            >
              신규 캐릭터
            </Button>
            <Button
              variant='contained'
              sx={{ m: '1rem 0' }}
              onClick={checkFieldCharacter}
            >
              저장
            </Button>
          </Box>
        </Box>
        <SwSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          contents={dialogMessage}
        />
        <SwAlert
          open={alertOpen}
          onConfirm={() => setAlertOpen(false)}
          title={alertTitle}
          contents={dialogMessage}
        />
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography sx={{ mt: '0.7rem' }}>
              캐릭터를 삭제하시겠습니까?
            </Typography>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => {
                removeFieldCharacter(openMore - 1);
              }}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </CharacterPaper>
    </>
  );
};
