import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { INotice, INoticeRequest } from '../../models/notice';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Notice
 */

export const getNoticeList = (data: INoticeRequest) =>
  xapi
    .get(
      `/admin/v2/apis/notice/general?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postNotice = (data: INotice) =>
  xapi.post(`/admin/v2/apis/notice/general`, {
    noticeId: data.noticeId,
    title: data.title,
    content: data.content,
    ios: data.ios,
    android: data.android,
  });

export const putNotice = (data: INotice) =>
  xapi.put(`/admin/v2/apis/notice/${data.noticeId}/general`, {
    title: data.title,
    content: data.content,
    ios: data.ios,
    android: data.android,
  });

export const deleteNotice = (data: INotice) =>
  xapi.post(`/admin/v2/apis/notice/general/delete`, data);
