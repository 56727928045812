import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';

import { MultilineTextField } from '../../styles/Styles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import {
  postDataPortalFaq,
  putDataPortalFaq,
  deleteDataPortalFaq,
} from '../../../common/api/ApiDataPortal';
import { IDataPortalFaq } from '../../../models/dataportal';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
  faq: any;
}

export const FAQDrawup: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
  faq,
}) => {
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const [faqIdx, setFaqIdx] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const { mutate: postDataPortalFaqMutate, isError: isMutateError } =
    useMutation(postDataPortalFaq, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('자주 묻는 질문을 등록했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '자주 묻는 질문 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const { mutate: putDataPortalFaqMutate, isError: isPutMutateError } =
    useMutation(putDataPortalFaq, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('자주 묻는 질문을 수정했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '자주 묻는 질문 수정이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const checkDataPortalFaq = () => {
    const newData: IDataPortalFaq = {
      idx: Number(faqIdx),
      title: titleRef.current?.value,
      content: contentRef.current?.value,
    };

    faqIdx !== ''
      ? putDataPortalFaqMutate(newData)
      : postDataPortalFaqMutate(newData);
  };

  const { mutate: deleteDataPortalFaqMutation, isError: isDeleteMutateError } =
    useMutation(deleteDataPortalFaq, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('자주 묻는 질문을 삭제했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '자주 묻는 질문을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const deleteGuide = (idx: number) => {
    const newData: IDataPortalFaq = {
      idxList: [idx],
    };
    console.log(newData);
    deleteDataPortalFaqMutation(newData);
  };

  useEffect(() => {
    console.log('faq : ', faq);
    setFaqIdx(faq?.idx === undefined ? '' : faq?.idx);
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          minHeight: '56vh',
          minWidth: '44vw',
        },
      }}
    >
      <DialogTitle sx={{ pl: '24px !important' }}>
        <Box className='flex_between'>
          <span>
            {!edit && faq === null
              ? '자주 묻는 질문 작성'
              : !edit && faq?.idx !== null
              ? '자주 묻는 질문 수정'
              : '자주 묻는 질문'}
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent>
        {edit ? (
          <>
            <Typography
              sx={{ fontWeight: 700, mb: '.7rem', fontSize: '1.1rem' }}
            >
              {faq?.title}
            </Typography>
            <Typography sx={{ mb: '.7rem', color: '#999999' }}>
              {faq?.createDate}
            </Typography>
            <Divider />
            <MultilineTextField
              multiline
              rows={10}
              fullWidth
              value={faq?.content}
            />
            <Divider />
          </>
        ) : (
          <>
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>제목</Typography>
            <TextField
              fullWidth
              inputRef={titleRef}
              defaultValue={faq?.title}
            />
            <Divider />
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>내용</Typography>
            <MultilineTextField
              inputRef={contentRef}
              multiline
              rows={8}
              fullWidth
              defaultValue={faq?.content}
            />
          </>
        )}
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        {edit ? (
          <>
            <Button
              onClick={() => {
                deleteGuide(faq?.idx);
              }}
              color='info'
              variant='outlined'
            >
              삭제
            </Button>
            <Button onClick={isEdit} color='info' variant='contained'>
              수정
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} color='info' variant='outlined'>
              닫기
            </Button>
            <Button
              onClick={() => checkDataPortalFaq()}
              color='info'
              variant='contained'
            >
              저장
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
