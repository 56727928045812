import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface Props {}

interface IStampTour {
  title?: string;
  id: string;
  name?: string;
  lat: number;
  lng: number;
  radius: number;
}

export const Test1234: React.FC<Props> = () => {
  const [mapCenter, setMapCenter] = useState<any>([37.5, 127.012]);
  const mapOptions = {
    center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
    useStyleMap: true,
    zoom: 17,
    minZoom: 17,
    maxZoom: 17,
    scaleControl: false,
    mapDataControl: false,
    logoControlOptions: {
      position: naver.maps.Position.LEFT_BOTTOM,
    },
    disableKineticPan: false,
  };
  const map = useRef<any>();
  const stampRef = useRef<any>(null);
  const [stamps, setStamps] = useState<IStampTour[]>([]);
  const [done, setdone] = useState(false);
  let drawingLine: any;
  let drawingCircle: any;
  let drawingOverlay: any;
  let drawingStart = false;
  let centerPosition: any;
  let length: any;
  let news: any;

  const clickEvent = (evt: any) => {
    const newCoord = evt.coord;
    console.log(
      'click>>>>>>>>',
      newCoord,
      drawingStart,
      drawingCircle,
      drawingLine
    );
    if (!drawingStart) {
      drawingStart = true;
      centerPosition = evt.coord;
      drawingLine = new naver.maps.Polyline({
        strokeWeight: 2,
        strokeOpacity: 0.6,
        strokeColor: '#00a0e9',
        path: [newCoord],
        map: map.current,
      });
      drawingCircle = new naver.maps.Circle({
        map: map.current,
        center: newCoord,
        radius: length,
        fillColor: 'crimson',
        fillOpacity: 0.1,
      });
      drawingLine.setMap(map.current);
      drawingCircle.setMap(map.current);
    }
  };

  const moveEvent = (evt: any) => {
    if (drawingStart) {
      const mousePosition = evt.coord;
      const linePath = [centerPosition, mousePosition];
      drawingLine.setPath(linePath);
      length = drawingLine.getDistance();
      const info = `<div class ='test'><span>${Math.round(
        length
      )}m</span></div>`;
      if (length >= 0) {
        const circleOptions = {
          center: centerPosition,
          radius: length,
        };
        drawingCircle.setOptions(circleOptions);
        drawingLine.setMap(map.current);
        drawingCircle.setMap(map.current);
        const infowindow = new naver.maps.InfoWindow({
          content: info,
          borderWidth: 0,
          disableAnchor: true,
          backgroundColor: 'transparent',
          pixelOffset: new naver.maps.Point(0, -28),
        });
        infowindow.open(map.current, mousePosition);
        setdone(!done);
      } else {
        drawingLine.setMap(null);
        drawingCircle.setMap(null);
        drawingStart = false;
        drawingCircle = null;
        drawingLine = null;
        centerPosition = null;
      }
    }
  };

  const rightClickEvent = (evt: any) => {
    if (drawingStart) {
      const rClickPosition = evt.coord;
      console.log('rclick>>>>>>>>>>>>', evt.coord);
      const polyline = new naver.maps.Polyline({
        map: map.current,
        path: [centerPosition, rClickPosition],
        strokeWeight: 3,
        strokeColor: '#00a0e9',
        strokeOpacity: 0.1,
        strokeStyle: 'solid',
      });
      const circle = new naver.maps.Circle({
        map: map.current,
        center: centerPosition,
        radius: polyline.getDistance(),
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0.1,
        strokeStyle: 'solid',
        fillColor: '#00a0e9',
        fillOpacity: 0.2,
      });
      const circleInfo = `<div><span>${Math.round(
        polyline.getDistance()
      )}m</span></div>`;
      const infowindow = new naver.maps.InfoWindow({
        content: circleInfo,
        borderWidth: 0,
        disableAnchor: true,
        backgroundColor: 'transparent',
        pixelOffset: new naver.maps.Point(0, -28),
      });
      news = {
        id: '1',
        lat: centerPosition.y,
        lng: centerPosition.x,
        radius: polyline.getDistance(),
      };
      stampRef.current = news;
      setStamps([...stamps, stampRef.current]);
      infowindow.open(map.current, rClickPosition);
      circle.setMap(map.current);
      polyline.setMap(map.current);
      drawingCircle.setMap(null);
      drawingLine.setMap(null);
      drawingStart = false;
      drawingCircle = null;
      drawingLine = null;
      centerPosition = null;
      console.log('dd???>', stampRef.current);
    }
  };

  const setStampCircles = () => {
    stamps.map((stamp: any, idx: number) => {
      const newStamps = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(stamp.lat, stamp.lng),
        radius: stamp.radius,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0.1,
        strokeStyle: 'solid',
        fillColor: '#00a0e9',
        fillOpacity: 0.2,
      });
      const newInfo = new naver.maps.InfoWindow({
        content: `${idx + 1}`,
        borderWidth: 0,
        disableAnchor: true,
        backgroundColor: 'transparent',
        pixelOffset: new naver.maps.Point(0, -28),
      });
    });
  };

  const makeMap = () => {
    map.current = new naver.maps.Map('map', mapOptions);
  };

  const drawingMap = () => {
    naver.maps.Event.addListener(map.current, 'click', clickEvent);
    naver.maps.Event.addListener(map.current, 'mousemove', moveEvent);
    naver.maps.Event.addListener(map.current, 'rightclick', rightClickEvent);
  };

  useEffect(() => {
    makeMap();
    return () => {
      map.current = null;
    };
  }, []);

  useEffect(() => {
    drawingMap();
  }, []);

  // useCallback(() => {
  //   stamps.length > 0 && setStamps([...stamps, stampRef.current]);
  //   console.log('ref>>>>>>>>>>>>', stampRef.current);
  // }, [stampRef.current]);

  return (
    <div style={{ padding: '1rem' }}>
      <div id='map' ref={map} style={{ width: '80vw', height: '80vh' }} />
      <div>2</div>
      {stamps.length > 0 &&
        stamps.map((stamp: any, idx: number) => (
          <div key={idx.toString()}>
            <span>{stamp.lat}</span>
            <span>{stamp.lng}</span>
            <span>{stamp.radius}</span>
          </div>
        ))}
    </div>
  );
};
