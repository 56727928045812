import { Alert, Snackbar } from '@mui/material';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  contents: string;
}

export const SwSnackbar: React.FC<Props> = ({ open, onClose, contents }) => (
  <>
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <Alert onClose={onClose} icon={false} sx={{ width: '100%', mb: '4rem' }}>
        {contents}
      </Alert>
    </Snackbar>
  </>
);
