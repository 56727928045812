import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { JoinDialog } from './JoinDialog';
import { SwPagination } from '../commonComponent/tableElement/SwPagination';
import { useSession, xapi } from '../../hooks/session';
import {
  getAccountList,
  getAccountRoleList,
  patchAccountRole,
  deleteAccount,
  patchAccountObserver,
} from '../../common/api/ApiAccount';
import {
  KEY_ACCOUNT_LIST,
  KEY_ACCOUNT_ROLE_LIST,
  KEY_ACCOUNT_ROLE_CHANGE,
} from '../../common/key';
import { RESULT_OK } from '../../common/resultCode';
import { SwSnackbar } from '../commonComponent/views/SwSnackbar';
import { SwAlert } from '../commonComponent/views/SwAlert';
import { SwObserverDialog } from './SwObseverDialog';

interface Props {}

const columns = [
  '이름',
  '아이디',
  '계정 생성일시',
  '연락처',
  '권한',
  '운영자',
  '수정',
];

export const AccountManagement: React.FC<Props> = () => {
  const { loginVal } = useSession();
  const roleRef = useRef<any>();
  const empSearchRef = useRef<any>();
  const checkBoxAllRef = useRef<any>();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [check, setCheck] = useState<boolean>(false);
  const [checkContents, setCheckContents] = useState<string>('');
  const onCloseCheck = () => setCheck(!check);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentRow, setCurrentRow] = useState<string>('');
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [openChangeRole, setOpenChangeRole] = useState(false);
  const [openChangeObserver, setOpenChangeObserver] = useState(false);
  const [observerType, setObserverType] = useState<number>(0);
  const [empList, setEmpList] = useState<any>([]);
  const [createEmp, setCreateEmp] = useState<boolean>(false);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const openCreateEmp = () => setCreateEmp(!createEmp);

  const { data: role } = useQuery(KEY_ACCOUNT_ROLE_LIST, () =>
    getAccountRoleList()
  );

  const { data, isLoading, refetch } = useQuery(
    KEY_ACCOUNT_LIST,
    () => getAccountList(page, rowsPerPage, searchWord),
    {
      onSuccess: res => {
        console.log('emp list :', res);
        setEmpList(res.account);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const handleChangePage = (_event: any, newPage: number) => {
    setCheckedAll(false);
    setCheckedButtons([]);
    setPage(newPage);
    queryClient.prefetchQuery(KEY_ACCOUNT_LIST, () =>
      getAccountList(newPage, rowsPerPage, searchWord)
    );
  };

  const { mutate: accountRoleMutate, isError: isRoleMutateError } = useMutation(
    patchAccountRole,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_ACCOUNT_LIST);
        setCheckedButtons([]);
        setCheckedAll(false);
      },
      onError: error => {
        console.log(error);
      },
    }
  );

  const { mutate: accountObserverMutate, isError: isObserverMutateError } =
    useMutation(patchAccountObserver, {
      onSuccess: () => {
        queryClient.invalidateQueries(KEY_ACCOUNT_LIST);
        setCheckedButtons([]);
        setCheckedAll(false);
      },
      onError: error => {
        console.log(error);
      },
    });

  const changeRole = () => {
    if (roleRef.current.value && checkedButtons.includes(currentRow)) {
      const newRole = role
        ?.map((item: any, idx: number) => {
          if (item === roleRef.current.value) return idx;
          return -1;
        })
        .filter((item: any) => item !== -1);
      const newEmp = {
        ids: checkedButtons,
        role: newRole.length > 0 ? newRole[0] + 1 : 1,
      };
      accountRoleMutate(newEmp);
      setOpenChangeRole(false);
    } else if (!currentRow) {
      console.log('변경할 아이디를 선택해주세요');
    } else if (!roleRef.current.value) {
      console.log('변경할 권한을 선택해주세요');
    }
  };

  const changeObserver = () => {
    const newEmp = {
      ids: checkedButtons,
      observerType: observerType,
    };
    accountObserverMutate(newEmp);
    setOpenChangeObserver(false);
  };

  const { mutate: deleteAccountMutate, isError: isDeleteMutateError } =
    useMutation(deleteAccount, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          queryClient.invalidateQueries(KEY_ACCOUNT_LIST);
          setCheckedButtons([]);
          setCurrentRow('');
          setCheckContents(`아이디를 삭제했습니다.`);
        } else {
          setCheckContents(
            `아이디를 삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
        setCheck(true);
      },
      onError: error => {
        console.log(error);
        setCheckContents(`아이디를 삭제하는 동안 시스템 오류가 발생했습니다.`);
        setCheck(true);
      },
    });

  const deleteSelectedAccount = () => {
    const accountList = empList
      ?.map((row: any, idx: number) => {
        if (checkedButtons.includes(row.id)) {
          return row.id;
        }
        return '';
      })
      .filter((item: string) => item !== '');
    if (accountList.length > 0) {
      deleteAccountMutate(accountList);
    } else {
      console.log('delete error : empty list');
    }
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      empList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.id]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const clickAccount = (event: any, id: string) => {
    if (event.target.classList.contains('MuiButton-root')) {
      return;
    }

    if (checkedButtons.includes(id)) {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
      setCurrentRow('');
    } else {
      setCheckedButtons([...checkedButtons, id]);
      setCurrentRow(id);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  const searchAccount = () => {
    queryClient.prefetchQuery(KEY_ACCOUNT_LIST, () =>
      getAccountList(page, rowsPerPage, searchWord)
    );
  };

  const changeAccount = () => {
    if (loginVal.value.user.type !== 3) {
      setOpenDialog(true);
      setDialogMessage('마스터 권한만 정보를 수정할 수 있습니다.');
    } else {
      setIsEdit(true);
      openCreateEmp();
    }
  };

  const checkObserver = () => {
    if (loginVal.value.user.type !== 3) {
      setOpenDialog(true);
      setDialogMessage('마스터 권한만 권한을 변경할 수 있습니다.');
      return;
    }
    if (checkedButtons.length === 0) {
      setOpenDialog(true);
      setDialogMessage('변경할 아이디를 선택해주세요.');
      return;
    }
    setOpenChangeObserver(true);
  };

  const checkRole = () => {
    if (loginVal.value.user.type !== 3) {
      setOpenDialog(true);
      setDialogMessage('마스터 권한만 권한을 변경할 수 있습니다.');
    } else setOpenChangeRole(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchAccount();
    }
  };

  if (isLoading) return <div>isLoading...</div>;

  // useEffect(() => {
  //   try {
  //     console.log('getAccount list : ', data);
  //     // const accountList = data.account.map((item: any) => item);
  //     // setEmpList(accountList);
  //     setEmpList(usersdummy);
  //   } catch (error) {
  //     console.log('er>', error);
  //   }
  //   // setEmpList(usersdummy);
  // }, []);

  return (
    <>
      <Box className='flex_between' sx={{ m: '2rem 0' }}>
        <TextField
          placeholder='전체 계정 검색'
          inputRef={empSearchRef}
          sx={{ width: '20rem' }}
          onChange={onChangeSearch}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={searchAccount}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          color='info'
          variant='contained'
          onClick={() => {
            setIsEdit(false);
            openCreateEmp();
          }}
        >
          <AddOutlinedIcon sx={{ fontSize: '1rem', mr: '.3rem' }} /> 계정
          생성하기
        </Button>
      </Box>
      <Paper sx={{ m: '2rem 0', p: '2rem' }}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {empList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={event => {
                    setSelectedRow(idx);
                    clickAccount(event, row.id);
                  }}
                >
                  <TableCell padding='checkbox'>
                    <Checkbox
                      id={row.id}
                      onChange={e => {
                        changeHandler(e.currentTarget.checked, row.id);
                      }}
                      checked={checkedButtons.includes(row.id)}
                    />
                  </TableCell>
                  <TableCell key={row.na} sx={{ p: '.3rem .6rem' }}>
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.id}</TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.createTime}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.phoneNumber}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.type === 1
                      ? '일반'
                      : row.type === 2
                      ? '관리자'
                      : row.type === 3
                      ? '마스터'
                      : '일반'}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.isObserver === 1 ? '운영자' : '-'}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    <Button
                      size='small'
                      variant='outlined'
                      color='primary'
                      onClick={changeAccount}
                    >
                      수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Box className='flex_end'>
        <Button
          color='error'
          variant='outlined'
          sx={{ mr: '1rem' }}
          onClick={deleteSelectedAccount}
        >
          계정 삭제
        </Button>
        <Button
          color='error'
          variant='contained'
          sx={{ mr: '1rem' }}
          onClick={checkObserver}
        >
          테스트 운영자 설정
        </Button>
        <Button color='error' variant='contained' onClick={checkRole}>
          권한 변경
        </Button>
      </Box>
      <Dialog
        open={openChangeRole}
        onClose={() => setOpenChangeRole(false)}
        sx={{
          '& .MuiDialog-paper .MuiDialogActions-root': {
            padding: '.5rem',
          },
          '& .MuiDialog-paper .MuiDialogTitle-root': {
            padding: '1rem',
          },
          ' .MuiDialog-paper': {
            width: '30vw',
          },
          marginLeft: '67vw',
          marginTop: '22vh',
          width: '33vw',
        }}
      >
        <DialogTitle>
          <Typography fontSize='.94rem'>
            변경하실 권한을 선택하시고, 확인을 눌러주세요.
          </Typography>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <TextField inputRef={roleRef} select fullWidth defaultValue='일반'>
            <MenuItem key='1' value='일반'>
              일반
            </MenuItem>
            <MenuItem key='2' value='관리자'>
              관리자
            </MenuItem>
            <MenuItem key='3' value='마스터'>
              마스터
            </MenuItem>
          </TextField>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions sx={{ p: 0 }}>
          <Button
            fullWidth
            color='error'
            onClick={() => setOpenChangeRole(false)}
          >
            취소
          </Button>
          <Button fullWidth onClick={changeRole}>
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <SwObserverDialog
        setObserverType={setObserverType}
        open={openChangeObserver}
        onConfirm={() => changeObserver()}
        onClose={() => setOpenChangeObserver(false)}
      />
      <JoinDialog
        role={role}
        open={createEmp}
        onclose={openCreateEmp}
        title={isEdit ? '계정 수정하기' : '계정 생성하기'}
        isEdit={isEdit}
        account={empList[selectedRow]}
      />
      <SwSnackbar
        open={check}
        onClose={onCloseCheck}
        contents={checkContents}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
