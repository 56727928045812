import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Local Store Owner
 */

export const getLocalStoreOwnerList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/localstore/owner?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getLocalStoreOwnerStoreList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/localstore/owner/${data.ownerId}/store?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postLocalStoreOwnerRegister = (data: any) =>
  xapi.post('/admin/v2/apis/localstore/owner/register', data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const changeLocalStoreOwnerPassword = (data: any) =>
  xapi.put(`/admin/v2/apis/localstore/owner/${data.ownerId}/password`, data);

export const deleteLocalStoreOwner = (data: any) =>
  xapi.put(`/admin/v2/apis/localstore/owner/${data.ownerId}/delete`);

/*
 * Local Store
 */
export const getLocalstoreRequestList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/localstore/store/request?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const confirmLocalstoreRegister = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/localstore/store/${data.storeId}/confirm/${data.testType}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const changeLocalstoreApplyStatus = (data: any) =>
  xapi
    .post(
      `/admin/v2/apis/localstore/store/${data.storeId}/status/${data.status}?message=${data.message}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getLocalstoreList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/localstore/store?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerLocalstoreMaster = (data: any) =>
  xapi.post(`/admin/v2/apis/localstore/store/master`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getLocalStoreRewardList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/localstore/store/${data.storeId}/reward?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

/*
 * Notice
 */

export const getLocalStoreNoticeList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/localstore/notice?page=${data.page}&per_page=${data.rowsPerPage}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postLocalStoreNotice = (data: any) =>
  xapi.post(`/admin/v2/apis/localstore/notice`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const putLocalStoreNotice = (data: any) =>
  xapi.put(`/admin/v2/apis/localstore/notice/${data.idx}`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteLocalStoreNotice = (data: any) =>
  xapi.post(`/admin/v2/apis/localstore/notice/delete`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });
