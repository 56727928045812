import clsx from 'clsx';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ChangeEvent, useEffect, useRef, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { PortalInformation } from './PortalInformation';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { RequestPagingTable } from '../../commonComponent/table/SwRequestPagingTable';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { getDataPortalAccountDetailList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_DETAIL_LIST } from '../../../common/key';

interface Props {}

const columns = ['포탈 아이디', '유형', '계약 기관', '담당자', '포탈 생성일자'];

export const PortalUserManagement: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const searchRef = useRef<string | null>();
  const [request, setRequest] = useState<boolean>(true);
  const [portalUserList, setPortalUserList] = useState<any>([]);
  const [selectedPortalUser, setSelectedPortalUser] = useState<any>();
  const [searchKey, setSearchKey] = useState<any>('all');
  const [searchWord, setSearchWord] = useState<any>('');
  const [accountList, setAccountList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [waitCount, setWaitCount] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: refetchDataPortal,
  } = useQuery(
    KEY_DATAPORTAL_ACCOUNT_DETAIL_LIST,
    () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (request) return getDataPortalAccountDetailList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log('PortalUserManagement res :', res);
        if (res != null) {
          const newAccount = res.accountDetail?.map((item: any) => {
            const newDate = timestamp2Localestring(item.constime, 1000);
            const newData = [
              item.portalId,
              item.isManager === 1 ? '통합' : '일반',
              item.parent ? item.parent : '',
              item.userName1,
              newDate.split(' ')[0],
            ];
            return newData;
          });
          setRequestList(newAccount);
          setAccountList(res.accountDetail);
          // setSelectedPortalUser(null);
          setTotalRecords(res.pagination.totalRecordCount);
        }
        setShowForm(true);
      },
      onError: e => {
        console.log(e);
        setShowForm(true);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_DATAPORTAL_ACCOUNT_DETAIL_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getDataPortalAccountDetailList(newData);
    });
  };

  const chooseRequest = (i: number) => {
    setSelectedPortalUser(accountList[i]);
  };

  const onChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchWord(event.target.value);
  };

  const searchAccount = () => {
    prefetch(1);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchAccount();
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  useEffect(() => {
    if (reload) refetchDataPortal();
  }, [reload]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>포탈 계정 관리</title>
      </Helmet>
      <TextField
        inputRef={searchRef}
        placeholder='포탈 아이디 및 계약 기관 이름으로 검색해 주세요.'
        sx={{ width: '28rem', p: '2rem 0' }}
        onChange={onChangeSearch}
        onKeyDown={handleKeyDown}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={searchAccount}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RequestPagingTable
        columns={columns}
        rows={requestList}
        page={page}
        rowsPerPage={rowsPerPage}
        totalCount={totalRecords}
        title={`포탈 계정 ${totalRecords}개`}
        onclick={(i: number) => setSelectedPortalUser(accountList[i])}
        handleChangePage={handleChangePage}
      />

      {showForm === true && (
        <PortalInformation user={selectedPortalUser} setReload={setReload} />
      )}
    </div>
  );
};
