import {
  Box,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { Dispatch, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { DataportalConnect } from '../../commonComponent/DataportalConnect';
import {
  convertDistance,
  convertStep,
  convertTime,
  generateUuidId,
} from '../../../common/helper';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { IWalkcourse, IWalkcouseDetail } from '../../../models/walkcourse';
import {
  getWalkcourseRequestList,
  getWalkcourseRequestDetail,
  registerWalkcourse,
  deleteWalkcourse,
} from '../../../common/api/ApiWalkcourse';
import { RESULT_OK } from '../../../common/resultCode';

const CreatePaper = styled(Paper)({
  margin: '2rem 0',
  padding: '1.5rem',
  borderRadius: '12px',
});

interface Props {
  commandType: number;
  setCommandType: Dispatch<React.SetStateAction<number>>;
  mapInfo: any;
  walkcourseDetail: IWalkcouseDetail;
  setWalkcourseDetail: Dispatch<React.SetStateAction<IWalkcouseDetail>>;
  onReload: () => void;
}

export const WalkcourseForm: React.FC<Props> = ({
  commandType,
  setCommandType,
  mapInfo,
  walkcourseDetail,
  setWalkcourseDetail,
  onReload,
}) => {
  const [walkcourseId, setWalkcourseId] = useState<string>('');
  const [localWalkcourseDetail, setLocalWalkcourseDetail] = useState<any>();
  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<any>(null);
  const [title, setTitle] = useState<string>('');
  const [distance, setDistance] = useState<number>(0);
  const [estimatedSteps, setEstimatedSteps] = useState<number>(0);
  const [expectedMins, setExpectedMins] = useState<number>(0);
  const [courseCenterPointLng, setCourseCenterPointLng] = useState<number>(0);
  const [courseCenterPointLat, setCourseCenterPointLat] = useState<number>(0);
  const [courseCenterRadius, setCourseCenterRadius] = useState<number>(0);
  const [creator, setCreator] = useState<string>('');
  const [editor, setEditor] = useState<string>('');
  const [courseImgpath, setCourseImgpath] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [show, setShow] = useState<number>(1);
  const [difficulty, setDifficulty] = useState<number>(3);
  const [changeDistance, setChangeDistance] = useState<boolean>(false);
  const [changeStep, setChangeStep] = useState<boolean>(false);
  const [changeTime, setChangeTime] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    mutate: registerWalkcourseMutation,
    isError: isWalkcourseMutateError,
  } = useMutation(registerWalkcourse, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        onReload();
        setOpenSnackbar(true);
        setDialogMessage('길 코스가 저장되었습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `길 코스를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        `길 코스를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const {
    mutate: deleteWalkcourseMutation,
    isError: isDeleteWalkcourseMutateError,
  } = useMutation(deleteWalkcourse, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        onReload();
        setOpenSnackbar(true);
        setDialogMessage('길 코스가 삭제되었습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `길 코스를 삭제하는 동안 오류가 발생했습니다. (${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '길 코스를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteWalkcourseInfo = () => {
    const newData: IWalkcourse = {
      courseId: walkcourseId,
    };

    deleteWalkcourseMutation(newData);
  };

  const checkValid = () => {
    const newCourseCenterRadius = mapInfo.circle?.radius;
    const newCreator =
      connectDataportal !== undefined &&
      connectDataportal !== null &&
      connectDataportal.portalId !== undefined &&
      connectDataportal.portalId !== null
        ? connectDataportal.portalId
        : creator.length > 0
        ? creator
        : '';

    var val = 0;

    if (val === 0 && walkcourseId.length === 0) val = 1;
    if (
      val === 0 &&
      (newCourseCenterRadius === undefined || newCourseCenterRadius === null)
    )
      val = 2;
    // if (val === 0 && newCreator.length === 0) val = 3;
    if (val === 0 && title.length === 0) val = 4;
    if (val === 0 && mapInfo?.points.length === 0) val = 5;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '길코스 아이디를 입력해주세요.';
          break;
        case 2:
          message = '위치 인식 반경을 지정해주세요';
          break;
        case 3:
          message = '데이터 포탈 연결을 해주세요';
          break;
        case 4:
          message = '길코스 이름을 입력해주세요';
          break;
        case 5:
          message = '길 코스를 추가해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenDialog(true);
    }
    return val;
  };

  const registerWalkcourseInfo = () => {
    console.log('register walkcourse:', mapInfo);
    if (checkValid() !== 0) {
      return;
    }

    const newCourseCenterPointLng = mapInfo.circle?.lng;
    const newCourseCenterPointLat = mapInfo.circle?.lat;
    const newCourseCenterRadius = mapInfo.circle?.radius;
    const newCreator =
      connectDataportal !== undefined &&
      connectDataportal !== null &&
      connectDataportal.portalId !== undefined &&
      connectDataportal.portalId !== null
        ? connectDataportal.portalId
        : '';

    const newPointList = mapInfo.points?.map((item: any, idx: number) => {
      const newPoint = {
        pointId: generateUuidId(),
        pointPtLat: item.lat,
        pointPtLng: item.lng,
        pointRadius: item.radius,
        pointSeq: idx,
      };
      return newPoint;
    });

    const newSpotList = mapInfo.pointsContent?.map((item: any, idx: number) => {
      const newSpot = {
        spotId: generateUuidId(),
        spotPointLat: item.lat,
        spotPointLng: item.lng,
        spotRadius: item.radius !== '' ? item.radius : 150,
        spotName: item.name,
        spotImage: item.image,
        spotSeq: idx,
        description: item.description,
        className: item.className,
        link: item.link,
        phoneNo: item.phoneNo,
        address: item.address,
        spotDeleted: 0,
      };
      return newSpot;
    });

    const newData: IWalkcourse = {
      courseId: walkcourseId,
      courseName: title,
      courseCal: 0,
      courseCenterPointLat: newCourseCenterPointLat,
      courseCenterPointLng: newCourseCenterPointLng,
      courseCenterRadius: newCourseCenterRadius,
      courseDeleted: 0,
      courseDistance: distance,
      courseImgpath:
        courseImgpath === null || courseImgpath === ''
          ? '/v2/walkcourse/map_img.jpg'
          : courseImgpath,
      courseTime: expectedMins,
      courseStep: estimatedSteps === 0 ? convertStep(distance) : estimatedSteps,
      creator: newCreator,
      editor: editor,
      points: newPointList,
      spots: newSpotList,
      courseContent: content,
      courseShow: show,
      courseDifficulty: difficulty,
    };
    console.log('register newData:', newData);
    registerWalkcourseMutation(newData);
  };

  useEffect(() => {
    if (commandType === 1) {
      registerWalkcourseInfo();
    } else if (commandType === 2) {
      registerWalkcourseInfo();
    } else if (commandType === 3) {
      deleteWalkcourseInfo();
    }
    setCommandType(0);
  }, [commandType]);

  useEffect(() => {
    console.log('WalkcourseForm walkcourseDetail :', walkcourseDetail);
    if (walkcourseDetail && walkcourseDetail.course.courseId !== '') {
      setLocalWalkcourseDetail(walkcourseDetail);
      setTitle(walkcourseDetail.course.courseName);
      setWalkcourseId(walkcourseDetail.course.courseId);
      setDistance(walkcourseDetail.course.courseDistance);
      setEstimatedSteps(
        walkcourseDetail.course.courseStep !== 0
          ? walkcourseDetail.course.courseStep
          : convertStep(walkcourseDetail.course.courseDistance)
      );
      setExpectedMins(walkcourseDetail.course.courseTime);
      setCourseCenterPointLat(walkcourseDetail.course.courseCenterPointLat);
      setCourseCenterPointLng(walkcourseDetail.course.courseCenterPointLng);
      setCourseCenterRadius(walkcourseDetail.course.courseCenterRadius);
      setCourseImgpath(walkcourseDetail.course.courseImgpath);
      setEditor(
        walkcourseDetail.course.editor ? walkcourseDetail.course.editor : ''
      );
      const newCreator = walkcourseDetail.course.creator
        ? walkcourseDetail.course.creator
        : '';
      setCreator(newCreator);
      setFindDataportal('');
      setConnectDataportal({ portalId: newCreator, name: '' });
      setContent(walkcourseDetail.course.courseContent);
      setShow(walkcourseDetail.course.courseShow);
      setDifficulty(walkcourseDetail.course.courseDifficulty);
    } else {
      setDistance(walkcourseDetail.course.courseDistance);
      setEstimatedSteps(convertStep(walkcourseDetail.course.courseDistance));
      setExpectedMins(walkcourseDetail.course.courseTime);
    }
  }, [walkcourseDetail]);

  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>길 코스 이름</Typography>
          <TextField
            placeholder='길 코스 이름을 50자 이내로 작성해 주세요.'
            value={title}
            onChange={evt => setTitle(evt.target.value.substring(0, 50))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>{title.length} / 50</Typography>
                </InputAdornment>
              ),
            }}
            sx={{ width: '70vw' }}
          />
          {/* <MenuItem value={1}>걸음수 </MenuItem> */}
          <Divider />
        </Box>
        <Box>
          <SwUuidCreate
            title='길 코스'
            id={walkcourseId}
            setId={setWalkcourseId}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>추천 코스 노출</Typography>
          <TextField
            select
            sx={{ width: '10rem' }}
            value={show}
            onChange={evt => {
              setShow(Number(evt.target.value));
            }}
          >
            <MenuItem value={0}>노출 안함 </MenuItem>
            <MenuItem value={1}>노출</MenuItem>
          </TextField>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>내용</Typography>
          <TextField
            fullWidth
            multiline
            rows={5}
            sx={{
              '& .MuiOutlinedInput-root': {
                height: 'auto',
              },
            }}
            onChange={evt => setContent(evt.target.value)}
            value={content}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>길 코스 거리</Typography>
          {changeDistance === false ? (
            <TextField
              value={convertDistance(distance)}
              onChange={evt => {
                setChangeDistance(true);
              }}
            />
          ) : (
            <>
              <TextField
                value={Math.round(distance)}
                onChange={evt => {
                  setDistance(Number(evt.target.value));
                }}
              />
              <Button
                variant='contained'
                color='info'
                sx={{ ml: '1rem' }}
                onClick={() => setChangeDistance(false)}
              >
                수정 (미터)
              </Button>
            </>
          )}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>길 코스 예상 걸음 수</Typography>
          {changeStep === false ? (
            <TextField
              value={`${estimatedSteps}걸음`}
              onChange={evt => {
                setChangeStep(true);
              }}
            />
          ) : (
            <>
              <TextField
                value={estimatedSteps}
                onChange={evt => {
                  setEstimatedSteps(Number(evt.target.value));
                }}
              />
              <Button
                variant='contained'
                color='info'
                sx={{ ml: '1rem' }}
                onClick={() => setChangeStep(false)}
              >
                수정 (걸음)
              </Button>
            </>
          )}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>길 코스 소요 시간</Typography>
          {changeTime === false ? (
            <TextField
              value={convertTime(expectedMins)}
              onChange={evt => {
                setChangeTime(true);
              }}
            />
          ) : (
            <>
              <TextField
                value={Math.round(expectedMins)}
                onChange={evt => {
                  setExpectedMins(Number(evt.target.value));
                }}
              />
              <Button
                variant='contained'
                color='info'
                sx={{ ml: '1rem' }}
                onClick={() => setChangeTime(false)}
              >
                수정 (분)
              </Button>
            </>
          )}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>난이도</Typography>
          <TextField
            select
            sx={{ width: '10rem' }}
            value={difficulty}
            onChange={evt => {
              setDifficulty(Number(evt.target.value));
            }}
          >
            <MenuItem value={1}>⭐️</MenuItem>
            <MenuItem value={2}>⭐️⭐️</MenuItem>
            <MenuItem value={3}>⭐️⭐️⭐️</MenuItem>
            <MenuItem value={4}>⭐️⭐️⭐️⭐️</MenuItem>
            <MenuItem value={5}>⭐️⭐️⭐️⭐️⭐️</MenuItem>
          </TextField>
          <Divider />
        </Box>
        <DataportalConnect
          findDataportal={findDataportal}
          setFindDataportal={setFindDataportal}
          connectDataportal={connectDataportal}
          setConnectDataportal={setConnectDataportal}
        />
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
