import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import SearchIcon from '@mui/icons-material/Search';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { ButtonBox, ContentTitle, MarginButton } from './ProgramStyles';
import { ProgramForm } from './ProgramForm';
import { ProgramReward } from './ProgramReward';
import { getDateRange, timestamp2Localestring } from '../../../common/helper';
import {
  getProgramRequestList,
  confirmProgramRegister,
  changeProgramApplyStatus,
  cloneProgramMaster,
} from '../../../common/api/ApiProgram';
import { KEY_PROGRAM_REQUEST_LIST } from '../../../common/key';
import { SwCreationRequestReject } from '../../commonComponent/SwCreationRequestReject';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { RESULT_EMPTY_REWARD, RESULT_OK } from '../../../common/resultCode';
import { getAccountList } from '../../../common/api/ApiAccount';
import { useSession } from '../../../hooks/session';
import { ProgramCurriculum } from './ProgramCurriculum';
import {
  getProgramRegisterStatus,
  programSearchOption,
} from '../../../common/helperProgram';
import { ProgramOpenRegion } from './ProgramOpenRegion';
import { CreateTab, CreateTabs } from '../../styles/Styles';
import { brandPointAdvertiserState } from '../../../common/atom';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const requestColumnlists = [
  '번호',
  '프로그램 이름',
  '신청일자',
  '커뮤니티',
  '고객명',
  '상태',
  '담당자',
];

const clickColumnlist = ['고객명', '신청일자', '담당자'];

interface Props {}

export const ProgramCreate: React.FC<Props> = () => {
  const [brandPointAdvertiserList, setBrandPointAdvertiserList] =
    useRecoilState(brandPointAdvertiserState);

  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const { loginVal, logout, session } = useSession();
  const [programId, setProgramId] = useState<string>('');
  const [curriculumId, setCurriculumId] = useState<string>('');
  const [programList, setProgramList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [programRequestData, setProgramRequestData] = useState<any>();
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);
  const [programMasterType, setProgramMasterType] = useState<any>([]);
  const [programInfo, setProgramInfo] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>('');
  const [selectedRow, setSelectedRow] = useState(-1);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([['-', '-', '-']]);
  const [tabValue, setTabValue] = useState(0);
  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== tabValue) setTabValue(newValue);
  };
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [empList, setEmpList] = useState<any>();
  const [operator, setOperator] = useState<string>(''); // loginVal.value?.user?.id);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  let changeAlertMessage = '';

  const {
    data,
    isLoading,
    refetch: programRequestRefetch,
  } = useQuery(
    KEY_PROGRAM_REQUEST_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      const searchData: any = {
        operator: operator,
        searchKey: searchKey,
        searchWord: searchWord,
      };
      if (checkRequest) return getProgramRequestList(searchData);
      return null;
    },
    {
      onSuccess: res => {
        console.log('requestList:', res);
        const newProgram =
          res &&
          res.creationRequest &&
          res.creationRequest.program.map((item: any, idx: number) => {
            const currentDate = new Date();
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const status = getProgramRegisterStatus(item.registerStatus);
            const newData = [
              idx + 1,
              item.title,
              newDate,
              item.communityName,
              item.customerName,
              status,
              item.operatorName,
            ];
            if (programId === item.programId) {
              setSelectedRow(idx);
            }
            return newData;
          });
        setRequestList(newProgram);
        setProgramList(res ? res.creationRequest.program : []);
        setProgramRequestData(res ? res.creationRequest : null);
        setRewardGivetype(res ? res.creationRequest.rewardGivetype : null);
        setRewardUsetype(res ? res.creationRequest.rewardUsetype : null);
        setBrandPointAdvertiserList(
          res ? res.creationRequest.advertiser : null
        );
        if (selectedId !== '') {
          const newSelect = res.creationRequest.program.filter(
            (item: any) => item.programId === selectedId
          );
          console.log('reloadProgram: ', selectedId, ', info:', newSelect);
          setProgramInfo(newSelect.length > 0 ? newSelect[0] : null);
        }
        setSelectedId('');
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: confirmProgramRegisterMutation, isError: isMutateError } =
    useMutation(confirmProgramRegister, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK || res.templateCode === 5) {
          setOpenSnackbar(true);
          setAlertMessage('저장되었습니다.');
          programRequestRefetch();
        } else if (res.resultCode === RESULT_EMPTY_REWARD) {
          setAlertOpen(true);
          setAlertMessage('리워드를 등록해 주세요.');
        } else {
          setAlertOpen(true);
          setAlertMessage(`프로그램 생성 오류 : ${res.resultCodeMsg}`);
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '프로그램 생성을 승인하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: changeProgramApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeProgramApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage(changeAlertMessage);
      programRequestRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        `요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.`
      );
    },
  });

  const { mutate: cloneProgramMasterMutation, isError: isMasterMutateError } =
    useMutation(cloneProgramMaster, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('복제 완료, 작성중 내역에서 확인하세요.');
          programRequestRefetch();
        } else {
          setOpenSnackbar(true);
          setAlertMessage(
            `프로그램을 복제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          `프로그램을 복제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[4], Chosen[2], Chosen[6]];
    setChosenRequest([newChosen]);
    setProgramId(programList[i].programId);
    setProgramInfo(programList[i]);
    setCurriculumId(programList[i].curriculumId);
    setSelectedRow(i);
    Chosen[1] === '작성중' ? setIsEditing(true) : setIsEditing(false);
  };

  const confirmProgram = (testType: number) => {
    const newData: any = {
      programId: programId,
    };

    confirmProgramRegisterMutation(newData);
  };

  const saveRewardCount = (count: number) => {
    setRewardCount(count);
    if (count !== programInfo.rewardCount) {
      programRequestRefetch();
    }
  };

  const reloadProgram = (id: string) => {
    if (id !== '') {
      setProgramId(id);
      setSelectedId(id);
      programRequestRefetch();
    }
  };

  // const searchProgramRequest = (
  //   newOperator: string,
  //   key: string,
  //   name: string
  // ) => {
  //   const newData = {
  //     operator: newOperator,
  //     searchKey: key,
  //     searchWord: name,
  //   };
  //   searchData = newData;
  //   programRequestRefetch();
  // };

  // useEffect(() => {
  //   if (reloadProgram !== '') {
  //     programRequestRefetch();
  //   }
  //   return () => {};
  // }, [reloadProgram]);

  const onRejectMessage = (message: string) => {
    if (message.length > 0) {
      const newData: any = {
        programId: programId,
        status: 3, // 1-작성중 2-접수요청 3-반려 4-접수완료
        message: message,
      };
      changeAlertMessage = '프로그램이 수정되었습니다.';
      changeProgramApplyStatusMutation(newData);
    }
  };

  const deleteProgram = () => {
    const newData: any = {
      programId: programId,
      status: 9, // 1-작성중 2-접수요청 3-반려 4-접수완료 9-삭제 (어드민 only)
      message: '',
    };
    changeAlertMessage = '프로그램이 삭제되었습니다.';
    changeProgramApplyStatusMutation(newData);
  };

  const searching = () => {
    setPage(1);
    if (searchRef.current.value && selectRef.current.value) {
      if (
        searchRef.current.value === searchWord &&
        selectRef.current.value === searchKey
      ) {
        programRequestRefetch();
      } else {
        setSearchKey(selectRef.current.value);
        setSearchWord(searchRef.current.value);
      }
    } else if (
      searchRef.current.value === '' &&
      selectRef.current.value !== ''
    ) {
      if (
        searchRef.current.value === searchWord &&
        selectRef.current.value === searchKey
      ) {
        programRequestRefetch();
      } else {
        setSearchKey(selectRef.current.value);
        setSearchWord('');
      }
    } else {
      setSearchKey('');
      setSearchWord('');
    }
  };

  const cloneProgram = () => {
    const newData: any = {
      programId: programId,
    };

    cloneProgramMasterMutation(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      programRequestRefetch();
    }
  };

  useEffect(() => {
    console.log('searchKey :', searchKey, ', searchWord:', searchWord);
    programRequestRefetch();
  }, [searchKey, searchWord]);

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      programRequestRefetch();
    }
    return () => {};
  }, [checkRequest]);

  return (
    <>
      <Box sx={{ mt: '1rem' }}>
        <SelectSearchBox
          searchOption={programSearchOption}
          optionValue={selectRef}
          searchWord={searchRef}
          defaultOption='all'
          isSearching={searching}
          placeholderString='프로그램 이름, 고객명 검색'
        />
      </Box>
      <Paper sx={{ cursor: 'pointer', mb: '1rem' }}>
        <Box sx={{ m: '1rem 1rem' }}>
          <Box className='flex_between'>
            <Typography className='title'>
              요청 {requestList?.length}개
            </Typography>
          </Box>
          <TableContainer sx={{ maxHeight: '27rem' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {requestColumnlists.map((column, idx) => (
                    <TableCell
                      key={idx.toString()}
                      sx={{ p: '.4rem .7rem !important' }}
                      className={clsx(
                        { request_head: false },
                        'request_tablehead'
                      )}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {requestList?.map((row: any, idx: number) => (
                  <TableRow
                    key={idx.toString()}
                    onClick={() => chooseRequest(idx)}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                  >
                    {row.map((rowItem: any, rowIdx: number) => (
                      <TableCell
                        key={rowIdx.toString()}
                        sx={{ p: '.6rem .7rem', maxWidth: '25rem' }}
                        className={
                          selectedRow === idx &&
                          programList[idx].programStatus === 0
                            ? clsx(
                                'request_tablecell selected_row not_important_row'
                              )
                            : selectedRow === idx && row[5] === '접수요청'
                            ? clsx(
                                'request_tablecell selected_row specified_row'
                              )
                            : selectedRow === idx
                            ? clsx('request_tablecell selected_row')
                            : programList[idx].programStatus === 0
                            ? clsx('request_tablecell not_important_row')
                            : row[5] === '접수요청'
                            ? clsx('request_tablecell specified_row')
                            : clsx('request_tablecell ')
                        }
                      >
                        {rowIdx !== 0
                          ? row[rowIdx]
                          : programList[idx].programStatus === 0
                          ? `${row[rowIdx]} (close)`
                          : row[rowIdx]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      <RequestTable columns={clickColumnlist} rows={chosenRequest} />
      <Paper>
        <Box sx={{ p: '0rem 0', mt: '1rem', background: '#F5F7FA' }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
            }}
          >
            <CreateTabs value={tabValue} onChange={onChangeTab}>
              <CreateTab key='0' label='기본 정보' />
              <CreateTab key='1' label='공개범위(필터)' />
              <CreateTab key='2' label='커리큘럼' />
              <CreateTab key='3' label='리워드 정보' />
            </CreateTabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <ProgramForm
              programInfo={programInfo}
              setProgramInfo={setProgramInfo}
              reloadProgram={reloadProgram}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ProgramOpenRegion
              programInfo={programInfo}
              setProgramInfo={setProgramInfo}
              reloadProgram={reloadProgram}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <ProgramCurriculum
              curriculumId={curriculumId}
              programInfo={programInfo}
              reloadProgram={reloadProgram}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <ProgramReward
              rewardGivetype={rewardGivetype}
              rewardUsetype={rewardUsetype}
              programInfo={programInfo}
              setProgramInfo={setProgramInfo}
              saveRewardCount={saveRewardCount}
              reloadProgram={reloadProgram}
            />
          </TabPanel>
        </Box>
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box>
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => {
                window.location.reload();
              }}
            >
              신규작성
            </MarginButton>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => cloneProgram()}
            >
              프로그램 복제
            </MarginButton>
          </Box>
          <Box>
            {isEditing && (
              <MarginButton
                variant='contained'
                color='secondary'
                onClick={() => setOpenDelete(true)}
              >
                삭제
              </MarginButton>
            )}
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => setRejectOpen(true)}
            >
              수정 요청
            </MarginButton>
            <MarginButton
              variant='contained'
              color='info'
              onClick={() => confirmProgram(1)}
            >
              승인
            </MarginButton>
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwCreationRequestReject
        title='프로그램'
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onRejectMessage={onRejectMessage}
      />
      <SwConfirmDialog
        contents='작성중인 프로그램을 삭제하시겠습니까?'
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false);
          deleteProgram();
        }}
        confirm={1}
      />
    </>
  );
};
