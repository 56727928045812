import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { useEffect, useState, Dispatch, ChangeEvent, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  KEY_PROGRAM_ATTEND_USER_LIST,
  KEY_PROGRAM_MAGAZINE_LIST,
} from '../../../common/key';
import {
  deleteProgramMagazine,
  getProgramAttendUserList,
  getProgramMagazineList,
  postProgramMagazine,
} from '../../../common/api/ApiProgram';
import { SwPagination } from '../tableElement/SwPagination';
import { SelectSearchBox } from '../SelectSearchBox';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSelectTemplate } from './SwSelectTemplate';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { SwImageMultipleView } from '../SwImageMultipleView';

const selectVal = [
  {
    title: '매거진 전체',
    value: 'all',
  },
  { title: '레시피', value: 'recipe' },
  { title: '맞춤 운동', value: 'exercise' },
  { title: '식재료 사전', value: 'ingredient' },
  { title: '예방 및 관리', value: 'manage' },
];

interface Props {
  open: boolean;
  onClose: () => void;
  templateCode: string;
  subType: number; // sub_type: 1:식재료 사전, 2:레시피, 3:맞춤 운동, 4:예방 및 관리
}

const columns = ['번호', '위치', '제목', '순서', '상세보기'];

export const SwProgramMagazine: React.FC<Props> = ({
  open,
  onClose,
  templateCode,
  subType,
}) => {
  const queryClient = useQueryClient();
  const selectRef = useRef<any>(2);
  const searchRef = useRef<any>();
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [programMagazineList, setProgramMagazineList] = useState<any>([]);
  const [selectTarget, setSelectTarget] = useState<any>();
  const [imageList, setImageList] = useState<any[]>([]);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rowsPerMemberPage, setRowsPerMemberPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openTemplate, setOpenTemplate] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>('');
  const [openDetailView, setOpenDetailView] = useState<boolean>(false);

  let searchWord = '';
  let searchKey = '';

  const {
    data,
    isLoading,
    refetch: refetchProgramMagzine,
  } = useQuery(
    KEY_PROGRAM_MAGAZINE_LIST,
    () => {
      const newData: any = {
        templateCode: templateCode,
        searchKey: searchKey,
        searchWord: searchWord,
        page: page,
        rowsPerPage: 0,
      };
      if (open) return getProgramMagazineList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res != null) {
          setProgramMagazineList(res.magazine);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: postProgramMagazineMutate, isError: isMutateError } =
    useMutation(postProgramMagazine, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setSnackbarMessage('프로그램 매거진을 저장했습니다.');
          refetchProgramMagzine();
        } else {
          setOpenSnackbar(true);
          setSnackbarMessage(
            `프로그램 매거진을 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setSnackbarMessage(
          `프로그램 매거진 저장가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
        );
      },
    });

  const { mutate: deleteProgramMagazineMutate, isError: isMutateDeleteError } =
    useMutation(deleteProgramMagazine, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setSnackbarMessage('프로그램 매거진을 삭제했습니다.');
          refetchProgramMagzine();
        } else {
          setOpenSnackbar(true);
          setSnackbarMessage(
            `프로그램 매거진을 삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setSnackbarMessage(
          `프로그램 매거진 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
        );
      },
    });

  const saveMagazineList = () => {
    const newList = programMagazineList.map((item: any, idx: number) => {
      const newData: any = {
        templateCode: templateCode,
        magazineId: item.magazineId,
        title: item.title,
        menuPath: item.menuPath,
        seq: idx,
        subType: item.subType,
      };
      return newData;
    });
    const newData: any = {
      templateCode: templateCode,
      magazineList: newList,
    };
    postProgramMagazineMutate(newData);
  };

  const deleteMagazineListApi = () => {
    const newList = checkedButtons.map((item: any, idx: number) => {
      const newData: any = {
        templateCode: templateCode,
        magazineId: item,
        title: '',
        menuPath: '',
        seq: '',
        subType: '',
      };
      return newData;
    });

    const newData: any = {
      templateCode: templateCode,
      magazineList: newList,
    };
    deleteProgramMagazineMutate(newData);
  };

  const deleteMagazineList = () => {
    const newList = programMagazineList.filter(
      (item: any, idx: number) =>
        checkedButtons.includes(item.magazineId) === false
    );
    setProgramMagazineList(newList);
  };

  const clickItem = (row: any) => {
    const target = [
      { userId: row.userId, name: row.name, phoneno: row.phoneno, status: '' },
    ];
    onClose();
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      programMagazineList.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.magazineId]);
      });
    } else {
      setCheckedAll(false);
    }
    console.log('handleSelectAllClick:', checkedButtons);
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    // communityMemberRefetch();
  };

  const topMove = (idx: number) => {
    console.log('top idex :', idx);
    const i = idx + (page - 1) * rowsPerPage;
    const newList = [...programMagazineList];
    const tmp = newList[i];
    newList[i] = newList[i - 1];
    newList[i - 1] = tmp;

    setProgramMagazineList(newList);
  };

  const bottomMove = (idx: number) => {
    console.log('bottom idex :', idx);
    const i = idx + (page - 1) * rowsPerPage;
    const newList = [...programMagazineList];
    const tmp = newList[i];
    newList[i] = newList[i + 1];
    newList[i + 1] = tmp;

    setProgramMagazineList(newList);
  };

  const detailView = (image: string) => {
    const newData = JSON.parse(image);
    setImageList(newData);
    setOpenDetailView(true);
  };

  const isSearching = () => {
    console.log('searchRef.current.value: ', searchRef.current.value);
    console.log('selectRef.current.value: ', selectRef.current.value);
    searchKey = selectRef.current.value;
    searchWord = searchRef.current.value;

    refetchProgramMagzine();
  };

  useEffect(() => {
    if (selectTarget !== undefined && selectTarget.length > 0) {
      const tmpList = selectTarget.map((item: any, idx: number) => {
        const newData: any = {
          magazineId: item.contentId,
          title: item.title,
          menuPath: item.menuPath.join('>'),
          seq: idx,
          subType: item.subType,
          image: item.image,
        };
        return newData;
      });
      console.log('selectTarget :', selectTarget);
      const newList =
        programMagazineList !== null && programMagazineList.length > 0
          ? [...programMagazineList, ...tmpList]
          : [...tmpList];
      setProgramMagazineList(newList);
    }
  }, [selectTarget]);

  useEffect(() => {
    if (open) {
      setCheckedAll(false);
      setCheckedButtons([]);
      refetchProgramMagzine();
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>매거진 목록</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Box className='flex_between' sx={{ mb: '1rem' }}>
          <SelectSearchBox
            searchOption={selectVal}
            searchWord={searchRef}
            defaultOption='all'
            isSearching={isSearching}
            optionValue={selectRef}
          />
          <Box>
            <Button
              sx={{ mr: '1rem' }}
              variant='outlined'
              color='info'
              onClick={deleteMagazineList}
            >
              삭제
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => setOpenTemplate(true)}
            >
              매거진 추가
            </Button>
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                <TableCell padding='checkbox'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                </TableCell>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {programMagazineList !== undefined &&
              programMagazineList !== null &&
              programMagazineList.length > 0 ? (
                programMagazineList
                  .slice((page - 1) * rowsPerPage, page * rowsPerPage)
                  .map((row: any, idx: number) => (
                    <TableRow key={`magazine_${idx}`}>
                      <TableCell padding='checkbox' sx={{ width: '2%' }}>
                        <Checkbox
                          id={row.magazineId}
                          onChange={e => {
                            changeHandler(
                              e.currentTarget.checked,
                              row.magazineId
                            );
                          }}
                          checked={checkedButtons.includes(row.magazineId)}
                        />
                      </TableCell>
                      <TableCell sx={{ p: '.3rem .6rem', width: '2%' }}>{`${
                        idx + 1 + (page - 1) * rowsPerPage
                      }`}</TableCell>
                      <TableCell sx={{ p: '.3rem .6rem', width: '20%' }}>
                        {row.menuPath}
                      </TableCell>
                      <TableCell sx={{ p: '.3rem .6rem', width: '20%' }}>
                        {row.title}
                      </TableCell>
                      <TableCell sx={{ p: '.3rem .6rem', width: '10%' }}>
                        <Button
                          disabled={idx + (page - 1) * rowsPerPage === 0}
                          variant='outlined'
                          color='info'
                          onClick={() => topMove(idx)}
                        >
                          <ExpandLessOutlinedIcon />
                        </Button>
                        <Button
                          disabled={
                            idx + (page - 1) * rowsPerPage ===
                            programMagazineList.length - 1
                          }
                          variant='outlined'
                          color='info'
                          onClick={() => bottomMove(idx)}
                        >
                          <ExpandMoreOutlinedIcon />
                        </Button>
                      </TableCell>
                      <TableCell sx={{ p: '.3rem .6rem', width: '10%' }}>
                        <Button
                          variant='outlined'
                          color='info'
                          onClick={() => detailView(row.image)}
                        >
                          상세보기
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>검색결과 없습니다.</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {programMagazineList !== undefined &&
            programMagazineList !== null &&
            programMagazineList.length > 0 && (
              <SwPagination
                page={page}
                handleChangePage={handleChangePage}
                count={Math.ceil(programMagazineList.length / rowsPerPage)}
              />
            )}
        </TableContainer>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <DialogActions sx={{ p: '1rem' }}>
        <Button variant='outlined' color='info' onClick={onClose}>
          취소
        </Button>
        <Button
          variant='contained'
          color='info'
          sx={{ fontSize: '.87rem' }}
          onClick={saveMagazineList}
        >
          저장
        </Button>
      </DialogActions>
      <SwSelectTemplate
        categoryType={2}
        open={openTemplate}
        onClose={() => {
          setOpenTemplate(false);
        }}
        setTarget={setSelectTarget}
      />
      <SwImageMultipleView
        images={imageList}
        open={openDetailView}
        onClose={() => {
          setOpenDetailView(false);
        }}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={snackbarMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </Dialog>
  );
};
