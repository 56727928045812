import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  MobileTimePicker,
  PickersDay,
  TimePicker,
} from '@mui/x-date-pickers';
import moment from 'moment';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SearchIcon from '@mui/icons-material/Search';
import koLocale from 'date-fns/locale/ko';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { MultilineTextField } from '../../styles/Styles';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { ITimeline, DefaultTimeline } from '../../../models/timeline';
import { IUploadFile } from '../../../models/common';
import { postTimeline } from '../../../common/api/ApiTimeline';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { RESULT_OK } from '../../../common/resultCode';
import {
  checkImageFileName,
  getAddUrl,
  getHealthItems,
} from '../../../common/helper';

interface Props {}

const ContentBox = styled(Box)({
  margin: '1rem',
  // padding: '.8rem 1rem 0',
});

const ReceiverChip = styled(Chip)({
  border: 'none',
  '& .MuiChip-deleteIcon': {
    fontSize: '.9rem',
    marginTop: '.1rem',
  },
});

const LinkerChip = styled(Chip)({
  border: 'none',
  '& .MuiChip-deleteIcon': {
    fontSize: '.9rem',
    marginTop: '.1rem',
  },
});

const feeds = [
  { title: '기본', value: 'basic' },
  { title: '유튜브', value: 'youtube' },
];

const SwReceivers = [
  { title: '선택', value: 'empty' },
  { title: '전체', value: 'all' },
  { title: '공식 커뮤니티', value: 'community' },
  { title: '챌린지', value: 'challenge' },
  { title: '리워드', value: 'reward' },
  { title: '개인', value: 'user' },
  { title: '건강정보', value: 'health' },
];

const SwLinkers = [
  { title: '없음', value: 'empty' },
  { title: '프로필 관리', value: 'profile' },
  { title: '챌린지', value: 'challenge' },
  { title: '챌린지 목록', value: 'challengeList' },
  { title: '공식커뮤니티', value: 'community' },
  { title: '커뮤니티 목록', value: 'communityList' },
  { title: '질의응답', value: 'questionanswer' },
  { title: '보관함', value: 'box' },
  { title: '공지사항', value: 'notice' },
  { title: '외부URL', value: 'url' },
];

export const TimelineRegist: React.FC<Props> = () => {
  const site = 'webAdmin';
  const youtubeRef = useRef<any>();
  const prefixRef = useRef<any>();
  const findReceiverRef = useRef<any>();
  const timelineLinkRef = useRef<any>();
  const [feedType, setFeedType] = useState<string>('basic');
  const [receiverClassification, setReceiverClassification] =
    useState<string>('empty');
  const [receivers, setReceivers] = useState<any[]>([]);
  const [linkers, setLinkers] = useState<any[]>([]);
  const [timelineImage, setTimelineImage] = useState<string>('');
  const [timelineLink, setTimelineLink] = useState<any>('empty');
  const [timelineDates, setTimelineDates] = useState<any[]>([]);
  const [timelineFile, setTimelineFile] = useState<any>(null);
  const [timelineRangeDate, setTimelineRangeDate] = useState<any[]>([
    null,
    null,
  ]);
  const [healthItem, setHealthItem] = useState<string>('1');
  const [rangeDateTime, setRangeDateTime] = useState<any>(new Date());
  const [sendDates, setSendDates] = useState<any[]>([]);
  const fileUpload = document.getElementById('fileUpload');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [openType, setOpenType] = useState<number>(0);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [snackbarContents, setSnackbarContents] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [title, setTitle] = useState<string[]>([]);
  const [prefixText, setPrefixText] = useState<string>('');
  const [contentText, setContentText] = useState<string>('');
  const [receiverName, setReceiverName] = useState<string>('');
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [ctrType, setCtrType] = useState<string>('no');

  const onChangeAlertOpen = () => setAlertOpen(!alertOpen);

  const { mutate: postTimelineMutate, isError: isPushMutateError } =
    useMutation(postTimeline, {
      onSuccess: res => {
        setOpenMore(0);
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setSnackbarContents('타임라인을 등록했습니다.');
        } else {
          setAlertMessage(
            `타임라인 등록이 실패했습니다.(${res.resultCodeMsg})`
          );
          setAlertOpen(true);
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setSnackbarContents(
          '타임라인 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const registerTimeline = (newUploadUrl: any) => {
    const id = receivers.length > 0 ? receivers[0].id : '';
    const name = receivers.length > 0 ? receivers[0].name : '';
    const targetType = receiverClassification;
    const newTimelineRangeDate =
      timelineRangeDate[0] === null || timelineRangeDate[1] === null
        ? null
        : timelineRangeDate.map(rangeDate => rangeDate.getTime() / 1000);

    const linkData =
      timelineLink === 'challenge' ||
      timelineLink === 'community' ||
      timelineLink === 'questionanswer'
        ? linkers[0].id
        : timelineLink === 'url'
        ? timelineLinkRef.current?.value
        : '';

    const linkDataName =
      timelineLink === 'challenge' ||
      timelineLink === 'community' ||
      timelineLink === 'questionanswer'
        ? linkers[0].name
        : '';

    const newDates = timelineDates.map(
      timelineDate => timelineDate.getTime() / 1000
    );

    var extra = {};
    var action = 0;
    switch (timelineLink) {
      case 'empty':
        action = 0;
        break;
      case 'profile':
        action = 2;
        break;
      case 'challenge':
        action = 3;
        extra = {
          v: linkData,
          n: linkDataName,
        };
        break;
      case 'challengeList':
        action = 4;
        break;
      case 'community':
        action = 5;
        extra = {
          v: linkData,
          n: linkDataName,
        };
        break;
      case 'communityList':
        action = 6;
        break;
      case 'box':
        action = 7;
        break;
      case 'notice':
        action = 8;
        break;
      case 'url':
        action = 9;
        extra = {
          url: getAddUrl(linkData),
          n: linkDataName,
        };
        break;
      case 'questionanswer':
        action = 11;
        extra = {
          v: linkData,
          n: linkDataName,
        };
        break;
      default:
        action = 1;
    }
    console.log('reg loadurl:', newUploadUrl);
    var imageSize = { width: 0, height: 0 };
    if (
      newUploadUrl !== null &&
      newUploadUrl !== undefined &&
      newUploadUrl?.file.height !== 0 &&
      newUploadUrl?.file.width !== 0
    )
      imageSize = {
        width: newUploadUrl.file.width,
        height: newUploadUrl.file.height,
      };

    var data = {};
    if (feedType === 'basic') {
      data = {
        timestamp: prefixText,
        text: contentText,
        imageUrl: newUploadUrl !== null ? newUploadUrl.file.path : '',
        action: action,
        extra: extra,
        imgSize: imageSize,
        ctr: ctrType,
        name: name,
      };
    } else {
      // youtube
      data = {
        timestamp: prefixText,
        text: contentText,
        v: youtubeRef.current?.value,
        thumb: newUploadUrl !== null ? newUploadUrl.file.path : '',
        action: 0,
        extra: {},
        ctr: ctrType,
        name: name,
      };
    }

    const newTimeline: ITimeline = {
      feedType: feedType === 'basic' ? 7 : 8,
      receiver: {
        type: targetType,
        id: id,
        name: '',
        healthItem: Number(healthItem),
      },
      content: JSON.stringify(data),
      site: site,
      requestDate: newDates,
      repeat: {
        startDate: newTimelineRangeDate === null ? 0 : newTimelineRangeDate[0],
        endDate: newTimelineRangeDate === null ? 0 : newTimelineRangeDate[1],
      },
    };
    console.log('postTimelineMutate :', newTimeline);
    postTimelineMutate(newTimeline);
  };

  const { mutate: uploadFileMutate, isError: isMutateError } = useMutation(
    postUploadFile,
    {
      onSuccess: res => {
        console.log('uploadFile:', res);
        setUploadUrl(res.uploadUrl);
        console.log('uploadFile uploadUrl:', uploadUrl);
        registerTimeline(res.uploadUrl);
      },
      onError: error => {
        setOpenSnackbar(true);
        setSnackbarContents(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    }
  );

  const onChangePrefixText = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    if (newValue.length > 60) {
      setOpenSnackbar(true);
      setSnackbarContents('60자까지만 입력이 가능합니다.');
      return;
    }
    setPrefixText(newValue);
  };

  const onChangeContentText = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    const splitLineCount = newValue.split(/\n/).length;
    if (newValue.length > 800) {
      setOpenSnackbar(true);
      setSnackbarContents('800자까지만 입력이 가능합니다.');
      return;
    }
    setContentText(newValue);
  };

  const checkTimeline = () => {
    if (timelineFile === null) {
      registerTimeline(null);
    } else {
      const uploadfile: IUploadFile = {
        type: 'timeline',
        file: timelineFile,
      };
      uploadFileMutate(uploadfile);
    }
  };

  const checkSendTimeline = () => {
    const newTimelineRangeDate =
      timelineRangeDate[0] === null || timelineRangeDate[1] === null
        ? null
        : timelineRangeDate.map(rangeDate => rangeDate.getTime() / 1000);
    var isValid = 0;

    if (
      (receiverClassification === 'challenge' ||
        receiverClassification === 'community' ||
        receiverClassification === 'reward' ||
        receiverClassification === 'user') &&
      receivers.length === 0
    ) {
      isValid = 1;
    }

    if (receiverClassification === 'empty') {
      isValid = 1;
    }

    if (isValid === 0 && contentText.length === 0) {
      isValid = 2;
    }

    if (
      isValid === 0 &&
      (timelineLink === 'challenge' || timelineLink === 'community') &&
      linkers.length === 0
    ) {
      isValid = 3;
    }

    console.log(
      'timelineLinkRef.current?.value:',
      timelineLinkRef.current?.value
    );
    if (
      isValid === 0 &&
      timelineLink === 'url' &&
      timelineLinkRef.current?.value === ''
    ) {
      isValid = 3;
    }

    if (
      isValid === 0 &&
      timelineDates.length === 0 &&
      (timelineRangeDate[0] === null || timelineRangeDate[1] === null)
    ) {
      isValid = 4;
    }

    if (isValid === 0) {
      const newSendDates = timelineDates.map(timelineDate =>
        moment(timelineDate).format('YYYY.MM.DD HH:mm:ss')
      );
      const repeat = [
        ...newSendDates,
        timelineRangeDate[0] !== null &&
          timelineRangeDate[1] !== null &&
          timelineRangeDate.map(q => q.toLocaleString()).join(' ~ '),
      ];
      setSendDates(repeat);
      setReceiverName(receivers.length > 0 ? receivers[0].name : '전체');

      setOpenMore(2);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '받는 사람을 선택해주세요.';
          break;
        case 2:
          message = '내용을 입력해 주세요.';
          break;
        case 3:
          message = '이동 링크를 선택해주세요.';
          break;
        case 4:
          message = '적용 일시를 선택해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }
  };

  const confirmSendTimeline = () => {
    if (receiverClassification === 'all') {
      setOpenConfirm(true);
    } else {
      checkSendTimeline();
    }
  };

  const changeReceiverClassification = (value: any) => {
    if (findReceiverRef.current) findReceiverRef.current.value = '';
    setReceivers([]);
    setReceiverClassification(value);
  };

  useEffect(() => {
    if (timelineFile !== null) {
      if (checkImageFileName(timelineFile.name) === false) {
        setTimelineImage('');
        setTimelineFile(null);

        setAlertMessage('업로드가 불가한 파일입니다.');
        setAlertOpen(true);
      }
    }
  }, [timelineImage]);

  useEffect(() => {
    const newSearchResult = [...searchResult];
    if (openType === 0) {
      setReceivers(newSearchResult);
    } else if (openType === 1) {
      setLinkers(newSearchResult);
    }
    return () => {};
  }, [searchResult]);

  return (
    <>
      <Paper sx={{ m: '2rem 0', p: '1rem' }}>
        <ContentBox>
          <Typography className='title'>피드 종류</Typography>
          <TextField
            select
            sx={{ width: '15em' }}
            defaultValue='basic'
            value={feedType}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setFeedType(event.target.value)}
          >
            {feeds.map((feed: any, idx: number) => (
              <MenuItem value={feed.value} key={idx.toString()}>
                {feed.title}
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </ContentBox>
        <ContentBox>
          <Typography className='title'>받는 사람</Typography>
          <div style={{ display: 'flex', marginTop: '.8rem' }}>
            <TextField
              select
              sx={{ width: '12rem', mr: '1rem' }}
              defaultValue='empty'
              value={receiverClassification}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => changeReceiverClassification(event.target.value)}
            >
              {SwReceivers.map((receiver: any, idx: number) => (
                <MenuItem value={receiver.value} key={idx.toString()}>
                  {receiver.title}
                </MenuItem>
              ))}
            </TextField>
            <div>
              {receiverClassification === 'challenge' ||
              receiverClassification === 'community' ||
              receiverClassification === 'reward' ||
              receiverClassification === 'user' ? (
                <TextField
                  placeholder={`${
                    receiverClassification === 'challenge'
                      ? '챌린지 이름'
                      : receiverClassification === 'community'
                      ? '커뮤니티 이름'
                      : receiverClassification === 'reward'
                      ? '리워드 이름'
                      : '닉네임, 연락처'
                  }, 아이디 검색`}
                  inputRef={findReceiverRef}
                  sx={{ width: '44rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() => {
                            const selected = SwReceivers.map(item => {
                              if (item.value === receiverClassification)
                                return item.title;
                              return '';
                            }).filter(value => value !== '');
                            setTitle(selected);
                            setSearchKey(receiverClassification);
                            setSearchWord(findReceiverRef.current?.value);
                            setOpenType(0);
                            setOpenSearchResult(true);
                          }}
                          sx={{ pr: 0 }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : receiverClassification === 'health' ? (
                <TextField
                  select
                  defaultValue='1'
                  value={healthItem}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setHealthItem(event.target.value)}
                  sx={{ width: '12rem', mr: '1rem' }}
                >
                  {getHealthItems().map((q, idx) => (
                    <MenuItem value={q.value} key={idx.toString()}>
                      {q.title}
                    </MenuItem>
                  ))}
                </TextField>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Grid container sx={{ mt: '.5rem', width: '120rem ' }}>
            {receivers.length > 0 &&
              receivers.map((receive: any, idx: number) => (
                <Grid item xs={5} key={idx.toString()}>
                  <ReceiverChip
                    variant='outlined'
                    label={
                      <>
                        <span>{receive.name}&nbsp;&nbsp;</span>
                        <span className='bluecontent'>{receive.id}</span>
                      </>
                    }
                    onDelete={() => {
                      const newReceiver = receivers.filter(
                        (r: string, i: number) => i !== idx
                      );
                      setReceivers(newReceiver);
                    }}
                  />
                </Grid>
              ))}
          </Grid>
          <Divider />
        </ContentBox>
        <ContentBox>
          <Typography className='title'>말머리(선택)</Typography>
          <TextField
            fullWidth
            inputRef={prefixRef}
            value={prefixText}
            onChange={onChangePrefixText}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>({prefixText.length}/60)</Typography>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </ContentBox>
        <ContentBox>
          <Typography className='title'>내용</Typography>
          <MultilineTextField
            fullWidth
            multiline
            rows={5}
            value={contentText}
            onChange={onChangeContentText}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>({contentText.length}/800)</Typography>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </ContentBox>
        <ContentBox>
          <Typography className='title'>
            {feedType === 'basic' ? '이미지(선택)' : '미리보기(썸네일)'}
          </Typography>
          <SwFileSetting
            setImage={setTimelineImage}
            image={timelineImage}
            setFile={setTimelineFile}
            file={timelineFile}
          />
          <Divider />
        </ContentBox>
        {feedType === 'youtube' && (
          <ContentBox>
            <Typography className='title'>유튜브 Key</Typography>
            <Typography className='title'>
              유튜브 링크의 v=XXXXXXXXXX 값을 입력해 주세요.
            </Typography>
            <TextField
              placeholder='SMemB8quejk'
              inputRef={youtubeRef}
              sx={{ width: '20rem' }}
            />
            <Divider />
          </ContentBox>
        )}
        {feedType === 'basic' && (
          <ContentBox>
            <Typography className='title'>링크 이동</Typography>
            <Typography className='title'>
              타임라인 클릭 시 이동할 곳을 골라주세요.
            </Typography>
            <div>
              <TextField
                select
                defaultValue='empty'
                value={timelineLink}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  setTimelineLink(event.target.value);
                  setLinkers([]);
                }}
                sx={{ width: '12rem', mr: '1rem' }}
              >
                {SwLinkers.map((q, idx) => (
                  <MenuItem value={q.value} key={idx.toString()}>
                    {q.title}
                  </MenuItem>
                ))}
              </TextField>
              {(timelineLink === 'challenge' ||
                timelineLink === 'community' ||
                timelineLink === 'questionanswer' ||
                timelineLink === 'url') && (
                <TextField
                  placeholder={`${
                    timelineLink === 'challenge'
                      ? '챌린지'
                      : timelineLink === 'community'
                      ? '커뮤니티'
                      : timelineLink === 'questionanswer'
                      ? '질의응답'
                      : 'url 입력'
                  } ${timelineLink !== 'url' ? '이름, 아이디 검색' : ''}`}
                  sx={{ width: '44rem' }}
                  inputRef={timelineLinkRef}
                  InputProps={{
                    endAdornment: timelineLink !== 'url' && (
                      <InputAdornment position='end'>
                        <IconButton
                          sx={{ pr: 0 }}
                          onClick={() => {
                            const selected = SwLinkers.map(item => {
                              if (item.value === timelineLink)
                                return item.title;
                              return '';
                            }).filter(value => value !== '');
                            console.log('title :', selected);
                            setTitle(selected);
                            setSearchKey(timelineLink);
                            setSearchWord(timelineLinkRef.current?.value);
                            setOpenType(1);
                            setOpenSearchResult(true);
                          }}
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </div>
            <Grid container sx={{ mt: '.5rem', width: '120rem ' }}>
              {linkers.length > 0 &&
                linkers.map((linker: any, idx: number) => (
                  <Grid item xs={5} key={idx.toString()}>
                    <LinkerChip
                      variant='outlined'
                      label={
                        <>
                          <span>{linker.name}&nbsp;&nbsp;</span>
                          <span className='bluecontent'>{linker.id}</span>
                        </>
                      }
                      onDelete={() => {
                        const newLinkers = linkers.filter(
                          (r: string, i: number) => i !== idx
                        );
                        setLinkers(newLinkers);
                      }}
                    />
                  </Grid>
                ))}
            </Grid>
            <Divider />
          </ContentBox>
        )}
        <ContentBox>
          <Typography className='title'>CTR(클릭률) 측정</Typography>
          <TextField
            select
            sx={{ width: '15em' }}
            defaultValue='no'
            value={ctrType}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setCtrType(event.target.value)}
          >
            <MenuItem key='yes' value='yes'>
              측정
            </MenuItem>
            <MenuItem key='no' value='no'>
              사용안함
            </MenuItem>
          </TextField>
          <Divider />
        </ContentBox>
        <ContentBox>
          <Typography className='title'>적용일시</Typography>
          <SwDatesPicker dates={timelineDates} setDates={setTimelineDates} />
          <Divider />
        </ContentBox>
        <ContentBox>
          <Typography className='title'>반복 설정</Typography>
          <SwDateRangePicker
            rangeDate={timelineRangeDate}
            setRangeDate={setTimelineRangeDate}
            rangeDateTime={rangeDateTime}
            setRangeDateTime={setRangeDateTime}
          />
          {timelineRangeDate.filter(q => q !== null).length > 1 && (
            <Chip
              label={timelineRangeDate.map(q => q.toLocaleString()).join(' ~ ')}
              variant='outlined'
              onDelete={() => setTimelineRangeDate([])}
              sx={{ m: '.5em' }}
            />
          )}
          <Divider />
        </ContentBox>
      </Paper>
      <Box
        className='flex_between'
        sx={{
          p: '0 0 5rem',
        }}
      >
        <Button
          variant='contained'
          color='secondary'
          sx={{ mr: '1rem' }}
          onClick={() => {
            window.location.reload();
          }}
        >
          신규작성
        </Button>
        <Button
          variant='contained'
          color='info'
          onClick={() => {
            confirmSendTimeline();
          }}
        >
          등록하기
        </Button>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={snackbarContents}
      />
      {openMore === 2 && (
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography
              sx={{ mb: '0.7rem' }}
            >{`'${receiverName}'에게`}</Typography>
            {sendDates.map((input, idx) => (
              <Box key={idx.toString()}>
                <Typography>{input}</Typography>
              </Box>
            ))}
            <Typography sx={{ mt: '0.7rem' }}>
              타임라인을 등록하시겠습니까?
            </Typography>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => checkTimeline()}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <SwSearchTarget
        title={title !== null ? title[0] : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwAlert
        title='타임라인 등록 오류'
        open={alertOpen}
        onConfirm={onChangeAlertOpen}
        contents={alertMessage}
      />
      <SwConfirmDialog
        contents='전체 사용자 대상으로 타임라인을 등록하시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          checkSendTimeline();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </>
  );
};
