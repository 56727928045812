/* eslint-disable global-require */
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import AddIcon from '@mui/icons-material/Add';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation } from 'react-router';
import { SwSwitch } from '../../styles/Styles';
import { SwLoadGPX } from '../../commonComponent/SwLoadGPX';
import { WalkcourseSpotInfo } from '../walkcourse/WalkcourseSpotInfo';
import { ChallengeFieldCharacterInfo } from './ChallengeFieldCharacterInfo';
import {
  deleteAllFieldCharacter,
  deleteFieldCharacter,
  deleteFieldGeometry,
  getFieldCharacterList,
  getFieldGeometryList,
  getFieldSpotList,
  registerFieldCharacter,
  registerFieldGeometry,
  registerFieldSpot,
  updateChallengeFieldGeometryHiddenCharacter,
  updateFieldCharacterLocation,
} from '../../../common/api/ApiField';
import {
  KEY_FIELD_FIXED_CHARACTER_LIST,
  KEY_FIELD_GEOMETRY_LIST,
  KEY_FIELD_SPOT_LIST,
} from '../../../common/key';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { ChallengeFieldSpotInfo } from './ChallengeFieldSpotInfo';
import { selectedFieldIdState } from '../../../common/atom';
import {
  addDistanceToCoordinates,
  FIXED_CHARACTER,
  generateUuidId,
  getGeometryObjectCenterLocation,
  isPointInPolygon,
  squareMetersToSquareKilometers,
} from '../../../common/helper';
import { useSession } from '../../../hooks/session';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';

const POLYGON_TYPE = 'polygon';
const CIRCLE_TYPE = 'circle';
const FILL_COLOR = '#00a0e9';
const LINE_COLOR = '#00a0e9';
const MAX_GEOMETRY_COUNT = 1000;
const MAX_CHARACTER_COUNT = 1000;

const TextBox = styled(Box)({
  width: '6vw',
  padding: '.2rem .4rem',
  maxWidth: '6rem',
  minWidth: '5.9rem',
  marginLeft: '.3rem',
});

const OverlayView = styled(Box)({
  position: 'absolute',
  zIndex: 100,
  padding: '.5rem',
  margin: '1rem',
  height: '8%',
  display: 'flex',
  justifyContent: 'space-between',
  width: '98%',
});

const OverlayViewDivider = styled(Divider)({
  margin: 0,
  padding: 0,
});

const RadiusInput = styled(TextField)({
  minWidth: '6.5rem',
  maxWidth: '7.0rem',
  backgroundColor: '#FFFFFF',
  '& .MuiOutlinedInput-root': {
    height: '2.4rem',
    borderRadius: '4px',
    paddingRight: 10,
    paddingLeft: 10,
  },
  '& .MuiOutlinedInput-input': {
    padding: 0.1,
  },
});

const SwitchPaper = styled(Paper)({
  // display: 'flex',
  zIndex: 10,
  padding: '.5rem',
  margin: '1rem .5rem',
  minWidth: '6rem',
});

const ContentsPaper = styled(Paper)({
  width: '29%',
  height: '70vh',
  borderRadius: '12px',
  marginLeft: '1%',
});

const ButtonPaper = styled(Paper)({
  borderRadius: '12px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: '10rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.1rem',
  cursor: 'grab',
  boxShadow: 'none',
});

interface Props {
  fieldData?: any;
  mapInfo?: any;
  setMapInfo?: any;
  elementId?: string;
  disabled?: boolean;
  reloadField: (id: string) => void;
}

export const ChallengeFieldMap: React.FC<Props> = ({
  fieldData = null,
  mapInfo = null,
  setMapInfo = null,
  elementId = 'map',
  disabled = false,
  reloadField,
}) => {
  const { loginVal } = useSession();

  const selectedFieldId = useRecoilValue(selectedFieldIdState);

  const map = useRef<any>();
  const maps = document.getElementById(elementId);
  const location = useLocation();
  const drawRef = useRef<any>();
  const drawTypeRef = useRef<any>();
  const geometryIndexRef = useRef<number>(-1);
  const characterIndexRef = useRef<number>(-1);
  const drawingPointsRef = useRef<any>(null);
  const drawingCharacterRef = useRef<any>(null);
  const drawingCharacterTypeRef = useRef<any>(null);
  const tempCircleOpacityRef = useRef<any>(0.6);
  const tempPolylineRef = useRef<any>();
  const tempCircleInfoRef = useRef<any>();
  const tempCourseRef = useRef<any>();
  const tempMarkerRef = useRef<any>();
  const centerRef = useRef<any>();
  const radiusRef = useRef<any>();
  const reloadRef = useRef<any>(null);
  const addPointRef = useRef<any>(null);
  const changePointRef = useRef<any>(null);
  const tempPointsRef = useRef<any>([]);
  const stampCircleRef = useRef<any>(150);
  const rightClickRef = useRef<any>(false);
  const characterImageRef = useRef<any>(false);
  const characterImageWidthRef = useRef<any>('50');
  const characterImageHeightRef = useRef<any>('50');
  const tempCharacterListRef = useRef<any>([]);
  const tempMarkerCharacterRef = useRef<any>([]);
  const tempRef = useRef<any>([]);
  const totalAreaRef = useRef<any>(0);
  const selectedCharacterUrlRef = useRef<any>('');
  const [selectInsertCharacter, setSelectInsertCharacter] =
    useState<boolean>(false);
  const [selectInsertContent, setSelectInsertContent] =
    useState<boolean>(false);
  const [drawType, setDrawType] = useState<number>(2);
  const [circle, setCircle] = useState<any>();
  const [pointChange, setPointChange] = useState<boolean>(false);
  const [changeCharacter, setChangeCharacter] = useState<number>(0);
  const [circleStart, setCircleStart] = useState(false);
  const [drawingOpacity, setDrawingOpacity] = useState(false);
  const [mapCenter, setMapCenter] = useState<any>([37.5, 127.012]);
  const [stampCircle, setStampCircle] = useState<number>(150);
  const [courseWeight, setCourseWeight] = useState<number>(40);
  const [courseDistance, setCourseDistance] = useState<number>(0);
  const [currentDistance, setCurrentDistance] = useState<any>([]);
  const [courseTime, setCourseTime] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<any>([]);
  const [markerStart, setMarkerStart] = useState(false);
  const [markerCharacterStart, setMarkerCharacterStart] = useState(false);
  const [markerContentStart, setMarkerContentStart] = useState(false);
  const [points, setPoints] = useState<any>([]);
  const [pointsCharacter, setPointsCharacter] = useState<any>([]);
  const [pointsContent, setPointsContent] = useState<any>([]);
  const [course, setCourse] = useState<any>(null);
  const [marker, setMarker] = useState<any>([]);
  const [markerCharacter, setMarkerCharacter] = useState<any>([]);
  const [markerContent, setMarkerContent] = useState<any>([]);
  const [polyline, setPolyline] = useState<any>(null);
  const [uploadFile, setUploadFile] = useState<any>('');
  const [tempPoint, setTempPoints] = useState<any>([]);
  const [tempPointsContent, setTempPointsContent] = useState<any>([]);
  const [markerShow, setMarkerShow] = useState<boolean>(true);
  const [contentMarkerShow, setContentMarkerShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<any>(null);
  const [circleData, setCircleData] = useState<any>();
  const [openGpx, setOpenGpx] = useState<boolean>(false);
  const [gpxData, setGpxData] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [characterInfoIndex, setCharacterInfoIndex] = useState<number>(0);
  const [characterInfo, setCharacterInfo] = useState<any>();
  const [contentInfoIndex, setContentInfoIndex] = useState<number>(0);
  const [contentInfo, setContentInfo] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );
  const [openCreateCharacter, setOpenCreateCharacter] =
    useState<boolean>(false);
  const [openCreateContent, setOpenCreateContent] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openConfirm, setOpenConfirm] = useState<number>(0);
  const [showContent, setShowContent] = useState<boolean>(true);
  const [stamps, setStamps] = useState<any>([]);
  const [stampsCircle, setStampsCircle] = useState<any>([]);
  const [geometryIndex, setGeometryIndex] = useState<number>(-1);
  const [geometryObjectList, setGeometryObjectList] = useState<any[]>([
    {
      type: '', // polygon, circle
      points: [],
      cirlce: null,
      courseMap: null,
      circleMap: null,
      area: 0,
      location: [],
      marker: null,
      hiddenRandomCharacter: false,
    },
  ]);
  const [characterIndex, setCharacterIndex] = useState<number>(-1);
  const [fieldCharacterList, setFieldCharacterList] = useState<any[]>([
    {
      characterId: '',
      image: '',
      name: '',
      mileage: 0,
      bigMileage: 0,
      bigAppearNumber: 0,
      popupTitle: '',
      popupContent: '',
      popupLink: '',
      popupImage: '',
      lat: 0,
      lng: 0,
      markerMap: [],
      width: 0,
      height: 0,
    },
  ]);

  let movingPoint = false;
  let ncourse: any;
  let drawingStart = false;
  let drawingCharacterStart = false;
  let drawingContentStart = false;
  let circleDrawStart = false;
  let drawingCircle: any;
  let drawingCircleLine: any;
  let length: any;
  let drawingCircleInfo: any;
  let drawingLine: any;
  let tempCircle: any;
  let tempPosition: any;
  let tempPolyline: any;
  let currentMarker: any;
  let currentCircle: any;
  let centerPosition: any;
  let mousePosition: any;
  const pointMouseOverInfo = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >지도 위에 원하는 지점을\n 연속적으로 클릭하여\n다각형 영역을 만들어보세요.</div>`;
  const characterMouseOverInfo = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >지도의 캐릭터 출현 영역 안에서\n 케릭터가 출현할 위치를\n클릭하여 지정해주세요.</div>`;
  const spotMouseOverInfo = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >영역 근처 원하는 지점을 클릭하여\n 소개하고 싶은 장소 콘텐츠를\n추가해보세요.</div>`;
  const changePointContent = `<div  class="info" style="padding:5px;margin:10px; background-color:white; border:black solid 1px; white-space: pre;" >이동하실 위치를 선택해주세요! :)</div>`;
  const mouseMoveCustomOverlay = new naver.maps.InfoWindow({
    content: pointMouseOverInfo,
    borderWidth: 0,
    disableAnchor: true,
    backgroundColor: 'transparent',
    pixelOffset: new naver.maps.Point(0, -28),
  });

  const changeMapCenter = (lat: number, lng: number) => {
    lat && lng && map.current.setCenter(new naver.maps.LatLng(lat, lng));
  };

  const setupGeometryList = (newGeometryList: any[]) => {
    const newObjectList = geometryObjectList;
    newGeometryList.map((geometry: any, idx: number) => {
      if (geometry.type === 1) {
        const tmpPoint =
          geometry.path !== null && geometry.path.length > 0
            ? JSON.parse(geometry.path)
            : [];
        const newPoints = tmpPoint.map((item: any) => {
          const tmp: any = { lat: item[0], lng: item[1] };
          return tmp;
        });

        const tempPolygon = new naver.maps.Polygon({
          paths: newPoints.map(
            (point: any) => new naver.maps.LatLng(point.lat, point.lng)
          ),
          fillColor: FILL_COLOR,
          fillOpacity: 0.1,
          strokeWeight: 5,
          strokeColor: LINE_COLOR,
          strokeOpacity: 1,
          strokeStyle: 'solid',
          strokeLineCap: 'round',
          strokeLineJoin: 'round',
        });
        tempPolygon.setMap(map.current);

        const centerLoc: any = getGeometryObjectCenterLocation(newPoints);
        const tempMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(centerLoc.lat, centerLoc.lng),
          map: map.current,
          icon: {
            content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
            anchor: new naver.maps.Point(18, 38),
          },
          clickable: false,
          draggable: false,
        });
        tempMarker.setMap(map.current);

        newObjectList[idx].geometryId = geometry.geometryId;
        newObjectList[idx].points = newPoints;
        newObjectList[idx].courseMap = tempPolygon;
        newObjectList[idx].type = POLYGON_TYPE;
        newObjectList[idx].area = tempPolygon.getAreaSize();
        newObjectList[idx].marker = tempMarker;
        newObjectList[idx].hiddenRandomCharacter =
          geometry.hiddenRandomCharacter === 1;
      } else if (geometry.type === 2) {
        const newCircle = new naver.maps.Circle({
          map: map.current,
          center: new naver.maps.LatLng(geometry.lat || 0, geometry.lng || 0),
          radius: geometry.radius || 0,
          fillColor: FILL_COLOR,
          fillOpacity: 0.1,
          strokeWeight: 5,
          strokeColor: LINE_COLOR,
          strokeStyle: 'solid',
          strokeOpacity: 1,
        });

        const newCircleData = {
          center: new naver.maps.LatLng(geometry.lat || 0, geometry.lng || 0),
          radius: geometry.radius || 0,
        };
        newCircle.setMap(map.current);

        const tempMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(geometry.lat || 0, geometry.lng || 0),
          map: map.current,
          icon: {
            content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
            anchor: new naver.maps.Point(18, 38),
          },
          clickable: false,
          draggable: false,
        });
        tempMarker.setMap(map.current);

        newObjectList[idx].geometryId = geometry.geometryId;
        newObjectList[idx].circle = newCircleData;
        newObjectList[idx].circleMap = newCircle;
        newObjectList[idx].type = CIRCLE_TYPE;
        newObjectList[idx].area = newCircle.getAreaSize();
        newObjectList[idx].marker = tempMarker;
        newObjectList[idx].hiddenRandomCharacter =
          geometry.hiddenRandomCharacter === 1;
      }
    });
    const newIndex =
      newGeometryList.length > 0 ? newGeometryList.length - 1 : -1;
    geometryIndexRef.current = newIndex;
    setGeometryIndex(newIndex);
    setGeometryObjectList(newObjectList);

    if (newObjectList.length > 0) {
      const lng =
        newObjectList[0].type === CIRCLE_TYPE
          ? newObjectList[0].circle.center.x
          : newObjectList[0].points[0]?.lng;
      const lat =
        newObjectList[0].type === CIRCLE_TYPE
          ? newObjectList[0].circle.center.y
          : newObjectList[0].points[0]?.lat;
      changeMapCenter(lat, lng);
    }
  };

  const resetContentData = () => {
    markerContent?.map((m: any) => m?.setMap(null));
  };

  const resetGeometryData = () => {
    setPoints([]);
    marker?.map((m: any) => m?.setMap(null));
    course?.setMap(null);
    circle?.setMap(null);
    setMarker([]);
    stampsCircle?.map((stamp: any) => stamp?.setMap(null));

    setGeometryIndex(-1);
    geometryIndexRef.current = -1;
    geometryObjectList.map((object: any) => {
      object.courseMap?.setMap !== undefined &&
        object.courseMap?.setMap !== null &&
        object.courseMap?.setMap(null);
      object.circleMap?.setMap !== undefined &&
        object.circleMap?.setMap !== null &&
        object.circleMap?.setMap(null);
      object.marker?.setMap !== undefined &&
        object.marker?.setMap !== null &&
        object.marker?.setMap(null);
      object.type = '';
      object.points = [];
      object.courseMap = null;
      object.circleMap = null;
      object.marker = null;
      object.hiddenRandomCharacter = false;
    });
  };

  const resetMarkerCharacter = () => {
    markerCharacter?.map((m: any) => m?.setMap(null));
    setMarkerCharacter([]);
  };

  const deleteMarkerCharacter = (idx: number) => {
    const newCharacterIndex = characterIndexRef.current;
    const tmpList = tempCharacterListRef.current;

    const newLocation =
      tmpList[newCharacterIndex].location !== undefined &&
      tmpList[newCharacterIndex].location !== null &&
      tmpList[newCharacterIndex].location.length > 0
        ? tmpList[newCharacterIndex].location
        : [];

    const tmpCharacterList = tempCharacterListRef.current;
    const newTmpMaker = tmpCharacterList[newCharacterIndex].markerMap;
    newTmpMaker.map((m: any) => m?.setMap(null));
    markerCharacter.map((m: any) => m?.setMap(null));
    if (newLocation !== null && newLocation.length > 0) {
      const tmpLocaiton = newLocation.filter(
        (newData: any, i: number) => i !== idx
      );
      const newMarkers = tmpLocaiton.map((loc: any, i: number) => {
        const tmpMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(loc[0], loc[1]),
          map: map.current,
          icon: {
            content: `<img src="${characterImageRef.current}" width="${characterImageWidthRef.current}" height="${characterImageHeightRef.current}" alt="현재 위치"/>`,
            anchor: new naver.maps.Point(13, 33),
          },
          clickable: true,
          draggable: false,
        });
        naver.maps.Event.addListener(tmpMarker, 'click', () => {
          console.log('--> selected character index :', newCharacterIndex);
          console.log('--> marker clicked :', i);
          if (drawingCharacterTypeRef.current === 2) deleteMarkerCharacter(i);
        });
        return tmpMarker;
      });
      newMarkers?.map((m: any) => m?.setMap(map.current));
      tmpCharacterList[newCharacterIndex].markerMap = newMarkers;
      tmpCharacterList[newCharacterIndex].location = tmpLocaiton;
      setMarkerCharacter(newMarkers);
    }
  };

  const setupMarkerCharacter = (idx: number) => {
    console.log('--> setupMarkerCharacter idx:', idx);
    resetMarkerCharacter();
    characterIndexRef.current = idx;
    const preCharacterInfoIndex = characterInfoIndex;
    const newMarkerMap = fieldCharacterList[preCharacterInfoIndex].markerMap;
    newMarkerMap.map((m: any) => m?.setMap(null));

    const newLocation = fieldCharacterList[idx].location;
    const characterImage =
      fieldCharacterList[idx].image !== undefined &&
      fieldCharacterList[idx].image !== null &&
      fieldCharacterList[idx].image.length > 0
        ? `${imageUrlPrefix}${fieldCharacterList[idx].image}`
        : '';
    characterImageRef.current = characterImage;

    characterImageWidthRef.current = '50';
    characterImageHeightRef.current = '50';
    // if (characterImage !== '') {
    //   const tmp1 = fieldCharacterList[idx].image.split('_')[1];
    //   const tmp2 = tmp1.split('.')[0];
    //   const sizeStr = tmp2.split('x');
    //   characterImageWidthRef.current = sizeStr[0] !== null ? sizeStr[0] : '100';
    //   characterImageHeightRef.current =
    //     sizeStr[1] !== null ? sizeStr[1] : '100';
    // }

    if (newLocation !== null && newLocation.length > 0) {
      const newMarkers = newLocation.map((loc: any, i: number) => {
        const tmpMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(loc[0], loc[1]),
          map: map.current,
          icon: {
            content: `<img src="${characterImage}" width="${characterImageWidthRef.current}" height="${characterImageHeightRef.current}" alt="현재 위치"/>`,
            anchor: new naver.maps.Point(13, 33),
          },
          clickable: true,
          draggable: false,
        });
        naver.maps.Event.addListener(tmpMarker, 'click', () => {
          console.log('--> selected character index :', idx);
          console.log('--> marker clicked :', i);
          if (drawingCharacterTypeRef.current === 2) deleteMarkerCharacter(i);
        });
        return tmpMarker;
      });
      newMarkers?.map((m: any) => m?.setMap(map.current));
      setMarkerCharacter(newMarkers);
      tempMarkerCharacterRef.current = newMarkers;
      fieldCharacterList[idx].markerMap = newMarkers;
    }

    setCharacterInfoIndex(idx);
  };

  const setupCharacterList = (newCharacterList: any[]) => {
    const newObjectList = fieldCharacterList;
    newObjectList.map((character: any, idx: number) => {
      character.characterId = generateUuidId();
      character.lat = 0;
      character.lng = 0;
      character.markerMap = [];
      character.width = 0;
      character.height = 0;
      character.location = '';
      character.image = '';
      character.name = '';
      character.mileage = 0;
      character.bigMileage = 0;
      character.bigAppearNumber = 0;
      character.difficulty = 1;
      character.basicRadius = 20;
      character.bigRadius - 20;
      character.popupTitle = '';
      character.popupContent = '';
      character.popupLink = '';
      character.popupImage = '';
    });

    newCharacterList.map((character: any, idx: number) => {
      const newLoc =
        character.location !== undefined &&
        character.location !== null &&
        character.location.length > 0
          ? JSON.parse(character.location)
          : [];
      newObjectList[idx].characterId = character.characterId;
      newObjectList[idx].location = newLoc;
      newObjectList[idx].image = character.image;
      newObjectList[idx].name = character.name;
      newObjectList[idx].mileage = character.mileage;
      newObjectList[idx].bigMileage = character.bigMileage;
      newObjectList[idx].bigAppearNumber = character.bigAppearNumber;
      newObjectList[idx].difficulty = character.difficulty;
      newObjectList[idx].basicRadius = character.basicRadius;
      newObjectList[idx].bigRadius = character.bigRadius;
      newObjectList[idx].popupTitle = character.popupTitle;
      newObjectList[idx].popupContent = character.popupContent;
      newObjectList[idx].popupLink = character.popupLink;
      newObjectList[idx].popupImage = character.popupImage;
    });
    const newIndex =
      newCharacterList.length === 0
        ? -1
        : characterIndexRef.current <= newCharacterList.length - 1
        ? characterIndexRef.current
        : 0;
    characterIndexRef.current = newIndex;
    setPointsCharacter([]);
    setCharacterIndex(newIndex);
    setFieldCharacterList(newObjectList);
    tempCharacterListRef.current = newObjectList;

    setupMarkerCharacter(newIndex);
  };

  const { refetch: fieldGeometryRefetch } = useQuery(
    KEY_FIELD_GEOMETRY_LIST,
    () => {
      if (selectedFieldId !== null && selectedFieldId.length > 0)
        return getFieldGeometryList(selectedFieldId);
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.fieldGeometry) {
          setupGeometryList(res.fieldGeometry);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const refreshGeometryArea = () => {
    let newArea = 0;

    geometryObjectList.filter((item: any, idx: number) => {
      if (item.type !== '') {
        newArea += squareMetersToSquareKilometers(item.area);
      }
    });

    totalAreaRef.current = newArea;
  };

  const {
    mutate: registerChallengeFieldGeometryMutation,
    isError: isGeometryMutateError,
  } = useMutation(registerFieldGeometry, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('그리기 데이터가 등록되었습니다.');
      refreshGeometryArea();
      reloadField(selectedFieldId);
      // fieldCharacterRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '그리기 데이터 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteChallengeFieldGeometryMutation,
    isError: isGeometryDeleteMutateError,
  } = useMutation(deleteFieldGeometry, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('그리기 데이터가 삭제되었습니다.');
      refreshGeometryArea();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '그리기 데이터 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: updateChallengeFieldGeometryHiddenCharacterMutation,
    isError: isGeometryUpdateHideenCharacterMutateError,
  } = useMutation(updateChallengeFieldGeometryHiddenCharacter, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('랜덤형 캐릭터 노출정보가 수정되었습니다.');
      refreshGeometryArea();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '랜덤형 캐릭터 노출정보 수정이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const setupSpotList = (newSpotList: any[]) => {
    const newSpot = newSpotList.map((spot: any) => {
      const temp = {
        id: spot.spotId,
        name: spot.spotName,
        image: spot.spotImage,
        lat: spot.spotPointLat,
        lng: spot.spotPointLng,
        radius: spot.spotRadius,
        description: spot.description,
        className: spot.className,
        link: spot.link,
        phoneNo: spot.phoneNo,
        address: spot.address,
      };
      return temp;
    });

    setTempPointsContent(newSpot);
  };

  const { refetch: fieldSpotRefetch } = useQuery(
    KEY_FIELD_SPOT_LIST,
    () => {
      if (selectedFieldId !== null && selectedFieldId.length > 0)
        return getFieldSpotList(selectedFieldId);
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.fieldSpot) {
          setupSpotList(res.fieldSpot);
        } else {
          setPointsContent([]);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerChallengeFieldSpotMutation,
    isError: isSpoMutateError,
  } = useMutation(registerFieldSpot, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('플레이스가 등록되었습니다.');
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '플레이스 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const updateChallengeFieldSpot = (newPointsContent: any) => {
    const newSpotList = newPointsContent?.map((item: any, idx: number) => {
      const newSpot = {
        spotId: generateUuidId(),
        spotPointLat: item.lat,
        spotPointLng: item.lng,
        spotRadius: item.radius !== '' ? item.radius : 150,
        spotName: item.name,
        spotImage: item.image,
        spotSeq: idx,
        description: item.description,
        className: item.className,
        link: item.link,
        phoneNo: item.phoneNo,
        address: item.address,
        spotDeleted: 0,
      };
      return newSpot;
    });

    const newData: any = {
      fieldId: selectedFieldId,
      spots: newSpotList,
    };

    registerChallengeFieldSpotMutation(newData);
  };

  const saveCurrentGeometry = () => {
    let newCenterPointLat = 0;
    let newCenterPointLng = 0;
    let newRadius = 0;
    let newPath = [];
    const newIndex = geometryIndexRef.current;

    if (geometryObjectList[newIndex].type === POLYGON_TYPE) {
      newPath = geometryObjectList[newIndex].points.map(
        (item: any, idx: number) => [item.lat, item.lng]
      );
    } else if (geometryObjectList[newIndex].type === CIRCLE_TYPE) {
      newCenterPointLat = geometryObjectList[newIndex].circle.center.y;
      newCenterPointLng = geometryObjectList[newIndex].circle.center.x;
      newRadius = geometryObjectList[newIndex].circle.radius;
    }
    const newGeometry = {
      geometryId: geometryObjectList[newIndex].geometryId,
      type: geometryObjectList[newIndex].type === POLYGON_TYPE ? 1 : 2,
      path: JSON.stringify(newPath),
      lat: newCenterPointLat,
      lng: newCenterPointLng,
      radius: newRadius,
      area: geometryObjectList[newIndex].area,
      hiddenRandomCharacter: geometryObjectList[newIndex].hiddenRandomCharacter
        ? 1
        : 0,
    };
    const newData: any = {
      fieldId: selectedFieldId,
      geometry: newGeometry,
    };
    registerChallengeFieldGeometryMutation(newData);
  };

  const setDistance = (distance: number) => {
    const newDistance = Math.round(distance).toString();
    const contentLength = newDistance.length;
    let result: any;
    if (contentLength > 4) {
      const settingKm = Math.floor(distance / 1000);
      const settingMeter = (distance % 1000).toString().split('.')[0];
      result = `${settingKm}.${settingMeter}km`;
    } else if (contentLength === 4) {
      result = `${newDistance[0]},${newDistance.slice(1, 4)}m`;
    } else {
      result = `${newDistance}m`;
    }
    return result;
  };

  const setTime = (time: number) => {
    const newTime = Math.round(time);
    if (time / 60 >= 1) {
      const hour = Math.floor(newTime / 60);
      const minute = newTime % 60;
      return `${hour}시간${minute}분`;
    }
    if (newTime === 0) {
      const seconds = Math.round(time * 60);
      return `${seconds}초`;
    }
    return `${newTime}분`;
  };

  const initGeometryObjectList = () => {
    if (geometryObjectList !== undefined && geometryIndex !== undefined) {
      setGeometryIndex(-1);
      geometryIndexRef.current = -1;
      for (var j = 0; j < MAX_GEOMETRY_COUNT; j += 1) {
        geometryObjectList.pop();
      }
      for (var i = 0; i < MAX_GEOMETRY_COUNT; i += 1) {
        geometryObjectList.push({
          geometryId: generateUuidId(),
          type: '',
          points: [],
          circle: null,
          courseMap: null,
          circleMap: null,
          location: [],
          marker: null,
          hiddenRandomCharacter: false,
        });
      }
    }
  };

  const initCharacterObjectList = () => {
    if (fieldCharacterList !== undefined && characterIndex !== undefined) {
      setCharacterIndex(-1);
      characterIndexRef.current = -1;
      for (var j = 0; j < MAX_CHARACTER_COUNT; j += 1) {
        fieldCharacterList.pop();
      }
      for (var i = 0; i < MAX_CHARACTER_COUNT; i += 1) {
        fieldCharacterList.push({
          characterId: generateUuidId(),
          lat: 0,
          lng: 0,
          markerMap: [],
          image: '',
          width: 0,
          height: 0,
        });
      }
      tempCharacterListRef.current = fieldCharacterList;
    }
  };

  const initMap = () => {
    if (maps) {
      map.current = new naver.maps.Map(elementId, {
        center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        zoom: 15,
      });
      const checkRadius = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        radius: stampCircle,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0,
      });
      naver.maps.Event.addListener(map.current, 'zoom_changed', () => {
        checkRadius.setRadius(stampCircleRef.current);
        const newMaxBounds = checkRadius.getDrawingRect().getMax();
        const newMinBounds = checkRadius.getDrawingRect().getMin();
        const newWeight = newMaxBounds.y - newMinBounds.y;
        setCourseWeight(newWeight);
      });
    }
  };

  const addMarker = (newCenterPosition: any) => {
    const tempMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(newCenterPosition.y, newCenterPosition.x),
      map: map.current,
      icon: {
        content: `<div class='walkcourse_marker'>${
          geometryIndexRef.current + 1
        }</div>`,
        anchor: new naver.maps.Point(18, 38),
      },
      clickable: false,
      draggable: false,
    });
    tempMarker.setMap(map.current);

    const tmpIndex = geometryIndexRef.current;
    const newObjectList = geometryObjectList;
    newObjectList[tmpIndex].marker = tempMarker;
    setGeometryObjectList(newObjectList);
  };

  const changeMarker = (newCenterPosition: any) => {
    const tmpIndex = geometryIndexRef.current;
    const newObjectList: any = geometryObjectList;
    newObjectList[tmpIndex].marker?.setMap(null);

    const tempMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(
        newCenterPosition.lat,
        newCenterPosition.lng
      ),
      map: map.current,
      icon: {
        content: `<div class='walkcourse_marker'>${
          geometryIndexRef.current + 1
        }</div>`,
        anchor: new naver.maps.Point(18, 38),
      },
      clickable: false,
      draggable: false,
    });
    tempMarker.setMap(map.current);

    newObjectList[tmpIndex].marker = tempMarker;
    setGeometryObjectList(newObjectList);
  };

  const isPointInsideCircle = (point: any, center: any, radius: number) => {
    const tmpDrawingLine = new naver.maps.Polyline({
      strokeWeight: 3,
      strokeColor: LINE_COLOR,
      path: [center, point],
      map: map.current,
    });

    const distance = tmpDrawingLine?.getDistance();
    tmpDrawingLine.setMap(null);
    return distance <= radius;
  };

  const checkPointInPolygon = (coord: any) => {
    const newCoord: any = {
      lat: coord.y,
      lng: coord.x,
    };
    let isInside = false;
    geometryObjectList
      .filter((item: any, idx: number) => item.type !== '')
      .map((item: any, idx: number) => {
        if (
          item.type === POLYGON_TYPE &&
          isPointInPolygon(newCoord, item.points)
        ) {
          isInside = true;
        } else if (
          item.type === CIRCLE_TYPE &&
          isPointInsideCircle(coord, item.circle.center, item.circle.radius)
        ) {
          isInside = true;
        }
      });
    console.log('--> isInside:', isInside);
    return isInside;
  };

  // 지도 이벤트 설정
  const clickEvent = (evt: any) => {
    if (disabled) return;
    if (!drawingPointsRef.current && changePointRef.current === null) {
      if (!drawingStart) {
        setGeometryIndex &&
          setGeometryIndex((prevState: number) => prevState + 1);
        geometryIndexRef.current += 1;
        drawingStart = true;
        rightClickRef.current = false;
        centerPosition = evt.coord;
        if (drawTypeRef?.current?.value === 2) {
          centerPosition = evt.coord;
          drawingLine?.setMap(null);
          centerRef.current = evt.coord;
          drawingLine = new naver.maps.Polygon({
            fillColor: FILL_COLOR,
            fillOpacity: 0.1,
            strokeWeight: 3,
            strokeOpacity: 0.3,
            strokeColor: LINE_COLOR,
            paths: [centerPosition],
            map: map.current,
          });
          drawingLine?.setMap(map.current);
          setMarkerStart(true);

          addMarker(evt.coord);
        } else if (drawTypeRef?.current?.value === 3) {
          centerPosition = evt.coord;
          mouseMoveCustomOverlay.close();
          drawingStart = true;
          setMarkerStart(true);
          drawingLine = new naver.maps.Polyline({
            strokeWeight: 3,
            strokeColor: LINE_COLOR,
            path: [centerPosition],
            map: map.current,
          });
          tempCircle = new naver.maps.Circle({
            map: map.current,
            center: mousePosition,
            radius: stampCircleRef.current,
            fillColor: FILL_COLOR,
            fillOpacity: 0.1,
            strokeWeight: 5,
            strokeColor: LINE_COLOR,
            strokeOpacity: 1,
            strokeStyle: 'solid',
          });
          drawingLine?.setMap(map.current);
          tempCircle?.setMap(map.current);

          addMarker(evt.coord);
        }
      } else if (drawTypeRef?.current?.value === 2) {
        centerPosition = evt.coord;
        centerRef.current = evt.coord;
        setMarkerStart(false);
      }
    } else if (
      drawingPointsRef.current === 1 &&
      drawingCharacterRef.current !== null
    ) {
      if (drawingCharacterTypeRef.current === 1) {
        if (!drawingCharacterStart) {
          if (checkPointInPolygon(evt.coord)) {
            reloadRef.current?.lat
              ? (centerPosition = reloadRef.current)
              : null;
            mouseMoveCustomOverlay.close();
            drawingCharacterStart = true;
            setMarkerCharacterStart(true);
          }
        } else if (checkPointInPolygon(evt.coord)) {
          centerPosition = evt.coord;
          tempPosition = evt.coord;
          drawingLine?.setMap(null);
          centerRef.current = evt.coord;
          reloadRef.current = null;
          setMarkerCharacterStart(false);
        }
      }
    } else if (
      drawingPointsRef.current === 1 &&
      drawingCharacterRef.current === null
    ) {
      console.log(
        '--> drawingPointsRef.current === 1 & drawingCharacterRef.current === null '
      );
    } else if (
      drawingPointsRef.current === 2 &&
      changePointRef.current === null
    ) {
      if (!drawingContentStart) {
        reloadRef.current?.lat ? (centerPosition = reloadRef.current) : null;
        mouseMoveCustomOverlay.close();
        drawingContentStart = true;
        setMarkerContentStart(true);
      } else {
        centerPosition = evt.coord;
        tempPosition = evt.coord;
        drawingLine?.setMap(null);
        centerRef.current = evt.coord;
        reloadRef.current = null;
        setMarkerContentStart(false);
      }
    } else if (movingPoint) {
      // tempMarkerRef.current?.map((m: any) => m?.setMap(null));
      mouseMoveCustomOverlay.close();
      const newPoint = {
        lat: evt.coord.y,
        lng: evt.coord.x,
        radius: stampCircleRef.current,
      };
      const newPoints = [
        ...tempPointsRef.current.slice(0, changePointRef.current),
        newPoint,
        ...tempPointsRef.current.slice(
          changePointRef.current + 1,
          tempPointsRef.current.length
        ),
      ];
      tempPointsRef.current = newPoints;
      tempMarkerRef.current[changePointRef.current].setPosition(evt.coord);
      tempMarkerRef.current[changePointRef.current].setMap(map.current);
      tempCourseRef.current.setPath(tempPointsRef.current);
      tempPolylineRef.current.setPath(tempPointsRef.current);
      setPointChange(true);
      movingPoint = false;
    } else {
      if (changePointRef.current !== null)
        tempMarkerRef.current[changePointRef.current].setMap(null);
      movingPoint = true;
    }
  };
  const moveEvent = (evt: any) => {
    mousePosition = evt.coord;
    tempCircle?.setMap(null);
    if (!drawingPointsRef.current && changePointRef.current === null) {
      if (drawingStart) {
        if (drawTypeRef?.current?.value === 2) {
          setMarkerStart(true);
          const linePath = [centerPosition, mousePosition];
          drawingLine?.setPath(linePath);
          drawingLine?.setMap(map.current);
          mouseMoveCustomOverlay.setPosition(mousePosition);
          mouseMoveCustomOverlay.setMap(map.current);
        } else if (drawTypeRef?.current?.value === 3) {
          drawingLine.setPath([centerPosition, mousePosition]);
          tempCircle.setRadius(drawingLine?.getDistance());
          length = setDistance(drawingLine?.getDistance());
          drawingLine?.setMap(map.current);
          tempCircle?.setMap(map.current);

          mouseMoveCustomOverlay.setPosition(mousePosition);
          mouseMoveCustomOverlay.setMap(map.current);
          if (reloadRef.current !== 1) {
            const linePath = [centerPosition, mousePosition];
            drawingLine?.setPath(linePath);
            drawingLine?.setMap(map.current);
          }
        }
      } else {
        mouseMoveCustomOverlay.setContent(pointMouseOverInfo);
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    } else if (circleDrawStart) {
      tempCircle?.setMap(null);
      drawingLine?.setMap(null);
      circle?.setMap(null);
      if (centerPosition) {
        const linePath = [centerPosition, mousePosition];
        drawingCircleLine?.setPath(linePath);
        length = drawingCircleLine?.getDistance();
        const info = `<div class ='drawing_circleinfo'><span>${setDistance(
          length
        )}\n우측클릭으로 완성</span></div>`;
        if (length >= 0) {
          const circleOptions = {
            center: centerPosition,
            radius: length,
          };
          drawingCircle.setOptions(circleOptions);
          drawingCircleLine.setMap(map.current);
          drawingCircle.setMap(map.current);
          mouseMoveCustomOverlay.setContent(info);
          mouseMoveCustomOverlay.setPosition(mousePosition);
          mouseMoveCustomOverlay.setMap(map.current);
        }
      } else {
        drawingCircleInfo.setPosition(mousePosition);
        drawingCircleInfo.setMap(map.current);
      }
    } else if (
      drawingPointsRef.current === 1 &&
      changePointRef.current === null
    ) {
      if (drawingCharacterStart) {
        setMarkerCharacterStart(true);
        tempCircle = new naver.maps.Circle({
          map: map.current,
          center: mousePosition,
          radius: stampCircleRef.current,
          fillColor: '#F0F4C3',
          fillOpacity: tempCircleOpacityRef.current,
          strokeColor: '#F0F4C3',
          strokeOpacity: 0,
        });
        tempCircle.setMap(map.current);
      } else {
        mouseMoveCustomOverlay.setContent(characterMouseOverInfo);
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    } else if (
      drawingPointsRef.current === 2 &&
      changePointRef.current === null
    ) {
      if (drawingContentStart) {
        setMarkerContentStart(true);
        tempCircle = new naver.maps.Circle({
          map: map.current,
          center: mousePosition,
          radius: stampCircleRef.current,
          fillColor: '#F0F4C3',
          fillOpacity: tempCircleOpacityRef.current,
          strokeColor: '#F0F4C3',
          strokeOpacity: 0,
        });
        tempCircle.setMap(map.current);
      } else {
        mouseMoveCustomOverlay.setContent(spotMouseOverInfo);
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    } else {
      const mouseLatLng = { lat: mousePosition.y, lng: mousePosition.x };
      // if (
      //   Math.abs(
      //     tempPointsRef.current[changePointRef.current].lat - mouseLatLng.lat
      //   ) < 0.0025 &&
      //   Math.abs(
      //     tempPointsRef.current[changePointRef.current].lng - mouseLatLng.lng
      //   ) < 0.0025
      // ) {
      //   movingPoint = true;
      // }
      if (movingPoint) {
        drawingLine?.setMap(null);
        tempMarkerRef.current[changePointRef.current].setMap(null);
        mouseMoveCustomOverlay.setOptions({
          content: `${changePointContent}<div class='walkcourse_marker_selected' style='margin-left: 6.3rem; display: inline-block;'>${
            changePointRef.current + 1
          }</div>`,
          pixelOffset: new naver.maps.Point(0, -10),
        });
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
        tempCircle = new naver.maps.Circle({
          map: map.current,
          center: mousePosition,
          radius: stampCircleRef.current,
          fillColor: '#F0F4C3',
          fillOpacity: tempCircleOpacityRef.current,
          strokeColor: '#F0F4C3',
          strokeOpacity: 0,
        });
        tempCircle.setMap(map.current);
        const newPoints = [
          ...tempPointsRef.current.slice(0, changePointRef.current),
          mouseLatLng,
          ...tempPointsRef.current.slice(
            changePointRef.current + 1,
            tempPointsRef.current.length
          ),
        ];
        const newMaxBounds = tempCircle.getDrawingRect().getMax();
        const newMinBounds = tempCircle.getDrawingRect().getMin();
        const newWeight = newMaxBounds.y - newMinBounds.y;
        tempCourseRef.current?.setOptions({
          path: newPoints,
          strokeWeight: newWeight,
        });
        tempPolylineRef.current?.setOptions({
          path: newPoints,
          strokeWeight: newWeight / 15,
        });
        tempCourseRef.current?.setMap(map.current);
        tempPolylineRef.current?.setMap(map.current);
      } else {
        mouseMoveCustomOverlay.setOptions({
          content: `<div class='walkcourse_marker_selected' style='margin-left: 6.3rem; display: inline-block;'>
변경을 원하시면 클릭 후 진행해주세요!
          </div>`,
          pixelOffset: new naver.maps.Point(0, -10),
        });
        mouseMoveCustomOverlay.setPosition(mousePosition);
        mouseMoveCustomOverlay.setMap(map.current);
      }
    }
  };
  const rightClickEvent = (evt: any) => {
    if (drawingStart) {
      rightClickRef.current = true;
      if (drawTypeRef?.current?.value === 2) {
        tempPosition = evt.coord;
        centerRef.current = evt.coord;
        centerPosition = evt.coord;
        drawingLine?.setMap(null);
        setMarkerStart(false);
        drawingStart = false;
        mouseMoveCustomOverlay.setMap(null);
        drawRef.current = 1;
      } else if (drawTypeRef?.current?.value === 3) {
        length = 0;
        centerRef.current = evt.coord;
        mouseMoveCustomOverlay.setMap(null);
        drawingLine.setPath([centerPosition, evt.coord]);
        tempCircle.setRadius(drawingLine?.getDistance());
        tempCircleInfoRef.current = {
          point: [centerPosition.y, centerPosition.x],
          radius: drawingLine?.getDistance(),
        };
        drawingLine?.setMap(null);
        tempCircle?.setMap(null);
        setMarkerStart(false);
        drawingStart = false;
      }
    } else if (circleDrawStart) {
      radiusRef.current = [centerPosition, evt.coord];
      circleDrawStart = false;
      setCircleStart(false);
      centerPosition = null;
      drawingCircleLine?.setMap(null);
      drawingCircle?.setMap(null);
    }
  };
  const mouseoutEvent = (evt: any) => {
    mouseMoveCustomOverlay.close();
    tempCircle?.setMap(null);
    drawingStart = false;
    drawingLine?.setMap(null);
    centerPosition = tempPosition;
    circleDrawStart = false;
    drawingCircleLine?.setMap(null);
    drawingCircle?.setMap(null);
  };

  const keydownEvent = (evt: any) => {
    if (evt.keyboardEvent.key === 'Escape') {
      drawingStart = false;
      drawingLine?.setMap(null);
      circleDrawStart = false;
      drawingLine?.setMap(null);
      drawingCircle?.setMap(null);
    }
  };
  const drawingMap = () => {
    if (maps) {
      naver.maps.Event.addListener(map.current, 'click', clickEvent);
      naver.maps.Event.addListener(map.current, 'mousemove', moveEvent);
      naver.maps.Event.addListener(map.current, 'rightclick', rightClickEvent);
      naver.maps.Event.addListener(map.current, 'mouseout', mouseoutEvent);
      naver.maps.Event.addListener(map.current, 'keydown', keydownEvent);
    }
  };

  const parseGPX = (xml: any) => {
    const parser = new DOMParser();
    const result: any = [];
    const newXML = parser.parseFromString(uploadFile, 'text/xml');
    const toJson = newXML.querySelectorAll('trkpt');
    toJson.forEach((element: any, idx: number) => {
      idx % 2 &&
        idx % 4 === 1 &&
        idx % 8 === 1 &&
        idx % 16 === 1 &&
        // idx % 32 === 1 &&
        //   idx % 64 === 1 &&
        result.push({
          lat: element.attributes[0].value,
          lng: element.attributes[1].value,
          radius: stampCircleRef.current,
        });
    });
    console.log('parseGPX :', result);
    setTempPoints(result);
    setUploadFile('');
    setMapCenter([result[0]?.lat, result[0]?.lng]);
  };

  const getCurrentPosition = () => {
    const handleGeoSucces = (position: any) => {
      setMapCenter([position.coords.latitude, position.coords.longitude]);
    };
    const handleGeoError = () => {
      console.log('access geo location error>>>>>>>>>>>>>');
    };
    return navigator.geolocation.getCurrentPosition(
      handleGeoSucces,
      handleGeoError
    );
  };

  const deletePoint = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    marker?.map((m: any) => m?.setMap(null));
    const newPoints = points.filter(
      (_point: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    setPoints(newPoints);
    const newCourse = course;
    const newPolyline = polyline;
    const newMarker = marker.filter(
      (_m: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    marker[Number(evt.currentTarget.id)]?.setMap(null);
    const newCurrentDistance = currentDistance.filter(
      (_distance: any, i: number) => Number(evt.currentTarget.id) !== i
    );
    const newCurrentTime = currentTime.filter(
      (_time: any, i: number) => Number(evt.currentTarget.id) !== i
    );
    if (newPoints.length > 0) {
      newPolyline.setOptions({
        path: newPoints.map((p: any) => new naver.maps.LatLng(p.lat, p.lng)),
      });
      newCourse.setOptions({
        path: newPoints.map((p: any) => new naver.maps.LatLng(p.lat, p.lng)),
      });
      reloadRef.current = newPoints[newPoints.length - 1];
      setMarker(newMarker);
      setCourse(newCourse);
      setPolyline(newPolyline);
      setCourseDistance(newCourse.getDistance());
      setCourseTime(newCourse.getDistance() / 67);
      setCurrentDistance(newCurrentDistance);
      setCurrentTime(newCurrentTime);
    } else {
      newCourse?.setMap(null);
      newPolyline?.setMap(null);
      setMarkerStart(false);
      setMarker(newMarker);
      setCourse(null);
      setPolyline(null);
      setCourseDistance(0);
      setCourseTime(0);
      setCurrentDistance([]);
      setCurrentTime([]);
      reloadRef.current = 1;
    }
  };

  const deletePointContent = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    markerContent?.map((m: any) => m?.setMap(null));
    const newPointContent = pointsContent.filter(
      (_point: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    setPointsContent(newPointContent);
    const newMarker = markerContent.filter(
      (_m: any, i: number) => i !== Number(evt.currentTarget.id)
    );
    markerContent[Number(evt.currentTarget.id)]?.setMap(null);

    if (newPointContent.length > 0) {
      setMarkerContent(newMarker);
    } else {
      setMarkerContentStart(false);
      setMarkerContent(newMarker);
    }

    updateChallengeFieldSpot(newPointContent);
  };

  const changePointContentOrder = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    checkDirection: boolean
  ) => {
    const index = Number(evt.currentTarget.id);
    const checkIndex = checkDirection
      ? [index - 1, index, index - 1, index + 1]
      : [index, index + 1, index, index + 2];
    markerContent?.map((m: any) => m?.setMap(null));
    const newPointContent: any = [
      ...pointsContent.slice(0, checkIndex[0]),
      pointsContent[checkIndex[1]],
      pointsContent[checkIndex[2]],
      ...pointsContent.slice(checkIndex[3], pointsContent.length),
    ];

    const newMarker: any = [
      ...markerContent.slice(0, checkIndex[0]),
      markerContent[checkIndex[1]],
      markerContent[checkIndex[2]],
      ...markerContent.slice(checkIndex[3], markerContent.length),
    ];
    newMarker[checkIndex[1]].setIcon({
      content: newPointContent[checkIndex[1]].name
        ? `<div class='stamp_marker'><span class='stamp_marker_index'>${
            checkIndex[1] + 1
          }</span><span> ${newPointContent[checkIndex[1]].name}</span></div>`
        : `<div class='stamp_marker'><span class='stamp_marker_index'>${
            checkIndex[1] + 1
          }</span></div>`,
      anchor: new naver.maps.Point(18, 38),
    });
    newMarker[checkIndex[2]].setIcon({
      content: newPointContent[checkIndex[2]].name
        ? `<div class='stamp_marker'><span class='stamp_marker_index'>${
            checkIndex[2] + 1
          }</span><span> ${newPointContent[checkIndex[2]].name}</span></div>`
        : `<div class='stamp_marker'><span class='stamp_marker_index'>${
            checkIndex[2] + 1
          }</span></div>`,
      anchor: new naver.maps.Point(18, 38),
    });
    setPointsContent(newPointContent);
    setMarkerContent(newMarker);
    newMarker?.map((m: any) => m?.setMap(map.current));

    updateChallengeFieldSpot(newPointContent);
  };

  const onChangeContentInfo = (idx: number, content: any) => {
    console.log('onChangeContentInfo :, ', content);
    const newPointContent = pointsContent;
    const newMarker = markerContent;
    newMarker?.map((m: any) => m?.setMap(null));
    newPointContent[idx] = { point: [content.lat, content.lng], ...content };
    const tempMarker = new naver.maps.Marker({
      position: new naver.maps.LatLng(content.lat, content.lng),
      map: map.current,
      icon: {
        content: `<div class='field_content_marker'>${idx + 1}</div>`,
        anchor: new naver.maps.Point(18, 38),
      },
      clickable: false,
      draggable: false,
    });
    newMarker[idx] = tempMarker;
    markerContent?.map((m: any) => m?.setMap(null)); // cleawr preview marker
    newMarker?.map((m: any) => m?.setMap(map.current));
    setMarkerContent(newMarker);
    setOpenCreateContent(false);
    setContentInfoIndex(0);
    setContentInfo({});

    updateChallengeFieldSpot(newPointContent);
  };

  const onAddContentInfo = (idx: number, content: any) => {
    const newPointContent = pointsContent;
    const newMarker = markerContent;
    newMarker?.map((m: any) => m?.setMap(null));
    newPointContent[idx] = { point: [content.lat, content.lng], ...content };
    newMarker[idx] = new naver.maps.Marker({
      position: new naver.maps.LatLng(content.lat, content.lng),
      map: map.current,
      icon: {
        content: `<div class='field_content_marker'>${idx + 1}</div>`,
        anchor: new naver.maps.Point(18, 38),
      },
      clickable: false,
      draggable: false,
    });
    newMarker?.map((m: any) => m?.setMap(map.current));
    setMarkerContent(newMarker);
    setPointsContent(newPointContent);
    setOpenCreateContent(false);
    setContentInfoIndex(0);
    setContentInfo({});

    updateChallengeFieldSpot(newPointContent);
  };

  const deleteGeometryInfo = (idx: number) => {
    const tmpObject = geometryObjectList[idx];
    const newObjectList = geometryObjectList
      .map((object: any, i: number) => {
        if (i === idx) {
          object.courseMap?.setMap !== undefined &&
            object.courseMap?.setMap !== null &&
            object.courseMap?.setMap(null);
          object.circleMap?.setMap !== undefined &&
            object.circleMap?.setMap !== null &&
            object.circleMap?.setMap(null);
          object.marker?.setMap !== undefined &&
            object.marker?.setMap !== null &&
            object.marker?.setMap(null);
          object.type = '';
          object.points = [];
          object.courseMap = null;
          object.circleMap = null;
          object.location = [];
          object.marker = null;
          object.hiddenRandomCharacter = false;
        }
        return object;
      })
      .filter((object: any, i: number) => i !== idx);
    newObjectList.push({
      geometryId: generateUuidId(),
      type: '',
      points: [],
      circle: null,
      courseMap: null,
      circleMap: null,
      location: [],
    });
    setGeometryObjectList(newObjectList);

    let count = 0;
    geometryObjectList.map((object: any, i: number) => {
      if (object.type !== '') count += 1;
    });

    setGeometryIndex(count - 1);
    geometryIndexRef.current = count - 1;

    const newMapInfo = {
      geometryList: newObjectList,
      pointsContent: pointsContent,
    };
    setMapInfo(newMapInfo);

    const newData: any = {
      type: 1, // 1 : one, 2: all
      fieldId: selectedFieldId,
      geometryId: tmpObject.geometryId,
    };
    deleteChallengeFieldGeometryMutation(newData);
  };

  const deleteAllGeometryInfo = () => {
    const newData: any = {
      type: 2, // 1 : one, 2: all
      fieldId: selectedFieldId,
      geometryId: '0',
    };
    resetGeometryData();
    deleteChallengeFieldGeometryMutation(newData);
  };

  const hiddenGeometryMarker = () => {
    geometryObjectList.map((object: any, i: number) => {
      object.marker?.setMap !== undefined &&
        object.marker?.setMap !== null &&
        object.marker?.setMap(null);
    });
  };

  const showGeometryMarker = () => {
    geometryObjectList.map((object: any, i: number) => {
      object.marker?.setMap !== undefined &&
        object.marker?.setMap !== null &&
        object.marker?.setMap(map.current);
    });
  };

  const changeHiddenRandomCharacter = (idx: number) => {
    const newObjectList = geometryObjectList.map((object: any, i: number) => {
      const newObject = object;
      if (idx === i) {
        newObject.hiddenRandomCharacter = !newObject.hiddenRandomCharacter;
      }
      return newObject;
    });
    setGeometryObjectList(newObjectList);

    const tmpObject = geometryObjectList[idx];
    const newData: any = {
      fieldId: selectedFieldId,
      geometryId: tmpObject.geometryId,
      hidden: tmpObject.hiddenRandomCharacter ? 1 : 0,
    };
    updateChallengeFieldGeometryHiddenCharacterMutation(newData);
  };

  const {
    data,
    isLoading,
    refetch: fieldCharacterRefetch,
  } = useQuery(
    KEY_FIELD_FIXED_CHARACTER_LIST,
    () => {
      if (selectedFieldId !== null && selectedFieldId.length > 0) {
        return getFieldCharacterList(selectedFieldId, FIXED_CHARACTER);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res && res.fieldCharacter) {
          setupCharacterList(res.fieldCharacter);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: registerChallengeFieldCharacterMutation,
    isError: isMutateError,
  } = useMutation(registerFieldCharacter, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('캐릭터가 등록되었습니다.');
      fieldCharacterRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '캐릭터 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteChallengeFieldCharacterMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteFieldCharacter, {
    onSuccess: () => {
      setOpenMore(0);
      setOpenSnackbar(true);
      setDialogMessage('캐릭터가 삭제되었습니다.');
      fieldCharacterRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '캐릭터를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteAllChallengeFieldCharacterMutation,
    isError: isDeleteAllMutateError,
  } = useMutation(deleteAllFieldCharacter, {
    onSuccess: () => {
      setOpenMore(0);
      setOpenSnackbar(true);
      setDialogMessage('전체 캐릭터가 삭제되었습니다.');
      fieldCharacterRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '전체 캐릭터를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: changeChallengeFieldCharacterLocationMutation,
    isError: isChangeMutateError,
  } = useMutation(updateFieldCharacterLocation, {
    onSuccess: () => {
      setOpenMore(0);
      setOpenSnackbar(true);
      setDialogMessage('캐릭터 위치가 저장되었습니다.');
      fieldCharacterRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '캐릭터 위치를저장하는 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const addCharacterInfo = (character: any) => {
    const newData: any = {
      characterId: character.characterId,
      fieldId: selectedFieldId,
      type: 2, // 고정
      name: character.name,
      image: character.image,
      mode: 1, // 일반
      appearNumber: character.appearNumber ? character.appearNumber : 0,
      mileage: character.mileage,
      bigAppearNumber: character.bigAppearNumber,
      bigMileage: character.bigMileage,
      popupTitle: character.popupTitle ? character.popupTitle : '',
      popupContent: character.popupContent ? character.popupContent : '',
      popupLink: character.popupLink ? character.popupLink : '',
      popupImage: character.popupImage,
      difficulty: character.difficulty,
      basicRadius: character.basicRadius,
      bigRadius: character.bigRadius,
    };

    const newCharacter: any = {
      fieldId: selectedFieldId,
      character: newData,
    };

    registerChallengeFieldCharacterMutation(newCharacter);
  };

  const onAddCharacterInfo = (idx: number, character: any) => {
    addCharacterInfo(character);
  };

  const onChangeCharacterInfo = (idx: number, character: any) => {
    addCharacterInfo(character);
  };

  const deleteCharacterInfo = (idx: number) => {
    markerCharacter.map((m: any) => m?.setMap(null));
    const newData = {
      characterId: fieldCharacterList[idx].characterId,
      fieldId: selectedFieldId,
    };
    deleteChallengeFieldCharacterMutation(newData);
  };

  const deleteAllCharacterInfo = () => {
    markerCharacter.map((m: any) => m?.setMap(null));
    setMarkerCharacter([]);
    setPointsCharacter([]);
    tempMarkerCharacterRef.current = [];

    const newData = {
      fieldId: selectedFieldId,
    };
    deleteAllChallengeFieldCharacterMutation(newData);
  };

  const saveCharacterPosition = (idx: number) => {
    const newLocations = markerCharacter.map((character: any, i: number) => [
      character.position.y,
      character.position.x,
    ]);
    fieldCharacterList[idx].markerMap = markerCharacter;
    fieldCharacterList[idx].location = newLocations;
    const newData: any = {
      characterId: fieldCharacterList[idx].characterId,
      fieldId: selectedFieldId,
      locations: newLocations,
    };
    changeChallengeFieldCharacterLocationMutation(newData);
  };

  const onClickContentInfo = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    const newIndex = Number(evt.currentTarget.id);
    setContentInfoIndex(newIndex);
    setContentInfo(pointsContent[newIndex]);
    setOpenCreateContent(true);
  };

  const onClickCharacterInfo = (
    evt: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    const newIndex = Number(evt.currentTarget.id);
    if (newIndex >= 0) {
      setCharacterInfoIndex(newIndex);
      setCharacterInfo(fieldCharacterList[newIndex]);
    } else {
      setCharacterInfoIndex(newIndex);
      setCharacterInfo(null);
    }
    setOpenCreateCharacter(true);
  };

  useMemo(() => {
    if (
      map.current !== null &&
      map.current !== undefined &&
      map.current.updateBy !== undefined
    ) {
      map.current.updateBy(
        new naver.maps.LatLng(mapCenter[0], mapCenter[1]),
        15
      );
    }
  }, [mapCenter]);

  useEffect(() => {
    if (contentMarkerShow && markerShow) {
      marker?.map((m: any) => m?.setMap(map.current));
      markerContent?.map((m: any) => m?.setMap(null));
    } else if (contentMarkerShow && !markerShow) {
      marker?.map((m: any) => m?.setMap(null));
      markerContent?.map((m: any) => m?.setMap(null));
    } else if (!contentMarkerShow) {
      marker?.map((m: any) => m?.setMap(null));
      markerContent?.map((m: any) => m?.setMap(map.current));
    }
  }, [contentMarkerShow]);

  useEffect(() => {
    console.log('maps>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    initMap();
    initGeometryObjectList();
    initCharacterObjectList();
    drawingMap();
    return () => {
      map.current = null;
      drawingPointsRef.current = null;
      tempCircleOpacityRef.current = 0.6;
      tempPolylineRef.current = null;
      tempCourseRef.current = null;
      tempMarkerRef.current = null;
      centerRef.current = null;
      radiusRef.current = null;
      reloadRef.current = null;
      addPointRef.current = null;
      changePointRef.current = null;
      tempPointsRef.current = [];
      stampCircleRef.current = 150;
    };
  }, [maps, disabled]);

  useEffect(() => {
    console.log('drawingOpacity>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    course?.setOptions({
      strokeOpacity: drawingOpacity ? 0 : 0.3,
    });
    course?.setMap(map.current);
    tempCircleOpacityRef.current = drawingOpacity ? 0 : 0.6;
  }, [drawingOpacity]);

  useEffect(() => {
    if (points.length > 1) {
      console.log('stampCircle>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      currentCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(
          points[points.length - 1].lat,
          points[points.length - 1].lng
        ),
        radius: stampCircle,
        strokeOpacity: 0,
        fillOpacity: 0,
      });
      const newMaxBounds = currentCircle.getDrawingRect().getMax();
      const newMinBounds = currentCircle.getDrawingRect().getMin();
      const newWeight = newMaxBounds.y - newMinBounds.y;
      setCourseWeight(newWeight);
      course?.setOptions({
        strokeWeight: newWeight,
      });
      polyline?.setOptions({
        strokeWeight: courseWeight / 15,
      });
      course?.setMap(map.current);
      polyline?.setMap(map.current);

      const newPoints = points.map((point: any) => {
        const newPoint = {
          lat: point.lat,
          lng: point.lng,
          radius: stampCircle,
        };
        return newPoint;
      });
      const newMapInfo = {
        courseStep: mapInfo.courseStep,
        courseTime: mapInfo.courseTime,
        courseDistance: mapInfo.courseDistance,
        points: newPoints,
        pointsContent: pointsContent,
        circle: mapInfo.circle,
      };
      setMapInfo(newMapInfo);
    }
  }, [stampCircle]);

  // 워크 코스 포인트 설정
  useEffect(() => {
    if (centerRef.current) {
      const newPoint = { lat: centerRef.current.y, lng: centerRef.current.x };
      const newPointList =
        geometryObjectList[geometryIndex] !== undefined &&
        geometryObjectList[geometryIndex].points !== undefined
          ? geometryObjectList[geometryIndex].points
          : [];
      const tmpPoints = newPointList.filter(
        (p: any, idx: number) =>
          idx === 0 || (idx !== 0 && p !== newPointList[0])
      );
      var newPoints = [...tmpPoints, newPoint];
      // if (newPointList.length > 0) {
      //   newPoints = [...newPoints, newPointList[0]];
      // }

      centerRef.current = null;
      let newCircleData: any = null;
      let newArea = 0;
      if (drawTypeRef?.current?.value === 2) {
        if (newPointList.length < 1) {
          tempPolyline = new naver.maps.Polygon({
            paths: newPoints.map(
              (point: any) => new naver.maps.LatLng(point.lat, point.lng)
            ),
            fillColor: FILL_COLOR,
            fillOpacity: 0.1,
            strokeWeight: 5,
            strokeColor: LINE_COLOR,
            strokeOpacity: 1,
            strokeStyle: 'solid',
            strokeLineCap: 'round',
            strokeLineJoin: 'round',
          });
          setCourse(tempPolyline);
          tempPolyline.setMap(null);

          if (geometryObjectList[geometryIndex] !== undefined) {
            const newObjectList = geometryObjectList;
            newObjectList[geometryIndex].points = newPoints;
            newObjectList[geometryIndex].courseMap = tempPolyline;
            newObjectList[geometryIndex].type = POLYGON_TYPE;
            newObjectList[geometryIndex].area = newArea;
            setGeometryObjectList(newObjectList);
          }
        } else {
          const prePoint = newPoints.length - 1;
          const newCourse = course;
          newCourse?.setOptions({
            paths: newPoints.map(
              (point: any) => new naver.maps.LatLng(point.lat, point.lng)
            ),
          });
          tempPolyline = new naver.maps.Polygon({
            map: map.current,
            paths: [
              new naver.maps.LatLng(
                newPoints[prePoint]?.lat,
                newPoints[prePoint]?.lng
              ),
              new naver.maps.LatLng(newPoint.lat, newPoint.lng),
            ],
            strokeOpacity: 0,
          });
          setCourse(newCourse);
          newArea = newCourse.getAreaSize();
          tempPolyline.setMap(null);

          if (geometryObjectList[geometryIndex] !== undefined) {
            const newObjectList = geometryObjectList;
            newObjectList[geometryIndex].points = newPoints;
            newObjectList[geometryIndex].courseMap = newCourse;
            newObjectList[geometryIndex].type = POLYGON_TYPE;
            newObjectList[geometryIndex].area = newArea;
            setGeometryObjectList(newObjectList);

            const newMapInfo = {
              geometryList: newObjectList,
              pointsContent: pointsContent,
            };
            setMapInfo(newMapInfo);

            if (markerStart === false && rightClickRef.current === true) {
              naver.maps.Event.addListener(newCourse, 'click', () => {
                // 다각형의 채우기 옵션을 변경합니다
                console.log('--> polygon clicked !!!');
              });
              saveCurrentGeometry();
            }
          }
        }

        const centerLoc: any = getGeometryObjectCenterLocation(newPoints);
        changeMarker(centerLoc);
      } else if (drawTypeRef?.current?.value === 3) {
        const newStamps = [...stamps, tempCircleInfoRef.current];
        const newCircle = new naver.maps.Circle({
          map: map.current,
          center: new naver.maps.LatLng(
            tempCircleInfoRef.current?.point[0] || 0,
            tempCircleInfoRef.current?.point[1] || 0
          ),
          radius: tempCircleInfoRef.current?.radius || 0,
          fillColor: FILL_COLOR,
          fillOpacity: 0.1,
          strokeWeight: 5,
          strokeColor: LINE_COLOR,
          strokeStyle: 'solid',
          strokeOpacity: 1,
        });
        newCircleData = {
          center: new naver.maps.LatLng(
            tempCircleInfoRef.current?.point[0] || 0,
            tempCircleInfoRef.current?.point[1] || 0
          ),
          radius: tempCircleInfoRef.current?.radius || 0,
        };
        newArea = newCircle.getAreaSize();
        setCircle(newCircle);
        setStamps(newStamps);
        setStampsCircle([...stampsCircle, newCircle]);
        tempCircleInfoRef.current = null;

        const tempMarker = new naver.maps.Marker({
          position: new naver.maps.LatLng(
            tempCircleInfoRef.current?.point[0] || 0,
            tempCircleInfoRef.current?.point[1] || 0
          ),
          map: map.current,
          icon: {
            content: `<div class='walkcourse_marker'>${geometryIndex}</div>`,
            anchor: new naver.maps.Point(18, 38),
          },
          clickable: false,
          draggable: false,
        });
        tempMarker.setMap(map.current);

        if (geometryObjectList[geometryIndex] !== undefined) {
          const newObjectList = geometryObjectList;
          newObjectList[geometryIndex].circle = newCircleData;
          newObjectList[geometryIndex].circleMap = newCircle;
          newObjectList[geometryIndex].type = CIRCLE_TYPE;
          newObjectList[geometryIndex].area = newArea;
          newObjectList[geometryIndex].marker = tempMarker;
          setGeometryObjectList(newObjectList);

          const newMapInfo = {
            geometryList: newObjectList,
            pointsContent: pointsContent,
          };
          setMapInfo(newMapInfo);

          if (markerStart === false && rightClickRef.current === true) {
            saveCurrentGeometry();
          }
        }
      }

      // if (geometryObjectList[geometryIndex] !== undefined) {
      //   const newObjectList = geometryObjectList;
      //   if (drawTypeRef?.current?.value === 2) {
      //     newObjectList[geometryIndex].points = newPoints;
      //     newObjectList[geometryIndex].courseMap = course;
      //     newObjectList[geometryIndex].type = POLYGON_TYPE;
      //   } else if (drawTypeRef?.current?.value === 3) {
      //     newObjectList[geometryIndex].circle = newCircleData;
      //     newObjectList[geometryIndex].circleMap = circle;
      //     newObjectList[geometryIndex].type = CIRCLE_TYPE;
      //   }
      //   newObjectList[geometryIndex].area = newArea;
      //   setObjectList(newObjectList);

      // console.log('--> markerStart:', newObjectList);
    }
  }, [markerStart]);

  useEffect(() => {
    if (centerRef.current) {
      console.log('markerCharacterStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', course);
      const newPoint = {
        lat: centerRef.current.y,
        lng: centerRef.current.x,
        radius: stampCircleRef.current,
      };
      const newPoints = [...pointsCharacter, newPoint];
      currentMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(newPoint?.lat, newPoint?.lng),
        map: map.current,
        icon: {
          content: `<div class='walkcourse_marker'>${newPoints.length}</div>`,
          anchor: new naver.maps.Point(13, 33),
        },
        clickable: true,
        draggable: false,
      });
      // naver.maps.Event.addListener(currentMarker, 'click', (e: any) => {
      //   console.log(e);
      // });
      setMarkerCharacter([...markerCharacter, currentMarker]);
      tempMarkerCharacterRef.current = [...markerCharacter, currentMarker];
      currentMarker?.setMap(null);
      currentCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(newPoint.lat, newPoint.lng),
        radius: stampCircle,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0,
        strokeStyle: 'solid',
        fillColor: '#00a0e9',
        fillOpacity: 0,
      });
      const newMaxBounds = currentCircle.getDrawingRect().getMax();
      const newMinBounds = currentCircle.getDrawingRect().getMin();
      const newWeight = newMaxBounds.y - newMinBounds.y;
      setPointsCharacter(newPoints);
      setCourseWeight(newWeight);
      centerRef.current = null;
    }
  }, [markerCharacterStart]);

  useEffect(() => {
    if (centerRef.current) {
      console.log('markerContentStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', course);
      const newPoint = {
        lat: centerRef.current.y,
        lng: centerRef.current.x,
        radius: stampCircleRef.current,
      };
      const newPoints = [...pointsContent, newPoint];
      currentMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng(newPoint?.lat, newPoint?.lng),
        map: map.current,
        icon: {
          content: `<div class='walkcourse_marker'>${newPoints.length}</div>`,
          anchor: new naver.maps.Point(13, 33),
        },
        clickable: false,
        draggable: false,
      });
      // naver.maps.Event.addListener(currentMarker, 'click', (e: any) => {
      //   console.log(e);
      // });
      setMarkerContent([...markerContent, currentMarker]);
      currentMarker?.setMap(null);
      currentCircle = new naver.maps.Circle({
        map: map.current,
        center: new naver.maps.LatLng(newPoint.lat, newPoint.lng),
        radius: stampCircle,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0,
        strokeStyle: 'solid',
        fillColor: '#00a0e9',
        fillOpacity: 0,
      });
      const newMaxBounds = currentCircle.getDrawingRect().getMax();
      const newMinBounds = currentCircle.getDrawingRect().getMin();
      const newWeight = newMaxBounds.y - newMinBounds.y;
      setPointsContent(newPoints);
      setCourseWeight(newWeight);
      centerRef.current = null;
    }
  }, [markerContentStart]);

  // 전체 반경 설정
  useEffect(() => {
    console.log('circleStart>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
    console.log('circleStart> radiusRef.current :', radiusRef.current);
    circle?.setMap(null);
    if (radiusRef.current) {
      const radius = new naver.maps.Polyline({
        map: map.current,
        path: radiusRef.current,
        strokeWeight: 1,
        strokeColor: '#00a0e9',
        strokeOpacity: 0.1,
        strokeStyle: 'solid',
      });
      const newCircle = new naver.maps.Circle({
        map: map.current,
        center: radiusRef.current[0],
        radius: radius.getDistance(),
        strokeWeight: 1,
        fillColor: '#FF8A65',
        fillOpacity: 0.15,
        strokeColor: '#FF8A65',
        strokeOpacity: 0.3,
      });
      setCircle(newCircle);
      radius.setMap(null);
      newCircle.setMap(null);
      radiusRef.current = null;
    }
  }, [circleStart]);

  useEffect(() => {
    course?.setMap(map.current);
    // const newObjectList = geometryObjectList;
    // if (newObjectList[geometryIndex] !== undefined) {
    //   newObjectList[geometryIndex].courseMap = course;
    //   newObjectList[geometryIndex].type = POLYGON_TYPE;
    //   setObjectList(newObjectList);
    // }
  }, [course]);

  useEffect(() => {
    circle?.setMap(map.current);
    // const newObjectList = geometryObjectList;
    // if (newObjectList[geometryIndex] !== undefined) {
    //   newObjectList[geometryIndex].circleMap = circle;
    //   newObjectList[geometryIndex].type = CIRCLE_TYPE;
    //   setObjectList(newObjectList);
    // }
  }, [circle]);

  useEffect(() => {
    if (course) {
      console.log('courseWeight>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      // const newCourse = course;
      // const newPolyline = polyline;
      // newCourse?.setOptions({
      //   strokeWeight: courseWeight,
      // });
      // newPolyline?.setOptions({
      //   strokeWeight: courseWeight / 15,
      // });
      // setCourse(newCourse);
      // setPolyline(newPolyline);
      // newCourse?.setMap(map.current);
      // newPolyline?.setMap(map.current);
    }
  }, [courseWeight]);

  useEffect(() => {
    console.log('points>>>>>>>>>>>>>>>>>>>>>>>>>');
    marker?.map((m: any, idx: number) =>
      m.setIcon({
        content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
        anchor: new naver.maps.Point(13, 33),
      })
    );
    marker?.map((m: any) => m?.setMap(map.current));
    const newMapInfo = {
      courseStep: 0,
      courseTime: courseTime,
      courseDistance: courseDistance,
      points: points,
      pointsContent: pointsContent,
      circle: mapInfo?.circle,
    };
    setMapInfo(newMapInfo);
  }, [points]);

  useEffect(() => {
    console.log('pointsCharacter>>>>>>>>>>>>>>>>>>>>>>>>>');
    markerCharacter?.map((m: any, idx: number) => {
      m.setIcon({
        content: `<img src="${characterImageRef.current}" width="${characterImageWidthRef.current}" height="${characterImageHeightRef.current}" alt="현재 위치"/>`,
        anchor: new naver.maps.Point(13, 33),
      });
      naver.maps.Event.addListener(m, 'click', () => {
        if (drawingCharacterTypeRef.current === 2) deleteMarkerCharacter(idx);
      });
    });

    markerCharacter?.map((m: any) => m?.setMap(map.current));
    const newMapInfo = {
      courseStep: 0,
      courseTime: courseTime,
      courseDistance: courseDistance,
      points: points,
      pointsCharacter: pointsCharacter,
      pointsContent: pointsContent,
      circle: mapInfo?.circle,
    };
    setMapInfo(newMapInfo);
  }, [pointsCharacter]);

  useEffect(() => {
    console.log('pointsContent>>>>>>>>>>>>>>>>>>>>>>>>>');
    markerContent?.map((m: any, idx: number) =>
      m.setIcon({
        content: `<div class='field_content_marker'>${idx + 1}</div>`,
        anchor: new naver.maps.Point(13, 33),
      })
    );
    markerContent?.map((m: any) => m?.setMap(map.current));
    const newMapInfo = {
      courseStep: 0,
      courseTime: courseTime,
      courseDistance: courseDistance,
      points: points,
      pointsContent: pointsContent,
      circle: mapInfo?.circle,
    };
    setMapInfo(newMapInfo);
  }, [pointsContent]);

  useEffect(() => {
    if (isEdit !== null) {
      console.log('isEdit>>>>>>>>>>>>>>>>>>>>>>>>>null');
      course?.setMap(null);
      polyline?.setMap(null);
      marker?.map((m: any) => m?.setMap(null));
      tempPolylineRef.current = polyline;
      tempCourseRef.current = course;
      tempMarkerRef.current = marker;
      tempMarkerRef.current?.map((m: any) => m?.setMap(map.current));
      const markers = document.querySelectorAll('div.walkcourse_marker');
      markers
        .item(changePointRef.current)
        .classList.add('walkcourse_marker_selected');
      tempCourseRef.current.setMap(map.current);
      tempPolylineRef.current.setMap(map.current);
    } else if (changePointRef.current != null) {
      console.log('isEdit>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>notnull');
      tempMarkerRef.current?.map((m: any) => m?.setMap(null));
      changePointRef.current = null;
      const newCourse = new naver.maps.Polyline({
        path: tempPointsRef.current?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        strokeWeight: courseWeight,
        strokeColor: '#2681FF6F',
        strokeOpacity: 0.5,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const newPolyline = new naver.maps.Polyline({
        path: tempPointsRef.current?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        strokeWeight: courseWeight / 15,
        strokeColor: '#2681FF',
        strokeOpacity: 1,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const newMarker: any = [];
      tempPointsRef.current?.map((q: any, idx: number) => {
        newMarker.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
      });
      setPoints(tempPointsRef.current);
      setMarker(newMarker);
      setCourse(newCourse);
      setPolyline(newPolyline);
      reloadRef.current = tempPointsRef.current
        ? tempPointsRef.current[tempPointsRef.current.length - 1]
        : null;
      newCourse?.setMap(map.current);
      newPolyline?.setMap(map.current);
      newMarker?.map((m: any) => m?.setMap(map.current));
    }
  }, [isEdit]);

  useMemo(() => {
    if (tempCourseRef.current && pointChange) {
      console.log('pointchange>>>>>>>>>>>>>>>>>>>>>>');
      const tempTime: any = [];
      const tempDistance: any = [];
      tempPointsRef.current?.map((q: any, idx: number) => {
        tempPolyline = new naver.maps.Polyline({
          path: [
            new naver.maps.LatLng(
              tempPointsRef.current[idx - 1]?.lat,
              tempPointsRef.current[idx - 1]?.lng
            ),
            new naver.maps.LatLng(q.lat, q.lng),
          ],
          map: map.current,
          strokeOpacity: 0,
        });
        tempPolyline?.getDistance() &&
          tempTime.push(tempPolyline.getDistance() / 67);
        tempDistance.push(tempPolyline?.getDistance());
      });
      setCurrentDistance(tempDistance);
      setCurrentTime(tempTime);
      setCourseDistance(tempCourseRef.current.getDistance());
      setCourseTime(tempCourseRef.current.getDistance() / 67);
      console.log(
        'erer>>>>>>>>>>>>>',
        new naver.maps.LatLng(
          tempPointsRef.current[-1]?.lat,
          tempPointsRef.current[-1]?.lng
        )
      );
      tempPolyline?.setMap(null);
      setPointChange(false);
    }
  }, [pointChange]);

  useEffect(() => {
    if (tempPoint.length > 0) {
      console.log('tempPoint>>>>>>>>>>>>>>>>>>>>>>');
      course?.setMap(null);
      polyline?.setMap(null);
      marker.map((m: any) => m?.setMap(null));
      markerContent?.map((m: any) => m?.setMap(null));
      const tempstime: any = [];
      const tempsdistance: any = [];
      tempPoint?.map((q: any, idx: number) => {
        tempPolyline = new naver.maps.Polyline({
          path: [
            new naver.maps.LatLng(
              tempPoint[idx - 1]?.lat,
              tempPoint[idx - 1]?.lng
            ),
            new naver.maps.LatLng(q.lat, q.lng),
          ],
          map: map.current,
          strokeOpacity: 0,
        });
        tempPolyline?.getDistance() &&
          tempstime.push(tempPolyline.getDistance() / 67);
        tempsdistance.push(tempPolyline?.getDistance());
      });
      tempPolyline?.setMap(null);
      setCurrentDistance(tempsdistance);
      setCurrentTime(tempstime);
      ncourse = new naver.maps.Polyline({
        path: tempPoint?.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        map: map.current,
        strokeWeight: courseWeight,
        strokeColor: '#2681FF6F',
        strokeOpacity: drawingOpacity ? 0 : 0.3,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      tempPolyline = new naver.maps.Polyline({
        path: tempPoint.map(
          (point: any) => new naver.maps.LatLng(point.lat, point.lng)
        ),
        map: map.current,
        strokeWeight: courseWeight / 15,
        strokeColor: '#2681FF',
        strokeOpacity: 1,
        strokeStyle: 'solid',
        strokeLineCap: 'round',
        strokeLineJoin: 'round',
      });
      const temps: any = [];
      tempPoint?.map((q: any, idx: number) => {
        temps.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: `<div class='walkcourse_marker'>${idx + 1}</div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
        // naver.maps.Event.addListener(temps[idx], 'click', (e: any) => {
        //   console.log(e);
        // });
      });
      setMarker(temps);
      setPolyline(tempPolyline);
      setCourse(ncourse);
      setCourseDistance(ncourse.getDistance());
      setCourseTime(ncourse.getDistance() / 67);
      setPoints(tempPoint);
      setTempPoints([]);
    }
  }, [tempPoint]);

  useEffect(() => {
    if (tempPointsContent.length > 0) {
      console.log('tempPointsContent>>>>>>>>>>>>>>>>>>>>>>');
      markerContent?.map((m: any) => m?.setMap(null));

      const temps: any = [];
      tempPointsContent?.map((q: any, idx: number) => {
        temps.push(
          new naver.maps.Marker({
            position: new naver.maps.LatLng(q?.lat, q?.lng),
            map: map.current,
            icon: {
              content: `<div class='field_content_marker'>${idx + 1}</div>`,
              anchor: new naver.maps.Point(13, 33),
            },
            clickable: false,
            draggable: false,
          })
        );
        // naver.maps.Event.addListener(temps[idx], 'click', (e: any) => {
        //   console.log(e);
        // });
      });
      setMarkerContent(temps);
      setPointsContent(tempPointsContent);
      setTempPoints([]);
    } else {
      setMarkerContent([]);
      setPointsContent([]);
      setTempPoints([]);
    }
  }, [tempPointsContent]);

  useEffect(() => {
    if (gpxData.length > 0) {
      const result: any = [];

      gpxData.forEach((element: any, idx: number) => {
        result.push({
          lat: element[0],
          lng: element[1],
          radius: stampCircleRef.current,
        });
      });

      setTempPoints(result);
      setUploadFile('');
      setMapCenter([result[0]?.lat, result[0]?.lng]);
    }
  }, [gpxData]);

  const test = () => {
    const newDistance = courseDistance / 100;
    let i = 1;
    let sumDistance = 0;
    const reducer = (q: any, val: any, idx: number) => {
      if (idx + 1 < points.length) {
        const newCurrentDistance = currentDistance[idx + 1];
        console.log('newCurrentDistance>>>>>>>>>', newCurrentDistance);
        do {
          const ratio = (newDistance * i - sumDistance) / newCurrentDistance;
          const newPath = {
            lat: val.lat + ratio * (points[idx + 1].lat - val.lat),
            lng: val.lng + ratio * (points[idx + 1].lng - val.lng),
          };
          q.push(newPath);
          i += 1;
        } while (sumDistance + newCurrentDistance > i * newDistance);
        sumDistance += newCurrentDistance;
      }
      return q;
    };
    const newPoints = points.reduce(reducer, []);
    setTempPoints(newPoints);
  };

  useEffect(() => {
    drawingPointsRef.current = null;
    setSelectInsertCharacter(false);
    setSelectInsertContent(false);
    resetGeometryData();
    resetMarkerCharacter();
    resetContentData();

    fieldGeometryRefetch();
    // fieldSpotRefetch();
  }, [selectedFieldId]);

  useEffect(() => {
    console.log('fieldData Data>>>>>>>>>>>>>>>>>>>>>>>>>:', fieldData);
    setSelectInsertCharacter(false);
    setSelectInsertContent(false);
    if (fieldData && fieldData.gpsData.length > 0) {
      console.log('fieldData Data: ', fieldData.gpsData);
      const newTempPoints = fieldData.gpsData;
      setTempPoints(newTempPoints);
      setMapCenter([newTempPoints[0]?.lat, newTempPoints[0]?.lng]);

      var newRadius = 9999999;
      fieldData.gpsData.map((gps: any) => {
        newRadius = gps.radius < newRadius ? gps.radius : newRadius;
      });
      setStampCircle(newRadius);
      stampCircleRef.current = newRadius;
    } else {
      setMapCenter([37.5, 127.012]);
    }

    if (fieldData) {
      totalAreaRef.current = squareMetersToSquareKilometers(
        fieldData.totalArea
      );
    }

    // if (
    //   fieldData &&
    //   fieldData.spotData !== undefined &&
    //   fieldData.spotData !== null &&
    //   fieldData.spotData.length > 0
    // ) {
    //   const newTempSpots = fieldData.spotData;
    //   setTempPointsContent(newTempSpots);
    // } else {
    //   setPointsContent([]);
    // }
  }, [fieldData]);

  return (
    <div style={{ margin: '2rem 0' }}>
      <div style={{ display: 'flex' }}>
        {new Array(showContent ? 3 : 2).fill(0).map((_v: any, idx: number) => (
          <ButtonPaper
            id={idx.toString()}
            key={idx.toString()}
            sx={{
              ml: !idx ? '.4rem' : '',
              border:
                (!selectInsertCharacter && !selectInsertContent && idx === 0) ||
                (selectInsertCharacter && !selectInsertContent && idx === 1) ||
                (!selectInsertCharacter && selectInsertContent && idx === 2)
                  ? '1px solid #00A2FF'
                  : '',
              borderBottom: '1px solid white',
            }}
            aria-hidden
            onClick={evt => {
              mouseMoveCustomOverlay.close();
              if (evt.currentTarget.id === '0') {
                drawingPointsRef.current = null;
                setContentMarkerShow(true);
                setSelectInsertCharacter(false);
                setSelectInsertContent(false);
                circle?.setMap(null);
                setChangeCharacter(0);
                resetMarkerCharacter();
                showGeometryMarker();
              } else if (evt.currentTarget.id === '1') {
                setContentMarkerShow(true);
                setSelectInsertCharacter(true);
                setSelectInsertContent(false);
                drawingPointsRef.current = 1;
                drawingCharacterTypeRef.current = null;
                setChangeCharacter(0);
                hiddenGeometryMarker();
                fieldCharacterRefetch();
              } else if (evt.currentTarget.id === '2') {
                setContentMarkerShow(false);
                setSelectInsertCharacter(false);
                setSelectInsertContent(true);
                drawingPointsRef.current = 2;
                circle?.setMap(null);
                setChangeCharacter(0);
                resetMarkerCharacter();
                hiddenGeometryMarker();

                fieldSpotRefetch();
              }
            }}
          >
            <span style={{ fontSize: '1rem' }}>
              {idx === 0
                ? '필드 그리기'
                : idx === 1
                ? '지정형 몬스터 추가'
                : '플레이스 추가'}
            </span>
          </ButtonPaper>
        ))}
      </div>
      <Box className='flex_center'>
        <div
          id={elementId}
          ref={map}
          style={{
            width: '70%',
            height: '70vh',
            borderRadius: '10px',
            minWidth: '900px',
          }}
          onContextMenu={e => {
            e.preventDefault();
          }}
        >
          {selectInsertCharacter === false && selectInsertContent === false && (
            <OverlayView
              sx={{
                justifyContent: 'start',
                width: '5rem',
                backgroundColor: '#ffffff',
                height: '3.5rem',
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: '500' }}>총 면적</Typography>
                <Typography className=''>{`${totalAreaRef.current} ㎢`}</Typography>
              </Box>
            </OverlayView>
          )}
          {selectInsertCharacter === false && selectInsertContent === false && (
            <OverlayView sx={{ justifyContent: 'end' }}>
              <Box>
                <TextField
                  select
                  sx={{
                    width: '10rem',
                    ml: '1rem',
                    backgroundColor: '#ffffff',
                  }}
                  inputRef={drawTypeRef}
                  value={drawType}
                  onChange={evt => setDrawType(Number(evt.target.value))}
                >
                  <MenuItem value={2}>다각형 그리기</MenuItem>
                  <MenuItem value={3}>원 그리기</MenuItem>
                </TextField>
                <Button
                  sx={{ ml: '1rem' }}
                  color='info'
                  variant='contained'
                  onClick={() => {
                    setDialogTitle('생성한 필드를 전부 다 삭제하시겠습니까?');
                    setOpenConfirm(4); // geometry all delete
                  }}
                >
                  초기화
                </Button>
              </Box>
            </OverlayView>
          )}
        </div>
        {selectInsertCharacter === false && selectInsertContent === false && (
          <ContentsPaper>
            <Box className='walkcourse_content_header'>필드 정보</Box>
            <Box className='flex_between'>
              <Button
                disabled={disabled}
                size='small'
                color='error'
                onClick={() => {
                  setDialogTitle('생성한 필드를 전부 다 삭제하시겠습니까?');
                  setOpenConfirm(4); // geometry all delete
                }}
              >
                전체 삭제
              </Button>
              <Button disabled={disabled} size='small' color='error'>
                필드 검색
              </Button>
            </Box>
            <Divider sx={{ m: 0, p: 0 }} />
            <Box className='flex_start' sx={{ m: '1rem' }}>
              <Typography sx={{ fontSize: '1.06rem' }}>
                필드(캐릭터 출현 영역) 지정
              </Typography>
            </Box>
            <Divider sx={{ m: 0, p: 0 }} />
            <Box sx={{ p: '1rem', overflowY: 'scroll', height: '60vh' }}>
              {geometryObjectList
                .filter(
                  (geometryObject: any, idx: number) =>
                    geometryObject.type !== ''
                )
                .map((geometryObject: any, idx: number) => (
                  <Box
                    key={`geometryObject_${idx}`}
                    style={{ padding: '0 1rem 1rem' }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        mb: '1rem',
                      }}
                      key={idx.toString()}
                    >
                      <Box className='flex_center'>
                        <Box
                          id={idx.toString()}
                          aria-hidden
                          className='walkcourse_marker'
                          sx={{ mr: '1.5rem' }}
                        >
                          {idx + 1}
                        </Box>
                        <Box>
                          {geometryObject.type === 'polygon'
                            ? '다각형 그리기'
                            : '원 그리기'}
                        </Box>
                      </Box>
                      <div>
                        <IconButton
                          id={idx.toString()}
                          size='small'
                          sx={{ ml: '1rem', pt: '.2rem' }}
                          onClick={evt => {
                            evt.stopPropagation();
                            setSelectedIndex(idx);
                            setDialogTitle(
                              `${idx + 1}번 필드를 삭제하시겠습니까?`
                            );
                            setOpenConfirm(1); // geometry delete
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Box>
                    <Typography sx={{ fontSize: '1.06rem' }}>
                      {`총 면적 : ${squareMetersToSquareKilometers(
                        geometryObject.area
                      )} ㎢`}
                    </Typography>
                    <Box className='flex_start'>
                      <Checkbox
                        onChange={e => {
                          changeHiddenRandomCharacter(idx);
                        }}
                        checked={geometryObject.hiddenRandomCharacter}
                      />
                      <Typography sx={{ fontSize: '1.06rem' }}>
                        랜덤형 캐릭터 노출안함
                      </Typography>
                    </Box>
                    <Divider sx={{ p: 0, m: 0 }} />
                  </Box>
                ))}
            </Box>
          </ContentsPaper>
        )}
        {selectInsertCharacter === true && (
          <ContentsPaper>
            <Box className='walkcourse_content_header'>캐릭터 정보</Box>
            <Box className='walkcourse_contents_button'>
              <Button
                size='small'
                color='error'
                disabled={changeCharacter !== 0}
                onClick={() => {
                  setDialogTitle(`생성한 몬스터를 전부 다 삭제하시겠습니까?`);
                  setOpenConfirm(3); // character all delete
                }}
              >
                전체 삭제
              </Button>
            </Box>
            <Box className='flex_between' sx={{ m: '1rem' }}>
              <Typography sx={{ fontSize: '1.06rem', mb: '1em' }}>
                지정 출현 캐릭터 등록
              </Typography>
              <Button
                id='-1'
                variant='contained'
                size='small'
                color='info'
                disabled={changeCharacter !== 0}
                onClick={evt => onClickCharacterInfo(evt)}
              >
                캐릭터 등록
              </Button>
            </Box>
            <Divider sx={{ m: 0, p: 0 }} />
            <Box sx={{ p: '1rem', overflowY: 'scroll', height: '60vh' }}>
              {fieldCharacterList
                .filter((character: any, idx: number) => character.image !== '')
                .map((character: any, idx: number) => (
                  <Box
                    key={`character_${idx}`}
                    style={{ padding: '0 1rem 1rem' }}
                    onClick={evt => {
                      if (changeCharacter === 0) {
                        setupMarkerCharacter(idx);
                      }
                    }}
                    className={
                      idx === characterIndexRef.current ? 'selected_row' : ''
                    }
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-start',
                        mb: '1rem',
                      }}
                      key={idx.toString()}
                    >
                      <Box className='flex_center'>
                        <div
                          id={idx.toString()}
                          aria-hidden
                          className='walkcourse_marker'
                        >
                          {idx + 1}
                        </div>
                      </Box>
                      <div>
                        <IconButton
                          id={idx.toString()}
                          size='small'
                          sx={{ ml: '1rem', pt: '.2rem' }}
                          disabled={changeCharacter !== 0}
                          onClick={evt => {
                            evt.stopPropagation();
                            setSelectedIndex(idx);
                            setDialogTitle(`생성한 몬스터를 삭제하시겠습니까?`);
                            setOpenConfirm(2); // character one delete
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </Box>
                    <Typography sx={{ fontSize: '1.06rem', mb: '1em' }}>
                      {character.name ? character.name : '이름 넣기'}
                    </Typography>
                    <Box sx={{ mb: '1.5rem' }} className='flex_between'>
                      <Button
                        id={idx.toString()}
                        variant='outlined'
                        size='small'
                        color='info'
                        disabled={changeCharacter !== 0}
                        onClick={evt => onClickCharacterInfo(evt)}
                      >
                        정보 입력
                      </Button>
                      {changeCharacter === 0 ||
                      (changeCharacter !== 0 &&
                        drawingCharacterRef.current !== idx) ? (
                        <Box>
                          <Button
                            id={`character_${idx + 1}`}
                            variant='outlined'
                            size='small'
                            color='primary'
                            sx={{ mr: '1rem' }}
                            disabled={
                              changeCharacter !== 0 &&
                              drawingCharacterRef.current !== idx
                            }
                            onClick={evt => {
                              evt.stopPropagation();
                              if (changeCharacter === 0) {
                                setupMarkerCharacter(idx);
                              }
                              drawingCharacterRef.current = idx;
                              drawingCharacterTypeRef.current = 1; // 추가
                              setChangeCharacter(1);
                            }}
                          >
                            캐릭터 추가
                          </Button>
                          <Button
                            id={`character_${idx + 1}`}
                            variant='outlined'
                            size='small'
                            color='warning'
                            disabled={
                              changeCharacter !== 0 &&
                              drawingCharacterRef.current !== idx
                            }
                            onClick={evt => {
                              evt.stopPropagation();
                              drawingCharacterRef.current = idx;
                              drawingCharacterTypeRef.current = 2; // 삭제
                              setChangeCharacter(2);
                            }}
                          >
                            캐릭터 삭제
                          </Button>
                        </Box>
                      ) : changeCharacter !== 0 &&
                        drawingCharacterRef.current === idx ? (
                        <Button
                          id={`character_save_${idx + 1}`}
                          variant='contained'
                          size='small'
                          color='primary'
                          onClick={evt => {
                            evt.stopPropagation();
                            drawingCharacterRef.current = null;
                            saveCharacterPosition(idx);
                            setChangeCharacter(0);
                          }}
                        >
                          저장
                        </Button>
                      ) : (
                        ''
                      )}
                    </Box>
                    <Divider sx={{ p: 0, m: 0 }} />
                  </Box>
                ))}
            </Box>
          </ContentsPaper>
        )}
        {selectInsertContent === true && (
          <ContentsPaper>
            <Box className='walkcourse_content_header'>콘텐츠 정보</Box>
            <Box className='walkcourse_contents_button'>
              <Button
                size='small'
                color='error'
                onClick={() => {
                  markerContent?.map((m: any) => m?.setMap(null));
                  setMarkerContent([]);
                  setPointsContent([]);
                }}
              >
                전체 삭제
              </Button>
            </Box>
            <Box sx={{ p: '1rem', overflowY: 'scroll', height: '60vh' }}>
              {pointsContent.map((stamp: any, idx: number) => (
                <div key={`content_${idx}`} style={{ padding: '0 1rem 1rem' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'flex-start',
                      mb: '1rem',
                    }}
                    key={idx.toString()}
                  >
                    <Box className='flex_center'>
                      <div
                        id={idx.toString()}
                        aria-hidden
                        className='field_content_marker'
                        onClick={() =>
                          setMapCenter([stamp?.point[0], stamp?.point[1]])
                        }
                      >
                        {idx + 1}
                      </div>
                    </Box>
                    <div>
                      <IconButton
                        id={idx.toString()}
                        size='small'
                        sx={{ ml: '1rem', pt: '.2rem' }}
                        onClick={evt => deletePointContent(evt)}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Box>
                  <Typography sx={{ fontSize: '1.06rem', mb: '1em' }}>
                    {stamp.name ? stamp.name : '이름 넣기'}
                  </Typography>
                  <Box sx={{ mb: '1.5rem' }} className='flex_between'>
                    <Button
                      id={idx.toString()}
                      variant='outlined'
                      size='small'
                      color='info'
                      onClick={evt => onClickContentInfo(evt)}
                    >
                      <EditOutlinedIcon />
                      정보 입력
                    </Button>
                    <Box>
                      {!!idx && (
                        <Button
                          id={idx.toString()}
                          variant='outlined'
                          size='small'
                          color='info'
                          sx={{ p: '.2rem', minWidth: '1rem' }}
                          onClick={evt => changePointContentOrder(evt, true)}
                        >
                          <ExpandLessOutlinedIcon />
                        </Button>
                      )}
                      {idx !== points.length - 1 && (
                        <>
                          <span style={{ marginLeft: '.8rem' }} />
                          <Button
                            id={idx.toString()}
                            size='small'
                            variant='outlined'
                            color='info'
                            sx={{ p: '.2rem', minWidth: '1rem' }}
                            onClick={evt => changePointContentOrder(evt, false)}
                          >
                            <ExpandMoreOutlinedIcon />
                          </Button>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Divider sx={{ p: 0, m: 0 }} />
                </div>
              ))}
            </Box>
          </ContentsPaper>
        )}
      </Box>
      <SwLoadGPX
        type={1}
        open={openGpx}
        onclose={() => setOpenGpx(false)}
        gpxData={gpxData}
        setGpxData={setGpxData}
        radius={stampCircleRef.current}
      />
      <ChallengeFieldSpotInfo
        open={openCreateContent}
        spot={contentInfo}
        spotIndex={contentInfoIndex}
        onClose={() => {
          setOpenCreateContent(false);
          setContentInfoIndex(0);
          setContentInfo({});
        }}
        onConfirm={onChangeContentInfo}
        onAdd={onAddContentInfo}
      />
      <ChallengeFieldCharacterInfo
        open={openCreateCharacter}
        character={characterInfo}
        characterIndex={characterInfoIndex}
        onClose={() => {
          setOpenCreateCharacter(false);
          setCharacterInfoIndex(0);
          setCharacterInfo({});
        }}
        onConfirm={onChangeCharacterInfo}
        onAdd={onAddCharacterInfo}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents={dialogTitle}
        open={openConfirm !== 0}
        onClose={() => setOpenConfirm(0)}
        onConfirm={() => {
          openConfirm === 1
            ? deleteGeometryInfo(selectedIndex)
            : openConfirm === 2
            ? deleteCharacterInfo(selectedIndex)
            : openConfirm === 3
            ? deleteAllCharacterInfo()
            : openConfirm === 4
            ? deleteAllGeometryInfo()
            : '';
          setOpenConfirm(0);
        }}
        confirm={1}
      />
    </div>
  );
};
