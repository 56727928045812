import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Upload
 */
export const uploadFile = async (type: string, newFile: any) => {
  var formData = new FormData();
  formData.append('file', newFile);

  const res = await xapi.post(`/admin/v2/apis/upload/${type}`, formData);
  return res;
};
