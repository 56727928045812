import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  KEY_DATAPORTAL_ACCOUNT_LIST,
  KEY_POINT_USE_PLACE_LIST,
} from '../../common/key';
import { getDataPortalAccountList } from '../../common/api/ApiDataPortal';
import { SwSearchDataPortal } from './SwSearchDataPortal';
import { SwSearchTarget } from './SwSearchTarget';
import { SwFileSetting } from './SwFileSetting';
import { useSession } from '../../hooks/session';
import {
  deleteChallengePointUsePlace,
  getPointUsePlaceList,
  registerChallengePointUsePlace,
} from '../../common/api/ApiChallenge';
import { checkUrl, getAddUrl } from '../../common/helper';
import { uploadFile } from '../../common/api/ApiUpload';
import { SwSnackbar } from './views/SwSnackbar';
import { SwAlert } from './views/SwAlert';

const columns = ['번호', '사용처 이름', '액션 링크', '삭제'];

interface Props {
  brandPointId: string;
  reset: boolean;
}

export const SwPointUsePlace: React.FC<Props> = ({ brandPointId, reset }) => {
  const { loginVal } = useSession();
  const [usePlaceIdx, setUsePlaceIdx] = useState<number>(0);
  const [usePlaceList, setUsePlaceList] = useState<any>([]);
  const [usePlaceName, setUsePlaceName] = useState<string>('');
  const [usePlaceImage, setUsePlaceImage] = useState<string>('');
  const [usePlaceFile, setUsePlaceFile] = useState<any>(null);
  const [actionLink, setActionLink] = useState<string>('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [searchKey, setSearchKey] = useState<string>('community');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [title, setTitle] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: pointUsePlaceRefetch,
  } = useQuery(
    KEY_POINT_USE_PLACE_LIST,
    () => {
      const newData: any = {
        brandPointId: brandPointId,
      };
      if (brandPointId.length > 0) return getPointUsePlaceList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (
          res !== null &&
          res.usePlace !== undefined &&
          res.usePlace !== null
        ) {
          setUsePlaceList(res.usePlace);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: registerUsePlaceMutation, isError: isRegisterMutateError } =
    useMutation(registerChallengePointUsePlace, {
      onSuccess: () => {
        setOpenSnakerDialog(true);
        setDialogMessage('사용처가 등록되었습니다.');
        pointUsePlaceRefetch();
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '사용처를 등록하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: deleteChallengePointUsePlaceMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteChallengePointUsePlace, {
    onSuccess: () => {
      setOpenSnakerDialog(true);
      setDialogMessage('사용처가 삭제되었습니다.');
      pointUsePlaceRefetch();
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '사용처를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const registerUsePlace = (newUploadUrl: any) => {
    const newImage =
      usePlaceFile !== null
        ? newUploadUrl
        : usePlaceImage !== null && usePlaceImage.length > 0
        ? usePlaceImage
        : '';
    const newData: any = {
      brandPointId: brandPointId,
      idx: usePlaceIdx,
      placeName: usePlaceName,
      actionLink: checkUrl(actionLink),
      image: newImage,
    };

    registerUsePlaceMutation(newData);
  };

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (usePlaceFile !== null) {
      const newUploadFile: any = await uploadFile(
        'advertisement',
        usePlaceFile
      );
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    registerUsePlace(newUploadUrl);
  }

  const checkUsePlace = () => {
    uploadMultipleFiles();
  };

  const clickRow = (idx: number) => {
    setUsePlaceIdx(usePlaceList[idx].idx);
    setUsePlaceName(usePlaceList[idx].placeName);
    setActionLink(usePlaceList[idx].actionLink);
    const newImage =
      usePlaceList[idx].image !== null && usePlaceList[idx].image.length > 0
        ? usePlaceList[idx].image
        : '';
    console.log('--> usePlaceList[idx]:', usePlaceList[idx]);
    console.log('--> newImage:', newImage);
    setUsePlaceImage(newImage);
    setUsePlaceFile(null);
  };

  const resetUsePlace = () => {
    setUsePlaceIdx(0);
    setUsePlaceName('');
    setActionLink('');
    setUsePlaceImage('');
    setUsePlaceFile(null);
  };

  const deleteUsePlace = (idx: number) => {
    const newData: any = {
      advertiserId: brandPointId,
      idx: idx,
    };
    deleteChallengePointUsePlaceMutation(newData);
  };

  useEffect(() => {
    if (reset) {
      setUsePlaceList([]);
    }
  }, [reset]);

  useEffect(() => {
    if (brandPointId !== null && brandPointId.length > 0)
      pointUsePlaceRefetch();
  }, [brandPointId]);

  return (
    <>
      <Box>
        <Typography className='title'>포인트 사용처</Typography>
        <Paper>
          <Typography sx={{ m: '1rem 0 ' }}>사용처 목록</Typography>
          <TableContainer
            sx={{ p: '0 1rem', height: '20vh', overflowY: 'scroll' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => (
                    <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {usePlaceList.map((row: any, idx: number) => (
                  <TableRow
                    key={idx.toString()}
                    className={
                      row.idx === usePlaceIdx ? 'selected_row' : 'whitecolor'
                    }
                    onClick={() => clickRow(idx)}
                  >
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{row.placeName}</TableCell>
                    <TableCell>{row.actionLink}</TableCell>
                    <TableCell sx={{ p: '.1rem .6rem' }}>
                      <Button
                        color='info'
                        variant='contained'
                        onClick={() => deleteUsePlace(row.idx)}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Box sx={{ mt: '1rem' }}>
          <Box>
            <Typography className='title'>사용처 이름</Typography>
            <TextField
              placeholder='사용처 이름을 입력해 주세요.'
              sx={{ width: '50%' }}
              value={usePlaceName}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setUsePlaceName(evt.target.value)}
            />
          </Box>
          <Box>
            <Typography className='title'>사용처 이미지</Typography>
            <SwFileSetting
              setImage={setUsePlaceImage}
              image={
                usePlaceFile !== null
                  ? usePlaceImage
                  : usePlaceImage !== ''
                  ? `${imageUrlPrefix}${usePlaceImage}`
                  : ''
              }
              setFile={setUsePlaceFile}
              file={usePlaceFile}
            />
          </Box>
          <Box>
            <Typography className='title'>액션 링크(url)</Typography>
            <TextField
              placeholder='url을 입력해주세요.'
              sx={{ width: '50%' }}
              value={actionLink}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => setActionLink(getAddUrl(evt.target.value))}
            />
          </Box>
        </Box>
        <Box className='flex_start' sx={{ mt: '1rem' }}>
          <Button variant='outlined' color='primary' onClick={resetUsePlace}>
            사용처 신규작성
          </Button>
          <Button
            sx={{ ml: '1rem' }}
            variant='contained'
            color='primary'
            onClick={checkUsePlace}
          >
            사용처 저장
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
