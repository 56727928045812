import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  Paper,
  Switch,
  ClickAwayListener,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  ChangeEvent,
  DragEvent,
  SyntheticEvent,
  useEffect,
  useState,
  Dispatch,
  useRef,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { useMany } from '../../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../../common/api/ApiDataPortal';
import {
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
  KEY_PROGRAM_MISSION_QNA_DETAIL_INFO,
  KEY_PROGRAM_MISSION_QNA_QUESTION_LIST,
} from '../../../../common/key';
import {
  TOOLTIP_FONT_SIZE,
  TOOLTIP_QNA_QUESTION_TYPE,
  checkNaN,
  generateUuidId,
} from '../../../../common/helper';
import {
  getProgramMissionDetailInfo,
  getProgramMissionQnaQuestionList,
  postProgramMissionQna,
  putMissionQnaQuestionInfo,
  putProgramCurriculumMissionDelete,
  putProgramCurriculumMissionQuestionDelete,
  putProgramMissionQnaQuestionIsRequired,
  putProgramMissionQnaType,
} from '../../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../../common/resultCode';
import { useSession } from '../../../../hooks/session';
import { CreatePaper } from './QuestionAnswerStyles';
import { TOOLTIP_REQUIRED_ALL_ANSWER } from '../../../commonComponent/program/SwProgramCommon';
import { SwSurveyCreate } from '../../../commonComponent/program/SwSurveyCreate';
import SwLoading from '../../../commonComponent/spinner/SwLoading';
import { SwQuizCreate } from '../../../commonComponent/program/SwQuizCreate';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../../commonComponent/views/SwConfirmDialog';
import { changeQuestionAnswerMasterHasReward } from '../../../../common/api/ApiQuestionAnswer';
import { requiredAllAnswerSts } from '../../../../common/atom';

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderRadius: '15px',
  },
});

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

interface Props {
  questionAnswerInfo: any;
  setQuestionAnswerInfo: Dispatch<React.SetStateAction<any>>;
  reloadQuestionAnswer: (id: string) => void;
}

export const QuestionAnswerQuestion: React.FC<Props> = ({
  questionAnswerInfo,
  setQuestionAnswerInfo,
  reloadQuestionAnswer,
}) => {
  const queryClient = useQueryClient();
  const { loginVal } = useSession();
  const [requiredAllAnswerInfo, setRequiredAllAnswerInfo] =
    useRecoilState(requiredAllAnswerSts);

  const titleRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [achieveCount, setAchieveCount] = useState<number>(1);
  const [attendDay, setAttendDay] = useState<string>('day-all');
  const [minAnswer, setMinAnswer] = useState<number>(1);
  const [isRepeated, setIsRepeated] = useState<number>(0);
  const [hasReward, setHasReward] = useState<number>(0);
  const [requiredAllAnswer, setRequiredAllAnswer] = useState<number>(-1);
  const [requiredAllAnswerSwitch, setRequiredAllAnswerSwitch] =
    useState<number>(-1);
  const [questionAnswerTotalPoint, setQuestionAnswerTotalPoint] =
    useState<number>(0);
  const [content, setContent] = useState<string>('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [link, setLink] = useState<string>('');
  const [qnaType, setQnaType] = useState<number>(1);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openQnaConfirm, setOpenQnaConfirm] = useState<number>(0);
  const [missionId, setMissionId] = useState<string>('');
  const [weekId, setWeekId] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const [questionList, setQuestionList] = useState<any[]>([]);
  const [showAttenDay, setShowAttendDay] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<number>(0);
  const [deleteAll, setDeleteAll] = useState<boolean>(false);
  const [forcedSave, setForcedSave] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const reset = () => {
    setTabValue(0);
    setTitle('');
    setPoint(10);
    setLink('');
    setQuestionList([]);
    setMinAnswer(1);
    setRequiredAllAnswer(-1);
    setRequiredAllAnswerSwitch(-1);
    setIsRepeated(0);
    setQuestionAnswerTotalPoint(0);
  };

  const makeDummyQuestion = (
    newType: number,
    newMissionId: string,
    newWeekId: string
  ) => {
    const newOption = [{ body: '', isCorrect: 0, isCustomAnswer: 0 }];
    const newQuestion = {
      image: null,
      missionId: newMissionId,
      optionList: JSON.stringify(newOption),
      seq: 0,
      title: '',
      type: 1,
      weekId: newWeekId,
    };
    setQuestionList([newQuestion]);
  };

  const {
    data: detailInfo,
    isLoading,
    refetch: programMissionRefetch,
  } = useQuery(
    KEY_PROGRAM_MISSION_QNA_DETAIL_INFO,
    () => {
      console.log('missionId: ', missionId);
      if (missionId.length > 0) {
        const newData: any = {
          type: 'qna',
          missionId: missionId,
        };
        return getProgramMissionDetailInfo(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('mission detailInfo :', res);
        if (res !== null && res.mission !== null && res.mission.qna !== null) {
          res.mission.qna.title !== undefined &&
            setTitle(res.mission.qna.title);
          res.mission.qna.successScore !== undefined &&
            setPoint(res.mission.qna.successScore);
          setIsRepeated(
            res.mission.qna.isRepeated === null
              ? 0
              : res.mission.qna.isRepeated === true ||
                res.mission.qna.isRepeated === 1
              ? 1
              : 0
          );
          setAttendDay('day-all');
          if (res.mission.qna.type !== undefined) {
            res.mission.qna.type === 1 ? setTabValue(0) : setTabValue(1);
          }
          setQuestionList(
            res.mission.questionList !== null ? res.mission.questionList : []
          );
          setQuestionAnswerTotalPoint(
            res.mission.questionList !== null
              ? res.mission.questionList.length
              : 0
          );
          // if (
          //   res.mission.questionList !== null &&
          //   res.mission.questionList.length === 1
          // ) {
          //   setIsEdit(2);
          // }
          if (
            res.mission.questionList !== null &&
            res.mission.questionList.length === 0
          ) {
            if (questionAnswerInfo !== undefined && questionAnswerInfo !== null)
              makeDummyQuestion(
                tabValue === 0 ? 1 : 2,
                missionId,
                questionAnswerInfo.weekId
              );
            // setIsEdit(2);
          }

          setMinAnswer(res.mission.qna.minAnswer);
        } else if (missionId.length > 0) {
          reset();
          setOpenDialog(true);
          setDialogMessage('데이터가 없습니다.');
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newMissionId: string) => {
    queryClient.prefetchQuery(KEY_PROGRAM_MISSION_QNA_DETAIL_INFO, () => {
      const newData: any = {
        type: 'qna',
        missionId: newMissionId,
      };
      return getProgramMissionDetailInfo(newData);
    });
  };

  const { refetch: programMissionQuestionRefetch } = useQuery(
    KEY_PROGRAM_MISSION_QNA_QUESTION_LIST,
    () => {
      console.log('missionId: ', missionId);
      if (missionId.length > 0) {
        const newData: any = {
          missionId: missionId,
        };
        return getProgramMissionQnaQuestionList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('mission question list :', res);
        if (res !== null && res.questionList !== null) {
          setQuestionList(res.questionList !== null ? res.questionList : []);
          setQuestionAnswerTotalPoint(
            res.questionList !== null ? res.questionList.length : 0
          );
          if (res.questionList !== null && res.questionList.length === 0) {
            if (questionAnswerInfo !== undefined && questionAnswerInfo !== null)
              makeDummyQuestion(
                tabValue === 0 ? 1 : 2,
                missionId,
                questionAnswerInfo.weekId
              );
            // setIsEdit(2);
          }
        } else if (missionId.length > 0) {
          reset();
          setOpenDialog(true);
          setDialogMessage('데이터가 없습니다.');
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchQuestionList = (newMissionId: string) => {
    queryClient.prefetchQuery(KEY_PROGRAM_MISSION_QNA_QUESTION_LIST, () => {
      const newData: any = {
        missionId: newMissionId,
      };
      return getProgramMissionQnaQuestionList(newData);
    });
  };

  const {
    mutate: deleteProgramCurriculumMissionMutate,
    isError: isMutateDeleteError,
  } = useMutation(putProgramCurriculumMissionDelete, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('삭제되었습니다.');
        reloadQuestionAnswer(missionId);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: deleteProgramCurriculumMissionQuestionMutate,
    isError: isMutateDeleteQuestionError,
  } = useMutation(putProgramCurriculumMissionQuestionDelete, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('삭제되었습니다.');
        if (!deleteAll) programMissionRefetch();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('삭제가 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: putMissionQnaQuestionIsRequiredMutate,
    isError: isRequriedMutateError,
  } = useMutation(putProgramMissionQnaQuestionIsRequired, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('변경되었습니다.');
        reloadQuestionAnswer(missionId);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('변경이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const { mutate: putMissionQnaTypeMutate, isError: isTypeMutateError } =
    useMutation(putProgramMissionQnaType, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('변경되었습니다.');
          reloadQuestionAnswer(missionId);
        } else {
          setOpenSnackbar(true);
          setDialogMessage(
            `변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage('변경이 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    });

  const {
    mutate: putMissionQnaQuestionInfoMutate,
    isError: isInfoMutateError,
  } = useMutation(putMissionQnaQuestionInfo, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('저장되었습니다.');
        reloadQuestionAnswer(missionId);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('저장이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const {
    mutate: changeQuestionAnswerMasterHasRewardMutate,
    isError: isHasRewardMutateError,
  } = useMutation(changeQuestionAnswerMasterHasReward, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('저장되었습니다.');
        reloadQuestionAnswer(missionId);
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage('저장이 실패했습니다. 다시 시도해주시기 바랍니다.');
    },
  });

  const registerCurriculumMission = () => {
    let isValid = 0;

    const newMissionId =
      missionId !== undefined && missionId.length > 0
        ? missionId
        : questionAnswerInfo.qnaId;

    const emptyTitleIndex = questionList.findIndex((q: any) => q.title === '');

    if (
      isValid === 0 &&
      (title === undefined || title === null || title.length === 0)
    ) {
      isValid = 1;
    }

    if (isValid === 0 && (point === undefined || point === null)) {
      isValid = 2;
    }

    if (isValid === 0 && qnaType === 2 && hasReward && minAnswer === 0) {
      isValid = 3;
    }

    if (isValid === 0 && newMissionId === '00000000000000000000000000000000') {
      isValid = 4;
    }

    if (isValid === 0 && emptyTitleIndex !== -1) {
      isValid = 5;
    }

    if (isValid === 0) {
      const newMissionData = {
        weekId: '',
        missionId: newMissionId,
        title: title,
        successScore: 10,
        successCount: 1,
        isRepeated: isRepeated,
        minAnswer: qnaType === 2 ? minAnswer : 0,
        type: qnaType,
      };
      putMissionQnaQuestionInfoMutate(newMissionData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '제목을 입력해 주세요';
          break;
        case 2:
          message = '달성시 획득 점수를 선택해주세요';
          break;
        case 3:
          message = '달성 인정 정답 개수를 입력해 주세요';
          break;
        case 4:
          message = '미션 아이디 오류, 화면 fresh 후 다시 시도해주세요';
          break;
        case 5:
          message = '질문을 작성해주세요';
          break;
        case 7:
          message = '커버 이미지를 등록해 주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenSnackbar(true);
      setDialogMessage(message);
    }
  };

  const checkValid = () => {
    setForcedSave(true);
    setIsEdit(disabled ? 1 : 2);
    registerCurriculumMission();
  };

  const updateMissionQnaType = (newType: number) => {
    const newData: any = {
      missionId: questionAnswerInfo.qnaId,
      type: newType,
    };
    putMissionQnaTypeMutate(newData);
  };

  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (isEdit === 1) return;

    console.log('onChangeTab tabValue :', tabValue, ', newValue:', newValue);
    if (newValue !== tabValue) {
      if (questionList.length > 0) {
        setOpenQnaConfirm(newValue + 1);
      } else {
        setQnaType(newValue + 1);
        updateMissionQnaType(newValue + 1);

        setTabValue(newValue);
        if (questionAnswerInfo !== undefined && questionAnswerInfo !== null) {
          makeDummyQuestion(
            newValue === 0 ? 1 : 2,
            questionAnswerInfo.qnaId,
            questionAnswerInfo.weekId
          );
        }
      }
    }
  };

  const reloadMission = (newMissionId: string) => {
    console.log('reloadMission :', newMissionId);
    prefetchQuestionList(newMissionId);
  };

  const [isPressed, setIsPressed] = useState(false);

  const deleteMissionQna = () => {
    setDeleteAll(false);
    const newMissionData = {
      weekId: weekId,
      missionId: missionId,
      type: 'qna',
    };
    console.log('deleteMissionQnaMutate :', newMissionData);
    deleteProgramCurriculumMissionMutate(newMissionData);
  };

  const changeQnaType = (newValue: number) => {
    setDeleteAll(true);
    questionList.map((item: any) => {
      const newMissionData = {
        weekId: weekId,
        missionId: item.missionId,
        type: 'qna',
      };
      console.log('deleteMissionQnaQuestionMutate :', newMissionData);
      deleteProgramCurriculumMissionQuestionMutate(newMissionData);
    });

    setQuestionList([]);
    makeDummyQuestion(
      newValue,
      questionAnswerInfo.qnaId,
      questionAnswerInfo.weekId
    );
  };

  const changeRequiredAnswer = (value: number) => {
    setRequiredAllAnswer(value);

    const newIds = questionList.map((item: any) => item.questionId);

    setRequiredAllAnswerInfo(prev => ({
      ...prev,
      ids: value === 1 ? [...newIds] : [''],
      requiredAll: value,
    }));

    const newData = {
      questionIds: newIds,
      isRequired: value === 1 ? 1 : 0,
    };
    putMissionQnaQuestionIsRequiredMutate(newData);
  };

  const changeHasReward = (value: number) => {
    setHasReward(value);
    const newQnaInfo = {
      qnaId: questionAnswerInfo.qnaId,
      hasReward: value,
    };
    changeQuestionAnswerMasterHasRewardMutate(newQnaInfo);
  };

  useEffect(() => {
    const newQuestionList = questionList.filter(q => q.title !== '');

    const isRequiredList = questionList.filter(q =>
      requiredAllAnswerInfo.ids.includes(q.questionId)
    );
    newQuestionList.length > 0 && requiredAllAnswerInfo.requiredAll === 1
      ? setRequiredAllAnswerSwitch(1)
      : setRequiredAllAnswerSwitch(0);
    if (
      newQuestionList.length > 0 &&
      isRequiredList !== null &&
      isRequiredList.length === questionList.length
    ) {
      setRequiredAllAnswerSwitch(1);
    }
  }, [requiredAllAnswerInfo]);

  useEffect(() => {
    if (
      questionList !== undefined &&
      questionList !== null &&
      questionList.length > 0
    ) {
      const newQuestionList = questionList.filter(q => q.title !== '');
      const isRequiredList = questionList.filter(q =>
        requiredAllAnswerInfo.ids.includes(q.questionId)
      );
      if (
        newQuestionList.length > 0 &&
        isRequiredList !== null &&
        isRequiredList.length === questionList.length
      ) {
        setRequiredAllAnswerSwitch(1);
      }
    }
  }, [questionList]);

  useEffect(() => {
    console.log('missionId :', missionId);
    if (missionId !== undefined && missionId !== null && missionId.length > 0) {
      programMissionRefetch();
      questionAnswerInfo.isEdit === 2
        ? setIsEdit(2)
        : setIsEdit(disabled ? 1 : 2);
    }
  }, [missionId]);

  useEffect(() => {
    if (questionAnswerInfo !== undefined && questionAnswerInfo !== null) {
      console.log('questionAnswerInfo -> :', questionAnswerInfo);
      setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);
      setMissionId(questionAnswerInfo.qnaId);
      setForcedSave(false);
      setQnaType(questionAnswerInfo.type);
      setMinAnswer(questionAnswerInfo.goalPoint);
      setQuestionAnswerTotalPoint(questionAnswerInfo.questionCount);
      setHasReward(questionAnswerInfo.hasReward);

      setDisabled(questionAnswerInfo.attendUserCount);
      setRequiredAllAnswerSwitch(-1);
    } else {
      setOpenSnackbar(true);
      setDialogMessage('질의응답을 선택 후 다시 시도해주시기 바랍니다.');
    }
  }, [questionAnswerInfo]);
  return (
    <>
      <CreatePaper sx={{ m: '0 0' }}>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>질의응답 유형 선택</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_QNA_QUESTION_TYPE}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <CreateTabs value={tabValue} onChange={onChangeTab}>
            <Tab
              disabled={disabled}
              key='0'
              sx={{ width: '30rem', margin: '1rem .5rem' }}
              label={
                <Box
                  className='flex_between'
                  sx={{
                    gap: '8px',
                    width: '100%',
                  }}
                >
                  <span>설문조사</span>
                  <DoneIcon />
                </Box>
              }
            />
            <Tab
              disabled={disabled}
              key='1'
              sx={{ width: '30rem', margin: '1rem .5rem' }}
              label={
                <Box
                  className='flex_between'
                  sx={{
                    gap: '8px',
                    width: '100%',
                  }}
                >
                  <span>퀴즈</span>
                  <DoneIcon />
                </Box>
              }
            />
          </CreateTabs>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>재답변 허용 여부</Typography>
          <RadioGroup
            row
            aria-labelledby='movie-row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            value={isRepeated}
            onChange={evt => {
              if (!disabled) {
                setIsRepeated(Number(evt.target.value));
              } else {
                setOpenSnackbar(true);
                setDialogMessage(
                  `질의응답 참여자가 있어서 정보를 변경할 수 없습니다.`
                );
              }
            }}
            sx={{ margin: '.5rem 1rem' }}
          >
            <FormControlLabel value='1' control={<Radio />} label='허용' />
            <FormControlLabel value='0' control={<Radio />} label='미허용' />
          </RadioGroup>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>리워드 발급 여부</Typography>
          <RadioGroup
            row
            aria-labelledby='movie-row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            value={hasReward}
            onChange={evt => {
              if (!disabled) {
                changeHasReward(Number(evt.target.value));
              } else {
                setOpenSnackbar(true);
                setDialogMessage(
                  `질의응답 참여자가 있어서 정보를 변경할 수 없습니다.`
                );
              }
            }}
            sx={{ margin: '.5rem 1rem' }}
          >
            <FormControlLabel value='1' control={<Radio />} label='발급' />
            <FormControlLabel value='0' control={<Radio />} label='미발급' />
          </RadioGroup>
          <Divider />
        </Box>
        {qnaType === 2 && hasReward === 1 && (
          <Box>
            <Typography sx={{ m: '1rem 0 ' }}>
              리워드 발급 기준 점수 <span style={{ color: '#F63454' }}>*</span>
            </Typography>
            <Box className='flex_start'>
              <Box sx={{ height: '2rem', mb: '1rem' }}>
                <TextField
                  disabled={disabled}
                  sx={{
                    marginRight: '1rem',
                    '& .MuiFormHelperText-root': {
                      color:
                        minAnswer <= questionAnswerTotalPoint
                          ? '#000000'
                          : '#F63454',
                    },
                  }}
                  value={minAnswer}
                  helperText={
                    minAnswer > questionAnswerTotalPoint
                      ? '질의응답 개수보다 높습니다.'
                      : ''
                  }
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    const newValue = checkNaN(
                      evt.target.value,
                      questionAnswerTotalPoint
                    );
                    setMinAnswer(
                      newValue <= questionAnswerTotalPoint
                        ? newValue
                        : questionAnswerTotalPoint
                    );
                  }}
                />
              </Box>
              <Box sx={{ marginRight: '1rem', height: '2rem', m: '1rem, 0' }}>
                <Typography>{`/ ${questionAnswerTotalPoint}`}</Typography>
              </Box>
            </Box>
            <Divider />
          </Box>
        )}
        {qnaType === 1 ? (
          <Box>
            <Box className='flex_end' sx={{ mb: '1rem' }}>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_REQUIRED_ALL_ANSWER}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
              <FormControlLabel
                value='start'
                control={
                  <Switch
                    disabled={disabled}
                    color='primary'
                    checked={requiredAllAnswerSwitch === 1}
                    value={requiredAllAnswerSwitch === 1}
                    onClick={evt => evt.stopPropagation()}
                    onChange={() => {
                      setRequiredAllAnswerSwitch(
                        requiredAllAnswerSwitch === 1 ? 0 : 1
                      );
                      changeRequiredAnswer(
                        requiredAllAnswerSwitch === 1 ? 0 : 1
                      );
                    }}
                  />
                }
                label='전체 필수 응답'
                labelPlacement='start'
              />
            </Box>
            <SwSurveyCreate
              requiredAllAnswer={requiredAllAnswer}
              missionInfo={questionAnswerInfo}
              questionList={questionList}
              setQuestionList={setQuestionList}
              isEdit={isEdit}
              onReloadMission={reloadMission}
              forcedSave={forcedSave}
              disabled={disabled}
              onDisabledButton={type => setDisabledButton(type)}
            />
          </Box>
        ) : (
          <Box>
            <SwQuizCreate
              missionInfo={questionAnswerInfo}
              questionList={questionList}
              setQuestionList={setQuestionList}
              isEdit={isEdit}
              onReloadMission={reloadMission}
              forcedSave={forcedSave}
              disabled={disabled}
              onDisabledButton={type => setDisabledButton(type)}
            />
          </Box>
        )}
        <Box>
          {isEdit === 1 ? (
            <Box className='flex_end'>
              {/* <Button
                sx={{ mr: '1rem' }}
                variant='outlined'
                color='info'
                size='small'
                onClick={deleteMissionQna}
              >
                삭제
              </Button> */}
              {/* <Button
                disabled={disabled}
                variant='contained'
                size='small'
                onClick={() => {
                  setIsEdit(2);
                }}
              >
                수정
              </Button> */}
            </Box>
          ) : isEdit === 2 ? (
            <Box className='flex_end'>
              {/* <Button
                disabled={disabledButton}
                sx={{ mr: '1rem' }}
                variant='outlined'
                color='info'
                size='small'
                onClick={doClose}
              >
                취소
              </Button> */}
              <Button
                disabled={disabled}
                variant='contained'
                size='small'
                onClick={checkValid}
              >
                정보 저장
              </Button>
            </Box>
          ) : (
            <Box className='flex_end'>
              {/* <Button
                sx={{ mr: '1rem' }}
                variant='outlined'
                color='info'
                size='small'
                onClick={doClose}
              >
                취소
              </Button> */}
              <Button
                disabled={disabled}
                variant='contained'
                size='small'
                onClick={checkValid}
              >
                정보 저장
              </Button>
            </Box>
          )}
        </Box>
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='질의응답 타입을 변경하실 경우, 작성한 질문이 모두 삭제됩니다. 그래도 변경하시겠습니까?'
        open={!!openQnaConfirm}
        onClose={() => setOpenQnaConfirm(0)}
        onConfirm={() => {
          setQnaType(openQnaConfirm);
          changeQnaType(openQnaConfirm);
          setTabValue(openQnaConfirm === 1 ? 0 : 1);
          updateMissionQnaType(openQnaConfirm === 1 ? 1 : 2);
          setOpenQnaConfirm(0);
        }}
        confirm={1}
      />
      {loading && <SwLoading />}
    </>
  );
};
