import { Box, styled, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { useResize } from '../../../hooks/resize';
import { TabBox } from '../../styles/Styles';

interface Props {}

export const Users: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState<any>(location.state);
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    const id = userData ? userData.id : '';
    const phoneno = userData ? userData.phoneno : '';

    switch (newValue) {
      case 1:
        setValue(1);
        navigate('userinquire', {
          replace: true,
          state: {
            id: id,
            phoneno: phoneno,
          },
        });
        break;
      case 2:
        setValue(2);
        navigate('userinfo', {
          replace: true,
          state: {
            id: id,
            phoneno: phoneno,
          },
        });
        break;
      case 3:
        setValue(3);
        navigate('usercert', {
          replace: true,
          state: {
            id: id,
            phoneno: phoneno,
          },
        });
        break;
      case 4:
        setValue(4);
        navigate('userreject', {
          replace: true,
          state: {
            id: id,
            phoneno: phoneno,
          },
        });
        break;
      default:
        setValue(0);
        navigate('/users');
    }
  };
  useEffect(() => {
    if (location.state) {
      setUserData(location.state);
    }
    const path = window.location.pathname;
    if (path.includes('/users/userinquire')) setValue(1);
    else if (path.includes('/users/userinfo')) setValue(2);
    else if (path.includes('/users/usercert')) setValue(3);
    else if (path.includes('/users/userreject')) setValue(4);
  }, [window.location.pathname]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>사용자 목록</title>
      </Helmet>
      <TabBox>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='사용자 조회' value={1} />
          <Tab label='사용자 정보' value={2} />
          <Tab label='인증 문자 기록' value={3} />
          <Tab label='앱 강제 차단' value={4} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
