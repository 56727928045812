import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import {
  getCommunityMemberList,
  putCommunityChangeMemberType,
  postCommunityMember,
} from '../../../common/api/ApiCommunity';
import { KEY_COMMUNITY_MEMBER_LIST, KEY_USER_LIST } from '../../../common/key';
import { getUserList } from '../../../common/api/ApiUser';
import { RESULT_OK } from '../../../common/resultCode';
import {
  encodeSearchWord,
  removeNatCodePhoneNumber,
} from '../../../common/helper';

interface Props {
  communityId: string;
  title: string;
  memberType: number;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isMember: (item: string) => boolean;
}

const MembersDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    minWidth: '44vw',
    minHeight: '60vh',
  },
});

const SelectChip = styled(Chip)({
  margin: '.5em',
  color: '#00A2FF',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const SearchResultPaper = styled(Paper)({
  margin: '1rem 0',
  padding: '1rem',
  widht: '100%',
  height: '50vh',
  overflowY: 'scroll',
});

const ChosenUserPaper = styled(Paper)({
  margin: '1rem 0 0',
  padding: '1rem',
  widht: '100%',
  height: '10vh',
  overflowY: 'scroll',
});

const selectVal = [
  {
    title: '아이디',
    value: 'memberId',
  },
  { title: '닉네임', value: 'memberName' },
  { title: '연락처', value: 'memberPhoneNumber' },
  { title: '이메일', value: 'memberEmail' },
];

export const CommunityMemberSetting: React.FC<Props> = ({
  communityId,
  title,
  memberType,
  open,
  onClose,
  onConfirm,
  isMember,
}) => {
  const inputRef = useRef<any>();
  const selectRef = useRef<any>(2);
  const searchRef = useRef<any>();
  const [communityMembers, setCommunityMembers] = useState<any>([]);
  const [chosenUser, setChosenUser] = useState<any>([]);
  const [filterMembers, setFilterMembers] = useState<any>(communityMembers);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const rowsPerPageOptions = [5, 8, 10, 15, 20];
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(null);

  const {
    data,
    isLoading,
    refetch: communityMemberRefetch,
  } = useQuery(
    KEY_COMMUNITY_MEMBER_LIST,
    () => {
      const searchKey = 'all';
      const searchWord = inputRef.current?.value;
      const newData = {
        communityId: communityId,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        page: page,
        rowsPerPage: 0,
      };
      if (
        communityId !== undefined &&
        communityId !== null &&
        communityId.length > 0
      )
        return getCommunityMemberList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          setCommunityMembers(
            res.member.filter((member: any) => member.type === 0)
          );
          setFilterMembers(communityMembers);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: refetchUsers } = useQuery(
    KEY_USER_LIST,
    () => {
      console.log('newSearchWord :', searchRef);
      const newSearchKey =
        selectRef.current !== undefined ? selectRef.current.value : '';
      const newSearchWord =
        searchRef.current !== undefined ? searchRef.current.value : '';
      if (newSearchWord !== undefined && newSearchWord !== '') {
        return getUserList(
          1,
          0,
          newSearchKey,
          encodeSearchWord(newSearchKey, newSearchWord)
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        setLoading(false);
        if (res !== null) {
          // console.log('res.user: ', res.user);
          const newUsers = res.user.map((user: any) => {
            const newUser = {
              userId: user.id,
              phoneno: removeNatCodePhoneNumber(user.phoneno),
              name: user.name,
            };
            return newUser;
          });
          setFilterMembers(newUsers);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const { mutate: putCommunityTypeMemberMutate, isError: isMutateNameError } =
    useMutation(putCommunityChangeMemberType, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('커뮤니티 멤버 타입을 변경했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '커뮤니티 멤버 타입 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const { mutate: postCommunityMemberMutate, isError: isMutateMemberError } =
    useMutation(postCommunityMember, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenDialog(true);
          setDialogMessage('커뮤니티 멤버를 추가했습니다.');
          onConfirm();
        } else {
          setOpenDialog(true);
          setDialogMessage(
            `커뮤니티 멤버 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          `커뮤니티 멤버 추가가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
        );
      },
    });

  const searchMemberUser = () => {
    const newSearchWord = inputRef.current?.value;
    const newMember =
      newSearchWord.length > 0
        ? communityMembers.filter((member: any, idx: number) => {
            const newCondition =
              member.name.includes(newSearchWord) ||
              member.phoneno.includes(newSearchWord) ||
              member.userId.includes(newSearchWord);
            return newCondition;
          })
        : communityMembers;
    // console.log('newMember : ', newMember);
    setFilterMembers(newMember);
  };

  const searchUser = () => {
    setLoading(true);
    refetchUsers();
  };

  const addMemberType = () => {
    const newUserIds = chosenUser.map((user: any) => user.userId);
    const newData = {
      communityId: communityId,
      type: memberType,
      userIds: newUserIds,
    };

    if (newUserIds.length === 0) {
      setDialogMessage(`멤버를 선택한 후 시도해 주시기 바랍니다.`);
      setOpenDialog(true);
      return;
    }
    // console.log('addMemberType newData: ', newData);
    if (memberType === 1) putCommunityTypeMemberMutate(newData);
    else postCommunityMemberMutate(newData);
  };

  const selectUser = (user: any) => {
    if (
      chosenUser.findIndex((chosen: any) => chosen.userId === user.id) === -1
    ) {
      console.log('selectUser user :', user, ', isMember :');
      if (isMember(user.phoneno)) {
        setDialogMessage(`이미 가입된 커뮤니티 멤버입니다..`);
        setOpenSnackbar(true);
      } else {
        const newUsers = [...chosenUser, user];
        setChosenUser(newUsers);
        console.log('chosenUser:', newUsers);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchMemberUser();
    }
  };

  useEffect(() => {
    if (open === true) {
      setFilterMembers([]);
      setChosenUser([]);
      if (memberType === 1) communityMemberRefetch();
    }
  }, [open]);

  return (
    <>
      <MembersDialog open={open} fullWidth>
        <DialogTitle className='flex_between'>
          <Box>{title} 추가하기</Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          {memberType === 1 ? (
            <TextField
              placeholder='닉네임, 아이디, 연락처로 검색해 주세요'
              fullWidth
              inputRef={inputRef}
              onKeyDown={evt => {
                handleKeyDown(evt);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() =>
                        memberType === 1 ? searchMemberUser() : searchUser()
                      }
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <SelectSearchBox
              searchOption={selectVal}
              searchWord={searchRef}
              defaultOption='memberPhoneNumber'
              isSearching={() => {
                memberType === 1 ? searchMemberUser() : searchUser();
              }}
              optionValue={selectRef}
            />
          )}
          <SearchResultPaper>
            {/* <EmptyPage /> */}
            <Box>
              {filterMembers !== undefined && filterMembers.length > 0 ? (
                filterMembers.map((user: any, idx: number) => (
                  <Paper
                    sx={{ p: '.5rem 0', m: '.3rem 0' }}
                    key={idx.toString()}
                  >
                    <Box className='flex_between' sx={{ m: '0 1rem' }}>
                      <Box className='flex_center'>
                        <Avatar src={user.profile} alt={user.name} />
                        <Box sx={{ ml: '1rem' }}>
                          <Typography>{user.name}</Typography>
                          <Typography>{user.phoneno}</Typography>
                        </Box>
                      </Box>
                      <Button
                        variant='outlined'
                        color='info'
                        value={user.userId}
                        disabled={
                          chosenUser.findIndex(
                            (chosen: any) => chosen.userId === user.userId
                          ) !== -1 || isMember(user.phoneno)
                        }
                        onClick={() => selectUser(user)}
                      >
                        선택
                      </Button>
                    </Box>
                  </Paper>
                ))
              ) : (
                <Typography>검색결과 없습니다.</Typography>
              )}
            </Box>
          </SearchResultPaper>
          <ChosenUserPaper>
            {chosenUser?.map((user: any, idx: number) => (
              <SelectChip
                label={user.name}
                variant='outlined'
                deleteIcon={<CloseIcon />}
                key={idx.toString()}
                onDelete={() => {
                  const newChosenUser = chosenUser.filter(
                    (_user: any, i: number) => i !== idx
                  );
                  setChosenUser(newChosenUser);
                }}
              />
            ))}
          </ChosenUserPaper>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          <Button variant='outlined' color='inherit' onClick={onClose}>
            취소
          </Button>
          <Button variant='contained' color='info' onClick={addMemberType}>
            추가하기
          </Button>
        </DialogActions>
        {loading && <SwLoading />}
      </MembersDialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
