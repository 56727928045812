import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Paper } from '@mui/material';

interface Props {}

export const EmptyPage: React.FC<Props> = () => (
  <Paper className='emptypage'>
    <ErrorOutlineIcon className='emptypage_icon' />
    <div>조회된 데이터가 없습니다.</div>
  </Paper>
);
