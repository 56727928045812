import {
  AppBar,
  Toolbar,
  Typography,
  styled,
  Avatar,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import { Dispatch, useState, useEffect } from 'react';
import DehazeOutlinedIcon from '@mui/icons-material/DehazeOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { useResize } from '../../hooks/resize';
import personIcon from '../../images/coolicon.svg';
import { useSession, setAuthorization } from '../../hooks/session';
import { ChangePassword } from './ChangePassword';

interface Props {
  openNav: boolean;
  setOpenNav: Dispatch<React.SetStateAction<boolean>>;
  param: string;
}

const TopBar = styled(AppBar)({
  position: 'sticky',
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  padding: 0,
  backgroundColor: 'rgba(255,255,255,0.5)',
  boxShadow:
    '0px 2px 4px -1px rgb(0 0 0 / 10%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
});

const HeaderAvatar = styled(Avatar)({
  width: '3rem',
  height: '1.1rem',
  borderRadius: '30px',
  margin: '.1em 1em 0 .7em',
  fontSize: '.8rem',
  fontWeight: 600,
});

export const Header: React.FC<Props> = ({ openNav, setOpenNav, param }) => {
  const { isMobile } = useResize();
  const { loginVal, logout, session } = useSession();
  const auth = (role: number | null) => {
    switch (role) {
      case 3:
        return <HeaderAvatar sx={{ bgcolor: '#FF537C' }}>마스터</HeaderAvatar>;
      case 2:
        return <HeaderAvatar sx={{ bgcolor: '#5891FF' }}>관리자</HeaderAvatar>;
      case 1:
        return <HeaderAvatar sx={{ bgcolor: '#00C21F' }}>일반</HeaderAvatar>;
      default:
        return <HeaderAvatar sx={{ bgcolor: '#785AEC' }}>테스트</HeaderAvatar>;
    }
  };

  const [menuElement, setMenuElement] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const openMenu = (evt: React.MouseEvent<HTMLButtonElement>) => {
    setMenuElement(evt.currentTarget);
  };
  const closeMenu = () => {
    setMenuElement(null);
  };

  useEffect(() => {
    setAuthorization(loginVal.value?.authorization);
  }, [loginVal.value?.authorization]);

  return (
    <TopBar
      color='default'
      className={clsx(
        { topbar_resize: isMobile() },
        { navigation: !isMobile() }
      )}
    >
      <Toolbar className='flex_between' variant='dense'>
        <div
          className={clsx(
            { toolbar_main: openNav },
            { toolbar_resize: !openNav }
          )}
        >
          {!!isMobile() && (
            <IconButton
              onClick={() => setOpenNav(!openNav)}
              sx={{ mt: '-.11em' }}
            >
              <DehazeOutlinedIcon />
            </IconButton>
          )}
          {param}
        </div>
        <div className='flex_center'>
          <img src={personIcon} alt='id' />
          <Typography component='span' sx={{ ml: '.5em', fontSize: '1.15em' }}>
            {loginVal.value?.user?.name}&nbsp;님
            {/* {loginVal.value?.user.name}&nbsp;님 */}
          </Typography>
          {auth(loginVal.value?.user.type)}
          {/* {auth(loginVal.value.user.role)} */}
          {!isMobile() && (
            <>
              <IconButton onClick={openMenu}>
                <ExpandMoreIcon />
              </IconButton>
              <Menu
                anchorEl={menuElement}
                open={!!menuElement}
                onClose={closeMenu}
                sx={{
                  '& .MuiMenu-list': {
                    // padding: 0,
                  },
                }}
              >
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    logout();
                  }}
                >
                  로그아웃
                </MenuItem>
                <MenuItem onClick={() => setOpenDialog(true)}>
                  비밀번호 변경
                </MenuItem>
              </Menu>
            </>
          )}
        </div>
        <ChangePassword
          open={openDialog}
          onClose={() => setOpenDialog(false)}
        />
      </Toolbar>
    </TopBar>
  );
};
