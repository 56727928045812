import {
  Box,
  Button,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, { Dispatch, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../../commonComponent/views/SwConfirmDialog';
import {
  postRewardDrawDrawing,
  postRewardChangeDrawResult,
  getRewardDrawUserDownload,
} from '../../../../common/api/ApiReward';
import {
  IReward,
  IRewardDrawing,
  IRewardDrawingUser,
} from '../../../../models/reward';
import { RESULT_OK } from '../../../../common/resultCode';
import {
  PROGRAM_REWARD_STATE_DRAWING,
  PROGRAM_REWARD_STATE_SUBMIT,
} from '../ProgramRewardDraw';
import { DrawTable } from './DrawTable';
import {
  getProgramRewardDrawUserDownload,
  postProgramRewardChangeDrawResult,
  postProgramRewardDrawDrawing,
} from '../../../../common/api/ApiProgram';

const column = ['번호', '이름', '커뮤니티 닉네임 사용 여부', '커뮤니티 닉네임'];

interface Props {
  rewardDrawUserList: any;
  setRewardDrawUserList: Dispatch<React.SetStateAction<any>>;
  rewardConditionList: any;
  setRewardConditionList: Dispatch<React.SetStateAction<any>>;
  changeState: any;
  setChangeState: Dispatch<React.SetStateAction<any>>;
  onRefresh: () => void;
}

export const ProgramRewardDrawing: React.FC<Props> = ({
  rewardDrawUserList,
  setRewardDrawUserList,
  rewardConditionList,
  setRewardConditionList,
  changeState,
  setChangeState,
  onRefresh,
}) => {
  const [programId, setProgramId] = useState<string>('');
  const [rewardId, setRewardId] = useState<string>('');
  const [rewardCount, setRewardCount] = useState<number>(0);
  const [allUserList, setAllUserList] = useState<any>([]);
  const [applyUserList, setApplyUserList] = useState<any>([]);
  const [winnerUserList, setWinnerUserList] = useState<any>([]);
  const [nonwinnerUserList, setNonwinnerUserList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [openDrawing, setOpenDrawing] = useState<boolean>(false);
  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const { mutate: postDrawDrawingMutation, isError: isMutateError } =
    useMutation(postProgramRewardDrawDrawing, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setChangeState(PROGRAM_REWARD_STATE_SUBMIT);
          setOpenSnakerDialog(true);
          setDialogMessage('추첨을 진행했습니다.');
        } else {
          setOpenDialog(true);
          setDialogMessage(`추첨 실패 : ${res.resultCodeMsg}`);
        }
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '추첨하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const { mutate: postDrawChangeResultMutation, isError: isMutateChangeError } =
    useMutation(postProgramRewardChangeDrawResult, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnakerDialog(true);
          setDialogMessage('상태를 변경했습니다.');
          onRefresh();
        } else {
          setOpenDialog(true);
          setDialogMessage(`상태변경 실패 : ${res.resultCodeMsg}`);
        }
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '상태를 변경하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const downloadDrawUser = (type: string) => {
    getProgramRewardDrawUserDownload(rewardId, type);
  };

  const drawing = () => {
    const count = applyUserList.length;
    const newData: any = {
      programId: rewardDrawUserList.programId,
      rewardId: rewardDrawUserList.rewardId,
      preDrawingCount: winnerUserList.length,
      rewardCount: rewardCount > 0 ? rewardCount : 0,
    };
    const hasBiResult = allUserList
      .filter((user: any) => user.hasBiResult === 1)
      .map((user: any) => user.phoneno);
    console.log('hasBiResult:', hasBiResult);
    if (count === 0 && winnerUserList.length === 0) {
      setOpenDialog(true);
      setDialogMessage(
        `추첨 대상자가 없습니다. 조건적용하기를 다시 시도해주세요`
      );
      return;
    }
    if (hasBiResult.length > 0) {
      setOpenDialog(true);
      setDialogMessage(
        `사전 당첨자와 사전 미당첨자에 중복 번호가 있습니다 : ${hasBiResult}`
      );
      return;
    }
    console.log('postDrawDrawingMutation: ', newData);
    postDrawDrawingMutation(newData);
  };

  const onDelete = (type: number, phoneno: string) => {
    console.log('onDelete type:', type, 'phoneno: ', phoneno);
    const newData = {
      rewardId: rewardId,
      phonenos: [phoneno],
      state: type,
    };
    postDrawChangeResultMutation(newData);
  };

  // useEffect(() => {
  //   if (changeState === REWARD_STATE_DRAWING && rewardConditionList) {
  //     console.log('Drawing rewardConditionList :', rewardConditionList);
  //     if (rewardDrawUserList?.all.length > 0) {
  //       const newApplyUserList = rewardDrawUserList.all.filter((user: any) => {
  //         const unexcept = rewardConditionList.unexcept?.filter((item: any) => {
  //           if (item.phoneno === user.phoneno) return true;
  //           return false;
  //         });
  //         const except = rewardConditionList.except?.filter((item: any) => {
  //           if (item.phoneno === user.phoneno) return true;
  //           return false;
  //         });
  //         if (
  //           (unexcept === null || unexcept?.length === 0) &&
  //           (except === null || except?.length === 0)
  //         )
  //           return true;
  //         return false;
  //       });
  //       setApplyUserList(newApplyUserList);
  //       setWinnerUserList(
  //         rewardConditionList.unexcept?.map((item: any) => {
  //           const newItem = { name: item.name, phoneno: item.phoneno };
  //           return newItem;
  //         })
  //       );
  //       setNonwinnerUserList(
  //         rewardConditionList.except?.map((item: any) => {
  //           const newItem = { name: item.name, phoneno: item.phoneno };
  //           return newItem;
  //         })
  //       );
  //     }
  //   }
  // }, [rewardConditionList]);

  useEffect(() => {
    console.log(
      'Drawing rewardDrawUserList changeState :',
      changeState,
      ',',
      rewardDrawUserList
    );
    if (
      (changeState === PROGRAM_REWARD_STATE_DRAWING ||
        changeState === PROGRAM_REWARD_STATE_SUBMIT) &&
      rewardDrawUserList
    ) {
      console.log('Drawing rewardDrawUserList :', rewardDrawUserList);
      if (rewardDrawUserList?.all.length > 0) {
        setProgramId(rewardDrawUserList.programId);
        setRewardId(rewardDrawUserList.rewardId);
        setRewardCount(rewardDrawUserList.rewardCount);
        setAllUserList(rewardDrawUserList.all);
        const newApplyUserList = rewardDrawUserList.all.filter((user: any) => {
          const unexcept = rewardDrawUserList.unexcept?.filter((item: any) => {
            if (item.phoneno === user.phoneno) return true;
            return false;
          });
          const except = rewardDrawUserList.except?.filter((item: any) => {
            if (item.phoneno === user.phoneno) return true;
            return false;
          });
          if (
            (unexcept === null || unexcept?.length === 0) &&
            (except === null || except?.length === 0)
          )
            return true;
          return false;
        });
        setApplyUserList(newApplyUserList);
        setWinnerUserList(
          rewardDrawUserList.unexcept?.map((item: any) => {
            const newItem = {
              name: item.name,
              phoneno: item.phoneno,
              biResult: item.hasBiResult,
              nickName: item.nickName,
              useNickName: item.useNickName,
              deleted: item.deleted,
            };
            return newItem;
          })
        );
        setNonwinnerUserList(
          rewardDrawUserList.except?.map((item: any) => {
            const newItem = {
              name: item.name,
              phoneno: item.phoneno,
              biResult: item.hasBiResult,
              nickName: item.nickName,
              useNickName: item.useNickName,
              deleted: item.deleted,
            };
            return newItem;
          })
        );
      } else {
        setWinnerUserList([]);
        setNonwinnerUserList([]);
      }
    } else {
      setProgramId(rewardDrawUserList.programId);
      setRewardId(rewardDrawUserList.rewardId);
      setApplyUserList([]);
      setWinnerUserList([]);
      setNonwinnerUserList([]);
    }
  }, [rewardDrawUserList]);

  return (
    <>
      <Paper sx={{ m: '2rem 0 ', p: '2rem' }}>
        <Box sx={{ mb: '4rem' }}>
          <Typography variant='h6'>2. 추첨하기</Typography>
          <Typography sx={{ color: '#9EA2A9', mb: '1.5rem' }}>
            추첨 조건에 속한 참여자는 추첨 대상자 목록에서 제외됩니다.
          </Typography>

          <Paper sx={{ m: '1rem 0', p: '1rem' }}>
            <Box className='flex_between'>
              <Typography sx={{ mb: '1.5rem' }}>
                추첨 대상자 목록{' '}
                <span style={{ color: '#2581FF' }}>{applyUserList.length}</span>
                명
              </Typography>
              <Button
                color='info'
                variant='outlined'
                onClick={() => downloadDrawUser('all')}
              >
                데이터 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
            </Box>
            <Box>
              <DrawTable
                columns={column}
                rows={applyUserList}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
              />
            </Box>
          </Paper>
          <Paper sx={{ ml: '0', mt: '3rem', p: '1rem' }}>
            <Box className='flex_between'>
              <Typography sx={{ mb: '1.5rem' }}>
                사전 당첨자 목록{' '}
                <span style={{ color: '#2581FF' }}>
                  {winnerUserList.length}
                </span>
                명
              </Typography>
              <Button
                color='info'
                variant='outlined'
                onClick={() => downloadDrawUser('unexcept')}
              >
                데이터 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
            </Box>
            <DrawTable
              columns={column}
              rows={winnerUserList}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              onDelete={(phoneno: string) => {
                onDelete(0, phoneno);
              }}
            />
          </Paper>
          <Paper sx={{ ml: '0', mt: '3rem', p: '1rem' }}>
            <Box className='flex_between'>
              <Typography sx={{ mb: '1.5rem' }}>
                사전 미당첨자 목록
                <span style={{ color: '#2581FF' }}>
                  {' '}
                  {nonwinnerUserList.length}
                </span>
                명
              </Typography>
              <Button
                color='info'
                variant='outlined'
                onClick={() => downloadDrawUser('except')}
              >
                데이터 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
            </Box>
            <DrawTable
              columns={column}
              rows={nonwinnerUserList}
              rowsPerPage={rowsPerPage}
              page={page}
              handleChangePage={handleChangePage}
              onDelete={(phoneno: string) => {
                onDelete(1, phoneno);
              }}
            />
          </Paper>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: '3.5rem' }}>
          <Button
            variant='contained'
            onClick={() => {
              changeState === PROGRAM_REWARD_STATE_SUBMIT
                ? setOpenDrawing(true)
                : drawing();
            }}
          >
            추첨하기
          </Button>
        </Box>
      </Paper>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='추첨 내역이 있습니다. 다시 추첨하시겠습니까?'
        open={openDrawing}
        onClose={() => setOpenDrawing(false)}
        onConfirm={() => {
          drawing();
          setOpenDrawing(false);
        }}
        confirm={1}
      />
    </>
  );
};
