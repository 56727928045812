import { Box, Pagination } from '@mui/material';
import React from 'react';

interface Props {
  page: number;
  handleChangePage: (_event: any, newPage: number) => void;
  count: any;
  sibling?: number;
}

export const SwPagination: React.FC<Props> = ({
  page,
  handleChangePage,
  count,
  sibling = 3,
}) => {
  const a = 1;
  return (
    <>
      <Box sx={{ p: '.6rem', display: 'flex', justifyContent: 'center' }}>
        <Pagination
          color='primary'
          variant='outlined'
          siblingCount={sibling}
          page={page}
          onChange={handleChangePage}
          count={count}
        />
      </Box>
    </>
  );
};
