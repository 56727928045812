import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Drawer,
  MenuItem,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { ChangeEvent, Dispatch, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import tabItems from './tabList.json';
import { useSession } from '../../hooks/session';
import { useResize } from '../../hooks/resize';
import useClasses from '../../hooks/useClasses';

const LeftTab = styled(Drawer)({
  '& .MuiDrawer-paper': {
    width: '18em',
    boxSizing: 'border-box',
    borderRadius: 6,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
});

const TabAccordion = styled(Accordion)({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-root.Mui-expanded': {
    minHeight: 'inherit',
    borderLeft: '3px solid #2581FF',
    backgroundColor: 'rgba(37, 129, 255, 0.04)',
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: '15px 0',
  },
  borderRadius: 1,
});

const TabTitle = styled(Typography)({
  fontSize: '.8rem',
  fontWeight: 500,
  color: '#999999',
  margin: '1rem',
  marginBottom: '.5rem',
});

const TabButton = styled(Button)({
  color: 'inherit',
  fontSize: '.85rem',
  textAlign: 'left',
  padding: '.5rem 1.5rem',
  justifyContent: 'flex-start',
});

const styles = {
  isDevTab: {
    '& .MuiDrawer-paper': {
      backgroundColor: '#2C313D !important',
      color: '#FFFFFF',
    },
  },
  defaultDashboard: {
    padding: '2rem 1rem',
    paddingBottom: '1rem',
    cursor: 'pointer',
  },
  dashboard: {
    backgroundColor: '#F6F7F8',
  },
};

const SELECT_BOX_WIDTH = '15rem';

const tabList: any[] = tabItems;

interface Props {
  menuMap: any;
  onSelected: (node: any) => void;
}

export const SwSelectOnMenuMap: React.FC<Props> = ({ menuMap, onSelected }) => {
  const { logout } = useSession();
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const classes = useClasses(styles);
  const [selectedCategory1, setSelectedCategory1] = useState<number>(-1);
  const [selectedCategory2, setSelectedCategory2] = useState<number>(-1);
  const [selectedCategory3, setSelectedCategory3] = useState<number>(-1);
  const [selectedCategory4, setSelectedCategory4] = useState<number>(-1);
  const [depth2Nodes, setDepth2Nodes] = useState<any[]>([]);
  const [depth3Nodes, setDepth3Nodes] = useState<any[]>([]);
  const [depth4Nodes, setDepth4Nodes] = useState<any[]>([]);

  const doSelected = (node: any, menuPath: string[], list: any) => {
    const newNode = { ...node };
    newNode.menuPath = menuPath;
    newNode.node = list;
    newNode.nodeType = 'node';
    onSelected(newNode);
  };

  useEffect(() => {
    let newNode: any = {
      title: '',
      id: '',
      nodeList: [],
      contentCount: 0,
    };
    if (
      selectedCategory1 !== -1 &&
      selectedCategory2 === -1 &&
      selectedCategory3 === -1 &&
      selectedCategory4 === -1
    ) {
      newNode = { ...menuMap[selectedCategory1] };
    } else if (
      selectedCategory1 !== -1 &&
      selectedCategory2 !== -1 &&
      selectedCategory3 === -1 &&
      selectedCategory4 === -1
    ) {
      newNode = { ...depth2Nodes[selectedCategory2] };
    } else if (
      selectedCategory1 !== -1 &&
      selectedCategory2 !== -1 &&
      selectedCategory3 !== -1 &&
      selectedCategory4 === -1
    ) {
      newNode = { ...depth3Nodes[selectedCategory2] };
    } else if (
      selectedCategory1 !== -1 &&
      selectedCategory2 !== -1 &&
      selectedCategory3 !== -1 &&
      selectedCategory4 !== -1
    ) {
      newNode = { ...depth4Nodes[selectedCategory2] };
    }

    if (newNode.contentCount === 0 && newNode.nodeList.length > 0) {
      newNode = {
        title: '',
        id: '',
        nodeList: [],
        contentCount: 0,
      };
    }
    onSelected(newNode);
  }, [
    selectedCategory1,
    selectedCategory2,
    selectedCategory3,
    selectedCategory4,
  ]);

  return (
    <Box className='flex_start'>
      <TextField
        select
        sx={{ width: SELECT_BOX_WIDTH }}
        value={selectedCategory1}
        onChange={(
          event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
        ) => {
          const newIdx = Number(event.target.value);
          setSelectedCategory1(newIdx);
          if (newIdx !== -1) {
            const newNode = menuMap[newIdx];
            if (newNode.contentCount === 0 && newNode.nodeList.length > 0) {
              setSelectedCategory2(-1);
              setDepth2Nodes(newNode.nodeList);
            } else {
              setDepth2Nodes([]);
            }
            setSelectedCategory3(-1);
            setSelectedCategory4(-1);
            setDepth3Nodes([]);
            setDepth4Nodes([]);
          }
        }}
      >
        <MenuItem key={`menu_depth_1_${0}`} value={-1}>
          선택
        </MenuItem>
        {menuMap.map((list: any, idx: number) => (
          <MenuItem key={`menu_depth_1_${idx + 1}`} value={idx}>
            {list.title}
          </MenuItem>
        ))}
      </TextField>
      {depth2Nodes.length > 0 && (
        <TextField
          select
          sx={{ width: SELECT_BOX_WIDTH, ml: '.5rem' }}
          value={selectedCategory2}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const newIdx = Number(event.target.value);
            setSelectedCategory2(newIdx);
            if (newIdx !== -1) {
              const newNode = depth2Nodes[newIdx];
              if (newNode.contentCount === 0 && newNode.nodeList.length > 0) {
                setDepth3Nodes(newNode.nodeList);
              } else {
                setDepth3Nodes([]);
              }
              setSelectedCategory4(-1);
              setDepth4Nodes([]);
            }
          }}
        >
          <MenuItem key={`menu_depth_2_${0}`} value={-1}>
            선택
          </MenuItem>
          {depth2Nodes.map((list: any, idx: number) => (
            <MenuItem key={`menu_depth_2_${idx + 1}`} value={idx}>
              {list.title}
            </MenuItem>
          ))}
        </TextField>
      )}
      {depth3Nodes.length > 0 && (
        <TextField
          select
          sx={{ width: SELECT_BOX_WIDTH, ml: '.5rem' }}
          value={selectedCategory3}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const newIdx = Number(event.target.value);
            setSelectedCategory3(newIdx);
            if (newIdx !== -1) {
              const newNode = depth3Nodes[newIdx];
              if (newNode.contentCount === 0 && newNode.nodeList.length > 0) {
                setDepth4Nodes(newNode.nodeList);
              } else {
                setDepth4Nodes([]);
              }
            }
          }}
        >
          <MenuItem key={`menu_depth_3_${0}`} value={-1}>
            선택
          </MenuItem>
          {depth3Nodes.map((list: any, idx: number) => (
            <MenuItem key={`menu_depth_3_${idx + 1}`} value={idx}>
              {list.title}
            </MenuItem>
          ))}
        </TextField>
      )}
      {depth4Nodes.length > 0 && (
        <TextField
          select
          sx={{ width: SELECT_BOX_WIDTH, ml: '.5rem' }}
          value={selectedCategory4}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            const newIdx = Number(event.target.value);
            setSelectedCategory4(newIdx);
          }}
        >
          <MenuItem key={`menu_depth_4_${0}`} value={-1}>
            선택
          </MenuItem>
          {depth4Nodes.map((list: any, idx: number) => (
            <MenuItem key={`menu_depth_4_${idx + 1}`} value={idx}>
              {list.title}
            </MenuItem>
          ))}
        </TextField>
      )}
    </Box>
  );
};
