import {
  Box,
  Button,
  Collapse,
  MenuItem,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { Dispatch, useEffect, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import clsx from 'clsx';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DateTextField,
  SelectTextField,
  TableTopButton,
} from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';

interface Props {
  columns: any[];
  rows: any[];
  rowsPerPage: number;
  page: number;
  handleChangePage: (_event: any, newPage: number) => void;
  onDelete?: (phoneno: string) => void;
}

const ColumsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  whiteSpace: 'nowrap',
});

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  maxWidth: '10rem',
});

const RowsNumberCell = styled(TableCell)({
  fontSize: '.88rem',
  textAlign: 'center',
  fontWeight: 400,
  padding: '.6rem',
  whiteSpace: 'nowrap',
});

export const DrawTable: React.FC<Props> = ({
  columns,
  rows,
  rowsPerPage,
  page,
  handleChangePage,
  onDelete,
}) => {
  //   const [page, setPage] = useState(1);
  //   const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  //   const handleChangePage = (_event: any, newPage: number) => {
  //     setPage(newPage);
  //   };
  //   const rowsPerPageOptions = [5, 10, 15, 20];
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <ColumsCell key={idx.toString()}>{column}</ColumsCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((row: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  {columns.length === 5 && (
                    <RowsCell
                      key={0}
                      className={
                        row.deleted === 1 ? clsx('not_important_row') : ''
                      }
                    >
                      {(page - 1) * rowsPerPage + idx + 1}
                    </RowsCell>
                  )}
                  <RowsCell
                    key={1}
                    className={
                      row.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {row.phoneno}
                  </RowsCell>
                  <RowsCell
                    key={2}
                    className={
                      row.deleted === 1
                        ? clsx('not_important_row')
                        : row.biResult
                        ? clsx('specified_row')
                        : ''
                    }
                  >
                    {row.name}
                    {onDelete !== undefined && row.biResult ? (
                      <ClearIcon
                        className='clear_icon_button'
                        onClick={() => onDelete && onDelete(row.phoneno)}
                      />
                    ) : (
                      ''
                    )}
                  </RowsCell>
                  <RowsCell
                    key={3}
                    className={
                      row.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {row.nickName === null
                      ? '-'
                      : row.useNickName === 1
                      ? 'O'
                      : '-'}
                  </RowsCell>
                  <RowsCell
                    key={4}
                    className={
                      row.deleted === 1 ? clsx('not_important_row') : ''
                    }
                  >
                    {row.nickName !== null ? row.nickName : '-'}
                  </RowsCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SwPagination
        page={page}
        handleChangePage={handleChangePage}
        count={Math.ceil(rows.length / rowsPerPage)}
      />
    </>
  );
};
