import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grow,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { SwDateRangePicker } from '../commonComponent/dateSetting/SwDateRangePicker';
import { SwPagination } from '../commonComponent/tableElement/SwPagination';
import { SelectSearchBox } from '../commonComponent/SelectSearchBox';
import { getCommunityDailyStepList } from '../../common/api/ApiCommunity';
import {
  KEY_OPERATING_METRICS_NEW_COMMUNITY,
  KEY_COMMUNITY_DAILYSTEP_LIST,
} from '../../common/key';
import { ICommunityReqeust } from '../../models/community';
import {
  timestamp2string,
  getYearList,
  getMonthList,
  commaFormat,
  timestamp2Localestring,
} from '../../common/helper';
import {
  getOperatingMetrics,
  downloadOperatingMetrics,
} from '../../common/api/ApiOperatingMetrics';
import { IOperatingMetrics } from '../../models/operating_metrics';

const columns = ['구분'];

interface Props {
  metricsType: string;
}

export const CommunityMetrics: React.FC<Props> = ({ metricsType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const yearList = getYearList();
  const monthList = getMonthList();
  const currentYear = new Date(Date.now()).getFullYear();
  const currentMonth = new Date(Date.now()).getMonth() + 1;
  const [selectedYear, setSelectedYear] = useState<string>(
    `${currentYear.toString()}년`
  );
  const [selectedMonth, setSelectedMonth] = useState<string>(
    `${currentMonth.toString()}월`
  );
  const [title, setTitle] = useState<string>('');
  const [communityData, setCommunityData] = useState<any>(location.state);
  const selectRef = useRef<any>();
  const searchRef = useRef<any>();
  const checkBoxAllRef = useRef<any>();
  const [openFilter, setOpenFilter] = useState(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentRow, setCurrentRow] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dataList, setDataList] = useState<any>([]);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [communityId, setCommunityId] = useState<string>('');
  const [dateTitles, setDateTitles] = useState<any>([]);
  const [statType, setStatType] = useState<any>('community');
  const [statSubType, setStatSubType] = useState<any>('5');
  const [checkSearch, setCheckSearch] = useState<any>(true);

  const {
    data,
    isLoading,
    refetch: operatingMetricsRefetch,
  } = useQuery(
    KEY_OPERATING_METRICS_NEW_COMMUNITY,
    () => {
      if (checkSearch) {
        const newData: IOperatingMetrics = {
          statType: statType,
          statSubType: statSubType,
          year: selectedYear.replace('년', ''),
          month: selectedMonth.replace('월', ''),
        };
        return getOperatingMetrics(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('dailystep :', res);
        if (res != null) {
          setDataList(res.data);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = () => {
    queryClient.prefetchQuery(KEY_OPERATING_METRICS_NEW_COMMUNITY, () => {
      const newData: IOperatingMetrics = {
        statType: statType,
        statSubType: '',
        year: selectedYear.replace('년', ''),
        month: selectedMonth.replace('월', ''),
      };
      return getOperatingMetrics(newData);
    });
  };

  const makeDateTitle = () => {
    var newTitles: string[] = [];

    // month list
    for (var j = 1; j <= 12; j += 1) {
      newTitles.push(`${j}`);
    }

    setDateTitles(newTitles);
  };

  const convertDate = (timestamp: any): string => {
    const newDate = timestamp2Localestring(timestamp);
    return newDate.split(' ')[0];
  };

  const getSubType = (subType: any): string =>
    subType === '0'
      ? '개인'
      : subType === '1'
      ? '공식'
      : subType === 'total'
      ? '전체'
      : '';

  const onDownloadFile = () => {
    const newData: IOperatingMetrics = {
      statType: statType,
      statSubType: statSubType,
      year: selectedYear.replace('년', ''),
      month: selectedMonth.replace('월', ''),
    };
    downloadOperatingMetrics(newData);
  };

  useEffect(() => {
    makeDateTitle();
    operatingMetricsRefetch();
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    setTitle('신규 커뮤니티 수');
  }, [metricsType]);

  return (
    <>
      <Paper sx={{ p: '1.5rem' }}>
        <Box>
          <Typography>{title}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            mb: '1rem',
          }}
        >
          <Box className='flex_between'>
            <Box className='flex_between'>
              <Button
                color='info'
                variant='outlined'
                sx={{ ml: '1rem' }}
                onClick={onDownloadFile}
              >
                데이터 다운로드
                <FileDownloadRoundedIcon color='success' />
              </Button>
            </Box>
          </Box>
          <Box className='flex_between' sx={{ ml: '0.8rem', mt: '0.8rem' }}>
            <TextField
              sx={{ width: '10rem', mr: '1rem' }}
              select
              value={selectedYear}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setSelectedYear(event.target.value);
              }}
            >
              {yearList.map((option: any, idx: number) => (
                <MenuItem key={idx} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column: any, idx: number) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
                {dateTitles.map((column: any, idx: number) => (
                  <TableCell
                    key={(idx + columns.length).toString()}
                    align='center'
                  >
                    {`${column}월`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {getSubType(row.subType)}
                  </TableCell>
                  {dateTitles.map((column: any, i: number) => (
                    <TableCell key={(idx + i + 1).toString()} align='center'>
                      {commaFormat(
                        String(row[column] !== undefined ? row[column] : '-')
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
};
