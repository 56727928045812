import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import {
  KEY_CHALLENGE_FILTER_LOAD_LIST,
  KEY_DATAPORTAL_ACCOUNT_LIST,
} from '../../common/key';
import { getDataPortalAccountList } from '../../common/api/ApiDataPortal';
import { ICommunityReqeust } from '../../models/community';
import { SwSnackbar } from './views/SwSnackbar';
import { getCommunityListByPortal } from '../../common/api/ApiCommunity';
import { getChallengeCommunityFilterLoadList } from '../../common/api/ApiChallenge';
import { ageFormat } from '../../common/helper';

interface Props {
  open: boolean;
  onclose: () => void;
  setFilterLoad: Dispatch<React.SetStateAction<any>>;
}

export const SwLoadPortalFilter: React.FC<Props> = ({
  open,
  onclose,
  setFilterLoad,
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [title, setTitle] = useState<string>('');
  const [findDataportal, setFindDataportal] = useState<any>();
  const [requestList, setRequestList] = useState<any>([]);
  const [communityList, setCommunityList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [showEmptyResult, setShowEmptyResult] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  var portalId = '';

  const makeFilterData = (res: any) => {
    const filterData: any[] = res.filter.map((filter: any, idx: number) => {
      var communityFilter: any = [];
      if (filter.communityFilter !== null) {
        const parsedData = JSON.parse(filter.communityFilter);
        communityFilter = parsedData.map((item: any) => {
          const newData = {
            filterType: 'community',
            communityName: item.communityName,
            targetId: item.communityId,
          };
          return newData;
        });
      }

      var groupFilter: any = [];
      if (filter.groupFilter !== null) {
        const parsedData = JSON.parse(filter.groupFilter);
        groupFilter = parsedData.map((item: any) => {
          const newData = {
            filterType: 'group',
            communityName: item.groupName,
            targetId: item.groupId,
          };
          return newData;
        });
      }

      var locationFilter: any = [];
      if (filter.locationFilter !== null) {
        const parsedData = JSON.parse(filter.locationFilter);
        locationFilter = parsedData.map((item: any) => {
          const newData = {
            amd: item.amdCode,
            location: item.locationName,
          };
          return newData;
        });
      }

      var regionFilter: any = [];
      if (filter.regionFilter !== null) {
        const parsedData = JSON.parse(filter.regionFilter);
        regionFilter = parsedData.map((item: any) => item.regionName);
      }

      var ageFilter: any = [];
      if (filter.ageFilter !== null) {
        const parsedData = JSON.parse(filter.ageFilter);
        ageFilter = ageFormat(parsedData);
      }

      return {
        community: communityFilter,
        gender: Number(filter.genderFilter),
        group: groupFilter,
        location: locationFilter,
        region: regionFilter,
        age: ageFilter,
      };
    });

    return filterData;
  };

  const {
    data,
    isLoading,
    refetch: communityRefetch,
  } = useQuery(
    KEY_CHALLENGE_FILTER_LOAD_LIST,
    () => {
      if (portalId.length > 0) {
        return getChallengeCommunityFilterLoadList(portalId);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('SwLoadPortalFilter FilterLoad res:', res);
        if (res && res.filter) {
          const newCommunity = makeFilterData(res);
          console.log('makeFilterData portalId:', portalId, ',', newCommunity);
          setRequestList(newCommunity);
          setShowEmptyResult(true);
          // setTotalRecords(res.pagination.totalRecordCount);
        } else {
          setShowEmptyResult(false);
        }
      },
      onError: e => {
        setShowEmptyResult(false);
        console.log(e);
      },
    }
  );

  const searchCommunity = () => {
    portalId = findDataportal;
    if (findDataportal.length === 0) {
      setOpenSnackbar(true);
      setSnackbarMessage('검색할 포털 계정 아이디를 입력해주세요.');
      return;
    }
    communityRefetch();
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchCommunity();
    }
  };

  useEffect(() => {
    if (searchResult !== null && searchResult !== undefined) {
      console.log('searchResult:', searchResult);
      portalId = searchResult.id;
      communityRefetch();
    }
    return () => {};
  }, [searchResult]);

  useEffect(() => {
    if (open) {
      setFindDataportal('');
      setRequestList([]);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onclose}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box>
              <Typography>공개 대상 불러오기</Typography>
            </Box>
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box>
            <Typography sx={{ mb: '.5rem', p: 0 }}>데이터 포탈 계정</Typography>
            <TextField
              placeholder='포탈 계정 아이디로 검색해 주세요.'
              value={findDataportal || ''}
              onChange={event => setFindDataportal(event.target.value)}
              onKeyDown={handleKeyDown}
              sx={{ width: '26rem', mr: '1em' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      sx={{ pr: 0 }}
                      onClick={() => searchCommunity()}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <TableContainer sx={{ height: '40vh', overflowY: 'scroll' }}>
              <Table stickyHeader>
                <TableBody>
                  {requestList.length > 0 ? (
                    requestList.map((item: any, idx: number) => (
                      <TableRow
                        key={idx.toString()}
                        onClick={() => {
                          setFilterLoad(item);
                          onclose();
                        }}
                      >
                        <TableCell key={idx.toString()} sx={{ p: '.7rem' }}>
                          <Typography sx={{ fontWeight: 500 }}>
                            공개 커뮤니티 및 그룹
                          </Typography>
                          {item.community.length > 0 ? (
                            item.community.map((filter: any, i: number) => (
                              <Typography
                                sx={{ ml: '1rem' }}
                                key={(i + 100).toString()}
                              >
                                커뮤니티 : {filter.communityName}, ID:
                                {filter.targetId}
                              </Typography>
                            ))
                          ) : item.community.length === 0 &&
                            item.group.length === 0 ? (
                            <Typography
                              sx={{ ml: '1rem' }}
                              key={(idx + 100).toString()}
                            >
                              -
                            </Typography>
                          ) : (
                            <></>
                          )}
                          {item.group.length > 0 &&
                            item.group.map((filter: any, i: number) => (
                              <Typography
                                sx={{ ml: '1rem' }}
                                key={(i + 200).toString()}
                              >
                                그룹 : {filter.communityName}, ID:
                                {filter.targetId}
                              </Typography>
                            ))}
                          <Typography sx={{ fontWeight: 500 }}>
                            위치 제한
                          </Typography>
                          {item.location.length > 0 ? (
                            item.location.map((filter: any, i: number) => (
                              <Typography
                                sx={{ ml: '1rem' }}
                                key={(i + 300).toString()}
                              >
                                {filter.location}
                              </Typography>
                            ))
                          ) : (
                            <Typography
                              sx={{ ml: '1rem' }}
                              key={(idx + 300).toString()}
                            >
                              -
                            </Typography>
                          )}
                          <Typography sx={{ fontWeight: 500 }}>
                            지역 제한
                          </Typography>
                          {item.region.length > 0 ? (
                            item.region.map((filter: any, i: number) => (
                              <Typography
                                sx={{ ml: '1rem' }}
                                key={(i + 400).toString()}
                              >
                                {filter}
                              </Typography>
                            ))
                          ) : (
                            <Typography
                              sx={{ ml: '1rem' }}
                              key={(idx + 400).toString()}
                            >
                              -
                            </Typography>
                          )}
                          <Typography sx={{ fontWeight: 500 }}>성별</Typography>
                          <Typography sx={{ ml: '1rem' }}>
                            {item.gender === 0
                              ? '-'
                              : item.gender === 1
                              ? '남성'
                              : '여성'}
                          </Typography>
                          <Typography sx={{ fontWeight: 500 }}>나이</Typography>
                          <Typography sx={{ ml: '1rem' }}>
                            {item.age.length > 0
                              ? item.age.join(',').replaceAll(',~,', '~')
                              : '-'}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : showEmptyResult ? (
                    <TableRow>
                      <TableCell colSpan={1}>검색결과 없습니다.</TableCell>
                    </TableRow>
                  ) : (
                    <></>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={onclose}
          >
            닫기
          </Button>
          <Button variant='contained' color='info' sx={{ fontSize: '.87rem' }}>
            불러오기
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => {
          setOpenSnackbar(false);
          setSnackbarMessage('');
        }}
        contents={snackbarMessage}
      />
    </>
  );
};
