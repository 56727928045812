import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import koLocale from 'date-fns/locale/ko';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { MultilineTextField } from '../../styles/Styles';

interface Props {
  open: boolean;
  edit: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: MutableRefObject<any>;
  content: MutableRefObject<any>;
  rangeDate: any[];
  setRangeDate: Dispatch<SetStateAction<any[]>>;
}

export const AccessRestrictionDrawUp: React.FC<Props> = ({
  open,
  edit,
  onClose,
  onConfirm,
  title,
  content,
  rangeDate,
  setRangeDate,
}) => {
  const a = 1;
  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          minHeight: '56vh',
          minWidth: '44vw',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <Typography variant='h6'>
            {edit ? '접속 제한 수정' : '접속 제한 작성'}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent sx={{ p: '1.5rem 2.6em' }}>
        <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>적용 일시</Typography>
        <SwDateRangePicker rangeDate={rangeDate} setRangeDate={setRangeDate} />
        <Divider />
        <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>제목</Typography>
        <TextField fullWidth inputRef={title} />
        <Divider />
        <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>내용</Typography>
        <MultilineTextField multiline rows={8} fullWidth inputRef={content} />
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        <Button onClick={onClose} color='error' variant='outlined'>
          닫기
        </Button>
        <Button onClick={onConfirm} color='error' variant='contained'>
          저장
        </Button>
      </DialogActions>
    </Dialog>
  );
};
