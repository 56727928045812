import { Box, Tab, Tabs } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { TabBox } from '../../styles/Styles';
import { useResize } from '../../../hooks/resize';

interface Props {}

export const PortalGuideManage: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('guidelist');
        break;
      case 2:
        setValue(2);
        navigate('categorymanage');
        break;
      default:
        setValue(0);
        navigate('/portalguidemanage');
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/portalguidemanage/guidelist') setValue(1);
    else if (path === '/portalguidemanage/categorymanage') setValue(2);
  }, [window.location.pathname]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>포털 설명서 관리</title>
      </Helmet>
      <TabBox>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='목록' value={1} />
          <Tab label='카테고리' value={2} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
