import {
  Box,
  Button,
  Divider,
  MenuItem,
  Paper,
  Select,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { NoneBorderChip, SwSwitch } from '../../../styles/Styles';
import { SwSelectLocation } from '../../../commonComponent/SwSelectLocation';
import { SwSelectGeohashGroup } from '../../../commonComponent/SwSelectGeohashGroup';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../../commonComponent/views/SwConfirmDialog';
import { SwSearchTarget } from '../../../commonComponent/SwSearchTarget';
import {
  ageFormat,
  ageUnFormat,
  getRegionAmdCode,
  getRegionCategory,
  splitLocaitonLimit,
} from '../../../../common/helper';
import { RegionFilterMap } from '../../../challenge/regionalFilter/RegionFilterMap';
import {
  ButtonBox,
  QuestionAnswerTypeBox,
  QuestionAnswerTypeTitle,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './QuestionAnswerStyles';
import { SwLoadPortalFilter } from '../../../commonComponent/SwLoadPortalFilter';
import {
  KEY_CHALLENGE_COMMUNITY_FILTER_INFO,
  KEY_CHALLENGE_COMMUNITY_FILTER_LIST,
  KEY_PROGRAM_COMMUNITY_FILTER_INFO,
  KEY_PROGRAM_FILTER_INFO,
} from '../../../../common/key';
import {
  getQuestionAnswerCommunityFilterList,
  getQuestionAnswerCommunityFilterInfo,
  registerQuestionAnswerFilter,
  getQuestionAnswerFilterInfo,
} from '../../../../common/api/ApiQuestionAnswer';
import { SwSearchCommunityFilter } from '../../../commonComponent/SwSearchCommunityFilter';

interface Props {
  questionAnswerInfo: any;
  setQuestionAnswerInfo: Dispatch<React.SetStateAction<any>>;
  reloadQuestionAnswer: (id: string) => void;
}

const CommunityBox = styled(Box)({
  margin: '.4rem .4rem .4rem 0',
  color: '#777777',
  display: 'flex',
  alignItems: 'center',
});

export const QuestionAnswerOpenRegion: React.FC<Props> = ({
  questionAnswerInfo,
  setQuestionAnswerInfo,
  reloadQuestionAnswer,
}) => {
  const [filterList, setFilterList] = useState<any>([]);
  const [communityFilterList, setCommunityFilterList] = useState<any>([]);
  const [challengeFilterList, setChallengeFilterList] = useState<any>([]);
  const [programFilterList, setProgramFilterList] = useState<any>([]);
  const [regionLimit, setRegionLimit] = useState<string[]>([]);
  const [authRegionLimit, setAuthRegionLimit] = useState<any[]>([]);
  const [saveAuthRegionLimit, setSaveAuthRegionLimit] = useState<any[]>([]);
  const [locationLimit, setLocationLimit] = useState<any[]>([]);
  const [saveLocationLimit, setSaveLocationLimit] = useState<any[]>([]);
  const [geohashGroupLimit, setGeohashGroupLimit] = useState<any>([]);
  const [geohashList, setGeohashList] = useState<any>([]);
  const [geohashData, setGeohashData] = useState<any>([]);
  const [openTarget, setOpenTarget] = useState<boolean>(false);
  const [gender, setGender] = useState<any>(0);
  const [age, setAge] = useState<any>([]);
  const [targetId, setTargetId] = useState<any>('');
  const [filterLoad, setFilterLoad] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [openSwitchCommunity, setOpenSwitchCommunity] =
    useState<boolean>(false);
  const [openSwitchChallenge, setOpenSwitchChallenge] =
    useState<boolean>(false);
  const [openSwitchProgram, setOpenSwitchProgram] = useState<boolean>(false);
  const [openSwitchAuthRegionRestrict, setOpenSwitchAuthRegionRestrict] =
    useState<boolean>(false);
  const [openSwitchLocation, setOpenSwitchLocation] = useState<boolean>(false);
  const [openSwitchGender, setOpenSwitchGender] = useState<boolean>(false);
  const [openSwitchAge, setOpenSwitchAge] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [searchTarget, setSearchTarget] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('community');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchWordCommunity, setSearchWordCommunity] = useState<string>('');
  const [searchWordChallenge, setSearchWordChallenge] = useState<string>('');
  const [searchWordProgram, setSearchWordProgram] = useState<string>('');
  const [openSearchCommunity, setOpenSearchCommunity] =
    useState<boolean>(false);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchCommunity, setSearchCommunity] = useState<any[]>([]);
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');

  const makeFilterInfo = (data: any) => {
    const newFilters =
      data.targetFilters !== undefined && data.targetFilters.length > 0
        ? JSON.parse(data.targetFilters)
        : [];
    console.log('newFilters :', newFilters);
    const newCommunityFilter: any[] = [];
    const newChallengeFilter: any[] = [];
    const newProgramFilter: any[] = [];

    newFilters &&
      newFilters.map((item: any) => {
        if (item.filter_type === '1' || item.filter_type === '2') {
          const newData = {
            filterType: item.filter_type === '1' ? 'community' : 'group',
            qnaId: item.questionAnswer_id,
            targetId: item.target_id,
            communityName: item.name,
          };
          newCommunityFilter.push(newData);
        }
        if (item.filter_type === '3') {
          const newData = {
            filterType: 3,
            qnaId: item.questionAnswer_id,
            id: item.target_id,
            name: item.name,
          };
          newChallengeFilter.push(newData);
        }
        if (item.filter_type === '4') {
          const newData = {
            filterType: 4,
            qnaId: item.questionAnswer_id,
            id: item.target_id,
            name: item.name,
          };
          newProgramFilter.push(newData);
        }
      });
    setCommunityFilterList(newCommunityFilter);
    setOpenSwitchCommunity(newCommunityFilter.length > 0);
    setChallengeFilterList(newChallengeFilter);
    setOpenSwitchChallenge(newChallengeFilter.length > 0);
    setProgramFilterList(newProgramFilter);
    setOpenSwitchProgram(newProgramFilter.length > 0);

    const newAuthRegionLimit =
      data.authRegions !== undefined && data.authRegions.length > 0
        ? JSON.parse(data.authRegions)
        : [];
    const newAuthRegion =
      newAuthRegionLimit &&
      newAuthRegionLimit.map((item: any) => {
        const newData = {
          location: item.target_address,
          amd: item.target_amd,
        };
        return newData;
      });
    setSaveAuthRegionLimit(newAuthRegion);
    setOpenSwitchAuthRegionRestrict(newAuthRegionLimit.length > 0);

    const newLocationList =
      data.locationFilters !== undefined && data.locationFilters.length > 0
        ? JSON.parse(data.locationFilters)
        : [];
    const newLocation =
      newLocationList &&
      newLocationList.map((item: any) => {
        const newData = {
          location: item.target_address,
          amd: item.target_amd,
        };
        return newData;
      });
    setSaveLocationLimit(newLocation);

    const newGeohashData =
      data.geohash !== undefined && data.geohash.length > 0
        ? JSON.parse(data.geohash)
        : [];

    if (data.geohash === 0) {
      setGeohashData([]);
      setRegionLimit([]);
    } else {
      setGeohashData([newGeohashData]);
    }
    // console.log('questionAnswerInfo.geohash :', questionAnswerInfo.geohash);
    // console.log('newGeohashData.length :', newGeohashData.length);
    // console.log('newLocationList.length :', newLocationList.length);
    setOpenSwitchLocation(
      newGeohashData.length > 0 || newLocationList.length > 0
    );

    const newRegionFilterList =
      data.regionFilters !== undefined && data.regionFilters.length > 0
        ? JSON.parse(data.regionFilters)
        : [];
    const newRegion =
      newRegionFilterList &&
      newRegionFilterList.map((item: any) => item.target_address);
    setRegionLimit(newRegion);

    var isSaved = false;
    const newGeohashGroupLimit =
      newRegionFilterList &&
      newRegionFilterList.map((item: any) => {
        const geo = {
          load: true,
          name: item.target_address,
          id: item.target_id,
          group: isSaved === false ? data.geohash : '',
          creator: '',
          createDate: '',
        };
        isSaved = true;
        return geo;
      });
    setGeohashGroupLimit(newGeohashGroupLimit);

    const newAttendFilters =
      data.attendFilters !== undefined && data.attendFilters.length > 0
        ? JSON.parse(data.attendFilters)
        : [];
    const newGenderFilter =
      newAttendFilters !== undefined && newAttendFilters !== null
        ? newAttendFilters
            .filter(
              (item: any) =>
                item.filter_type === '1' &&
                (item.filter_target === 1 || item.filter_target === 2)
            )
            .map((item: any) => {
              const newData = {
                filterType: item.filter_type,
                filterTarget: item.filter_target,
              };
              return newData;
            })
        : null;
    const newAgeFilter =
      newAttendFilters !== undefined && newAttendFilters !== null
        ? newAttendFilters
            .filter((item: any) => item.filter_type === '2')
            .map((item: any) => {
              const newData = {
                filterType: item.filter_type,
                filterTarget: item.filter_target,
              };
              return newData;
            })
        : null;
    const newGender =
      newGenderFilter !== null && newGenderFilter.length > 0
        ? newGenderFilter[0].filterTarget
        : 0;
    const newAge =
      newAgeFilter !== null && newAgeFilter.length > 0
        ? newAgeFilter[0].filterTarget
        : null;
    setGender(newGender);
    setOpenSwitchGender(newGenderFilter !== null && newGenderFilter.length > 0);
    const newFormatAge = ageFormat(newAge);
    setAge(newFormatAge.join(',').replaceAll(',~,', '~'));
    setOpenSwitchAge(newAgeFilter !== null && newAgeFilter.length > 0);
    setTargetId('');
  };

  const { refetch: questionAnswerFilterInfoRefetch } = useQuery(
    KEY_PROGRAM_FILTER_INFO,
    () => {
      const newTargetId = questionAnswerInfo?.qnaId;
      console.log('questionAnswerFilterInfoRefetch targetId: ', newTargetId);
      if (newTargetId !== undefined && newTargetId !== null)
        return getQuestionAnswerFilterInfo(newTargetId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('questionAnswerFilterInfoRefetch res:', res);
        if (res !== null) {
          makeFilterInfo(res.filter);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: questionAnswerCommunityFilterInfoRefetch } = useQuery(
    KEY_PROGRAM_COMMUNITY_FILTER_INFO,
    () => {
      const newTargetId = searchWord;
      console.log(
        'questionAnswerCommunityFilterInfoRefetch targetId: ',
        newTargetId
      );
      if (searchTarget)
        return getQuestionAnswerCommunityFilterInfo(newTargetId);
      return null;
    },
    {
      onSuccess: res => {
        setSearchTarget(false);
        console.log('questionAnswerCommunityFilterInfoRefetch res:', res);
        if (res !== null) {
          const newData = [...communityFilterList, ...res.filter];
          setCommunityFilterList(newData);
        }
      },
      onError: e => {
        setSearchTarget(false);
        console.log(e);
      },
    }
  );

  const {
    mutate: registerQuestionAnswerFilterMutation,
    isError: isFilterMutateError,
  } = useMutation(registerQuestionAnswerFilter, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage('질의응답 필터를 저장하였습니다.');
      reloadQuestionAnswer(questionAnswerInfo?.qnaId);
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '질의응답 필터를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteFilter = (index: number, from: string) => {
    if (from === 'community') {
      const newSomething = communityFilterList.filter(
        (_val: any, i: number) => i !== index
      );
      setCommunityFilterList([...newSomething]);
    } else if (from === 'challenge') {
      const newSomething = challengeFilterList.filter(
        (_val: any, i: number) => i !== index
      );
      setChallengeFilterList([...newSomething]);
    } else if (from === 'program') {
      const newSomething = programFilterList.filter(
        (_val: any, i: number) => i !== index
      );
      setProgramFilterList([...newSomething]);
    }
  };

  const deleteLocation = (index: number, from: string) => {
    const deleted = saveLocationLimit[index].location;
    const newLocation = saveLocationLimit.filter(
      (_val: any, i: number) => _val.location !== deleted
    );
    if (newLocation.length !== saveLocationLimit.length)
      setSaveLocationLimit([...newLocation]);
  };

  const deleteAuthRegion = (index: number, from: string) => {
    const deleted = saveAuthRegionLimit[index].location;
    const newLocation = saveAuthRegionLimit.filter(
      (_val: any, i: number) => _val.location !== deleted
    );
    if (newLocation.length !== saveAuthRegionLimit.length)
      setSaveAuthRegionLimit([...newLocation]);
  };

  const deleteRegion = (index: number, from: string) => {
    const deleted = regionLimit[index];
    setRegionLimit([]);

    console.log('deleteRegion regionLimit : ', regionLimit);
    console.log('deleteRegion geohashGroupLimit: ', geohashGroupLimit);
    const newGeohash = geohashGroupLimit.filter(
      (_val: any, i: number) => _val.name !== deleted
    );
    console.log('deleteRegion newGeohash : ', newGeohash);
    if (newGeohash.length !== geohashGroupLimit.length) {
      setGeohashGroupLimit([...newGeohash]);
    }
  };

  const deleteAllLocation = () => {
    setSaveLocationLimit([]);
  };

  const deleteAllAuthRegion = () => {
    setSaveAuthRegionLimit([]);
  };

  const deleteAllRegion = () => {
    setRegionLimit([]);
    setGeohashGroupLimit([]);
  };

  const addCommunity = () => {
    console.log('addCommunity');
    setSearchTarget(true);
  };

  function getFilterCount() {
    const newFilters = openSwitchCommunity
      ? communityFilterList.map((item: any) => {
          const newData = {
            filterType: item.filterType,
            targetId: item.targetId,
          };
          return newData;
        })
      : [];
    const newGeohashs = openSwitchLocation
      ? geohashList.map((item: any) => {
          const newData = {
            filterType: 1,
            targetHash: item,
          };
          return newData;
        })
      : [];
    const newRegions = openSwitchLocation
      ? geohashGroupLimit.map((item: any) => {
          const newData = {
            filterType: 2,
            filterTarget: item.id,
            targetAddress: item.name,
          };
          return newData;
        })
      : [];
    const newLocation = openSwitchLocation
      ? saveLocationLimit.map((item: any) => {
          const newData = {
            filterType: 1,
            targetAddress: item.location,
            targetAmd: item.amd,
          };
          return newData;
        })
      : [];
    const newAges = openSwitchAge
      ? ageUnFormat(age.replaceAll('~', ',~,').split(','))
      : [];
    const newGender = openSwitchGender ? gender : 0;

    if (
      newFilters.length === 0 &&
      newGeohashs.length === 0 &&
      newLocation.length === 0 &&
      newAges.length === 0 &&
      newGender === 0
    ) {
      return false;
    }
    return true;
  }

  const addFilter = () => {
    console.log('addFilter geohashGroupLimit: ', geohashGroupLimit);
    const newCommunityFilters = openSwitchCommunity
      ? communityFilterList.map((item: any) => {
          const newData = {
            filterType: item.filterType === 'community' ? 1 : 2,
            targetId: item.targetId,
          };
          return newData;
        })
      : [];

    const newChallengeFilters = openSwitchChallenge
      ? challengeFilterList.map((item: any) => {
          const newData = {
            filterType: 3,
            targetId: item.id,
          };
          return newData;
        })
      : [];

    const newProgramFilters = openSwitchProgram
      ? programFilterList.map((item: any) => {
          const newData = {
            filterType: 4,
            targetId: item.id,
          };
          return newData;
        })
      : [];

    const newGeohashs = openSwitchLocation
      ? geohashList.map((item: any) => {
          const newData = {
            filterType: 1,
            targetHash: item,
          };
          return newData;
        })
      : [];
    const newRegions = openSwitchLocation
      ? geohashGroupLimit.map((item: any) => {
          const newData = {
            filterType: 2,
            filterTarget: item.id,
            targetAddress: item.name,
          };
          return newData;
        })
      : [];
    const newLocation = openSwitchLocation
      ? saveLocationLimit.map((item: any) => {
          const newData = {
            filterType: 1,
            targetAddress: item.location,
            targetAmd: item.amd,
          };
          return newData;
        })
      : [];
    const newAuthRegion = openSwitchAuthRegionRestrict
      ? saveAuthRegionLimit.map((item: any) => {
          const newData = {
            targetType: 0,
            targetAddress: item.location,
            targetAmd: item.amd,
          };
          return newData;
        })
      : [];
    const newAges = openSwitchAge
      ? ageUnFormat(age.replaceAll('~', ',~,').split(','))
      : [];
    const newGender = openSwitchGender ? gender : 0;

    const newData: any = {
      qnaId: questionAnswerInfo?.qnaId,
      filters: [
        ...newCommunityFilters,
        ...newChallengeFilters,
        ...newProgramFilters,
      ],
      geohashs: newRegions.length > 0 ? newGeohashs : [],
      region: newRegions,
      location: newLocation,
      authRegions: newAuthRegion,
      ages: newAges,
      gender: newGender,
    };

    if (newData.qnaId === undefined) {
      setOpenSnackbar(true);
      setAlertMessage('필터를 추가할 질의응답을 선택하세요');
      return;
    }

    // if (newData.filters.length === 0) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('추가할 필터가 없습니다.');
    //   return;
    // }
    console.log('addFilter newData :', newData);
    registerQuestionAnswerFilterMutation(newData);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      if (type === 'community') {
        setSearchKey('community');
        setSearchWord(searchWordCommunity);
        setOpenSearchCommunity(true);
      } else if (type === 'challenge' || type === 'program') {
        setSearchKey(type);
        setSearchWord(
          type === 'challenge'
            ? searchWordChallenge
            : type === 'program'
            ? searchWordProgram
            : ''
        );
        setOpenSearchResult(true);
      }
    }
  };

  useEffect(() => {
    if (searchTarget) questionAnswerCommunityFilterInfoRefetch();
  }, [searchTarget]);

  useEffect(() => {
    const newFilterList = communityFilterList.map((item: any) => item.targetId);
    const newSearchResult = searchCommunity.filter(
      (item: any) => !newFilterList.includes(item.targetId)
    );
    const newData = [...communityFilterList, ...newSearchResult];
    setCommunityFilterList(newData);
    return () => {};
  }, [searchCommunity]);

  // useEffect(() => {
  //   setRegionLimit([]);
  //   const newGeohash = geohashGroupLimit.map(
  //     (_val: any, i: number) => _val.name
  //   );
  //   setRegionLimit([...locationLimit, ...newGeohash]);
  // }, [locationLimit]);

  useEffect(() => {
    setSaveLocationLimit([...saveLocationLimit, ...locationLimit]);
  }, [locationLimit]);

  useEffect(() => {
    setSaveAuthRegionLimit([...saveAuthRegionLimit, ...authRegionLimit]);
  }, [authRegionLimit]);

  useEffect(() => {
    // setRegionLimit([]);
    if (geohashGroupLimit.length > 0) {
      const isLoad = geohashGroupLimit.filter(
        (_val: any, i: number) => _val.load === undefined
      );

      const newGeohash = geohashGroupLimit.map(
        (_val: any, i: number) => _val.name
      );
      const newGeohashData = geohashGroupLimit
        .filter((_val: any, i: number) => _val.group !== '')
        .map((_val: any, i: number) =>
          _val.group === '' ? '' : JSON.parse(_val.group)
        );
      setGeohashData(newGeohashData);
      setRegionLimit([...newGeohash]);
    }
  }, [geohashGroupLimit]);

  useEffect(() => {
    console.log('setFilterLoad: ', filterLoad);
    if (filterLoad !== undefined) {
      var newData: any[] = [];
      newData.push(...filterLoad.community);
      newData.push(...filterLoad.group);
      setCommunityFilterList(newData);
      setOpenSwitchCommunity(
        filterLoad?.community.length > 0 || filterLoad?.group.length > 0
      );
      setSaveLocationLimit(filterLoad.location);

      if (filterLoad.region.length === 0) {
        setGeohashData([]);
        setRegionLimit([]);
      } else {
        setGeohashData([]);
      }
      // console.log('newGeohashData.length :', newGeohashData.length);
      // console.log('newLocationList.length :', newLocationList.length);
      setOpenSwitchLocation(
        filterLoad.region.length > 0 || filterLoad.location.length > 0
      );

      setGender(filterLoad.gender);
      setOpenSwitchGender(filterLoad.gender > 0);

      setAge(filterLoad.age.join(',').replaceAll(',~,', '~'));
      setOpenSwitchAge(filterLoad.age !== null && filterLoad.age.length > 0);
    }
  }, [filterLoad]);

  useEffect(() => {
    console.log('searchResult :', searchResult);
    if (searchResult.length > 0) {
      if (searchKey === 'challenge') {
        const newSearchResult = [...challengeFilterList, ...searchResult];
        setChallengeFilterList(newSearchResult);
      } else if (searchKey === 'program') {
        const newSearchResult = [...programFilterList, ...searchResult];
        setProgramFilterList(newSearchResult);
      }
    }
  }, [searchResult]);

  useEffect(() => {
    if (questionAnswerInfo) {
      console.log(
        'QuestionAnswerOpenRegion questionAnswerInfo :',
        questionAnswerInfo
      );
      questionAnswerFilterInfoRefetch();
    }
  }, [questionAnswerInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Button variant='contained' onClick={() => setOpenTarget(true)}>
        공개 대상 불러오기
      </Button>
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center'>
          <Typography sx={{ mr: '.4rem' }}>커뮤니티 및 그룹</Typography>
          <SwSwitch
            checked={openSwitchCommunity}
            onChange={() => setOpenSwitchCommunity(!openSwitchCommunity)}
          />
        </Box>
        <Box sx={{ display: 'flex', m: '.8rem 0' }}>
          <TextField
            sx={{ width: '22rem', mb: 0, mr: '.3rem' }}
            placeholder='커뮤니티 및 그룹 아이디를 입력해 주세요.'
            disabled={!openSwitchCommunity}
            value={searchWordCommunity}
            onKeyDown={e => handleKeyDown(e, 'community')}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWordCommunity(evt.target.value)}
          />
          <Button
            variant='contained'
            disabled={!openSwitchCommunity}
            onClick={() => {
              setSearchKey('community');
              setSearchWord(searchWordCommunity);
              setOpenSearchCommunity(true);
            }}
          >
            조회
          </Button>
        </Box>
        <Paper
          sx={{
            backgroundColor: '#FCFCFC',
            p: '2rem 2rem 1rem',
          }}
        >
          <Box sx={{ height: '8rem', overflowY: 'scroll' }}>
            <Typography fontWeight={500}>공개 커뮤니티 및 그룹</Typography>
            {communityFilterList &&
              communityFilterList.map((item: any, idx: number) => (
                <CommunityBox key={idx.toString()}>
                  <Typography>
                    {item.filterType === 'community'
                      ? `커뮤니티 : ${item.communityName}, ID:${item.targetId}`
                      : `그룹    : ${item.communityName}, ID:${item.targetId}`}
                  </Typography>
                  <ClearIcon
                    className='clear_icon_button'
                    onClick={() => deleteFilter(idx, 'community')}
                  />
                </CommunityBox>
              ))}
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              color='error'
              onClick={() => {
                setCommunityFilterList([]);
              }}
            >
              <DeleteOutlineIcon />
              전체 삭제
            </Button>
          </Box>
        </Paper>
      </Box>
      <Divider />
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center'>
          <Typography sx={{ mr: '.4rem' }}>챌린지</Typography>
          <SwSwitch
            checked={openSwitchChallenge}
            onChange={() => setOpenSwitchChallenge(!openSwitchChallenge)}
          />
        </Box>
        <Box sx={{ display: 'flex', m: '.8rem 0' }}>
          <TextField
            sx={{ width: '22rem', mb: 0, mr: '.3rem' }}
            placeholder='챌린지 이름 또는 아이디를 입력해 주세요.'
            disabled={!openSwitchChallenge}
            value={searchWordChallenge}
            onKeyDown={e => handleKeyDown(e, 'challenge')}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWordChallenge(evt.target.value)}
          />
          <Button
            variant='contained'
            disabled={!openSwitchChallenge}
            onClick={() => {
              setSearchKey('challenge');
              setSearchWord(searchWordChallenge);
              setOpenSearchResult(true);
            }}
          >
            조회
          </Button>
        </Box>
        <Paper
          sx={{
            backgroundColor: '#FCFCFC',
            p: '2rem 2rem 1rem',
          }}
        >
          <Box sx={{ height: '8rem', overflowY: 'scroll' }}>
            <Typography fontWeight={500}>챌린지</Typography>
            {challengeFilterList &&
              challengeFilterList.map((item: any, idx: number) => (
                <CommunityBox key={idx.toString()}>
                  <Typography>
                    {`챌린지 : ${item.name}, ID:${item.id}`}
                  </Typography>
                  <ClearIcon
                    className='clear_icon_button'
                    onClick={() => deleteFilter(idx, 'challenge')}
                  />
                </CommunityBox>
              ))}
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              color='error'
              onClick={() => {
                setChallengeFilterList([]);
              }}
            >
              <DeleteOutlineIcon />
              전체 삭제
            </Button>
          </Box>
        </Paper>
      </Box>
      <Divider />
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center'>
          <Typography sx={{ mr: '.4rem' }}>프로그램</Typography>
          <SwSwitch
            checked={openSwitchProgram}
            onChange={() => setOpenSwitchProgram(!openSwitchProgram)}
          />
        </Box>
        <Box sx={{ display: 'flex', m: '.8rem 0' }}>
          <TextField
            sx={{ width: '22rem', mb: 0, mr: '.3rem' }}
            placeholder='프로그램 이름 또는 아이디를 입력해 주세요.'
            disabled={!openSwitchProgram}
            value={searchWordProgram}
            onKeyDown={e => handleKeyDown(e, 'program')}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWordProgram(evt.target.value)}
          />
          <Button
            variant='contained'
            disabled={!openSwitchProgram}
            onClick={() => {
              setSearchKey('program');
              setSearchWord(searchWordProgram);
              setOpenSearchResult(true);
            }}
          >
            조회
          </Button>
        </Box>
        <Paper
          sx={{
            backgroundColor: '#FCFCFC',
            p: '2rem 2rem 1rem',
          }}
        >
          <Box sx={{ height: '8rem', overflowY: 'scroll' }}>
            <Typography fontWeight={500}>프로그램</Typography>
            {programFilterList &&
              programFilterList.map((item: any, idx: number) => (
                <CommunityBox key={idx.toString()}>
                  <Typography>
                    {`프로그램 : ${item.name}, ID:${item.id}`}
                  </Typography>
                  <ClearIcon
                    className='clear_icon_button'
                    onClick={() => deleteFilter(idx, 'program')}
                  />
                </CommunityBox>
              ))}
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Button
              color='error'
              onClick={() => {
                setProgramFilterList([]);
              }}
            >
              <DeleteOutlineIcon />
              전체 삭제
            </Button>
          </Box>
        </Paper>
      </Box>
      <Divider />
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center' sx={{ mb: '1rem' }}>
          <Typography sx={{ mr: '.4rem' }}>
            동네(프로필 인증 지역) 제한
          </Typography>
          <SwSwitch
            checked={openSwitchAuthRegionRestrict}
            onChange={() =>
              setOpenSwitchAuthRegionRestrict(!openSwitchAuthRegionRestrict)
            }
          />
        </Box>
        <Box>
          <SwSelectLocation
            limitKu={true}
            includeAmd={true}
            selectOne={true}
            showButton={false}
            showDepth1={false}
            location={authRegionLimit}
            setLocation={setAuthRegionLimit}
            showContainer={false}
            disabled={!openSwitchAuthRegionRestrict}
            mapId='authRegion_map'
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>위치</Typography>
          {saveAuthRegionLimit.length > 0 &&
            saveAuthRegionLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={`${getRegionAmdCode(location.amd)} ${splitLocaitonLimit(
                  location.location
                )}`}
                variant='outlined'
                onDelete={() => deleteAuthRegion(idx, 'authRegionLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              disabled={!openSwitchAuthRegionRestrict}
              onClick={() => deleteAllAuthRegion()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
      </Box>
      <Box sx={{ p: '1.5rem 0' }}>
        <Box className='flex_center' sx={{ mb: '1rem' }}>
          <Typography sx={{ mr: '.4rem' }}>위치(GPS) 제한</Typography>
          <SwSwitch
            checked={openSwitchLocation}
            onChange={() => setOpenSwitchLocation(!openSwitchLocation)}
          />
        </Box>
        <Box>
          <SwSelectLocation
            limitKu={true}
            includeAmd={true}
            selectOne={true}
            showButton={false}
            showDepth1={false}
            location={locationLimit}
            setLocation={setLocationLimit}
            showContainer={false}
            disabled={!openSwitchLocation}
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>입력된 지역</Typography>
          {saveLocationLimit.length > 0 &&
            saveLocationLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={`${getRegionAmdCode(location.amd)} ${splitLocaitonLimit(
                  location.location
                )}`}
                variant='outlined'
                onDelete={() => deleteLocation(idx, 'locationLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              disabled={!openSwitchLocation}
              onClick={() => deleteAllLocation()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
        <Box className='flex_center' sx={{ m: '2rem 0rem 1rem 0' }}>
          <SwSelectGeohashGroup
            geohashGroup={geohashGroupLimit}
            setGeohashGroup={setGeohashGroupLimit}
            showContainer={false}
            disabled={!openSwitchLocation}
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>지역</Typography>
          {regionLimit.length > 0 &&
            regionLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={location}
                variant='outlined'
                // onDelete={() => deleteRegion(idx, 'regionLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              disabled={!openSwitchLocation}
              onClick={() => deleteAllRegion()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
      </Box>
      <Paper>
        <RegionFilterMap
          geohashData={geohashData}
          setGeohashData={setGeohashData}
          setTargetGeohashList={setGeohashList}
          elementId='map-region'
        />
      </Paper>
      <Divider />
      <Box sx={{ pt: '1rem' }}>
        <Box className='flex_center'>
          <Typography sx={{ mr: '.4rem' }}>성별</Typography>
          <SwSwitch
            checked={openSwitchGender}
            onChange={() => setOpenSwitchGender(!openSwitchGender)}
          />
        </Box>
        <Box sx={{ mt: '.8rem' }}>
          <TextField
            sx={{ width: '18rem' }}
            select
            value={gender}
            onChange={evt => setGender(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              선택
            </MenuItem>
            <MenuItem key={1} value={1}>
              남성
            </MenuItem>
            <MenuItem key={2} value={2}>
              여성
            </MenuItem>
          </TextField>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ pt: '1rem', mb: '1rem' }}>
        <Box className='flex_center' sx={{ mb: '.4rem' }}>
          <Typography sx={{ mr: '.4rem' }}>나이</Typography>
          <SwSwitch
            checked={openSwitchAge}
            onChange={() => setOpenSwitchAge(!openSwitchAge)}
          />
        </Box>
        <Typography fontSize='.8rem' fontWeight={350} color='#999999'>
          여러 나이는 ,(콤마)로 구분하고 나이 범위는 ~(물결)로 입력해 주세요.
          예) 10, 27, 15~18, 30~40
        </Typography>
        <Box sx={{ mt: '.8rem' }}>
          <TextField
            disabled={!openSwitchAge}
            sx={{ width: '18rem' }}
            value={age}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setAge(evt.target.value)}
          />
        </Box>
      </Box>
      <Divider />
      <ButtonBox className='flex_end ' sx={{ mt: '2rem' }}>
        <Box>
          <Button
            variant='contained'
            onClick={() => {
              const newCount = getFilterCount();
              console.log('newCount:', newCount);
              newCount ? addFilter() : setOpenConfirm(true);
            }}
          >
            정보 저장
          </Button>
        </Box>
      </ButtonBox>
      <SwLoadPortalFilter
        open={openTarget}
        onclose={() => setOpenTarget(false)}
        setFilterLoad={setFilterLoad}
      />
      <SwSearchCommunityFilter
        title='커뮤니티/그룹 이름, 아이디 검색'
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchCommunity}
        onClose={() => {
          setOpenSearchCommunity(false);
        }}
        setTarget={setSearchCommunity}
      />
      <SwSearchTarget
        title={targetTitle}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwConfirmDialog
        contents='필터가 없이 개설됩니다. 저장하시겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          addFilter();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </Paper>
  );
};
