import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  SwSwitch,
  AddGroupTextField,
  NoneBorderChip,
  DraggablePaper,
} from '../../styles/Styles';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import {
  getPopularSearchTerm,
  registerPopularSearchTerm,
} from '../../../common/api/ApiChallenge';
import { RESULT_OK } from '../../../common/resultCode';
import { KEY_POPULAR_SERACH_TERM } from '../../../common/key';

interface Props {}

export const PopularSearchTerm: React.FC<Props> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [popularSearchTerm, setPopularSearchTerm] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [changeOrder, setChangeOrder] = useState<boolean>(false);

  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnacker, setOpenSnacker] = useState<boolean>(false);

  const popularSearchTermItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const {
    data,
    isLoading,
    refetch: popularSearchTermRefetch,
  } = useQuery(KEY_POPULAR_SERACH_TERM, () => getPopularSearchTerm(1), {
    onSuccess: res => {
      if (res !== null) {
        const newTerms =
          res.terms !== undefined && res.terms !== null
            ? JSON.parse(res.terms)
            : [];
        setPopularSearchTerm(newTerms);
      }
    },
    onError: e => {
      console.log(e);
    },
  });

  const { mutate: postPopularSearchTermMutate, isError: isMutateError } =
    useMutation(registerPopularSearchTerm, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnacker(true);
          setDialogMessage('인기 검색어를 추가했습니다.');
          popularSearchTermRefetch();
        } else {
          setOpenSnacker(true);
          setDialogMessage(
            `인기 검색어를 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnacker(true);
        setDialogMessage(
          `인기 검색어 추가가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
        );
      },
    });

  const deletePopularSearchTerm = (index: number, from: string) => {
    const newSomething = popularSearchTerm.filter(
      (_val: any, i: number) => i !== index
    );
    setPopularSearchTerm([...newSomething]);
  };

  const savePopularSearchTerm = () => {
    const newData = {
      targetType: 1,
      terms: JSON.stringify(popularSearchTerm),
    };
    postPopularSearchTermMutate(newData);
  };

  const onChangeTerm = () => {
    if (searchTerm) {
      const newTerms = [...popularSearchTerm, searchTerm];

      setPopularSearchTerm(newTerms);
    }
  };

  // draw & drop ------------------------------------------------
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: popularSearchTerm,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setPopularSearchTerm(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    popularSearchTermItems.forEach(item => item.classList.remove('drag_over'));
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setPopularSearchTerm(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    popularSearchTermItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    popularSearchTermItems.forEach(item =>
      item.classList.remove('click_group')
    );
    newContain && evt.currentTarget.classList.add('click_group');
    const newPosition = Number(evt.currentTarget.dataset.position);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newPosition : null,
    });
  };
  // ------------------------------------------------------

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      popularSearchTerm.map((term: any, idx: number) => {
        setCheckedButtons(current => [...current, term]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const deleteCheckedPopularSearchTerm = () => {
    const newGroup = popularSearchTerm.filter(
      (term: any, idx: number) => !checkedButtons.includes(term)
    );
    setCheckedAll(false);
    setPopularSearchTerm(newGroup);
    setListEvent({ ...listEvent, clicked: null });
  };

  useEffect(() => {
    setChangeOrder(false);
  }, []);

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box>
        <Typography className='title'>인기 검색어 추가</Typography>
        <Box className='flex_between'>
          <Box>
            <AddGroupTextField
              placeholder='검색어를 입력해주세요'
              onChange={evt => setSearchTerm(evt.target.value.substring(0, 20))}
              value={searchTerm}
              sx={{ width: '24rem' }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <span>{searchTerm.length} / 20</span>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant='contained' onClick={onChangeTerm}>
              추가
            </Button>
          </Box>
        </Box>
        <Paper className='chip_container' sx={{ height: '12rem' }}>
          <Typography>추가한 검색어</Typography>
          <Box sx={{ height: '7rem' }}>
            {popularSearchTerm?.length > 0 &&
              popularSearchTerm.map((term: any, idx: number) => (
                <NoneBorderChip
                  key={idx.toString()}
                  label={term}
                  variant='outlined'
                  onDelete={() => deletePopularSearchTerm(idx, 'term')}
                />
              ))}
          </Box>
          <Box className='flex_end' sx={{ mt: '0.1rem' }}>
            <Button
              color='info'
              onClick={() => setPopularSearchTerm([])}
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </Box>
        </Paper>
        <Divider />
        {changeOrder && (
          <Paper>
            <Box sx={{ width: '70%' }}>
              <Box className='flex_between' sx={{ mt: '1rem' }}>
                <Box className='flex_center'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                  <Typography sx={{ ml: '1rem' }}>
                    전체 선택
                    <span style={{ color: '#2581FF' }}>
                      {' '}
                      [{popularSearchTerm.length}개]
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant='contained'
                    color='info'
                    sx={{ mr: '1.5rem', mt: '.5rem' }}
                    onClick={() => {
                      deleteCheckedPopularSearchTerm();
                    }}
                  >
                    선택한 검색어 삭제
                  </Button>
                </Box>
                {/* <Button variant='outlined' color='info' sx={{ mr: '1.7rem' }}>
              저장
            </Button> */}
              </Box>
              {popularSearchTerm?.map((term: any, idx: number) => (
                <Box key={idx.toString()} sx={{ display: 'flex' }}>
                  <Checkbox
                    id={`popular_term_${idx}`}
                    onChange={e => {
                      changeHandler(e.currentTarget.checked, term);
                    }}
                    checked={checkedButtons.includes(term)}
                  />

                  <DraggablePaper
                    className='draggable'
                    id={idx.toString()}
                    variant='outlined'
                    onDragStart={dragStart}
                    onDragEnd={dragEnd}
                    onDragOver={dragOver}
                    onDrop={drop}
                    onDragLeave={dragLeave}
                    onDragEnter={dragEnter}
                    draggable
                    data-position={idx}
                    onClick={selectList}
                  >
                    <div>
                      <span>{term}</span>
                    </div>
                    <DragHandleIcon />
                  </DraggablePaper>
                </Box>
              ))}
            </Box>
          </Paper>
        )}
      </Box>
      <Box sx={{ textAlign: 'right', mt: '1rem' }}>
        <Button
          variant='outlined'
          color='info'
          sx={{ mr: '1.5rem' }}
          onClick={() => {
            setChangeOrder(!changeOrder);
          }}
        >
          {changeOrder ? '순서 변경 닫기' : '순서 변경'}
        </Button>
        <Button
          variant='contained'
          color='info'
          sx={{ mr: '1.5rem' }}
          onClick={() => {
            savePopularSearchTerm();
          }}
        >
          저장
        </Button>
      </Box>
      <SwSnackbar
        open={openSnacker}
        onClose={() => setOpenSnacker(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};
