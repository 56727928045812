import {
  Box,
  Collapse,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  styled,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { WalkwayPointForm } from './WalkwayPointForm';
import {
  deleteWalkwayPoint,
  getWalkwayPointList,
} from '../../../common/api/ApiWalkway';
import { KEY_WALKWAY_POINT_LIST } from '../../../common/key';
import {
  IWalkwayCommand,
  IWalkwayPointCommand,
  DefaultalkwayPointCommand,
} from '../../../models/walkway';

const PointPaper = styled(Paper)({
  borderRadius: '12px',
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  width: '8rem',
  height: '3rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: '.2rem',
  boxShadow: 'none',
  borderBottom: '1px solid white',
  fontSize: '1rem',
});

const defaultpoint = {
  id: '',
  title: '',
  descripion: '',
  mainImage: '',
  subImages: [''],
  location: '',
  open: false,
};

interface Props {
  walkwayCommand: IWalkwayCommand;
  reload: string;
}

export const WalkwayPointCreate: React.FC<Props> = ({
  walkwayCommand,
  reload,
}) => {
  const scrollRef = useRef<any>();
  const queryClient = useQueryClient();
  const [openPoint, setOpenPoint] = useState<boolean>(false);
  const [selectId, setSelectId] = useState<number>(0);
  const [points, setPoints] = useState<any>([]);
  const [point, setPoint] = useState([defaultpoint]);
  const [reset, setReset] = useState(false);
  const [walkwayId, setWalkwayId] = useState<string>('');
  const [reloadPoints, setReloadPoints] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [selectPointNumber, setSelectPointNumber] = useState<number>(-1);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: walkwayRefetch,
  } = useQuery(
    KEY_WALKWAY_POINT_LIST,
    () => {
      if (walkwayId !== '') {
        console.log('point walkwayId:', walkwayId);
        return getWalkwayPointList(walkwayId);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('res point:', res);
        setReloadPoints(false);
        if (res != null && res.point != null) {
          const newPoints = res.point.map((item: any) => {
            const urlPrefix = item.imageUrlPrefix;

            const parsedData = JSON.parse(item.storyImages);
            const newSubImages = parsedData
              ? parsedData.map((subImage: any) => subImage)
              : [];
            const newPoint: IWalkwayPointCommand = {
              walkwayId: item.walkWayId,
              pointId: item.pointId,
              title: item.title,
              description: item.description,
              mainImage: item.imagePath,
              subImages: newSubImages,
              location: item.location,
              la: item.la,
              lo: item.lo,
              contentId: item.contentId,
              alias: item.alias,
              open: item.hidden,
              urlPrefix: item.imageUrlPrefix,
            };
            return newPoint;
          });
          setPoints(newPoints);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const walkwayPointPrefetch = (newWalkwayId: string) => {
    queryClient.prefetchQuery(KEY_WALKWAY_POINT_LIST, () =>
      getWalkwayPointList(newWalkwayId)
    );
  };

  const { mutate: deleteWalkwayPointMutate, isError: isMutateError } =
    useMutation(deleteWalkwayPoint, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('워크웨이 포인트를 삭제했습니다.');
        walkwayRefetch();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '워크웨이 포인트 삭제가 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const resetWalkPoint = () => {
    setPoints([]);
    setReset(true);
  };

  const getWalkwayId = () => walkwayId;

  const deletePoint = () => {
    const walkpointId = points[selectPointNumber].pointId;
    const newData = {
      pointId: walkpointId,
    };

    const newPoints = points.filter(
      (_p: any, i: number) => i !== selectPointNumber
    );
    setPoints(newPoints);

    deleteWalkwayPointMutate(newData);
  };

  useEffect(() => {
    if (reloadPoints) walkwayRefetch();
  }, [reloadPoints]);

  useEffect(() => {
    console.log('walkwayPointCreate :', reload);
    if (reload !== '') {
      setWalkwayId(reload);
      walkwayPointPrefetch(reload);
    }
  }, [reload]);

  useEffect(() => {
    openPoint &&
      setTimeout(() => {
        scrollRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
  }, [openPoint]);

  useEffect(() => {
    console.log('Point Create walkwayCommand :', walkwayCommand);
    setReloadPoints(false);
    if (walkwayCommand && walkwayCommand.walkwayId) {
      setWalkwayId(walkwayCommand.walkwayId);
      walkwayPointPrefetch(walkwayCommand.walkwayId);
    }

    if (walkwayCommand && walkwayCommand.reset) {
      console.log('point reset :', walkwayCommand.reset);
      resetWalkPoint();
    }

    if (walkwayCommand && walkwayCommand.isSend) {
      console.log('point isSend :', walkwayCommand.isSend);
    }
  }, [walkwayCommand]);

  // TODO 포인트 저장방법 1. 여기에서 state 2. recoil

  return (
    <Box sx={{ m: '2rem 0' }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', p: '0 1rem', mb: '1rem' }}
      >
        <Typography variant='h4' sx={{ fontWeight: 500 }} component='span'>
          워크웨이 포인트
        </Typography>
        <Switch value={openPoint} onChange={() => setOpenPoint(!openPoint)} />
      </Box>
      <Collapse in={openPoint}>
        <div style={{ display: 'flex' }} ref={scrollRef}>
          {points.map((_point: any, idx: number) => (
            <PointPaper
              id={idx.toString()}
              key={idx.toString()}
              sx={{
                border: selectId === idx ? '1px solid #00A2FF' : '',
              }}
              aria-hidden
              onClick={(evt: any) => setSelectId(Number(evt.currentTarget.id))}
            >
              <span>{idx + 1}번 포인트</span>
              <CancelOutlinedIcon
                sx={{
                  fontSize: '1rem',
                  color: '#757575',
                  m: '.15rem 0 0 .2rem',
                  cursor: 'grab',
                }}
                aria-hidden
                onClick={() => {
                  setSelectPointNumber(idx);
                  setOpenConfirm(true);
                }}
              />
            </PointPaper>
          ))}
          <PointPaper
            onClick={() => setPoints([...points, DefaultalkwayPointCommand])}
          >
            <AddOutlinedIcon sx={{ mt: '.15rem' }} />
          </PointPaper>
        </div>
        <WalkwayPointForm
          walkwayCommand={walkwayCommand}
          value={selectId}
          points={points}
          setPoints={setPoints}
          setReloadPoints={setReloadPoints}
          getWalkwayId={getWalkwayId}
        />
      </Collapse>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='워크포인트를 삭제하겠습니까?'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          deletePoint();
          setOpenConfirm(false);
        }}
        confirm={1}
      />
    </Box>
  );
};
