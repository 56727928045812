import React from 'react';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

interface Props {}

const datum = [
  {
    country: 'AD',
    hotdog: 24,
    hotdogColor: 'hsl(112, 70%, 50%)',
    burger: 126,
    burgerColor: 'hsl(64, 70%, 50%)',
    sandwich: 19,
    sandwichColor: 'hsl(191, 70%, 50%)',
    kebab: 175,
    kebabColor: 'hsl(112, 70%, 50%)',
    fries: 91,
    friesColor: 'hsl(301, 70%, 50%)',
    donut: 5,
    donutColor: 'hsl(298, 70%, 50%)',
  },
  {
    country: 'AE',
    hotdog: 91,
    hotdogColor: 'hsl(104, 70%, 50%)',
    burger: 43,
    burgerColor: 'hsl(197, 70%, 50%)',
    sandwich: 123,
    sandwichColor: 'hsl(215, 70%, 50%)',
    kebab: 76,
    kebabColor: 'hsl(328, 70%, 50%)',
    fries: 175,
    friesColor: 'hsl(141, 70%, 50%)',
    donut: 106,
    donutColor: 'hsl(240, 70%, 50%)',
  },
  {
    country: 'AF',
    hotdog: 185,
    hotdogColor: 'hsl(150, 70%, 50%)',
    burger: 38,
    burgerColor: 'hsl(280, 70%, 50%)',
    sandwich: 5,
    sandwichColor: 'hsl(331, 70%, 50%)',
    kebab: 17,
    kebabColor: 'hsl(284, 70%, 50%)',
    fries: 68,
    friesColor: 'hsl(37, 70%, 50%)',
    donut: 82,
    donutColor: 'hsl(160, 70%, 50%)',
  },
  {
    country: 'AG',
    hotdog: 146,
    hotdogColor: 'hsl(136, 70%, 50%)',
    burger: 41,
    burgerColor: 'hsl(53, 70%, 50%)',
    sandwich: 119,
    sandwichColor: 'hsl(240, 70%, 50%)',
    kebab: 18,
    kebabColor: 'hsl(80, 70%, 50%)',
    fries: 86,
    friesColor: 'hsl(95, 70%, 50%)',
    donut: 184,
    donutColor: 'hsl(239, 70%, 50%)',
  },
  {
    country: 'AI',
    hotdog: 98,
    hotdogColor: 'hsl(87, 70%, 50%)',
    burger: 54,
    burgerColor: 'hsl(143, 70%, 50%)',
    sandwich: 69,
    sandwichColor: 'hsl(312, 70%, 50%)',
    kebab: 16,
    kebabColor: 'hsl(141, 70%, 50%)',
    fries: 53,
    friesColor: 'hsl(280, 70%, 50%)',
    donut: 29,
    donutColor: 'hsl(122, 70%, 50%)',
  },
];

export const Test2: React.FC<Props> = () => {
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'firstName',
      headerName: 'First name',
      width: 150,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last name',
      width: 150,
      editable: true,
    },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 110,
      editable: true,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.firstName || ''} ${params.row.lastName || ''}`,
    },
  ];

  const rows = [
    { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
    { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
    { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
    { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
    { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
    { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
    { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
    { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
    { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </div>
  );
};
