import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AdjustOutlinedIcon from '@mui/icons-material/AdjustOutlined';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useState,
  Dispatch,
  useRef,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';
import {
  IMAGE_SIZE_10MB_MESSAGE,
  MAX_OPTION_COUNT,
  MAX_UPLOAD_FILE_SIZE,
  getQnaSurveyType,
  getQnaSurveyTypeNo,
  pointPerAchieve,
  quizTypeList,
  unitSelectorList,
} from './SwProgramCommon';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { SwDatesPicker } from '../dateSetting/SwDatesPicker';
import { SwFileSetting } from '../SwFileSetting';
import { useSession } from '../../../hooks/session';
import { ButtonBox } from '../../challenge/challengeManagement/ChallengeStyles';
import { SwTimePicker } from '../dateSetting/SwTimePicker';
import {
  cloneProgramMissionQnaQuestion,
  deleteProgramMissionQnaQuestion,
  postProgramMissionQnaQuestion,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { newUuidId } from '../SwUuidCreate';
import { SwConfirmDialog } from '../views/SwConfirmDialog';
import { getFileSize } from '../../../common/helperProgram';
import { DraggablePaper } from '../../styles/Styles';
import { getAddUrl } from '../../../common/helper';

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

const QnaBox = styled(Box)({
  margin: '1rem 1rem 1rem 3rem',
});

const INIT_UNIT_SELECTOR = '선택';

interface Props {
  quiz: any;
}

export const SwQuizAnswerForm: React.FC<Props> = ({ quiz }) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [preEditMode, setPreEditMode] = useState<string>('view');
  const [qnaType, setQnaType] = useState<string>('choice');
  const [missionId, setMissionId] = useState<string>('');
  const [question, setQuestion] = useState<string>('');
  const [seq, setSeq] = useState<number>(0);
  const [answer, setAnswer] = useState<string>('');
  const [userAnswer, setUserAnswer] = useState<string>('');
  const [explanation, setExplanation] = useState<string>('');
  const [inputType, setInputType] = useState<string>('direct');
  const [link, setLink] = useState<string>('');
  const [hintUrl, setHintUrl] = useState<string>('');
  const [coverFile, setCoverFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [requiredAnswer, setRequiredAnswer] = useState<boolean>(true);
  const [multipleAnswer, setMultipleAnswer] = useState<boolean>(false);
  const [options, setOptions] = useState<any>([
    { type: 'normal', title: '' },
    { type: 'normal', title: '' },
    { type: 'normal', title: '' },
  ]);
  const [image, setImage] = useState<string>('');
  const [uploadQuestionFile, setUploadQuestionFile] = useState<any>(null);
  const [uploadQuestionFileName, setUploadQuestionFileName] = useState<any>('');
  const [multipleAnswerCount, setMultipleAnswerCount] = useState<number>(0);
  const [multipleAnswerCountList, setMultipleAnswerCountList] = useState<any[]>(
    [
      { title: '지정 안함', value: 0 },
      { title: '2', value: 2 },
      { title: '3', value: 3 },
    ]
  );
  const [hideEtcButton, setHideEtcButton] = useState<boolean>(false);
  const [showUnitSelector, setShowUnitSelector] = useState<boolean>(false);
  const [selectUnit, setSelectUnit] = useState<any>(INIT_UNIT_SELECTOR);
  const [directInputUnit, setDirectInputUnit] = useState<any>('');
  const [startScale, setStartScale] = useState<number>(1);
  const [endScale, setEndScale] = useState<number>(2);
  const [startDates, setStartDates] = useState<any[]>([]);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const [durationYear, setDurationYear] = useState<string>('년');
  const [durationMonth, setDurationMonth] = useState<string>('개월');
  const [durationDay, setDurationDay] = useState<string>('일');
  const [selectTime, setSelectTime] = useState<any>(null);
  const [showHintUrl, setShowHintUrl] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [confirmType, setConfirmType] = useState<number>(0);
  const [multipleAnswerCountMessage, setMultipleAnswerCountMessage] =
    useState<boolean>(false);
  const [isCorrectAnswer, setIsCorrectAnswer] = useState<boolean>(false);

  const makeMultipleAnswer = (newOption: any) => {
    let newCountList: any[];
    if (newOption < 2) {
      newCountList = [];
      setMultipleAnswerCountList(newCountList);
    } else {
      newCountList = [];
      for (let i = 2; i <= newOption.length; i += 1) {
        newCountList.push({ title: i.toString(), value: i });
      }
      setMultipleAnswerCountList(newCountList);
    }
  };

  const checkAnswer = (newQuiz: any) => {
    const newQnaType = getQnaSurveyType(quiz.qnaType);
    if (newQnaType === 'short') {
      newQuiz.answerType2 === newQuiz.quizAnswer
        ? setIsCorrectAnswer(true)
        : setIsCorrectAnswer(false);
    }
  };

  useEffect(() => {
    if (quiz !== undefined && quiz !== null) {
      console.log('quiz:', quiz);
      setIsCorrectAnswer(false);
      const newQnaType = getQnaSurveyType(quiz.qnaType);
      setPreEditMode('view');
      setQnaType(newQnaType);
      setQuestion(quiz.title);
      const optionData =
        quiz.optionList !== undefined && quiz.optionList !== null
          ? JSON.parse(quiz.optionList)
          : [];
      const newOptions = optionData.map((item: any, idx: number) => {
        let correctAnswer = 0;
        if (
          newQnaType === 'choice' &&
          quiz.answerType1.includes(item.optionId)
        ) {
          if (item.isCorrect === 1) {
            correctAnswer = 1; // correct
            setIsCorrectAnswer(true);
          } else correctAnswer = 2; // incorrect
        }
        const newData = {
          type: item.isCustomAnswer === 0 ? 'normal' : 'etc',
          title: item.body,
          isCorrect: item.isCorrect,
          isCustomAnswer: item.isCustomAnswer,
          correctAnswer: correctAnswer,
        };

        return newData;
      });
      setOptions(newOptions);
      setMultipleAnswer(quiz.allowMultipleResponses === 1);
      makeMultipleAnswer(newOptions);
      setMultipleAnswerCount(
        newOptions !== undefined && newOptions !== null
          ? quiz.responsesCount
          : 0
      );
      setExplanation(quiz.quizExplanation);
      setAnswer(quiz.quizAnswer !== null ? quiz.quizAnswer : '');
      setUserAnswer(quiz.answerType2 !== null ? quiz.answerType2 : '');
      setUploadQuestionFileName(quiz.image);
      setHintUrl(
        quiz.quizHintUrl !== null && quiz.quizHintUrl !== undefined
          ? quiz.quizHintUrl
          : ''
      );
      setShowHintUrl(
        quiz.quizHintUrl !== undefined &&
          quiz.quizHintUrl !== null &&
          quiz.quizHintUrl.length > 0
      );
      setShowUnitSelector(quiz.isUnitExist === 1);
      const newSelectUnit = unitSelectorList.filter(
        unit => unit.title === quiz.answerUnit
      );
      setSelectUnit(
        quiz.isUnitExist === undefined ||
          quiz.isUnitExist === null ||
          quiz.isUnitExist === 0
          ? '선택'
          : newSelectUnit !== null && newSelectUnit.length > 0
          ? newSelectUnit[0].type
          : '직접입력'
      );
      setDirectInputUnit(
        newSelectUnit === null || newSelectUnit.length === 0
          ? quiz.answerUnit
          : ''
      );
      setSeq(quiz.seq);
      setRequiredAnswer(quiz.isRequired === 1);
      setMissionId(quiz.missionId);

      // check answer
      checkAnswer(quiz);
    }
  }, [quiz]);

  return (
    <Paper
      sx={{
        mb: '1rem',
        background: '#FFFFFF',
      }}
    >
      <Box className='flex_between'>
        <Box className='flex_start' sx={{ ml: '1rem', mt: '1rem' }}>
          <Typography className='title' sx={{ ml: '1rem' }}>
            {`질문 ${seq + 1} `}
            <span style={{ color: '#F63454' }}>*</span>
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ m: '0rem' }} />
      <>
        <QnaBox className='flex_between'>
          <Typography>{question}</Typography>
          {isCorrectAnswer ? (
            <Box className='flex_start' sx={{ color: 'rgba(17, 202, 114,1)' }}>
              <DoneIcon />
              <Typography>정답</Typography>
            </Box>
          ) : (
            <Box className='flex_start' sx={{ color: 'rgba(246, 52, 84,1)' }}>
              <CloseIcon />
              <Typography>오답</Typography>
            </Box>
          )}
        </QnaBox>

        <QnaBox>
          {uploadQuestionFileName !== undefined &&
          uploadQuestionFileName !== null &&
          uploadQuestionFileName !== '' ? (
            <Box sx={{ m: '1rem 0' }}>
              <img
                src={`${imageUrlPrefix}${uploadQuestionFileName}`}
                alt={`${imageUrlPrefix}${uploadQuestionFileName}`}
                style={{
                  maxWidth: '10vw',
                  borderRadius: '14px',
                  width: '10rem',
                  height: 'auto',
                }}
              />
            </Box>
          ) : (
            <></>
          )}
          <Box sx={{ mr: '1rem' }}>
            {showHintUrl && (
              <Button
                onClick={evt => {
                  evt.stopPropagation();
                  window.open(`${hintUrl}`, '_blank');
                }}
              >
                힌트 보러가기 {'>'}
              </Button>
            )}
          </Box>
        </QnaBox>

        <Divider />
      </>
      {qnaType === 'choice' ? (
        <>
          <QnaBox sx={{ mt: '2rem' }}>
            {options.map((option: any, idx: number) => (
              <Box
                key={`choice_view_${idx}`}
                sx={{
                  mb: '1rem',
                  p: '.5rem',
                  backgroundColor:
                    option.correctAnswer === 1
                      ? 'rgba(17, 202, 114, 0.06)'
                      : option.correctAnswer === 2
                      ? 'rgba(246, 52, 84, 0.06)'
                      : '',
                  borderColor:
                    option.correctAnswer === 1
                      ? 'rgba(17, 202, 114, 0.5)'
                      : option.correctAnswer === 2
                      ? 'rgba(246, 52, 84, 0.5)'
                      : '',
                }}
                className='flex_between'
              >
                <Box className='flex_start'>
                  <RadioButtonUncheckedIcon
                    sx={{
                      color:
                        option.correctAnswer === 1 || option.correctAnswer === 2
                          ? 'rgba(0, 162, 255,1)'
                          : '#D4D9E1',
                    }}
                  />
                  <Typography sx={{ ml: '1rem' }}>{option.title}</Typography>
                </Box>
                <Box>
                  {option.correctAnswer === 1 ? (
                    <DoneIcon sx={{ color: 'rgba(17, 202, 114,1)' }} />
                  ) : option.correctAnswer === 2 ? (
                    <CloseIcon sx={{ color: 'rgba(246, 52, 84,1)' }} />
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
            ))}
          </QnaBox>
          <QnaBox>
            <Typography sx={{ fontWeight: 'bold' }}>[정답 및 해설]</Typography>
          </QnaBox>
          <QnaBox sx={{ mt: '2rem' }}>
            {options
              .filter((option: any) => option.isCorrect === 1)
              .map((option: any, idx: number) => (
                <Box
                  key={`choice_opt_view_${idx}`}
                  className='flex_start'
                  sx={{ color: '#00A2FF' }}
                >
                  <Typography>{option.title}</Typography>
                </Box>
              ))}
          </QnaBox>
          <QnaBox sx={{ padding: '0 0 1rem 0rem' }}>
            <Typography>{explanation}</Typography>
          </QnaBox>
        </>
      ) : qnaType === 'short' ? (
        <>
          <QnaBox sx={{ mt: '2rem', padding: '0 0 1rem 0rem' }}>
            <Box sx={{ width: '50%' }} className='flex_start'>
              <TextField
                sx={{
                  width: '100%',
                  backgroundColor: isCorrectAnswer
                    ? 'rgba(17, 202, 114, 0.06)'
                    : 'rgba(246, 52, 84, 0.06)',
                  borderColor: isCorrectAnswer
                    ? 'rgba(17, 202, 114, 0.5)'
                    : 'rgba(246, 52, 84, 0.5)',
                }}
                placeholder='답변을 입력해주세요.'
                value={userAnswer}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      {isCorrectAnswer ? (
                        <DoneIcon sx={{ color: 'rgba(17, 202, 114,1)' }} />
                      ) : (
                        <CloseIcon sx={{ color: 'rgba(246, 52, 84,1)' }} />
                      )}
                      {showUnitSelector && selectUnit === '직접입력' ? (
                        <Typography>{directInputUnit}</Typography>
                      ) : (
                        <Typography>
                          {selectUnit !== '선택' ? selectUnit : ''}
                        </Typography>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </QnaBox>
          <QnaBox>
            <Typography sx={{ fontWeight: 'bold' }}>[정답 및 해설]</Typography>
          </QnaBox>
          <QnaBox sx={{ padding: '0 0 1rem 0rem', color: '#00A2FF' }}>
            <Typography>{answer}</Typography>
          </QnaBox>
          <QnaBox sx={{ padding: '0 0 1rem 0rem' }}>
            <Typography>{explanation}</Typography>
          </QnaBox>
        </>
      ) : (
        <></>
      )}
    </Paper>
  );
};
