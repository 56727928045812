import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { TableTitle } from '../../../commonComponent/tableElement/TableTitle';
import { DrawTable } from '../rewardDrawing/DrawTable';
import {
  ButtonBox,
  QuestionAnswerTypeBox,
  QuestionAnswerTypeTitle,
  ColorTableCell,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './QuestionAnswerStyles';
import {
  getQuestionAnswerRewardDrawingResult,
  getQuestionAnswerRewardDrawingResultDownload,
  getQuestionAnswerRewardList,
} from '../../../../common/api/ApiQuestionAnswer';
import {
  getRewardDrawingResult,
  getRewardDrawingResultDownload,
} from '../../../../common/api/ApiReward';
import {
  KEY_CHALLENGE_REWARD_LIST,
  KEY_QUESTION_ANSWER_REWARD_DRAWING_RESULT,
  KEY_QUESTION_ANSWER_REWARD_LIST,
  KEY_REWARD_DRAWING_RESULT,
} from '../../../../common/key';
import { postPushSingle } from '../../../../common/api/ApiPush';
import { postTimeline } from '../../../../common/api/ApiTimeline';
import { RESULT_OK } from '../../../../common/resultCode';
import { IPush } from '../../../../models/push';
import { SwSnackbar } from '../../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../../commonComponent/views/SwAlert';
import { ITimeline } from '../../../../models/timeline';

const columns = [
  '쿠폰 이름',
  '수량',
  '응모 인원',
  '추첨 상태',
  '추첨 바로가기',
];

const columns2 = [
  '번호',
  '연락처',
  '참여자 닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
];

interface Props {
  rewardGivetype: any;
  rewardUsetype: any;
  questionAnswerInfo: any;
  setQuestionAnswerInfo: Dispatch<React.SetStateAction<any>>;
}

export const QuestionAnswerDrawResult: React.FC<Props> = ({
  rewardGivetype,
  rewardUsetype,
  questionAnswerInfo,
  setQuestionAnswerInfo,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [list, setList] = useState<any>([]);
  const [rewardInfo, setRewardInfo] = useState<any>();
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [qnaId, setQnaId] = useState<string>('');
  const [questionAnswerType, setQuestionAnswerType] = useState<number>(1);
  const [rewardId, setRewardId] = useState<string>('');
  const [rewardStatus, setRewardStatus] = useState<number>(1);
  const [winner, setWinner] = useState<any>([]);
  const [loser, setLoser] = useState<any>([]);
  const [allUserList, setAllUserList] = useState<any>([]);
  const [winnerUserList, setWinnerUserList] = useState<any>([]);
  const [nonwinnerUserList, setNonwinnerUserList] = useState<any>([]);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const {
    data,
    isLoading,
    refetch: questionAnswerRewardRefetch,
  } = useQuery(
    KEY_QUESTION_ANSWER_REWARD_LIST,
    () => {
      console.log('qnaId: ', qnaId);
      if (qnaId.length > 0) return getQuestionAnswerRewardList(qnaId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('DrawResult res:', res);
        if (res !== null) {
          const newRewardList = res.reward
            .filter((item: any, idx: number) => item.rewardGiveType === 2)
            .map((item: any, idx: number) => {
              const newGiveType =
                rewardGivetype[item.rewardGiveType]
                  .questionAnswerRewardGivetypeDescription;
              const newUseType = rewardUsetype
                .filter(
                  (useItem: any) =>
                    useItem.questionAnswerRewardUsetype ===
                      item.rewardUseType &&
                    useItem.questionAnswerRewardUsesubtype ===
                      item.rewardUsetypeSub
                )
                .map(
                  (useItem: any) =>
                    useItem.questionAnswerRewardUsetypeDescription
                );

              const newReward = {
                title: item.rewardTitle,
                count: item.rewardMaxCount,
                drawCount: item.drawUserCount,
                status: item.rewardStatus,
                attendCount: item.attendUserCount,
              };
              return newReward;
            });

          setRequestList(newRewardList);
          setRewardList(res.reward);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: rewardDrawingResultRefetch } = useQuery(
    KEY_QUESTION_ANSWER_REWARD_DRAWING_RESULT,
    () => {
      if (rewardId.length > 0)
        return getQuestionAnswerRewardDrawingResult(
          'all',
          rewardId,
          searchKey,
          searchWord
        );
      return null;
    },
    {
      onSuccess: res => {
        console.log('GivingReward drawing list :', res);
        if (res !== null) {
          setAllUserList(res.rewardResult);
          setWinnerUserList(
            res.rewardResult.filter(
              (user: any) =>
                user.rewardDrawResult === 1 || user.rewardDrawResult === 3
            )
          );
          setNonwinnerUserList(
            res.rewardResult.filter(
              (user: any) =>
                user.rewardDrawResult !== 1 && user.rewardDrawResult !== 3
            )
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const resetResult = () => {
    setSelectedRow(-1);
    setWinnerUserList([]);
    setAllUserList([]);
    setNonwinnerUserList([]);
  };

  const prefetchUser = (newRewardId: string) => {
    queryClient.prefetchQuery(KEY_QUESTION_ANSWER_REWARD_DRAWING_RESULT, () =>
      getQuestionAnswerRewardDrawingResult(
        'all',
        newRewardId,
        searchKey,
        searchWord
      )
    );
  };

  const navRewardDrawing = (idx: number) => {
    console.log('navRewardDrawing rewardId:', rewardList[idx].rewardId);
    navigate('/questionAnswer/drawing', {
      replace: true,
      state: {
        rewardId: rewardList[idx].rewardId,
        qnaId: rewardList[idx].qnaId,
      },
    });
  };

  const downloadRewardDrawingResult = (type: string) => {
    const newData = {
      rewardId: rewardId,
      type: type,
    };

    getQuestionAnswerRewardDrawingResultDownload(newData);
  };

  const { mutate: postTimelineMutate, isError: isPushMutateError } =
    useMutation(postTimeline, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnakerDialog(true);
          setDialogMessage('타임라인을 등록했습니다.');
        } else {
          setDialogMessage(
            `타임라인 등록이 실패했습니다.(${res.resultCodeMsg})`
          );
          setOpenSnakerDialog(true);
        }
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '타임라인 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const registerTimeline = (type: string) => {
    const users = type === 'winner' ? winnerUserList : nonwinnerUserList;
    const newUseType = `${rewardInfo.rewardUseType}/${rewardInfo.rewardUseSubtype}`;
    const newRewardTitle = rewardInfo.rewardTitle;
    var newContent = {};

    if (type === 'winner') {
      newContent =
        newUseType === '4/0' // 신청양식 입력형
          ? `🏆[${newRewardTitle}] 쿠폰에 당첨되셨습니다. 보관함으로 이동하여 쿠폰 수령을 위한 추가 정보를 입력해 주세요!`
          : newUseType === '5/1' || newUseType === '5/2' // 건강/기념증서
          ? `🏆[${newRewardTitle}] 증서에 당첨되셨습니다. 보관함으로 이동하여 증서를 확인해 주세요!`
          : `🏆[${newRewardTitle}] 쿠폰에 당첨되셨습니다. 보관함으로 이동하여 쿠폰을 확인해 주세요!`; // 전체(신청양식 입력폼, 건강/기념 증서 제외)
    } else {
      newContent =
        newUseType === '5/1' || newUseType === '5/2' // 건강/기념증서
          ? `아쉽게도 [${newRewardTitle}] 증서에 당첨되지 않았습니다! 다음 기회에 다시 도전해 주세요.`
          : `아쉽게도 [${newRewardTitle}] 쿠폰에 당첨되지 않았습니다! 다음 기회에 다시 도전해 주세요.`; // 전체(신청양식 입력폼, 건강/기념 증서 제외)
    }
    users.map((user: any) => {
      const nowDate = new Date(Date.now());
      const newDates = nowDate.getTime() / 1000;
      const newData = {
        timestamp: questionAnswerInfo.questionAnswerTitle,
        text: newContent,
        imageUrl: '',
        action: type === 'winner' ? 7 : 0,
        extra: {},
        imgSize: { width: 0, height: 0 },
        ctr: 'no',
        name: user.name,
      };
      const newTimeline: ITimeline = {
        feedType: 7,
        receiver: {
          type: 'user',
          id: user.userId,
          name: user.name,
          healthItem: 1,
        },
        content: JSON.stringify(newData),
        site: 'webAdmin',
        requestDate: [newDates],
        repeat: {
          startDate: 0,
          endDate: 0,
        },
      };
      // console.log('registerTimeline :', newTimeline);
      postTimelineMutate(newTimeline);
    });
  };

  const { mutate: pushSingleMutate, isError: isPushSingleMutateError } =
    useMutation(postPushSingle, {
      onSuccess: res => {},
      onError: error => {},
    });

  const sendPush = (type: string) => {
    const users = type === 'winner' ? winnerUserList : nonwinnerUserList;
    const newTitle = questionAnswerInfo.questionAnswerTitle; // 챌린지명
    const newRewardTitle = rewardInfo.rewardTitle;
    const newUseType = `${rewardInfo.rewardUseType}/${rewardInfo.rewardUseSubtype}`;
    const newContent =
      newUseType === '4/0' // 신청양식 입력형
        ? `🏆[${newRewardTitle}] 쿠폰에 당첨되셨습니다. 쿠폰 수령을 위한 추가 정보를 입력해 주세요!`
        : newUseType === '5/1' || type === '5/2' // 건강/기념증서
        ? `🏆[${newRewardTitle}] 증서에 당첨되셨습니다. 보관함으로 이동하여 증서를 확인해 주세요!`
        : `🏆[${newRewardTitle}] 쿠폰에 당첨되셨습니다. 보관함으로 이동하여 쿠폰을 확인해 주세요!`; // 전체(신청양식 입력폼, 건강/기념 증서 제외)

    users.map((user: any) => {
      const newPush: IPush = {
        receiver: {
          type: 'user',
          id: user.userId,
          name: user.name,
        },
        title: newTitle,
        content: newContent,
        link: 'walkon://home/popup',
        linkName: '',
        site: 'webAdmin',
        requestDate: [],
      };
      // console.log('sendPush :', newPush);
      pushSingleMutate(newPush);
    });
    setOpenSnakerDialog(true);
    setDialogMessage(
      type === 'winner'
        ? '당첨자에게 알림 발송이 완료되었습니다.'
        : '미당첨자에게 알림 발송이 완료되었습니다.'
    );
  };

  useEffect(() => {
    if (rewardId.length > 0) {
      rewardDrawingResultRefetch();
    }
  }, [rewardId]);

  const clickRow = (event: any, idx: number) => {
    if (event.target.classList.contains('MuiButton-root')) {
      return;
    }
    setSelectedRow(idx);
    setRewardInfo(rewardList[idx]);
    setRewardId(rewardList[idx].rewardId);
    prefetchUser(rewardList[idx].rewardId);
  };

  useEffect(() => {
    questionAnswerRewardRefetch();
  }, [qnaId]);

  useEffect(() => {
    if (questionAnswerInfo) {
      console.log(
        'QuestionAnswerOpenRegion questionAnswerInfo :',
        questionAnswerInfo
      );
      setQnaId(questionAnswerInfo.qnaId);
      setQuestionAnswerType(questionAnswerInfo.questionAnswerType);
      setImageUrlPrefix(questionAnswerInfo.imageUrlPrefix);
      resetResult();
    }
  }, [questionAnswerInfo]);

  return (
    <Paper sx={{ p: '1.5rem' }}>
      <Paper sx={{ p: '1rem', m: '1rem 0' }}>
        <TableTitle
          title='응모형 리워드'
          count={requestList.length}
          postFix='개'
        />
        <TableContainer
          sx={{ p: '0 1rem', height: '20vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList.length > 0 ? (
                requestList?.map((row: any, idx: number) => (
                  <TableRow
                    key={idx.toString()}
                    onClick={event => clickRow(event, idx)}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                  >
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.count}</TableCell>
                    <TableCell>{row.drawCount}</TableCell>
                    <TableCell>
                      {row.attendCount > 0 ? '추첨완료' : '추첨대기'}
                    </TableCell>
                    <TableCell sx={{ p: '.1rem .6rem' }}>
                      <Button
                        color='info'
                        variant='contained'
                        onClick={() => navRewardDrawing(idx)}
                      >
                        {row.status === 0 ? '재추첨' : '바로가기'}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    추첨 대상 리워드가 없습니다.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Box sx={{ m: '3rem 0 1.5rem' }}>
        <Typography fontSize='1rem' fontWeight={500}>
          추첨 결과
        </Typography>
        <Box className='flex_end'>
          <Button
            disabled={winnerUserList.length === 0}
            color='info'
            variant='outlined'
            sx={{ mr: '.5rem' }}
            onClick={() => {
              downloadRewardDrawingResult('winner');
            }}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button>
          <Button
            disabled={winnerUserList.length === 0}
            variant='outlined'
            sx={{ mr: '.5rem' }}
            onClick={() => {
              sendPush('winner');
              registerTimeline('winner');
            }}
          >
            알림 발송
          </Button>
        </Box>
        <Paper sx={{ p: '1rem', m: '1rem 0' }}>
          <Typography sx={{ mb: '1.5rem' }}>
            당첨자 목록{' '}
            <span style={{ color: '#2581FF' }}>{winnerUserList.length}</span>명
          </Typography>
          <DrawTable
            columns={columns2}
            rows={winnerUserList}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Paper>
      </Box>
      <Box sx={{ m: '1.5rem 0' }}>
        <Box className='flex_end'>
          <Button
            disabled={nonwinnerUserList.length === 0}
            color='info'
            variant='outlined'
            sx={{ mr: '.5rem' }}
            onClick={() => {
              downloadRewardDrawingResult('nowinner');
            }}
          >
            데이터 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button>
          <Button
            disabled={nonwinnerUserList.length === 0}
            variant='outlined'
            sx={{ mr: '.5rem' }}
            onClick={() => {
              registerTimeline('nonwinner');
            }}
          >
            알림 발송
          </Button>
        </Box>
        <Paper sx={{ p: '1rem', m: '1rem 0' }}>
          <Typography sx={{ mb: '1.5rem' }}>
            미당첨자 목록{' '}
            <span style={{ color: '#2581FF' }}>{nonwinnerUserList.length}</span>
            명
          </Typography>
          <DrawTable
            columns={columns2}
            rows={nonwinnerUserList}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
          />
        </Paper>
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Paper>
  );
};
