import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import Grid from '@mui/material/Unstable_Grid2';
import clsx from 'clsx';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../hooks/resize';
import { SwCard } from './SwCard';
import dashboarddummy from '../../dummy/dashboarddummy.json';
import { MultilineTextField, SelectTextField } from '../styles/Styles';
import {
  getChallengeRequestList,
  getChallengeRequestDetail,
  confirmChallengeRegister,
  changeChallengeApplyStatus,
  getChallengeRequestListByOperator,
} from '../../common/api/ApiChallenge';
import {
  getCommunityRequestList,
  getCommunityRequestDetail,
  getCommunityRequestListByOperator,
} from '../../common/api/ApiCommunity';
import {
  getWalkcourseRequestList,
  getWalkcourseRequestDetail,
  getWalkcourseRequestListByOperator,
} from '../../common/api/ApiWalkcourse';
import {
  getDataPortalQnAList,
  getDataPortalQnAListByOperator,
} from '../../common/api/ApiDataPortal';
import {
  getStamptourRequestList,
  getStamptourRequestDetail,
  registerStamptour,
  getStamptourRequestListByOperator,
} from '../../common/api/ApiStamptour';
import {
  getRewardDrawChallengeList,
  getRewardDrawChallengeDetailList,
  getRewardDrawUserList,
  getRewardDrawChallengeListByOperator,
} from '../../common/api/ApiReward';
import {
  KEY_CHALLENGE_REQUEST_LIST,
  KEY_CHALLENGE_REQUEST_LIST_BY_OPERATOR,
  KEY_COMMUNITY_REQUEST_LIST,
  KEY_COMMUNITY_REQUEST_LIST_BY_OPERATOR,
  KEY_DASHBOARD_MEMO_LIST,
  KEY_DASHBOARD_SHORTCUT_LIST,
  KEY_DATAPORTAL_QNA_LIST,
  KEY_FIELD_REQUEST_LIST_BY_OPERATOR,
  KEY_PROGRAM_REQUEST_LIST_BY_OPERATOR,
  KEY_QUESTION_ANSWER_REQUEST_LIST_BY_OPERATOR,
  KEY_REWARD_DRAWING_LIST,
  KEY_STAMPTOUR_REQUEST_LIST,
  KEY_WALKCOURSE_REQUEST_LIST,
  KEY_WALKCOURSE_REQUEST_LIST_BY_OPERATOR,
} from '../../common/key';
import {
  deleteDashboardMemo,
  getDashboardMemoList,
  postDashboardMemoRegister,
  deleteDashboardShortcut,
  getDashboardShortcutList,
  postDashboardShortcutRegister,
} from '../../common/api/ApiDashboard';
import { SwAlert } from '../commonComponent/views/SwAlert';
import { SwSnackbar } from '../commonComponent/views/SwSnackbar';
import { IDashboardMemo, IDashboardShortcut } from '../../models/dashboard';
import { timestamp2Localestring } from '../../common/helper';
import { dashBoardState } from '../../common/atom';
import { getProgramRequestListByOperator } from '../../common/api/ApiProgram';
import { getQuestionAnswerRequestListByOperator } from '../../common/api/ApiQuestionAnswer';
import { getFieldRequestListByOperator } from '../../common/api/ApiField';

interface Props {}

const tabs = [
  {
    id: 'users/userinquire',
    title: '사용자 목록',
  },
  { id: 'push/regist', title: '푸시 알림' },
  { id: 'timeline/regist', title: '타임라인' },
  { id: 'survey/regist', title: '질의응답' },
  {
    id: 'communitymanage/create',
    title: '커뮤니티 관리',
  },
  {
    id: 'programmanage/list',
    title: '프로그램 관리',
  },
  {
    id: 'contentmanage/contentmanage_movie',
    title: '콘텐츠 관리',
  },
  {
    id: 'walkway',
    title: '내 주변',
  },
  {
    id: 'walkonnotice',
    title: '공지사항',
  },
  // {
  //   id: 'accessrestrictionmanage',
  //   title: '접속 제한 관리',
  // },
  // {
  //   id: 'inappmessage',
  //   title: '인앱 메시지',
  // },
  {
    id: 'challengemanagement/create',
    title: '챌린지 관리',
  },
  {
    id: 'walkcourse/create',
    title: '길 코스',
  },
  {
    id: 'stamptour/create',
    title: '스탬프 코스',
  },
  {
    id: 'rewarddrawing',
    title: '리워드 추첨',
  },
  {
    id: 'regionalfilter/create',
    title: '지역 필터',
  },
  {
    id: 'portalusermanagement',
    title: '포탈 계정 관리',
  },
  {
    id: 'FAQ',
    title: '자주 묻는 질문',
  },
  {
    id: 'privateinquiry',
    title: '1:1 문의',
  },
  {
    id: 'teamnotice',
    title: '워크온팀 공지사항',
  },
  {
    id: 'portalconnectpopup',
    title: '접속 안내팝업',
  },
  {
    id: 'portalguidemanage/guidelist',
    title: '포털 설명서 관리',
  },
  {
    id: 'partnermanagement',
    title: '고객 관리',
  },
  // {
  //   id: 'users',
  //   title: '카드 결제 관리',
  // },
  {
    id: 'supplyplan',
    title: '서비스 이용권',
  },
  {
    id: 'advertiser',
    title: '광고주',
  },
  {
    id: 'empadminister/accountmanagement',
    title: '어드민 계정 관리',
  },
  {
    id: 'localstoreowner',
    title: '사장님 계정 관리',
  },
  {
    id: 'localstore',
    title: '가게 관리',
  },
  {
    id: 'localstorenotice',
    title: '우리 동네 챌린지 공지사항',
  },
];

export const Dashboard: React.FC<Props> = () => {
  const selectRef = useRef<any>();
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [dashBoardInfo, setDashBoardInfo] = useRecoilState(dashBoardState);
  const [editShortcut, setEditShortcut] = useState(false);
  const [memoList, setMemoList] = useState<any>([]);
  const [memoText, setMemoText] = useState<string>('');
  const [shortcutList, setShortcutList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any>([]);
  const [test, setTest] = useState<any>([]);
  const [challengeContents, setChallengeContents] = useState<any>([]);
  const [communityContents, setCommunityContents] = useState<any>([]);
  const [programContents, setProgramContents] = useState<any>([]);
  const [questionAnswerContents, setQuestionAnswerContents] = useState<any>([]);
  const [qnaContents, setQnaContents] = useState<any>([]);
  const [fieldContents, setFieldContents] = useState<any>([]);
  const [walkcourseContents, setWalkcourseContents] = useState<any>([]);
  const [stamptourContents, setStamptourContents] = useState<any>([]);
  const [rewardContents, setRewardContents] = useState<any>([]);
  const [contents, setContents] = useState<any>([]);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openAlertDialog, setOpenAlertDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const addBadgeCount = (count: number) => {
    const newCount = Number.isNaN(dashBoardInfo.count)
      ? count
      : dashBoardInfo.count + count;
    console.log('dashBoardInfo newCount :', newCount);
    setDashBoardInfo({
      count: newCount,
    });
  };

  const {
    data,
    isLoading,
    refetch: challengeRequestRefetch,
  } = useQuery(
    KEY_CHALLENGE_REQUEST_LIST_BY_OPERATOR,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getChallengeRequestListByOperator();
      return null;
    },
    {
      onSuccess: res => {
        console.log('requestList:', res);
        const newChallenge =
          res &&
          res.creationRequest &&
          res.creationRequest.challenge.map((item: any) => {
            const newData = {
              title: item.challengeTitle,
              customer: `${item.challengeCreatorName}(${item.challengeCreator})`,
            };
            return newData;
          });
        setChallengeContents(newChallenge);
        addBadgeCount(
          newChallenge !== undefined && newChallenge !== null
            ? newChallenge.length
            : 0
        );
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: communityRequestRefetch } = useQuery(
    KEY_COMMUNITY_REQUEST_LIST_BY_OPERATOR,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getCommunityRequestListByOperator();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newCommunity =
          res &&
          res.creationRequest &&
          res.creationRequest.map((item: any) => {
            const newData = {
              title: item.name,
              customer: item.ownerName,
            };
            return newData;
          });
        setCommunityContents(newCommunity);
        addBadgeCount(
          newCommunity !== undefined && newCommunity !== null
            ? newCommunity.length
            : 0
        );
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: programRequestRefetch } = useQuery(
    KEY_PROGRAM_REQUEST_LIST_BY_OPERATOR,
    () => {
      if (checkRequest) return getProgramRequestListByOperator();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newProgram =
          res &&
          res.creationRequest &&
          res.creationRequest.program.map((item: any) => {
            const newData = {
              title: item.title,
              customer: item.customerName,
            };
            return newData;
          });
        setProgramContents(newProgram);
        addBadgeCount(
          newProgram !== undefined && newProgram !== null
            ? newProgram.length
            : 0
        );
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: qnaRequestRefetch } = useQuery(
    KEY_QUESTION_ANSWER_REQUEST_LIST_BY_OPERATOR,
    () => {
      if (checkRequest) return getQuestionAnswerRequestListByOperator();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newQna =
          res &&
          res.creationRequest &&
          res.creationRequest.qna.map((item: any) => {
            const newData = {
              title: item.title,
              customer: item.customerName,
            };
            return newData;
          });
        setQuestionAnswerContents(newQna);
        addBadgeCount(
          newQna !== undefined && newQna !== null ? newQna.length : 0
        );
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: refetchDataPortal } = useQuery(
    KEY_DATAPORTAL_QNA_LIST,
    () => {
      const newData = {
        page: 1,
        rowsPerPage: 20,
        searchKey: 'all',
        searchWord: '',
      };
      if (checkRequest) return getDataPortalQnAListByOperator(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log('PrivateInquiry res :', res);
        if (res != null) {
          const newQna = res.qna.qna.map((item: any) => {
            const newData = {
              customer: item.name,
              title: item.title,
            };
            return newData;
          });
          setQnaContents(newQna);
          addBadgeCount(
            newQna !== undefined && newQna !== null ? newQna.length : 0
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: walkcourseRequestRefetch } = useQuery(
    KEY_WALKCOURSE_REQUEST_LIST_BY_OPERATOR,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getWalkcourseRequestListByOperator();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newWalkcourse =
          res &&
          res.creationRequest &&
          res.creationRequest.map((item: any) => {
            const newData = {
              title: item.courseName,
              customer: item.creatorName,
            };
            return newData;
          });
        setWalkcourseContents(newWalkcourse);
        addBadgeCount(
          newWalkcourse !== undefined && newWalkcourse !== null
            ? newWalkcourse.length
            : 0
        );
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: fieldRequestRefetch } = useQuery(
    KEY_FIELD_REQUEST_LIST_BY_OPERATOR,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getFieldRequestListByOperator();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newField =
          res &&
          res.creationRequest &&
          res.creationRequest.map((item: any) => {
            const newData = {
              title: item.name,
              customer: item.creatorName,
            };
            return newData;
          });
        setFieldContents(newField);
        addBadgeCount(
          newField !== undefined && newField !== null ? newField.length : 0
        );
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: stmaptourRequestRefetch } = useQuery(
    KEY_STAMPTOUR_REQUEST_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getStamptourRequestListByOperator();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newStamptour =
          res &&
          res.creationRequest &&
          res.creationRequest.map((item: any) => {
            const newData = {
              title: item.stampTourName,
              customer: item.creator,
            };
            return newData;
          });
        setStamptourContents(newStamptour);
        addBadgeCount(
          newStamptour !== undefined && newStamptour !== null
            ? newStamptour.length
            : 0
        );
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: rewardDrawRefetch } = useQuery(
    KEY_REWARD_DRAWING_LIST,
    () => {
      const newData = {
        page: 0,
        rowsPerPage: 0,
        searchKey: 'all',
        searchWord: '',
      };
      return getRewardDrawChallengeListByOperator(newData);
    },
    {
      onSuccess: res => {
        console.log('reward drawing list :', res);
        if (res !== null) {
          const newRewardDrawing =
            res &&
            res.result.rewardDraw &&
            res.result.rewardDraw.map((item: any) => {
              const newDate = timestamp2Localestring(
                item.challengeAttendEtime * 1000
              );
              const newData = {
                title: item.challengeTitle,
                customer: item.challengeCreator,
                date: newDate,
              };
              return newData;
            });
          setRewardContents(newRewardDrawing);
        } else setRewardContents([]);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: dashboardMemoRefetch } = useQuery(
    KEY_DASHBOARD_MEMO_LIST,
    () => getDashboardMemoList(),
    {
      onSuccess: res => {
        console.log('dashboard memo list :', res);
        if (res !== null) {
          const newMemoData =
            res &&
            res.memo &&
            res.memo.map((item: any) => {
              const newData = {
                memo: item.memo,
                memoId: item.memoId,
              };
              return newData;
            });
          setMemoList(newMemoData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: dashboardShortcutRefetch } = useQuery(
    KEY_DASHBOARD_SHORTCUT_LIST,
    () => getDashboardShortcutList(),
    {
      onSuccess: res => {
        console.log('dashboard shortcut list :', res);
        if (res !== null) {
          const newShortcutData =
            res &&
            res.shortcut &&
            res.shortcut.map((item: any) => {
              const newData = {
                shortcutId: item.shortcutId,
                title: item.title,
                link: item.link,
              };
              return newData;
            });
          setShortcutList(newShortcutData);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: registerMemoMutation, isError: isRegisterMemoMutateError } =
    useMutation(postDashboardMemoRegister, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('메모가 저장되었습니다.');
        dashboardMemoRefetch();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '메모를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const { mutate: deleteMemoMutation, isError: isDeleteMemoMutateError } =
    useMutation(deleteDashboardMemo, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('메모가 삭제되었습니다.');
        dashboardMemoRefetch();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '메모를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: registerShortcutMutation,
    isError: isRegisterShortcutMutateError,
  } = useMutation(postDashboardShortcutRegister, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('바로가기가 저장되었습니다.');
      dashboardShortcutRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '바로가기를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteShortcutMutation,
    isError: isDeleteShortcutMutateError,
  } = useMutation(deleteDashboardShortcut, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('바로가기가 삭제되었습니다.');
      dashboardShortcutRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '바로가기를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const addMemo = () => {
    if (!memoText) {
      return;
    }
    const newData: IDashboardMemo = {
      memo: memoText,
    };
    registerMemoMutation(newData);
    setMemoText('');
  };

  const deleteMemo = (idx: number) => {
    const newData: IDashboardMemo = {
      memoId: idx,
    };
    deleteMemoMutation(newData);
  };

  const deleteShortcut = (idx: number) => {
    const newData: IDashboardShortcut = {
      shortcutId: idx,
    };
    deleteShortcutMutation(newData);
  };

  const resetBadgeCount = () => {
    setDashBoardInfo({
      count: 0,
    });
  };

  useEffect(() => {
    resetBadgeCount();
  }, []);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>대시보드</title>
      </Helmet>
      <Box sx={{ minWidth: '1000px' }} className='flex_center'>
        <Box sx={{ width: '100%', mr: '1rem' }}>
          <Grid container spacing={2}>
            <Grid md={6} xl={3}>
              <SwCard
                title='챌린지 요청'
                count={challengeContents.length}
                contents={challengeContents}
                navi='/challengemanagement/create'
                offset={0}
              />
            </Grid>
            <Grid md={6} xl={3}>
              <SwCard
                title='커뮤니티 요청'
                count={communityContents.length}
                contents={communityContents}
                navi='/communitymanage/create'
                offset={100}
              />
            </Grid>
            <Grid md={6} xl={3}>
              <SwCard
                title='프로그램'
                count={programContents.length}
                contents={programContents}
                navi='/programmanage/create'
                offset={200}
              />
            </Grid>
            <Grid md={6} xl={3}>
              <SwCard
                title='질의응답'
                count={questionAnswerContents.length}
                contents={questionAnswerContents}
                navi='/questionAnswer/create'
                offset={200}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid md={6} xl={3}>
              <SwCard
                title='스탬프 코스 요청'
                count={stamptourContents.length}
                contents={stamptourContents}
                navi='/stamptour/create'
                offset={400}
              />
            </Grid>
            <Grid md={6} xl={3}>
              <SwCard
                title='길 코스 요청'
                count={walkcourseContents.length}
                contents={walkcourseContents}
                navi='/walkcourse/create'
                offset={300}
              />
            </Grid>
            <Grid md={6} xl={3}>
              <SwCard
                title='챌린지 필드 요청'
                count={fieldContents.length}
                contents={fieldContents}
                navi='/challengeField/create'
                offset={200}
              />
            </Grid>
            <Grid md={6} xl={3}>
              <SwCard
                title='1:1문의'
                count={qnaContents.length}
                contents={qnaContents}
                navi='/privateinquiry'
                offset={200}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid md={6} xl={3}>
              <SwCard
                title='리워드 추첨'
                count={rewardContents?.length}
                contents={rewardContents}
                navi='/rewarddrawing'
                offset={500}
              />
            </Grid>
            <Grid md={6} xl={3}>
              <Card>
                <CardHeader
                  sx={{ p: '.8rem 1rem .8rem 1.5rem' }}
                  action={
                    <>
                      <Button
                        sx={{
                          fontSize: '.81rem',
                          p: '.2rem .6rem',
                          minWidth: 'inherit',
                        }}
                        color='warning'
                        onClick={() => setEditShortcut(!editShortcut)}
                      >
                        {editShortcut ? '완료' : '삭제'}
                      </Button>
                      <Button
                        sx={{
                          fontSize: '.81rem',
                          p: '.2rem .6rem',
                          minWidth: 'inherit',
                        }}
                        color='info'
                        onClick={() => setOpenDialog(true)}
                      >
                        추가
                      </Button>
                    </>
                  }
                  title={
                    <Typography fontWeight={500} sx={{ mr: '.3rem' }}>
                      바로가기
                    </Typography>
                  }
                />
                <Divider sx={{ p: 0, m: 0 }} />
                <CardContent
                  sx={{ height: '35vh', overflowY: 'scroll', p: '0' }}
                >
                  <Box>
                    {shortcutList?.map((shortcut: any, idx: number) => (
                      <Fragment key={idx.toString()}>
                        <Box className='flex_between' sx={{ p: '.6rem 1rem' }}>
                          <Typography>{shortcut.title}</Typography>
                          {editShortcut ? (
                            <IconButton
                              onClick={() => {
                                deleteShortcut(shortcut.shortcutId);
                              }}
                            >
                              <DeleteOutlineIcon sx={{ fontSize: '1rem' }} />
                            </IconButton>
                          ) : (
                            <IconButton onClick={() => navigate(shortcut.link)}>
                              <ArrowForwardIosIcon sx={{ fontSize: '1rem' }} />
                            </IconButton>
                          )}
                        </Box>
                        <Divider sx={{ p: 0, m: 0 }} />
                      </Fragment>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={6} xl={3}>
              <Card>
                <CardHeader
                  sx={{ p: '.8rem 1rem .8rem 1.5rem' }}
                  action={
                    <Button
                      sx={{ fontSize: '.81rem' }}
                      color='info'
                      onClick={addMemo}
                    >
                      저장
                    </Button>
                  }
                  title={
                    <Typography fontWeight={500} sx={{ mr: '.3rem' }}>
                      메모장
                    </Typography>
                  }
                />
                <Divider sx={{ p: 0, m: 0 }} />
                <CardContent sx={{ height: '35vh', p: '0' }}>
                  <Box
                    sx={{
                      height: '20vh',
                      p: '.4rem .8rem',
                      borderBottom: '1px solid #D2D9E1',
                      overflowY: 'scroll',
                    }}
                  >
                    {memoList?.map((item: any, idx: number) => (
                      <Box className='flex_center' key={idx.toString()}>
                        <Typography>{item.memo}</Typography>
                        <IconButton onClick={() => deleteMemo(item.memoId)}>
                          <ClearIcon sx={{ fontSize: '.8rem' }} />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                  <MultilineTextField
                    fullWidth
                    multiline
                    rows={10}
                    placeholder='이곳에 메모를 입력하세요.'
                    value={memoText}
                    onChange={evt => setMemoText(evt.target.value)}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        p: '0.3rem',
                        borderRadius: 0,
                      },
                      '& .MuiOutlinedInput-input': {
                        p: '0.3rem',
                      },
                      '& .MuiOutlinedInput-notchedOutline': {
                        border: 'none',
                        borderBottom: '1px solid #D2D9E1',
                      },
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent sx={{ p: '1.5rem 2.5rem' }}>
          <Typography
            fontSize='.94rem'
            sx={{ p: '.4rem 1.5rem .4rem .4rem', mb: '1rem' }}
          >
            바로가기에 추가할 탭을 선택해 주세요.
          </Typography>
          <SelectTextField
            select
            inputRef={selectRef}
            defaultValue={tabs[0].id}
            fullWidth
          >
            {tabs.map((tab, idx) => (
              <MenuItem key={idx.toString()} value={tab.id}>
                {tab.title}
              </MenuItem>
            ))}
          </SelectTextField>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions sx={{ p: '.4rem !important' }}>
          <Button fullWidth color='error' onClick={() => setOpenDialog(false)}>
            취소
          </Button>
          <Button
            fullWidth
            onClick={() => {
              const newSelect = selectRef.current.value;
              const newShortcut = tabs.filter(tab => tab.id === newSelect);
              if (
                shortcutList.findIndex((s: any) => s.id === newSelect) !== -1
              ) {
                return;
              }
              const newData: IDashboardShortcut = {
                title: newShortcut[0].title,
                link: newShortcut[0].id,
              };
              registerShortcutMutation(newData);
              setOpenDialog(false);
            }}
          >
            확인
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlertDialog}
        onConfirm={() => setOpenAlertDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </div>
  );
};
