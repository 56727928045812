import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  styled,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';

interface Props {
  open: boolean;
  onClose?: () => void;
  onConfirm: () => void;
  title?: string;
  contents: any;
  confirm?: number;
  confirmWord?: string;
}
const AlertDialog = styled(Dialog)({
  margin: '0 0 10rem 10rem',
  '& .MuiDialog-paper': {
    width: '30vw',
    height: '15vh',
  },
  '& .MuiDialogTitle-root': {
    padding: '.8rem 1rem !important',
    fontSize: '.93rem',
    marginLeft: '.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: '0.33rem !important',
  },
});

const CancelButton = styled(Button)({
  borderRight: '1px solid #EBECF0',
  paddingRight: '8px',
});

export const SwAlert: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  title,
  contents,
  confirm,
  confirmWord = '확인',
}) => {
  const a = 1;
  return (
    <AlertDialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      {title && <Divider sx={{ p: 0, m: 0 }} />}
      <DialogContent
        sx={{ display: 'flex', alignItems: 'center', whiteSpace: 'pre-wrap' }}
      >
        {contents}
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        {confirm && (
          <CancelButton color='info' fullWidth size='small' onClick={onClose}>
            취소
          </CancelButton>
        )}
        <Button
          color='primary'
          fullWidth
          size='small'
          onClick={onConfirm}
          // sx={{ pl: '.5rem' }}
        >
          {confirmWord}
        </Button>
      </DialogActions>
    </AlertDialog>
  );
};
