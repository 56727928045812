import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import { xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  commaFormat,
  generateUuidId,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
  GrayTypography,
} from './ProgramStyles';
import { IUploadFile } from '../../../models/common';
import {
  getProgramTarget,
  postUploadFile,
} from '../../../common/api/ApiCommon';
import {
  registerProgramMaster,
  registerTemplateCodeMaster,
  updateProgramTemplateCodeStatus,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';

import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import { SwSearchProgram } from '../../commonComponent/SwSearchProgram';
import {
  getProgramTargetName,
  makeTemplateCode,
  programContentConfiguration,
  programDifficulty,
  programTarget,
  programTemplateCodeClass,
  getProgramDifficultyName,
  getProgramTargetCode,
  getProgramDifficultyCode,
  getProgramClassCode,
  programCustomeContent,
} from '../../../common/helperProgram';
import { SwImageSlider } from '../../commonComponent/others/SwImageSlider';
import { SwProgramMagazine } from '../../commonComponent/program/SwProgramMagazine';

interface Props {
  templateCodeInfo: any;
  setTemplateCodeInfo: Dispatch<React.SetStateAction<any>>;
  reloadTemplateCode: (id: string) => void;
}

const MAX_IMAGE_PAGE = 10;

export const ProgramTemplateCodeForm: React.FC<Props> = ({
  templateCodeInfo,
  setTemplateCodeInfo,
  reloadTemplateCode,
}) => {
  const location = useLocation();
  const [templateCodeType, setTemplateCodeType] = useState<string>('선택');
  const [target, setTarget] = useState<string>('전체');
  const [difficulty, setDifficulty] = useState<string>('기본');
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>('');
  const [templateDescription, setTemplateDescription] = useState<string>('');
  const [templateStatus, setTemplateStatus] = useState<number>(0);

  const [curriculumId, setCurriculumId] = useState<string>('');
  const [programMainImage, setProgramMainImage] = useState<string[]>([]);
  const [imageList, setImageList] = useState<string[]>([]);

  const [selectedTemplateCode, setSelectedTemplateCode] =
    useState<boolean>(false);
  const [templateCode, setTemplateCode] = useState<string>('');
  const [customContentTitle, setCustomContentTitle] = useState<any[]>([]);

  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [targetTitle, setTargetTitle] = useState<string>('');
  const [openType, setOpenType] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [defaultCurriculumId, setDefaultCurriculumId] = useState<string>(
    generateUuidId()
  );
  const [disabled, setDisabled] = useState<boolean>(false);
  const [openProgramMagazine, setOpenProgramMagazine] = useState<number>(0);

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const { mutate: registerTemplateCodeMutation, isError: isMasterMutateError } =
    useMutation(registerTemplateCodeMaster, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('템플릿 코드 정보가 저장되었습니다.');
          reloadTemplateCode(
            templateCodeInfo !== undefined
              ? templateCodeInfo.templateCode
              : defaultCurriculumId
          );
        } else {
          showError(
            `템플릿 코드정보를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        showError(
          `템플릿 코드 정보를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
        );
      },
    });

  const addTemplateCode = (newImageList: string[]) => {
    const newMainImage =
      newImageList.length > 0 ? JSON.stringify(newImageList) : [];

    if (newMainImage === undefined || newMainImage.length === 0) {
      setAlertMessage('템플릿 코드 이미지를 선택해주세요');
      setAlertOpen(true);
      return;
    }
    const newConf = JSON.stringify(checkedButtons);
    const newTarget = getProgramTargetCode(target);
    const newDifficulty = getProgramDifficultyCode(difficulty);
    const newClassCode = getProgramClassCode(templateCodeType);
    const newCustomeTitle = programCustomeContent.map(
      (item: any, idx: number) => {
        const newTitle =
          customContentTitle.length > 0 ? customContentTitle[idx].title : '';
        const newData: any = {
          type: item.type,
          title: newTitle,
        };
        return newData;
      }
    );

    const newData: any = {
      templateCode:
        templateCodeInfo !== undefined
          ? templateCodeInfo.templateCode
          : defaultCurriculumId,
      typeName: templateCodeType,
      templateName: templateName,
      weekCount:
        templateCodeInfo !== undefined ? templateCodeInfo.weekCount : 16,
      classCode: newClassCode,
      target: newTarget,
      description: templateDescription,
      difficulty: newDifficulty,
      defaultCurriculum:
        templateCodeInfo !== undefined
          ? templateCodeInfo.defaultCurriculum
          : defaultCurriculumId,
      image: newMainImage,
      status: templateStatus,
      configuration: newConf,
      appMagazineTitle: JSON.stringify(newCustomeTitle),
      registerStatus:
        templateCodeInfo !== undefined ? templateCodeInfo.registerStatus : 1,
    };

    setTemplateDescription(
      templateCodeInfo !== undefined ? templateCodeInfo.description : ''
    );

    console.log('registerTemplateCode :', newData);
    registerTemplateCodeMutation(newData);
  };

  const checkValid = () => {
    var val = 0;

    if (val === 0 && templateName.length === 0) val = 10;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '템플릿 코드 아이디를 입력해주세요.';
          break;
        case 2:
          message = '템플릿 코드 약관에서 기관명을 입력해주세요';
          break;
        case 10:
          message = '템플릿 코드 이름을 입력해주세요';
          break;
        case 11:
          message = '템플릿 코드 메인 이미지를 선택해주세요';
          break;
        case 12:
          message = '연결한 커뮤니티를 선택해주세요';
          break;
        case 13:
          message = '템플릿 코드 노출기간을 지정해주세요';
          break;
        case 14:
          message = '템플릿 코드 참여기간을 지정해주세요';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setAlertMessage(message);
      setAlertOpen(true);
    }

    return val;
  };

  async function uploadMultipleFiles() {
    try {
      const newImageList = await Promise.all(
        imageList.map(async (item: any, idx: number) => {
          let newUploadUrl = item;
          if (item.includes('blob:')) {
            const response = await axios.get(item, { responseType: 'blob' });
            const blob = response.data;

            const newUploadFile: any = await uploadFile('program', blob);
            newUploadUrl = newUploadFile.data.uploadUrl.file.path;
          }
          return newUploadUrl;
        })
      );

      console.log('newImageList:', newImageList);
      addTemplateCode(newImageList);
    } catch (error) {
      console.log('upload fail:', error);
    }
  }

  const checkProgram = () => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }

    uploadMultipleFiles();
  };

  const saveTemplateCode = () => {
    console.log('saveProgram !!!');
    checkProgram();
  };

  const {
    mutate: updateTemplateCodeStatusMutation,
    isError: isStatusMutateError,
  } = useMutation(updateProgramTemplateCodeStatus, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('템플릿 코드 상태가 변경되었습니다.');
        reloadTemplateCode(templateCodeInfo.templateCode);
      } else {
        showError(
          `템플릿 코드 상태를 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      showError(
        `템플릿 코드 상태를 변경하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const changeTemplateStatus = (status: number) => {
    if (templateCodeInfo === undefined || templateCodeInfo === null) {
      setOpenSnackbar(true);
      setAlertMessage('템플릿 코드를 선택해주세요');
      return;
    }

    setTemplateStatus(status);
    const newData = {
      templateCode: templateCodeInfo.templateCode,
      status: status,
    };
    updateTemplateCodeStatusMutation(newData);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      console.log('handleKeyDown !!!');
    }
  };

  const handleCheckBoxSelectAllClick = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      programContentConfiguration?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeCheckBoxHandler = (checked: boolean, id: string) => {
    if (checked) {
      const newData = [...checkedButtons, id];
      setCheckedButtons(newData);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  useEffect(() => {
    console.log('imageList: ', imageList);
  }, [imageList]);

  useEffect(() => {
    console.log('templateCodeInfo -> :', templateCodeInfo);
    if (templateCodeInfo) {
      console.log('templateCodeInfo -> :', templateCodeInfo);
      setTemplateCode(templateCodeInfo.templateCode);
      setTemplateCodeType(templateCodeInfo.typeName);
      setTarget(getProgramTargetName(templateCodeInfo.target));
      setDifficulty(getProgramDifficultyName(templateCodeInfo.difficulty));

      const newConf = templateCodeInfo.configuration
        ? JSON.parse(templateCodeInfo.configuration)
        : [];
      setCheckedButtons(newConf);
      setTemplateName(templateCodeInfo.templateName);
      setTemplateDescription(templateCodeInfo.description);
      setTemplateStatus(templateCodeInfo.status);

      const newImage =
        templateCodeInfo.image !== null
          ? JSON.parse(templateCodeInfo.image)
          : [];
      setImageList(newImage);
      setDisabled(templateCodeInfo.programCount > 0);

      const newAppMagazineTitle =
        templateCodeInfo.appMagazineTitle !== undefined &&
        templateCodeInfo.appMagazineTitle !== null
          ? JSON.parse(templateCodeInfo.appMagazineTitle)
          : [];
      const newTitle = programCustomeContent.map((item: any, idx: number) => {
        const title =
          newAppMagazineTitle.length > 0 ? newAppMagazineTitle[idx].title : '';
        const newData: any = {
          type: item.type,
          title: title,
        };
        return newData;
      });
      setCustomContentTitle(newTitle);
    } else {
      setDisabled(false);
      const newTitle = programCustomeContent.map((item: any, idx: number) => {
        const newData: any = {
          type: item.type,
          title: '',
        };
        return newData;
      });
      setCustomContentTitle(newTitle);
      location.pathname.includes('create')
        ? setSelectedTemplateCode(true)
        : setSelectedTemplateCode(false);
    }
    return () => {};
  }, [templateCodeInfo]);
  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>분류</Typography>
          <TextField
            disabled={disabled}
            select
            defaultValue='선택'
            value={templateCodeType}
            sx={{ width: '40rem' }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setTemplateCodeType(evt.target.value);
            }}
          >
            {programTemplateCodeClass &&
              programTemplateCodeClass.map((item: any, idx: number) => (
                <MenuItem key={`template_code_${idx}`} value={item}>
                  {item}
                </MenuItem>
              ))}
          </TextField>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>대상</Typography>
          <TextField
            disabled={disabled}
            select
            defaultValue='전체'
            value={target}
            sx={{ width: '40rem' }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setTarget(evt.target.value);
            }}
          >
            {programTarget &&
              programTarget.map((item: any, idx: number) => (
                <MenuItem key={`target_${idx}`} value={item}>
                  {item}
                </MenuItem>
              ))}
          </TextField>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>단계</Typography>
          <TextField
            disabled={disabled}
            defaultValue='기본'
            select
            value={difficulty}
            sx={{ width: '40rem' }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setDifficulty(evt.target.value);
            }}
          >
            {programDifficulty &&
              programDifficulty.map((item: any, idx: number) => (
                <MenuItem key={`difficulty_${idx}`} value={item}>
                  {item}
                </MenuItem>
              ))}
          </TextField>
          <Divider />
        </Box>
        <Box>
          <Box>
            <Typography className='title'>콘텐츠 구성</Typography>
            <FormGroup sx={{ flexDirection: 'row' }}>
              {programContentConfiguration.map((row: any, idx: number) => (
                <FormControlLabel
                  key={`configuration_${idx}`}
                  control={
                    <Checkbox
                      disabled={disabled}
                      value={programContentConfiguration[idx]}
                      checked={checkedButtons.includes(
                        programContentConfiguration[idx]
                      )}
                      onChange={evt =>
                        changeCheckBoxHandler(
                          evt.currentTarget.checked,
                          programContentConfiguration[idx]
                        )
                      }
                    />
                  }
                  label={programContentConfiguration[idx]}
                />
              ))}
            </FormGroup>
          </Box>
          <Divider />
        </Box>
        <Box>
          <Box>
            <Box className='flex_start' sx={{ mb: '.5rem' }}>
              <Typography className='title'>맞춤 콘텐츠별 타이틀</Typography>
              <Button
                // disabled={disabled}
                sx={{ ml: '1rem' }}
                variant='contained'
                color='info'
                onClick={() => setOpenProgramMagazine(-1)}
              >
                매거진 목록
              </Button>
            </Box>
            {customContentTitle.length > 0 &&
              programCustomeContent.map((item: any, idx: number) => (
                <Box className='flex_start' key={`custom_content_${idx}`}>
                  <Typography className='title' sx={{ width: '10rem' }}>
                    {item.title}
                  </Typography>
                  <TextField
                    disabled={disabled}
                    sx={{ width: '48rem' }}
                    value={customContentTitle[idx].title}
                    onChange={(
                      evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => {
                      const newTitle = [...customContentTitle];
                      newTitle[idx].title = evt.target.value.substring(0, 30);
                      setCustomContentTitle(newTitle);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>
                            {customContentTitle[idx].title.length} / 30
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              ))}
          </Box>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>템플릿 이름</Typography>
          <TextField
            disabled={disabled}
            sx={{ width: '48rem' }}
            value={templateName}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setTemplateName(evt.target.value.substring(0, 20))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{templateName?.length} / 20</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>템플릿 소개</Typography>
          <MultilineTextField
            disabled={disabled}
            fullWidth
            multiline
            rows={6}
            value={templateDescription}
            onChange={evt =>
              setTemplateDescription(evt.target.value.substring(0, 1000))
            }
            placeholder='템플릿 소개글을 1000자 이내로 작성해주세요.'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{templateDescription?.length} / 1000</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>

        <Box>
          <Typography className='title'>상세 콘텐츠</Typography>
          <SwImageSlider
            disabled={disabled}
            imageList={imageList}
            setImageList={setImageList}
          />
          <GrayTypography sx={{ mt: '1rem' }}>
            *1:1(정사각) 비율 권장
          </GrayTypography>
          <GrayTypography>
            *용량 10MB 이하의 JPG, PNG만 업로드 가능
          </GrayTypography>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            sx={{ width: '12rem' }}
            select
            value={templateStatus === undefined ? 1 : templateStatus}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              changeTemplateStatus(Number(evt.target.value));
            }}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
        {disabled === false && (
          <Box>
            <Box sx={{ mt: '1.5rem' }}>
              <ButtonBox className='flex_end'>
                <Box>
                  <Button
                    variant='contained'
                    onClick={() => saveTemplateCode()}
                  >
                    임시 저장
                  </Button>
                </Box>
              </ButtonBox>
            </Box>
          </Box>
        )}
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwSearchProgram
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwProgramMagazine
        open={!!openProgramMagazine}
        onClose={() => setOpenProgramMagazine(0)}
        templateCode={templateCode}
        subType={openProgramMagazine}
      />
    </>
  );
};
