import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';
import {
  Box,
  Button,
  Chip,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const TagsTextField = styled(TextField)({
  '& .MuiInputLabel-root': { top: '-5px' },
  '& .MuiOutlinedInput-root': { pr: '1rem' },
  width: '20rem',
});

const TagsChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const TagsAddButton = styled(Button)({
  marginTop: '.3rem',
  marginLeft: '.7rem',
  padding: '.1rem 0',
  borderRadius: '20px',
});

interface Props {
  title?: string;
  description?: string;
  placeholder?: string;
  keyword: string;
  maxLimit?: number;
  tags: string[];
  setTags: Dispatch<React.SetStateAction<string[]>>;
}

export const SwSelectTags: React.FC<Props> = ({
  title,
  description = '추가할 항목을 입력해주세요',
  placeholder = '',
  keyword,
  maxLimit = 5,
  tags,
  setTags,
}) => {
  const [tagName, setTagName] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const addTags = () => {
    const newTag = tagName;
    const newTags = [...tags];
    if (newTags.length === maxLimit) {
      setErrorMessage(`${keyword}은 최대 ${maxLimit}개까지 등록 가능합니다.`);
    } else if (newTags.filter(tag => tag === newTag).length) {
      setErrorMessage(`중복된 ${keyword}입니다.`);
    } else {
      newTags.push(newTag);
      setErrorMessage('');
    }
    setTags(newTags);
  };

  return (
    <>
      <Box>
        <Typography className='title'>{title}</Typography>
        {description.length > 0 && (
          <Typography sx={{ mb: '.5rem' }}>{description}</Typography>
        )}
        <TextField
          placeholder={placeholder}
          value={tagName}
          onChange={evt => setTagName(evt.target.value.substring(0, 20))}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Typography>{tagName.length} / 20</Typography>
              </InputAdornment>
            ),
          }}
          sx={{ width: '30vw' }}
        />
        <TagsAddButton onClick={addTags} variant='outlined' color='info'>
          추가
        </TagsAddButton>
      </Box>
      {errorMessage !== '' && (
        <Typography sx={{ color: 'red' }}>{errorMessage}</Typography>
      )}
      <Box>
        {tags.length > 0 &&
          tags.map((tag: String, idx: number) => (
            <TagsChip
              label={tag}
              variant='outlined'
              deleteIcon={<CloseIcon />}
              onDelete={() => {
                const newTags = tags.filter(
                  (_tag: String, i: number) => i !== idx
                );
                setTags([...newTags]);
              }}
              key={idx.toString()}
            />
          ))}
      </Box>
    </>
  );
};
