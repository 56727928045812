import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useSession } from '../../../hooks/session';

import { MultilineTextField } from '../../styles/Styles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { IUploadFile } from '../../../models/common';
import {
  deleteLocalStoreNotice,
  postLocalStoreNotice,
  putLocalStoreNotice,
} from '../../../common/api/ApiLocalstore';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
  notice: any;
}

export const NoticeDrawup: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
  notice,
}) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const [noticeIdx, setNoticeIdx] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageFile, setImageFile] = useState<any>(null);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>(
    loginVal.value.user.imageUrlPrefix
  );

  const { mutate: postLocalStoreNoticeMutate, isError: isMutateError } =
    useMutation(postLocalStoreNotice, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('공지사항을 등록했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '공지사항 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const { mutate: putLocalStoreNoticeMutate, isError: isPutMutateError } =
    useMutation(putLocalStoreNotice, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('공지사항을 수정했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '공지사항 수정이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const registerPortalNotice = (newUploadUrl: string) => {
    const newImage =
      imageFile !== null
        ? newUploadUrl
        : image === ''
        ? ''
        : notice?.image.length > 0
        ? notice?.image
        : '';

    const newData: any = {
      idx: Number(noticeIdx),
      title: titleRef.current?.value,
      image: newImage,
      content: contentRef.current?.value,
    };

    const newNotice: any = {
      notice: newData,
      idx: Number(noticeIdx),
    };
    noticeIdx !== ''
      ? putLocalStoreNoticeMutate(newNotice)
      : postLocalStoreNoticeMutate(newNotice);
  };

  const { mutate: uploadFileMutate, isError: isUploadMutateError } =
    useMutation(postUploadFile, {
      onSuccess: res => {
        setUploadUrl(res.uploadUrl.file.path);
        registerPortalNotice(res.uploadUrl.file.path);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const checkLocalStoreNotice = () => {
    if (imageFile === null) {
      registerPortalNotice('');
    } else {
      const uploadfile: IUploadFile = {
        type: 'localstore',
        file: imageFile,
      };
      uploadFileMutate(uploadfile);
    }
  };

  const {
    mutate: deleteLocalStoreNoticeMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteLocalStoreNotice, {
    onSuccess: () => {
      setOpenDialog(true);
      setDialogMessage('공지사항을 삭제했습니다.');
      onConfirm();
    },
    onError: error => {
      setOpenDialog(true);
      setDialogMessage(
        '공지사항을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteNotice = (idx: number) => {
    const newData: any = {
      idxList: [idx],
    };
    deleteLocalStoreNoticeMutation(newData);
  };

  useEffect(() => {
    setNoticeIdx(notice?.idx === undefined ? '' : notice?.idx);
    if (!edit && notice === null) {
      setImage('');
      setImageFile(null);
    } else {
      const newImage =
        notice?.image?.length > 0 ? `${imageUrlPrefix}${notice?.image}` : '';
      setImage(newImage);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          minHeight: '56vh',
          minWidth: '44vw',
        },
      }}
    >
      <DialogTitle sx={{ pl: '24px !important' }}>
        <Box className='flex_between'>
          <span>
            {!edit && notice === null
              ? '공지사항 작성'
              : !edit && notice?.idx !== null
              ? '공지사항 수정'
              : '공지사항'}
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent>
        {edit ? (
          <>
            <Typography
              sx={{ fontWeight: 700, mb: '.7rem', fontSize: '1.1rem' }}
            >
              {notice?.title}
            </Typography>
            <Typography sx={{ mb: '.7rem', color: '#999999' }}>
              {notice?.createDate}
            </Typography>
            <Divider />
            <MultilineTextField
              multiline
              rows={10}
              fullWidth
              value={notice?.content}
            />
            <Divider />

            {notice?.image?.length > 0 ? (
              <Paper
                sx={{
                  widht: '100%',
                  height: '30vh',
                  overflowY: 'scroll',
                  mt: '1rem',
                  mb: '1rem',
                }}
              >
                <img
                  src={
                    notice?.image?.length > 0
                      ? `${imageUrlPrefix}${notice?.image}`
                      : ''
                  }
                  alt=''
                  style={{ width: '100%' }}
                />
              </Paper>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>제목</Typography>
            <TextField
              fullWidth
              inputRef={titleRef}
              defaultValue={notice?.title}
            />
            <Divider />
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>내용</Typography>
            <MultilineTextField
              inputRef={contentRef}
              multiline
              rows={8}
              fullWidth
              defaultValue={notice?.content}
            />
            <Divider />
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
              이미지(선택)
            </Typography>
            <SwFileSetting
              image={image}
              setImage={setImage}
              setFile={setImageFile}
              file={imageFile}
            />
          </>
        )}
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        {edit ? (
          <>
            <Button
              onClick={() => {
                deleteNotice(notice?.idx);
              }}
              color='info'
              variant='outlined'
            >
              삭제
            </Button>
            <Button onClick={isEdit} color='info' variant='contained'>
              수정
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} color='info' variant='outlined'>
              닫기
            </Button>
            <Button
              onClick={() => checkLocalStoreNotice()}
              color='info'
              variant='contained'
            >
              저장
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
