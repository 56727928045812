import {
  Box,
  Button,
  Collapse,
  MenuItem,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import React, { Dispatch, useState } from 'react';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { MobileDateRangePicker } from '@mui/x-date-pickers-pro/MobileDateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DateTextField,
  SelectTextField,
  TableTopButton,
} from '../../../styles/Styles';
import { SwPagination } from '../../../commonComponent/tableElement/SwPagination';

interface Props {
  columns: any[];
  rows: any[];
  setRows: Dispatch<React.SetStateAction<string[]>>;
}

const ColumsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  whiteSpace: 'nowrap',
});

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
  overflowX: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'pre',
  maxWidth: '10rem',
});

const RowsNumberCell = styled(TableCell)({
  fontSize: '.88rem',
  textAlign: 'center',
  fontWeight: 400,
  padding: '.6rem',
  whiteSpace: 'nowrap',
});

export const DrawUserTable: React.FC<Props> = ({ columns, rows, setRows }) => {
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };
  const rowsPerPageOptions = [5, 10, 15, 20];

  const deleteRow = (idx: number) => {
    const newRows = rows.filter((item: any, index: number) => idx !== index);
    setRows(newRows);
  };
  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <ColumsCell key={idx.toString()}>{column}</ColumsCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((row: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  <RowsCell key={0}>{idx + 1}</RowsCell>
                  <RowsCell key={1}>{row.phoneno}</RowsCell>
                  <RowsCell key={2}>
                    <Button
                      id={idx.toString()}
                      size='small'
                      variant='contained'
                      onClick={(evt: any) => {
                        deleteRow(idx);
                      }}
                    >
                      삭제하기
                    </Button>
                  </RowsCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <SwPagination
        page={page}
        handleChangePage={handleChangePage}
        count={Math.ceil(rows.length / rowsPerPage)}
      />
    </>
  );
};
