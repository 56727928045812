import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useResize } from '../../../hooks/resize';
import { NoticeDrawUp } from './NoticeDrawUp';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { INoticeRequest, INotice } from '../../../models/notice';
import { getNoticeList, deleteNotice } from '../../../common/api/ApiNotice';
import { KEY_NOTICE_LIST } from '../../../common/key';

interface Props {}

const columns = ['제목', '작성자', '작성일시', '공개'];

export const Notice: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [openNoticeDialog, setOpenNoticeDialog] = useState<boolean>(false);
  const [noticeList, setNoticeList] = useState<any>([]);
  const [selectNotice, setSelectNotice] = useState<any>();
  const [edit, setEdit] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  function getDivision(notice: any) {
    return notice.ios === 0 && notice.android === 1
      ? '안드로이드'
      : notice.ios === 1 && notice.android === 0
      ? '아이폰'
      : notice.ios === 1 && notice.android === 1
      ? '전체'
      : '미공개';
  }

  const {
    data,
    isLoading,
    refetch: noticeRefetch,
  } = useQuery(
    KEY_NOTICE_LIST,
    () => {
      const newData: INoticeRequest = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getNoticeList(newData);
    },
    {
      onSuccess: res => {
        console.log('notce res:', res);
        if (res != null) {
          setNoticeList(res.notice);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: deleteNoticeMutate, isError: isMutateError } = useMutation(
    deleteNotice,
    {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('공지사항을 삭제했습니다.');
        noticeRefetch();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '공지사항 삭제에 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        noticeRefetch();
      },
    }
  );

  const checkDeleteNotice = () => {
    const newNoticeIds = checkedButtons;
    const newData: INotice = {
      noticeIds: newNoticeIds,
    };
    deleteNoticeMutate(newData);
  };

  const closeNotice = () => {
    setOpenNoticeDialog(false);
    setEdit(true);
    setSelectNotice(null);
  };

  const confirmNotice = () => {
    setOpenNoticeDialog(false);
    setEdit(true);
    setSelectNotice(null);
    noticeRefetch();
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      noticeList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.noticeId]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    noticeRefetch();
  }, [page]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>공지사항</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '1rem' }}>
        <Button variant='contained' onClick={() => setOpenNoticeDialog(true)}>
          <AddIcon />
          작성하기
        </Button>
      </Box>
      <Paper sx={{ m: '1rem 0', p: '1rem' }}>
        <Box className='flex_between'>
          <TableTitle title='게시물' count={123} />
          <SelectTextField
            select
            value={rowsPerPage}
            onChange={evt => {
              setRowsPerPage(Number(evt.target.value));
              setPage(1);
            }}
          >
            {rowsPerPageOptions.map(option => (
              <MenuItem key={option.toString()} value={option}>
                {option}개씩 보기
              </MenuItem>
            ))}
          </SelectTextField>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  onChange={handleSelectAllClick}
                  checked={checkedAll}
                />
              </TableCell>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {noticeList?.map((notice: any, idx: number) => (
              <TableRow key={idx.toString()}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    id={notice.noticeId}
                    onChange={e => {
                      changeHandler(e.currentTarget.checked, notice.noticeId);
                    }}
                    checked={checkedButtons.includes(notice.noticeId)}
                  />
                </TableCell>
                <TableCell
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    setEdit(false);
                    setSelectNotice(noticeList[idx]);
                    setOpenNoticeDialog(true);
                  }}
                >
                  {notice.title}
                </TableCell>
                <TableCell>{notice.creator}</TableCell>
                <TableCell>
                  {timestamp2Localestring(notice.timestamp, 1000)}
                </TableCell>
                <TableCell>{getDivision(notice)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={noticeList && Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '1rem' }}>
        <Button variant='outlined' color='info' onClick={checkDeleteNotice}>
          삭제
        </Button>
      </Box>
      <NoticeDrawUp
        open={openNoticeDialog}
        onClose={closeNotice}
        onConfirm={confirmNotice}
        edit={edit}
        isEdit={() => setEdit(!edit)}
        selected={selectNotice}
      />
      <SwSnackbar
        open={false}
        onClose={() => setOpenDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </div>
  );
};
