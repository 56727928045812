import React from 'react';
import {
  DataGrid,
  GridColDef,
  gridPageCountSelector,
  gridPageSelector,
  GridValueGetterParams,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';
import { Pagination, PaginationItem } from '@mui/material';

interface Props {}

function getFullName(params: GridValueGetterParams) {
  return `${params.row.firstName || ''} ${params.row.lastName || ''}`;
}

const columns: GridColDef[] = [
  { field: 'firstName', headerName: 'First name', width: 130 },
  { field: 'lastName', headerName: 'Last name', width: 130 },
  {
    field: 'fullName',
    headerName: 'Full name',
    width: 160,
    valueGetter: getFullName,
  },
];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon' },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei' },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime' },
  { id: 4, lastName: 'Stark', firstName: 'Arya' },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys' },
  { id: 6, lastName: 'Targaryen', firstName: 'Daeerys' },
  { id: 7, lastName: 'Targaryen', firstName: 'Daenrys' },
  { id: 8, lastName: 'Targaryen', firstName: 'Daerys' },
  { id: 9, lastName: 'Targryen', firstName: 'Daenrys' },
  { id: 10, lastName: 'Targaryen', firstName: 'Daeerys' },
  { id: 11, lastName: 'Tararyen', firstName: 'Daeerys' },
  { id: 12, lastName: 'Targaryen', firstName: 'Danerys' },
  { id: 13, lastName: 'Tararyen', firstName: 'Daenrys' },
  { id: 14, lastName: 'Tararyen', firstName: 'Daeerys' },
  { id: 15, lastName: 'Tararyen', firstName: 'Daenys' },
  { id: 16, lastName: 'Tararyen', firstName: 'Daeerys' },
  { id: 17, lastName: 'Tararyen', firstName: 'Danerys' },
  { id: 18, lastName: 'Tararyen', firstName: 'Daeerys' },
  { id: 19, lastName: 'Targryen', firstName: 'Daeerys' },
  { id: 20, lastName: 'Targaen', firstName: 'Daeners' },
  { id: 21, lastName: 'Targaryen', firstName: 'Daenrys' },
  { id: 22, lastName: 'Targayen', firstName: 'Daenrys' },
  { id: 23, lastName: 'Targaren', firstName: 'Daeerys' },
  { id: 24, lastName: 'Targaryn', firstName: 'Daeerys' },
  { id: 25, lastName: 'Targarn', firstName: 'Daenrys' },
  { id: 26, lastName: 'Targayen', firstName: 'Daeerys' },
  { id: 27, lastName: 'Targryen', firstName: 'Daeerys' },
  { id: 28, lastName: 'Tararyen', firstName: 'Danerys' },
  { id: 29, lastName: 'Targryen', firstName: 'Danerys' },
  { id: 30, lastName: 'Targayen', firstName: 'Daeerys' },
];
function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color='primary'
      variant='outlined'
      shape='rounded'
      page={page + 1}
      count={pageCount}
      // @ts-expect-error
      renderItem={props2 => <PaginationItem {...props2} disableRipple />}
      onChange={(event: React.ChangeEvent<unknown>, value: number) =>
        apiRef.current.setPage(value - 1)
      }
    />
  );
}

export const Test: React.FC<Props> = () => {
  const a = 1;

  return (
    <div style={{ height: 300, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        rowCount={100}
        components={{
          Pagination: CustomPagination,
        }}
      />
    </div>
  );
};
