import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { MultilineTextField } from '../styles/Styles';
import { SwDatesPicker } from './dateSetting/SwDatesPicker';
import { SwSnackbar } from './views/SwSnackbar';
import { SwAlert } from './views/SwAlert';
import { RESULT_OK } from '../../common/resultCode';
import { addChallengeAttendUser } from '../../common/api/ApiChallenge';
import { MobileRegexp } from '../../common/helper';

interface Props {
  open: boolean;
  onclose: () => void;
  setLoadList: Dispatch<React.SetStateAction<any>>;
  maxSize?: number;
}

export const SwLoadUser: React.FC<Props> = ({
  open,
  onclose,
  setLoadList,
  maxSize = 500,
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<any>('');
  const [invalidData, setInvalidData] = useState<any[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const checkContent = () => {
    const newContent = content.split('\n');

    const reducer = (s: any[], val: string, idx: number) => {
      if (s.findIndex(q => q.value === val) === -1) {
        if (MobileRegexp.test(val)) {
          s.push(val.replaceAll('-', ''));
        } else {
          val !== '' && val.length > 0 ? invalidData.push(val) : '';
        }
      }
      return s;
    };
    const sortList = newContent.reduce(reducer, []);

    if (sortList.length > maxSize) {
      setAlertOpen(true);
      setAlertMessage(`데이터가 ${maxSize}개를 초과하여 업로드가 불가합니다.`);
      return;
    }

    if (sortList.length > 0 && invalidData.length > 0) {
      setAlertOpen(true);
      setAlertMessage(
        `문제 데이터를 제외하고 업로드 완료했습니다. \n문제 데이터 : ${invalidData}`
      );
    }

    if (sortList.length === 0 && invalidData.length > 0) {
      setAlertOpen(true);
      setAlertMessage(
        `문제 데이터를 제외하고 업로드할 데이터가 없습니다. \n문제 데이터 : ${invalidData}`
      );
      return;
    }

    if (sortList.length === 0) {
      setAlertOpen(true);
      setAlertMessage(`업로드할 데이터가 없습니다.`);
      return;
    }

    setLoadList(sortList);
    onclose();
  };

  useEffect(() => {
    if (open) {
      setContent('');
    }
    setInvalidData([]);
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onclose}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box>
              <Typography>휴대폰 번호 추가</Typography>
            </Box>
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box className='flex_between'>
            <Box>
              <Typography sx={{ m: '1rem 0 ' }}>
                {`휴대폰 번호를 입력해주세요(최대 ${maxSize}명)`}
              </Typography>

              <MultilineTextField
                placeholder='예시
                01012341234
                01023452345'
                multiline
                rows={20}
                value={content}
                onChange={evt => setContent(evt.target.value)}
                sx={{
                  height: '100%',
                  width: '20vw',
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={() => checkContent()}
          >
            완료
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
