import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import {
  getUserDetailInfo,
  patchUserBlocking,
  patchUserDelete,
  patchUserInfo,
  patchUserProfileEmdCode,
} from '../../../common/api/ApiUser';
import { KEY_USER_DETAIL_INFO } from '../../../common/key';
import { SwSearchPostCode } from '../../commonComponent/SwSearchPostCode';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  convertIphoneModel,
  getDeletedReason,
  removeNatCodePhoneNumber,
  timestamp2Localestring,
  validCheck,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import SwLoading from '../../commonComponent/spinner/SwLoading';

interface Props {
  userInfo: any;
  open: boolean;
  onClose: () => void;
}

// const tabTitle = [
//   [
//     { title: '스탭 센서', content: 'ss' },
//     { title: '가속도 센서', content: 'ss' },
//     { title: '삼성헬스', title2: '애플워치', content: 'ss' },
//     { title: '구글 피트니스', title2: '미밴드', content: 'ss' },
//     { title: '인랩워치', title2: '세븐 일렉', content: 'ss' },
//   ],
//   [
//     { title: '단말기 모델 정보', content: 'ss' },
//     { title: '단말기 OS 정보', content: 'ss' },
//     { title: '단말기 고유식별번호(IMEI)', content: 'ss' },
//     { title: '앱 버전 정보', content: 'ss' },
//     { title: '정상 승인 계정(AUTH)', content: 'ss' },
//     { title: '친구에게 나의 걸음 수 공개', content: 'ss' },
//   ],
//   [
//     { title: '카카오 계정', content: 'ss' },
//     { title: '카카오 계정 CI', content: 'ss' },
//     { title: '애플 계정', content: 'ss' },
//     { title: '애플 머?', content: 'ss' },
//   ],
// ];

export const UserInfoDetail: React.FC<Props> = ({
  open,
  onClose,
  userInfo,
}) => {
  const queryClient = useQueryClient();
  const [nickname, setNickname] = useState<any>('');
  const [email, setEmail] = useState<any>('');
  const [gender, setGender] = useState<any>('2');
  const [birthday, setBirthday] = useState<any>('');
  const [address, setAddress] = useState<any>('');
  const [deleted, setDeleted] = useState<boolean>(false);
  const [activityAuthYN, setActivityAuthYN] = useState<any>('');
  const [residenceAuthYN, setResidenceAuthYN] = useState<any>('');
  const [residenceAddress, setResidenceAddress] = useState<any>('');
  const [activityAddress, setActivityAddress] = useState<any>('');
  const [residenceEmdCode, setResidenceEmdCode] = useState<any>('');
  const [activityEmdCode, setActivityEmdCode] = useState<any>('');
  const [authAddress, setAuthAddress] = useState<any>('');
  const [authDate, setAuthDate] = useState<any>('');
  const [targetData, setTargetData] = useState<any>({
    addresss: '',
    sigunguCode: '',
  });
  const [addressType, setAddressType] = useState<number>(0);
  const [openPostCode, setOpenPostCode] = useState<number>(0);
  const [openMore, setOpenMore] = useState<number>(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  const [sensorInfo, setSensorInfo] = useState([
    {
      idx: 0,
      title: '스탭 센서',
      title2: '센서활성화(동작 및 피트니스)',
      content: 'ss',
    },
    {
      idx: 1,
      title: '가속도 센서',
      title2: '헬스킷활성화(건강앱)',
      content: 'ss',
    },
    {
      idx: 2,
      title: '삼성헬스',
      title2: '건강앱(웨어러블 기기)',
      content: 'ss',
    },
    {
      idx: 3,
      title: '구글 피트니스',
      title2: '연동 웨어러블 기기',
      content: 'ss',
    },
    { idx: 4, title: '인랩워치', title2: '', content: 'ss' },
    { idx: 5, title: '-', title2: '', content: 'ss' },
  ]);

  const [deviceInfo, setDeviceInfo] = useState([
    { title: '단말기 모델 정보', content: 'ss' },
    { title: '단말기 OS 정보', content: 'ss' },
    { title: '단말기 고유식별번호(IMEI)', content: 'ss' },
    { title: '앱 버전 정보', content: 'ss' },
    { title: '정상 승인 계정(AUTH)', content: 'ss' },
    { title: '친구에게 나의 걸음 수 공개', content: 'ss' },
    { title: '탈퇴 날짜', content: 'ss' },
    { title: '탈퇴 사유', content: 'ss' },
  ]);

  const [snsInfo, setSnsInfo] = useState([
    { title: '카카오 계정', content: 'ss' },
    { title: '카카오 계정 CI', content: 'ss' },
    { title: '애플 계정', content: 'ss' },
    { title: '애플 머?', content: 'ss' },
  ]);

  // const { data: usersDetailInfo, refetch: refetchUsersDetailInfo } =
  //   useMany<any>(
  //     'admin/apis/user-management/users/',
  //     {
  //       searchCondition: 'id',
  //       searchWord: id,
  //     },
  //     [],
  //     false
  //   );

  const getModelInfo = (model: string) => {
    let newModel = '-';
    if (model.includes('iPhone'))
      newModel = `${convertIphoneModel(model)} / ${model}`;
    else newModel = model;

    return newModel;
  };

  const chageUserDetailInfo = (detailInfo: any) => {
    console.log('user detail : ', detailInfo);
    const newModel =
      detailInfo.wearableDevice === null
        ? detailInfo.deviceModel
        : detailInfo.wearableDevice;
    const isIphone = newModel.includes('iPhone') || newModel.includes('iphone');
    var deviceOs = '';
    var stepSensor = '';
    var accelermeter = '';
    var sensorActive = '';
    var hkActive = '';
    var hkDeviceClass = '';
    var hkDeviceClassName = '';
    var connected: any = null;
    // var hk_apple = 0;
    // var hk_xiomi = 0;
    // var hk_veryFit = 0;

    setResidenceAddress(
      detailInfo.residenceAddress !== null ? detailInfo.residenceAddress : ''
    );
    setActivityAddress(
      detailInfo.activityAddress !== null ? detailInfo.activityAddress : ''
    );
    setActivityAuthYN(detailInfo.activityAuthYN);
    setResidenceAuthYN(detailInfo.residenceAuthYN);
    setActivityEmdCode(detailInfo.activityEmdCode);
    setResidenceEmdCode(detailInfo.residenceEmdCode);

    setAuthAddress(
      detailInfo.authAddress !== undefined && detailInfo.authAddress !== null
        ? detailInfo.authAddress
        : ''
    );
    setAuthDate(
      detailInfo.authDate !== undefined && detailInfo.authDate !== null
        ? timestamp2Localestring(detailInfo.authDate, 1000)
        : ''
    );

    if (!isIphone) {
      const model = newModel.split('(');
      const tmp1 = model[1].split(',');
      stepSensor = String(tmp1[0]).trim() === '0' ? '미사용' : '사용';
      accelermeter = String(tmp1[0]).trim() === '1' ? '사용' : '미사용';
      const tmp2 = tmp1[1].split(')');
      hkDeviceClass = String(tmp2[0]).trim();
      hkDeviceClassName =
        hkDeviceClass === '2'
          ? '삼성헬스'
          : hkDeviceClass === '3'
          ? '인랩워치'
          : hkDeviceClass === '4'
          ? '삼성헬스(전체걸음)'
          : hkDeviceClass === '5'
          ? '구글 피트니스'
          : '-';
      console.log('model:', model, ', sensor:', stepSensor);
    } else if (isIphone && detailInfo.wearableDevice !== null) {
      const tmpModel = newModel.split('(');
      const hk = tmpModel[1].split(')');
      const hkStr = String(hk[0]).trim();
      const hkInfo = JSON.parse(hkStr);

      connected = Object.keys(hkInfo)
        .map(item => (item === 'connected' ? hkInfo[item] : ''))
        .filter(item => item !== '');
      hkDeviceClassName =
        Object.keys(hkInfo).length === 0
          ? '미지정'
          : Object.keys(hkInfo)
              .sort()
              .filter(item => item !== 'connected')
              .map(item => {
                console.log('item:', item);
                const name =
                  item === 'iPhone'
                    ? '핸드폰'
                    : item === 'Apple Watch'
                    ? '애플워치'
                    : item;
                return name;
              })
              .join(',');
      console.log('newModel:', newModel);
      console.log('connected:', connected[0]);
      console.log('hkDeviceClassName :', hkDeviceClassName);
    } else if (isIphone && detailInfo.wearableDevice === null) {
      const model = newModel.split(')');
      const hk = model[1].split(',');
      hkDeviceClass = String(hk[0]).trim();
      hkDeviceClassName =
        hkDeviceClass === '4'
          ? '애플워치(이전 버전)'
          : hkDeviceClass === '5'
          ? '건강앱(애플워치)'
          : hkDeviceClass === '6'
          ? '건강앱(Zepp Life)'
          : hkDeviceClass === '7'
          ? '건강앱(Very Fit)'
          : '-';
      hkActive = String(hk[2]).trim() === '0' ? '비활성화' : '활성화';
      sensorActive = String(hk[1]).trim() === '0' ? '비활성화' : '활성화';

      const tmp1 = model[0].split('(');
      const tmp2 = String(tmp1[1]).split(',');
      deviceOs = String(tmp2[0]);

      connected = [false];
    }

    var detailSensor = sensorInfo.map(sensor =>
      sensor.idx === 0
        ? {
            ...sensor,
            title: isIphone ? '센서활성화(동작 및 피트니스)' : '스탭 센서',
            content: isIphone ? sensorActive : stepSensor,
          }
        : sensor
    );

    detailSensor = detailSensor.map(sensor =>
      sensor.idx === 1
        ? {
            ...sensor,
            title: isIphone ? '헬스킷활성화(건강앱)' : '가속도 센서',
            content: isIphone ? hkActive : accelermeter,
          }
        : sensor
    );

    detailSensor = detailSensor.map(sensor => {
      var newContent = '-';
      if (isIphone) {
        newContent = connected[0] ? '연동' : '-';
      } else {
        newContent =
          hkDeviceClass === '2' || hkDeviceClass === '4' ? '연동' : '-';
      }
      if (sensor.idx === 2)
        return {
          ...sensor,
          title: isIphone ? '건강 앱(웨어러블 기기)' : '삼성헬스',
          content: newContent,
        };
      return sensor;
    });

    detailSensor = detailSensor.map(sensor => {
      var newContent = '-';
      if (isIphone) {
        newContent = hkDeviceClassName.length === 0 ? '-' : hkDeviceClassName;
      } else {
        newContent = hkDeviceClass === '5' ? hkDeviceClassName : '-';
      }
      if (sensor.idx === 3)
        return {
          ...sensor,
          title: isIphone ? '연동 웨어러블 기기' : '구글 피트니스',
          content: newContent,
        };
      return sensor;
    });

    detailSensor = detailSensor.map(sensor => {
      var newContent = '-';
      if (isIphone) {
        newContent = hkDeviceClass === '6' ? '연동' : '-';
      } else {
        newContent = hkDeviceClass === '3' ? hkDeviceClassName : '-';
      }
      if (sensor.idx === 4)
        return {
          ...sensor,
          title: isIphone ? '-' : '인랩워치',
          content: newContent,
        };
      return sensor;
    });

    detailSensor = detailSensor.map(sensor => {
      var newContent = '-';
      if (isIphone) {
        newContent = hkDeviceClass === '7' ? '연동' : '-';
      } else {
        newContent = '-';
      }
      if (sensor.idx === 5)
        return {
          ...sensor,
          title: isIphone ? '-' : '-',
          content: newContent,
        };
      return sensor;
    });

    setSensorInfo(detailSensor);

    // Device
    var detailDevice = deviceInfo.map(device =>
      device.title === '단말기 모델 정보'
        ? {
            ...device,
            content:
              detailInfo.deviceModel === null || detailInfo.deviceModel === ''
                ? '-'
                : getModelInfo(detailInfo.deviceModel),
          }
        : device
    );

    detailDevice = detailDevice.map(device =>
      device.title === '단말기 OS 정보'
        ? {
            ...device,
            content:
              detailInfo.deviceModel === '' || detailInfo.deviceModel === null
                ? '-'
                : detailInfo.deviceModel.includes('iPhone')
                ? `iPhone ${deviceOs}`
                : `Android ${deviceOs}`,
          }
        : device
    );

    detailDevice = detailDevice.map(device =>
      device.title === '단말기 고유식별번호(IMEI)'
        ? {
            ...device,
            content:
              detailInfo.imei === null || detailInfo.imei === ''
                ? '-'
                : detailInfo.imei,
          }
        : device
    );

    detailDevice = detailDevice.map(device =>
      device.title === '앱 버전 정보'
        ? {
            ...device,
            content:
              detailInfo.appVersion === null || detailInfo.appVersion === ''
                ? '-'
                : detailInfo.appVersion,
          }
        : device
    );

    detailDevice = detailDevice.map(device =>
      device.title === '정상 승인 계정(AUTH)'
        ? { ...device, content: detailInfo.auth === '1' ? '승인' : '미승인' }
        : device
    );

    detailDevice = detailDevice.map(device =>
      device.title === '친구에게 나의 걸음 수 공개'
        ? {
            ...device,
            content:
              detailInfo.shareMyStep === null || detailInfo.shareMyStep === ''
                ? '-'
                : detailInfo.shareMyStep === 0
                ? '비공개'
                : '공개',
          }
        : device
    );

    detailDevice = detailDevice.map(device =>
      device.title === '탈퇴 날짜'
        ? {
            ...device,
            content: deleted
              ? timestamp2Localestring(detailInfo.deletedDate, 1000)
              : '-',
          }
        : device
    );

    detailDevice = detailDevice.map(device =>
      device.title === '탈퇴 사유'
        ? {
            ...device,
            content: deleted ? getDeletedReason(detailInfo.deletedReason) : '-',
          }
        : device
    );

    setDeviceInfo(detailDevice);

    // SNS
    var detailSns = snsInfo.map(sns =>
      sns.title === '카카오 계정' ? { ...sns, content: '-' } : sns
    );

    detailSns = detailSns.map(sns =>
      sns.title === '카카오 계정 CI' ? { ...sns, content: '-' } : sns
    );

    detailSns = detailSns.map(sns =>
      sns.title === '애플 계정' ? { ...sns, content: '-' } : sns
    );

    detailSns = detailSns.map(sns =>
      sns.title === '애플 ??' ? { ...sns, content: '-' } : sns
    );

    setSnsInfo(detailSns);
  };

  const {
    data: usersDetailInfo,
    isLoading,
    refetch: refetchUsersDetailInfo,
  } = useQuery(
    KEY_USER_DETAIL_INFO,
    () => {
      if (open && userInfo !== undefined && userInfo.id !== '')
        return getUserDetailInfo(userInfo.id);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) chageUserDetailInfo(res.userDetail);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { mutate: userDeleteMutate, isError: isDeleteMutateError } =
    useMutation(patchUserDelete, {
      onSuccess: res => {
        setLoading(false);
        if (res.resultCode === RESULT_OK) {
          setOpenSnakerDialog(true);
          setDialogMessage('탈퇴되었습니다.');
        } else {
          setOpenSnakerDialog(true);
          setDialogMessage(
            `탈퇴처리동안 오류가 발생했습니다 : ${res.resultCodeMsg}`
          );
        }
      },
      onError: error => {
        setLoading(false);
        setOpenSnakerDialog(true);
        setDialogMessage(`탈퇴처리동안 오류가 발생했습니다 : ${error}`);
      },
    });

  const { mutate: userBlockingMutate, isError: isBlockingMutateError } =
    useMutation(patchUserBlocking, {
      onSuccess: () => {
        setLoading(false);
        setOpenSnakerDialog(true);
        setDialogMessage('차단되었습니다.');
      },
      onError: error => {
        setLoading(false);
        setOpenSnakerDialog(true);
        setDialogMessage(`차단처리동안 오류가 발생했습니다 : ${error}`);
      },
    });

  const { mutate: userUpdateInfoMutate, isError: iUpdateInfoMutateError } =
    useMutation(patchUserInfo, {
      onSuccess: () => {
        setOpenSnakerDialog(true);
        setDialogMessage('저장되었습니다.');
        onClose();
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(`저장하는동안 오류가 발생했습니다 : ${error}`);
      },
    });

  const {
    mutate: userUpdateProfileEmdCodeMutate,
    isError: iUpdateProfileMutateError,
  } = useMutation(patchUserProfileEmdCode, {
    onSuccess: () => {
      setOpenSnakerDialog(true);
      setDialogMessage('저장되었습니다.');
      refetchUsersDetailInfo();
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(`저장하는동안 오류가 발생했습니다 : ${error}`);
    },
  });

  const deleteUser = () => {
    const newData = {
      id: userInfo?.id,
    };
    setLoading(true);
    userDeleteMutate(newData);
  };

  const blockingUser = () => {
    const newData = {
      id: userInfo?.id,
      phoneno: userInfo?.phoneno,
    };
    setLoading(true);
    userBlockingMutate(newData);
  };

  const registerEmdCode = (type: number) => {
    const newData = {
      id: userInfo?.id,
      residenceEmdCode: type === 1 ? residenceEmdCode : null,
      activityEmdCode: type === 2 ? activityEmdCode : null,
    };
    userUpdateProfileEmdCodeMutate(newData);
  };

  const saveUserInfo = () => {
    const newData = {
      id: userInfo?.id,
      name: nickname,
      email: email,
      birthday: birthday - 1,
      gender: gender,
    };
    const currentYear = new Date(Date.now()).getFullYear();
    var val = 0;
    // if (validCheck('email', email) === false) {
    //   setOpenSnakerDialog(true);
    //   setDialogMessage('이메일을 확인해주세요.');
    //   val = 1;
    // } else
    if (nickname === '' || nickname.length < 2 || nickname.length > 20) {
      setOpenSnakerDialog(true);
      setDialogMessage('닉네임은 2-20자로 입력해주세요.');
      val = 2;
    } else if (birthday < 1900 || birthday > currentYear) {
      setOpenSnakerDialog(true);
      setDialogMessage(`나이는 1900~${currentYear}사이의 값으로 입력하세요.`);
      val = 3;
    }
    // } else if (address === null || address.length === 0) {
    //   setOpenSnakerDialog(true);
    //   setDialogMessage('주소를 확인해주세요.');
    //   val = 4;
    // }

    if (val === 0) userUpdateInfoMutate(newData);
  };

  useEffect(() => {
    console.log('targetData :', addressType, ', ', targetData);
    if (addressType === 1) {
      setResidenceAddress(targetData.jibunAddress);
      setResidenceEmdCode(targetData.bcode);
    } else {
      setActivityAddress(targetData.jibunAddress);
      setActivityEmdCode(targetData.bcode);
    }
  }, [targetData]);

  useEffect(() => {
    if (open) {
      const currentYear = new Date(Date.now()).getFullYear();
      setNickname(userInfo?.name);
      setEmail(userInfo?.email !== null ? userInfo?.email : '');
      setBirthday(currentYear - Number(userInfo?.age) + 1);
      setGender(userInfo?.gender !== null ? userInfo?.gender : '2');
      setDeleted(userInfo?.deleted === 1);
      refetchUsersDetailInfo();
    }
    return () => {};
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            height: '85vh',
            margin: '20rem',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box className='flex_start'>
              <Typography>{userInfo?.nickName} 상세보기</Typography>
              {deleted && (
                <Typography sx={{ ml: '.3rem', color: 'red' }}>
                  (탈퇴)
                </Typography>
              )}
            </Box>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Grid container sx={{ pt: '0.5rem' }}>
            <Grid item xs={4}>
              <Typography
                component='div'
                sx={{ fontSize: '.8rem', color: '#858C97', mb: '1rem' }}
              >
                사용자 관리
              </Typography>
              <Box>
                <Typography
                  component='div'
                  sx={{ fontSize: '.9rem', mb: '.6rem' }}
                >
                  닉네임
                </Typography>
                <TextField
                  sx={{ width: '18rem' }}
                  value={nickname}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setNickname(evt.target.value)}
                />
              </Box>
              <Divider sx={{ p: 0 }} />
              <Box>
                <Typography
                  component='div'
                  sx={{ fontSize: '.9rem', mb: '.6rem' }}
                >
                  아이디
                </Typography>
                <Box className='flex_between'>
                  <Typography component='div' sx={{ fontSize: '.75rem' }}>
                    {userInfo?.id}{' '}
                  </Typography>
                  <Button
                    sx={{ mr: '1.5rem' }}
                    variant='contained'
                    color='info'
                    onClick={() => setOpenMore(1)}
                    disabled={deleted}
                  >
                    계정 탈퇴
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ p: 0 }} />
              <Box>
                <Typography
                  component='div'
                  sx={{ fontSize: '.9rem', mb: '.6rem' }}
                >
                  연락처
                </Typography>
                <Box className='flex_between'>
                  <Typography component='div' sx={{ fontSize: '.75rem' }}>
                    {removeNatCodePhoneNumber(userInfo?.phoneno)}
                  </Typography>
                  <Button
                    sx={{ mr: '1.5rem' }}
                    variant='contained'
                    color='info'
                    onClick={() => setOpenMore(2)}
                    disabled={deleted}
                  >
                    강제 차단
                  </Button>
                </Box>
              </Box>
              <Divider sx={{ p: 0 }} />
              <Box>
                <Typography
                  component='div'
                  sx={{ fontSize: '.9rem', mb: '.6rem' }}
                >
                  이메일
                </Typography>
                <TextField
                  sx={{ width: '18rem' }}
                  value={email}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setEmail(evt.target.value)}
                />
              </Box>
              <Divider sx={{ p: 0 }} />
              <Box className='flex_between'>
                <Box>
                  <Typography
                    component='div'
                    sx={{ fontSize: '.9rem', mb: '.6rem' }}
                  >
                    나이(출생연도)
                  </Typography>
                  <TextField
                    sx={{ width: '15rem' }}
                    value={birthday}
                    type='number'
                    inputProps={{ maxLength: 4 }}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => setBirthday(evt.target.value)}
                  />
                </Box>
                <Box>
                  <Typography
                    component='div'
                    sx={{ fontSize: '.9rem', mb: '.6rem' }}
                  >
                    성별
                  </Typography>
                  <TextField
                    sx={{ width: '15rem' }}
                    select
                    defaultValue='2'
                    value={gender}
                    onChange={evt => setGender(evt.target.value)}
                  >
                    <MenuItem key={2} value='2'>
                      선택
                    </MenuItem>
                    <MenuItem key={1} value='1'>
                      남성
                    </MenuItem>
                    <MenuItem key={0} value='0'>
                      여성
                    </MenuItem>
                  </TextField>
                </Box>
              </Box>
              <Divider sx={{ p: 0 }} />
              <Box>
                <Typography
                  component='div'
                  sx={{ fontSize: '.9rem', mb: '.6rem' }}
                >
                  주소
                </Typography>
                <Box>
                  <Typography
                    component='div'
                    sx={{ fontSize: '.7rem', mb: '.6rem' }}
                  >
                    거주 지역
                  </Typography>
                  <Box className='flex_start'>
                    <TextField
                      sx={{ width: '18rem' }}
                      inputProps={{
                        style: {
                          color: residenceAuthYN === 'Y' ? 'blue' : 'red',
                        },
                      }}
                      value={residenceAddress}
                      onChange={(
                        evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => setResidenceAddress(evt.target.value)}
                    />
                    <Button
                      sx={{ ml: '1.5rem' }}
                      variant='outlined'
                      color='info'
                      onClick={() => {
                        setAddressType(1);
                        setOpenPostCode(1);
                      }}
                    >
                      주소찾기
                    </Button>
                    <Button
                      sx={{ ml: '1.5rem' }}
                      variant='contained'
                      color='info'
                      onClick={() => registerEmdCode(1)}
                      disabled={deleted}
                    >
                      거주 지역 등록
                    </Button>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    component='div'
                    sx={{ fontSize: '.7rem', mb: '.6rem' }}
                  >
                    활동 지역
                  </Typography>
                  <Box className='flex_start'>
                    <TextField
                      sx={{ width: '18rem' }}
                      inputProps={{
                        style: {
                          color: activityAuthYN === 'Y' ? 'blue' : 'red',
                        },
                      }}
                      value={activityAddress}
                      onChange={(
                        evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => setActivityAddress(evt.target.value)}
                    />
                    <Button
                      sx={{ ml: '1.5rem' }}
                      variant='outlined'
                      color='info'
                      onClick={() => {
                        setAddressType(2);
                        setOpenPostCode(2);
                      }}
                    >
                      주소찾기
                    </Button>
                    <Button
                      sx={{ ml: '1.5rem' }}
                      variant='contained'
                      color='info'
                      onClick={() => registerEmdCode(2)}
                      disabled={deleted}
                    >
                      활동 지역 등록
                    </Button>
                  </Box>
                  {openPostCode && (
                    <SwSearchPostCode
                      open={!!openPostCode}
                      onClose={() => setOpenPostCode(0)}
                      setTarget={setTargetData}
                    />
                  )}
                </Box>
                <Box>
                  <Typography
                    component='div'
                    sx={{ fontSize: '.7rem', mb: '.6rem' }}
                  >
                    주민등록상 주소
                  </Typography>
                  <Box className='flex_start'>
                    <TextField
                      sx={{ width: '34rem' }}
                      value={
                        authAddress !== ''
                          ? `${authAddress} (${authDate.replaceAll(
                              '-',
                              '/'
                            )} 인증)`
                          : ''
                      }
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4} sx={{ pl: '1rem' }}>
              <Typography
                component='div'
                sx={{ fontSize: '.8rem', color: '#858C97', mb: '1rem' }}
              >
                센서/웨어러블 정보
              </Typography>
              {sensorInfo
                .filter((tab, idx) => tab.title !== '-')
                .map((tab, idx) => (
                  <Box key={idx.toString()}>
                    <Typography
                      component='div'
                      sx={{ fontSize: '.9rem', mb: '.6rem' }}
                    >
                      {tab.title}
                    </Typography>
                    <Typography component='div' sx={{ fontSize: '.75rem' }}>
                      {tab.content}
                    </Typography>
                    <Divider sx={{ p: 0 }} />
                  </Box>
                ))}
            </Grid>
            <Grid item xs={4} sx={{ pl: '1rem' }}>
              <Typography
                component='div'
                sx={{ fontSize: '.8rem', color: '#858C97', mb: '1rem' }}
              >
                기타 정보
              </Typography>
              {deviceInfo.map((tab, idx) => (
                <Box key={idx.toString()}>
                  <Typography
                    component='div'
                    sx={{ fontSize: '.88rem', mb: '.6rem' }}
                  >
                    {tab.title}
                  </Typography>
                  <Typography component='div' sx={{ fontSize: '.75rem' }}>
                    {tab.content}
                  </Typography>
                  <Divider sx={{ p: '.5em', mb: '.7rem' }} />
                </Box>
              ))}
            </Grid>
            {/* <Grid item xs={4} sx={{ pl: '1rem' }}>
            <Typography
              component='div'
              sx={{ fontSize: '.8rem', color: '#858C97', mb: '1rem' }}
            >
              소셜 계정 연동 정보
            </Typography>
            {snsInfo.map((tab, idx) => (
              <Box key={idx.toString()}>
                <Typography
                  component='div'
                  sx={{ fontSize: '.88rem', mb: '.6rem' }}
                >
                  {tab.title}
                </Typography>
                <Typography component='div' sx={{ fontSize: '.75rem' }}>
                  {tab.content}
                </Typography>
                <Divider sx={{ p: '.5em', mb: '.7rem' }} />
              </Box>
            ))}
          </Grid> */}
          </Grid>
          {loading && <SwLoading />}
        </DialogContent>
        <Divider sx={{ m: 0 }} />
        <DialogActions sx={{ p: '1rem' }}>
          {deleted ? (
            <Button
              variant='contained'
              color='primary'
              sx={{ fontSize: '.87rem' }}
              onClick={onClose}
            >
              닫기
            </Button>
          ) : (
            <Button
              variant='contained'
              color='primary'
              sx={{ fontSize: '.87rem' }}
              onClick={saveUserInfo}
            >
              저장
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <SwConfirmDialog
        contents={
          openMore === 1
            ? `${userInfo?.id} 해당 계정을 앱 탈퇴처리하시겠습니까?`
            : `${removeNatCodePhoneNumber(
                userInfo?.phoneno
              )} 해당 연락처로 앱 가입을 차단하시겠습니까?`
        }
        open={!!openMore}
        onClose={() => setOpenMore(0)}
        onConfirm={() => {
          openMore === 1 ? deleteUser() : openMore === 2 ? blockingUser() : '';
          setOpenMore(0);
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
