import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../common/key';

interface Props {
  payType: number;
  open: boolean;
  rewardValue: number;
  onClose: () => void;
  onChangePayInfo: (payInfo: any) => void;
}

export const SwPayBrandPoint: React.FC<Props> = ({
  payType,
  open,
  rewardValue,
  onClose,
  onChangePayInfo,
}) => {
  const [point, setPoint] = useState<number>(0);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setPoint(rewardValue);
    setMessage('');
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDialog-paper': {
          height: '35vh',
          width: '40vh',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>{payType === 1 ? '지급 포인트' : '회수 포인트'}</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Typography sx={{ fontWeight: 'bold', mt: '0.7rem' }}>
          {payType === 1 ? '지급' : '회수'}할 포인트를 입력해주세요.
        </Typography>
        <TextField
          placeholder='숫자 입력'
          sx={{
            width: '100%',
            mt: '0.71rem',
            mb: 0,
            input: {
              textAlign: 'left',
            },
          }}
          type='number'
          value={point}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setPoint(Number(event.target.value));
          }}
        />
        <Typography sx={{ fontWeight: 'bold', mt: '0.7rem' }}>
          내역에 표시될 내용을 입력해주세요
        </Typography>
        <TextField
          placeholder='텍스트 입력(30자)'
          sx={{
            width: '100%',
            mt: '0.71rem',
            mb: 0,
            input: {
              textAlign: 'left',
            },
          }}
          value={message}
          onChange={(
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => {
            setMessage(event.target.value);
          }}
        />
      </DialogContent>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogActions>
        <Box className='flex_end'>
          <Button
            color={payType === 1 ? 'primary' : 'warning'}
            variant='outlined'
            size='small'
            onClick={() => {
              onChangePayInfo({
                payType: payType,
                point: point,
                message: message,
              });
            }}
          >
            {payType === 1 ? '지급하기' : '회수하기'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
