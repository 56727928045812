import {
  Box,
  Button,
  Checkbox,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import SearchIcon from '@mui/icons-material/Search';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import { AxiosError } from 'axios';
import { UserInfoDetail } from './UserInfoDetail';
import { EmptyPage } from '../../commonComponent/table/EmptyPage';
import { debouncehook } from '../../../hooks/debouncehook';
import { UserInquireTable } from './UserInquireTable';
import { UserEmailAdd } from './UserEmailAdd';
import { IUser } from '../../../models/user';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  getUserList,
  patchUserEmail,
  downloadUserList,
  getUserBlackList,
  downloadUserBlackList,
  controlBlockingUsers,
} from '../../../common/api/ApiUser';
import {
  KEY_USER_LIST,
  KEY_USER_EMAIL_CHANGE,
  KEY_USER_BLACK_LIST,
} from '../../../common/key';
import { RESULT_OK } from '../../../common/resultCode';
import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { UserRejectSetting } from './UserRejectSetting';

interface Props {}

const columns = [
  {
    name: '닉네임',
    id: 'name',
  },
  {
    name: '연락처',
    id: 'phoneNumber',
  },
  {
    name: '차단 상태',
    id: 'status',
  },
  {
    name: '차단 사유',
    id: 'memo',
  },
  {
    name: '차단 일시',
    id: 'rejectDate',
  },
  {
    name: '차단 해제 일시',
    id: 'unRejectDate',
  },
];

export const UserReject: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const emailRef = useRef<any>();
  const searchRef = useRef<any>();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentRow, setCurrentRow] = useState<string>('');
  const [list, setList] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [selected, setSelected] = useState<any>(null);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  var searchWord = '';
  var searchKey = 'user';

  const {
    data,
    isLoading,
    refetch: refetchRejectUsers,
  } = useQuery(
    KEY_USER_BLACK_LIST,
    () => {
      if (searchKey !== '') {
        return getUserBlackList(
          page,
          rowsPerPage,
          searchKey,
          encodeSearchWord(searchKey, searchWord)
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          console.log('res.user :', res);
          setCheckedButtons([]);
          setList(res.user);
          setTotalRecords(res.pagination.totalRecordCount);
        } else {
          setList([]);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchUsers = (newPage: number) => {
    queryClient.prefetchQuery(KEY_USER_BLACK_LIST, () => {
      searchWord = searchRef.current.value;

      if (searchKey !== '') {
        return getUserBlackList(
          newPage,
          rowsPerPage,
          searchKey,
          encodeSearchWord(searchKey, searchWord)
        );
      }
      return null;
    });
  };

  const {
    mutate: controlBlockingUsersMutation,
    isError: isUnblockingMutateError,
  } = useMutation(controlBlockingUsers, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('차단 해제했습니다.');
      refetchRejectUsers();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '차단 해제하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const unBlocking = () => {
    const idxList = checkedButtons.map((item: string) => item);
    const newData: any = {
      type: 'unblock',
      idxList: idxList,
    };
    controlBlockingUsersMutation(newData);
  };

  const isSearching = () => {
    setPage(1);
    if (searchRef.current.value) {
      searchKey = 'all';
      searchWord = searchRef.current.value;
    } else {
      searchWord = '';
    }

    prefetchUsers(1);
  };

  const onDownloadFile = () => {
    if (searchRef.current.value) {
      searchKey = 'all';
      searchWord = searchRef.current.value;
    } else {
      searchWord = '';
    }
    downloadUserBlackList(searchKey, encodeSearchWord(searchKey, searchWord));
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      isSearching();
    }
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    queryClient.prefetchQuery(KEY_USER_BLACK_LIST, () =>
      getUserBlackList(
        newPage,
        rowsPerPage,
        searchKey,
        encodeSearchWord(searchKey, searchWord)
      )
    );
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      list?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.idx]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const selectedRow = (row: number) => {
    setSelected(row);
    setOpenMore(2);
  };

  return (
    <Box className='root_box'>
      <Box className='flex_between'>
        <TextField
          placeholder='연락처, 닉네임을 검색해 주세요.'
          inputRef={searchRef}
          onKeyDown={handleKeyDown}
          sx={{ width: '30rem', mr: '1em' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={isSearching}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box className='flex_start'>
          <Button
            sx={{ ml: '1rem' }}
            variant='contained'
            color='info'
            onClick={() => setOpenMore(3)}
          >
            복사하여 차단하기
          </Button>
          <Button
            color='info'
            variant='outlined'
            sx={{ ml: '1rem', p: '.2rem 1rem' }}
            onClick={(evt: any) => {
              onDownloadFile();
            }}
          >
            엑셀 다운로드
            <FileDownloadRoundedIcon color='success' />
          </Button>
        </Box>
      </Box>
      <Paper sx={{ p: '1.5rem', m: '1.4rem 0' }}>
        {list.length > 0 ? (
          <Box>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding='checkbox'>
                      <Checkbox
                        onChange={e => {
                          handleSelectAllClick(e);
                        }}
                        checked={checkedAll}
                      />
                    </TableCell>
                    {columns.map((column: any, idx) => (
                      <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                        {column.name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {list?.map((row: any, idx: number) => (
                    <TableRow key={`reject_${idx.toString()}`}>
                      <TableCell padding='checkbox'>
                        <Checkbox
                          id={row.idx}
                          onChange={e => {
                            changeHandler(e.currentTarget.checked, row.idx);
                          }}
                          checked={checkedButtons.includes(row.idx)}
                        />
                      </TableCell>
                      <TableCell onClick={() => selectedRow(row)}>
                        {row.name}
                      </TableCell>
                      <TableCell onClick={() => selectedRow(row)}>
                        {row.phoneno}
                      </TableCell>
                      <TableCell onClick={() => selectedRow(row)}>
                        {row.deleted === 1 ? '해제' : '차단'}
                      </TableCell>
                      <TableCell onClick={() => selectedRow(row)}>
                        {row.memo}
                      </TableCell>
                      <TableCell onClick={() => selectedRow(row)}>
                        {timestamp2Localestring(row.createDate, 1000)}
                      </TableCell>
                      <TableCell onClick={() => selectedRow(row)}>
                        {row.unblockDate !== null
                          ? timestamp2Localestring(row.unblockDate, 1000)
                          : '-'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <EmptyPage />
        )}
        <Box sx={{ p: '.6rem', display: 'flex', justifyContent: 'center' }}>
          <Pagination
            color='primary'
            variant='outlined'
            page={page}
            onChange={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </Box>
      </Paper>
      <Box className='flex_end'>
        <Button variant='outlined' color='info' onClick={() => unBlocking()}>
          차단 해제
        </Button>
      </Box>
      <UserRejectSetting
        open={openMore !== 0}
        onClose={() => {
          setOpenMore(0);
        }}
        onConfirm={() => {
          refetchRejectUsers();
          setOpenMore(0);
        }}
        edit={openMore}
        onEdit={() => setOpenMore(1)}
        selected={selected}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};
