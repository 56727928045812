import {
  Avatar,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SwSnackbar } from './views/SwSnackbar';
import { SwAlert } from './views/SwAlert';
import SwLoading from './spinner/SwLoading';
import { SelectSearchBox } from './SelectSearchBox';
import {
  getCommunityMemberList,
  putCommunityChangeMemberType,
  postCommunityMember,
} from '../../common/api/ApiCommunity';
import {
  KEY_CHALLENGE_ATTEND_USER_LIST,
  KEY_COMMUNITY_MEMBER_LIST,
  KEY_QUESTION_ANSWER_RECIPIENT_LIST,
  KEY_USER_LIST,
} from '../../common/key';
import { getUserList } from '../../common/api/ApiUser';
import { RESULT_OK } from '../../common/resultCode';
import {
  encodeSearchWord,
  removeNatCodePhoneNumber,
} from '../../common/helper';
import { SwPagination } from './tableElement/SwPagination';
import {
  changeQuestionAnswerRecipientUser,
  getQuestionAnswerRecipientUserList,
} from '../../common/api/ApiQuestionAnswer';
import { getChallengeAttendUserList } from '../../common/api/ApiChallenge';

interface Props {
  title: string;
  qnaId: string;
  targetId: string;
  targetType: number;
  receivers: any[];
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const MembersDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    minWidth: '44vw',
    minHeight: '60vh',
  },
});

const SelectChip = styled(Chip)({
  margin: '.5em',
  color: '#00A2FF',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const SearchResultPaper = styled(Paper)({
  margin: '1rem 0',
  padding: '1rem',
  widht: '100%',
  height: '50vh',
  overflowY: 'scroll',
});

const ChosenUserPaper = styled(Paper)({
  margin: '1rem 0 0',
  padding: '1rem',
  widht: '100%',
  height: '10vh',
  overflowY: 'scroll',
});

const selectVal = [
  {
    title: '아이디',
    value: 'memberId',
  },
  { title: '닉네임', value: 'memberName' },
  { title: '연락처', value: 'memberPhoneNumber' },
  { title: '이메일', value: 'memberEmail' },
];

export const SwManageReceivers: React.FC<Props> = ({
  title,
  qnaId,
  targetId,
  targetType,
  receivers,
  open,
  onClose,
  onConfirm,
}) => {
  const queryClient = useQueryClient();

  const inputRef = useRef<any>();
  const selectRef = useRef<any>(2);
  const searchRef = useRef<any>();
  const [communityMembers, setCommunityMembers] = useState<any>([]);
  const [userList, setUserList] = useState<any[]>([]);
  const [chosenUser, setChosenUser] = useState<any>([]);
  const [filterMembers, setFilterMembers] = useState<any>(communityMembers);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(8);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState<any>(null);

  const { refetch: qnaRecipientUserRefetch } = useQuery(
    KEY_QUESTION_ANSWER_RECIPIENT_LIST,
    () => {
      const newData = {
        qnaId: qnaId,
      };
      if (qnaId !== undefined && qnaId !== null && qnaId.length > 0)
        return getQuestionAnswerRecipientUserList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.user !== null) {
          setUserList(res.user);
          const newUserList = res.user.map((d: any) => {
            const newUser: any = {
              userId: d.id,
              name: d.name,
            };
            return newUser;
          });
          setChosenUser(newUserList);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    data,
    isLoading,
    refetch: communityMemberRefetch,
  } = useQuery(
    KEY_COMMUNITY_MEMBER_LIST,
    () => {
      const searchKey = 'all';
      const searchWord = inputRef.current?.value;
      const newData = {
        communityId: targetId,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (targetId !== undefined && targetId !== null && targetId.length > 0)
        return getCommunityMemberList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.member !== null) {
          const newMembers = res.member.map((d: any, idx: number) => ({
            userId: d.userId,
            name: d.name,
          }));
          setFilterMembers(newMembers);
          setTotalRecords(res.pagination?.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchCommunityMember = (newPage: number) => {
    queryClient.prefetchQuery(KEY_COMMUNITY_MEMBER_LIST, () => {
      const searchKey = 'all';
      const searchWord = inputRef.current?.value;
      const newData = {
        communityId: targetId,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      if (targetId !== undefined && targetId !== null && targetId.length > 0)
        return getCommunityMemberList(newData);
      return null;
    });
  };

  const { refetch: challengeAttendUserRefetch } = useQuery(
    KEY_CHALLENGE_ATTEND_USER_LIST,
    () => {
      const searchKey = 'all';
      const searchWord = inputRef.current?.value;
      const newData = {
        challengeId: targetId,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (targetId !== undefined && targetId !== null && targetId.length > 0)
        return getChallengeAttendUserList(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.user !== null) {
          const newMembers = res.user.map((d: any, idx: number) => ({
            userId: d.userId,
            name: d.name,
          }));
          setFilterMembers(newMembers);
          setTotalRecords(res.pagination?.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchChallengeAttendUser = (newPage: number) => {
    queryClient.prefetchQuery(KEY_CHALLENGE_ATTEND_USER_LIST, () => {
      const searchKey = 'all';
      const searchWord = inputRef.current?.value;
      const newData = {
        challengeId: targetId,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (targetId !== undefined && targetId !== null && targetId.length > 0)
        return getChallengeAttendUserList(newData);
      return null;
    });
  };

  const {
    mutate: changeQuestionAnswerRecipientUserMutate,
    isError: isMutateMemberError,
  } = useMutation(changeQuestionAnswerRecipientUser, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenDialog(true);
        setDialogMessage('수신자를 변경했습니다.');
        onConfirm();
      } else {
        setOpenDialog(true);
        setDialogMessage(
          `수신자를 변경하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenDialog(true);
      setDialogMessage(
        `수신자 변경이 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
      );
    },
  });

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    if (targetType === 2) prefetchCommunityMember(newPage);
    else if (targetType === 3) prefetchChallengeAttendUser(newPage);
  };

  const searchMemberUser = () => {
    const newSearchWord = inputRef.current?.value;
    const newMember =
      newSearchWord.length > 0
        ? communityMembers.filter((member: any, idx: number) => {
            const newCondition =
              member.name.includes(newSearchWord) ||
              member.phoneno.includes(newSearchWord) ||
              member.userId.includes(newSearchWord);
            return newCondition;
          })
        : communityMembers;
    setFilterMembers(newMember);
  };

  const addReceivers = () => {
    if (chosenUser === null || chosenUser.length === 0) {
      setOpenDialog(true);
      setDialogMessage('최소 1명은 선택되어야 합니다.');
      return;
    }

    const userIds = chosenUser.map((user: any) => user.userId);
    const newData: any = {
      qnaId: qnaId,
      userIds: userIds,
    };
    changeQuestionAnswerRecipientUserMutate(newData);
  };

  const searchUser = () => {
    setLoading(true);
  };

  const selectUser = (user: any) => {
    if (
      chosenUser.findIndex((chosen: any) => chosen.userId === user.id) === -1
    ) {
      const newUsers = [...chosenUser, user];
      setChosenUser(newUsers);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchMemberUser();
    }
  };

  useEffect(() => {
    if (open === true) {
      setFilterMembers([]);
      setChosenUser([]);
      qnaRecipientUserRefetch();
      if (targetType === 2) communityMemberRefetch();
      else if (targetType === 3) challengeAttendUserRefetch();
    } else {
      setFilterMembers([]);
      setChosenUser([]);
    }
  }, [open]);

  return (
    <>
      <MembersDialog open={open} fullWidth>
        <DialogTitle className='flex_between'>
          <Box>{title}</Box>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          <TextField
            placeholder='닉네임, 아이디, 연락처로 검색해 주세요'
            fullWidth
            inputRef={inputRef}
            onKeyDown={evt => {
              handleKeyDown(evt);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={() => searchUser()}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <SearchResultPaper>
            {/* <EmptyPage /> */}
            <Box>
              {filterMembers !== undefined && filterMembers.length > 0 ? (
                filterMembers.map((user: any, idx: number) => (
                  <Paper
                    sx={{ p: '.5rem 0', m: '.3rem 0' }}
                    key={idx.toString()}
                  >
                    <Box className='flex_between' sx={{ m: '0 1rem' }}>
                      <Box className='flex_center'>
                        <Avatar src={user.profile} alt={user.name} />
                        <Box sx={{ ml: '1rem' }}>
                          <Typography>{user.name}</Typography>
                          <Typography>{user.userId}</Typography>
                        </Box>
                      </Box>
                      <Button
                        variant='outlined'
                        color='info'
                        value={user.userId}
                        disabled={
                          chosenUser.findIndex(
                            (chosen: any) => chosen.userId === user.userId
                          ) !== -1
                        }
                        onClick={() => selectUser(user)}
                      >
                        선택
                      </Button>
                    </Box>
                  </Paper>
                ))
              ) : (
                <Typography>검색결과 없습니다.</Typography>
              )}
            </Box>
            {filterMembers.length > 0 && totalRecords && (
              <SwPagination
                page={page}
                handleChangePage={handleChangePage}
                count={filterMembers && Math.ceil(totalRecords / rowsPerPage)}
              />
            )}
          </SearchResultPaper>
          <ChosenUserPaper>
            {chosenUser?.map((user: any, idx: number) => (
              <SelectChip
                label={user.name}
                variant='outlined'
                deleteIcon={<CloseIcon />}
                key={idx.toString()}
                onDelete={() => {
                  const newChosenUser = chosenUser.filter(
                    (_user: any, i: number) => i !== idx
                  );
                  setChosenUser(newChosenUser);
                }}
              />
            ))}
          </ChosenUserPaper>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          <Button variant='outlined' color='inherit' onClick={onClose}>
            취소
          </Button>
          <Button variant='contained' color='info' onClick={addReceivers}>
            저장하기
          </Button>
        </DialogActions>
        {loading && <SwLoading />}
      </MembersDialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
