import {
  Box,
  Button,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import clsx from 'clsx';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { SelectTextField, TableButton } from '../../styles/Styles';
import {
  encodeSearchWord,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { KEY_LOCAL_STORE_OWNER_STORE_LIST } from '../../../common/key';
import { getLocalStoreOwnerStoreList } from '../../../common/api/ApiLocalstore';

interface Props {
  ownerInfo: any;
}

const columns = [
  '가게 이름',
  '가게 아이디',
  '주소',
  '업종',
  '등록 일시',
  '가게 바로가기',
];

const CreatePaper = styled(Paper)({
  // margin: '2rem',
  padding: '1rem',
  cursor: 'pointer',
});

const PaginationBox = styled(Box)({
  padding: '.6rem',
  display: 'flex',
  justifyContent: 'center',
});

export const LocalstoreOwnerStore: React.FC<Props> = ({ ownerInfo }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [requestList, setRequestList] = useState<any>([]);
  const [ownerStoreList, setOwnerStoreList] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: localstoreOwnerStoreRefetch,
  } = useQuery(
    KEY_LOCAL_STORE_OWNER_STORE_LIST,
    () => {
      if (ownerInfo && ownerInfo.ownerId.length > 0) {
        const newData: any = {
          page: page,
          rowsPerPage: rowsPerPage,
          ownerId: ownerInfo.ownerId,
        };
        return getLocalStoreOwnerStoreList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res && res.store) {
          const newStore = res.store.map((item: any) => {
            const createDate = timestamp2Localestring(item.createdDate, 1000);
            const newData = [
              item.storeName,
              item.storeId,
              item.address,
              item.categoryName,
              createDate,
            ];
            return newData;
          });
          setRequestList(newStore);
          setOwnerStoreList(res.store);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const localstoreOwnerStorePrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_LOCAL_STORE_OWNER_STORE_LIST, () => {
      const newData: any = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        ownerId: ownerInfo.ownerId,
      };
      getLocalStoreOwnerStoreList(newData);
    });
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const navRewardDrawing = (idx: number) => {
    navigate('/localstore/list', {
      replace: true,
      state: {
        ownerid: ownerStoreList[idx].ownerId,
        storeId: ownerStoreList[idx].storeId,
      },
    });
  };

  useEffect(() => {
    if (ownerInfo && ownerInfo.ownerId.length > 0)
      localstoreOwnerStoreRefetch();
  }, [page]);

  useEffect(() => {
    if (ownerInfo && ownerInfo.ownerId.length > 0)
      localstoreOwnerStoreRefetch();
  }, [ownerInfo]);

  return (
    <CreatePaper>
      {`가게 ${totalRecords}개`}
      <TableContainer sx={{ mt: '1rem', maxHeight: '27rem' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={`store_head_${idx}`}
                  className={clsx({ request_head: true }, 'request_tablehead')}
                  sx={{ p: '.4rem .7rem !important' }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ maxHeight: '10rem', overflowY: 'scroll' }}>
            {requestList?.map((row: any, idx: number) => (
              <TableRow key={idx.toString()} className='whitecolor'>
                {row.map((r: any, i: number) => (
                  <TableCell
                    key={`store_key_${i}`}
                    className={clsx('request_tablecell ')}
                    sx={{ p: '.6rem .7rem' }}
                  >
                    {r}
                  </TableCell>
                ))}
                <TableCell
                  key='store_key_999`'
                  className={clsx('request_tablecell ')}
                  sx={{ p: '.6rem .7rem' }}
                >
                  <Button
                    color='info'
                    variant='contained'
                    sx={{ mr: '1rem' }}
                    onClick={() => navRewardDrawing(idx)}
                  >
                    바로가기
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {(requestList === null || requestList.length === 0) && (
              <Typography sx={{ mt: '2rem', mb: '2rem' }}>
                데이터가 없습니다.
              </Typography>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {requestList && requestList.length > 0 && (
        <PaginationBox>
          <Pagination
            color='primary'
            variant='outlined'
            siblingCount={3}
            page={page}
            onChange={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </PaginationBox>
      )}
    </CreatePaper>
  );
};
