import {
  Badge,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { LocalstoreForm } from './LocalstoreForm';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { ButtonBox, MarginButton } from './LocalstoreStyles';
import {
  KEY_CHALLENGE_LIST,
  KEY_LOCAL_STORE_LIST,
  KEY_LOCAL_STORE_REQUEST_LIST,
} from '../../../common/key';
import { WhiteTextField, SwSwitch } from '../../styles/Styles';
import {
  encodeSearchWord,
  splitAddress,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import {
  confirmLocalstoreRegister,
  getLocalstoreList,
} from '../../../common/api/ApiLocalstore';
import { LocalstoreReward } from './LocalstoreReward';

interface Props {}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
});

const requestColumnlists = [
  '가게 이름',
  '가게 아이디',
  '주소',
  '상세주소',
  '업종',
  '등록일시',
];

export const LocalstoreList: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const [localstoreInfo, setLocalstoreInfo] = useState<any>();
  const [localstoreId, setLocalstoreId] = useState<string>('');
  const [requestList, setRequestList] = useState<any>([]);
  const [localstoreList, setLocalstoreList] = useState<any>([]);
  const [localstoreRequestData, setLocalstoreRequestData] = useState<any>();
  const [localstoreClassType, setLocalstoreClassType] = useState<any>([]);
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [localstoreTitle, setLocalstoreTitle] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const [rewardDrawUserList, setRewardDrawUserList] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSwitch, setOpenSwitch] = useState<boolean>(true);
  const [closeSwitch, setCloseSwitch] = useState<boolean>(true);
  const [localstoreFilterInfo, setLocalstoreFilterInfo] = useState<any>();
  const [filter, setFilter] = useState<any>(null);
  const [open, setOpen] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);

  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== tabValue) setTabValue(newValue);
  };
  const {
    data,
    isLoading,
    refetch: localstoreRefetch,
  } = useQuery(
    KEY_LOCAL_STORE_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      const newDate = new Date().getTime() / 1000;
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newRequest = {
        status: status,
        startDate: filter !== null ? filter.rangeDate[0] : 0,
        endDate: filter !== null ? filter.rangeDate[1] : 0,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (checkRequest) return getLocalstoreList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('requestList:', res);
        const newLocalstore =
          res &&
          res.store &&
          res.store.store.map((item: any, idx: number) => {
            const newDate = timestamp2Localestring(item.createdDate, 1000);
            const newData = [
              item.storeName,
              item.storeId,
              splitAddress(item.address),
              item.address,
              item.categoryName,
              newDate,
            ];
            if (localstoreId === item.localstoreId) {
              setSelectedRow(idx);
              setLocalstoreInfo(item);
            }
            return newData;
          });
        setRequestList(newLocalstore);
        setLocalstoreList(res ? res.store.store : []);
        setLocalstoreClassType(res ? res.store.classType : null);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_LOCAL_STORE_LIST, () => {
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getLocalstoreList(newData);
    });
  };

  const { mutate: confirmLocalstoreRegisterMutation, isError: isMutateError } =
    useMutation(confirmLocalstoreRegister, {
      onSuccess: res => {
        console.log('confirmLocalstoreRegister res:', res);
        if (res.resultCode === RESULT_OK || res.localstoreType === 5) {
          setOpenSnackbar(true);
          setAlertMessage('저장되었습니다.');
          localstoreRefetch();
        } else {
          setAlertOpen(true);
          setAlertMessage('리워드를 등록해 주세요.');
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '가게를 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const searchLocalstore = () => {
    setFilter(null);
    setPage(1);
    prefetch(1);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setSelectedRow(-1);
    setPage(newPage);
    prefetch(newPage);
  };

  const confirmChallege = (testType: number) => {
    const newData: any = {
      localstoreId: localstoreId,
      testType: testType,
    };
    // if (
    //   (localstoreInfo.rewardCount === 0 || localstoreInfo.rewardCount === null) &&
    //   rewardCount === 0 &&
    //   localstoreInfo.localstoreType !== 5
    // ) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('리워드 정보를 입력해주세요');
    //   return;
    // }
    confirmLocalstoreRegisterMutation(newData);
  };

  const checkFilter = (testType: number) => {
    const newFilters = JSON.parse(localstoreInfo.localstoreFilters);
    console.log('newFilters :', newFilters);
    const newFilterList =
      newFilters &&
      newFilters.map((item: any) => {
        const newData = {
          filterType: item.FILTER_TYPE,
          localstoreId: item.CHALLENGE_ID,
          targetId: item.TARGET_ID,
          communityName: item.NAME,
        };
        return newData;
      });

    if (newFilterList === undefined || newFilterList.length === 0) {
      setOpenFilter(true);
    } else {
      confirmChallege(testType);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchLocalstore();
    }
  };

  useEffect(() => {
    console.log('openSwitch :', openSwitch, ', closeSwitch :', closeSwitch);
    prefetch(1);
  }, [openSwitch, closeSwitch]);

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    setSelectedRow(i);
    const newChosen = [Chosen[2], Chosen[1], Chosen[3]];
    setChosenRequest([newChosen]);
    setLocalstoreId(localstoreList[i].localstoreId);
    setLocalstoreInfo(localstoreList[i]);
  };

  const saveRewardCount = (count: number) => {
    setRewardCount(count);
    if (count !== localstoreInfo.rewardCount) {
      localstoreRefetch();
    }
  };

  const reloadLocalstore = (id: string) => {
    if (id !== '') {
      setLocalstoreId(id);
      localstoreRefetch();
    }
  };

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      localstoreRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    if (filter !== null && filter !== undefined) {
      console.log('filter :', filter);
      setPage(1);
      prefetch(1);
    }
  }, [filter]);

  // useEffect(() => {
  //   if (reloadLocalstore !== '') {
  //     localstoreRefetch();
  //   }
  // }, [reloadLocalstore]);

  return (
    <>
      <Box className='flex_between' sx={{ m: '1.5rem 0' }}>
        <Box>
          <TextField
            placeholder='가게 이름, 아이디, 주소, 업종, 계정으로 검색해 주세요.'
            value={searchWord}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSearchWord(evt.target.value)}
            onKeyDown={handleKeyDown}
            sx={{ width: '30rem', mr: '1em' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ pr: 0 }} onClick={searchLocalstore}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '1.5rem' }}>
        <Box className='flex_between'>
          <Box>
            <Typography>
              가게 <span className='bluecontent'>{totalRecords}</span> 개
            </Typography>
          </Box>
          <Box className='flex_between'>
            <Typography sx={{ mr: '.4rem' }}>Open</Typography>
            <SwSwitch
              defaultChecked={true}
              value={openSwitch}
              onChange={() => setOpenSwitch(!openSwitch)}
            />
            <Typography sx={{ ml: '1rem', mr: '.4rem' }}>Close</Typography>
            <SwSwitch
              defaultChecked={true}
              value={closeSwitch}
              onChange={() => setCloseSwitch(!closeSwitch)}
            />
          </Box>
        </Box>
        <TableContainer
          sx={{ p: '0rem', mt: '1rem', height: '30vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {requestColumnlists.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  {row.map((rowItem: any, rowIdx: number) => (
                    <TableCell
                      key={rowIdx.toString()}
                      className={
                        localstoreList[idx].localstoreStatus === 0
                          ? 'not_important_row'
                          : ''
                      }
                    >
                      {rowIdx !== 1
                        ? row[rowIdx]
                        : localstoreList[idx].localstoreStatus === 0
                        ? `${row[rowIdx]} (close)`
                        : row[rowIdx]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Paper sx={{ p: '0rem 0' }}>
        <Box sx={{ p: '0rem 0', mt: '2rem' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <CreateTabs value={tabValue} onChange={onChangeTab}>
              <Tab key={0} label='가게 정보' />
              <Tab key={1} label='리워드 정보' />
            </CreateTabs>
          </Box>
          <TabPanel value={tabValue} index={0} key={0}>
            <LocalstoreForm
              localstoreInfo={localstoreInfo}
              setLocalstoreInfo={setLocalstoreInfo}
              reloadLocalstore={reloadLocalstore}
              classTypeList={localstoreClassType}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1} key={1}>
            <LocalstoreReward localstoreInfo={localstoreInfo} />
          </TabPanel>
        </Box>
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box>
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => {
                window.location.reload();
              }}
            >
              신규작성
            </MarginButton>
          </Box>
          <Box>
            <Button
              variant='contained'
              color='info'
              onClick={() => checkFilter(0)}
            >
              승인
            </Button>
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
      <SwConfirmDialog
        contents='공개 대상 커뮤니티 혹은 지역 설정이 되어있지 않습니다. 이대로 개설하시겠습니까?'
        open={openFilter}
        onClose={() => setOpenFilter(false)}
        onConfirm={() => {
          confirmChallege(0);
          setOpenFilter(false);
        }}
        confirm={1}
      />
      {loading && <SwLoading />}
    </>
  );
};
