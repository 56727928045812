import { Box, Tab, Tabs } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../hooks/resize';
import { TabBox } from '../styles/Styles';
import { getPermission } from '../../common/helper';
import { useSession } from '../../hooks/session';

interface Props {}

export const EmpAdminister: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('accountmanagement');
        break;
      case 2:
        setValue(2);
        navigate('loginhistory');
        break;
      default:
        setValue(0);
        navigate('/empadminister');
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/empadminister/accountmanagement') setValue(1);
    else if (path === '/empadminister/loginhistory') setValue(2);
  }, [window.location.pathname]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>어드민 계정관리</title>
      </Helmet>
      <TabBox>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='계정 관리' value={1} />
          <Tab label='로그인 기록' value={2} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
