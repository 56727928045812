import {
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CloseIcon from '@mui/icons-material/Close';
import React, { useRef, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
  DatePicker,
  LocalizationProvider,
  PickersDay,
} from '@mui/x-date-pickers';
import koLocale from 'date-fns/locale/ko';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
}

const TitleCell = styled(TableCell)({
  width: '10rem',
  whiteSpace: 'nowrap',
  textAlign: 'center',
  borderRight: '1px solid #e0e0e0',
});

export const InAppMessageDrawUp: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
}) => {
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const fileUpload = document.getElementById('fileUpload');
  const [inAppRangeDate, setInAppRangeDate] = useState<any[]>([null, null]);
  const [openEndDate, setOpenEndDate] = useState<boolean>(false);
  return (
    <Dialog
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          minHeight: '56vh',
          minWidth: '44vw',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>인앱 메세지 작성</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent sx={{ p: '1.5rem 2.6em' }}>
        {edit ? (
          <>
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>제목</Typography>
            <TextField fullWidth />
            <Divider />
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>내용</Typography>
            <TextField
              multiline
              rows={8}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  height: 'auto',
                },
              }}
            />
            <Divider />
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
              이미지(선택)
            </Typography>
            <Paper
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                color: '#C2CAD4',
                width: '10em',
                p: '1.5em',
                m: '1em 0',
              }}
              aria-hidden
              onClick={() => fileUpload?.click()}
            >
              <AddOutlinedIcon
                sx={{
                  border: '1px dashed #C2CAD4',
                  borderRadius: '50%',
                  width: '3em',
                  height: 'auto',
                }}
              />
              <Typography sx={{ fontSize: '.75rem' }}>이미지 선택</Typography>
            </Paper>
            <input type='file' id='fileUpload' style={{ display: 'none' }} />
            <Divider />
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
              액션 버튼 링크
            </Typography>
            <Box className='flex_column'>
              <TextField
                select
                defaultValue={1}
                sx={{ width: '13rem', mb: '.8rem' }}
              >
                <MenuItem value={1}>URL</MenuItem>
                <MenuItem value={2}>챌린지</MenuItem>
                <MenuItem value={3}>커뮤니티</MenuItem>
                <MenuItem value={4}>외부 URL</MenuItem>
              </TextField>
              <TextField
                placeholder='URL 입력 등 선택에 따라'
                sx={{ width: '28rem' }}
              />
            </Box>
            <Divider />
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
              공개 플랫폼
            </Typography>
            <TextField select defaultValue={1} sx={{ width: '13rem' }}>
              <MenuItem value={1}>전체</MenuItem>
              <MenuItem value={2}>챌린지</MenuItem>
              <MenuItem value={3}>커뮤니티</MenuItem>
            </TextField>
            <Divider />
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
              적용 일시
            </Typography>
            <SwDateRangePicker
              rangeDate={inAppRangeDate}
              setRangeDate={setInAppRangeDate}
            />
          </>
        ) : (
          <>
            <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
              인앱 메세지 상세보기
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TitleCell>제목</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
                <TableRow>
                  <TitleCell>내용</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
                <TableRow>
                  <TitleCell>이미지</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
                <TableRow>
                  <TitleCell>액션 버튼 이름</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
                <TableRow>
                  <TitleCell>액션 버튼 링크</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
                <TableRow>
                  <TitleCell>공개 플랫폼</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
                <TableRow>
                  <TitleCell>적용 기간</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
                <TableRow>
                  <TitleCell>노출</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
                <TableRow>
                  <TitleCell>클릭</TitleCell>
                  <TableCell>~~~~~~~~~~</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
      </DialogContent>
      <Divider sx={{ p: 0, m: '2.2rem 0 0 0' }} />
      <DialogActions>
        {edit ? (
          <>
            <Button onClick={isEdit}>test</Button>
            <Button onClick={onClose} color='info' variant='outlined'>
              닫기
            </Button>
            <Button onClick={onConfirm} color='info' variant='contained'>
              저장
            </Button>
          </>
        ) : (
          <>
            <Button onClick={onClose} color='info' variant='outlined'>
              삭제
            </Button>
            <Button onClick={isEdit} color='info' variant='contained'>
              수정
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
