export interface IRewardCommand {
  challengeId: string;
  rewardId: string;
}

export interface IReward {
  challengeId?: string;
  rewardId: string;
  userIds?: string[];
  exceptUserIds: string[];
  challengeCondition?: any;
  exceptChallengeCondition?: any;
}

export interface IRewardInfo {
  challengeId: string;
  rewardId: string;
}

export interface IRewardDrawing {
  challengeId: string;
  rewardId: string;
  preDrawingCount: number;
  rewardCount: number;
}

export interface IRewardDrawingUserInfo {
  name: string;
  phoneno: string;
  userId: string;
  rewardId: string;
  rewardDrawResult: number;
  rewardDrawTime: number;
}

export interface IRewardDrawingUser {
  challengeId: string;
  rewardId: string;
  rewardCount: number;
  creator?: string;
  unexcept: IRewardDrawingUserInfo[];
  except: IRewardDrawingUserInfo[];
  all: IRewardDrawingUserInfo[];
  winner: IRewardDrawingUserInfo[];
}

export interface IRewardDrawingInfo {
  challengeId: string;
  rewardId: string;
}

export interface IRewardDrawingCommand {
  challengeId: string;
  rewardId: string;
}

export const DefaultRewardInfo: IRewardInfo = {
  challengeId: '',
  rewardId: '',
};

export const DefaultRewardDrawingUser: IRewardDrawingUser = {
  challengeId: '',
  rewardId: '',
  rewardCount: 0,
  unexcept: [],
  except: [],
  all: [],
  winner: [],
};
