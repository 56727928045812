import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import useLocalStorage from '../../hooks/storage';
import { getAmdList } from '../../common/api/ApiGeohash';
import { KEY_AMD_LIST } from '../../common/key';

import {
  AddGroupTextField,
  CreatePaper,
  NoneBorderChip,
  TermPaper,
} from '../styles/Styles';

interface Props {
  location: any[];
  setLocation: Dispatch<any[]>;
  showContainer?: boolean;
  showButton?: boolean;
  showDepth1?: boolean;
  limitKu?: boolean;
  selectOne?: boolean;
  includeAmd?: boolean;
  disabled?: boolean;
  mapId?: string;
}

export const SwSelectLocation: React.FC<Props> = ({
  location,
  setLocation,
  showContainer = true,
  showButton = true,
  showDepth1 = true,
  limitKu = false,
  selectOne = false,
  includeAmd = false,
  disabled = false,
  mapId = 'map',
}) => {
  const locationSearch = useRef<any>();
  const [locationLimit, setLocationLimit] = useState<any>([]);
  const [amdList, setAmdList] = useState<any>();
  const [siList, setSiList] = useState<any>([]);
  const [dongList, setDongList] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('');
  const [doIndex, setDoIndex] = useState<any>(0);
  const [siIndex, setSiIndex] = useState<any>(0);
  const [dongIndex, setDongIndex] = useState<any>(0);
  const [showDongSelector, setShowDongSelector] = useState<any>(true);

  const settingMenu = (newAmdList: any) => {
    if (location === undefined || location === null) return;

    const address = location[0].split(' ');

    if (address.length > 1) {
      const tmpDoIndex = newAmdList.doNames
        .map((item: any, idx: number) => (item === address[0] ? idx : -1))
        .filter((item: any) => item !== -1);
      const newDoIndex = tmpDoIndex !== null ? tmpDoIndex[0] + 1 : 0;
      setDoIndex(newDoIndex);

      if (newDoIndex > 0) setSiList(newAmdList.doList[newDoIndex - 1].siNames);

      const tmpSiIndex =
        newDoIndex === 0
          ? null
          : newAmdList.doList[newDoIndex - 1].siNames
              .map((item: any, idx: number) => (item === address[1] ? idx : -1))
              .filter((item: any) => item !== -1);
      const newSiIndex = tmpSiIndex !== null ? tmpSiIndex[0] + 1 : 0;
      setSiIndex(newSiIndex);

      if (newSiIndex > 0)
        setDongList(
          newAmdList.doList[newDoIndex - 1].siList[newSiIndex - 1].dongNames
        );

      if (address.length > 2) {
        const tmpDongIndex =
          newSiIndex === 0
            ? null
            : newAmdList.doList[newDoIndex - 1].siList[newSiIndex - 1].dongNames
                .map((item: any, idx: number) =>
                  item === address[1] ? idx : -1
                )
                .filter((item: any) => item !== -1);
        const newDongIndex = tmpDongIndex !== null ? tmpDongIndex[0] + 1 : 0;
        setDongIndex(newDongIndex);
      }
    }
  };

  const {
    data,
    isLoading,
    refetch: refetchAmd,
  } = useQuery(KEY_AMD_LIST, () => getAmdList(), {
    onSuccess: res => {
      if (res !== null) {
        const newAmd = res.amd;
        console.log('amdList newAMd:', newAmd);
        setAmdList(newAmd);
        if (selectOne && showButton === false && location.length > 0) {
          settingMenu(newAmd);
        }
      }
    },
    onError: e => {
      console.log(e);
    },
  });

  const selectSiInfo = (selected: string) => {
    if (Number(selected) > 0) {
      const idx = Number(selected) - 1;
      setSiList(amdList.doList[idx].siNames);
    }
    setSiIndex(0);
    setDongIndex(0);
    setShowDongSelector(true);
  };

  const selectDongInfo = (selected: string) => {
    if (Number(selected) > 0) {
      const idx = Number(selected) - 1;
      const dongName = amdList.doList[doIndex - 1].siList[idx].dongNames;
      setDongList(dongName);
    }
    setDongIndex(0);
  };

  const deleteSomething = (index: number, from: string) => {
    const newSomething = locationLimit.filter(
      (_val: any, i: number) => i !== index
    );
    setLocationLimit([...newSomething]);
  };

  const addLocationLimit = () => {
    const doName = doIndex > 0 ? amdList.doNames[doIndex - 1] : '';
    const siName =
      siIndex > 0 ? amdList.doList[doIndex - 1].siNames[siIndex - 1] : '';
    const siAmdId =
      siIndex > 0 ? amdList.doList[doIndex - 1].siList[siIndex - 1].amdId : '';
    const dongName =
      siIndex > 0 && dongIndex > 0
        ? amdList.doList[doIndex - 1].siList[siIndex - 1].dongNames[
            dongIndex - 1
          ]
        : '';

    let newLocaiton = `${doName} ${siIndex === 0 ? '' : siName} ${
      dongIndex === 0 ? '' : dongName
    }`.trimEnd();

    if (limitKu) {
      const newLimit = [newLocaiton];
      const lastChar = String(newLimit[0]).slice(-1);
      setShowDongSelector(false);
      // if (lastChar === '구') {
      //   setShowDongSelector(false);
      // } else {
      //   setShowDongSelector(true);
      // }
    }

    newLocaiton = newLocaiton.includes('전체')
      ? newLocaiton.split(' ')[0]
      : showDepth1
      ? newLocaiton
      : newLocaiton.split(' ')[1];

    if (selectOne && includeAmd) {
      setLocationLimit([newLocaiton]);
      setLocation([{ location: newLocaiton, amd: siAmdId }]);
    } else if (selectOne) {
      setLocationLimit([newLocaiton]);
      setLocation([newLocaiton]);
    } else {
      const newData = locationLimit.filter((d: string) => d === newLocaiton);
      if (newData.length === 0) {
        const newLimit = [...locationLimit, newLocaiton];
        setLocationLimit(newLimit);
        setLocation(newLimit);
      }
    }
  };

  useEffect(() => {
    if (doIndex > 0 && siIndex > 0) addLocationLimit();
  }, [doIndex, siIndex, dongIndex]);

  useEffect(() => {
    setLocationLimit(location);
  }, [location]);

  if (isLoading) return <div>isLoading...</div>;

  return (
    <>
      <div
        id={mapId}
        style={{ width: '100vw', height: '50vh', display: 'none' }}
      />
      <Box>
        {showContainer && <Typography className='title'>위치 제한</Typography>}
        <Box className='flex_center'>
          <TextField
            sx={{ width: '11rem', mr: '1rem', mb: 0 }}
            select
            disabled={disabled}
            defaultValue={0}
            value={doIndex}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setDoIndex(event.target.value);
              selectSiInfo(event.target.value);
            }}
          >
            <MenuItem value={0}>선택</MenuItem>
            {amdList &&
              amdList.doNames.map((option: string, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {option}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            sx={{ width: '11rem', mr: '1rem', mb: 0 }}
            select
            disabled={disabled}
            defaultValue={0}
            value={siIndex}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setSiIndex(event.target.value);
              selectDongInfo(event.target.value);
            }}
          >
            <MenuItem value={0}>선택</MenuItem>
            {siList.map((option: string, idx: number) => (
              <MenuItem key={idx + 1} value={idx + 1}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {limitKu === false && showDongSelector && (
            <TextField
              sx={{ width: '11rem', mr: '1rem', mb: 0 }}
              select
              disabled={disabled}
              defaultValue={0}
              value={dongIndex}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setDongIndex(event.target.value);
              }}
            >
              <MenuItem value={0}>선택</MenuItem>
              {dongList.map((option: string, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          )}
          {showButton && (
            <Button
              variant='contained'
              disabled={disabled}
              onClick={addLocationLimit}
            >
              추가
            </Button>
          )}
        </Box>
        {showContainer && (
          <>
            <Paper className='chip_container'>
              <Typography>지역</Typography>
              {locationLimit &&
                locationLimit.length > 0 &&
                locationLimit.map((item: any, idx: number) => (
                  <NoneBorderChip
                    label={item}
                    variant='outlined'
                    onDelete={() => deleteSomething(idx, 'locationLimit')}
                    key={idx.toString()}
                    sx={{
                      m: '.5em',
                      border: 'none',
                      '& .css-6od3lo-MuiChip-label': {
                        paddingRight: '6px',
                      },
                    }}
                  />
                ))}
              <div className='flex_end'>
                <Button
                  onClick={() => setLocationLimit([])}
                  color='info'
                  disabled={disabled}
                  sx={{ fontSize: '.8rem' }}
                >
                  <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
                  전체삭제
                </Button>
              </div>
            </Paper>
            <Divider />
          </>
        )}
      </Box>
    </>
  );
};
