import {
  Box,
  Button,
  Collapse,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { ChangeEvent, useState, useEffect } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import {
  getUserDailyStepList,
  downloadUserDailyStepList,
  patchUserChangeStep,
} from '../../../common/api/ApiUser';
import { KEY_USER_DAILY_STEP_LIST } from '../../../common/key';
import { SwUserChangeStep } from '../../commonComponent/SwUserChangeStep';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';

interface Props {}

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.9rem 2rem',
});
const PaginationBox = styled(Box)({
  padding: '.6rem',
  display: 'flex',
  justifyContent: 'center',
});

const columns = [
  '날짜',
  '걸음수',
  '연동 걸음 수(구api)',
  '챌린지 스텝',
  '수정',
];

export const UserDayWalk: React.FC<Props> = () => {
  const location = useLocation();
  const [userData, setUserData] = useState<any>(location.state);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [challengeId, setChallengeId] = useState<string>('');
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const [stepforChart, setStepforChart] = useState<any>([]);
  const [openChangeStep, setOpenChangeStep] = useState<any>(false);
  const [userStepIndex, setUserStepIndex] = useState<any>(-1);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: dailyStepRefetch,
  } = useQuery(
    KEY_USER_DAILY_STEP_LIST,
    () => {
      if (userData.id !== '') {
        return getUserDailyStepList(
          userData.id,
          page,
          rowsPerPage,
          rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
          rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
          challengeId
        );
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('dailyStepRefetch :', res);
        if (res !== null) {
          const newSteps =
            res.userDailyStep &&
            res.userDailyStep.map((step: any, idx: number) => ({
              step: step.step,
              wstep: step.wstep,
              cstep: step.cstep,
              day: step.day,
              timestamp: step.day,
            }));
          setStepforChart(newSteps);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_USER_DAILY_STEP_LIST, () =>
      getUserDailyStepList(
        userData.id,
        newPage,
        rowsPerPage,
        rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
        rangeDate[1] === null ? 0 : rangeDate[1].getTime(),
        challengeId
      )
    );
  };

  const { mutate: userChangeStepMutate, isError: isRoleMutateError } =
    useMutation(patchUserChangeStep, {
      onSuccess: () => {
        dailyStepRefetch();
        setOpenSnakerDialog(true);
        setDialogMessage('걸음수을 수정했습니다.');
      },
      onError: error => {
        console.log(error);
        setOpenSnakerDialog(true);
        setDialogMessage(
          `걸음수를 수정하는 동안 오류가 발생했습니다 : ${error}`
        );
      },
    });

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const onChangeStep = (day: string, step: number) => {
    const newData = {
      id: userData.id,
      day: day,
      step: step,
    };
    console.log('onChangeStep newData :', newData);
    userChangeStepMutate(newData);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      prefetch(page);
    }
  };

  useEffect(() => {
    if (userData.id !== '') dailyStepRefetch();
  }, [userData]);

  useEffect(() => {
    console.log('user day walk :', location.state);
    if (location.state) {
      setUserData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (rangeDate[0] !== null && rangeDate[1] !== null) dailyStepRefetch();
  }, [rangeDate[0], rangeDate[1]]);

  const onDownloadFile = () => {
    downloadUserDailyStepList(
      userData.id,
      rangeDate[0] === null ? 0 : rangeDate[0].getTime(),
      rangeDate[1] === null ? 0 : rangeDate[1].getTime()
    );
  };

  return (
    <>
      <Box
        className='flex_center'
        sx={{ justifyContent: 'flex-end', marginTop: '-1.4rem' }}
      >
        <TextField
          placeholder='챌린지 아이디'
          sx={{ width: '20rem' }}
          value={challengeId}
          onKeyDown={evt => {
            handleKeyDown(evt);
          }}
          onChange={(
            evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
          ) => setChallengeId(evt.target.value)}
        />
        <Button
          color='info'
          variant='outlined'
          sx={{ ml: '1rem', mr: '1rem', p: '.2rem 1rem' }}
          onClick={onDownloadFile}
        >
          데이터 다운로드
          <FileDownloadRoundedIcon color='success' />
        </Button>
      </Box>
      <Paper sx={{ p: '0 1.5rem 1.5rem', m: '1.4rem 0' }}>
        <Box
          sx={{
            p: '1rem .5rem',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box className='flex_center'>
            <Typography sx={{ fontSize: '.75rem' }}>일일 걸음수</Typography>
          </Box>
          <Box className='flex_center'>
            <Collapse orientation='horizontal' in={openFilter}>
              <SwDateRangePicker
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
              />
            </Collapse>
            <Button
              color='info'
              variant='outlined'
              sx={{ ml: '1rem' }}
              onClick={() => setOpenFilter(!openFilter)}
            >
              <FilterAltOutlinedIcon />
              필터
              <ArrowBackIosNewOutlinedIcon
                sx={{ fontSize: '1rem', ml: '.5rem' }}
              />
            </Button>
          </Box>
        </Box>
        <Table sx={{ maxWidth: 'max-content' }}>
          <TableHead>
            <TableRow>
              {columns
                .filter((column: string, indx) =>
                  column !== '챌린지 스텝'
                    ? true
                    : !!(challengeId !== null && challengeId !== '')
                )
                .map((column, idx) => (
                  <RowsCell
                    key={idx.toString()}
                    sx={{
                      fontSize: '.88rem',
                      fontWeight: 400,
                      p: '.5rem',
                      textAlign: 'center',
                    }}
                    size='small'
                  >
                    {column}
                  </RowsCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {stepforChart?.map((step: any, idx: number) => (
              <TableRow key={idx.toString()}>
                <RowsCell>{step.day}</RowsCell>
                <RowsCell>{step.step !== null ? step.step : '-'}</RowsCell>
                <RowsCell>{step.wstep !== null ? step.wstep : '-'}</RowsCell>
                {challengeId !== null && challengeId !== '' && (
                  <RowsCell>{step.cstep !== null ? step.cstep : '-'}</RowsCell>
                )}
                <RowsCell>
                  <Button
                    size='small'
                    variant='outlined'
                    color='info'
                    onClick={(evt: any) => {
                      setUserStepIndex(idx);
                      setOpenChangeStep(true);
                    }}
                  >
                    {step.step !== null ? '수정' : '추가'}
                  </Button>
                </RowsCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <PaginationBox>
          <Pagination
            color='primary'
            variant='outlined'
            siblingCount={3}
            page={page}
            onChange={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </PaginationBox>
      </Paper>
      <SwUserChangeStep
        open={openChangeStep}
        userStep={stepforChart[userStepIndex]}
        onClose={() => setOpenChangeStep(false)}
        onChangeStep={onChangeStep}
      />
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
