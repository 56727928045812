import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwAlert } from '../commonComponent/views/SwAlert';
import { useSession } from '../../hooks/session';
import { patchAccountPassword } from '../../common/api/ApiAccount';

interface Props {
  open: boolean;
  onClose?: () => void;
}
const AlertDialog = styled(Dialog)({
  margin: '0 0 10rem 10rem',
  '& .MuiDialog-paper': {
    width: '30vw',
    height: '40vh',
  },
});

export const ChangePassword: React.FC<Props> = ({ open, onClose }) => {
  const { loginVal, logout, session } = useSession();
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const onChangeAlertOpen = () => setAlertOpen(!alertOpen);

  const [inputs, setInputs] = useState([
    {
      id: '변경할 비밀번호',
      value: '',
      helperText: '',
      getHelperText: (error: boolean) =>
        error ? '4자 이상 입력해주세요.' : '',
      isValid: (value0: string, value1: string) => value0.length < 4,
      view: false,
    },
    {
      id: '변경할 비밀번호 확인',
      value: '',
      helperText: '',
      getHelperText: (valid: boolean) =>
        valid
          ? '비밀번호를 알맞게 입력하였습니다.'
          : '비밀번호를 알맞게 입력해 주세요.',
      isValid: (value0: string, value1: string) => {
        if (value0 === value1) return true;
        return false;
      },
      view: false,
    },
  ]);
  const onChangeText = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    const newInputs = [...inputs];
    const index = inputs.findIndex(input => input.id === event.target.id);
    const newInput = newInputs[index];
    const valid = newInput.isValid(
      index === 0 ? newValue : inputs[0].value,
      index === 1 ? newValue : inputs[1].value
    );
    newInputs[index] = {
      ...newInput,
      value: newValue,
      helperText: newInput.getHelperText(valid),
    };
    setInputs(newInputs);
  };

  const onChangeView = (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    const newInputs = [...inputs];
    const index = inputs.findIndex(
      input => input.id === event.currentTarget.id
    );
    const newInput = newInputs[index];
    const newView = !newInput.view;
    newInputs[index] = {
      ...newInput,
      view: newView,
    };
    setInputs(newInputs);
  };
  // TODO 비밀번호 최소 길이, 확인 틀릴때

  const { mutate: accountPasswordMutate, isError: isRoleMutateError } =
    useMutation(patchAccountPassword, {
      onSuccess: () => {
        setAlertMessage('비밀번호가 변경되었습니다.');
        setAlertOpen(true);
      },
      onError: error => {
        console.log(error);
      },
    });

  const onConfirm = () => {
    const newEmp = {
      name: '',
      phoneNumber: '',
      id: session.user?.id,
      password: inputs[0].value,
      role: '',
    };

    if (inputs[0].value.length < 4) {
      setAlertMessage('유효하지 않은 패스워드입니다. (4자 이상).');
      setAlertOpen(true);
    } else if (inputs[0].value === inputs[1].value)
      accountPasswordMutate(newEmp);
    else {
      setAlertMessage('입력한 비밀번호와 비밀번호 확인이 다릅니다.');
      setAlertOpen(true);
    }
  };

  // useEffect(() => {
  //   if (open === false) {
  //     const newInputs = [...inputs];
  //     newInputs[0] = {
  //       ...newInputs[0],
  //       value: '',
  //       helperText: newInputs[0].getHelperText(true),
  //     };
  //     newInputs[1] = {
  //       ...newInputs[1],
  //       value: '',
  //       helperText: newInputs[1].getHelperText(true),
  //     };
  //     setInputs(newInputs);
  //   }
  // }, [open]);

  return (
    <AlertDialog open={open} onClose={onClose}>
      <Box className='flex_between' sx={{ p: '.4rem 1rem' }}>
        <Typography variant='h6'>비밀번호 변경하기</Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent sx={{ pb: '3rem' }}>
        {inputs.map((input, idx) => (
          <Box key={idx.toString()}>
            <Typography className='title'>{input.id}</Typography>
            <TextField
              id={input.id}
              value={input.value}
              helperText={input.helperText}
              type={input.view ? 'text' : 'password'}
              sx={{
                width: '20rem',
                '& .MuiFormHelperText-root': {
                  color:
                    input.helperText === '비밀번호를 알맞게 입력하였습니다.'
                      ? '#000000'
                      : '#F63454',
                },
              }}
              onChange={onChangeText}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton onClick={onChangeView} id={input.id}>
                      {input.view ? (
                        <VisibilityOffIcon sx={{ fontSize: '1.2rem' }} />
                      ) : (
                        <VisibilityIcon sx={{ fontSize: '1.2rem' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <SwAlert
              title='비밀번호 변경'
              open={alertOpen}
              onConfirm={onChangeAlertOpen}
              contents={alertMessage}
            />
          </Box>
        ))}
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <Box className='flex_end' sx={{ p: '.6rem 1.5rem .6rem 1rem' }}>
        <Button color='error' variant='outlined' onClick={onConfirm}>
          완료
        </Button>
      </Box>
    </AlertDialog>
  );
};
