import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { ChangeEvent, useState } from 'react';
import { validCheck } from '../../../common/helper';

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  email: any;
}

export const UserEmailAdd: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  email,
}) => {
  const a = 1;
  const [showAlert, setShowAlert] = useState<any>(false);
  const checkEmail = () => {
    if (validCheck('email', email.current.value) === false) {
      setShowAlert(true);
    } else {
      onConfirm();
    }
  };

  return (
    <Dialog
      open={open}
      //   fullWidth
      sx={{
        '& .MuiDialog-container': {
          height: '85%',
        },
      }}
    >
      <DialogTitle className='flex_between' sx={{ p: '.6rem 1rem' }}>
        <Box sx={{ fontSize: '1rem' }}>이메일 추가</Box>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Typography sx={{ m: '1rem 0' }}>이메일</Typography>
        <TextField
          sx={{ width: '18rem', mb: '4rem' }}
          inputRef={email}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setShowAlert(false)}
        />
        {showAlert && (
          <Typography sx={{ color: 'red' }}>이메일 형식이 아닙니다.</Typography>
        )}
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        <Button variant='outlined' color='info' onClick={checkEmail}>
          완료
        </Button>
      </DialogActions>
    </Dialog>
  );
};
