import {
  Box,
  Button,
  Chip,
  Dialog,
  Paper,
  styled,
  Switch,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';

export const ButtonBox = styled(Box)({
  paddingBottom: '2rem',
  display: 'flex',
  justifyContent: 'flex-between',
  alignItems: 'center',
});

export const MarginButton = styled(Button)({
  marginRight: '1rem',
});

export const CreatePaper = styled(Paper)({
  margin: '1.5rem 0',
  padding: '1.5rem',
});

export const ContentTitle = styled(Typography)({
  padding: '.5rem',
  marginBottom: '.5rem',
  fontWeight: 500,
});

export const QuestionAnswerTypeBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
});

export const QuestionAnswerTypeTitle = styled(Typography)({
  marginRight: '1rem',
});

export const ColorTableCell = styled(TableCell)({
  width: '20%',
  backgroundColor: '#f3f3f3f8',
  fontWeight: 'bold',
});
