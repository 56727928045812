import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  styled,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';

interface Props {
  isSearching: () => void;
  optionValue: any;
  searchWord: any;
  searchOption: any[];
  selectLength?: string;
  searchLength?: string;
  defaultOption: any;
  placeholderString?: string;
}

const Container = styled(Box)({
  borderRadius: '12px',
  border: '1px solid #D4D9E1',
  display: 'flex',
  backgroundColor: '#FFFFFF',
  alignItems: 'center',
});

const NoborderInput = styled(TextField)({
  marginBottom: 0,
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
  },
});

const SearchDivider = styled(Divider)({
  padding: 0,
  margin: '.4rem .4rem .4rem 0',
});

export const SelectSearchBox: React.FC<Props> = ({
  optionValue,
  searchWord,
  searchOption,
  defaultOption,
  isSearching,
  searchLength = '26rem',
  selectLength = '10rem',
  placeholderString = '검색어를 입력해주세요.',
}) => {
  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      isSearching();
    }
  };

  return (
    <div className='flex_center'>
      <Container>
        <NoborderInput
          select
          defaultValue={defaultOption}
          inputRef={optionValue}
          sx={{ width: selectLength }}
        >
          {searchOption.map((option: any) => (
            <MenuItem value={option.value} key={option.value}>
              {option.title}
            </MenuItem>
          ))}
        </NoborderInput>
        <SearchDivider orientation='vertical' flexItem />
        <NoborderInput
          placeholder={placeholderString}
          inputRef={searchWord}
          sx={{ width: searchLength }}
          onKeyDown={handleKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={isSearching} sx={{ pr: 0 }}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Container>
    </div>
  );
};
