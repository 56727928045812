import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import useLocalStorage from '../../hooks/storage';
import { getGeoHashGroupList } from '../../common/api/ApiGeohash';
import { KEY_GEOHASH_GROUP_LIST } from '../../common/key';

import {
  AddGroupTextField,
  CreatePaper,
  NoneBorderChip,
  TermPaper,
} from '../styles/Styles';
import { encodeSearchWord, timestamp2Localestring } from '../../common/helper';

interface Props {
  geohashGroup: any[];
  setGeohashGroup: Dispatch<any[]>;
  showContainer?: boolean;
  disabled?: boolean;
  title?: string;
}

export const SwSelectGeohashGroup: React.FC<Props> = ({
  geohashGroup,
  setGeohashGroup,
  showContainer = true,
  disabled = false,
  title = '지역',
}) => {
  const locationSearch = useRef<any>();
  const [geohashGroupLimit, setGeohashGroupLimit] = useState<any>([]);
  const [geohashGroupList, setGeohashGroupList] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [geohashIndex, setGeohashIndex] = useState<any>(0);

  const {
    data,
    isLoading,
    refetch: refetchAmd,
  } = useQuery(
    KEY_GEOHASH_GROUP_LIST,
    () =>
      getGeoHashGroupList(searchKey, encodeSearchWord(searchKey, searchWord)),
    {
      onSuccess: res => {
        if (res != null) {
          const newGeohashList = res.geohash.map(
            (geohash: any, idx: number) => {
              const newDate = timestamp2Localestring(geohash.createDate);
              const newGeohash = {
                id: geohash.geoHashId,
                name: geohash.name,
                creator: geohash.creator,
                group: geohash.geoHashGroup,
                createDate: newDate,
              };
              return newGeohash;
            }
          );
          setGeohashGroupList(newGeohashList);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const deleteSomething = (index: number, from: string) => {
    const newSomething = geohashGroupLimit.filter(
      (_val: any, i: number) => i !== index
    );
    setGeohashGroupLimit([...newSomething]);
  };

  const addGeohashGroupLimit = () => {
    const selectGroup = geohashGroupList[geohashIndex - 1];
    if (
      geohashGroupLimit.filter((group: any) => group.id === selectGroup.id)
        .length === 0
    ) {
      const newLimit = [...geohashGroupLimit, selectGroup];
      setGeohashGroupLimit(newLimit);
      setGeohashGroup(newLimit);
    }
  };

  useEffect(() => {
    setGeohashGroupLimit(geohashGroup);
  }, [geohashGroup]);

  return (
    <>
      <Box>
        {showContainer && (
          <Typography className='title'>지오해시 제한</Typography>
        )}
        <Box className='flex_center'>
          <TextField
            sx={{ width: '20.5rem', mr: '.5rem' }}
            select
            disabled={disabled}
            defaultValue={0}
            value={geohashIndex}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setGeohashIndex(event.target.value);
            }}
          >
            <MenuItem value={0}>{`${title}필터 선택`}</MenuItem>
            {geohashGroupList &&
              geohashGroupList.map((option: any, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {option?.name}
                </MenuItem>
              ))}
          </TextField>
          <Button
            variant='contained'
            disabled={disabled}
            onClick={addGeohashGroupLimit}
          >
            추가
          </Button>
        </Box>
        {showContainer && (
          <>
            <Paper className='chip_container'>
              <Typography>지역</Typography>
              {geohashGroupLimit.length > 0 &&
                geohashGroupLimit.map((location: any, idx: number) => (
                  <NoneBorderChip
                    label={location}
                    variant='outlined'
                    onDelete={() => deleteSomething(idx, 'locationLimit')}
                    key={idx.toString()}
                    sx={{
                      m: '.5em',
                      border: 'none',
                      '& .css-6od3lo-MuiChip-label': {
                        paddingRight: '6px',
                      },
                    }}
                  />
                ))}
              <div className='flex_end'>
                <Button
                  disabled={disabled}
                  onClick={() => setGeohashGroupLimit([])}
                  color='info'
                  sx={{ fontSize: '.8rem' }}
                >
                  <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
                  전체삭제
                </Button>
              </div>
            </Paper>
            <Divider />
          </>
        )}
      </Box>
    </>
  );
};
