import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { AccessRestrictionDrawUp } from './AccessRestrictionDrawUp';
import limitdummy from '../../../dummy/limitdummy.json';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SelectTextField } from '../../styles/Styles';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { newUuidId } from '../../commonComponent/SwUuidCreate';

interface Props {}

const columns = [
  '제목',
  '내용',
  '작성자',
  '적용 시작일',
  '적용 종료일',
  '수정',
];

export const AccessRestrictionManage: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [list, setList] = useState<any>(limitdummy);
  const [selectList, setSelectList] = useState<any>();
  const [openModal, setOpenModal] = useState(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };
  const [selected, setSelected] = useState<readonly string[]>([]);
  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelecteds = communityMembers.map((n: any) => n.name);
      // setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const onCloseModal = () => {
    setOpenModal(false);
    setEdit(false);
    setRangeDate([null, null]);
  };
  const reviseList = (index: number) => {
    setSelectList(list[index]);
    setEdit(true);
    setOpenModal(true);
  };
  const onChangeList = () => {
    if (
      titleRef.current.value &&
      contentRef.current.value &&
      rangeDate[0] &&
      rangeDate[1]
    ) {
      const newList = list;
      if (edit) {
        const newItem = {
          ...selectList,
          title: titleRef.current.value,
          content: contentRef.current.value,
          startDate: rangeDate[0],
          endDate: rangeDate[1],
        };
        const index = newList.findIndex((item: any) => item.id === newItem.id);
        newList[index] = newItem;
      } else {
        const newItem = {
          id: newUuidId(),
          title: titleRef.current.value,
          content: contentRef.current.value,
          startDate: rangeDate[0],
          endDate: rangeDate[1],
          creator: 'test',
        };
        newList.push(newItem);
      }
      setList(newList);
      setSelectList(null);
      setOpenModal(false);
      setEdit(false);
    }
  };

  useEffect(() => {
    if (edit) {
      setRangeDate([selectList.startDate, selectList.endDate]);
      setTimeout(() => {
        titleRef.current.value = selectList?.title;
        contentRef.current.value = selectList?.contents;
      }, 0);
    }
  }, [openModal]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>접속 제한 관리</title>
      </Helmet>
      <Box className='flex_end' sx={{ m: '2rem 0' }}>
        <Button variant='contained' onClick={() => setOpenModal(true)}>
          <AddIcon />
          작성하기
        </Button>
      </Box>
      <Paper sx={{ m: '1rem 0', p: '1rem' }}>
        <Box className='flex_between'>
          <TableTitle title='등록 내역' count={53} />
          <SelectTextField
            select
            value={rowsPerPage}
            onChange={evt => {
              setRowsPerPage(Number(evt.target.value));
              setPage(1);
            }}
          >
            {rowsPerPageOptions.map(option => (
              <MenuItem key={option.toString()} value={option}>
                {option}개씩 보기
              </MenuItem>
            ))}
          </SelectTextField>
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox />
              </TableCell>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list
              ?.slice((page - 1) * rowsPerPage, page * rowsPerPage)
              ?.map((item: any, idx: number) => (
                <TableRow key={idx.toString()}>
                  <TableCell padding='checkbox'>
                    <Checkbox />
                  </TableCell>
                  <TableCell sx={{ cursor: 'pointer' }}>{item.title}</TableCell>
                  <TableCell sx={{ width: '44%' }}>{item.contents}</TableCell>
                  <TableCell>{item.creator}</TableCell>
                  <TableCell>{item.startDate}</TableCell>
                  <TableCell>{item.endDate}</TableCell>
                  <TableCell sx={{ width: '12%', minWidth: '150px' }}>
                    <Button
                      variant='contained'
                      color='info'
                      onClick={() => reviseList(idx)}
                    >
                      내용 수정
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={list && Math.ceil(list.length / rowsPerPage)}
        />
      </Paper>
      <Box className='flex_end' sx={{ m: '1rem 0' }}>
        <Button variant='outlined' color='info'>
          삭제
        </Button>
      </Box>
      <AccessRestrictionDrawUp
        open={openModal}
        onClose={onCloseModal}
        onConfirm={onChangeList}
        title={titleRef}
        content={contentRef}
        rangeDate={rangeDate}
        setRangeDate={setRangeDate}
        edit={edit}
      />
    </div>
  );
};
