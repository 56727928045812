import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CloseIcon from '@mui/icons-material/Close';
import koLocale from 'date-fns/locale/ko';
import SearchIcon from '@mui/icons-material/Search';

import { debouncehook } from '../../../hooks/debouncehook';
import { useMany } from '../../../hooks/api';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { IPushUpdate, DefaultPush } from '../../../models/push';
import { patchPush } from '../../../common/api/ApiPush';

interface Props {
  targetPush: any;
  open: boolean;
  onClose: () => void;
}

const DateChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const ReceiverChip = styled(Chip)({
  border: 'none',
  '& .MuiChip-deleteIcon': {
    fontSize: '.9rem',
    marginTop: '.1rem',
  },
});

const LinkerChip = styled(Chip)({
  border: 'none',
  '& .MuiChip-deleteIcon': {
    fontSize: '.9rem',
    marginTop: '.1rem',
  },
});

const RootPaper = styled(Paper)({
  // margin: '2.8rem',
  padding: '1rem',
  borderRadius: '12px',
});

const ScrollPaper = styled(Paper)({
  maxHeight: '10rem',
  overflowY: 'scroll',
  padding: '.5rem',
  width: '44rem ',
});

const SwReceivers = [
  { title: '전체', value: 'all' },
  { title: '공식 커뮤니티', value: 'community' },
  { title: '챌린지', value: 'challenge' },
  { title: '프로그램', value: 'program' },
  { title: '리워드', value: 'reward' },
  { title: '지역', value: 'region' },
  { title: '위치', value: 'location' },
  { title: '앱 업데이트 버전', value: 'lastUpdated' },
  { title: '단말 OS', value: 'os' },
];
const push = [
  { title: '워크온 홈', value: 'home' },
  { title: '공식 커뮤니티', value: 'community' },
  { title: '챌린지', value: 'challenge' },
  { title: '프로그램', value: 'program' },
  { title: '질의응답', value: 'questionanswer' },
  // { title: '리워드', value: 'reward' },
  // { title: 'URL', value: 'url' },
];

export const PushUpdate: React.FC<Props> = ({ targetPush, open, onClose }) => {
  const site = 'webAdmin';
  const navigate = useNavigate();
  const findReceiverRef = useRef<any>();
  const pushLinkRef = useRef<any>();
  const testIdRef = useRef<any>();
  const [inputs, setInputs] = useState([
    {
      id: '제목',
      value: '',
      placeholder: '제목을 입력해주세요.',
      helperText: '',
      getHelperText: (error: boolean) =>
        error ? '50자 이하로 적어주세요' : '',
      isValid: (value: string) => value.length > 50,
    },
    {
      id: '내용',
      value: '',
      placeholder: '알림 내용을 입력해주세요.',
      helperText: '',
      getHelperText: (error: boolean) =>
        error ? '70자 이하로 적어주세요' : '',
      isValid: (value: string) => value.length > 70,
    },
  ]);
  var [receiverClassification, setReceiverClassification] =
    useState<string>('community');
  const [receiverClassificationDetail1, setReceiverClassificationDetail1] =
    useState<string>('all');
  const [receiverClassificationDetail2, setReceiverClassificationDetail2] =
    useState<string>('all');
  const [receiverClassificationDetail3, setReceiverClassificationDetail3] =
    useState<string>('all');
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [findReceiver, setFindReceiver] = useState<string>('');
  const [receivers, setReceivers] = useState<any[]>([]);
  const [linkers, setLinkers] = useState<any[]>([]);
  const [openType, setOpenType] = useState<number>(0);
  const [pushLink, setPushLink] = useState<any>('');
  const [pushDates, setPushDates] = useState<any[]>([]);
  const [sendDates, setSendDates] = useState<any[]>([]);
  const [isReservation, setIsReservation] = useState<boolean>(true);
  const [checkPickId, setCheckPickId] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [snackbarContents, setSnackbarContents] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [title, setTitle] = useState<string[]>([]);
  const onChangeText = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = event.target.value;
    const newInputs = [...inputs];
    const index = inputs.findIndex(input => input.id === event.target.id);
    const newInput = newInputs[index];
    const error = newInput.isValid(newValue);
    newInputs[index] = {
      ...newInput,
      value: newValue,
      helperText: newInput.getHelperText(error),
    };
    setInputs(newInputs);
  };

  const { mutate: updatePushMutate, isError: isPushMutateError } = useMutation(
    patchPush,
    {
      onSuccess: () => {
        setOpenMore(1);
        setSnackbarContents('푸시가 수정되었습니다.');
        onClose();
      },
      onError: error => {
        setOpenMore(1);
        setSnackbarContents(
          '푸시 수정에 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    }
  );

  const sendUpdatePush = () => {
    if (receivers.length > 0) {
      const { id, name } = receivers[0];
      var targetType = 'user';
      if (
        receiverClassification === 'challenge' ||
        receiverClassification === 'community' ||
        receiverClassification === 'reward'
      ) {
        targetType = receiverClassification;
      }

      var linkData = '';
      const linkPostfix = linkers.length > 0 ? linkers[0].id : '';
      if (pushLink === 'home') linkData = 'walkon://home/popup';
      else if (pushLink === 'challenge')
        linkData = `walkon://home/challenge?challenge_id=${linkPostfix}`;
      else if (pushLink === 'community')
        linkData = `walkon://home/my_community/detail?id=${linkPostfix}`;
      else if (pushLink === 'reward')
        linkData = `walkon://home/my_community/detail?id=${linkPostfix}`;
      else if (pushLink === 'questionanswer')
        linkData = `walkon://home/qna?qnaId=${linkPostfix}`;
      else if (pushLink === 'program')
        linkData = `walkon://home/program?programId=${linkPostfix}`;
      else if (pushLink === 'url') linkData = pushLinkRef.current?.value;

      const newDates = pushDates.map(pushDate => pushDate.getTime() / 1000);

      const newPush: IPushUpdate = {
        pushId: targetPush.pushId,
        receiver: {
          type: targetType,
          id: id,
          name: name,
        },
        title: inputs[0].value,
        content: inputs[1].value,
        link: linkData,
        linkName: linkers.length > 0 ? linkers[0].name : '',
        site: site,
        requestDate: newDates,
      };
      updatePushMutate(newPush);
    } else {
      setOpenMore(1);
      setSnackbarContents(
        '누락된 항목이 있습니다. 확인 후 다시 시도해주시기 바랍니다.'
      );
    }
  };

  const confirmUpdatePush = () => {
    if (pushDates.length === 0) {
      setOpenMore(1);
      setSnackbarContents('적용일시를 지정해야 합니다.');
    } else if (inputs[0].value.length === 0) {
      setOpenMore(1);
      setSnackbarContents('제목을 입력해야 합니다.');
    } else if (inputs[1].value.length === 0) {
      setOpenMore(1);
      setSnackbarContents('내용을 입력해야 합니다.');
    } else if (receivers.length > 0) {
      const newSendDates = pushDates.map(pushDate =>
        moment(pushDate).format('YYYY-MM-DD HH:mm:ss')
      );
      setSendDates(newSendDates);
      sendUpdatePush();
    } else {
      setOpenMore(1);
      setSnackbarContents(
        '누락된 항목이 있습니다. 확인 후 다시 시도해주시기 바랍니다.'
      );
    }
  };

  useEffect(() => {
    const newSearchResult = [...searchResult];
    if (openType === 0) {
      setReceivers(newSearchResult);
    } else if (openType === 1) {
      setLinkers(newSearchResult);
    }
    return () => {};
  }, [searchResult]);

  useEffect(() => {
    if (open) {
      setReceivers([
        {
          id: targetPush.receiver.id,
          name: targetPush.receiver.name
            ? targetPush.receiver.name
            : '이름 저장안됨',
        },
      ]);
      const newInputs = [...inputs];
      const newInput0 = newInputs[0];
      const newInput1 = newInputs[1];
      newInputs[0] = {
        ...newInput0,
        value: targetPush.title,
        helperText: newInput0.getHelperText(false),
      };
      newInputs[1] = {
        ...newInput1,
        value: targetPush.content,
        helperText: newInput1.getHelperText(false),
      };
      setInputs(newInputs);
      const newLinkers =
        targetPush.link === null || targetPush.link === ''
          ? []
          : [
              {
                id: targetPush.link.includes('walkon://home/popup')
                  ? targetPush.link
                  : targetPush.link.split('=')[1] !== null &&
                    targetPush.link.split('=')[1] !== undefined
                  ? targetPush.link.split('=')[1]
                  : targetPush.link.split('=')[0],
                name: targetPush.linkName,
              },
            ];
      setLinkers(newLinkers);
      setPushDates([new Date(targetPush.requestDate)]);
    }
    return () => {};
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        sx={{
          '& .MuiDialog-paper': {
            height: '66vh',
            margin: '10rem',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between' sx={{ mt: '0.5rem' }}>
            <span>푸시 알림 수정하기</span>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box className='flex_between'>
            <Box sx={{ p: '0.5rem' }}>
              <Typography className='title'>받는 사람</Typography>
              <div style={{ display: 'flex', marginTop: '.8rem' }}>
                <TextField
                  select
                  sx={{ width: '12rem', mr: '1rem' }}
                  value={receiverClassification}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setReceiverClassification(event.target.value);
                    setReceivers([]);
                  }}
                >
                  {SwReceivers.map((receiver: any, idx: number) => (
                    <MenuItem value={receiver.value} key={idx.toString()}>
                      {receiver.title}
                    </MenuItem>
                  ))}
                </TextField>
                <div>
                  {(receiverClassification === 'challenge' ||
                    receiverClassification === 'community' ||
                    receiverClassification === 'program' ||
                    receiverClassification === 'group' ||
                    receiverClassification === 'reward') && (
                    <TextField
                      placeholder={`${
                        receiverClassification === 'challenge'
                          ? '챌린지'
                          : receiverClassification === 'community' ||
                            receiverClassification === 'group'
                          ? '커뮤니티'
                          : receiverClassification === 'program'
                          ? '프로그램'
                          : '리워드'
                      } 이름, 아이디 검색`}
                      inputRef={findReceiverRef}
                      sx={{ width: '44rem' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              onClick={() => {
                                const selected = SwReceivers.map(item => {
                                  if (item.value === receiverClassification)
                                    return item.title;
                                  return '';
                                }).filter(value => value !== '');
                                setTitle(selected);
                                setSearchKey(receiverClassification);
                                setSearchWord(findReceiverRef.current?.value);
                                setOpenType(0);
                                setOpenSearchResult(true);
                              }}
                              sx={{ pr: 0 }}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </div>
                {(receiverClassification === 'region' ||
                  receiverClassification === 'location') && (
                  <TextField
                    select
                    sx={{ width: '12rem' }}
                    defaultValue='all'
                    value={receiverClassificationDetail1}
                    onChange={(
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => {
                      setReceiverClassificationDetail1(event.target.value);
                      console.log('eve>>>>>>>>>>>', event.target.value);
                    }}
                  >
                    {SwReceivers.map((receiver: any, idx: number) => (
                      <MenuItem value={receiver.value} key={idx.toString()}>
                        {receiver.title}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </div>
              <Grid container sx={{ mt: '.5rem', width: '120rem ' }}>
                {receivers.length > 0 &&
                  receivers.map((receive: any, idx: number) => (
                    <Grid item xs={5} key={idx.toString()}>
                      <ReceiverChip
                        variant='outlined'
                        label={
                          <>
                            <span>{receive.name}&nbsp;&nbsp;</span>
                            <span className='bluecontent'>{receive.id}</span>
                          </>
                        }
                        onDelete={() => {
                          const newReceiver = receivers.filter(
                            (r: string, i: number) => i !== idx
                          );
                          setReceivers(newReceiver);
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
              <Divider />
              {inputs.map((input, idx) => (
                <div key={idx.toString()}>
                  <Typography className='title'>{input.id}</Typography>
                  <TextField
                    id={input.id}
                    placeholder={input.placeholder}
                    value={input.value}
                    helperText={input.helperText}
                    fullWidth
                    sx={{ mt: '.8rem' }}
                    onChange={onChangeText}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <span>
                            {input.value.length} /{' '}
                            {input.id === '제목' ? 50 : 70}
                          </span>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Divider />
                </div>
              ))}
              <Typography className='title'>링크 이동</Typography>
              <Typography
                sx={{
                  fontSize: '.75rem',
                  color: '#999999',
                  m: '-.8rem 0 1rem .15rem',
                }}
              >
                푸시 알림 클릭 시 이동할 곳을 골라주세요.
              </Typography>
              <div>
                <TextField
                  select
                  defaultValue='home'
                  value={pushLink}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setPushLink(event.target.value);
                    setLinkers([]);
                  }}
                  sx={{ width: '12rem', mr: '1rem' }}
                >
                  {push.map((q, idx) => (
                    <MenuItem value={q.value} key={idx.toString()}>
                      {q.title}
                    </MenuItem>
                  ))}
                </TextField>
                {(pushLink === 'challenge' ||
                  pushLink === 'community' ||
                  pushLink === 'program' ||
                  pushLink === 'questionanswer' ||
                  pushLink === 'reward' ||
                  pushLink === 'url') && (
                  <TextField
                    placeholder={`${
                      pushLink === 'challenge'
                        ? '챌린지'
                        : pushLink === 'community'
                        ? '커뮤니티'
                        : pushLink === 'program'
                        ? '프로그램'
                        : pushLink === 'questionanswer'
                        ? '질의응답'
                        : pushLink === 'reward'
                        ? '리워드'
                        : 'url를 입력해주세요.'
                    } ${pushLink !== 'url' ? '이름, 아이디 검색' : ''}`}
                    sx={{ width: '44rem' }}
                    inputRef={pushLinkRef}
                    InputProps={{
                      endAdornment: pushLink !== 'url' && (
                        <InputAdornment position='end'>
                          <IconButton
                            sx={{ pr: 0 }}
                            onClick={() => {
                              const selected = push
                                .map(item => {
                                  if (item.value === pushLink)
                                    return item.title;
                                  return '';
                                })
                                .filter(value => value !== '');
                              console.log('title :', selected);
                              setTitle(selected);
                              setSearchKey(pushLink);
                              setSearchWord(pushLinkRef.current?.value);
                              setOpenType(1);
                              setOpenSearchResult(true);
                            }}
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              </div>
              <Grid container sx={{ mt: '.5rem', width: '120rem ' }}>
                {linkers.length > 0 &&
                  linkers.map((linker: any, idx: number) => (
                    <Grid item xs={5} key={idx.toString()}>
                      <LinkerChip
                        variant='outlined'
                        label={
                          <>
                            <span>{linker.name}&nbsp;&nbsp;</span>
                            <span className='bluecontent'>{linker.id}</span>
                          </>
                        }
                        onDelete={() => {
                          const newLinkers = linkers.filter(
                            (r: string, i: number) => i !== idx
                          );
                          setLinkers(newLinkers);
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
              <Divider />
              <Box
                className='flex_center'
                sx={{ p: '.5rem', pl: '.15rem', mb: '.33rem' }}
              >
                <Typography sx={{ fontWeight: 500 }}>
                  적용 일시 {!!pushDates.length && `(${pushDates.length}개)`}
                </Typography>
              </Box>
              <SwDatesPicker
                dates={pushDates}
                setDates={setPushDates}
                maxLimit={1}
              />
            </Box>
          </Box>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              sx={{ mr: '1rem', width: '5rem' }}
              variant='contained'
              color='secondary'
              onClick={() => {
                setOpenMore(2);
              }}
            >
              취소
            </Button>
            <Button
              sx={{ width: '9rem' }}
              variant='contained'
              color='info'
              onClick={() => {
                confirmUpdatePush();
              }}
            >
              저장
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      {openMore === 1 && (
        <SwSnackbar
          open={!!openMore}
          onClose={() => {
            setOpenMore(0);
            setSnackbarContents('');
          }}
          contents={snackbarContents}
        />
      )}
      {openMore === 2 && (
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography sx={{ mt: '0.7rem' }}>
              수정한 내용이 존재합니다.
            </Typography>
            <Typography sx={{ mt: '0.7rem' }}>
              저장하지 않고 닫겠습니까?
            </Typography>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => {
                setOpenMore(0);
                onClose();
              }}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <SwSearchTarget
        title={title !== null ? title[0] : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
    </>
  );
};
