import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';

import AddIcon from '@mui/icons-material/Add';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import { useResize } from '../../../hooks/resize';
import { FAQDrawup } from './FAQDrawup';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';

import {
  getDataPortalFaqList,
  deleteDataPortalFaq,
} from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_FAQ_LIST } from '../../../common/key';
import { IDataPortalFaq } from '../../../models/dataportal';
import {
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';

interface Props {}

const columns = ['제목', '작성자', '작성일시'];

export const FAQ: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const { isMobile } = useResize();
  const [openDrawup, setOpenDrawup] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [faqList, setFaqList] = useState<any>([]);
  const [selectFaq, setSelectFaq] = useState<any>(null);

  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const {
    data,
    isLoading,
    refetch: dataportalFaqRefetch,
  } = useQuery(
    KEY_DATAPORTAL_FAQ_LIST,
    () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
      };
      if (checkRequest) return getDataPortalFaqList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newFaqList =
          res &&
          res.faq &&
          res.faq.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate);
            const newData = {
              idx: String(item.idx),
              title: item.title,
              content: item.content,
              creator: item.creator,
              createDate: newDate,
            };
            return newData;
          });
        setFaqList(newFaqList);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_DATAPORTAL_FAQ_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
      };
      return getDataPortalFaqList(newData);
    });
  };

  const { mutate: deleteDataPortalFaqMutation, isError: isDeleteMutateError } =
    useMutation(deleteDataPortalFaq, {
      onSuccess: () => {
        setOpenAlert(false);
        setOpenDialog(true);
        setDialogMessage('자주 묻는 질문을 삭제했습니다.');
        dataportalFaqRefetch();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '자주 묻는 질문을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const deleteFaq = () => {
    const idxList = checkedButtons.map((item: string) => Number(item));
    const newData: IDataPortalFaq = {
      idxList: idxList,
    };
    deleteDataPortalFaqMutation(newData);
  };

  const clickFaq = (idx: number) => {
    console.log('idx: ', Number(idx), ', guide: ', faqList[idx]);
    setEdit(true);
    setSelectFaq(faqList[idx]);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      faqList?.map((row: any, idx: number) => {
        setCheckedButtons(current => [...current, row.idx]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  useEffect(() => {
    if (selectFaq !== null) setOpenDrawup(true);
  }, [selectFaq]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', m: '2rem 0' }}>
        <Button
          variant='contained'
          onClick={() => {
            setEdit(false);
            setOpenDrawup(true);
          }}
        >
          <AddIcon />
          작성하기
        </Button>
      </Box>
      <Paper sx={{ m: '1rem 0', p: '1.5rem' }}>
        <TableTitle title='자주 묻는 질문' count={totalRecords} />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  onChange={e => {
                    handleSelectAllClick(e);
                  }}
                  checked={checkedAll}
                />
              </TableCell>
              {columns.map((column, idx) => (
                <TableCell key={idx.toString()}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {faqList?.map((faq: any, idx: number) => (
              <TableRow key={idx.toString()}>
                <TableCell padding='checkbox'>
                  <Checkbox
                    id={faq.idx}
                    onChange={e => {
                      changeHandler(e.currentTarget.checked, faq.idx);
                    }}
                    checked={checkedButtons.includes(faq.idx)}
                  />
                </TableCell>
                <TableCell
                  sx={{ cursor: 'pointer' }}
                  onClick={() => clickFaq(idx)}
                >
                  {faq.title}
                </TableCell>
                <TableCell onClick={() => clickFaq(idx)}>
                  {faq.creator}
                </TableCell>
                <TableCell onClick={() => clickFaq(idx)}>
                  {faq.createDate}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={faqList && Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant='outlined'
          color='info'
          onClick={() => setOpenAlert(true)}
        >
          삭제
        </Button>
      </Box>
      <FAQDrawup
        open={openDrawup}
        onClose={() => {
          setOpenDrawup(false);
          setSelectFaq(null);
        }}
        onConfirm={() => {
          setOpenDrawup(false);
          setSelectFaq(null);
          dataportalFaqRefetch();
        }}
        edit={edit}
        isEdit={() => setEdit(!edit)}
        faq={selectFaq}
      />
      <SwAlert
        contents='선택하신 자주 묻는 질문을 삭제하시겠습니까?'
        open={openAlert}
        onClose={() => {
          setOpenAlert(false);
          setSelectFaq(null);
        }}
        onConfirm={() => deleteFaq()}
        confirm={1}
      />
    </div>
  );
};
