import { Tab, Tabs, Box, styled } from '@mui/material';
import React, { SyntheticEvent, useEffect, useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { TabBox } from '../../styles/Styles';
import { useResize } from '../../../hooks/resize';

interface Props {}

export const LocalstoreManagement: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(1);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('create');
        break;
      case 2:
        setValue(2);
        navigate('list');
        break;
      default:
        setValue(0);
        navigate('/localstore');
    }
  };
  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/localstore/create') setValue(1);
    else if (path === '/localstore/list') setValue(2);
    else setValue(3);
  }, [window.location.pathname]);
  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>질의응답 관리</title>
      </Helmet>
      <TabBox>
        <Tabs value={value} onChange={handleChange}>
          <Tab label='가게 생성' value={1} />
          <Tab label='가게 목록' value={2} />
        </Tabs>
      </TabBox>
      <Outlet />
    </div>
  );
};
