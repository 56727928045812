import {
  Box,
  Button,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { v4 as uuidv4 } from 'uuid';

import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { SwSelectGeohashGroup } from '../../commonComponent/SwSelectGeohashGroup';
import { getRegionCategory, generateUuidId } from '../../../common/helper';
import { RegionFilterMap } from './RegionFilterMap';
import { registerRegionFilter } from '../../../common/api/ApiGeohash';
import { IRegionFilter } from '../../../models/regionfilter';

import {
  AddGroupTextField,
  CreatePaper,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';

const WhiteTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    backgroundColor: '#fcfcfb',
  },
  '& .MuiOutlinedInput-root': {
    paddingRight: 0,
  },
});

interface Props {}

export const RegionalFilterCreate: React.FC<Props> = () => {
  const [title, setTitle] = useState<string>('');
  const [regionLimit, setRegionLimit] = useState<string[]>([]);
  const [locationLimit, setLocationLimit] = useState<string[]>([]);
  const [geohashGroupLimit, setGeohashGroupLimit] = useState<any>([]);
  const [geohashData, setGeohashData] = useState<any>([]);
  const [geohashList, setGeohashList] = useState<any>([]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [regionCategory, setRegionCategory] = useState<string[]>(
    getRegionCategory()
  );
  const [selectRegion, setSelectRegion] = useState<string>('서울특별시');

  const { mutate: registerRegionFilterMutation, isError: isRegionMutateError } =
    useMutation(registerRegionFilter, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('위치필터가 저장되었습니다.');
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '위치필터를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const deleteSomething = (index: number, from: string) => {
    const deleted = regionLimit[index];
    setRegionLimit([]);

    const newLocation = locationLimit.filter(
      (_val: any, i: number) => _val !== deleted
    );
    if (newLocation.length !== locationLimit.length)
      setLocationLimit([...newLocation]);

    const newGeohash = geohashGroupLimit.filter(
      (_val: any, i: number) => _val.name !== deleted
    );
    if (newGeohash.length !== geohashGroupLimit.length)
      setGeohashGroupLimit([...newGeohash]);
  };

  const deleteAllRegion = () => {
    setRegionLimit([]);
    setLocationLimit([]);
    setGeohashGroupLimit([]);
  };

  const registerRegion = () => {
    const newGeohash = geohashGroupLimit.map((_val: any, i: number) => _val.id);
    const newUuid = generateUuidId();
    const newData = {
      geoHashId: newUuid,
      name: title,
      geoHashGroup: JSON.stringify(geohashList),
      creator: '',
      category: selectRegion,
    };
    console.log('register region filter :', newData);

    var isValid = 0;
    if (isValid === 0 && title.length === 0) {
      isValid = 1;
    }
    if (isValid === 0 && geohashList.length === 0) {
      isValid = 2;
    }
    if (isValid === 0 && selectRegion === '선택') {
      isValid = 3;
    }

    if (isValid === 0) {
      registerRegionFilterMutation(newData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '지역필터 이름을 입력해 주세요';
          break;
        case 2:
          message = '지역을 추가해주세요.';
          break;
        case 3:
          message = '지역 카테고리를 선택해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenDialog(true);
      setDialogMessage(message);
    }
  };

  useEffect(() => {
    setRegionLimit([]);
    const newGeohash = geohashGroupLimit.map(
      (_val: any, i: number) => _val.name
    );
    setRegionLimit([...locationLimit, ...newGeohash]);
  }, [locationLimit]);

  useEffect(() => {
    setRegionLimit([]);
    const newGeohash = geohashGroupLimit.map(
      (_val: any, i: number) => _val.name
    );
    const newGeohashData = geohashGroupLimit.map((_val: any, i: number) =>
      JSON.parse(_val.group)
    );
    setRegionLimit([...locationLimit, ...newGeohash]);
    setGeohashData(newGeohashData);
  }, [geohashGroupLimit]);

  return (
    <Box sx={{ m: '2rem 0', p: '1rem' }}>
      <Box>
        <Typography sx={{ fontWeight: 500, m: '1rem 0' }}>
          지역 카테고리
        </Typography>
        <WhiteTextField
          select
          sx={{
            width: '10rem',
          }}
          value={selectRegion}
          onChange={evt => setSelectRegion(evt.target.value)}
        >
          <MenuItem key={0} value='선택'>
            선택
          </MenuItem>
          {regionCategory.map((region: string, idx: number) => (
            <MenuItem key={idx + 1} value={region}>
              {region}
            </MenuItem>
          ))}
        </WhiteTextField>
      </Box>
      <Box sx={{ mt: '3rem' }}>
        <Typography sx={{ fontWeight: 500, m: '1rem 0' }}>
          위치 필터 이름
        </Typography>
        <WhiteTextField
          sx={{ width: '28rem' }}
          placeholder='위치 필터 이름을 작성해 주세요.'
          value={title}
          onChange={event => {
            setTitle(event.target.value);
          }}
        />
      </Box>
      <Box sx={{ mt: '3rem' }}>
        <Typography sx={{ fontWeight: 500, m: '1rem 0' }}>
          지역 추가하기
        </Typography>
        <Box>
          <SwSelectLocation
            limitKu={true}
            location={locationLimit}
            setLocation={setLocationLimit}
            showContainer={false}
          />
        </Box>
        <Box className='flex_center' sx={{ m: '1rem 0rem' }}>
          <SwSelectGeohashGroup
            geohashGroup={geohashGroupLimit}
            setGeohashGroup={setGeohashGroupLimit}
            showContainer={false}
            title='위치'
          />
        </Box>
        <Paper className='chip_container'>
          <Typography>위치</Typography>
          {regionLimit.length > 0 &&
            regionLimit.map((location: any, idx: number) => (
              <NoneBorderChip
                label={location}
                variant='outlined'
                onDelete={() => deleteSomething(idx, 'regionLimit')}
                key={idx.toString()}
                sx={{
                  m: '.5em',
                  border: 'none',
                  '& .css-6od3lo-MuiChip-label': {
                    paddingRight: '6px',
                  },
                }}
              />
            ))}
          <div className='flex_end'>
            <Button
              onClick={() => deleteAllRegion()}
              color='info'
              sx={{ fontSize: '.8rem' }}
            >
              <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
              전체삭제
            </Button>
          </div>
        </Paper>
      </Box>
      <Paper>
        <RegionFilterMap
          geohashData={geohashData}
          setGeohashData={setGeohashData}
          setTargetGeohashList={setGeohashList}
          elementId='map-region'
        />
      </Paper>
      <Box className='flex_between' sx={{ p: '1.5rem 0' }}>
        <Button
          variant='outlined'
          color='info'
          onClick={() => {
            window.location.reload();
          }}
        >
          신규 작성
        </Button>
        <Button
          variant='contained'
          color='info'
          onClick={() => registerRegion()}
        >
          저장
        </Button>
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};
