import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Download
 */
export const downloadS3File = (filePath: string) =>
  xapi
    .get(`/admin/v2/apis/download/s3file?filePath=${filePath}`, {
      responseType: 'blob',
    })
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      return url;
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadS3FileDirect = (filePath: string) =>
  xapi
    .get(`/admin/v2/apis/download/s3file?filePath=${filePath}`, {
      responseType: 'blob',
    })
    .then(res => {
      const fileName = filePath.split('/');
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName[fileName.length - 1];
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });
