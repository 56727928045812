import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { DailyStatistics } from './DailyStatistics';
import { SubTabBox } from '../../styles/Styles';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  chosenCommunityState,
  communitySearchState,
  currentYearReportState,
} from '../../../common/atom';
import {
  getCommunityRequestDetail,
  getCommunityUserStepList,
  putCommunityChangeOfficial,
} from '../../../common/api/ApiCommunity';
import {
  KEY_COMMUNITY_REQUEST_DETAIL,
  KEY_COMMUNITY_USER_STEP_LIST,
  KEY_COMMUNITY_YEAR_REPORT_INFO,
} from '../../../common/key';
import { CommunityYearReport } from './CommunityYearReport';
import { getCommunityYearReport } from '../../../common/api/ApiReport';
import {
  downloadS3File,
  downloadS3FileDirect,
} from '../../../common/api/ApiDownload';
import { getReportCurrentYear } from '../../../common/helper';

interface Props {
  switchingView?: () => void;
}

const FirstTab = styled(Tab)({
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
});

const LastTab = styled(Tab)({
  borderTopRightRadius: '10px',
  borderBottomRightRadius: '10px',
});

export const CommunityDetail: React.FC<Props> = ({ switchingView }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentYearReport, setCurrentYearReport] = useRecoilState(
    currentYearReportState
  );
  const [showDailyStatistics, setShowDailyStatistics] =
    useState<boolean>(false);
  const [communityData, setCommunityData] = useState<any>(location.state);
  const [communityId, setCommunityId] = useState<string>('');
  const [reportInfo, setReportInfo] = useState<any>();
  const [value, setValue] = useState(1);
  const [officialType, setOfficialType] = useState<any>(0);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openYearReport, setOpenYearReport] = useState<boolean>(false);

  var checkRequest = false;

  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('basicinfomation', { replace: true, state: communityData });
        break;
      case 2:
        setValue(2);
        navigate('group', { replace: true, state: communityData });
        break;
      case 3:
        setValue(3);
        navigate('members', { replace: true, state: communityData });
        break;
      case 4:
        setValue(4);
        navigate('setting', { replace: true, state: communityData });
        break;
      default:
        setValue(0);
        navigate('/communitymanage/list');
    }
  };

  const { refetch: communityRequestDetailRefetch } = useQuery(
    KEY_COMMUNITY_REQUEST_DETAIL,
    () => {
      if (
        communityId !== undefined &&
        communityId !== null &&
        officialType === 1
      )
        return getCommunityRequestDetail(communityId);
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null && res.creationRequestDetail !== null) {
          setCommunityData(res.creationRequestDetail);
          navigate('/communitymanage/listdetail/basicinfomation', {
            replace: true,
            state: res.creationRequestDetail,
          });
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: getCommunityYearReportInfo } = useQuery(
    KEY_COMMUNITY_YEAR_REPORT_INFO,
    () => {
      if (communityId !== undefined && communityId !== null) {
        const newData: any = {
          communityId: communityId,
          year:
            currentYearReport !== 0
              ? currentYearReport
              : getReportCurrentYear(),
        };
        return getCommunityYearReport(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('report info : ', res);
        if (res !== null && res.report !== undefined && res.report !== null)
          setReportInfo(res.report);
        else setReportInfo(null);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const getDownloadReport = async (path: string) => {
    try {
      const dataUrl = await downloadS3FileDirect(path);
    } catch (error) {
      console.error('Error downloading or displaying image:', error);
    }
  };

  const showCommunityReport = () => {
    setOpenYearReport(true);
  };

  const { mutate: putCommunityChangeOfficialMutate, isError: isMutateError } =
    useMutation(putCommunityChangeOfficial, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('커뮤니티 공식 변환이 되었습니다.');
        setOfficialType(1);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '커뮤니티 공식 변환이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });
  //
  const getCommunityUserStep = () => {
    checkRequest = true;
    // communityUserStepRefetch();
  };

  const changeCommunityOfficial = (type: any) => {
    const newData = {
      type: 'official',
      value: type,
      communityIds: [communityData.communityId],
    };
    console.log('location.pathname :', location.pathname);
    putCommunityChangeOfficialMutate(newData);
  };

  useEffect(() => {
    if (officialType === 1) {
      communityRequestDetailRefetch();
    }
  }, [officialType]);

  useEffect(() => {
    if (location.state) {
      setCommunityData(location.state);
      setCommunityId(communityData.communityId);
      setOfficialType(communityData.official);
      setReportInfo(null);
      getCommunityYearReportInfo();
    }
  }, [location.state]);

  return (
    <>
      <Box sx={{ m: '1.5rem 0' }}>
        <Button
          onClick={() => {
            navigate('/communitymanage/list', {
              replace: true,
              state: { comeBack: 1 },
            });
          }}
          sx={{ fontSize: '.8rem', mb: '1rem', pl: 0 }}
          color='info'
        >
          <ArrowBackIcon sx={{ mr: '.2rem', fontSize: '1rem' }} />
          커뮤니티 목록
        </Button>
        <SubTabBox>
          <Tabs value={value} onChange={tabChange}>
            <FirstTab label='기본 정보' value={1} />
            <Tab label='그룹' value={2} />
            <Tab label='멤버' value={3} />
            <LastTab label='기타 설정' value={4} />
          </Tabs>
        </SubTabBox>
        <Box className='flex_end' sx={{ mt: '-2.5rem' }}>
          {officialType === 0 && location.pathname.includes('basicinfomation') && (
            <Button
              size='small'
              variant='outlined'
              sx={{ mr: '1.0rem' }}
              onClick={() => changeCommunityOfficial(1)}
            >
              공식 변환
            </Button>
          )}
          {reportInfo !== undefined &&
          reportInfo !== null &&
          reportInfo.status !== 3 ? (
            <Typography className='title' sx={{ mr: '1rem' }}>
              {reportInfo.status === 0
                ? '연간보고서 이력없음'
                : reportInfo.status === 1
                ? '연간보고서 생성준비'
                : '연간보고서 생성중'}
            </Typography>
          ) : reportInfo !== undefined &&
            reportInfo !== null &&
            reportInfo.status === 3 ? (
            <Button
              size='small'
              variant='outlined'
              onClick={() => getDownloadReport(reportInfo.path)}
              sx={{ mr: '1rem' }}
            >
              연간보고서 다운로드
            </Button>
          ) : (
            ''
          )}
          <Button
            size='small'
            variant='outlined'
            onClick={() => showCommunityReport()}
            sx={{ mr: '1rem' }}
          >
            연간보고서 생성요청
          </Button>
          <Button
            size='small'
            variant='outlined'
            onClick={() =>
              navigate('/communitymanage/dailystatistics', {
                replace: true,
                state: communityData,
              })
            }
          >
            일자별 통계
          </Button>
          {/* <Button
            sx={{ ml: '1.0rem' }}
            size='small'
            variant='outlined'
            onClick={() => {
              getCommunityUserStep();
            }}
          >
            분기별 걸음정보
          </Button> */}
        </Box>
        <Outlet />
      </Box>
      <CommunityYearReport
        communityId={communityId}
        open={openYearReport}
        onClose={() => setOpenYearReport(false)}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
