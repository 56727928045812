import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { KEY_DATAPORTAL_ACCOUNT_LIST } from '../../common/key';
import { getDataPortalAccountList } from '../../common/api/ApiDataPortal';
import { SwSearchDataPortal } from './SwSearchDataPortal';

interface Props {
  findDataportal: string;
  setFindDataportal: (value: SetStateAction<string>) => void;
  connectDataportal: any;
  setConnectDataportal: (value: SetStateAction<any>) => void;
  setTargetInfo?: Dispatch<React.SetStateAction<any>>;
  isDetail?: boolean;
  filterName?: boolean;
}

export const DataportalConnect: React.FC<Props> = ({
  findDataportal,
  setFindDataportal,
  connectDataportal,
  setConnectDataportal,
  setTargetInfo,
  isDetail = false,
  filterName = false,
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [portalId, setPortalId] = useState<string>('');
  const [title, setTitle] = useState<string>('');

  const changeValue = (
    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => setFindDataportal(evt.target.value);

  const connectingDataportal = () => {
    setConnectDataportal(findDataportal);
    // setFindDataportal('');
    setSearchKey('all');
    setSearchWord(findDataportal);
    setOpenSearchResult(true);
  };
  const disconnectingDataportal = () => {
    setPortalId('');
    setConnectDataportal('');
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      connectingDataportal();
    }
  };

  useEffect(() => {
    console.log('connectDataportal:', connectDataportal);
    connectDataportal?.portalId === undefined
      ? setPortalId(connectDataportal)
      : setPortalId(connectDataportal?.portalId);
  }, [connectDataportal]);

  useEffect(() => {
    console.log('DataportalConnect searchResult:', searchResult);
    if (searchResult !== null && searchResult !== undefined) {
      setConnectDataportal(searchResult);
      setPortalId(searchResult.portalId);
      isDetail
        ? setTargetInfo && setTargetInfo(searchResult)
        : setTargetInfo && setTargetInfo('');
    } else {
      setPortalId('');
    }
    return () => {};
  }, [searchResult]);

  return (
    <>
      <Box>
        <Typography className='title'>데이터 포탈 연결</Typography>
        <TextField
          value={findDataportal}
          onChange={changeValue}
          onKeyDown={handleKeyDown}
          sx={{ width: '26rem', mr: '1em' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={connectingDataportal}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          sx={{ width: '15rem' }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton sx={{ pr: 0 }} onClick={disconnectingDataportal}>
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={portalId}
        />
        {/* <Divider /> */}
      </Box>
      <SwSearchDataPortal
        title={title !== '' ? title : '포털아이디'}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
        isDetail={isDetail}
        filterName={filterName}
      />
    </>
  );
};
