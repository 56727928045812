import axios from 'axios';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Paper,
  styled,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  Dispatch,
  DragEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { chosenCommunityState } from '../../../common/atom';
import { newUuidId, SwUuidCreate } from '../SwUuidCreate';
import { DraggablePaper, RowsCell } from '../../styles/Styles';
import { SwSnackbar } from '../views/SwSnackbar';
import { SwAlert } from '../views/SwAlert';
import { xapi } from '../../../hooks/session';
import { putProgramMissionQnaQuestionOrder } from '../../../common/api/ApiProgram';

interface Props {
  open: boolean;
  onClose: () => void;
  questionList: any[];
  setQuestionList: Dispatch<React.SetStateAction<any[]>>;
}

const TopButton = styled(Button)({
  marginRight: '.7rem',
});

const DetailPaper = styled(Paper)({
  width: '100%',
  padding: '2rem 1.5rem .5rem',
  background: '#F9FAFC',
  border: '1px solid #DFE3E9',
});

const GroupDetailDivider = styled(Divider)({
  padding: 0,
  margin: '.5rem 0 1rem',
});

export const SwArrangeQuestion: React.FC<Props> = ({
  open,
  onClose,
  questionList,
  setQuestionList,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectAll, setSelectAll] = useState(false);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const questionItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const {
    mutate: putProgramMissionQnaQuestionOrderMutate,
    isError: isMutateOrderError,
  } = useMutation(putProgramMissionQnaQuestionOrder, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('질의응답 순서를 변경했습니다.');
      onClose();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '질의응답 순서 변경이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const changeProgramMissionQnaQuestionOrder = () => {
    const newList = questionList.map((question: any, idx: number) => {
      const newData = {
        missionId: question.missionId,
        questionId: question.questionId,
        seq: idx,
      };
      return newData;
    });
    const newData = {
      questionList: newList,
    };
    putProgramMissionQnaQuestionOrderMutate(newData);
  };

  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: questionList,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setQuestionList(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    questionItems.forEach(item => item.classList.remove('drag_over'));
    // changeProgramMissionQnaQuestionOrder();
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setQuestionList(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    questionItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    questionItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newPosition = Number(evt.currentTarget.dataset.position);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newPosition : null,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '30rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>질의응답 순서 변경</span>
          <Button onClick={onClose}>
            <CloseIcon />
          </Button>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <Paper
          sx={{
            heigth: '100%',
            overflowY: 'scroll',
            m: '1rem 0',
            p: '1rem',
            display: 'flex',
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box className='flex_between'>
              <Box className='flex_center'>
                <Typography sx={{ ml: '1rem' }}>
                  질의 응답 개수
                  <span style={{ color: '#2581FF' }}>
                    {' '}
                    [{questionList.length}개]
                  </span>
                </Typography>
              </Box>
              {/* <Button variant='outlined' color='info' sx={{ mr: '1.7rem' }}>
              저장
            </Button> */}
            </Box>
            {questionList?.map((question: any, idx: number) => (
              <Box key={idx.toString()} sx={{ display: 'flex' }}>
                <DraggablePaper
                  className='draggable'
                  id={idx.toString()}
                  variant='outlined'
                  onDragStart={dragStart}
                  onDragEnd={dragEnd}
                  onDragOver={dragOver}
                  onDrop={drop}
                  onDragLeave={dragLeave}
                  onDragEnter={dragEnter}
                  draggable
                  data-position={idx}
                  onClick={selectList}
                >
                  <div>
                    <span>{question.title}</span>
                  </div>
                  <DragHandleIcon />
                </DraggablePaper>
              </Box>
            ))}
          </Box>
        </Paper>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      <DialogActions sx={{ p: '1rem' }}>
        <Button variant='outlined' color='info' onClick={onClose}>
          취소
        </Button>
        <Button
          variant='contained'
          color='info'
          sx={{ fontSize: '.87rem' }}
          onClick={changeProgramMissionQnaQuestionOrder}
        >
          저장
        </Button>
      </DialogActions>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Dialog>
  );
};
