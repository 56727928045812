import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import React, {
  ChangeEvent,
  useState,
  useEffect,
  useRef,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { DataportalConnect } from '../../commonComponent/DataportalConnect';
import { newUuidId } from '../../commonComponent/SwUuidCreate';
import {
  getPartnerType,
  getPartnerTypeItems,
  phoneNumberHyphen,
  timestamp2Localestring,
  validCheck,
} from '../../../common/helper';
import {
  IDataPortalRequest,
  IDataPortal,
  IDataPortalQnA,
} from '../../../models/dataportal';
import {
  getDataPortalAccountAmdList,
  postDataPortalAccountRegister,
  deleteDataPortalAccount,
  getDataPortalAccountInfo,
  getDataPortalAccountExist,
} from '../../../common/api/ApiDataPortal';
import {
  KEY_ACCOUNT_LIST,
  KEY_DATAPORTAL_ACCOUNT_EXIST,
  KEY_DATAPORTAL_ACCOUNT_INFO,
  KEY_WORD_EMAIL,
  KEY_WORD_PHONE,
  KEY_WORD_PREFIX_COMPANY,
} from '../../../common/key';
import { getAccountList } from '../../../common/api/ApiAccount';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSelectPhoneno } from '../../commonComponent/SwSelectPhoneno';
import { RESULT_OK } from '../../../common/resultCode';

interface Props {
  title: string;
  contractInfo: any;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
}

const TitleTypography = styled(Typography)({
  fontWeight: 500,
  margin: '0 .5rem .7rem 0',
});

const RootPaper = styled(Paper)({
  margin: '2.8rem',
  padding: '1rem',
  borderRadius: '12px',
});

const ScrollPaper = styled(Paper)({
  maxHeight: '10rem',
  overflowY: 'scroll',
  padding: '.5rem',
  width: '44rem ',
});

export const CreatePartner: React.FC<Props> = ({
  title,
  contractInfo,
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
}) => {
  const [partnerId, setPartnerId] = useState<string>('');
  const [view, setView] = useState<boolean>(false);
  const [portalId, setPortalId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [manager1, setManager1] = useState<string>('');
  const [phoneno1, setPhoneno1] = useState<any>(['', '', '']);
  const [email1, setEmail1] = useState<string>('');
  const [manager2, setManager2] = useState<string>('');
  const [phoneno2, setPhoneno2] = useState<any>(['', '', '']);
  const [email2, setEmail2] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [role, setRole] = useState<number>(7);
  const [operator, setOperator] = useState<string>('0');
  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<any>();
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [location, setLocation] = useState<string>('');
  const [locationAddress, setLocationAddress] = useState<any>('');
  const [partnerType, setPartnerType] = useState(0);
  const [address, setAddress] = useState<any>('');
  const [targetInfo, setTargetInfo] = useState<any>();
  const [region, setRegion] = useState<any[]>([]);
  const [empList, setEmpList] = useState<any>([]);
  const [businessNumber, setBusinessNumber] = useState<string>('');
  const [useDates, setUseDates] = useState<any[]>([null, null]);
  const [isDuplicated, setIsDuplicated] = useState<number>(-1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [inputError1, setInputErro1] = useState<string>('');
  const [inputError2, setInputError2] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: accountRefetch,
  } = useQuery(
    KEY_ACCOUNT_LIST,
    () => {
      if (open) return getAccountList(0, 0, '');
      return null;
    },
    {
      onSuccess: (res: any) => {
        console.log('getAccountList:');
        if (res !== null) {
          setEmpList(res.account);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const { refetch: accountExistRefetch } = useQuery(
    KEY_DATAPORTAL_ACCOUNT_EXIST,
    () => {
      if (open && portalId !== '') return getDataPortalAccountExist(portalId);
      return null;
    },
    {
      onSuccess: (res: any) => {
        if (res !== null) {
          console.log('accountExistRefetch res :', res);
          setIsDuplicated(res.resultCode === RESULT_OK ? 0 : 1);
          setOpenSnakerDialog(true);
          setDialogMessage(
            res.resultCode === RESULT_OK
              ? '등록 가능한 아이디입니다.'
              : '중복 아이디입니다.'
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const copyId = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.log('er>', error);
    }
  };
  const getCustomerUuid = () => {
    const newUuid = newUuidId();
    setPartnerId(newUuid);
  };

  const { mutate: registerDataPortalAccountMutation, isError: isMutateError } =
    useMutation(postDataPortalAccountRegister, {
      onSuccess: res => {
        console.log('res : ', res);
        setOpenSnakerDialog(true);
        setDialogMessage(
          edit ? '고객정보를 수정했습니다.' : '고객이 추가되었습니다.'
        );
        onConfirm();
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          edit
            ? '고객정보를 수정하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
            : '고객을 추가하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: deleteDataPortalAccountMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteDataPortalAccount, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('포털계정이 삭제되었습니다.');
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '포털계정을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const registerDataPortalAccount = () => {
    const newKeyList = JSON.stringify([{ name: name, key: name }]);
    const newLtLng = resultLatLng.split(' ');
    const newLa = newLtLng[0] ? Number(newLtLng[0]) : 0;
    const newLo = newLtLng[1] ? Number(newLtLng[1]) : 0;
    const newPhoneno2 =
      phoneno2[1] !== undefined &&
      phoneno2[1] !== '' &&
      phoneno2[2] !== undefined &&
      phoneno2[2] !== ''
        ? `${phoneno2[0]}${phoneno2[1]}${phoneno2[2]}`
        : '';

    let newConnectedIds: any[] = [];
    if (contractInfo !== undefined && contractInfo.isManager === 1) {
      newConnectedIds = JSON.parse(contractInfo.connectedIds);
    }
    const newData: IDataPortal = {
      regType: 'company',
      portalId: portalId,
      password: password,
      conType: getPartnerType(partnerType) !== '기타' ? partnerType : 99,
      walkWayLocalId: '',
      amdCode: '',
      constime: useDates[0] === null ? 0 : useDates[0].getTime() / 1000,
      activate: useDates[1] === null ? 0 : useDates[1].getTime() / 1000,
      key: name,
      keyList: newKeyList,
      x: newLa,
      y: newLo,
      name1: manager1,
      phoneno1: `${phoneno1[0]}${phoneno1[1]}${phoneno1[2]}`,
      email1: email1,
      name2: manager2,
      phoneno2: newPhoneno2,
      email2: email2,
      operator: operator,
      address: location.length > 0 ? location : '',
      locationAddress:
        locationAddress.length > 0
          ? `${locationAddress[0]} ${locationAddress[1].replace(' ', '')}`
          : '',
      portalCode: partnerId,
      businessNumber: businessNumber,
      memo: memo,
      role: edit ? role : 7, // 7 : all role
      isManager: contractInfo?.isManager || 0,
      connectedIds: newConnectedIds,
    };
    console.log('registerDataPortalAccountMutation : ', newData);
    registerDataPortalAccountMutation(newData);
  };

  const checkValid = () => {
    var val = 0;
    const newAddress = location.length > 0 ? location : '';

    if (val === 0 && !edit && isDuplicated === -1) val = 10;
    if (val === 0 && !edit && isDuplicated === 1) val = 11;
    if (val === 0 && (portalId === null || portalId.length === 0)) val = 1;
    if (val === 0 && (password === null || password.length === 0)) val = 2;
    if (val === 0 && (name === null || name.length === 0)) val = 3;
    if (val === 0 && (manager1 === null || manager1.length === 0)) val = 4;
    if (val === 0 && (newAddress === undefined || newAddress.length === 0))
      val = 5;
    if (
      val === 0 &&
      (operator === null || operator.length === 0 || operator === '0')
    )
      val = 6;
    if (
      val === 0 &&
      (email1 === null ||
        email1.length === 0 ||
        validCheck(KEY_WORD_EMAIL, email1) === false)
    )
      val = 7;
    if (
      val === 0 &&
      email2 &&
      email2.length > 0 &&
      validCheck(KEY_WORD_EMAIL, email2) === false
    )
      val = 7;
    if (val === 0 && (useDates[0] === null || useDates[1] === null)) val = 8;
    if (
      val === 0 &&
      (phoneno1[1] === undefined || phoneno1[1] === null || phoneno1[1] === '')
    )
      val = 9;
    if (
      val === 0 &&
      (phoneno1[2] === undefined || phoneno1[2] === null || phoneno1[2] === '')
    )
      val = 9;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '데이터 포탈 아이디를 입력해 주세요.';
          break;
        case 2:
          message = '데이터 포탈 비밀번호를 입력해 주세요.';
          break;
        case 3:
          message = '고객명을 입력해 주세요.';
          break;
        case 4:
          message = '담당자 이름을 입력해주세요.';
          break;
        case 5:
          message = '주소를 입력해주세요.';
          break;
        case 6:
          message = '워크온 담당자를 선택해 주세요.';
          break;
        case 7:
          message = '이메일 입력이 없거나 이메일 형식 오류입니다.';
          break;
        case 8:
          message = '포탈 이용시간을 입력해주세요.';
          break;
        case 9:
          message = '담당자1 연락처를 입력해주세요.';
          break;
        case 10:
          message = '사용할 포탈 아이디를 중복체크 해주세요.';
          break;
        case 11:
          message =
            '중복된 포털 아이디입니다. 포털 아이디 입력 후 중복 중복체크를 해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenDialog(true);
    }

    return val;
  };

  // const deleteAccount = () => {
  //   if (portalId.length === 0) {
  //     setDialogMessage('삭제할 포탈 계정을 선택해 주세요');
  //     setOpenDialog(true);
  //     return;
  //   }
  //   deleteDataPortalAccountMutation(portalId);
  // };

  const checkDataPortalAccount = (type: number) => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }
    registerDataPortalAccount();

    type === 0 ? onConfirm() : isEdit();
  };

  const resetData = () => {
    setPortalId('');
    setPassword('');
    setName('');
    setManager1('');
    setPhoneno1('');
    setEmail1('');
    setManager2('');
    setPhoneno2('');
    setEmail2('');
    setMemo('');
    setOperator('0');
    setAddress('');
    setLocation('');
    setResultLatLng('');
    setPartnerType(0);
    setBusinessNumber('');
    setUseDates([null, null]);
    getCustomerUuid();
    setFindDataportal('');
    setConnectDataportal('');
    setIsDuplicated(-1);
    setRole(7);
  };

  const setData = () => {
    setIsDuplicated(0);
    setPortalId(contractInfo.id);
    setPassword(contractInfo.password);
    setBusinessNumber(contractInfo.businessNumber);
    setName(contractInfo.name);
    setManager1(contractInfo.userName1);
    setPhoneno1(phoneNumberHyphen(contractInfo.userPhoneno1).split('-'));
    setEmail1(contractInfo.userEmail1);
    setManager2(contractInfo.userName2);
    setPhoneno2(phoneNumberHyphen(contractInfo.userPhoneno2).split('-'));
    setEmail2(contractInfo.userEmail2);
    setMemo(contractInfo.memo);
    setOperator(contractInfo.operator);
    setAddress(contractInfo.address !== null ? contractInfo.address : '');
    setLocation(contractInfo.address !== null ? contractInfo.address : '');
    const newType = getPartnerTypeItems()
      .map((item: any, idx: number) => {
        if (item === getPartnerType(contractInfo.contype)) return idx + 1;
        return 0;
      })
      .filter((item: any) => item !== 0);
    console.log('newType: ', newType);
    setPartnerType(newType.length > 0 ? newType[0] : 0);
    setPartnerId(contractInfo.portalCode);

    const newUseDate = [
      new Date(timestamp2Localestring(contractInfo.constime, 1000)),
      new Date(timestamp2Localestring(contractInfo.activatetime, 1000)),
    ];
    setUseDates(newUseDate);
    setRegion(contractInfo.address === null ? [] : [contractInfo.address]);
    setRole(contractInfo.role);
  };

  const validCheckInput = (
    type: string,
    value: string,
    setInputError: Dispatch<React.SetStateAction<any>>
  ) => {
    if (type === KEY_WORD_PHONE && validCheck(KEY_WORD_PHONE, value)) {
      setInputError('');
    } else if (type === KEY_WORD_PHONE) {
      value === '' ? setInputError('') : setInputError(KEY_WORD_PHONE);
    }
    return value;
  };

  const checkDuplicated = () => {
    if (portalId.length > 0) {
      accountExistRefetch();
    } else {
      setOpenSnakerDialog(true);
      setDialogMessage('생성할 포탈 아이디를 입력해 주세요');
    }
  };

  useEffect(() => {
    if (open) {
      console.log('connectDataportal: ', connectDataportal);
      if (connectDataportal) {
        setPortalId(connectDataportal.id);
      } else setPortalId(contractInfo?.id);
    }
  }, [connectDataportal]);

  useEffect(() => {
    if (region.length > 0) {
      const selectRegion = region;
      console.log('region: ', selectRegion);
    }
  }, [region]);

  useEffect(() => {
    console.log('location : ', location, ', locationAddress:', locationAddress);
  }, [location, locationAddress]);

  useEffect(() => {
    console.log('targetInfo: ', targetInfo);
    if (targetInfo !== undefined && targetInfo !== null) {
      setPortalId(targetInfo.portalId);
      setPassword(targetInfo.password === null ? '' : targetInfo.password);
      const newUseDate = [
        new Date(timestamp2Localestring(targetInfo.constime, 1000)),
        new Date(timestamp2Localestring(targetInfo.activate, 1000)),
      ];
      setUseDates(newUseDate);
      setManager1(targetInfo.userName1);
      setPhoneno1(phoneNumberHyphen(targetInfo.userPhoneno1).split('-'));
      setEmail1(targetInfo.userEmail1);
      setIsDuplicated(0);
      setPartnerId(targetInfo.portalCode);
    }
  }, [targetInfo]);

  useEffect(() => {
    if (open) {
      if (!edit) resetData();
      else if (contractInfo) setData();
      accountRefetch();
    } else {
      resetData();
    }
  }, [open]);

  useEffect(() => {
    console.log('CreatePartner contractInfo:', contractInfo);
    if (contractInfo && edit) {
      setData();
    } else {
      resetData();
    }
  }, [contractInfo]);
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '66vh',
            minWidth: '70vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Typography className='title'>{title}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent sx={{ p: '1.5rem 2.6em' }}>
          {/* <div className='flex_center' style={{ height: '' }}>
            <Box sx={{ width: '49%' }}>1</Box>
            <Divider flexItem orientation='vertical' sx={{ height: '' }} />
            <Box sx={{ width: '49%', ml: '2%' }}>2</Box>
          </div> */}
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            divider={<Divider orientation='vertical' flexItem />}
            spacing={2}
            sx={{ minHeight: '55vh' }}
          >
            <Box sx={{ width: '49%' }}>
              <Box>
                <Box sx={{ p: '.5rem .15rem 0' }} className='flex_center'>
                  <Typography className='title'>고객 아이디</Typography>
                  <IconButton onClick={getCustomerUuid} sx={{ p: 0 }}>
                    <CachedIcon
                      sx={{ fontSize: '1.2rem', m: '-.2rem 0 0 .4rem' }}
                    />
                  </IconButton>
                </Box>
                <Box className='flex_center'>
                  <TextField
                    sx={{ width: '88%', mb: 0 }}
                    value={`${KEY_WORD_PREFIX_COMPANY}${partnerId}`}
                    InputProps={{ readOnly: true }}
                  >
                    {`${KEY_WORD_PREFIX_COMPANY}${partnerId}`}
                  </TextField>
                  <IconButton
                    onClick={() => copyId(portalId)}
                    sx={{ p: 0, m: '.5rem' }}
                  >
                    <ContentCopyOutlinedIcon sx={{ width: '1rem' }} />
                  </IconButton>
                </Box>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>고객 분류</Typography>
                <TextField
                  select
                  sx={{ width: '60%' }}
                  value={partnerType}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setPartnerType(Number(event.target.value));
                  }}
                >
                  <MenuItem key={0} value={0}>
                    선택
                  </MenuItem>
                  {getPartnerTypeItems().map((item: any, idx: number) => (
                    <MenuItem key={idx + 1} value={idx + 1}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider />
              </Box>
              <Box>
                <Typography sx={{ fontWeight: 500, m: '1rem 0' }}>
                  고객주소
                </Typography>
                <SwFindLatLngbyKakaomap
                  setLatLng={setResultLatLng}
                  setAddress={setLocationAddress}
                  location={location}
                  setLocation={setLocation}
                  value={resultLatLng}
                />
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>고객명</Typography>
                <TextField
                  sx={{ width: '88%' }}
                  value={name}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setName(evt.target.value)}
                />
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>사업자 등록증</Typography>
                <TextField
                  sx={{ width: '88%' }}
                  value={businessNumber}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setBusinessNumber(evt.target.value)}
                />
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>워크온 담당자</Typography>
                <TextField
                  sx={{ width: '18rem' }}
                  select
                  value={operator}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setOperator(evt.target.value)}
                >
                  <MenuItem key={0} value='0'>
                    선택
                  </MenuItem>
                  {empList?.length > 0 &&
                    empList.map((emp: any, idx: number) => (
                      <MenuItem key={emp.id} value={emp.id}>
                        {emp.name}
                      </MenuItem>
                    ))}
                </TextField>
                <Divider />
              </Box>
            </Box>
            <Box sx={{ width: '49%', ml: '2%' }}>
              <Box>
                <Typography className='title'>데이터 포탈 아이디</Typography>
                <TextField
                  sx={{ width: '66%' }}
                  placeholder='생성할 포탈의 아이디를 입력해 주세요.'
                  value={portalId}
                  disabled={edit}
                  autoComplete='off'
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setIsDuplicated(-1);
                    setPortalId(evt.target.value);
                  }}
                />
                <Button
                  sx={{ ml: '1rem' }}
                  variant='contained'
                  color='info'
                  disabled={edit}
                  onClick={() => checkDuplicated()}
                >
                  중복체크
                </Button>
                <Typography className='title'>데이터 포탈 비밀번호</Typography>
                <TextField
                  sx={{ width: '66%' }}
                  type={view ? 'text' : 'password'}
                  placeholder='생성할 포탈의 비밀번호를 입력해 주세요.'
                  value={password}
                  autoComplete='off'
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setPassword(evt.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton onClick={() => setView(!view)}>
                          {view ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Box>
                  <Typography className='title'>포털이용 일시</Typography>
                  <SwDateRangePicker
                    rangeDate={useDates}
                    setRangeDate={setUseDates}
                  />
                </Box>
                <Typography sx={{ mt: '.5rem' }} className='title'>
                  데이터 포탈 연결(새롭게 포탈을 생성하지 않는 경우 기존 포탈을
                  연결해 주세요.)
                </Typography>
                <DataportalConnect
                  findDataportal={findDataportal}
                  setFindDataportal={setFindDataportal}
                  connectDataportal={connectDataportal}
                  setConnectDataportal={setConnectDataportal}
                  isDetail={true}
                  filterName={!(portalId === null || portalId === '')}
                  setTargetInfo={setTargetInfo}
                />
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>담당자 1</Typography>
                <TextField
                  sx={{ width: '88%' }}
                  value={manager1}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setManager1(evt.target.value)}
                />
              </Box>
              <Box>
                <Typography className='title'>담당자 1 연락처</Typography>
                <SwSelectPhoneno phoneno={phoneno1} setPhoneno={setPhoneno1} />
                {inputError1 === KEY_WORD_PHONE && (
                  <Typography sx={{ color: 'red' }}>
                    핸드폰 형식이 아닙니다.
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography className='title'>담당자 1 이메일</Typography>
                <TextField
                  sx={{ width: '66%' }}
                  value={email1}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setEmail1(evt.target.value);
                  }}
                />
                {inputError1 === KEY_WORD_EMAIL && (
                  <Typography sx={{ color: 'red' }}>
                    이메일 형식이 아닙니다.
                  </Typography>
                )}
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>담당자 2</Typography>
                <TextField
                  sx={{ width: '88%' }}
                  value={manager2}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setManager2(evt.target.value)}
                />
              </Box>
              <Box>
                <Typography className='title'>담당자 2 연락처</Typography>
                <SwSelectPhoneno phoneno={phoneno2} setPhoneno={setPhoneno2} />
                {inputError2 === KEY_WORD_PHONE && (
                  <Typography sx={{ color: 'red' }}>
                    핸드폰 형식이 아닙니다.
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography className='title'>담당자 2 이메일</Typography>
                <TextField
                  sx={{ width: '66%' }}
                  value={email2}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setEmail2(evt.target.value);
                  }}
                />
                {inputError2 === KEY_WORD_EMAIL && (
                  <Typography sx={{ color: 'red' }}>
                    이메일 형식이 아닙니다.
                  </Typography>
                )}
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>메모</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: 'auto',
                    },
                  }}
                  value={memo}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setMemo(evt.target.value)}
                />
                <Divider />
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          {!edit ? (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                color='info'
                variant='contained'
                onClick={() => checkDataPortalAccount(1)}
              >
                저장
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                color='info'
                variant='contained'
                onClick={() => checkDataPortalAccount(0)}
              >
                수정
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
