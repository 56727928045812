import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ChangeEvent, useEffect, useState, Dispatch, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../../hooks/api';
import { getDataPortalAccountAmdList } from '../../../common/api/ApiDataPortal';
import { KEY_DATAPORTAL_ACCOUNT_AMD_LIST } from '../../../common/key';
import { pointPerAchieve } from './SwProgramCommon';
import { SwFileSetting } from '../SwFileSetting';
import { useSession } from '../../../hooks/session';

const GrayTypography = styled(Typography)({
  color: '#858C97',
});

const data = [
  { title: '직장인 대상 고혈압 4주 커리큘럼', date: '2020.02.05 15:08' },
  { title: '60대 이상 고혈압 4주 커리큘럼', date: '2020.02.05 15:08' },
  { title: '고혈압 1기 이상 4주 커리큘럼', date: '2020.02.05 15:08' },
  { title: '직장인 대상 고혈압 4주 커리큘럼', date: '2020.02.05 15:08' },
];

interface Props {
  open: boolean;
  onClose: () => void;
}

export const SwCurriculumLoad: React.FC<Props> = ({ open, onClose }) => {
  const { loginVal } = useSession();
  const titleRef = useRef<any>();
  const [title, setTitle] = useState<string>('');
  const [titleHelperText, setTitleHelperText] =
    useState<string>('20자 이내로 작성해주세요!');
  const [point, setPoint] = useState<number>(10);
  const [inputType, setInputType] = useState<string>('direct');
  const [link, setLink] = useState<string>('');
  const [coverImage, setCoverImage] = useState<string>('');
  const [coverFile, setCoverFile] = useState<any>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idx: number
  ) => {
    console.log('select menu idx :', idx);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteCurriculum = (idx: number) => {
    console.log('deleteCurriculum : ', idx);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '75vh',
          margin: '60rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>커리큘럼 불러오기</span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Box>
          <Typography className='title'>
            불러올 커리큘럼을 선택해주세요.
          </Typography>
          <Divider sx={{ m: '0rem' }} />
          {data.map((row: any, idx: number) => (
            <Box key={`curriculum_load_${idx}`}>
              <Box className='flex_between'>
                <Box>
                  <Box>
                    <Typography className='title'>{row.title}</Typography>
                  </Box>
                  <Box>
                    <Typography className='title'>{`작성일시 : ${row.date}`}</Typography>
                  </Box>
                </Box>
                <Box>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={e => {
                      onClose();
                    }}
                  >
                    불러오기
                  </Button>
                  <Button
                    color='info'
                    onClick={e => {
                      handleClick(e, idx);
                    }}
                  >
                    <MoreVertIcon />
                  </Button>
                  <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={openMenu}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        deleteCurriculum(idx);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteOutlineIcon fontSize='small' />
                      </ListItemIcon>
                      <ListItemText>삭제</ListItemText>
                    </MenuItem>
                  </Menu>
                </Box>
              </Box>
              <Divider sx={{ m: '.5rem', p: 0 }} />
            </Box>
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
