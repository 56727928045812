/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */
import React, { useEffect, useRef, useState } from 'react';

interface IStampTour {
  title?: string;
  id: string;
  name?: string;
  lat: number;
  lng: number;
  radius: number;
}

interface Props {}

export const Test123: React.FC<Props> = () => {
  const center = new window.naver.maps.LatLng(37.511337, 127.012084);
  const [stamps, setStamps] = useState<IStampTour[]>([]);
  const mapRef = useRef<any>();
  const [centerPosition, setCenterPosition] = useState<any>();
  const map = document.getElementById('map');
  let drawingLine: any;
  let drawingCircle: any;
  let drawingFlag = false;
  let centerposition: any;
  let length: any;
  const makeMap = () => {
    const mapOptions = {
      center: center,
      useStyleMap: true,
      zoom: 17,
      minZoom: 17,
      maxZoom: 17,
      scaleControl: false,
      mapDataControl: false,
      logoControlOptions: {
        position: window.naver.maps.Position.LEFT_BOTTOM,
      },
      disableKineticPan: false,
    };
    mapRef.current = new window.naver.maps.Map('map', mapOptions);
  };

  const drawing = () => {
    window.naver.maps.Event.addListener(mapRef.current, 'click', (evt: any) => {
      const newCoord = evt.coord;
      console.log(
        'newcoord>>>>>>>>>>>>>>>>>',
        newCoord,
        drawingFlag,
        drawingLine
      );
      if (!drawingFlag) {
        drawingFlag = true;
        centerposition = evt.coord;
        setCenterPosition(evt.coord);
        if (!drawingLine) {
          drawingLine = new window.naver.maps.Polyline({
            strokeWeight: 5,
            strokeOpacity: 0.6,
            path: [newCoord],
            map: mapRef.current,
          });
          drawingCircle = new window.naver.maps.Circle({
            map: mapRef.current,
            center: centerposition,
            radius: length,
            fillColor: 'crimson',
            fillOpacity: 0.1,
          });
          drawingLine.setMap(mapRef.current);
          drawingCircle.setMap(mapRef.current);
        }
      }
    });
    window.naver.maps.Event.addListener(
      mapRef.current,
      'mousemove',
      (mEvt: any) => {
        const latlng = mEvt.latlng;
        if (drawingFlag) {
          const mousePosition = mEvt.coord;
          const linePath = [centerposition, mousePosition];
          drawingLine.setPath(linePath);
          length = drawingLine.getDistance();
          const info = `<div><span>${Math.round(length)}m</span></div>`;
          if (length > 0) {
            const circleOptions = {
              center: centerposition,
              radius: length,
            };
            drawingCircle.setOptions(circleOptions);
            drawingLine.setMap(mapRef.current);
            drawingCircle.setMap(mapRef.current);
            const infowindow = new window.naver.maps.InfoWindow({
              content: info,
              borderWidth: 0,
              disableAnchor: true,
              backgroundColor: 'transparent',
              pixelOffset: new window.naver.maps.Point(0, -28),
            });
            infowindow.open(mapRef.current, latlng);
          } else {
            drawingLine.setMap(null);
            drawingCircle.setMap(null);
          }
        }
      }
    );
    window.naver.maps.Event.addListener(
      mapRef.current,
      'rightclick',
      (revt: any) => {
        if (drawingFlag) {
          const rClickPosition = revt.coord;
          const latlng = revt.latlng;
          console.log('rclick>>>>>>>>>>>>', revt.coord);
          const polyline = new window.naver.maps.Polyline({
            map: mapRef.current,
            path: [centerposition, rClickPosition],
            strokeWeight: 3,
            strokeColor: '#00a0e9',
            strokeOpacity: 0.1,
            strokeStyle: 'solid',
          });
          const circle = new window.naver.maps.Circle({
            map: mapRef.current,
            center: centerposition,
            radius: polyline.getDistance(),
            strokeWeight: 1,
            strokeColor: '#00a0e9',
            strokeOpacity: 0.1,
            strokeStyle: 'solid',
            fillColor: '#00a0e9',
            fillOpacity: 0.2,
          });
          const circleInfo = `<div><span>${Math.round(
            polyline.getDistance()
          )}m</span></div>`;
          const infowindow = new window.naver.maps.InfoWindow({
            content: circleInfo,
            borderWidth: 0,
            disableAnchor: true,
            backgroundColor: 'transparent',
            pixelOffset: new window.naver.maps.Point(0, -28),
          });
          setStamps([
            ...stamps,
            {
              id: '1',
              lat: centerposition.y,
              lng: centerposition.x,
              radius: Math.round(polyline.getDistance()),
            },
          ]);
          infowindow.open(mapRef.current, latlng);
          circle.setMap(mapRef.current);
          polyline.setMap(mapRef.current);
          drawingCircle.setMap(null);
          drawingLine.setMap(null);
          drawingFlag = false;
          drawingCircle = null;
          drawingLine = null;
          centerposition = null;
          console.log('dd???>', stamps);
        }
      }
    );
  };

  const makeCircle = () => {
    window.naver.maps.Event.once(mapRef.current, 'init', () => {
      const drawingManager = new window.naver.maps.drawing.DrawingManager({
        map: mapRef.current,
        drawingControl: [window.naver.maps.drawing.DrawingMode.ELLIPSE],
        drawingControlOptions: {
          position: window.naver.maps.Position.TOP_CENTER,
          style: window.naver.maps.drawing.DrawingStyle.VERTICAL,
        },
        ellipseOptions: {
          bounds: centerPosition,
          fillColor: '#ff25dc',
          fillOpacity: 0.5,
          strokeWeight: 3,
          strokeColor: '#ff25dc',
        },
      });
      drawingManager.addListener(
        window.naver.maps.drawing.DrawingEvent.SELECT,
        (overlay: any) => {
          console.log(overlay.id, overlay.name);
        }
      );
    });
  };

  useEffect(() => {
    makeMap();
    // makeCircle();
  }, []);

  useEffect(() => {
    drawing();
  }, [mapRef]);

  return (
    <div className='root' style={{ padding: '2rem' }}>
      <div id='map' ref={mapRef} style={{ width: '80vw', height: '80vh' }} />
      <div>11</div>
    </div>
  );
};
