import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { IOperatingMetrics } from '../../models/operating_metrics';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Operation
 */

export const getOperatingMetrics = (data: IOperatingMetrics) =>
  xapi
    .get(
      `/admin/v2/apis/operating-metrics/user-community-challenge?statType=${data.statType}&statSubType=${data.statSubType}&year=${data.year}&month=${data.month}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const downloadOperatingMetrics = (data: IOperatingMetrics) =>
  xapi
    .get(
      `/admin/v2/apis/operating-metrics/user-community-challenge/download?statType=${data.statType}&statSubType=${data.statSubType}&year=${data.year}&month=${data.month}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'operating_metrics.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });
