import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { ContentManageCategoryForm } from './ContentManageCategoryForm';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { ButtonBox, ContentTitle, MarginButton } from './ContentManageStyles';
import { WhiteTextField, SwSwitch } from '../../styles/Styles';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { ContentManageForm } from './ContentManageForm';

interface Props {}

export const ContentManageMagazine: React.FC<Props> = () => {
  const queryClient = useQueryClient();

  return (
    <>
      <ContentManageForm contentManageType='magazine' />
    </>
  );
};
