import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import {
  ITimeline,
  ITimelineCancel,
  ITimelineUpdate,
  ITimelineReqeust,
} from '../../models/timeline';
import { xapi } from '../../hooks/session';
import { throwError } from '../helper';

/*
 * Timeline
 */

export const getTimelineList = (data: ITimelineReqeust) =>
  xapi
    .get(
      `/admin/v2/apis/timeline/${data.targetType}/site/${data.site}?targetId=${data.targetId}&healthItem=${data.healthItem}&startDate=${data.startDate}&endDate=${data.endDate}&page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postTimeline = (data: ITimeline) =>
  xapi
    .post(`/admin/v2/apis/timeline/site/${data.site}`, {
      feedType: data.feedType,
      receiver: {
        type: data.receiver.type,
        id: data.receiver.id,
        name: data.receiver.name,
        healthItem: data.receiver.healthItem,
      },
      content: data.content,
      requestDate: data.requestDate,
      repeat: {
        startDate: data.repeat?.startDate,
        endDate: data.repeat?.endDate,
      },
    })
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const cancelTimeline = (data: ITimelineCancel) =>
  xapi.delete(
    `/admin/v2/apis/timeline/${data.timelineId}/type/${data.type}/site/${data.site}`
  );

export const patchTimeline = (data: ITimelineUpdate) =>
  xapi.put(`/admin/v2/apis/timeline/${data.timelineId}/site/${data.site}`, {
    feedType: data.feedType,
    receiver: {
      type: data.receiver.type,
      id: data.receiver.id,
      name: data.receiver.name,
      healthItem: data.receiver.healthItem,
    },
    content: data.content,
    requestDate: data.requestDate,
  });
