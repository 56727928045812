import {
  Box,
  Button,
  Chip,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  styled,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, Outlet } from 'react-router-dom';
import clsx from 'clsx';
import { Helmet } from 'react-helmet-async';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { useResize } from '../../../hooks/resize';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { DrawingSetting } from './DrawingSetting';
import { Drawing } from './Drawing';
import { GivingReward } from './GivingReward';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';

import {
  IReward,
  IRewardDrawing,
  IRewardDrawingUser,
  DefaultRewardDrawingUser,
} from '../../../models/reward';
import {
  getRewardDrawChallengeList,
  getRewardDrawChallengeDetailList,
  getRewardDrawUserList,
  getRewardDrawChallengeDetailInfo,
  postRewardResetDrawResult,
} from '../../../common/api/ApiReward';
import {
  KEY_REWARD_DRAWING_LIST,
  KEY_REWARD_DRAWING_DETAIL_LIST,
  KEY_REWARD_DRAWING_USER_LIST,
  KEY_REWARD_DRAWING_DETAIL_INFO,
  KEY_ACCOUNT_LIST,
} from '../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import { getAccountList } from '../../../common/api/ApiAccount';
import useLocalStorage from '../../../hooks/storage';
import { useSession } from '../../../hooks/session';

export const REWARD_STATE_NONE = 'none';
export const REWARD_STATE_SETTING = 'setting';
export const REWARD_STATE_DRAWING = 'drawing';
export const REWARD_STATE_SUBMIT = 'submit';

const CreatePaper = styled(Paper)({
  // margin: '2rem',
  padding: '1rem',
  cursor: 'pointer',
});

const requestColumnlists = [
  '챌린지 이름',
  '리워드 이름',
  '수량',
  '응모 인원',
  '포털 아이디',
  '담당자',
  '챌린지 노출 종료일시',
];
const clickColumnlist = ['리워드 이름', '수량', '응모 인원', '발급 쿠폰 수'];

interface Props {}

export const RewardDrawing: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const requestType = 'request';
  const { isMobile } = useResize();
  const { loginVal, logout, session } = useSession();
  const [adminAccountValue, setAdminAccountValue] = useLocalStorage(
    KEY_ACCOUNT_LIST,
    '',
    100000
  );
  const [challengeId, setChallengeId] = useState<string>('');
  const [rewardId, setRewardId] = useState<string>('');
  const [creator, setCreator] = useState<string>('');
  const [rewardCount, setRewardCount] = useState<number>(0);
  const [requestList, setRequestList] = useState<any>([]);
  const [rewardDrawingList, setRewardDrawingList] = useState<any>([]);
  const [rewardDrawingDetailList, setRewardDrawingDetailList] = useState<any>(
    []
  );
  const [rewardDrawUserList, setRewardDrawUserList] =
    useState<IRewardDrawingUser>(DefaultRewardDrawingUser);
  const [rewardConditionList, setRewardConditionList] = useState<any>([]);
  const [checkRequest, setCheckRequest] = useState<boolean>(false);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [selectRequest, setSelectRequest] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('all');
  const [searchWord, setSearchWord] = useState<string>('');
  const [loading, setLoading] = useState<any>(true);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [changeState, setChangeState] = useState<string>(REWARD_STATE_NONE);
  const [selectedRow, setSelectedRow] = useState<any>(-1);
  const [preDrawCount, setPreDrawCount] = useState<any>(0);
  const [redrawDisable, setRedrawDisable] = useState<any>(true);
  const [empList, setEmpList] = useState<any>();
  const [operator, setOperator] = useState<string>('all');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  var searchData: any = {
    operator: operator,
    searchKey: 'all',
    searchWord: '',
  };

  const { refetch: accountRefetch } = useQuery(
    KEY_ACCOUNT_LIST,
    () => {
      if (adminAccountValue === '' || adminAccountValue.length === 0)
        return getAccountList(0, 0, '');
      return adminAccountValue;
    },
    {
      onSuccess: res => {
        if (adminAccountValue === '' || adminAccountValue.length === 0) {
          setEmpList(res.account);
          setAdminAccountValue(res.account);
        } else {
          setEmpList(res.value);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    data,
    isLoading,
    refetch: rewardDrawRefetch,
  } = useQuery(
    KEY_REWARD_DRAWING_LIST,
    () => {
      if (checkRequest) {
        setLoading(true);
        const newData = {
          page: page,
          rowsPerPage: rowsPerPage,
          operator: searchData.operator,
          searchKey: searchData.searchKey,
          searchWord: encodeSearchWord(
            searchData.searchKey,
            searchData.searchWord
          ),
        };
        return getRewardDrawChallengeList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('reward drawing list :', res);
        setLoading(false);
        if (res !== null) {
          const newRewardDrawing =
            res &&
            res.result.rewardDraw &&
            res.result.rewardDraw.map((item: any) => {
              const newDate = timestamp2Localestring(
                item.challengeAttendEtime * 1000
              );
              const newData = [
                item.challengeTitle,
                item.rewardTitle,
                item.rewardCount,
                item.drawUserCount,
                item.challengeCreator,
                item.operatorName,
                newDate,
              ];
              return newData;
            });
          setRequestList(newRewardDrawing);
          setRewardDrawingList(res.result.rewardDraw);
          setRewardDrawUserList(DefaultRewardDrawingUser);
          setPreDrawCount(res.result.rewardPredrawCount);
          setTotalRecords(res.pagination.totalRecordCount);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetchRewardDraw = (
    newPage: number,
    newOperator: string,
    newSearchKey: string,
    newSearchWord: string
  ) => {
    queryClient.prefetchQuery(KEY_REWARD_DRAWING_LIST, () => {
      if (checkRequest) {
        setLoading(true);
        const newData = {
          page: newPage,
          rowsPerPage: rowsPerPage,
          operator: newOperator,
          searchKey: newSearchKey,
          searchWord: encodeSearchWord(newSearchKey, newSearchWord),
        };
        return getRewardDrawChallengeList(newData);
      }
      return null;
    });
  };

  const { refetch: rewardDrawDetailRefetch } = useQuery(
    KEY_REWARD_DRAWING_DETAIL_LIST,
    () => {
      if (checkRequest) {
        setLoading(true);
        if (challengeId.length > 0)
          return getRewardDrawChallengeDetailList(requestType, challengeId);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('reward detail drawing list :', res);
        setLoading(false);
        if (res !== null) {
          const newRewardDrawing =
            res &&
            res.rewardDrawDetail &&
            res.rewardDrawDetail.map((item: any) => {
              const newData = [
                item.rewardTitle,
                item.rewardCount,
                item.drawUserCount,
                item.attendUserCount,
              ];
              return newData;
            });
          setChosenRequest(newRewardDrawing);
          setRewardDrawingDetailList(res.rewardDrawDetail);
          setRewardDrawUserList(DefaultRewardDrawingUser);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const { refetch: rewardDrawDetailInfoRefetch } = useQuery(
    KEY_REWARD_DRAWING_DETAIL_INFO,
    () => {
      if (checkRequest) {
        setLoading(true);
        if (challengeId.length > 0)
          return getRewardDrawChallengeDetailInfo(
            requestType,
            challengeId,
            rewardId
          );
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('reward detail drawing info :', res);
        setLoading(false);
        if (res !== null) {
          const newRewardDrawing =
            res &&
            res.rewardDrawDetail &&
            res.rewardDrawDetail.map((item: any) => {
              const newData = [
                item.rewardTitle,
                item.rewardCount,
                item.drawUserCount,
                item.attendUserCount,
              ];
              return newData;
            });
          setChosenRequest(newRewardDrawing);
          setRewardDrawingDetailList(res.rewardDrawDetail);
          setRewardDrawUserList(DefaultRewardDrawingUser);
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newChallengeId: string, newRewardId: string) => {
    queryClient.prefetchQuery(KEY_REWARD_DRAWING_DETAIL_INFO, () =>
      getRewardDrawChallengeDetailInfo(requestType, newChallengeId, newRewardId)
    );
  };

  const { refetch: rewardDrawUserRefetch } = useQuery(
    KEY_REWARD_DRAWING_USER_LIST,
    () => {
      if (checkRequest) {
        if (rewardId.length > 0) return getRewardDrawUserList(rewardId);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('reward drawing user list :', res);
        setLoading(false);
        if (res !== null) {
          const newUserList: IRewardDrawingUser = {
            challengeId: challengeId,
            rewardId: rewardId,
            rewardCount: rewardCount,
            creator: creator,
            unexcept: res.rewardUser.unexcept,
            except: res.rewardUser.except,
            all: res.rewardUser.all,
            winner: res.rewardUser.winner,
          };
          setRewardDrawUserList(newUserList);
          if (
            res.rewardUser.winner !== null &&
            res.rewardUser.winner.length > 0
          ) {
            setChangeState(REWARD_STATE_SUBMIT);
          }
        }
      },
      onError: e => {
        setLoading(false);
        console.log(e);
      },
    }
  );

  const onRefresh = () => {
    rewardDrawUserRefetch();
  };

  const {
    mutate: postRewardResetDrawResultMutation,
    isError: isMutateChangeError,
  } = useMutation(postRewardResetDrawResult, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnakerDialog(true);
        setDialogMessage('추첨 상태를 초기화했습니다..');
        window.location.reload();
      } else {
        setOpenDialog(true);
        setDialogMessage(`추첨 상태 초기화 실패 : ${res.resultCodeMsg}`);
      }
    },
    onError: error => {
      setOpenSnakerDialog(true);
      setDialogMessage(
        '추첨 상태를 초기화하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const prefetchUser = (newRewardId: string) => {
    queryClient.prefetchQuery(KEY_REWARD_DRAWING_USER_LIST, () =>
      getRewardDrawUserList(newRewardId)
    );
  };

  const searchChallenge = () => {
    rewardDrawRefetch();
  };

  const chooseRequest = (i: number) => {
    console.log('choose index :', rewardDrawingList[i]);
    setRedrawDisable(rewardDrawingList[i].attendUserCount > 0);
    setLoading(true);
    setSelectedRow(i);
    setChallengeId(rewardDrawingList[i].challengeId);
    setRewardId(rewardDrawingList[i].rewardId);
    setCreator(rewardDrawingList[i].challengeCreator);
    setRewardCount(rewardDrawingList[i].rewardCount);
    // prefetchDetail(
    //   rewardDrawingList[i].challengeId,
    //   rewardDrawingList[i].rewardId
    // );

    const newData = [
      rewardDrawingList[i].rewardTitle,
      rewardDrawingList[i].rewardCount,
      rewardDrawingList[i].drawUserCount,
      rewardDrawingList[i].attendUserCount,
    ];
    setChosenRequest([newData]);
    rewardDrawingList[i].attendUserCount === 0
      ? setChangeState(REWARD_STATE_NONE)
      : setChangeState(REWARD_STATE_SUBMIT);
    prefetchUser(rewardDrawingList[i].rewardId);
  };

  const chooseDetailRequest = (i: number) => {
    console.log('choose detail index :', i);
    // const newRewardId = rewardDrawingDetailList[i].rewardId;
    // const newRewardCount = rewardDrawingDetailList[i].rewardCount;
    // setRewardCount(newRewardCount);
    // setRewardId(newRewardId);
    // prefetchUser(newRewardId);
  };

  const searchRewardRequest = (
    newOperator: string,
    key: string,
    name: string
  ) => {
    const newData = {
      operator: newOperator,
      searchKey: key,
      searchWord: name,
    };
    searchData = newData;
    searchChallenge();
  };

  const handleChangePage = (_event: any, newPage: number) => {
    const newData = {
      operator: operator,
      searchKey: searchKey,
      searchWord: searchWord,
    };
    searchData = newData;
    setPage(newPage);
  };

  const resetDrawResult = () => {
    const newData = {
      rewardId: rewardId,
    };
    postRewardResetDrawResultMutation(newData);
  };

  const searching = () => {
    setPage(1);
    prefetchRewardDraw(1, operator, searchKey, searchWord);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searching();
    }
  };

  useEffect(() => {
    prefetchRewardDraw(page, operator, searchKey, searchWord);
  }, [page]);

  useEffect(() => {
    if (
      changeState === REWARD_STATE_DRAWING ||
      changeState === REWARD_STATE_SUBMIT
    ) {
      rewardDrawUserRefetch();
    }
  }, [changeState]);

  useEffect(() => {
    if (location.state) {
      const newState: any = location.state;
      setSearchWord(newState?.challengeId);
      setPage(1);
      setCheckRequest(true);
    }
  }, [location.state]);

  useEffect(() => {
    if (checkRequest) {
      searchChallenge();
    }
    return () => {};
  }, [checkRequest]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>리워드 추첨</title>
      </Helmet>
      <Box>
        <Box>
          <Box sx={{ m: '1.5rem 0' }}>
            <Chip
              icon={<FiberNewOutlinedIcon />}
              label='확인'
              variant='outlined'
              color='primary'
              onClick={() => setCheckRequest(!checkRequest)}
            />
          </Box>
        </Box>
        <Collapse in={checkRequest}>
          <Box sx={{ mb: '1rem' }}>
            <CreatePaper>
              <Box className='flex_between'>
                <Typography sx={{ mb: '.5rem' }}>
                  추첨대기 {preDrawCount} 개
                </Typography>
                <Box className='flex_center'>
                  <Box className='flex_center' sx={{ mr: '2rem' }}>
                    <TextField
                      placeholder='챌린지 이름, 아이디로 검색해 주세요'
                      value={searchWord}
                      onKeyDown={handleKeyDown}
                      onChange={(
                        evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => setSearchWord(evt.target.value)}
                      sx={{ width: '26rem', mr: '1em' }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton sx={{ pr: 0 }} onClick={searching}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className='flex_center'>
                    <Typography>담당자</Typography>
                    <TextField
                      sx={{ width: '10rem', ml: '1rem' }}
                      select
                      value={operator}
                      onChange={(
                        evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                      ) => {
                        setOperator(evt.target.value);
                        searchRewardRequest(
                          evt.target.value,
                          searchKey,
                          searchWord
                        );
                      }}
                    >
                      <MenuItem key={0} value='all'>
                        선택
                      </MenuItem>
                      {empList?.length > 0 &&
                        empList.map((emp: any, idx: number) => (
                          <MenuItem key={emp.id} value={emp.id}>
                            {emp.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Box>
                </Box>
              </Box>
              <TableContainer sx={{ mt: '1rem', maxHeight: '27rem' }}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {requestColumnlists.map((column, idx) => (
                        <TableCell
                          key={idx.toString()}
                          className={clsx(
                            { request_head: false },
                            'request_tablehead'
                          )}
                          sx={{ p: '.4rem .7rem !important' }}
                        >
                          {column}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ maxHeight: '10rem', overflowY: 'scroll' }}>
                    {rewardDrawingList
                      ?.map((row: any, idx: number) => {
                        const now = new Date(Date.now()).getTime() / 1000;
                        const isOver =
                          row.challengeViewEtime <= now &&
                          row.attendUserCount === 0;
                        const newRow = { ...row, isOver: isOver };
                        return newRow;
                      })
                      .map((row: any, idx: number) => (
                        <TableRow
                          key={idx.toString()}
                          className={
                            selectedRow === idx
                              ? 'selected_row'
                              : row.des === 1
                              ? 'specified_row'
                              : 'whitecolor'
                          }
                          onClick={() => {
                            chooseRequest(idx);
                          }}
                        >
                          <TableCell
                            className={
                              selectedRow === idx &&
                              (row.des === 1 || row.isOver)
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row.des === 1 || row.isOver
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                            sx={{ p: '.6rem .7rem' }}
                          >
                            {row.challengeTitle}
                          </TableCell>
                          <TableCell
                            className={
                              selectedRow === idx &&
                              (row.des === 1 || row.isOver)
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row.des === 1 || row.isOver
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                            sx={{ p: '.6rem .7rem' }}
                          >
                            {row.rewardTitle}
                          </TableCell>
                          <TableCell
                            className={
                              selectedRow === idx &&
                              (row.des === 1 || row.isOver)
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row.des === 1 || row.isOver
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                            sx={{ p: '.6rem .7rem' }}
                          >
                            {row.rewardCount}
                          </TableCell>
                          <TableCell
                            className={
                              selectedRow === idx &&
                              (row.des === 1 || row.isOver)
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row.des === 1 || row.isOver
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                            sx={{ p: '.6rem .7rem' }}
                          >
                            {row.drawUserCount}
                          </TableCell>
                          <TableCell
                            className={
                              selectedRow === idx &&
                              (row.des === 1 || row.isOver)
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row.des === 1 || row.isOver
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                            sx={{ p: '.6rem .7rem' }}
                          >
                            {row.challengeCreator}
                          </TableCell>
                          <TableCell
                            className={
                              selectedRow === idx &&
                              (row.des === 1 || row.isOver)
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row.des === 1 || row.isOver
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                            sx={{ p: '.6rem .7rem' }}
                          >
                            {row.operatorName}
                          </TableCell>
                          <TableCell
                            className={
                              selectedRow === idx &&
                              (row.des === 1 || row.isOver)
                                ? clsx(
                                    'request_tablecell selected_row specified_row'
                                  )
                                : selectedRow === idx
                                ? clsx('request_tablecell selected_row')
                                : row.des === 1 || row.isOver
                                ? clsx('request_tablecell specified_row')
                                : clsx('request_tablecell ')
                            }
                            sx={{ p: '.6rem .7rem' }}
                          >
                            {timestamp2Localestring(
                              row.challengeViewEtime * 1000
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CreatePaper>
          </Box>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
          {/* <RequestTable
            columns={clickColumnlist}
            rows={chosenRequest}
            onclick={chooseDetailRequest}
          /> */}
        </Collapse>
      </Box>
      <DrawingSetting
        rewardDrawUserList={rewardDrawUserList}
        setRewardDrawUserList={setRewardDrawUserList}
        rewardConditionList={rewardConditionList}
        setRewardConditionList={setRewardConditionList}
        changeState={changeState}
        setChangeState={setChangeState}
        onRefresh={onRefresh}
      />
      <Drawing
        rewardDrawUserList={rewardDrawUserList}
        setRewardDrawUserList={setRewardDrawUserList}
        rewardConditionList={rewardConditionList}
        setRewardConditionList={setRewardConditionList}
        changeState={changeState}
        setChangeState={setChangeState}
        onRefresh={onRefresh}
      />
      <GivingReward
        challengeId={challengeId}
        rewardId={rewardId}
        rewardInfo={rewardDrawingList[selectedRow]}
        rewardDrawUserList={rewardDrawUserList}
        setRewardDrawUserList={setRewardDrawUserList}
        changeState={changeState}
        setChangeState={setChangeState}
      />
      <Box className='flex_end'>
        <Box>
          <Button
            disabled={redrawDisable}
            variant='contained'
            color='info'
            onClick={() => resetDrawResult()}
          >
            재추첨 하기
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      {loading && <SwLoading />}
    </div>
  );
};
