import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IContract } from '../../models/contract';
import { IServiceCoupon } from '../../models/service_coupon';
import { throwError } from '../helper';

/*
 * Contract
 */

export const getContractList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/contract?type=${data.type}&page=${data.page}&per_page=${data.rowsPerPage}&startDate=${data.startDate}&endDate=${data.endDate}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const postContractRegister = (data: IContract) =>
  xapi.post(`/admin/v2/apis/contract/${data.portalCode}`, data);

export const getContractInfoList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/contract/${data.id}?type=${data.type}&page=${data.page}&per_page=${data.rowsPerPage}&startDate=${data.startDate}&endDate=${data.endDate}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteContract = (contractId: string) =>
  xapi.delete(`/admin/v2/apis/contract/${contractId}`);

export const downloadContractData = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/contract/download?type=${data.type}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contract_data.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadContractInfoData = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/contract/contract-info/download?portalId=${data.id}&type=${data.type}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contract_info.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadCommunityData = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/contract/community/download?portalId=${data.portalId}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contract_community_data.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadChallengeData = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/contract/challenge/download?portalId=${data.portalId}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contract_challenge_data.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

export const downloadProgramData = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/contract/program/download?isCompleted=${data.isCompleted}&portalId=${data.portalId}&searchKey=${data.searchKey}&searchWord=${data.searchWord}&startDate=${data.startDate}&endDate=${data.endDate}`,
      { responseType: 'blob' }
    )
    .then(res => {
      const blob = new Blob([res.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'contract_program_data.xlsx';
      document.body.appendChild(a);
      a.click();
      setTimeout((_: any) => {
        window.URL.revokeObjectURL(url);
      }, 60000);
      a.remove();
    })
    .catch(err => {
      console.error('err: ', err);
    });

/*
 * Service Coupon
 */

export const getServiceCouponList = (data: IServiceCoupon) =>
  xapi
    .get(
      `/admin/v2/apis/contract/service-coupon?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getServiceCouponInfo = (data: IServiceCoupon) =>
  xapi
    .get(`/admin/v2/apis/contract/service-coupon/portal/${data.portalId}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getServiceCouponHistory = (id: string) =>
  xapi.get(`/admin/v2/apis/contract/service-coupon/${id}/history`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const postServiceCouponRegister = (data: IServiceCoupon) =>
  xapi.post(`/admin/v2/apis/contract/service-coupon`, {
    type: data.type,
    portalId: data.portalId,
    couponName: data.couponName,
    couponType: data.couponType,
    couponCount: data.couponCount,
  });

export const putServiceCouponCancel = (data: IServiceCoupon) =>
  xapi.put(`/admin/v2/apis/contract/service-coupon/${data.id}/cancel`, {
    couponCount: data.couponCount,
  });

export const deleteServiceCoupon = (id: string) =>
  xapi.delete(`/admin/v2/apis/contract/service-coupon/${id}`);

/*
 * Report
 */

export const getContractReportMonthly = () =>
  xapi.get(`/admin/v2/apis/contract/report/monthly`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });
