import {
  Box,
  Button,
  Chip,
  Dialog,
  Paper,
  styled,
  Switch,
  Tab,
  TableCell,
  Tabs,
  TextField,
} from '@mui/material';

export const TabBox = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider',
  // margin: '.7rem 1rem',
  '& .MuiTab-root.Mui-selected': {
    color: '#434655',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#434655',
  },
  '& .MuiTab-root': {
    padding: '.5rem',
    fontSize: '.9rem',
    color: '#858C97',
  },
});

export const SubTabBox = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider',
  '& .MuiTab-root.Mui-selected': {
    color: '#00A2FF',
    backgroundColor: '#fff',
    border: '4px solid #EBECF2',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTabs-root': {
    minHeight: '1rem',
  },
  '& .MuiTab-root': {
    fontSize: '.8rem',
    minWidth: '3.2rem',
    minHeight: '1rem',
    backgroundColor: '#EBECF2',
    padding: '.4rem 1.5rem',
  },
});

export const CreatePaper = styled(Paper)({
  margin: '1.5rem 0',
  padding: '1.5rem 2.5rem',
});

export const TermPaper = styled(Paper)({
  backgroundColor: '#F3F3F3',
  border: '1px solid #D4D9E1',
  padding: '.6rem .8rem',
  marginBottom: '.8rem',
  boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%)',
  fontWeight: 350,
});

export const JoinFormPaper = styled(Paper)({
  backgroundColor: '#FCFCFC',
  border: '1px solid #D4D9E1',
  padding: '.6rem .8rem',
  marginBottom: '.8rem',
  boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 10%)',
  fontWeight: 350,
});

export const AddGroupTextField = styled(TextField)({
  marginRight: '1rem',
  marginBottom: 0,
  '& .MuiOutlinedInput-root': {
    padding: '0.5rem 1rem',
    height: 'auto',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0.5rem',
  },
});

export const NoneBorderChip = styled(Chip)({
  margin: '0 .1rem',
  border: 'none',
  '& .css-6od3lo-MuiChip-label': {
    paddingRight: '8px',
  },
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
    marginTop: '.1rem',
  },
});

export const DraggablePaper = styled(Paper)({
  width: '100%',
  display: 'flex',
  margin: '1rem 1.7rem',
  padding: '.5rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'none',
  cursor: 'pointer',
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 4%)',
});

export const CurriculumPaper = styled(Paper)({
  width: '100%',
  display: 'flex',
  margin: '1rem 1.7rem',
  padding: '.5rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'none',
  cursor: 'pointer',
  boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 4%)',
});

export const LargeDialog = styled(Dialog)({
  '& .MuiDialog-paper': {
    minWidth: '80vw',
    minHeight: '80vh',
  },
});

export const SelectTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '4px',
    height: '2rem',
  },
});

export const WhiteTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    background: '#FFFFFF',
  },
});

export const DateTextField = styled(TextField)({
  '& .MuiInputLabel-root': { top: -4 },
  marginBottom: 0,
});

export const MultilineTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    height: 'auto',
  },
});

export const ColumsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.6rem',
});

export const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.9rem .6rem',
});

export const TableTopButton = styled(Button)({
  margin: '0 1rem .4rem',
  padding: '.17rem 1rem',
});

export const TableButton = styled(Button)({
  margin: '0 0 .4rem .5rem',
  padding: '.17rem 1rem',
});

export const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
});

export const CreateTab = styled(Tab)({
  background: '#ffFfFf',
});

export const SwSwitch = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 22,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 16,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(18px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 3,
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 15,
    height: 15,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));
