import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import React, { Dispatch, MouseEvent, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { SwAlert } from '../views/SwAlert';

export const CategoryPaper = styled(Paper)({
  width: '100%',
  display: 'flex',
  margin: '1rem 1rem',
  padding: '.5rem',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'none',
  cursor: 'pointer',
  boxShadow: '0px 2px 1px -1px rgb(ff ff ff / 4%)',
});

const categoryTitles = [
  { icon: '🍅', title: '식재료 사전', value: 1 },
  { icon: '🍛', title: '레시피', value: 2 },
  { icon: '💪', title: '맞춤 운동', value: 3 },
  { icon: '👨‍⚕️', title: '예방 및 관리', value: 4 },
];

interface Props {
  selectedTitles: any[];
  setSelectedTitles: Dispatch<React.SetStateAction<any[]>>;
  open: boolean;
  onSelected: (title: string) => void;
  onClose: () => void;
}

export const SwMagazineCategory: React.FC<Props> = ({
  selectedTitles,
  setSelectedTitles,
  open,
  onSelected,
  onClose,
}) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const clickHandler = (idx: number) => {
    const newSelectedTitles = [...selectedTitles];
    if (newSelectedTitles[idx + 1] !== 1) {
      newSelectedTitles[idx + 1] = 1;
      setSelectedTitles(newSelectedTitles);
      onSelected(categoryTitles[idx].title);
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      const newTitles = selectedTitles.filter((item: any) => item !== 1);
      console.log('newTitles', newTitles);
      if (newTitles.length === 0) {
        setDialogMessage('더 이상 생성할 수 있는 카테고리가 없습니다.');
        setAlertOpen(true);
      }
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            height: '30vh',
            width: '30vh',
          },
        }}
      >
        <DialogContent sx={{ p: '1rem 2rem' }}>
          <Box className='flex_between'>
            <Typography sx={{ mt: '0.7rem' }}>
              추가 카테고리 타입 선택
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={{ p: 0, m: 0 }} />
          <Box>
            {categoryTitles.map((row: any, idx: number) => (
              <CategoryPaper
                className={selectedTitles[idx + 1] === 1 ? '' : 'click_group'}
                key={`category_button_${idx}`}
                onClick={() => {
                  clickHandler(idx);
                }}
              >
                <Box>
                  <Button
                    fullWidth
                    disabled={selectedTitles[idx + 1] === 1}
                  >{`${row.icon} ${row.title}`}</Button>
                </Box>
              </CategoryPaper>
            ))}
          </Box>
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
      </Dialog>
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={dialogMessage}
      />
    </>
  );
};
