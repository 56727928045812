import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

const TagsChip = styled(Chip)({
  margin: '.5em',
  color: '#2581FF',
  backgroundColor: 'rgba(52, 84, 248, 0.08)',
  borderColor: '#2581FF',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

export const MAX_UPLOAD_FILE_SIZE = 10;
export const MAX_OPTION_COUNT = 20;

export const countArr = [1, 2, 3, 4, 5, 6, 7];
export const pointPerAchieve = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];

export const IMAGE_SIZE_10MB_MESSAGE = '10mb 이하의 이미지를 등록해주세요.';

export const TOOLTIP_REQUIRED_ALL_ANSWER =
  '사용자가 모든 질문에 필수로 답변해야 합니다.';

export enum curriculumType {
  movie = 0,
  magazine,
  qna,
  lifestyle,
  none = 99,
}

export const curriculumTypeList = [
  { type: curriculumType.movie, title: '🎥 영상' },
  { type: curriculumType.magazine, title: '📚 매거진' },
  { type: curriculumType.qna, title: '📝 질의응답' },
  { type: curriculumType.lifestyle, title: '🥛 생활습관' },
];

export const missionRecordings = [
  {
    id: '0',
    title: '혈압 기록하기',
    items: ['혈압(수축기 / 이완기)', '측정 날짜', '측정 시간'],
    keyword: '혈압',
  },
  {
    id: '1',
    title: '혈당 기록하기',
    items: [
      '혈당(공복, 아침 식후, 점심 식후, 저녁 식후, 취침 전)',
      '측정 날짜',
      '측정 시간',
    ],
    keyword: '혈당',
  },
  {
    id: '2',
    title: '체중 기록하기',
    items: ['체중', '키', '측정 날짜', '측정 시간'],
    keyword: '체중',
  },
  {
    id: '3',
    title: '금연 기록하기',
    items: ['흡연량', '흡연내용', '측정 날짜', '측정 시간'],
    keyword: '금연',
  },
  {
    id: '4',
    title: '스트레스 기록하기',
    items: ['스트레스 점수', '측정 날짜', '측정 시간'],
    keyword: '스트레스',
  },
];

export const authAvailableTime = [
  { type: 'unlimit', title: '제한없음' },
  { type: 'time_06', title: '아침 (06:00 ~ 11:00)' },
  { type: 'time_11', title: '점심 (11:00 ~ 14:00)' },
  { type: 'time_14', title: '오후 (14:00 ~ 18:00)' },
  { type: 'time_18', title: '저녁 (18:00 ~ 24:00)' },
  { type: 'time_00', title: '새벽 (00:00 ~ 6:00)' },
  { type: 'direct', title: '직접 입력' },
];

export const authPictureType = [
  { type: 'required', title: '필수' },
  { type: 'optional', title: '선택' },
];

export const authAutoType = [
  { type: 'step', title: '걸음수' },
  { type: 'walking', title: '걷기활동' },
  { type: 'power_walking', title: '파워워킹' },
];

export const qnaSurveyTypeList = [
  { type: 'choice', title: '객관식', no: 1 },
  { type: 'short', title: '단답형', no: 2 },
  { type: 'long', title: '장문형', no: 3 },
  { type: 'scale', title: '척도', no: 4 },
  { type: 'date', title: '날짜', no: 5 },
  { type: 'datepoint', title: '날짜+시각', no: 9 },
  { type: 'point', title: '시각', no: 6 },
  { type: 'duration', title: '기간', no: 7 },
  { type: 'time', title: '시간', no: 8 },
];

export const quizTypeList = [
  { type: 'choice', title: '객관식' },
  { type: 'short', title: '단답형' },
];

export const unitSelectorList = [
  { type: '선택', title: '선택' },
  { type: 'mm', title: 'mm' },
  { type: 'cm', title: 'cm' },
  { type: 'm', title: 'm' },
  { type: 'km', title: 'km' },
  { type: 'g', title: 'g' },
  { type: 'kg', title: 'kg' },
  { type: 'kg/m2', title: 'kg/m2' },
  { type: 'kcal', title: 'kcal' },
  { type: 'cc', title: 'cc' },
  { type: 'ml', title: 'ml' },
  { type: 'l', title: 'l' },
  { type: '℃', title: '℃' },
  { type: 'mmHg', title: 'mmHg' },
  { type: 'mg/dL', title: 'mg/dL' },
  { type: '%', title: '%' },
  { type: 'm/s', title: 'm/s' },
  { type: 'km/h', title: 'km/h' },
  { type: '개비', title: '개비' },
  { type: '갑', title: '갑' },
  { type: '개', title: '개' },
  { type: '잔', title: '잔' },
  { type: '병', title: '병' },
  { type: '캔', title: '캔' },
  { type: '회', title: '회' },
  { type: '세트', title: '세트' },
  { type: '세', title: '세' },
  { type: '직접입력', title: '직접입력' },
];

export function getTimeArray() {
  const timeArr: string[] = [];

  for (let i = 0; i < 24; i += 1) {
    timeArr.push(`${i.toString().padStart(2, '0')}:00`);
  }

  return timeArr;
}

export function getAuthAvailableTime(type: string) {
  const duration =
    type === 'time_06'
      ? [6, 11]
      : type === 'time_11'
      ? [11, 14]
      : type === 'time_14'
      ? [14, 18]
      : type === 'time_18'
      ? [18, 24]
      : type === 'time_00'
      ? [0, 6]
      : [0, 24];
  return duration;
}

export function getQnaSurveyType(type: number) {
  const newType = qnaSurveyTypeList.filter(
    (item: any, idx: number) => item.no === type
  );
  return newType !== undefined && newType !== null && newType.length > 0
    ? newType[0].type
    : 'choice';
}

export function getQnaSurveyTypeNo(type: string) {
  const newType = qnaSurveyTypeList.filter(
    (item: any, idx: number) => item.type === type
  );
  return newType !== undefined && newType !== null ? newType[0].no : 0;
}

export function showKeyword(keyword: string) {
  const keywords = JSON.parse(keyword);
  return keywords.map((item: any, i: number) => (
    <TagsChip label={`${item.t}`} variant='outlined' key={i.toString()} />
  ));
}

export function getParsedImage(images: string, idx: number) {
  const parsedImages = JSON.parse(images);
  if (parsedImages.length < idx) return '';
  return parsedImages[idx];
}

export function showImageSlideWithWidth(
  images: string,
  imageUrlPrefix: string,
  width: number,
  size: number
) {
  const parsedImages = JSON.parse(images);
  return (
    <Box>
      <Box
        sx={{
          height: `${width}rem`,
          width: `${width}rem`,
          overflow: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {parsedImages.map((item: any, idx: number) => (
          <Box>
            <img
              key={`slide_image_${idx}`}
              src={`${imageUrlPrefix}${item.image}`}
              alt=''
              style={{
                maxWidth: '10vw',
                borderRadius: '14px',
                width: `${size}rem`,
                height: 'auto',
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export function showImageSlide(images: string, imageUrlPrefix: string) {
  return showImageSlideWithWidth(images, imageUrlPrefix, 10, 10);
}
