import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { WalkcourseMap } from './WalkcourseMap';
import { WalkcourseForm } from './WalkcourseForm';
import {
  IWalkcouseDetail,
  IWalkcourseInfo,
  DefaultWalkcourseInfo,
  DefaultWalkcouseDetail,
} from '../../../models/walkcourse';
import {
  getWalkcourseList,
  getWalkcourseRequestDetail,
  getWalkcourseWalkway,
} from '../../../common/api/ApiWalkcourse';
import {
  KEY_WALKCOURSE_LIST,
  KEY_WALKCOURSE_REQUEST_DETAIL,
  KEY_WALKCOURSE_WALKWAY_COUNT,
} from '../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';

const requestColumnlists = [
  '개설 기관명',
  '길코스 이름',
  '추천코스 노출',
  '생성일시',
];

const selectVal = [
  {
    title: '코스 이름',
    value: 'name',
  },
  { title: '코스 아이디', value: 'id' },
  { title: '개설 기관명', value: 'company' },
];

interface Props {}

export const WalkcourseInquire: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const selectRef = useRef<any>(0);
  const searchRef = useRef<any>();
  const [commandType, setCommandType] = useState<number>(0); // 0: no-action, 1: save, 2:update, 3: delete
  const [mapReload, setMapReload] = useState(0);
  const [mapInfo, setMapInfo] = useState<any>();
  const [gpsData, setGpsData] = useState<any>([]);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [courseId, setCourseId] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('name');
  const [searchWord, setSearchWord] = useState<string>('');
  const [walkcourseList, setWalkcourseList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [courseData, setCourseData] = useState<any>();
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [walkcourseDetail, setWalkcourseDetail] = useState<IWalkcouseDetail>(
    DefaultWalkcouseDetail
  );
  const [walkwayList, setWalkwayList] = useState<string[]>([]);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const clearSearchCondition = () => {
    setSearchKey('');
    setSearchWord('');
  };

  const {
    data,
    isLoading,
    refetch: walkwayCourseRefetch,
  } = useQuery(
    KEY_WALKCOURSE_LIST,
    () => {
      if (searchKey !== '') {
        const newData = {
          page: page,
          rowsPerPage: rowsPerPage,
          searchKey: searchKey,
          searchWord: encodeSearchWord(searchKey, searchWord),
        };
        return getWalkcourseList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('WalkcourseInquire  res:', res);
        const newWalkcourse =
          res &&
          res.course &&
          res.course.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const newCreatorName = item.creatorName
              ? item.creatorName
              : item.creator;
            const show = item.courseShow === 1 ? '노출' : '-';
            const newData = [newCreatorName, item.courseName, show, newDate];
            return newData;
          });
        setRequestList(newWalkcourse);
        setWalkcourseList(res ? res.course : []);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const walkwayCoursePrefetchPage = (newPage: number) => {
    queryClient.prefetchQuery(KEY_WALKCOURSE_LIST, () => {
      const newData = {
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getWalkcourseList(newData);
    });
  };

  const walkwayCoursePrefetch = (
    newSearchKey: string,
    newSearchWord: string
  ) => {
    queryClient.prefetchQuery(KEY_WALKCOURSE_LIST, () => {
      const newData = {
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: newSearchKey,
        searchWord: encodeSearchWord(newSearchKey, newSearchWord),
      };
      return getWalkcourseList(newData);
    });
  };

  const { refetch: walkcourseRequestDetailRefetch } = useQuery(
    KEY_WALKCOURSE_REQUEST_DETAIL,
    () => {
      console.log('courseId:', courseId);
      if (courseId.length > 0) return getWalkcourseRequestDetail(courseId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          const tmpRoute = res.creationRequestDetail.point.map((point: any) => {
            const temp = {
              lat: point.pointPtLat,
              lng: point.pointPtLng,
              radius: point.pointRadius,
            };
            return temp;
          });
          console.log('point :', tmpRoute);
          setGpsData(tmpRoute);

          const newCircleData = {
            lat: res.creationRequestDetail.course.courseCenterPointLat,
            lng: res.creationRequestDetail.course.courseCenterPointLng,
            radius: res.creationRequestDetail.course.courseCenterRadius,
          };

          const newSpot = res.creationRequestDetail.spot.map((spot: any) => {
            const temp = {
              id: spot.spotId,
              name: spot.spotName,
              image: spot.spotImage,
              lat: spot.spotPointLat,
              lng: spot.spotPointLng,
              radius: spot.spotRadius,
              description: spot.description,
              className: spot.className,
              link: spot.link,
              phoneNo: spot.phoneNo,
              address: spot.address,
            };
            return temp;
          });

          const newCourseData = {
            gpsData: tmpRoute,
            circleData: newCircleData,
            spotData: newSpot,
          };
          setCourseData(newCourseData);

          setWalkcourseDetail(res.creationRequestDetail);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newCourseId: string) => {
    queryClient.prefetchQuery(KEY_WALKCOURSE_REQUEST_DETAIL, () =>
      getWalkcourseRequestDetail(newCourseId)
    );
  };

  const { refetch: walkwayCourseWalkwayRefetch } = useQuery(
    KEY_WALKCOURSE_WALKWAY_COUNT,
    () => {
      if (courseId !== undefined && courseId !== null && courseId.length > 0) {
        return getWalkcourseWalkway(courseId);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('Walkcour walkway res:', res);
        if (res && res.walkway) {
          setWalkwayList(res.walkway);
          console.log('res.walkway:', res.walkway[0]);
        } else setWalkwayList([]);
        if (courseId !== undefined && courseId !== null && courseId.length > 0)
          setOpenDelete(true);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const onReload = () => {
    walkwayCoursePrefetch(searchKey, searchWord);
  };

  useEffect(() => {
    setMapReload(1);
    return () => {
      setMapReload(0);
    };
  }, []);

  const isSearching = () => {
    if (searchRef.current.value && selectRef.current.value) {
      setSearchKey(selectRef.current.value);
      setSearchWord(searchRef.current.value);
    } else {
      clearSearchCondition();
    }
  };

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[0], Chosen[1], Chosen[2], Chosen[4]];
    // setIsSend(false);
    setChosenRequest([newChosen]);
    setCourseId(walkcourseList[i].courseId);
    prefetchDetail(walkcourseList[i].courseId);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    walkwayCoursePrefetchPage(newPage);
  };

  const getFocusIndex = () => {
    const newIndex = walkcourseList
      .map((item: any, idx: number) => (item.courseId === courseId ? idx : -1))
      .filter((item: any) => item !== -1);
    const index = newIndex !== null && newIndex.length > 0 ? newIndex[0] : -1;
    return index;
  };

  const checkDelete = () => {
    if (courseId !== undefined && courseId !== null && courseId.length > 0) {
      walkwayCourseWalkwayRefetch();
    } else {
      setOpenSnackbar(true);
      setDialogMessage('워크코스를 선택해주세요');
    }
  };

  useEffect(() => {
    console.log('searchKey :', searchKey, ', searchWord:', searchWord);
    walkwayCoursePrefetch(searchKey, searchWord);
  }, [searchKey, searchWord]);

  useEffect(() => {
    if (mapInfo) {
      console.log('WalkcourseInquire mapInfo :', mapInfo);
      const newCourse = walkcourseDetail.course;
      newCourse.courseDistance = mapInfo.courseDistance;
      newCourse.courseTime = mapInfo.courseTime;
      const newWalkcourseDetail = {
        course: newCourse,
        point: walkcourseDetail.point,
      };
      console.log(
        'WalkcourseInquire newWalkcourseDetail :',
        newWalkcourseDetail
      );
      setWalkcourseDetail(newWalkcourseDetail);
      setGpsData(mapInfo.points);
    }
  }, [mapInfo]);

  return (
    <Box className='root_box'>
      <Box className='flex_between'>
        <SelectSearchBox
          searchOption={selectVal}
          searchWord={searchRef}
          defaultOption='name'
          isSearching={isSearching}
          optionValue={selectRef}
        />
      </Box>
      <Paper sx={{ p: '1.5rem', m: '1.4rem 0' }}>
        <Box sx={{ mb: '1rem' }}>
          <RequestTable
            columns={requestColumnlists}
            rows={requestList}
            onclick={chooseRequest}
            getFocusIndex={getFocusIndex}
          />
        </Box>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
        <WalkcourseMap
          courseData={courseData}
          mapInfo={mapInfo}
          setMapInfo={setMapInfo}
        />
        <WalkcourseForm
          commandType={commandType}
          setCommandType={setCommandType}
          mapInfo={mapInfo}
          walkcourseDetail={walkcourseDetail}
          setWalkcourseDetail={setWalkcourseDetail}
          onReload={onReload}
        />
      </Paper>
      <Box className='flex_end' sx={{ m: '2rem 0' }}>
        <Box>
          <Button
            variant='outlined'
            color='info'
            sx={{ mr: '1rem' }}
            onClick={() => {
              checkDelete();
            }}
          >
            삭제
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              setCommandType(2);
            }}
          >
            저장
          </Button>
        </Box>
      </Box>
      <SwConfirmDialog
        contents={
          walkwayList.length === 0 ||
          (walkwayList.length > 0 && walkwayList[0] === null)
            ? '길코스 삭제하시겠습니까?'
            : '현재 길 코스를 사용중인 워크웨이가 있습니다. 길 코스를 삭제하시겠습니까?'
        }
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setCommandType(3);
          setOpenDelete(false);
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </Box>
  );
};
