import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  checkNaN,
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
} from './ContentManageStyles';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { registerContentManageContent } from '../../../common/api/ApiContentManage';
import { RESULT_OK } from '../../../common/resultCode';
import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import { SwSurveyContentForm } from '../../commonComponent/program/SwSurveyContentForm';
import { SwQuizContentForm } from '../../commonComponent/program/SwQuizContentForm';

const EDIT_MODE = 'edit';

interface Props {
  contentManageInfo: any;
  setContentManageInfo: Dispatch<React.SetStateAction<any>>;
  reloadContentManage: (id: any) => void;
}

export const ContentManageContentQnaForm: React.FC<Props> = ({
  contentManageInfo,
  setContentManageInfo,
  reloadContentManage,
}) => {
  const location = useLocation();
  const [qnaClass, setQnaClass] = useState<number>(3);
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [contentId, setContentId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [status, setStatus] = useState<number>(1);
  const [seq, setSeq] = useState<number>(0);
  const [selectedContentManage, setSelectedContentManage] =
    useState<boolean>(false);
  const [quiz, setQuiz] = useState<any>();
  const [survey, setSurvey] = useState<any>();
  const [editMode, setEditMode] = useState<string>('edit');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  useEffect(() => {
    if (
      contentManageInfo.contentId !== undefined &&
      contentManageInfo.contentId !== null
    ) {
      setStatus(contentManageInfo.status === false ? 0 : 1);
      setSeq(contentManageInfo.seq);
    }
  }, [contentManageInfo]);

  return (
    <>
      <CreatePaper sx={{ mb: '2rem' }}>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>순서</Typography>
          </Box>
          <TextField
            value={seq}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setSeq(checkNaN(evt.target.value, seq));
              setContentManageInfo((prev: any) => ({
                ...prev,
                save: false,
                seq: checkNaN(evt.target.value, seq),
              }));
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            sx={{ width: '12rem' }}
            select
            value={status}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              setStatus(Number(evt.target.value));
              setContentManageInfo((prev: any) => ({
                ...prev,
                save: false,
                status: Number(evt.target.value) === 1,
              }));
            }}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
      </CreatePaper>
      <CreatePaper>
        <Box>
          {contentManageInfo.type === 3 && (
            <Box>
              <SwSurveyContentForm
                editMode={editMode}
                onChangeEditMode={(value: string) => {
                  setEditMode(value);
                }}
                contentManageInfo={contentManageInfo}
                setContentManageInfo={setContentManageInfo}
                reloadContentManage={reloadContentManage}
              />
            </Box>
          )}
          {contentManageInfo.type === 5 && (
            <Box>
              <SwQuizContentForm
                editMode={editMode}
                onChangeEditMode={(value: string) => {
                  setEditMode(value);
                }}
                contentManageInfo={contentManageInfo}
                setContentManageInfo={setContentManageInfo}
                reloadContentManage={reloadContentManage}
              />
            </Box>
          )}
        </Box>
        <SwSnackbar
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
          contents={alertMessage}
        />
        <SwAlert
          open={alertOpen}
          onConfirm={() => setAlertOpen(false)}
          title={alertTitle}
          contents={alertMessage}
        />
      </CreatePaper>
    </>
  );
};
