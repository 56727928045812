import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwImageBox } from './SwImageBox';
import { useSession } from '../../../hooks/session';
import { checkImageFileName } from '../../../common/helper';
import { SwAlert } from '../views/SwAlert';

const NoImagePaper = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  color: '#C2CAD4',
  padding: '1.5rem',
  margin: '1rem 0',
  cursor: 'pointer',
});

const NoImageIcon = styled(AddOutlinedIcon)({
  border: '1px dashed #C2CAD4',
  borderRadius: '50%',
  width: '3em',
  height: 'auto',
});

interface Props {
  imageList: string[];
  setImageList: Dispatch<React.SetStateAction<string[]>>;
  width?: string;
  height?: string;
  disabled?: boolean;
}

export const SwImageSlider: React.FC<Props> = ({
  imageList,
  setImageList,
  width = '10rem',
  height = 'auto',
  disabled = false,
}) => {
  const { loginVal } = useSession();
  const inpRef = useRef<HTMLInputElement>(null);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const deleteImage = (idx: number) => {
    console.log('delete image : ', idx);
    if (inpRef.current !== null) inpRef.current.value = '';

    const newImageList = imageList.filter((item: any, i: number) => idx !== i);
    setImageList(newImageList);
  };

  useEffect(() => {
    if (imageList.length > 0)
      setImageUrlPrefix(loginVal.value.user.imageUrlPrefix);
  }, [imageList]);

  return (
    <>
      <Box className='container'>
        <Box
          className='flex_start'
          sx={{
            overflow: 'scroll',
            overflowY: 'hidden',
          }}
        >
          <input
            type='file'
            ref={inpRef}
            style={{ display: 'none' }}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              if (
                event.currentTarget.files &&
                !checkImageFileName(event.currentTarget.files[0].name)
              ) {
                setOpenDialog(true);
                setDialogMessage(
                  '지원하지 않는 이미지 포맷입니다. 다시 시도해 주시기 바랍니다.'
                );
                return;
              }

              if (event.currentTarget.files?.[0]) {
                const file = event.currentTarget.files[0];

                console.log(file.name);
                console.log(
                  'url :',
                  URL.createObjectURL(event.currentTarget.files[0])
                );
                const newImageList = [
                  ...imageList,
                  URL.createObjectURL(event.currentTarget.files[0]),
                ];
                setImageList(newImageList);
                // const reader = new FileReader();
                // reader.readAsDataURL(file);
                // reader.onloadend = evt => {
                //   console.log('evt.target?.result :', evt.target?.result);
                //   setImageList(prev => [...prev, evt.target?.result as string]);
                // };
              }
            }}
          />
          {imageList.map((el, idx) => (
            <SwImageBox
              key={el + idx}
              src={el.includes('blob:') ? el : `${imageUrlPrefix}${el}`}
              onDelete={() => {
                if (!disabled) deleteImage(idx);
              }}
            />
          ))}
          <NoImagePaper
            aria-hidden
            onClick={() => {
              if (!disabled) inpRef.current?.click();
            }}
            sx={{ width: width }}
          >
            <NoImageIcon />
            <Typography sx={{ fontSize: '.75rem' }}>이미지 선택</Typography>
          </NoImagePaper>
        </Box>
      </Box>
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
