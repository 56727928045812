import { Box, styled, Tab, Tabs } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import clsx from 'clsx';
import { useResize } from '../../hooks/resize';

interface Props {}

const ApisBox = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider',
  // margin: '1em 2em',
  '& .MuiTab-root.Mui-selected': {
    color: '#434655',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#434655',
  },
  '& .MuiTab-root': {
    padding: '.5em',
    fontSize: '.9rem',
    color: '#858C97',
  },
});

export const Apis: React.FC<Props> = () => {
  const { isMobile } = useResize();
  const navigate = useNavigate();
  const [value, setValue] = useState<number>(1);
  const tabChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('dataportal');
        break;
      case 2:
        setValue(2);
        navigate('admin');
        break;
      default:
        setValue(0);
        navigate('/apis');
    }
  };
  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/apis/dataportal') setValue(1);
    else if (path === '/apis/admin') setValue(2);
  }, [window.location.pathname]);

  return (
    <div className={clsx({ root: !isMobile() }, { root_resize: isMobile() })}>
      <Helmet>
        <title>api~~~</title>
      </Helmet>
      <ApisBox>
        <Tabs value={value} onChange={tabChange}>
          <Tab label='데이터포탈' value={1} />
          <Tab label='어드민' value={2} />
        </Tabs>
      </ApisBox>
      <Outlet />
    </div>
  );
};
