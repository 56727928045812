import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface Props {
  message: string;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const SwBasicDialog: React.FC<Props> = ({
  message,
  open,
  onConfirm,
  onClose,
}) => {
  const a = 1;
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-container': {
          height: '85%',
        },
      }}
    >
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Typography sx={{ mt: '0.7rem' }}>{message}</Typography>
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        <Button color='info' fullWidth size='small' onClick={onClose}>
          취소
        </Button>
        <Button color='primary' fullWidth size='small' onClick={onConfirm}>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};
