import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState, Dispatch } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getChallengeAttendUserList } from '../../common/api/ApiChallenge';
import { KEY_CHALLENGE_ATTEND_USER_LIST } from '../../common/key';
import { IChallengeReqeust } from '../../models/challenge';

interface Props {
  title: string;
  request: IChallengeReqeust;
  open: boolean;
  onClose: () => void;
  setTarget: Dispatch<React.SetStateAction<any[]>>;
}

const columns = [
  '이름',
  '아이디',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
];

export const SwSearchChallengeUser: React.FC<Props> = ({
  title,
  open,
  onClose,
  request,
  setTarget,
}) => {
  const queryClient = useQueryClient();
  const [userList, setUserList] = useState<any>([]);
  const {
    data,
    isLoading,
    refetch: refetchChallengeAttendUser,
  } = useQuery(
    KEY_CHALLENGE_ATTEND_USER_LIST,
    () => {
      if (open && request?.searchKey !== '')
        return getChallengeAttendUserList(request);
      return null;
    },
    {
      onSuccess: res => {
        if (res != null) setUserList(res.user);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const clickItem = (row: any) => {
    const target = [
      { userId: row.userId, name: row.name, phoneno: row.phoneno, status: '' },
    ];
    setTarget(target);
    onClose();
  };

  useEffect(() => {
    if (open) {
      refetchChallengeAttendUser();
    }
    return () => {};
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      sx={{
        '& .MuiDialog-paper': {
          height: '66vh',
          margin: '10rem',
        },
      }}
    >
      <DialogTitle>
        <Box className='flex_between'>
          <span>
            {request && request.searchWord && `'${request.searchWord}'`} {title}{' '}
            검색결과
          </span>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <Divider sx={{ m: 0, p: 0 }} />
      <DialogContent>
        <TableContainer>
          <Table stickyHeader>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.map((row: any, idx: number) => (
                <TableRow key={idx.toString()} onClick={() => clickItem(row)}>
                  <TableCell key={row.userId} sx={{ p: '.3rem .6rem' }}>
                    {`${row.name}(${row.phoneno})`}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>{row.userId}</TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.nickName === null
                      ? '-'
                      : row.useNickName === 1
                      ? 'O'
                      : '-'}
                  </TableCell>
                  <TableCell sx={{ p: '.3rem .6rem' }}>
                    {row.nickName !== null ? row.nickName : '-'}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Divider sx={{ m: 0 }} />
      {/* <DialogActions sx={{ p: '1rem' }}>
        <Button
          variant='contained'
          color='secondary'
          sx={{ fontSize: '.87rem' }}
          onClick={onClose}
        >
          닫기
        </Button>
      </DialogActions> */}
    </Dialog>
  );
};
