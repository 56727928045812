import {
  Box,
  Button,
  Pagination,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useState } from 'react';
import Dataportalapi from '../../dummy/dataportalapi.json';
import { SwGridTable } from '../commonComponent/table/SwGridTable';
import { SwGridTableTest } from '../commonComponent/table/SwGridTableTest';
import { SwTable } from '../commonComponent/table/SwTable';

interface Props {}

const ColumsCell = styled(TableCell)({
  fontSize: '1rem',
  fontWeight: 500,
  padding: '.6rem',
  backgroundColor: '#B0BEC5',
});

const RowsCell = styled(TableCell)({
  fontSize: '.88rem',
  fontWeight: 400,
  padding: '.9rem .6rem',
  maxWidth: '400px',
  width: 'fit-contents',
  overflowWrap: 'break-word',
});

const columnss: GridColDef[] = [
  {
    field: 'view',
    headerName: '화면',
    minWidth: 150,
    headerAlign: 'center',
    align: 'center',
  },
  { field: 'id', headerName: 'api 이름', minWidth: 360 },
  {
    field: '대충설명',
    headerName: '설명',
    minWidth: 260,
  },
  { field: '준다', headerName: '전송', minWidth: 600 },
  { field: '받는다', headerName: '수신', minWidth: 750 },
];

export const DataportalApi: React.FC<Props> = () => {
  const a = 1;
  const columns = ['화면', 'api 이름', '전송', '수신', '설명'];
  const rows = Dataportalapi;
  return (
    <>
      {/* <TableContainer>
        <Table size='small' sx={{ mt: '2rem' }}>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <ColumsCell
                  key={idx.toString()}
                  onClick={evt => console.log(evt.currentTarget.innerText)}
                >
                  {column}
                </ColumsCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow key={idx.toString()}>
                <RowsCell>{row.view}</RowsCell>
                <RowsCell>{row.id}</RowsCell>
                <RowsCell>{row.준다}</RowsCell>
                <RowsCell>{row.받는다}</RowsCell>
                <RowsCell>{row.대충설명}</RowsCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <SwGridTableTest columns={columnss} rows={rows} />
      {/* <Box sx={{ height: '100vh', width: '100vw' }}>
        <SwTable columns={columnss} rows={rows} />
      </Box> */}
    </>
  );
};
