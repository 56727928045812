import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import moment from 'moment';
import { useSession, xapi } from '../../../hooks/session';
import { debouncehook } from '../../../hooks/debouncehook';
import {
  AddGroupTextField,
  CreatePaper,
  DraggablePaper,
  RowsCell,
  MultilineTextField,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { DataportalMultiConnect } from '../../commonComponent/DataportalMultiConnect';
import useLocalStorage from '../../../hooks/storage';
import {
  checkNaN,
  commaFormat,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  MarginButton,
} from './ContentManageStyles';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { RESULT_OK } from '../../../common/resultCode';

import { uploadFile } from '../../../common/api/ApiUpload';
import { SwSearchTarget } from '../../commonComponent/SwSearchTarget';
import { CommunityConnect } from '../../commonComponent/CommunityConnect';
import { SwSelectTags } from '../../commonComponent/SwSelectTags';
import { registerContentManageCategory } from '../../../common/api/ApiContentManage';

interface Props {
  contentManageInfo: any;
  setContentManageInfo: Dispatch<React.SetStateAction<any>>;
  reloadContentManage: (id: any) => void;
}

const guidetool = {
  title: '소제목',
  contents: '안내내용',
  image: '',
  imageFile: null,
  uploadUrl: '',
};

const MAX_GUIDE_PAGE = 6;

export const ContentManageCategoryForm: React.FC<Props> = ({
  contentManageInfo,
  setContentManageInfo,
  reloadContentManage,
}) => {
  const location = useLocation();
  const [categoryId, setCategoryId] = useState<string>('');
  const [parentCategoryId, setParentCategoryId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [status, setStatus] = useState<number>(0);
  const [seq, setSeq] = useState<number>(0);
  const [selectedContentManage, setSelectedContentManage] =
    useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  // registerContentManageCategory

  const {
    mutate: registerContentManageCategoryMutation,
    isError: isMutateError,
  } = useMutation(registerContentManageCategory, {
    onSuccess: res => {
      console.log('registerContentManageCategory res:', res);
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('저장되었습니다.');
        reloadContentManage({
          id: categoryId,
          parentCategoryId: parentCategoryId,
        });
      } else {
        setAlertOpen(true);
        setAlertMessage(
          `저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '프로그램을 생성하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const saveData = () => {
    const newData: any = {
      categoryId: categoryId,
      parentCategoryId: parentCategoryId,
      type:
        contentManageInfo.type !== undefined ? contentManageInfo.type : null,
      subType:
        contentManageInfo.subType !== undefined
          ? contentManageInfo.subType
          : null,
      depth:
        contentManageInfo.depth !== undefined ? contentManageInfo.depth : 1,
      title: title,
      description: description,
      status: status,
      seq: seq,
    };
    // if (
    //   (contentManageInfo.rewardCount === 0 || contentManageInfo.rewardCount === null) &&
    //   rewardCount === 0 &&
    //   contentManageInfo.contentManageType !== 5
    // ) {
    //   setOpenSnackbar(true);
    //   setAlertMessage('리워드 정보를 입력해주세요');
    //   return;
    // }
    console.log('registerContentManageCategoryMutation:', newData);
    registerContentManageCategoryMutation(newData);
  };

  const showError = (msg: string) => {
    setOpenSnackbar(true);
    setAlertMessage(msg);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      console.log('contentManageInfo -> :', contentManageInfo);
    }
  };

  useEffect(() => {
    console.log('contentManageInfo -> :', contentManageInfo);
    if (contentManageInfo !== undefined && contentManageInfo !== null) {
      if (contentManageInfo.save) {
        saveData();
      }
      setCategoryId(
        contentManageInfo.categoryId !== undefined &&
          contentManageInfo.categoryId !== null
          ? contentManageInfo.categoryId
          : newUuidId()
      );
      setParentCategoryId(
        contentManageInfo.parentCategoryId !== undefined &&
          contentManageInfo.parentCategoryId !== null
          ? contentManageInfo.parentCategoryId
          : ''
      );
      setTitle(contentManageInfo.title);
      setDescription(contentManageInfo.description);
      setStatus(contentManageInfo.status);
      setSeq(contentManageInfo.seq);
    }
    // if (contentManageInfo) {
    //   console.log('contentManageInfo -> :', contentManageInfo);
    // } else {
    //   location.pathname.includes('create')
    //     ? setSelectedContentManage(true)
    //     : setSelectedContentManage(false);
    // }
  }, [contentManageInfo]);
  return (
    <>
      <CreatePaper>
        <Box>
          <Typography className='title'>카테고리 이름</Typography>
          <TextField
            sx={{ width: '48rem' }}
            value={title}
            onChange={(
              evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              if (
                !(contentManageInfo.type === 2 && contentManageInfo.depth === 2)
              )
                setTitle(evt.target.value.substring(0, 30));
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{title?.length} / 30</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>카테고리 설명</Typography>
          </Box>
          <TextField
            value={description}
            fullWidth
            multiline
            rows={5}
            sx={{ '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' } }}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setDescription(evt.target.value)}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_between'>
            <Typography className='title'>순서</Typography>
          </Box>
          <TextField
            value={seq}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setSeq(checkNaN(evt.target.value, seq))}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>상태</Typography>
          <TextField
            sx={{ width: '12rem' }}
            select
            defaultValue={1}
            value={status}
            onChange={(
              evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => setStatus(Number(evt.target.value))}
          >
            <MenuItem key={0} value={0}>
              Close
            </MenuItem>
            <MenuItem key={1} value={1}>
              Open
            </MenuItem>
          </TextField>
          <Divider />
        </Box>
      </CreatePaper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => setAlertOpen(false)}
        title={alertTitle}
        contents={alertMessage}
      />
    </>
  );
};
