import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import React, {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { useSession } from '../../../hooks/session';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { DraggablePaper } from '../../styles/Styles';
import { KEY_CHALLENGE_AUDIO_CONTENT_LIST } from '../../../common/key';
import {
  deleteChallengeAudioContent,
  getChallengeAudioContentList,
  registerChallengeAudioContent,
  updateChallengeAudioContentCommonAd,
} from '../../../common/api/ApiChallenge';
import { uploadFile } from '../../../common/api/ApiUpload';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import {
  checkUrl,
  getAddUrl,
  timestamp2Localestring,
} from '../../../common/helper';
import { RESULT_OK } from '../../../common/resultCode';
import { SwSearchContentManageTarget } from '../../commonComponent/SwSearchContentManageTarget';

const MAX_GUIDE_PAGE = 6;
const DEFAULT_IMAGE = '/v2/common/audio_content_default.jpg';
const CONTENT_WIDTH = '100%';

const columns = ['순서', '타이틀', '분류', '제작', '공개일시', '삭제'];

const guidetool: any = {
  title: '소제목',
  contents: '안내내용',
  image: '',
  imageFile: null,
  uploadUrl: '',
};

const GridTitle = styled(Grid)({
  borderLeft: 'none',
  padding: '.7em',
});

const GridCell = styled(Grid)({
  borderLeft: 'none',
  padding: '.7em',
});

const Item = styled(Box)({
  textAlign: 'left',
  fontWeight: 500,
  fontSize: '1rem',
});

interface Props {
  challengeInfo: any;
  setChallengeInfo: Dispatch<React.SetStateAction<any>>;
  reloadChallenge: (id: string) => void;
}

export const ChallengeAudioContent: React.FC<Props> = ({
  challengeInfo,
  setChallengeInfo,
  reloadChallenge,
}) => {
  const queryClient = useQueryClient();

  const { loginVal } = useSession();
  const searchAudioContentRef = useRef<any>();
  const [audioContentId, setAudioContentId] = useState<string>('');
  const [challengeId, setChallengeId] = useState<string>('');
  const [contentList, setContentList] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<number>(-1);
  const [selectedSequence, setSelectedSequence] = useState<number>(-1);
  const [audioContentPath, setAudioContentPath] = useState<any>();
  const [audioContentTitle, setAudioContentTitle] = useState<string>('');
  const [audioContentCategory, setAudioContentCategory] = useState<string>('');
  const [audioContentProducer, setAudioContentProducer] = useState<string>('');
  const [hasPreAdvertisement, setHasPreAdvertisement] =
    useState<boolean>(false);
  const [hasPostAdvertisement, setHasPostAdvertisement] =
    useState<boolean>(false);
  const [guidePage, setGuidePage] = useState(1);
  const [guideLists, setGuideLists] = useState<any[]>([]);
  const [guideTitle, setGuideTitle] = useState<any>('');
  const [guideContent, setGuideContent] = useState<any>('');
  const [guideImage, setGuideImage] = useState<any>('');
  const [guideImageFile, setGuideImageFile] = useState<any>(null);
  const [contentImage, setContentImage] = useState<string>('');
  const [contentFile, setContentFile] = useState<any>(null);
  const [bannerImageInPlayer1, setBannerImageInPlayer1] = useState<string>('');
  const [bannerImageInPlayer2, setBannerImageInPlayer2] = useState<string>('');
  const [bannerImageInPlayer3, setBannerImageInPlayer3] = useState<string>('');
  const [bannerFileInPlayer1, setBannerFileInPlayer1] = useState<any>(null);
  const [bannerFileInPlayer2, setBannerFileInPlayer2] = useState<any>(null);
  const [bannerFileInPlayer3, setBannerFileInPlayer3] = useState<any>(null);
  const [bannerLink1, setBannerLink1] = useState<any>();
  const [bannerLink2, setBannerLink2] = useState<any>();
  const [bannerLink3, setBannerLink3] = useState<any>();
  const [selectAudioContentDate, setSelectAudioContentDate] = useState<any[]>(
    []
  );
  const [preAdvertisementFile, setPreAdvertisementFile] = useState<any>();
  const [postAdvertisementFile, setPostAdvertisementFile] = useState<any>();
  const [preCommonAdvertisementFile, setPreCommonAdvertisementFile] =
    useState<any>();
  const [postCommonAdvertisementFile, setPostCommonAdvertisementFile] =
    useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>(
    loginVal.value.user.imageUrlPrefix
  );
  const [mainPlayTime, setMainPlayTime] = useState<number>(0);
  const [preAdPlayTime, setPreAdPlayTime] = useState<number>(0);
  const [postAdPlayTime, setPostAdPlayTime] = useState<number>(0);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [targetTitle, setTargetTitle] = useState<string>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openCommonAd, setOpenCommonAd] = useState<boolean>(false);
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);

  const guideItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });
  var guideIndex = 0;

  const clickRowItem = (idx: number, newContentList: any[]) => {
    setSelectedRow(idx);

    setAudioContentId(newContentList[idx].audioContentId);
    setAudioContentTitle(newContentList[idx].title);
    setAudioContentCategory(newContentList[idx].category);
    setAudioContentProducer(newContentList[idx].producer);

    setContentImage(
      newContentList[idx].image !== null && newContentList[idx].image !== ''
        ? newContentList[idx].image
        : ''
    );
    setContentFile(null);

    setAudioContentPath(
      newContentList[idx].mainContent !== null &&
        newContentList[idx].mainContent !== ''
        ? { name: newContentList[idx].mainContent, file: null }
        : { name: '', file: null }
    );
    setMainPlayTime(newContentList[idx].mainPlayTime);

    setPreAdvertisementFile(
      newContentList[idx].preAdvertisement !== null &&
        newContentList[idx].preAdvertisement !== ''
        ? { name: newContentList[idx].preAdvertisement, file: null }
        : { name: '', file: null }
    );
    setHasPreAdvertisement(
      newContentList[idx].preAdvertisement !== null &&
        newContentList[idx].preAdvertisement !== ''
    );
    setPreAdPlayTime(newContentList[idx].preAdPlayTime);

    setPostAdvertisementFile(
      newContentList[idx].postAdvertisement !== null &&
        newContentList[idx].postAdvertisement !== ''
        ? { name: newContentList[idx].postAdvertisement, file: null }
        : { name: '', file: null }
    );
    setHasPostAdvertisement(
      newContentList[idx].postAdvertisement !== null &&
        newContentList[idx].postAdvertisement !== ''
    );
    setPostAdPlayTime(newContentList[idx].postAdPlayTime);

    setSelectAudioContentDate(
      newContentList[idx].openDate !== null
        ? [timestamp2Localestring(newContentList[idx].openDate, 1000)]
        : []
    );

    setGuidePage(1);

    setGuideTitle('');
    setGuideContent('');
    const newDescription = JSON.parse(newContentList[idx].description);
    const newGuideList =
      newDescription &&
      newDescription.map((item: any) => {
        const newGuide = {
          idx: item.idx,
          title: item.title,
          contents: item.description,
          image: item.image,
          imageFile: null,
        };
        return newGuide;
      });
    setGuideLists(
      newGuideList === undefined || newGuideList === null ? [] : newGuideList
    );
    setGuideImage('');
    setGuideImageFile(null);

    const newPlayerBanner =
      newContentList[idx].playerBannerImage !== null
        ? JSON.parse(newContentList[idx].playerBannerImage)
        : [];
    if (newPlayerBanner.length > 0) {
      setBannerImageInPlayer1(newPlayerBanner[0].image || '');
      setBannerLink1(newPlayerBanner[0].link || '');
    } else {
      setBannerImageInPlayer1('');
      setBannerLink1('');
    }
    setBannerFileInPlayer1(null);
    if (newPlayerBanner.length > 1) {
      setBannerImageInPlayer2(newPlayerBanner[1].image || '');
      setBannerLink2(newPlayerBanner[1].link || '');
    } else {
      setBannerImageInPlayer2('');
      setBannerLink2('');
    }
    setBannerFileInPlayer2(null);
    if (newPlayerBanner.length > 2) {
      setBannerImageInPlayer3(newPlayerBanner[2].image || '');
      setBannerLink3(newPlayerBanner[2].link || '');
    } else {
      setBannerImageInPlayer3('');
      setBannerLink3('');
    }
    setBannerFileInPlayer3(null);
  };

  const clickRow = (event: any, idx: number) => {
    if (event.target.classList.contains('MuiButton-root')) {
      return;
    }
    clickRowItem(idx, contentList);
  };

  const {
    data,
    isLoading,
    refetch: challengeAudioContentRefetch,
  } = useQuery(
    KEY_CHALLENGE_AUDIO_CONTENT_LIST,
    () => {
      console.log('challengeId: ', challengeId);
      const newData: any = {
        challengeId: challengeId,
      };
      if (challengeId.length > 0) return getChallengeAudioContentList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (
          res !== null &&
          res.audioContent !== undefined &&
          res.audioContent !== null
        ) {
          setContentList(res.audioContent);
          const tmpIdx = res.audioContent
            .map((item: any, idx: number) =>
              item.audioContentId === audioContentId ? idx : -1
            )
            .filter((item: number) => item !== -1);
          if (tmpIdx !== null && tmpIdx.length > 0) {
            clickRowItem(tmpIdx[0], res.audioContent);
          }
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = () => {
    queryClient.prefetchQuery(KEY_CHALLENGE_AUDIO_CONTENT_LIST, () => {
      const newData: any = {
        challengeId: challengeId,
      };
      if (challengeId.length > 0) return getChallengeAudioContentList(newData);
      return null;
    });
  };

  const {
    mutate: registerChallengeAudioContentMutation,
    isError: isMutateError,
  } = useMutation(registerChallengeAudioContent, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('오디오 콘텐츠가 등록되었습니다.');
        prefetch();
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `오디오 콘텐츠를 등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '오디오 콘텐츠 등록이 실패했습니다. 다시 시도해주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: deleteChallengeAudioContentMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteChallengeAudioContent, {
    onSuccess: () => {
      setOpenMore(0);
      setOpenSnackbar(true);
      setDialogMessage('오디오 콘텐츠가 삭제되었습니다.');
      reloadChallenge(challengeId);
      prefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '오디오 콘텐츠를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const {
    mutate: updateChallengeAudioContentCommonAdMutation,
    isError: isUpdateMutateError,
  } = useMutation(updateChallengeAudioContentCommonAd, {
    onSuccess: () => {
      setOpenMore(0);
      setOpenSnackbar(true);
      setDialogMessage('광고를 공통 적용했습니다.');
      reloadChallenge(challengeId);
      prefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '광고를 공통 적용하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const addChallengeAudioContent = (
    newMainImageUrl: string,
    newAudioContentPathUrl: string,
    newPreAdvertisementUrl: string,
    newPostAdvertisementUrl: string,
    newGuideList: any,
    newBannerPlayerList: any,
    newMainPlayTime: number,
    newPreAdPlayTime: number,
    newPostAdPlayTime: number
  ) => {
    const newChallengeBannerImage: string[] = [];
    const newPlayerBannerImage: string[] = [];
    const newDates = selectAudioContentDate.map(contentDate => {
      const tmpDate = new Date(contentDate);
      return tmpDate.getTime() / 1000;
    });
    const newImage =
      contentFile !== null
        ? newMainImageUrl
        : contentImage.length > 0
        ? contentImage
        : '';

    const newBannerPlayer: any[] = [];
    if (bannerFileInPlayer1 !== null)
      newBannerPlayer.push({
        image: newBannerPlayerList[0],
        link: checkUrl(bannerLink1),
        seq: 1,
      });
    else if (bannerImageInPlayer1.length > 0)
      newBannerPlayer.push({
        image: bannerImageInPlayer1,
        link: checkUrl(bannerLink1),
        seq: 1,
      });

    if (bannerFileInPlayer2 !== null)
      newBannerPlayer.push({
        image: newBannerPlayerList[1],
        link: checkUrl(bannerLink2),
        seq: 2,
      });
    else if (bannerImageInPlayer2.length > 0)
      newBannerPlayer.push({
        image: bannerImageInPlayer2,
        link: checkUrl(bannerLink2),
        seq: 2,
      });

    if (bannerFileInPlayer3 !== null)
      newBannerPlayer.push({
        image: newBannerPlayerList[2],
        link: checkUrl(bannerLink3),
        seq: 3,
      });
    else if (bannerImageInPlayer3.length > 0)
      newBannerPlayer.push({
        image: bannerImageInPlayer3,
        link: checkUrl(bannerLink3),
        seq: 3,
      });

    const newDescription =
      newGuideList.length > 0
        ? newGuideList.map((item: any, idx: number) => {
            const newData = {
              idx: `${idx + 1}`.toString(),
              title: item.title,
              description: item.contents,
              image: item.uploadUrl !== null ? item.uploadUrl : '',
            };
            return newData;
          })
        : [];

    const newData: any = {
      audioContentId: audioContentId,
      category: audioContentCategory,
      description: JSON.stringify(newDescription),
      image: newImage !== '' ? newImage : DEFAULT_IMAGE,
      mainContent: newAudioContentPathUrl,
      openDate: selectAudioContentDate.length !== 0 ? newDates[0] : 0,
      playerBannerImage:
        newBannerPlayer.length > 0 ? JSON.stringify(newBannerPlayer) : '',
      postAdvertisement: hasPostAdvertisement ? newPostAdvertisementUrl : '',
      preAdvertisement: hasPreAdvertisement ? newPreAdvertisementUrl : '',
      producer: audioContentProducer,
      title: audioContentTitle,
      mainPlayTime: newMainPlayTime,
      preAdPlayTime: hasPreAdvertisement ? newPreAdPlayTime : 0,
      postAdPlayTime: hasPostAdvertisement ? newPostAdPlayTime : 0,
    };
    const newAudioContent: any = {
      challengeId: challengeId,
      audioContents: [newData],
    };
    registerChallengeAudioContentMutation(newAudioContent);
  };

  const resetAudioContent = () => {
    setSelectedRow(-1);
    setAudioContentId(newUuidId());
    setAudioContentTitle('');
    setAudioContentCategory('');
    setAudioContentPath({ name: '' });
    setAudioContentProducer('');
    setPreAdvertisementFile(null);
    setPreCommonAdvertisementFile(null);
    setPostAdvertisementFile(null);
    setPostCommonAdvertisementFile(null);
    setHasPostAdvertisement(false);
    setHasPreAdvertisement(false);
    setSelectAudioContentDate([]);
    setMainPlayTime(0);
    setPreAdPlayTime(0);
    setPostAdPlayTime(0);
    setContentFile(null);
    setContentImage('');
    setContentImage('');
    setContentFile(null);
    setBannerImageInPlayer1('');
    setBannerFileInPlayer1(null);
    setBannerImageInPlayer2('');
    setBannerFileInPlayer2(null);
    setBannerImageInPlayer3('');
    setBannerFileInPlayer3(null);

    setGuidePage(1);
    setGuideTitle('');
    setGuideContent('');
    setGuideImage('');
    setGuideImageFile(null);
    setGuideLists([]);
  };

  const removeAudioContent = () => {
    const newData = {
      challengeId: challengeId,
      audioContentId: contentList[selectedRow].audioContentId,
    };
    deleteChallengeAudioContentMutation(newData);
  };

  function addGuideInfo() {
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = guideLists;
      const newGuide = guideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuide,
        title: newTitle,
        contents: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      return newGuideLists;
    }
    return [];
  }

  const onChangeChallengeGuideView = (_event: any, newguide: number) => {
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }
    setGuidePage(newguide);
    setGuideImage(guideLists[newguide - 1]?.image);
    setGuideImageFile(guideLists[newguide - 1]?.imageFile);
  };

  const addChallengeGuideView = () => {
    if (guideLists.length === MAX_GUIDE_PAGE) {
      setDialogMessage(
        '오디오 콘텐츠 안내 내용은 최대 6까지 추가할 수 있습니다.'
      );
      setOpenAlert(true);
      return;
    }

    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      const newGuide = [...newGuideLists, guidetool];
      setGuideLists(newGuide);
      setGuideImage('');
      setGuideImageFile(null);
      setGuidePage(guidePage + 1);
    }
  };

  const ChangePageContents = () => {
    setGuideTitle(
      guideLists[guidePage - 1] ? guideLists[guidePage - 1].title : '소제목'
    );
    setGuideContent(
      guideLists[guidePage - 1]
        ? guideLists[guidePage - 1].contents
        : '안내내용'
    );

    const newImage = guideLists[guidePage - 1]
      ? guideLists[guidePage - 1].image
      : '';
    setGuideImage(newImage);
  };

  const updateChallengeGuideView = () => {
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newTitle = guideTitle;
      const newContents = guideContent;
      const newGuideLists = [...guideLists];
      const newGuide = newGuideLists[guidePage - 1];
      newGuideLists[guidePage - 1] = {
        ...newGuide,
        title: newTitle,
        contents: newContents,
        image: guideImage,
        imageFile: guideImageFile,
        uploadUrl: '',
      };
      setGuideLists(newGuideLists);
    }
  };

  const removeChallengeGuideView = () => {
    console.log(guidePage);
    ChangePageContents();
    const newGuide = guideLists.filter((_guide, idx) => idx !== guidePage - 1);
    setGuideLists(newGuide);
    newGuide.length < guidePage && setGuidePage(guidePage - 1);
    // setGuidePage(guidePage);
    if (newGuide.length === 0) {
      setGuideTitle('');
      setGuideContent('');
      setGuidePage(1);
    }
  };

  //
  // Drag & Drop
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: guideLists,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setGuideLists(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setGuideLists(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    guideItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    guideItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    guideIndex = Number(evt.currentTarget.dataset.position);
    setGuidePage(guideIndex + 1);
    setListEvent({
      ...listEvent,
      clicked: newContain ? guideIndex : null,
    });
    setGuideTitle(guideLists[guideIndex].title);
    setGuideContent(guideLists[guideIndex].contents);
    setGuideImage(guideLists[guideIndex].image);
    setGuideImageFile(guideLists[guideIndex].imageFile);
  };
  // End

  async function uploadMultipleFiles() {
    var newUploadUrl = '';
    if (contentFile !== null) {
      const newUploadFile: any = await uploadFile('challenge', contentFile);
      newUploadUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newAudioContentPathUrl = '';
    var newMainPlayTime = mainPlayTime;
    if (audioContentPath.file !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        audioContentPath.file
      );
      newAudioContentPathUrl = newUploadFile.data.uploadUrl.file.path;
      newMainPlayTime = newUploadFile.data.uploadUrl.file.duration;
    } else {
      newAudioContentPathUrl = audioContentPath.name || '';
    }

    var newPreAdvertisementUrl = '';
    var newPreAdPlayTime = preAdPlayTime;
    if (hasPreAdvertisement && preAdvertisementFile.file !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        preAdvertisementFile.file
      );
      newPreAdvertisementUrl = newUploadFile.data.uploadUrl.file.path;
      newPreAdPlayTime = newUploadFile.data.uploadUrl.file.duration;
    } else {
      newPreAdvertisementUrl =
        preAdvertisementFile !== null ? preAdvertisementFile.name : '';
    }

    var newPostAdvertisementUrl = '';
    var newPostAdPlayTime = postAdPlayTime;
    if (hasPostAdvertisement && postAdvertisementFile.file !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        postAdvertisementFile.file
      );
      newPostAdvertisementUrl = newUploadFile.data.uploadUrl.file.path;
      newPostAdPlayTime = newUploadFile.data.uploadUrl.file.duration;
    } else {
      newPostAdvertisementUrl =
        postAdvertisementFile !== null ? postAdvertisementFile.name : '';
    }

    const newGuideList = await Promise.all(
      guideLists.map(async (item: any, idx: number) => {
        var imageUrl = null;
        var newUploadFile: any = null;
        if (item.imageFile !== null) {
          newUploadFile = await uploadFile('challenge', item.imageFile);
        }

        return {
          title: item.title,
          contents: item.contents,
          image:
            item.image === null || item.image === undefined ? '' : item.image,
          imageFile: item.imageFile,
          uploadUrl:
            item.imageFile !== null
              ? newUploadFile.data.uploadUrl.file.path
              : null,
        };
      })
    );

    const newPlayerUploadUrl: string[] = [];
    if (bannerFileInPlayer1 !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        bannerFileInPlayer1
      );
      newPlayerUploadUrl.push(newUploadFile.data.uploadUrl.file.path);
    } else {
      newPlayerUploadUrl.push(
        bannerImageInPlayer1 !== '' ? bannerImageInPlayer1 : ''
      );
    }

    if (bannerFileInPlayer2 !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        bannerFileInPlayer2
      );
      newPlayerUploadUrl.push(newUploadFile.data.uploadUrl.file.path);
    } else {
      newPlayerUploadUrl.push(
        bannerImageInPlayer2 !== '' ? bannerImageInPlayer2 : ''
      );
    }

    if (bannerFileInPlayer3 !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        bannerFileInPlayer3
      );
      newPlayerUploadUrl.push(newUploadFile.data.uploadUrl.file.path);
    } else {
      newPlayerUploadUrl.push(
        bannerImageInPlayer3 !== '' ? bannerImageInPlayer3 : ''
      );
    }

    addChallengeAudioContent(
      newUploadUrl,
      newAudioContentPathUrl,
      newPreAdvertisementUrl,
      newPostAdvertisementUrl,
      newGuideList,
      newPlayerUploadUrl,
      newMainPlayTime,
      newPreAdPlayTime,
      newPostAdPlayTime
    );
  }

  const checkChallengeAudioContent = () => {
    // Guide 마지막 페이지 수정사항 저장
    if (guideTitle.length > 0 && guideContent.length > 0) {
      const newGuideLists = addGuideInfo();
      setGuideLists(newGuideLists);
    }

    uploadMultipleFiles();
  };

  async function uploadCommonAdvertisement() {
    var newPreAdvertisementUrl = '';
    if (preCommonAdvertisementFile.file !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        preCommonAdvertisementFile.file
      );
      newPreAdvertisementUrl = newUploadFile.data.uploadUrl.file.path;
    }

    var newPostAdvertisementUrl = '';
    if (postCommonAdvertisementFile.file !== null) {
      const newUploadFile: any = await uploadFile(
        'challenge',
        postCommonAdvertisementFile.file
      );
      newPostAdvertisementUrl = newUploadFile.data.uploadUrl.file.path;
    }

    const newData: any = {
      challengeId: challengeId,
      preAdvertisement: newPreAdvertisementUrl,
      postAdvertisement: newPostAdvertisementUrl,
    };
    updateChallengeAudioContentCommonAdMutation(newData);
  }

  const updateCommonAdvertisement = () => {
    uploadCommonAdvertisement();
  };

  const searchAudioContent = () => {
    setTargetTitle('콘텐츠');
    setSearchKey('audio');
    setSearchWord(searchAudioContentRef.current?.value);
    setOpenSearchResult(true);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      searchAudioContent();
    }
  };

  useEffect(() => {
    console.log('searchResult :', searchResult);
    if (searchResult !== undefined && searchResult !== null) {
      setAudioContentId(searchResult.contentId);
      setAudioContentTitle(searchResult.title);
      setAudioContentCategory(searchResult.categoryTitle);
      setAudioContentProducer(searchResult.source);

      setContentImage(
        searchResult.thumbImage !== null && searchResult.thumbImage !== ''
          ? searchResult.thumbImage
          : ''
      );
      setContentFile(null);

      setAudioContentPath(
        searchResult.audioContentPath !== null &&
          searchResult.audioContentPath !== ''
          ? { name: searchResult.audioContentPath, file: null }
          : { name: '', file: null }
      );
      setMainPlayTime(searchResult.playTime);
      setSearchResult(null);
    }
  }, [searchResult]);
  searchResult;
  useEffect(() => {
    if (guideLists.length > 0) ChangePageContents();
  }, [guidePage, guideLists]);

  useEffect(() => {
    challengeAudioContentRefetch();
  }, [challengeId]);

  useEffect(() => {
    if (challengeInfo) {
      setChallengeId(challengeInfo.challengeId);
    }
    resetAudioContent();
  }, [challengeInfo]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Box>
        <Box className='flex_between' sx={{ width: CONTENT_WIDTH }}>
          <Typography className='title'>오디오 콘텐츠</Typography>
          <Button
            color='primary'
            variant='outlined'
            onClick={() => {
              setOpenCommonAd(true);
            }}
          >
            광고 공통 적용
          </Button>
        </Box>
      </Box>
      <Paper sx={{ width: CONTENT_WIDTH }}>
        <Box>
          <Typography sx={{ m: '1rem 0 ' }}>콘텐츠 목록</Typography>
          <TableContainer
            sx={{
              p: '0 1rem',
              height: '33vh',
              overflowY: 'auto',
            }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column, idx) => (
                    <TableCell
                      key={`audio_content_head_${idx}`}
                      sx={{ p: '.4rem .6rem' }}
                    >
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {contentList?.map((row: any, idx: number) => (
                  <TableRow
                    key={`audio_content_body_${idx}`}
                    className={
                      selectedRow === idx ? 'selected_row' : 'whitecolor'
                    }
                    onClick={event => clickRow(event, idx)}
                  >
                    <TableCell>{idx + 1}</TableCell>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.category}</TableCell>
                    <TableCell>{row.producer}</TableCell>
                    <TableCell>
                      {row.openDate !== null
                        ? timestamp2Localestring(row.openDate * 1000)
                        : '미지정'}
                    </TableCell>
                    <TableCell
                      className='removeButton'
                      sx={{ p: '.1rem .6rem' }}
                    >
                      <Button
                        color='info'
                        variant='contained'
                        onClick={() => {
                          setSelectedSequence(idx);
                          setOpenMore(2);
                        }}
                      >
                        삭제
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
      <Divider />
      <Box className='flex_start'>
        <Typography className='title' sx={{ mt: '1rem' }}>
          콘텐츠 불러오기
        </Typography>
        <TextField
          placeholder='콘텐츠 이름, 아이디 검색'
          sx={{ ml: '.5rem', mt: '1rem', width: '21rem' }}
          inputRef={searchAudioContentRef}
          onKeyDown={evt => {
            handleKeyDown(evt, 'audio');
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    searchAudioContent();
                  }}
                  sx={{ pr: 0 }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Divider />
      <Box>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
        >
          <Box>
            <Typography className='title'>콘텐츠 순서</Typography>
            <TextField
              sx={{ width: '8rem' }}
              type='number'
              value={selectedRow + 1}
            />
          </Box>
          <SwUuidCreate
            disabled={false}
            title='콘텐츠 아이디'
            id={audioContentId}
            setId={setAudioContentId}
          />
        </Stack>
        <Divider />
      </Box>
      <Box className='flex_start' sx={{ width: CONTENT_WIDTH }}>
        <Box sx={{ width: '100%' }}>
          <Box>
            <Grid
              container
              sx={{
                width: '100%',
              }}
            >
              <GridTitle item xs={12}>
                <Item>콘텐츠 등록</Item>
              </GridTitle>
              <GridCell item xs={6}>
                <Box className='flex_between'>
                  <TextField
                    fullWidth
                    placeholder='`등록`버튼 선택'
                    value={audioContentPath?.name}
                  />
                  <Button
                    sx={{ ml: '1rem', mr: '1rem' }}
                    color='info'
                    variant='contained'
                    onClick={() => {
                      const newFile =
                        document.getElementById('audio_content_file');
                      newFile?.click();
                    }}
                  >
                    등록
                  </Button>
                  <input
                    className='nonedisplay'
                    id='audio_content_file'
                    accept='audio/*'
                    type='file'
                    onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                      if (evt.target.files) {
                        const newData: any = {
                          name: evt.target.files[0].name,
                          file: evt.target.files[0],
                        };
                        setAudioContentPath(newData);
                      }
                    }}
                  />
                </Box>
                <Typography className=''>
                  {' '}
                  play time :{' '}
                  {mainPlayTime > 0 ? `${mainPlayTime} sec` : '미등록'}
                </Typography>
              </GridCell>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              sx={{
                width: '100%',
              }}
            >
              <GridTitle item xs={4}>
                <Item>타이틀</Item>
              </GridTitle>
              <GridTitle item xs={4}>
                <Item>분류</Item>
              </GridTitle>
              <GridTitle item xs={4}>
                <Item>제작</Item>
              </GridTitle>
              <GridCell item xs={4}>
                <TextField
                  fullWidth
                  placeholder='콘텐츠 타이틀 입력'
                  value={audioContentTitle}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setAudioContentTitle(event.target.value)}
                />
              </GridCell>
              <GridCell item xs={4}>
                <TextField
                  fullWidth
                  placeholder='분류 입력'
                  value={audioContentCategory}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setAudioContentCategory(event.target.value)}
                />
              </GridCell>
              <GridCell item xs={4}>
                <TextField
                  fullWidth
                  placeholder='제작처 입력'
                  value={audioContentProducer}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => setAudioContentProducer(event.target.value)}
                />
              </GridCell>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              sx={{
                width: '100%',
              }}
            >
              <GridTitle item xs={12}>
                <Item>광고 등록</Item>
              </GridTitle>
              <GridCell item xs={10}>
                <Box className='flex_start'>
                  <Box className='flex_start'>
                    <Checkbox
                      onChange={e => {
                        setHasPreAdvertisement(e.currentTarget.checked);
                      }}
                      checked={hasPreAdvertisement}
                    />
                    <Typography className=''>앞</Typography>
                    {hasPreAdvertisement && (
                      <Box>
                        <Box>
                          <TextField
                            sx={{ ml: '1rem', width: '30rem' }}
                            placeholder='`등록`버튼 선택'
                            value={preAdvertisementFile?.name}
                          />
                          <Button
                            sx={{ ml: '1rem', mr: '1rem' }}
                            color='info'
                            variant='contained'
                            onClick={() => {
                              const newFile = document.getElementById(
                                'audio_content_file_pre'
                              );
                              newFile?.click();
                            }}
                          >
                            등록
                          </Button>
                          <input
                            className='nonedisplay'
                            id='audio_content_file_pre'
                            accept='audio/*'
                            type='file'
                            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                              if (evt.target.files) {
                                const newData: any = {
                                  name: evt.target.files[0].name,
                                  file: evt.target.files[0],
                                };
                                setPreAdvertisementFile(newData);
                              }
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ ml: '1rem' }} className=''>
                            play time :{' '}
                            {preAdPlayTime > 0
                              ? `${preAdPlayTime} sec`
                              : '미등록'}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box className='flex_start'>
                    <Checkbox
                      onChange={e => {
                        setHasPostAdvertisement(e.currentTarget.checked);
                      }}
                      checked={hasPostAdvertisement}
                    />
                    <Typography className=''>뒤</Typography>
                    {hasPostAdvertisement && (
                      <Box>
                        <Box>
                          <TextField
                            sx={{ ml: '1rem', width: '30rem' }}
                            placeholder='`등록`버튼 선택'
                            value={postAdvertisementFile?.name}
                          />
                          <Button
                            sx={{ ml: '1rem', mr: '1rem' }}
                            color='info'
                            variant='contained'
                            onClick={() => {
                              const newFile = document.getElementById(
                                'audio_content_file_post'
                              );
                              newFile?.click();
                            }}
                          >
                            등록
                          </Button>
                          <input
                            className='nonedisplay'
                            id='audio_content_file_post'
                            accept='audio/*'
                            type='file'
                            onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                              if (evt.target.files) {
                                const newData: any = {
                                  name: evt.target.files[0].name,
                                  file: evt.target.files[0],
                                };
                                setPostAdvertisementFile(newData);
                              }
                            }}
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ ml: '1rem' }} className=''>
                            play time :{' '}
                            {postAdPlayTime > 0
                              ? `${postAdPlayTime} sec`
                              : '미등록'}
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </GridCell>
            </Grid>
          </Box>
          <Box>
            <Grid
              container
              sx={{
                width: '100%',
              }}
            >
              <GridTitle item xs={12}>
                <Item>공개 일시</Item>
              </GridTitle>
              <GridCell item xs={12}>
                <Box className='flex_start'>
                  <SwDatesPicker
                    dates={selectAudioContentDate}
                    setDates={setSelectAudioContentDate}
                    allowPastDate={false}
                    showButton={false}
                    maxLimit={1}
                  />
                  <Button
                    sx={{ ml: '1rem', mr: '1rem' }}
                    color='info'
                    variant='outlined'
                    onClick={() => {
                      setSelectAudioContentDate([]);
                    }}
                  >
                    Reset
                  </Button>
                </Box>
              </GridCell>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box>
          <Grid
            container
            sx={{
              width: '100%',
            }}
          >
            <GridTitle item xs={12}>
              <Item>콘텐츠 이미지</Item>
            </GridTitle>
            <GridCell item xs={12}>
              <Box>
                <SwFileSetting
                  setImage={setContentImage}
                  image={
                    contentFile !== null
                      ? contentImage
                      : contentImage !== ''
                      ? `${imageUrlPrefix}${contentImage}`
                      : ''
                  }
                  setFile={setContentFile}
                  file={contentFile}
                />
              </Box>
            </GridCell>
          </Grid>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box className='flex_between'>
          <Typography className='title'>콘텐츠 안내 내용 (선택)</Typography>
          <div>
            <Button onClick={updateChallengeGuideView}>저장</Button>
            <Button onClick={addChallengeGuideView}>추가</Button>
            {guideLists.length > 0 && (
              <Button onClick={removeChallengeGuideView}>삭제</Button>
            )}
          </div>
        </Box>
        <TextField
          sx={{ width: '16rem' }}
          value={guideTitle}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setGuideTitle(evt.target.value)}
        />
        <TextField
          value={guideContent}
          fullWidth
          multiline
          rows={5}
          sx={{ '& .MuiOutlinedInput-root': { height: 'auto', mt: '1rem' } }}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setGuideContent(evt.target.value)}
        />
        <SwFileSetting
          image={
            guideImageFile !== null
              ? guideImage
              : guideImage !== ''
              ? `${imageUrlPrefix}${guideImage}`
              : ''
          }
          setImage={setGuideImage}
          setFile={setGuideImageFile}
          file={guideImageFile}
        />
        <Typography className='title'>콘텐츠 안내 목록</Typography>
        {guideLists?.map((item: any, idx: number) => (
          <DraggablePaper
            key={idx.toString()}
            className='draggable'
            id={idx.toString()}
            variant='outlined'
            onDragStart={dragStart}
            onDragEnd={dragEnd}
            onDragOver={dragOver}
            onDrop={drop}
            onDragLeave={dragLeave}
            onDragEnter={dragEnter}
            draggable
            data-position={idx}
            onClick={selectList}
            sx={{ width: '50%' }}
          >
            <div>
              <span>{idx}. </span>
              <span>{item.title}</span>
            </div>
            <DragHandleIcon />
          </DraggablePaper>
        ))}
        <Divider />
      </Box>
      <Box>
        <Box>
          <Box className='flex_between' sx={{ width: '20rem' }}>
            <Typography className='title'>광고 배너(플레이어 내부)</Typography>
            <Typography className='title_normal'>
              권장 사이즈 652 * 163
            </Typography>
          </Box>
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 3rem 0' }}
              />
            }
            sx={{ mb: '1.5rem' }}
          >
            <Box sx={{ width: '25rem' }}>
              <SwFileSetting
                setImage={setBannerImageInPlayer1}
                image={
                  bannerFileInPlayer1 !== null
                    ? bannerImageInPlayer1
                    : bannerImageInPlayer1 !== ''
                    ? `${imageUrlPrefix}${bannerImageInPlayer1}`
                    : ''
                }
                setFile={setBannerFileInPlayer1}
                file={bannerFileInPlayer1}
                width='15rem'
                onDelete={() => setBannerLink1('')}
              />
            </Box>
            <Box sx={{ width: '25rem' }}>
              <SwFileSetting
                setImage={setBannerImageInPlayer2}
                image={
                  bannerFileInPlayer2 !== null
                    ? bannerImageInPlayer2
                    : bannerImageInPlayer2 !== ''
                    ? `${imageUrlPrefix}${bannerImageInPlayer2}`
                    : ''
                }
                setFile={setBannerFileInPlayer2}
                file={bannerFileInPlayer2}
                width='15rem'
                onDelete={() => setBannerLink2('')}
              />
            </Box>
            <Box sx={{ width: '25rem' }}>
              <SwFileSetting
                setImage={setBannerImageInPlayer3}
                image={
                  bannerFileInPlayer3 !== null
                    ? bannerImageInPlayer3
                    : bannerImageInPlayer3 !== ''
                    ? `${imageUrlPrefix}${bannerImageInPlayer3}`
                    : ''
                }
                setFile={setBannerFileInPlayer3}
                file={bannerFileInPlayer3}
                width='15rem'
                onDelete={() => setBannerLink3('')}
              />
            </Box>
          </Stack>
          <Stack
            direction={{ sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 3rem 0' }}
              />
            }
            sx={{ mb: '1.5rem' }}
          >
            <Box>
              <Box>
                <TextField
                  sx={{ width: '25rem' }}
                  placeholder='url를 입력해주세요'
                  value={bannerLink1}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setBannerLink1(getAddUrl(evt.target.value));
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <TextField
                  sx={{ width: '25rem' }}
                  placeholder='url를 입력해주세요'
                  value={bannerLink2}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setBannerLink2(getAddUrl(evt.target.value));
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Box>
                <TextField
                  sx={{ width: '25rem' }}
                  placeholder='url를 입력해주세요'
                  value={bannerLink3}
                  onChange={(
                    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setBannerLink3(getAddUrl(evt.target.value));
                  }}
                />
              </Box>
            </Box>
          </Stack>
        </Box>
      </Box>
      <Divider />
      <Box className='flex_between' sx={{ mt: '0rem' }}>
        <Box>
          <Button variant='outlined' onClick={() => resetAudioContent()}>
            신규작성
          </Button>
        </Box>
        <Box>
          <Button
            variant='contained'
            onClick={() => checkChallengeAudioContent()}
          >
            정보 저장
          </Button>
        </Box>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlert}
        onConfirm={() => setOpenAlert(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      {openMore === 2 && (
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography sx={{ mt: '0.7rem' }}>
              콘텐츠를 삭제하시겠습니까?
            </Typography>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => {
                removeAudioContent();
              }}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openCommonAd && (
        <Dialog
          open={openCommonAd}
          sx={{
            '& .MuiDialog-paper': {
              minHeight: '36vh',
              minWidth: '24vw',
            },
          }}
        >
          <DialogTitle>
            <Box className='flex_between'>
              <Box>
                <Typography>광고 공통 적용</Typography>
              </Box>
              <IconButton onClick={() => setOpenCommonAd(false)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <Divider sx={{ m: 0, p: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Box>
              <Typography className='title'>앞</Typography>

              <Box className='flex_start'>
                <TextField
                  fullWidth
                  placeholder='`등록`버튼 선택'
                  value={preCommonAdvertisementFile?.name}
                />
                <Button
                  sx={{ ml: '1rem', mr: '1rem' }}
                  color='info'
                  variant='contained'
                  onClick={() => {
                    const newFile = document.getElementById(
                      'audio_content_file_pre_popup'
                    );
                    newFile?.click();
                  }}
                >
                  등록
                </Button>
                <input
                  className='nonedisplay'
                  id='audio_content_file_pre_popup'
                  type='file'
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    if (evt.target.files) {
                      const newData: any = {
                        name: evt.target.files[0].name,
                        file: evt.target.files[0],
                      };
                      setPreCommonAdvertisementFile(newData);
                    }
                  }}
                />
              </Box>
            </Box>
            <Box>
              <Typography className='title'>뒤</Typography>

              <Box className='flex_start'>
                <TextField
                  fullWidth
                  placeholder='`등록`버튼 선택'
                  value={postCommonAdvertisementFile?.name}
                />
                <Button
                  sx={{ ml: '1rem', mr: '1rem' }}
                  color='info'
                  variant='contained'
                  onClick={() => {
                    const newFile = document.getElementById(
                      'audio_content_file_post_popup'
                    );
                    newFile?.click();
                  }}
                >
                  등록
                </Button>
                <input
                  className='nonedisplay'
                  id='audio_content_file_post_popup'
                  type='file'
                  onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                    if (evt.target.files) {
                      const newData: any = {
                        name: evt.target.files[0].name,
                        file: evt.target.files[0],
                      };
                      setPostCommonAdvertisementFile(newData);
                    }
                  }}
                />
              </Box>
            </Box>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenCommonAd(false)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => updateCommonAdvertisement()}
            >
              완료
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <SwSearchContentManageTarget
        title={targetTitle !== null ? targetTitle : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
    </Paper>
  );
};
