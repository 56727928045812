import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import MDEditor from '@uiw/react-md-editor';

import { MultilineTextField } from '../../styles/Styles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { IUploadFile } from '../../../models/common';
import {
  deleteDataPortalGuide,
  postDataPortalGuide,
  putDataPortalGuide,
} from '../../../common/api/ApiDataPortal';
import { timestamp2string } from '../../../common/helper';
import { SwSelectOnMenuMap } from '../../commonComponent/SwSelectOnMenuMap';
import { SwImageGalleryView } from '../../commonComponent/SwImageGalleryView';

const EDITOR_HEIGHT = 500;

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
  guide: any;
  menuMap: any;
}

export const GuideManageDrawup: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
  guide,
  menuMap,
}) => {
  const [guideIdx, setGuideIdx] = useState<string>('');
  const [categoryId, setCategoryId] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [hidden, setHidden] = useState<number>(0);
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [image, setImage] = useState<string>('');
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageFile, setImageFile] = useState<any>(null);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const { mutate: postDataPortalGuideMutate, isError: isMutateError } =
    useMutation(postDataPortalGuide, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('포털 설명서를 등록했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '포털 설명서 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const { mutate: putDataPortalGuideMutate, isError: isPutMutateError } =
    useMutation(putDataPortalGuide, {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('포털 설명서를 수정했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '포털 설명서 수정이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const registerPortalGuide = (newUploadUrl: string) => {
    const newData: any = {
      idx: Number(guideIdx),
      categoryId: guideIdx !== '' ? categoryId : selectedCategory.id,
      title: title,
      content: content,
      hidden: hidden,
    };

    if (guideIdx === '' && selectedCategory.id === '') {
      setOpenSnackbar(true);
      setDialogMessage('카테고리 선택 오류입니다.');
      return;
    }
    if (title === '') {
      setOpenSnackbar(true);
      setDialogMessage('제목을 입력헤주세요');
      return;
    }
    if (content === '') {
      setOpenSnackbar(true);
      setDialogMessage('내용을 입력헤주세요');
      return;
    }
    guideIdx !== ''
      ? putDataPortalGuideMutate(newData)
      : postDataPortalGuideMutate(newData);
  };

  const checkDataPortalGuide = () => {
    registerPortalGuide('');
  };

  const {
    mutate: deleteDataPortalGuideMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteDataPortalGuide, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setDialogMessage('포털 설명서를 삭제했습니다.');
      onConfirm();
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        '포털 설명서를 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteGuide = (idx: number) => {
    const newData: any = {
      idxList: [idx],
    };
    console.log(newData);
    deleteDataPortalGuideMutation(newData);
  };

  const doSelected = (node: any) => {
    setSelectedCategory(node);
  };

  useEffect(() => {
    if (open) {
      setGuideIdx(
        guide?.idx === undefined || guide?.idx === null ? '' : guide?.idx
      );
      setContent(guide?.content ? guide?.content : '');
      setTitle(guide?.title ? guide?.title : '');
      setCategoryId(guide?.id);
    } else {
      setGuideIdx('');
      setContent('');
      setTitle('');
      setCategoryId('');
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '80vw',
          },
        }}
      >
        <DialogTitle sx={{ pl: '24px !important' }}>
          <Box className='flex_between'>
            <span>
              {!edit &&
              (guide === undefined || guide === null || guide.idx === '')
                ? '포털 설명서 작성'
                : !edit && guide?.idx !== null
                ? '포털 설명서 수정'
                : '포털 설명서'}
            </span>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          {edit ? (
            <>
              <Typography variant='h4' sx={{ fontWeight: 500, mb: '.7rem' }}>
                {title}
              </Typography>
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                {`작성일자: ${timestamp2string(guide?.createDate, 1000)}`}
              </Typography>
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                {`공개: ${guide?.hidden === 1 ? '비공개' : '전체'}`}
              </Typography>
              <Divider />
              <Box className='markarea'>
                <div data-color-mode='light'>
                  <MDEditor.Markdown style={{ padding: 10 }} source={content} />
                </div>
              </Box>
            </>
          ) : (
            <Stack
              direction={{ xs: 'column', sm: 'column', md: 'row' }}
              divider={
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ p: 0, m: '.5rem 1rem 0' }}
                />
              }
            >
              <Box sx={{ width: '70%', p: '.5rem' }}>
                <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                  카테고리 분류
                </Typography>
                <SwSelectOnMenuMap menuMap={menuMap} onSelected={doSelected} />
                <Divider />
                <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                  제목
                </Typography>
                <TextField
                  fullWidth
                  value={title}
                  onChange={evt => {
                    setTitle(evt.target.value);
                  }}
                />
                <Divider />
                <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                  내용
                </Typography>
                <Box className='markarea'>
                  <div data-color-mode='light'>
                    <MDEditor
                      height={EDITOR_HEIGHT}
                      value={content}
                      onChange={event => setContent(event || '')}
                    />
                  </div>
                </Box>
                <Divider />
                <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                  공개
                </Typography>
                <TextField
                  select
                  sx={{ width: '10rem' }}
                  value={hidden}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setHidden(Number(event.target.value));
                  }}
                >
                  <MenuItem key={0} value={0}>
                    공개
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    비공개
                  </MenuItem>
                </TextField>
              </Box>
              <Box sx={{ width: '30%', p: '.5rem' }}>
                <SwImageGalleryView />
              </Box>
            </Stack>
          )}
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          {edit ? (
            <>
              <Button
                onClick={() => {
                  deleteGuide(guide?.idx);
                }}
                color='info'
                variant='outlined'
              >
                삭제
              </Button>
              <Button onClick={isEdit} color='info' variant='contained'>
                수정
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                onClick={() => checkDataPortalGuide()}
                color='info'
                variant='contained'
              >
                저장
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </>
  );
};
