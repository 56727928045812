import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { v4 as uuidv4 } from 'uuid';
import componentGeohash from 'latlon-geohash';
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwNaverMap } from '../../commonComponent/map/SwNaverMap';
import { SwWalkcourseMap } from '../../commonComponent/map/SwWalkcourseMap';
import { SwSearchWalkcourse } from '../../commonComponent/SwSearchWalkcourse';
import { SwBasicDialog } from '../../commonComponent/views/SwBasicDialog';
import { SwLoadGPX } from '../../commonComponent/SwLoadGPX';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import {
  getWalkcourseList,
  getWalkcourseDetailInfo,
  getWalkcourseRequestDetail,
  registerWalkcourse,
  updateWalkcoursePoint,
} from '../../../common/api/ApiWalkcourse';
import {
  getWalkwayDetailInfo,
  postWalkway,
  postMakeLocalAmd,
} from '../../../common/api/ApiWalkway';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  KEY_WALKWAY_DETAIL_INFO,
  KEY_WALKCOURSE_DETAIL_INFO,
  KEY_WALKCOURSE_REQUEST_DETAIL,
} from '../../../common/key';
import { IUploadFile } from '../../../models/common';
import {
  IWalkwayCommand,
  IWalkway,
  IWalkwayInfo,
  IContent,
} from '../../../models/walkway';
import { convertStep, generateUuidId } from '../../../common/helper';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { SwSelectAmd } from '../../commonComponent/SwSelectAmd';
import {
  DefaultWalkcouseDetail,
  IWalkcourse,
  IWalkcouseDetail,
} from '../../../models/walkcourse';
import { WalkcourseMap } from '../../challenge/walkcourse/WalkcourseMap';
import { RESULT_OK } from '../../../common/resultCode';
import { searchWalkcourseFlagState } from '../../../common/atom';

interface Props {
  walkwayCommand: IWalkwayCommand;
  setWalkwayCommand: Dispatch<React.SetStateAction<IWalkwayCommand>>;
  setReload: Dispatch<React.SetStateAction<string>>;
}

export const WalkwayCreate: React.FC<Props> = ({
  walkwayCommand,
  setWalkwayCommand,
  setReload,
}) => {
  const queryClient = useQueryClient();
  const walkcourseRef = useRef<any>();

  const [searchWalkcourseFlag, setSearchWalkcourseFlag] = useRecoilState(
    searchWalkcourseFlagState
  );

  const [localId, setLocalId] = useState<string>('');
  const [walkwayId, setWalkwayId] = useState<string>('');
  const [localWalkwayId, setLocalWalkwayId] = useState<string>('');
  const [contentId, setContentId] = useState<string>('');
  const [walkcourseId, setWalkcourseId] = useState<string>('');
  const [walkwayDetail, setWalkwayDetail] = useState<any>();
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [region, setRegion] = useState<any>([]);
  const [hasWalkcourse, setHasWalkcourse] = useState<boolean>(false);
  const [walkwayImage, setWalkwayImage] = useState<string>('');
  const [walkwayImageFile, setWalkwayImageFile] = useState<any>(null);
  const [walkcourseImage, setWalkcourseImage] = useState<string>('');
  const [title, setTitle] = useState<string>('');
  const [walkwayRoute, setWalkwayRoute] = useState<string>('');
  const [walkwayTag, setWalkwayTag] = useState<any>(['', '', '']);
  const [distance, setDistance] = useState<any>();
  const [estimatedSteps, setEstimatedSteps] = useState<any>();
  const [expectedMins, setExpectedMins] = useState<any>();
  const [recommended, setRecommended] = useState<number>(1);
  const [hidden, setHidden] = useState<number>(0);
  const [searchName, setSearchName] = useState<string>('');
  const [courseName, setCourseName] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [editor, setEditor] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [show, setShow] = useState<number>(0); // 0: 노출안함
  const [geohash, setGeohash] = useState<string>('');
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>();
  const [gpsData, setGpsData] = useState<any>([]);
  const [locationLimit, setLocationLimit] = useState<string[]>([]);
  const [mapInfo, setMapInfo] = useState<any>();
  const [regionInfo, setRegionInfo] = useState<any>();
  const [courseData, setCourseData] = useState<any>();
  const [walkcourseDetail, setWalkcourseDetail] = useState<IWalkcouseDetail>(
    DefaultWalkcouseDetail
  );
  const [refChallengeCount, setRefChallengeCount] = useState<number>(0);
  const [openGpx, setOpenGpx] = useState<boolean>(false);
  const [gpxDataArray, setGpxDataArray] = useState<any>([]);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: walkwayRefetch,
  } = useQuery(
    KEY_WALKWAY_DETAIL_INFO,
    () => {
      if (walkwayId !== null && walkwayId.length > 0) {
        return getWalkwayDetailInfo(walkwayId);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('setWalkwayDetail :', res);
        if (res !== null) setWalkwayDetail(res.walkwayDetail);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const walkwayDetailPrefetch = (newWalkwayId: string) => {
    queryClient.prefetchQuery(KEY_WALKWAY_DETAIL_INFO, () =>
      getWalkwayDetailInfo(newWalkwayId)
    );
  };

  const {
    data: detailData,
    isLoading: detailIsLoading,
    refetch: walkwayCourseDetailRefetch,
  } = useQuery(
    KEY_WALKCOURSE_DETAIL_INFO,
    () => {
      if (walkcourseId.length > 0) {
        return getWalkcourseDetailInfo(walkcourseId);
      }
      return null;
    },
    {
      onSuccess: res => {
        if (res !== null) {
          const tmpWalkwayRoute = res.coursePoint.map((point: any) => {
            const temp = [point.pointPtLat, point.pointPtLng];
            return temp;
          });
          const tmpRoute = res.coursePoint.map((point: any) => {
            const temp = { lat: point.pointPtLat, lng: point.pointPtLng };
            return temp;
          });
          console.log('point :', tmpRoute);
          setWalkwayRoute(tmpWalkwayRoute);
          setGpsData(tmpRoute);

          if (tmpRoute.length > 0) setHasWalkcourse(true);
          else setHasWalkcourse(false);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const walkwayCourseDetailPrefetch = (newWalkcourseId: string) => {
    queryClient.prefetchQuery(KEY_WALKCOURSE_DETAIL_INFO, () =>
      getWalkcourseDetailInfo(newWalkcourseId)
    );
  };

  const { refetch: walkcourseRequestDetailRefetch } = useQuery(
    KEY_WALKCOURSE_REQUEST_DETAIL,
    () => {
      console.log('walkcourseId:', walkcourseId);
      if (walkcourseId.length > 0)
        return getWalkcourseRequestDetail(walkcourseId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          const tmpRoute = res.creationRequestDetail.point.map((point: any) => {
            const temp = {
              lat: point.pointPtLat,
              lng: point.pointPtLng,
              radius: point.pointRadius,
            };
            return temp;
          });
          console.log('point :', tmpRoute);
          setGpsData(tmpRoute);
          if (tmpRoute.length > 0) setHasWalkcourse(true);
          else setHasWalkcourse(false);
          setRefChallengeCount(
            res.creationRequestDetail.course.refChallengeCount !== null
              ? res.creationRequestDetail.course.refChallengeCount
              : 0
          );

          const newCircleData = {
            lat: res.creationRequestDetail.course.courseCenterPointLat,
            lng: res.creationRequestDetail.course.courseCenterPointLng,
            radius: res.creationRequestDetail.course.courseCenterRadius,
          };

          const newCourseData = {
            gpsData: tmpRoute,
            circleData: newCircleData,
          };
          setCourseData(newCourseData);

          setWalkcourseDetail(res.creationRequestDetail);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newWalkCourseId: string) => {
    queryClient.prefetchQuery(KEY_WALKCOURSE_REQUEST_DETAIL, () =>
      getWalkcourseRequestDetail(newWalkCourseId)
    );
  };

  const { mutate: postWalkwayMutate, isError: isMutateError } = useMutation(
    postWalkway,
    {
      onSuccess: () => {
        setOpenSnackbar(true);
        setDialogMessage('워크웨이를 등록했습니다.');
        setReload(localWalkwayId);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '워크웨이 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    }
  );

  const deleteTag = (index: number) => {
    const newTag = walkwayTag.filter((_val: any, i: number) => i !== index);
    setWalkwayTag([...newTag]);
  };
  const addTag = () => {
    if (walkwayTag?.length > 7) {
      // setDialogTitle('워크코스 태그');
      // setDialogMessage('워크코스 태그는 8개까지 생성가능합니다.');
      // setOpenDialog(true);
      return;
    }
    setWalkwayTag([...walkwayTag, '']);
  };

  const onChangeTags = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newTag = evt.target.value;
    const tags = [...walkwayTag];
    tags[index] = newTag;
    setWalkwayTag(tags);
  };

  const searchWalkCourse = (newSearchWord: string) => {
    setSearchKey('walkcourse');
    setSearchWord(newSearchWord);
    setOpenSearchResult(true);
    // walkwayCoursePrefetch(newSearchWord);
  };

  const registerWalkway = (newUploadUrl: string) => {
    console.log('route :', walkwayDetail);
    const newLtLng = resultLatLng.split(' ');
    const newLa = newLtLng[0] ? Number(newLtLng[0]) : 0;
    const newLo = newLtLng[1] ? Number(newLtLng[1]) : 0;
    const newGeohash =
      geohash && geohash.length > 0
        ? geohash
        : componentGeohash.encode(newLa, newLo, 4);
    var newRoute: any[] = [];
    gpsData.length > 0
      ? gpsData.map((item: any) => {
          newRoute = [...newRoute, [item.lat, item.lng]];
        })
      : walkwayDetail !== null && walkwayDetail?.route !== ''
      ? (newRoute = walkwayDetail.route)
      : [];
    const newImage =
      walkwayImageFile !== null
        ? newUploadUrl
        : walkwayImage !== ''
        ? walkwayImage
        : '';

    const newContent: IContent = {
      contentId: contentId,
      imagePath: newImage,
      bgImages: [newImage],
      routeImagePath: walkcourseImage,
      tags: walkwayTag,
    };

    const newWalkcourseId =
      walkcourseId !== ''
        ? walkcourseId
        : walkwayDetail !== null && walkwayDetail.walkCourseId !== null
        ? walkwayDetail.walkCourseId
        : '';

    console.log('newImage: ', newImage);
    if (newImage === null || newImage.length === 0) {
      setDialogMessage('워크웨이 이미지를 선택해 주세요');
      setOpenSnackbar(true);
      return;
    }

    const newWalkwayInfo: IWalkwayInfo = {
      walkWayId: localWalkwayId,
      localId: localId,
      title: title,
      la: newLa,
      lo: newLo,
      location: location,
      route: JSON.stringify(newRoute),
      distance: distance,
      expectedMins: expectedMins,
      estimatedSteps: estimatedSteps,
      recommended: recommended,
      hidden: hidden,
      geohash: newGeohash,
      walkCourseId: newWalkcourseId,
    };
    const newWalkway: IWalkway = {
      walkWay: newWalkwayInfo,
      content: newContent,
    };
    console.log('newWalkway:', newWalkway);
    postWalkwayMutate(newWalkway);
  };

  const { mutate: uploadFileMutate, isError: isUploadMutateError } =
    useMutation(postUploadFile, {
      onSuccess: res => {
        console.log('uploadFile:', res);
        setUploadUrl(res.uploadUrl.file.path);
        console.log('uploadFile uploadUrl:', uploadUrl);
        registerWalkway(res.uploadUrl.file.path);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const {
    mutate: updateWalkcoursePointMutation,
    isError: isWalkcourseUpdateMutateError,
  } = useMutation(updateWalkcoursePoint, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        if (walkwayImageFile === null) {
          registerWalkway('');
        } else {
          const uploadfile: IUploadFile = {
            type: 'walkway',
            file: walkwayImageFile,
          };
          uploadFileMutate(uploadfile);
        }
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `길 코스를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        `길 코스를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const {
    mutate: registerWalkcourseMutation,
    isError: isWalkcourseMutateError,
  } = useMutation(registerWalkcourse, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        if (walkwayImageFile === null) {
          registerWalkway('');
        } else {
          const uploadfile: IUploadFile = {
            type: 'walkway',
            file: walkwayImageFile,
          };
          uploadFileMutate(uploadfile);
        }
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `길 코스를 저장하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        `길 코스를 저장하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.(${error})`
      );
    },
  });

  const updateWalkcourseInfo = () => {
    const newCourseCenterPointLng = mapInfo.circle?.lng;
    const newCourseCenterPointLat = mapInfo.circle?.lat;
    const newCourseCenterRadius = mapInfo.circle?.radius;

    const newPointList = mapInfo.points?.map((item: any, idx: number) => {
      const newPoint = {
        pointId: generateUuidId(),
        pointPtLat: item.lat,
        pointPtLng: item.lng,
        pointRadius: item.radius,
        pointSeq: idx,
      };
      return newPoint;
    });
    const newData: any = {
      courseId:
        walkcourseId !== ''
          ? walkcourseId
          : walkwayDetail !== null && walkwayDetail.walkCourseId !== null
          ? walkwayDetail.walkCourseId
          : '',
      courseCenterPointLat: newCourseCenterPointLat,
      courseCenterPointLng: newCourseCenterPointLng,
      courseCenterRadius: newCourseCenterRadius,
      courseDistance: distance,
      courseTime: expectedMins,
      courseStep: estimatedSteps === 0 ? convertStep(distance) : estimatedSteps,
      courseCal: 0,
      points: newPointList,
    };
    console.log('update newData:', newData);
    if (newData.courseId === '') {
      setOpenSnackbar(true);
      setDialogMessage('길 코스를 선택해 주세요.');
      return;
    }
    updateWalkcoursePointMutation(newData);
  };

  const registerWalkcourseInfo = () => {
    const newCourseCenterPointLng = mapInfo.circle?.lng;
    const newCourseCenterPointLat = mapInfo.circle?.lat;
    const newCourseCenterRadius = mapInfo.circle?.radius;
    const newCreator = '';

    const newPointList = mapInfo.points?.map((item: any, idx: number) => {
      const newPoint = {
        pointId: generateUuidId(),
        pointPtLat: item.lat,
        pointPtLng: item.lng,
        pointRadius: item.radius,
        pointSeq: idx,
      };
      return newPoint;
    });
    console.log('walkwayDetail:', walkwayDetail);
    const newData: IWalkcourse = {
      courseId:
        walkwayDetail !== null && walkwayDetail.walkCourseId !== null
          ? walkwayDetail.walkCourseId
          : generateUuidId(),
      courseName: `${title}(내주변) `,
      courseCal: 0,
      courseCenterPointLat: newCourseCenterPointLat,
      courseCenterPointLng: newCourseCenterPointLng,
      courseCenterRadius: newCourseCenterRadius,
      courseDeleted: 0,
      courseDistance: distance,
      courseImgpath: '/v2/walkcourse/map_img.jpg',
      courseTime: expectedMins,
      courseStep: estimatedSteps === 0 ? convertStep(distance) : estimatedSteps,
      creator: newCreator,
      editor: editor,
      points: newPointList,
      courseContent: content,
      courseShow: show,
      courseDifficulty: 3,
    };
    console.log('register newData:', newData);
    registerWalkcourseMutation(newData);
  };

  const checkWalkway = () => {
    var result = 0;

    if (location === null || location.length === 0) {
      result = 1;
      setDialogMessage('워크웨이 위치 정보를 입력해 주세요');
    } else if (title === null || title.length === 0) {
      result = 2;
      setDialogMessage('워크웨이 이름을 입력해 주세요');
    } else if (gpsData === null || gpsData.length === 0) {
      result = 3;
      setDialogMessage('길 코스를 선택해 주세요');
    }

    if (result !== 0) {
      setOpenSnackbar(true);
      return;
    }

    updateWalkcourseInfo();
  };

  const resetWalkway = () => {
    setWalkwayDetail(null);
    setLocalId(walkwayCommand.localId);
    const newId = () => uuidv4().replace(/-/gi, '').toUpperCase();
    setLocalWalkwayId(newId());
    console.log('localWalkwayId :', localWalkwayId);
    const newContentId = () => uuidv4().replace(/-/gi, '').toUpperCase();
    setContentId(newContentId());
    setTitle('');
    setResultLatLng('');
    setDistance('');
    setEstimatedSteps('');
    setExpectedMins('');
    setHidden(0);
    setRegion(['', '']);
    setLocation(' ');
    setGeohash('');
    setCourseName('');
    setWalkwayTag(['', '', '']);
    setWalkwayImage('');
    setWalkcourseImage('');
    setGpsData([]);
    setHasWalkcourse(false);
    setWalkwayImageFile(null);
  };

  const processGpx = () => {
    const newPoint = gpsData.map((element: any, idx: number) => [
      element.lat,
      element.lng,
    ]);
    setGpxDataArray(newPoint);
    setOpenGpx(true);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchWalkCourse(walkcourseRef.current?.value);
    }
  };

  useEffect(() => {
    console.log('detail :', walkwayDetail);
    if (walkwayDetail) {
      setWalkcourseId('');

      setLocalId(walkwayDetail.localId);
      setTitle(walkwayDetail.title);
      setWalkwayId(walkwayDetail.walkWayId);
      setContentId(walkwayDetail.contentId);
      setResultLatLng(`${walkwayDetail.la} ${walkwayDetail.lo}`);
      setDistance(walkwayDetail.distance);
      setEstimatedSteps(
        walkwayDetail.estimatedSteps !== 0
          ? walkwayDetail.estimatedSteps
          : convertStep(walkwayDetail.distance)
      );
      setExpectedMins(walkwayDetail.expectedMins);
      setHidden(walkwayDetail.hidden);
      setLocation(walkwayDetail.location);
      setCourseName('');
      const newTags =
        walkwayDetail.tags !== null
          ? JSON.parse(walkwayDetail.tags)
          : ['', '', ''];
      setWalkwayTag(newTags);
      setGeohash(walkwayDetail.geohash);

      setImageUrlPrefix(walkwayDetail.imageUrlPrefix);
      setWalkwayImageFile(null);

      const newImage =
        walkwayDetail.imagePath !== null ? walkwayDetail.imagePath : '';
      setWalkwayImage(newImage);
      const newCourseImage =
        walkwayDetail.routeImagePath !== null
          ? walkwayDetail.routeImagePath
          : '';
      setWalkcourseImage(newCourseImage);

      setWalkwayRoute(walkwayDetail.route);
      const newRoute = JSON.parse(walkwayDetail.route);
      const tmpRoute = newRoute.map((route: any) => {
        const temp = { lat: route[0], lng: route[1] };
        return temp;
      });
      setGpsData(tmpRoute);

      if (tmpRoute.length > 0) setHasWalkcourse(true);
      else setHasWalkcourse(false);

      const newWalkwayCommand: IWalkwayCommand = {
        localId: walkwayDetail.localId,
        walkwayId: walkwayDetail.walkWayId,
        reset: false,
        isSend: 0,
      };
      setWalkwayCommand(newWalkwayCommand);
      setRefChallengeCount(
        walkwayDetail.refChallengeCount !== null
          ? walkwayDetail.refChallengeCount
          : 0
      );

      if (walkwayDetail.walkCourseId !== null) {
        prefetchDetail(walkwayDetail.walkCourseId);
      }
    }
  }, [walkwayDetail]);

  useEffect(() => {
    console.log('regionInfo:', regionInfo);
    if (regionInfo !== undefined && regionInfo.name !== '') {
      const newData = regionInfo.name.split(' ');
      if (newData.length === 2) setRegion([newData[0], newData[1]]);
      else setRegion(['', '']);
      setLocation(regionInfo.name);
    }
  }, [regionInfo]);

  useEffect(() => {
    console.log('walkwayReq :', walkwayCommand);
    setReload('');
    setSearchWalkcourseFlag(false);
    if (walkwayCommand && walkwayCommand.isSend === 1) {
      checkWalkway();
    } else if (walkwayCommand && walkwayCommand.reset) {
      console.log('reset :', walkwayCommand.reset);
      resetWalkway();
    } else if (walkwayCommand && walkwayCommand.walkwayId.length > 0) {
      console.log('walkwayReq.walkwayId :', walkwayCommand.walkwayId);
      setLocalWalkwayId(walkwayCommand.walkwayId);
      setWalkwayId(walkwayCommand.walkwayId);
      walkwayDetailPrefetch(walkwayCommand.walkwayId);
    }
  }, [walkwayCommand]);

  useEffect(() => {
    if (searchResult.length > 0) {
      setSearchWalkcourseFlag(true);

      console.log('searchResult :', searchResult);
      setWalkcourseId(searchResult[0].id);
      setDistance((searchResult[0].distance / 1000).toFixed(2)); // meter -> km
      setEstimatedSteps(
        searchResult[0].step !== 0
          ? searchResult[0].step
          : convertStep(searchResult[0].distance)
      );
      setExpectedMins(searchResult[0].time);
      setCourseName(searchResult[0].name);
      // walkwayCourseDetailPrefetch(searchResult[0].id);
      prefetchDetail(searchResult[0].id);
    }
  }, [searchResult]);

  useEffect(() => {
    if (mapInfo) {
      console.log('WalkwayCreate mapInfo :', mapInfo);
      const newCourse = walkcourseDetail.course;
      newCourse.courseDistance = mapInfo.courseDistance;
      newCourse.courseTime = mapInfo.courseTime;
      const newWalkcourseDetail = {
        course: newCourse,
        point: mapInfo.points,
      };
      setCourseName(newCourse.courseName);

      if (searchWalkcourseFlag === true) {
        setDistance((mapInfo.courseDistance / 1000).toFixed(2)); // meter -> km
        setEstimatedSteps(convertStep(mapInfo.courseDistance));
        setExpectedMins(Math.round(mapInfo.courseTime));
      } else {
        setDistance(
          walkwayDetail !== undefined &&
            walkwayDetail !== null &&
            walkwayDetail.distance !== 0
            ? walkwayDetail.distance
            : (mapInfo.courseDistance / 1000).toFixed(2)
        ); // meter -> km
        setEstimatedSteps(
          walkwayDetail !== undefined &&
            walkwayDetail !== null &&
            walkwayDetail.estimatedSteps !== 0
            ? walkwayDetail.estimatedSteps
            : convertStep(mapInfo.courseDistance)
        );
        setExpectedMins(
          walkwayDetail !== undefined &&
            walkwayDetail !== null &&
            walkwayDetail.expectedMins !== 0
            ? walkwayDetail.expectedMins
            : Math.round(mapInfo.courseTime)
        );
      }

      setWalkcourseDetail(newWalkcourseDetail);
      setGpsData(mapInfo.points);
    }
  }, [mapInfo]);

  // useEffect(() => {
  //   if (isSend === 1) {
  //     console.log('way isSend :', isSend);
  //     setIsSend(2);
  //   }
  // }, [isSend]);

  return (
    <>
      <Paper sx={{ m: '2rem 0', p: '2rem' }}>
        <Box>
          <Typography className='title'>워크웨이 이름</Typography>
          <TextField
            sx={{ width: '48rem' }}
            placeholder='워크웨이 이름을 50자 이내로 작성해 주세요.'
            value={title}
            onChange={evt => setTitle(evt.target.value.substring(0, 50))}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <Typography>{title.length} / 50</Typography>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <SwUuidCreate
            id={localWalkwayId}
            setId={setLocalWalkwayId}
            title='워크웨이'
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>워크웨이 위치 정보</Typography>
          <SwFindLatLngbyKakaomap
            elementId='locationInfo'
            setLatLng={setResultLatLng}
            setAddress={setRegion}
            value={resultLatLng}
            location={location}
            setLocation={setLocation}
          />
          <Divider />
        </Box>
        {/* 
        <Box>
          <TextField value={region[0]} sx={{ mr: '1rem' }} />
          <TextField value={region[1]} />
          <Divider />
        </Box> */}
        {/* <Box sx={{ mb: '1rem' }}>
          <Typography className='title'>지역 카테고리</Typography>
          <SwSelectAmd setTarget={setRegionInfo} title='' />
        </Box> */}

        <Box>
          <Typography className='title'>길 코스</Typography>
          <TextField
            placeholder='길 코스 이름, 아이디로 검색해 주세요.'
            inputRef={walkcourseRef}
            value={searchName}
            onChange={evt => setSearchName(evt.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    onClick={() =>
                      searchWalkCourse(walkcourseRef.current?.value)
                    }
                    sx={{ pr: 0 }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ width: '48rem' }}
          />
          <Collapse in={hasWalkcourse}>
            <Paper>
              <Typography className='title'>
                길 코스 이름: {courseName}
              </Typography>
              <WalkcourseMap
                elementId='walkcourse_map'
                courseData={courseData}
                mapInfo={mapInfo}
                setMapInfo={setMapInfo}
                disabled={refChallengeCount > 0}
              />
            </Paper>
          </Collapse>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>워크웨이 이미지</Typography>
          <SwFileSetting
            setImage={setWalkwayImage}
            image={
              walkwayImageFile !== null
                ? walkwayImage
                : walkwayImage !== ''
                ? `${imageUrlPrefix}${walkwayImage}`
                : ''
            }
            setFile={setWalkwayImageFile}
            file={walkwayImageFile}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_center'>
            <Typography className='title'>워크웨이 태그</Typography>
            {/* <IconButton sx={{ mb: '.33rem' }} size='small' onClick={addTag}>
              <AddOutlinedIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton> */}
          </Box>
          {walkwayTag?.map((tag: string, idx: number) => (
            <TextField
              key={idx.toString()}
              sx={{ mr: '1rem' }}
              placeholder='태그 입력'
              value={tag}
              onChange={evt => onChangeTags(evt, idx)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    {/* <IconButton size='small' onClick={() => deleteTag(idx)}>
                      <ClearIcon sx={{ fontSize: '1rem' }} />
                    </IconButton> */}
                  </InputAdornment>
                ),
              }}
            />
          ))}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>
            워크웨이 거리 (km - 킬로미터)
          </Typography>
          <TextField
            value={distance}
            onChange={evt => {
              setDistance(evt.target.value);
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>워크웨이 예상 걸음 수</Typography>
          <TextField
            value={estimatedSteps}
            onChange={evt => {
              setEstimatedSteps(evt.target.value);
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>워크웨이 소요 시간 (분)</Typography>
          <TextField
            value={expectedMins}
            onChange={evt => {
              setExpectedMins(evt.target.value);
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>공개 / 비공개</Typography>
          <TextField
            select
            defaultValue={0}
            sx={{ width: '13rem' }}
            value={hidden}
            onChange={evt => setHidden(Number(evt.target.value))}
          >
            <MenuItem value={0}>공개</MenuItem>
            <MenuItem value={1}>비공개</MenuItem>
          </TextField>
          <Divider />
        </Box>
        <Box sx={{ p: '1rem 0 1rem' }} className='flex_between'>
          <Button
            variant='contained'
            color='secondary'
            sx={{ mr: '1rem' }}
            onClick={() => {
              window.location.reload();
            }}
          >
            신규작성
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              setDialogTitle('워크웨이 저장');
              setDialogMessage('워크웨이를 저장하시겠습니까?');
              setOpenMore(1);
            }}
          >
            저장
          </Button>
        </Box>
      </Paper>
      <SwSearchWalkcourse
        title={title !== null ? title[0] : ''}
        searchKey={searchKey}
        searchWord={searchWord}
        open={openSearchResult}
        onClose={() => {
          setOpenSearchResult(false);
        }}
        setTarget={setSearchResult}
      />
      <SwLoadGPX
        type={1}
        open={openGpx}
        onclose={() => setOpenGpx(false)}
        gpxData={gpxDataArray}
        setGpxData={setGpxDataArray}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      {openMore === 1 && (
        <SwBasicDialog
          message={dialogMessage}
          open={!!openMore}
          onConfirm={() => {
            checkWalkway();
            setOpenMore(0);
          }}
          onClose={() => {
            setOpenMore(0);
          }}
        />
      )}
    </>
  );
};
