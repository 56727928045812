import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IField } from '../../models/field';
import { throwError } from '../helper';
/*
 * Field
 */

export const getFieldList = (data: any) =>
  xapi
    .get(
      `/admin/v2/apis/field?page=${data.page}&per_page=${data.rowsPerPage}&searchKey=${data.searchKey}&searchWord=${data.searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const getFieldDetailInfo = (fieldId: string) =>
  xapi.get(`/admin/v2/apis/field/${fieldId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getFieldRequestList = () =>
  xapi.get(`/admin/v2/apis/field/creation-request`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getFieldRequestListByOperator = () =>
  xapi.get(`/admin/v2/apis/field/creation-request-operator`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getFieldRequestDetail = (fieldId: string) =>
  xapi.get(`/admin/v2/apis/field/creation-request/${fieldId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

// -------------------------------------------------------------------------
// field character
// -------------------------------------------------------------------------
export const getFieldCharacterList = (fieldId: string, characterType: number) =>
  xapi
    .get(`/admin/v2/apis/field/${fieldId}/character/${characterType}`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerField = (data: IField) =>
  xapi.post(`/admin/v2/apis/field`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerFieldCharacter = (data: any) =>
  xapi
    .post(`/admin/v2/apis/field/${data.fieldId}/character`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteFieldCharacter = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/field/${data.fieldId}/character/${data.characterId}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const deleteAllFieldCharacter = (data: any) =>
  xapi
    .delete(`/admin/v2/apis/field/${data.fieldId}/character/all`)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const updateFieldCharacterLocation = (data: any) =>
  xapi
    .put(
      `/admin/v2/apis/field/${data.fieldId}/character/${data.characterId}/location`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });
// -------------------------------------------------------------------------
// field geomtry
// -------------------------------------------------------------------------
export const getFieldGeometryList = (fieldId: string) =>
  xapi.get(`/admin/v2/apis/field/${fieldId}/geometry`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerFieldGeometry = (data: any) =>
  xapi.post(`/admin/v2/apis/field/${data.fieldId}/geometry`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteFieldGeometry = (data: any) =>
  xapi
    .delete(
      `/admin/v2/apis/field/${data.fieldId}/type/${data.type}/geometry/${data.geometryId}`,
      data
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const updateChallengeFieldGeometryHiddenCharacter = (data: any) =>
  xapi
    .put(`/admin/v2/apis/field/${data.fieldId}/geometry/hidden-character`, data)
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

// -------------------------------------------------------------------------
// field place
// -------------------------------------------------------------------------
export const getFieldSpotList = (fieldId: string) =>
  xapi.get(`/admin/v2/apis/field/${fieldId}/spot`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const registerFieldSpot = (data: any) =>
  xapi.post(`/admin/v2/apis/field/${data.fieldId}/spot`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

// -------------------------------------------------------------------------
// field etc
// -------------------------------------------------------------------------
export const updateFieldPoint = (data: any) =>
  xapi.put(`/admin/v2/apis/field/${data.fieldId}/points`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const deleteField = (data: IField) =>
  xapi.delete(`/admin/v2/apis/field/${data.fieldId}`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const changeFieldApplyStatus = (data: any) =>
  xapi.post(`/admin/v2/apis/field/${data.fieldId}/status`, data).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });
