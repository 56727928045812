import { Box, styled, Tab, Tabs } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { SyntheticEvent, useEffect, useState } from 'react';

interface Props {}

const TabContainer = styled(Box)({
  borderBottom: 1,
  borderColor: 'divider',
  '& .MuiTab-root.Mui-selected': {
    color: 'red',
    backgroundColor: '#fff',
  },
  '& .MuiTabs-indicator': {
    bgcolor: 'inherit',
  },
  '& .MuiTabs-root': {
    minHeight: '1rem',
  },
  '& .MuiTab-root': {
    fontSize: '.85rem',
    minWidth: '3.2rem',
    minHeight: '1rem',
    backgroundColor: '#EBECF2',
  },
});

export const TimelineInquire: React.FC<Props> = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(1);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 1:
        setValue(1);
        navigate('admintimeline');
        break;
      case 2:
        setValue(2);
        navigate('dataportaltimeline');
        break;
      default:
        setValue(0);
        navigate('/timeline/inquire');
    }
  };

  useEffect(() => {
    const path = window.location.pathname;
    if (path === '/timeline/inquire/admintimeline') setValue(1);
    else if (path === '/timeline/inquire/dataportaltimeline') setValue(2);
  }, [window.location.pathname]);
  return (
    <div style={{ margin: '2em' }}>
      <TabContainer>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            label='어드민'
            value={1}
            sx={{
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
            }}
          />
          <Tab
            label='데이터 포탈'
            value={2}
            sx={{
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
          />
        </Tabs>
      </TabContainer>
      <Outlet />
    </div>
  );
};
