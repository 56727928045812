import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { getWalkwayLocalSimpleList } from '../../common/api/ApiWalkway';
import { KEY_WALKWAY_LOCAL_SIMPLE_LIST } from '../../common/key';
import { IWalkwayCommand } from '../../models/walkway';

const devCheck =
  process.env.REACT_APP_SERVER_URL === 'https://admin.swallaby.net';

const TabTitle = styled(Typography)({
  fontSize: '.8rem',
  fontWeight: 500,
  color: '#999999',
  margin: '1rem',
  marginBottom: '.5rem',
});

const TabButton = styled(Button)({
  color: 'inherit',
  fontSize: '.85rem',
  textAlign: 'left',
  padding: '.5rem 1.5rem',
  justifyContent: 'flex-start',
});

interface ItabList {
  title: string;
  contents: {
    id: string;
    title: string;
  }[];
}

interface Props {
  setWalkwayCommand: Dispatch<React.SetStateAction<IWalkwayCommand>>;
  isSearch?: boolean;
}

export const WalkwayLocalSimple: React.FC<Props> = ({
  setWalkwayCommand,
  isSearch = false,
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const [searchRegion, setSearchRegion] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [param, setParam] = useState<string>('');
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [siList, setSiList] = useState<any>([]);
  const [walkwayList, setWalkwayList] = useState<any>([]);
  const [doIndex, setDoIndex] = useState<any>(0);
  const [siIndex, setSiIndex] = useState<any>(0);
  const [walkwayIndex, setWalkwayIndex] = useState<any>(0);
  const [localList, seLocalList] = useState<any>();

  const {
    data,
    isLoading,
    refetch: walkwayRefetch,
  } = useQuery(
    KEY_WALKWAY_LOCAL_SIMPLE_LIST,
    () => getWalkwayLocalSimpleList(searchWord),
    {
      onSuccess: res => {
        console.log('walkway local:', res);
        seLocalList(res.local);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const walkwayPrefetch = (newSearchWord: string) => {
    queryClient.prefetchQuery(KEY_WALKWAY_LOCAL_SIMPLE_LIST, () =>
      getWalkwayLocalSimpleList(newSearchWord)
    );
  };

  const tabChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const selectSiInfo = (selected: string) => {
    if (Number(selected) > 0) {
      const idx = Number(selected) - 1;
      const siName = localList[idx].walkway.map((item: any) => item.title);
      setSiList(siName);
    }
    setSiIndex(0);
    setWalkwayIndex(0);
  };

  const selectDongInfo = (selected: string) => {
    const newWalkwayCommand: IWalkwayCommand = {
      localId: '',
      localName: '',
      walkwayId: '',
      walkwayName: '',
      reset: false,
      isSend: 0,
    };

    if (Number(selected) > 0) {
      const idx = Number(selected) - 1;
      const newData = localList[doIndex - 1].walkway[idx];
      console.log('WalkwayLocalSimple newData :', newData);
      newWalkwayCommand.localId = newData.localId;
      newWalkwayCommand.localName = `${localList[doIndex - 1].localName} ${
        newData.title
      }`;
      newWalkwayCommand.reset = false;
    }
    setWalkwayIndex(0);
    setWalkwayCommand(newWalkwayCommand);
  };

  return (
    <>
      <Paper className={isSearch ? 'p_1rem' : 'p_2rem_m_2rem'}>
        <Box>
          {isSearch === false && (
            <Typography className='title'>지역 카테고리</Typography>
          )}
          <Box className='flex_center'>
            <TextField
              sx={{ width: '11rem', mr: '1rem', mb: 0 }}
              select
              defaultValue={0}
              value={doIndex}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setDoIndex(event.target.value);
                selectSiInfo(event.target.value);
              }}
            >
              <MenuItem value={0}>선택</MenuItem>
              {localList &&
                localList.map((option: any, idx: number) => (
                  <MenuItem key={idx + 1} value={idx + 1}>
                    {option?.localName}
                  </MenuItem>
                ))}
            </TextField>
            <TextField
              sx={{ width: '11rem', mr: '1rem', mb: 0 }}
              select
              defaultValue={0}
              value={siIndex}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setSiIndex(event.target.value);
                selectDongInfo(event.target.value);
              }}
            >
              <MenuItem value={0}>선택</MenuItem>
              {siList.map((option: string, idx: number) => (
                <MenuItem key={idx + 1} value={idx + 1}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        </Box>
      </Paper>
    </>
  );
};
