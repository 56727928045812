import {
  Box,
  Button,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchIcon from '@mui/icons-material/Search';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import React, {
  ChangeEvent,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { ProgramForm } from './ProgramForm';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { SelectSearchBox } from '../../commonComponent/SelectSearchBox';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { ButtonBox, MarginButton } from './ProgramStyles';
import {
  confirmProgramRegister,
  confirmProgramTemplateCodeRegister,
  deleteProgramTemplateCode,
  getProgramTemplateCodeList,
} from '../../../common/api/ApiProgram';
import { WhiteTextField, SwSwitch } from '../../styles/Styles';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import {
  RESULT_CODE_TEMPLATE_CODE_WITH_PROGRAM,
  RESULT_OK,
} from '../../../common/resultCode';
import { ProgramCurriculum } from './ProgramCurriculum';
import { KEY_PROGRAM_TEMPLATE_CODE_LIST } from '../../../common/key';
import {
  getProgramTemplateCodeClass,
  programTemplateCodeClass,
} from '../../../common/helperProgram';
import { ProgramTemplateCodeForm } from './ProgramTemplateCodeForm';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';

interface Props {}

interface ITabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: ITabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div hidden={value !== index}>
      {/* <Box sx={{ p: 3 }}>{children}</Box> */}
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

const CreateTabs = styled(Tabs)({
  '& .MuiTab-root.Mui-selected': {
    border: '2px solid #2181FF',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  '& .MuiTabs-indicator': {
    backgroundColor: 'inherit',
  },
  '& .MuiTab-root': {
    padding: '0 1.5rem',
    marginRight: '.1rem',
    border: '1px solid #EBECF0',
    borderBottom: 'inherit',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
});

const requestColumnlists = [
  '템플릿 코드',
  '템플릿 이름',
  '분류',
  '주차수',
  '작성중/완료',
  '프로그램 갯수',
  '등록일시',
];

const clickColumnlist = ['템플릿 코드', '분류', '등록일시'];

export const ProgramTemplateCode: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const searchRef = useRef<any>();
  const selectRef = useRef<any>();
  const [templateCodeInfo, setTemplateCodeInfo] = useState<any>();
  const [templateCode, setTemplateCode] = useState<string>('');
  const [curriculumId, setCurriculumId] = useState<string>('');
  const [requestList, setRequestList] = useState<any>([]);
  const [programTemplateCodeList, setProgramTemplateCodeList] = useState<any>(
    []
  );
  const [programRequestData, setProgramRequestData] = useState<any>();
  const [programMasterType, setProgramMasterType] = useState<any>([]);
  const [rewardGivetype, setRewardGivetype] = useState<any>([]);
  const [rewardUsetype, setRewardUsetype] = useState<any>([]);
  const [rewardList, setRewardList] = useState<any>([]);
  const [programTitle, setProgramTitle] = useState<string>('');
  const [tabValue, setTabValue] = useState(0);
  const [rewardDrawUserList, setRewardDrawUserList] = useState<any>();
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [searchKey, setSearchKey] = useState<string>('선택');
  const [searchWord, setSearchWord] = useState<string>('');
  const [openSwitch, setOpenSwitch] = useState<boolean>(true);
  const [closeSwitch, setCloseSwitch] = useState<boolean>(true);
  const [programFilterInfo, setProgramFilterInfo] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>('');
  const [filter, setFilter] = useState<any>(null);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [open, setOpen] = useState<boolean>(false);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedRow, setSelectedRow] = useState(-1);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [alertTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [rewardCount, setRewardCount] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);

  const onChangeTab = (event: SyntheticEvent, newValue: number) => {
    if (newValue !== tabValue) setTabValue(newValue);
  };
  const {
    data,
    isLoading,
    refetch: programTemplateCodeRefetch,
  } = useQuery(
    KEY_PROGRAM_TEMPLATE_CODE_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newRequest = {
        status: status,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: searchWord,
      };
      if (checkRequest) return getProgramTemplateCodeList(newRequest);
      return null;
    },
    {
      onSuccess: res => {
        console.log('requestList:', res);
        const newProgram =
          res &&
          res.templateCode &&
          res.templateCode.map((item: any, idx: number) => {
            const newData = [
              item.templateCode.length !== 32
                ? item.templateCode
                : '(임시코드)',
              item.templateName,
              item.typeName,
              item.weekCount,
              item.registerStatus === 1
                ? '작성중'
                : item.registerStatus === 4
                ? '완료'
                : '-',
              item.programCount,
              timestamp2Localestring(item.createDate, 1000),
            ];
            return newData;
          });
        setRequestList(newProgram);
        setProgramTemplateCodeList(res ? res.templateCode : []);
        setTotalRecords(res.pagination.totalRecordCount);

        if (selectedId !== '') {
          const newSelect = res.templateCode.filter(
            (item: any) => item.templateCode === selectedId
          );
          console.log('reloadTemplateCode: ', selectedId, ', info:', newSelect);
          setTemplateCodeInfo(newSelect.length > 0 ? newSelect[0] : null);
        }
        setSelectedId('');
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_PROGRAM_TEMPLATE_CODE_LIST, () => {
      const status = (openSwitch ? 2 : 0) + (closeSwitch ? 1 : 0);
      const newRequest = {
        status: status,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: searchWord,
      };
      return getProgramTemplateCodeList(newRequest);
    });
  };

  const {
    mutate: confirmTemplateCodeRegisterMutation,
    isError: isMutateError,
  } = useMutation(confirmProgramTemplateCodeRegister, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setAlertMessage('저장되었습니다.');
        programTemplateCodeRefetch();
      } else if (res.resultCode === RESULT_CODE_TEMPLATE_CODE_WITH_PROGRAM) {
        setOpenSnackbar(true);
        setAlertMessage('사용중인 프로그램이 존재합니다.');
      } else {
        setOpenSnackbar(true);
        setAlertMessage(
          `등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        '템플릿 코드를 승인하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const { mutate: deleteTemplateCodeMutation, isError: isDeleteMutateError } =
    useMutation(deleteProgramTemplateCode, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setAlertMessage('삭제되었습니다.');
          setSelectedId('');
          setSelectedRow(-1);
          programTemplateCodeRefetch();
        } else if (res.resultCode === RESULT_CODE_TEMPLATE_CODE_WITH_PROGRAM) {
          setOpenSnackbar(true);
          setAlertMessage('사용중인 프로그램이 존재합니다.');
        } else {
          setOpenSnackbar(true);
          setAlertMessage(
            `삭제하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setAlertMessage(
          '템플릿 코드를 삭제하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const searchProgram = () => {
    setFilter(null);
    setPage(1);
    prefetch(1);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setSelectedRow(-1);
    setPage(newPage);
    prefetch(newPage);
  };

  const deleteTemplateCode = () => {
    const newData: any = {
      templateCode: templateCodeInfo.templateCode,
    };
    deleteTemplateCodeMutation(newData);
    setAlertOpen(false);
  };

  const confirmTemplateCode = (testType: number) => {
    if (templateCodeInfo === undefined || templateCodeInfo === null) {
      setOpenSnackbar(true);
      setAlertMessage('템플릿 코드를 선택해주세요');
      return;
    }

    if (testType === 1) {
      setAlertOpen(true);
      setAlertMessage('템플릿 코드를 삭제하시겠습니까?');
      return;
    }

    const newData: any = {
      templateCode: templateCodeInfo.templateCode,
    };
    confirmTemplateCodeRegisterMutation(newData);
  };

  const handleKeyDown = (e: any, type: string) => {
    if (e.key === 'Enter') {
      searchProgram();
    }
  };

  useEffect(() => {
    console.log('openSwitch :', openSwitch, ', closeSwitch :', closeSwitch);
    programTemplateCodeRefetch();
  }, [openSwitch, closeSwitch]);

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    setSelectedRow(i);
    const newTemplateCode = Chosen[0].length !== 32 ? Chosen[0] : '(임시코드)';
    const newChosen = [newTemplateCode, Chosen[2], Chosen[5]];
    setChosenRequest([newChosen]);
    setTemplateCodeInfo(programTemplateCodeList[i]);
    setCurriculumId(programTemplateCodeList[i].defaultCurriculum);
    setSelectedId(newTemplateCode);
  };

  const reloadTemplateCode = (id: string) => {
    console.log('reloadTemplateCode :', id);
    if (id !== '') {
      setTemplateCode(id);
      setSelectedId(id);
      programTemplateCodeRefetch();
    }
  };

  const searching = () => {
    setPage(1);
    if (searchRef.current.value && selectRef.current.value) {
      if (
        searchRef.current.value === searchWord &&
        selectRef.current.value === searchKey
      ) {
        programTemplateCodeRefetch();
      } else {
        setSearchKey(selectRef.current.value);
        setSearchWord(searchRef.current.value);
      }
    } else if (searchWord === '' && searchKey === '') {
      programTemplateCodeRefetch();
    } else {
      setSearchKey('');
      setSearchWord('');
    }
  };

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      programTemplateCodeRefetch();
    }
  }, [checkRequest]);

  useEffect(() => {
    if (filter !== null && filter !== undefined) {
      console.log('filter :', filter);
      setPage(1);
      prefetch(1);
    }
  }, [filter]);

  return (
    <>
      <Box className='flex_start' sx={{ m: '1.5rem 0' }}>
        <TextField
          sx={{ width: '10rem', mr: '1rem' }}
          select
          defaultValue='선택'
          value={searchKey}
          onChange={evt => setSearchKey(evt.target.value)}
        >
          {programTemplateCodeClass.map((row: string, idx: number) => (
            <MenuItem key={`template_code_${idx}`} value={row}>
              {row}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          sx={{ width: '20rem' }}
          value={searchWord}
          onKeyDown={evt => {
            handleKeyDown(evt, 'templateCode');
          }}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => setSearchWord(evt.target.value)}
        />
      </Box>
      <Paper sx={{ m: '1.5rem 0', p: '1.5rem' }}>
        <Box className='flex_between'>
          <Box>
            <Typography>
              프로그램 <span className='bluecontent'>{totalRecords}</span> 개
            </Typography>
          </Box>
          <Box className='flex_between'>
            <Typography sx={{ mr: '.4rem' }}>Open</Typography>
            <SwSwitch
              defaultChecked={true}
              value={openSwitch}
              onChange={() => setOpenSwitch(!openSwitch)}
            />
            <Typography sx={{ ml: '1rem', mr: '.4rem' }}>Close</Typography>
            <SwSwitch
              defaultChecked={true}
              value={closeSwitch}
              onChange={() => setCloseSwitch(!closeSwitch)}
            />
          </Box>
        </Box>
        <TableContainer
          sx={{ p: '0rem', mt: '1rem', height: '30vh', overflowY: 'scroll' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {requestColumnlists.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.4rem .6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {requestList?.map((row: any, idx: number) => (
                <TableRow
                  key={idx.toString()}
                  onClick={() => chooseRequest(idx)}
                  className={
                    selectedRow === idx ? 'selected_row' : 'whitecolor'
                  }
                >
                  {row.map((rowItem: any, rowIdx: number) => (
                    <TableCell
                      key={rowIdx.toString()}
                      className={
                        programTemplateCodeList[idx].programStatus === 0
                          ? 'not_important_row'
                          : ''
                      }
                    >
                      {rowIdx !== 1
                        ? row[rowIdx]
                        : programTemplateCodeList[idx].programStatus === 0
                        ? `${row[rowIdx]} (close)`
                        : row[rowIdx]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <RequestTable columns={clickColumnlist} rows={chosenRequest} />
      <Paper sx={{ p: '0rem 0', mt: '1rem' }}>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <CreateTabs value={tabValue} onChange={onChangeTab}>
              <Tab label='기본 정보' />
              <Tab
                label='커리큘럼'
                disabled={
                  curriculumId === undefined ||
                  curriculumId === null ||
                  curriculumId.length === 0
                }
              />
            </CreateTabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <ProgramTemplateCodeForm
              templateCodeInfo={templateCodeInfo}
              setTemplateCodeInfo={setTemplateCodeInfo}
              reloadTemplateCode={reloadTemplateCode}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ProgramCurriculum
              curriculumId={curriculumId}
              programInfo={templateCodeInfo}
              reloadProgram={reloadTemplateCode}
              showDuration={false}
            />
          </TabPanel>
        </Box>
      </Paper>
      <Box sx={{ mt: '1.5rem' }}>
        <ButtonBox className='flex_between'>
          <Box>
            <MarginButton
              variant='outlined'
              color='info'
              onClick={() => {
                window.location.reload();
              }}
            >
              신규작성
            </MarginButton>
          </Box>
          <Box>
            <Button
              sx={{ mr: '2rem' }}
              variant='outlined'
              color='warning'
              onClick={() => confirmTemplateCode(1)}
            >
              삭제
            </Button>
            <Button
              variant='contained'
              color='info'
              onClick={() => confirmTemplateCode(0)}
            >
              저장
            </Button>
          </Box>
        </ButtonBox>
      </Box>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwAlert
        open={alertOpen}
        onConfirm={() => deleteTemplateCode()}
        title={alertTitle}
        contents={alertMessage}
      />
      {loading && <SwLoading />}
    </>
  );
};
