import {
  Box,
  Pagination,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { MouseEvent, useEffect, useState } from 'react';

interface Props {
  title?: any;
  columns: string[];
  rows: any[];
  page: number;
  rowsPerPage: number;
  totalCount: number;
  isCritical?: boolean;
  onclick?: (i: number) => void;
  isSelect?: (i: any) => void;
  handleChangePage: (_event: any, newPage: number) => void;
}

const CreatePaper = styled(Paper)({
  // margin: '2rem',
  padding: '1rem',
  cursor: 'pointer',
});

const PaginationBox = styled(Box)({
  padding: '.6rem',
  display: 'flex',
  justifyContent: 'center',
});

export const RequestPagingTable: React.FC<Props> = ({
  title,
  columns,
  rows,
  page,
  rowsPerPage,
  totalCount,
  isCritical = false,
  onclick,
  isSelect,
  handleChangePage,
}) => {
  const [selectedRow, setSelectedRow] = useState(-1);

  const handlerRowClicked = (idx: number) => {
    if (onclick) onclick(idx);
    setSelectedRow(idx);
  };

  useEffect(() => {
    setSelectedRow(-1);
  }, [rows]);

  return (
    <CreatePaper>
      {/* <Typography component='div' sx={{ ml: '.4rem' }}>
          {title}
        </Typography> */}
      {title}
      <TableContainer sx={{ mt: '1rem', maxHeight: '27rem' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column, idx) => (
                <TableCell
                  key={idx.toString()}
                  className={clsx(
                    { request_head: !!title },
                    'request_tablehead'
                  )}
                  sx={{ p: '.4rem .7rem !important' }}
                >
                  {column}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody sx={{ maxHeight: '10rem', overflowY: 'scroll' }}>
            {rows?.map((row, idx) => (
              <TableRow
                key={idx.toString()}
                onClick={() => handlerRowClicked(idx)}
                className={
                  selectedRow === idx
                    ? 'selected_row'
                    : row[columns.length] === 1
                    ? 'specified_row'
                    : 'whitecolor'
                }
              >
                {row
                  .filter((r: any, i: number) => i < columns.length)
                  .map((r: any, i: number) => (
                    <TableCell
                      key={i.toString()}
                      className={
                        selectedRow === idx && row[columns.length] === 1
                          ? clsx('request_tablecell selected_row specified_row')
                          : selectedRow === idx
                          ? clsx('request_tablecell selected_row')
                          : row[columns.length] === 1
                          ? clsx('request_tablecell specified_row')
                          : clsx('request_tablecell ')
                      }
                      sx={{ p: '.6rem .7rem' }}
                    >
                      {r}
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PaginationBox>
        <Pagination
          color='primary'
          variant='outlined'
          siblingCount={3}
          page={page}
          onChange={handleChangePage}
          count={Math.ceil(totalCount / rowsPerPage)}
        />
      </PaginationBox>
    </CreatePaper>
  );
};
