import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';

import { MultilineTextField } from '../../styles/Styles';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import {
  postDataPortalConnectPopup,
  putDataPortalConnectPopup,
  deleteDataPortalConnectPopup,
} from '../../../common/api/ApiDataPortal';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { IUploadFile } from '../../../models/common';
import {
  getPartnerType,
  getPartnerTypeItems,
  timestamp2Localestring,
} from '../../../common/helper';

const TagsChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

interface Props {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
  notice: any;
}

export const ConnectPopupDrawup: React.FC<Props> = ({
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
  notice,
}) => {
  const titleRef = useRef<any>();
  const contentRef = useRef<any>();
  const [connectPopupIdx, setConnectPopupIdx] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [conTypes, setConTypes] = useState<any[]>([]);
  const [partnerType, setPartnerType] = useState<number>(0);
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageFile, setImageFile] = useState<any>(null);
  const [openRangeDate, setOpenRangeDate] = useState<any[]>([null, null]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);

  const { mutate: postDataPortalConnectPopupMutate, isError: isMutateError } =
    useMutation(postDataPortalConnectPopup, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('안내팝업을 등록했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '안내팝업 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const { mutate: putDataPortalConnectPopupMutate, isError: isPutMutateError } =
    useMutation(putDataPortalConnectPopup, {
      onSuccess: () => {
        setOpenDialog(true);
        setDialogMessage('안내팝업을 수정했습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '안내팝업 수정이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
        onClose();
      },
    });

  const registerPortalConnectPopup = (newUploadUrl: string) => {
    const openStime =
      openRangeDate[0] === null
        ? 0
        : new Date(openRangeDate[0]).getTime() / 1000;
    const openEtime =
      openRangeDate[1] === null
        ? 0
        : new Date(openRangeDate[1]).getTime() / 1000;
    const newImage =
      imageFile !== null
        ? newUploadUrl
        : image === ''
        ? ''
        : notice?.image.length > 0
        ? notice?.image
        : '';

    const newConTypes = conTypes.map((item: number) =>
      getPartnerType(item) !== '기타' ? item : 99
    );
    const newConType = conTypes.length > 0 ? JSON.stringify(newConTypes) : '[]';

    const newData: any = {
      idx: Number(connectPopupIdx),
      startDate: openStime,
      endDate: openEtime,
      image: newImage,
      conTypes: newConType,
    };

    if (newImage === '') {
      setOpenSnackbar(true);
      setDialogMessage('이미지를 선택 후 다시 시도해주시기 바랍니다.');
      return;
    }

    if (openStime === 0 || openEtime === 0) {
      setOpenSnackbar(true);
      setDialogMessage('공개기간을 선택 후 다시 시도해주시기 바랍니다.');
      return;
    }
    connectPopupIdx !== ''
      ? putDataPortalConnectPopupMutate(newData)
      : postDataPortalConnectPopupMutate(newData);
  };

  const { mutate: uploadFileMutate, isError: isUploadMutateError } =
    useMutation(postUploadFile, {
      onSuccess: res => {
        console.log('uploadFile:', res);
        setUploadUrl(res.uploadUrl.file.path);
        console.log('uploadFile uploadUrl:', uploadUrl);
        registerPortalConnectPopup(res.uploadUrl.file.path);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const checkDataPortalConnectPopup = () => {
    if (imageFile === null) {
      registerPortalConnectPopup('');
    } else {
      const uploadfile: IUploadFile = {
        type: 'portalConnectPopup',
        file: imageFile,
      };
      uploadFileMutate(uploadfile);
    }
  };

  const {
    mutate: deleteDataPortalConnectPopupMutation,
    isError: isDeleteMutateError,
  } = useMutation(deleteDataPortalConnectPopup, {
    onSuccess: () => {
      setOpenDialog(true);
      setDialogMessage('안내팝업을 삭제했습니다.');
      onConfirm();
    },
    onError: error => {
      setOpenDialog(true);
      setDialogMessage(
        '공안내팝업을 삭제하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
      );
    },
  });

  const deleteConnectPopup = (idx: number) => {
    const newData: any = {
      idxList: [idx],
    };
    console.log(newData);
    deleteDataPortalConnectPopupMutation(newData);
  };

  const onChangePartnerType = (idx: number) => {
    setPartnerType(idx);
    if (idx !== 0) {
      const newIdx = getPartnerType(idx) !== '기타' ? idx : 99;
      const newData = conTypes
        .map(item => (item === newIdx ? 1 : -1))
        .filter(item => item !== -1);
      if (newData === null || newData.length === 0) {
        setConTypes([...conTypes, newIdx]);
      }
    } else {
      setConTypes([]);
    }
  };

  useEffect(() => {
    console.log('notice : ', notice);
    setConnectPopupIdx(notice?.idx === undefined ? '' : notice?.idx);
    if (!edit && notice === null) {
      setImage('');
      setImageFile(null);
      setConTypes([]);
    } else {
      const newImage =
        notice?.image?.length > 0
          ? `${notice?.imageUrlPrefix}${notice?.image}`
          : '';
      setImage(newImage);
      const newOpenDate = [
        timestamp2Localestring(notice?.startDate, 1),
        timestamp2Localestring(notice?.endDate, 1),
      ];
      setOpenRangeDate(newOpenDate);

      const parsedConTypes =
        notice?.conTypes && notice?.conTypes.length > 0
          ? JSON.parse(notice?.conTypes)
          : [];
      const newConTypes = parsedConTypes.map((item: number) =>
        getPartnerType(item) !== '기타' ? item : 99
      );
      setConTypes(newConTypes);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle sx={{ pl: '24px !important' }}>
          <Box className='flex_between'>
            <span>
              {!edit && notice === null
                ? '안내팝업 작성'
                : !edit && notice?.idx !== null
                ? '안내팝업 수정'
                : '안내팝업'}
            </span>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent>
          {edit ? (
            <>
              <Typography sx={{ mb: '.7rem', color: '#999999' }}>
                {notice?.createDate}
              </Typography>
              <Box sx={{ mb: '.7rem' }}>
                <Typography className='title'>공개기간</Typography>
                <SwDateRangePicker
                  rangeDate={openRangeDate}
                  setRangeDate={setOpenRangeDate}
                />
              </Box>
              <Box>
                <Typography className='title'>공개 고객군</Typography>
                <Box>
                  {conTypes.length > 0 &&
                    conTypes.map((item: number) => (
                      <TagsChip
                        label={getPartnerType(item)}
                        variant='outlined'
                        deleteIcon={<CloseIcon />}
                        onDelete={() => {
                          const newConType = conTypes.filter(
                            newItem => item !== newItem
                          );
                          setConTypes([...newConType]);
                        }}
                        key={item.toString()}
                      />
                    ))}
                  {conTypes.length === 0 && (
                    <TagsChip label='전체' variant='outlined' />
                  )}
                </Box>
              </Box>
              <Divider />

              {notice?.image?.length > 0 ? (
                <Paper
                  sx={{
                    widht: '100%',
                    height: '50vh',
                    overflowY: 'scroll',
                    mt: '1rem',
                    mb: '1rem',
                  }}
                >
                  <img
                    src={
                      notice?.image?.length > 0
                        ? `${notice?.imageUrlPrefix}${notice?.image}`
                        : ''
                    }
                    alt=''
                    style={{ width: '100%' }}
                  />
                </Paper>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <Typography sx={{ fontWeight: 500, mb: '.7rem' }}>
                이미지(선택)
              </Typography>
              <SwFileSetting
                image={image}
                setImage={setImage}
                setFile={setImageFile}
                file={imageFile}
                width='30rem'
                height='auto'
              />
              <Divider />
              <Box sx={{ fontWeight: 500, mb: '.7rem' }}>
                <Typography className='title'>공개기간</Typography>
                <SwDateRangePicker
                  rangeDate={openRangeDate}
                  setRangeDate={setOpenRangeDate}
                />
              </Box>
              <Divider />
              <Box sx={{ fontWeight: 500, mb: '.7rem' }}>
                <Typography className='title'>고객 분류</Typography>
                <TextField
                  select
                  sx={{ width: '60%' }}
                  value={partnerType}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    onChangePartnerType(Number(event.target.value));
                  }}
                >
                  <MenuItem key={0} value={0}>
                    전체
                  </MenuItem>
                  {getPartnerTypeItems().map((item: any, idx: number) => (
                    <MenuItem key={idx + 1} value={idx + 1}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
              <Box>
                {conTypes.length > 0 &&
                  conTypes.map((item: number) => (
                    <TagsChip
                      label={getPartnerType(item)}
                      variant='outlined'
                      deleteIcon={<CloseIcon />}
                      onDelete={() => {
                        const newConType = conTypes.filter(
                          newItem => item !== newItem
                        );
                        setConTypes([...newConType]);
                      }}
                      key={item.toString()}
                    />
                  ))}
              </Box>
            </>
          )}
        </DialogContent>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogActions>
          {edit ? (
            <>
              <Button
                onClick={() => {
                  deleteConnectPopup(notice?.idx);
                }}
                color='info'
                variant='outlined'
              >
                삭제
              </Button>
              <Button onClick={isEdit} color='info' variant='contained'>
                수정
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                onClick={() => checkDataPortalConnectPopup()}
                color='info'
                variant='contained'
              >
                저장
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openAlert}
        onConfirm={() => setOpenAlert(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
