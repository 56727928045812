import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import React, {
  ChangeEvent,
  useEffect,
  useRef,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { KEY_DATAPORTAL_ACCOUNT_LIST } from '../../common/key';
import { getDataPortalAccountList } from '../../common/api/ApiDataPortal';
import { MultilineTextField } from '../styles/Styles';
import { parseGPXContent } from '../../common/helper';

interface Props {
  type?: number;
  gpxData: any;
  setGpxData: Dispatch<React.SetStateAction<any>>;
  radius?: number;
  open: boolean;
  onclose: () => void;
}

export const SwLoadGPX: React.FC<Props> = ({
  type = 1,
  gpxData,
  setGpxData,
  radius = 100,
  open,
  onclose,
}) => {
  const [openSearchResult, setOpenSearchResult] = useState<boolean>(false);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchResult, setSearchResult] = useState<any>();
  const [title, setTitle] = useState<string>('');
  const [findDataportal, setFindDataportal] = useState<any>();
  const [content, setContent] = useState<any>('');
  const [uploadFile, setUploadFile] = useState<any>('');

  const saveContent = () => {
    if (content.length > 0) {
      console.log('JSON.parse(content):', JSON.parse(content));
      setGpxData(JSON.parse(content));
      onclose();
    } else setGpxData([]);
  };

  useEffect(() => {
    if (uploadFile) {
      const parsedGpx = parseGPXContent(uploadFile, radius, type);
      setContent(JSON.stringify(parsedGpx));
      setUploadFile('');
    }
  }, [uploadFile]);

  useEffect(() => {
    if (open) {
      console.log('open gpxData: ', gpxData);
      if (gpxData !== null) {
        setContent(JSON.stringify(gpxData));
      } else {
        setUploadFile('');
      }
      setUploadFile('');
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onclose}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '44vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Box>
              <Typography>경로(pgx)파일</Typography>
            </Box>
            <IconButton onClick={onclose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent>
          <Box className='flex_center'>
            <Box>
              <MultilineTextField
                multiline
                rows={15}
                value={content}
                onChange={evt => setContent(evt.target.value)}
                sx={{
                  height: '100%',
                  width: '20vw',
                }}
              />
            </Box>
            <Box sx={{ ml: '1rem', mb: '20rem' }}>
              <Button
                size='small'
                color='info'
                onClick={() => {
                  const gpxfile = document.getElementById('gpxfile_common');
                  gpxfile?.click();
                }}
              >
                GPX파일 읽기
              </Button>
              <input
                className='nonedisplay'
                id='gpxfile_common'
                type='file'
                onChange={(evt: ChangeEvent<HTMLInputElement>) => {
                  const reader = new FileReader();
                  if (evt.target.files) reader.readAsText(evt.target.files[0]);
                  reader.onload = e => {
                    setUploadFile(e.target?.result);
                  };
                }}
              />
            </Box>
          </Box>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button
            variant='outlined'
            color='info'
            sx={{ fontSize: '.87rem' }}
            onClick={saveContent}
          >
            닫기
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
