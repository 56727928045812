import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  ChangeEvent,
  DragEvent,
  MouseEvent,
  useEffect,
  useState,
  Dispatch,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import {
  KEY_CHALLENGE_CLASS_TYPE_LIST,
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
} from '../../common/key';
import {
  getChallengeClassTypeList,
  registerChallengeClassType,
} from '../../common/api/ApiChallenge';
import { IChallengeClassType } from '../../models/challenge';
import { DraggablePaper } from '../styles/Styles';
import { SwSnackbar } from './views/SwSnackbar';
import { RESULT_OK } from '../../common/resultCode';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const SwChallengeClassType: React.FC<Props> = ({ open, onClose }) => {
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const currentMonth = new Date(Date.now()).getMonth() + 1;
  const [selectedMonth, setSelectedMonth] = useState<number>(currentMonth);
  const [classTypeAllList, setClassTypeAllList] = useState<any[]>([]);
  const [classTypeList, setClassTypeList] = useState<any[]>([]);
  const [classType, setClassType] = useState<number>(0);
  const [checkedButtons, setCheckedButtons] = useState<string[]>([]);
  const [checkedAll, setCheckedAll] = useState<boolean>(false);

  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);

  const classTypeItems = document.querySelectorAll('.draggable');
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const {
    data,
    isLoading,
    refetch: challengeClassTypeRefetch,
  } = useQuery(
    KEY_CHALLENGE_CLASS_TYPE_LIST,
    () => {
      if (open) {
        const newData: any = {
          month: selectedMonth,
        };
        return getChallengeClassTypeList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        setClassTypeAllList(res ? res.classType.all : []);
        setClassTypeList(res ? res.classType.classType : []);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: postChallengeClassTypeMutate,
    isError: isMutateClassTypeError,
  } = useMutation(registerChallengeClassType, {
    onSuccess: res => {
      if (res.resultCode === RESULT_OK) {
        setOpenSnackbar(true);
        setDialogMessage('챌린지 분류를 추가했습니다.');
      } else {
        setOpenSnackbar(true);
        setDialogMessage(
          `챌린지 분류를 추가하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
        );
      }
    },
    onError: error => {
      setOpenSnackbar(true);
      setDialogMessage(
        `챌린지 분류 추가가 실패했습니다. 다시 시도해주시기 바랍니다.(${error})`
      );
    },
  });

  // draw & drop ------------------------------------------------
  const dragStart = (evt: DragEvent<HTMLDivElement>) => {
    setListEvent({
      ...listEvent,
      currentDrag: evt.currentTarget.dataset.position,
      list: classTypeList,
    });
    evt.currentTarget.style.border = '1px solid #757575';
  };

  const drop = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';

    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (list: any, idx: number) => idx.toString() !== listEvent.currentDrag
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    setClassTypeList(newList);

    setListEvent({
      currentDrag: null,
      over: null,
      ...listEvent,
    });
  };

  const dragEnd = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    classTypeItems.forEach(item => item.classList.remove('drag_over'));
  };

  const dragEnter = (evt: DragEvent<HTMLDivElement>) => {
    const newOver = evt.currentTarget.dataset.position;
    setListEvent({ ...listEvent, over: newOver });
  };

  const dragOver = (evt: DragEvent<HTMLDivElement>) => {
    evt.preventDefault();
    evt.currentTarget.classList.add('drag_over');
    const newOver = evt.currentTarget.dataset.position;
    const newCurrent = listEvent.currentDrag;
    const filterList = listEvent.list.filter(
      (_list: any, idx: number) => idx.toString() !== newCurrent
    );
    const newList = [
      ...filterList.slice(0, newOver),
      listEvent.list[Number(newCurrent)],
      ...filterList.slice(newOver),
    ];
    listEvent.over !== newOver && setClassTypeList(newList);
  };

  const dragLeave = (evt: DragEvent<HTMLDivElement>) => {
    evt.currentTarget.style.border = '';
    classTypeItems.forEach(item => item.classList.remove('drag_over'));
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    classTypeItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newPosition = Number(evt.currentTarget.dataset.position);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newPosition : null,
    });
  };
  // ------------------------------------------------------

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedButtons([]);
    if (event.target.checked) {
      setCheckedAll(true);
      classTypeList.map((term: any, idx: number) => {
        setCheckedButtons(current => [...current, term.classTitle]);
      });
    } else {
      setCheckedAll(false);
    }
  };

  const changeHandler = (checked: boolean, id: string) => {
    if (checked) {
      setCheckedButtons([...checkedButtons, id]);
    } else {
      setCheckedButtons(checkedButtons.filter(button => button !== id));
    }
  };

  const deleteCheckedClassType = () => {
    const newGroup = classTypeList.filter(
      (term: any, idx: number) => !checkedButtons.includes(term.classTitle)
    );
    setCheckedAll(false);
    setClassTypeList(newGroup);
    setListEvent({ ...listEvent, clicked: null });
  };

  const addClassType = () => {
    const newClassType = classTypeAllList.filter(
      (item: any) => item.classType === classType
    );
    if (newClassType === null || newClassType.length === 0) return;

    const duplicatedClassType = classTypeList.filter(
      (item: any) => item.classType === classType
    );
    if (duplicatedClassType !== null && duplicatedClassType.length > 0) {
      setOpenSnackbar(true);
      setDialogMessage('중복된 챌린지 분류입니다.');
      return;
    }

    setClassTypeList((prev: any) => [
      ...prev,
      {
        classType: newClassType[0].classType,
        classTitle: newClassType[0].classTitle,
      },
    ]);
  };

  const saveChallengeClassType = () => {
    const newClassTypes = classTypeList.map((item: any, index: number) => {
      const newData: any = {
        classType: item.classType,
        classTitle: item.classTitle,
        seq: index + 1,
      };
      return newData;
    });

    if (newClassTypes === null || newClassTypes.length === 0) {
      setOpenSnackbar(true);
      setDialogMessage('등록할 챌린지 분류가 없습니다.');
      return;
    }
    const newData: any = {
      month: selectedMonth,
      classTypes: newClassTypes,
    };
    postChallengeClassTypeMutate(newData);
  };

  useEffect(() => {
    if (open) {
      challengeClassTypeRefetch();
    } else {
      setClassTypeList([]);
      setClassTypeAllList([]);
      setCheckedAll(false);
      setCheckedButtons([]);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        sx={{
          '& .MuiDialog-paper': {
            height: '90%',
            width: '50%',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Typography className='title'>챌린지 분류 관리</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent sx={{ p: '1rem 2rem' }}>
          <Box>
            <Typography className='title'>월</Typography>
            <TextField
              sx={{ width: '20rem' }}
              select
              value={selectedMonth}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setSelectedMonth(Number(evt.target.value));
              }}
            >
              <MenuItem key='class_type_0' value={0}>
                선택
              </MenuItem>
              {months.map((item: any) => (
                <MenuItem key={`class_type_${item}`} value={item}>
                  {item}
                </MenuItem>
              ))}
            </TextField>
            <Button
              variant='contained'
              sx={{ ml: '.5rem' }}
              onClick={() => {
                challengeClassTypeRefetch();
              }}
            >
              조회
            </Button>
          </Box>
          <Box>
            <Typography className='title'>전체 챌린지 분류</Typography>
            <TextField
              sx={{ width: '20rem' }}
              select
              value={classType}
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ) => {
                setClassType(Number(evt.target.value));
              }}
            >
              <MenuItem key='class_type_0' value={0}>
                선택
              </MenuItem>
              {classTypeAllList &&
                classTypeAllList.map((item: any) => (
                  <MenuItem
                    key={`class_type_${item.classType}`}
                    value={item.classType}
                  >
                    {item.classTitle}
                  </MenuItem>
                ))}
            </TextField>
            <Button
              variant='outlined'
              sx={{ ml: '.5rem' }}
              onClick={() => {
                addClassType();
              }}
            >
              추가
            </Button>
          </Box>
          <Divider />
          <Paper>
            <Box sx={{ width: '100%' }}>
              <Box className='flex_between' sx={{ mt: '1rem' }}>
                <Box className='flex_center'>
                  <Checkbox
                    onChange={e => {
                      handleSelectAllClick(e);
                    }}
                    checked={checkedAll}
                  />
                  <Typography sx={{ ml: '1rem' }}>
                    전체 선택
                    <span style={{ color: '#2581FF' }}>
                      {' '}
                      [{classTypeList.length}개]
                    </span>
                  </Typography>
                </Box>
                <Box>
                  <Button
                    variant='outlined'
                    color='info'
                    sx={{ mr: '1.5rem', mt: '.5rem' }}
                    onClick={() => {
                      deleteCheckedClassType();
                    }}
                  >
                    선택한 분류 삭제
                  </Button>
                </Box>
                {/* <Button variant='outlined' color='info' sx={{ mr: '1.7rem' }}>
              저장
            </Button> */}
              </Box>
              {classTypeList?.map((item: any, idx: number) => (
                <Box key={idx.toString()} sx={{ display: 'flex' }}>
                  <Checkbox
                    id={`class_type_${idx}`}
                    onChange={e => {
                      changeHandler(e.currentTarget.checked, item.classTitle);
                    }}
                    checked={checkedButtons.includes(item.classTitle)}
                  />

                  <DraggablePaper
                    className='draggable'
                    id={idx.toString()}
                    variant='outlined'
                    onDragStart={dragStart}
                    onDragEnd={dragEnd}
                    onDragOver={dragOver}
                    onDrop={drop}
                    onDragLeave={dragLeave}
                    onDragEnter={dragEnter}
                    draggable
                    data-position={idx}
                    onClick={selectList}
                  >
                    <div>
                      <span>{item.classTitle}</span>
                    </div>
                    <DragHandleIcon />
                  </DraggablePaper>
                </Box>
              ))}
            </Box>
          </Paper>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button color='info' fullWidth size='small' onClick={onClose}>
            닫기
          </Button>
          <Button
            color='primary'
            fullWidth
            size='small'
            onClick={() => {
              saveChallengeClassType();
            }}
          >
            저장
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </>
  );
};
