import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

/*
 * reference: https://usehooks-typescript.com/
 */

type SetValue<T> = Dispatch<SetStateAction<T>>;

function useLocalStorage<T>(
  key: string,
  initialValue: T,
  tts: number
): [T, SetValue<T>] {
  const readValue = (): T => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    const obj = { value: initialValue, expire: Date.now() };
    // console.log('loginStorage>>>>>>>>>>>>', window.localStorage);
    try {
      const item = window.localStorage.getItem(key);
      return item ? (JSON.parse(item) as T) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  };

  const [storedValue, setStoredValue] = useState<T>(readValue);

  const setValue: SetValue<T> = value => {
    if (typeof window === 'undefined') {
      console.warn(
        `Tried setting localStorage key “${key}” even though environment is not a client`
      );
    }

    try {
      const newValue = value instanceof Function ? value(storedValue) : value;
      const obj = { value: newValue, expire: Date.now() + tts };
      // : window.localStorage.removeItem(key);
      window.localStorage.setItem(key, JSON.stringify(obj));
      // window.localStorage.setItem(key, JSON.stringify(newValue));
      setStoredValue(newValue);
      window.dispatchEvent(new Event('local-storage'));
    } catch (error) {
      console.warn(`Error setting localStorage key “${key}”:`, error);
    }
  };

  useEffect(() => {
    setStoredValue(readValue());
  }, []);

  useEffect(() => {
    const handleStorageChange = () => {
      setStoredValue(readValue());
    };

    window.addEventListener('storage', handleStorageChange);

    window.addEventListener('local-storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('local-storage', handleStorageChange);
    };
  }, []);

  return [storedValue, setValue];
}

export default useLocalStorage;
