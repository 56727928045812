import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwDatesPicker } from '../../commonComponent/dateSetting/SwDatesPicker';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { MultilineTextField } from '../../styles/Styles';
import {
  getAddUrl,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import {
  ButtonBox,
  TemplateCodeBox,
  TemplateCodeTitle,
  ContentTitle,
  CreatePaper,
  MarginButton,
} from './ProgramStyles';
import {
  IGuideTool,
  IChallengePost,
  IChallengeCreate,
  DefaultChallengeCreate,
} from '../../../models/challenge';
import {
  getChallengeAdvertisementList,
  getChallengeAdvertisementDetail,
  registerChallengeAdvertisement,
  deleteChallengeAdvertisement,
  deleteChallengeAdvertisementByIndex,
} from '../../../common/api/ApiChallenge';
import {
  KEY_CHALLENGE_ADVERTISEMENT_LIST,
  KEY_CHALLENGE_ADVERTISEMENT_DETAIL,
  KEY_PROGRAM_CURRICULUM_LIST,
} from '../../../common/key';
import { SwCurriculumList } from '../../commonComponent/program/SwCurriculumList';
import { SwCurriculumLoad } from '../../commonComponent/program/SwCurriculumLoad';
import { SwSaveDialog } from '../../commonComponent/views/SwSaveDialog';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import {
  getProgramCurriculumList,
  restoreProgramCurriculum,
} from '../../../common/api/ApiProgram';
import { RESULT_OK } from '../../../common/resultCode';

const columns = ['번호', '내용', '광고주', '광고 저장일시', '삭제'];
const repeatItem = ['one', 'custom', 'daily'];

interface Props {
  curriculumId: any;
  programInfo: any;
  reloadProgram: (id: string) => void;
  showDuration?: boolean;
}

export const ProgramCurriculum: React.FC<Props> = ({
  curriculumId,
  programInfo,
  reloadProgram,
  showDuration = true,
}) => {
  const queryClient = useQueryClient();
  const [requestList, setRequestList] = useState<any>([]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [useChallengePost, setUseChallengePost] = useState<number>(0);
  const [content, setContent] = useState<string>('');
  const [actionText, setActionText] = useState<string>('');
  const [actionUrl, setActionUrl] = useState<string>('');
  const [actionClickCount, setActionClickCount] = useState<number>(0);
  const [imageUrlPrefix, setImageUrlPrefix] = useState<string>('');
  const [formList, setFormList] = useState<any>([]);
  const [repeatType, setRepeatType] = useState<string>('one');
  const [repeatData, setRepeatData] = useState<any>();
  const [startDates, setStartDates] = useState<any[]>([]);
  const [endDates, setEndDates] = useState<any[]>([]);
  const [deletedDate, setDeletedDate] = useState<any>();
  const [degreeDay, setDegreeDay] = useState<any>('');
  const [curriculumName, setCurriculumName] = useState<string>('');
  const [saveCurriculum, setSaveCurriculum] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);
  const [openCurriculumLoad, setOpenCurriculumLoad] = useState<boolean>(false);
  const [openCurriculumSave, setOpenCurriculumSave] = useState<boolean>(false);
  const [openCurriculumInit, setOpenCurriculumInit] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false);

  const makeFormList = (data: any) => {
    const newFormList = data.map((item: any, idx: number) => {
      const newMissionType = item.missionType !== null ? item.missionType : 0;
      const newMissionSeq = item.missionSeq !== null ? item.missionSeq : 0;
      const newData: any[] = [];
      if (!newData[item.weekSeq]) newData[item.weekSeq] = [];
      if (!newData[item.weekSeq][newMissionType])
        newData[item.weekSeq][newMissionType] = [];
      const missionInfo =
        item.missionInfo !== null && item.missionInfo.length > 0
          ? JSON.parse(item.missionInfo)
          : null;
      const detail = {
        curriculumTitle: item.curriculumTitle,
        weekId: item.weekId,
        weekCount: item.weekCount,
        weekSeq: item.weekSeq,
        weekTitle: item.weekTitle,
        missionType: newMissionType,
        missionSeq: newMissionSeq,
        missionId: item.missionId,
        missionTitle: missionInfo !== null ? missionInfo.title : '',
        successScore: missionInfo !== null ? missionInfo.successScore : 10,
        successCount: missionInfo !== null ? missionInfo.successCount : 1,
      };
      newData[item.weekSeq][newMissionType][newMissionSeq] = detail;
      return newData;
    });
    setFormList(newFormList);
  };

  const {
    data,
    isLoading,
    refetch: programCurriculumRefetch,
  } = useQuery(
    KEY_PROGRAM_CURRICULUM_LIST,
    () => {
      console.log('curriculumId: ', curriculumId);
      if (curriculumId != null && curriculumId.length > 0) {
        const newData: any = {
          curriculumId: curriculumId,
        };
        return getProgramCurriculumList(newData);
      }
      return null;
    },
    {
      onSuccess: res => {
        console.log('curriculumList :', res);
        if (res !== null) {
          makeFormList(res.curriculum);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchProgramCurriculum = (newCurriculumId: string) => {
    queryClient.prefetchQuery(KEY_PROGRAM_CURRICULUM_LIST, () => {
      const newData: any = {
        curriculumId: newCurriculumId,
      };
      return getProgramCurriculumList(newData);
    });
  };

  const checkCurriculum = () => {
    console.log('check Curriculum');
    setSaveCurriculum(true);
  };

  const { mutate: restoreProgramCurriculumMutate, isError: isMutateError } =
    useMutation(restoreProgramCurriculum, {
      onSuccess: res => {
        setLoading(false);
        if (res.resultCode === RESULT_OK) {
          setOpenSnakerDialog(true);
          setDialogMessage('초기화되었습니다.');

          programCurriculumRefetch();
        } else {
          setOpenSnakerDialog(true);
          setDialogMessage(
            `초기화하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage('초기화가 실패했습니다. 다시 시도해주시기 바랍니다.');
      },
    });

  const restoreProgram = () => {
    const newData = {
      programId: programInfo.programId,
      curriculumId: curriculumId,
    };
    setLoading(true);
    restoreProgramCurriculumMutate(newData);
  };

  const reloadCurriculum = (id: string, type: string) => {
    if (id !== '') {
      console.log('ProgramCurriculum reloadCurriculum :', id, ', type:', type);
      // setSaveCurriculum(false);
      type === 'program'
        ? reloadProgram(programInfo.programId)
        : type === 'template'
        ? reloadProgram(curriculumId)
        : prefetchProgramCurriculum(id);
    }
  };

  // useEffect(() => {
  //   console.log('ProgramCurriculum tmpCurriculumId :', tmpCurriculumId);
  //   if (tmpCurriculumId !== '') {
  //     // console.log('ProgramCurriculum tmpCurriculumId :', tmpCurriculumId);
  //     setSaveCurriculum(false);
  //     programCurriculumRefetch();
  //   }
  // }, [tmpCurriculumId]);

  useEffect(() => {
    console.log('ProgramCurriculum programInfo :', programInfo);
  }, [programInfo]);

  useEffect(() => {
    if (
      curriculumId !== undefined &&
      curriculumId !== null &&
      curriculumId.length > 0
    ) {
      console.log('ProgramCurriculum curriculumnId :', curriculumId);
      setSaveCurriculum(false);
      programCurriculumRefetch();
    } else {
      setOpenSnakerDialog(true);
      setDialogMessage('프로그램을 선택해주세요');
    }
  }, [curriculumId]);

  return (
    <Paper sx={{ p: '2rem 1.5rem' }}>
      <Box className='flex_end' sx={{ width: '95%', margin: '0rem 1.7rem' }}>
        <Box className='flex_between'>
          <Box
            className='flex_center'
            sx={{
              margin: '0rem 1.0rem',
            }}
          >
            {programInfo !== undefined &&
              programInfo !== null &&
              programInfo.registerStatus !== 4 && (
                <Button
                  variant='outlined'
                  color='info'
                  onClick={() => {
                    setOpenCurriculumInit(true);
                  }}
                >
                  커리큘럼 초기화
                </Button>
              )}
          </Box>
          {/* <Box
            className='flex_center'
            sx={{
              margin: '0rem 1.0rem',
            }}
          >
            <Button
              variant='outlined'
              color='info'
              onClick={() => {
                setOpenCurriculumSave(true);
              }}
            >
              커리큘럼 저장하기
            </Button>
          </Box>
          <Box
            className='flex_center'
            sx={{
              margin: '0rem 1.0rem',
            }}
          >
            <Button
              variant='outlined'
              color='info'
              onClick={() => {
                setOpenCurriculumLoad(true);
              }}
            >
              커리큘럼 불러오기
            </Button>
          </Box> */}
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box>
          <SwCurriculumList
            draggableName='draggable_form'
            formList={formList}
            setFormList={setFormList}
            programInfo={programInfo}
            showDuration={showDuration}
            saveCurriculum={saveCurriculum}
            onReloadCurriculum={reloadCurriculum}
          />
          <Divider />
        </Box>
        {/* <Box className='flex_end' sx={{ mt: '0rem' }}>
          <Button variant='contained' color='primary' onClick={checkCurriculum}>
            임시 저장
          </Button>
        </Box> */}
      </Box>
      <SwCurriculumLoad
        open={openCurriculumLoad}
        onClose={() => setOpenCurriculumLoad(false)}
      />
      <SwSaveDialog
        head='저장할 커리큘럼의 제목을 입력해 주세요.'
        setTitle={setCurriculumName}
        open={openCurriculumSave}
        onConfirm={() => {
          setOpenCurriculumSave(false);
        }}
        onClose={() => {
          setOpenCurriculumSave(false);
        }}
      />
      <SwSnackbar
        open={openSnakerDialog}
        onClose={() => setOpenSnakerDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      <SwConfirmDialog
        contents='커리큘럼을 초기화하시겠습니까?'
        open={openCurriculumInit}
        onClose={() => setOpenCurriculumInit(false)}
        onConfirm={() => {
          restoreProgram();
          setOpenCurriculumInit(false);
        }}
        confirm={1}
      />
      {loading && <SwLoading />}
    </Paper>
  );
};
