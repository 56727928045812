import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { xapi } from '../../hooks/session';
import { IAmd } from '../../models/amd';
import { IRegionFilter } from '../../models/regionfilter';
import { throwError } from '../helper';

/*
 * GeoHash
 */

export const getAmdList = () =>
  xapi.get(`/admin/v2/apis/region-filter/amd`).then(res => {
    if (res.status !== 200) throwError(res.status);
    return res.data;
  });

export const getGeoHashGroupList = (searchKey: string, searchWord: string) =>
  xapi
    .get(
      `/admin/v2/apis/region-filter?searchKey=${searchKey}&searchWord=${searchWord}`
    )
    .then(res => {
      if (res.status !== 200) throwError(res.status);
      return res.data;
    });

export const registerRegionFilter = (data: IRegionFilter) =>
  xapi.post(`/admin/v2/apis/region-filter`, data);

export const deleteRegionFilter = (data: IRegionFilter) =>
  xapi.delete(`/admin/v2/apis/region-filter/${data.geoHashId}`);
