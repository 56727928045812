export interface IField {
  fieldId: string;
  name: string;
  deleted: number;
  dataPortalId: string;
  dataPortalName?: string;
  editor: string;
  spots?: IFieldSpotInfo[];
  registerStatus: number;
  hidden: number;
  createDate?: number;
  requestedFrom?: number;
  refreshPeriod: number;
  refreshLimit: number;
  refreshRadius: number;
}

export interface IFieldInfo {
  fieldId: string;
  name: string;
  deleted: number;
  dataPortalId: string;
  dataPortalName?: string;
  editor: string;
  spots?: IFieldSpotInfo[];
  registerStatus: number;
  requestedFrom: number;
  hidden: number;
  createDate?: number;
  refreshPeriod: number;
  refreshLimit: number;
  refreshRadius: number;
}

export interface IWalkpointInfo {
  pointId: string;
  pointPtLat: number;
  pointPtLng: number;
  pointRadius: number;
  pointSeq: number;
}

export interface IFieldSpotInfo {
  spotDeleted: string;
  spotId: string;
  spotName: string;
  spotPointLat: number;
  spotPointLng: number;
  spotRadius: number;
  spotSeq: number;
  className: string;
  link: string;
  phoneNo: string;
  address: string;
  description: string;
}

export interface IFieldDetail {
  field: IFieldInfo;
  point: IWalkpointInfo[];
}

export const DefaultFieldInfo: IFieldInfo = {
  fieldId: '',
  name: '',
  deleted: 0,
  dataPortalId: '',
  createDate: 0,
  dataPortalName: '',
  editor: '',
  registerStatus: 0,
  requestedFrom: 0,
  hidden: 0,
  refreshPeriod: 0,
  refreshLimit: 0,
  refreshRadius: 0,
};

export const DefaultWalkpointInfo: IWalkpointInfo = {
  pointId: '',
  pointPtLat: 0,
  pointPtLng: 0,
  pointRadius: 0,
  pointSeq: 0,
};

export const DefaultFieldDetail: IFieldDetail = {
  field: DefaultFieldInfo,
  point: [DefaultWalkpointInfo],
};
