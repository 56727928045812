import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, Dispatch, ChangeEvent } from 'react';

interface Props {
  setObserverType: Dispatch<React.SetStateAction<number>>;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export const SwObserverDialog: React.FC<Props> = ({
  setObserverType,
  open,
  onConfirm,
  onClose,
}) => {
  const [observer, setObserver] = useState<number>(0);

  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-container': {
          height: '85%',
        },
      }}
    >
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogContent sx={{ p: '1rem 2rem' }}>
        <Typography sx={{ m: '1rem' }}>
          운영자 설정값을 선택해주세요.
        </Typography>
        <TextField
          select
          fullWidth
          value={observer}
          onChange={(
            evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            setObserver(Number(evt.target.value));
            setObserverType(Number(evt.target.value));
          }}
        >
          <MenuItem key='role_0' value='0'>
            해제
          </MenuItem>
          <MenuItem key='role_1' value='1'>
            운영자
          </MenuItem>
        </TextField>
      </DialogContent>
      <Divider sx={{ p: 0, m: 0 }} />
      <DialogActions>
        <Button color='info' fullWidth size='small' onClick={onClose}>
          취소
        </Button>
        <Button color='primary' fullWidth size='small' onClick={onConfirm}>
          확인
        </Button>
      </DialogActions>
    </Dialog>
  );
};
