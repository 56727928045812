import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import koLocale from 'date-fns/locale/ko';
import {
  Box,
  Button,
  Chip,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  title?: string;
  dates: any;
  setDates: Dispatch<SetStateAction<any[]>>;
  showButton?: boolean;
  setDeletedDate?: Dispatch<SetStateAction<any>>;
  maxLimit?: number;
  allowPastDate?: boolean;
  disabled?: boolean;
  dateRange?: any[];
  allowDeletePastDate?: boolean;
}

const DateTextField = styled(TextField)({
  '& .MuiInputLabel-root': { top: '-5px' },
  '& .MuiOutlinedInput-root': { pr: '1rem' },
  width: '20rem',
});

const DateChip = styled(Chip)({
  margin: '.5em',
  backgroundColor: 'rgba(0, 162, 255, 0.1)',
  borderColor: 'rgba(0, 162, 255, 0.5)',
  '& .MuiChip-deleteIcon': {
    fontSize: '1rem',
  },
});

const DateAddButton = styled(Button)({
  marginTop: '.3rem',
  marginLeft: '.7rem',
  padding: '.1rem 0',
  borderRadius: '20px',
});

export const SwDatesPicker: React.FC<Props> = ({
  title = '적용일시 선택',
  dates,
  setDates,
  showButton = true,
  setDeletedDate,
  maxLimit = 10,
  allowPastDate = true,
  disabled = false,
  dateRange = null,
  allowDeletePastDate = true,
}) => {
  const [date, setDate] = useState<Date | null>(null);
  const [dateErrorMessage, setDateErrorMessage] = useState<string>('');
  const getType = (target: any) =>
    Object.prototype.toString.call(target).slice(8, -1);
  const addDate = () => {
    const now = new Date(Date.now());
    const newDates = [...dates];
    if (newDates.length === maxLimit) {
      setDateErrorMessage(
        `적용 일시는 최대 ${maxLimit}개까지 등록 가능합니다.`
      );
    } else if (newDates.filter(d => d === date).length) {
      setDateErrorMessage('중복된 날짜입니다.');
    } else if (!allowPastDate && date !== null && date < now) {
      setDateErrorMessage('과거 시간은 선택할 수 없습니다.');
    } else if (
      getType(date) === 'Date' &&
      date?.getTime().toString() !== 'NaN'
    ) {
      newDates.push(date);
      setDateErrorMessage('');
    } else {
      setDateErrorMessage('날짜를 입력해 주세요.');
    }

    if (dateRange !== null && dateRange.length === 2) {
      if (date !== null && (date < dateRange[0] || date > dateRange[1])) {
        setDateErrorMessage('진행기간 내로 설정해 주세요.');
        return;
      }
    }
    setDates(newDates);
  };

  useEffect(() => {
    if (dateRange !== null && dateRange.length === 2 && dates?.length > 0) {
      if (
        dates.findIndex((d: any) => d < dateRange[0] || d > dateRange[1]) !== -1
      ) {
        setDateErrorMessage('진행기간 내로 설정해 주세요.');
      } else {
        setDateErrorMessage('');
      }
    }
  }, [dateRange]);

  useEffect(() => {
    if (dates?.length === 0) {
      setDate(null);
    } else {
      setDate(dates[dates.length - 1]);
    }
  }, [dates]);
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={koLocale}
        >
          <DateTimePicker
            disabled={disabled}
            inputFormat='yyyy-MM-dd HH:mm:ss'
            minDate={
              allowPastDate ? new Date('1970-01-01 00:00:00') : new Date()
            }
            renderInput={props => <DateTextField {...props} />}
            label={title}
            value={date}
            onChange={newValue => {
              setDate(newValue);
              if (!showButton) setDates([newValue]);
            }}
            minDateTime={
              dateRange !== null && dateRange.length === 2 ? dateRange[0] : null
            }
            maxDateTime={
              dateRange !== null && dateRange.length === 2 ? dateRange[1] : null
            }
          />
        </LocalizationProvider>
        {showButton && (
          <DateAddButton onClick={addDate} variant='outlined' color='info'>
            추가
          </DateAddButton>
        )}
      </Box>
      {dateErrorMessage !== '' && (
        <Typography sx={{ color: 'red' }}>{dateErrorMessage}</Typography>
      )}
      <Box>
        {showButton &&
          dates.length > 0 &&
          dates.map((d: Date, idx: number) =>
            allowDeletePastDate || d.getTime() > Date.now() ? (
              <DateChip
                label={d
                  .toLocaleString()
                  .slice(0, d.toLocaleString().length - 3)}
                variant='outlined'
                deleteIcon={<CloseIcon />}
                onDelete={() => {
                  const newDates = dates.filter(
                    (_dt: Date, i: number) => i !== idx
                  );
                  setDates([...newDates]);
                  setDeletedDate && setDeletedDate(d);
                  setDateErrorMessage('');
                }}
                key={idx.toString()}
              />
            ) : (
              <DateChip
                label={d
                  .toLocaleString()
                  .slice(0, d.toLocaleString().length - 3)}
                variant='outlined'
                key={idx.toString()}
              />
            )
          )}
      </Box>
    </>
  );
};
